import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    success_url,
    alert,
    GET,
    POST,
    PUT,
    format_date,
    float,
    validate,
    required,
    DatePickerTH,
    getStorage,
    toFixed,
    validateEmail,
    total,
    days_between,
    print_paper01,
    status_rent,
    status_booking,
    days_between_return_car,
    fieldSorter,
    getimgBase64
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Resizer from "react-image-file-resizer";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";
import CAR from "../../assets/images/bike.png";
import EMPTY from "../../assets/images/empty.png";

export default class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {

            page: 1,
            loading: false,

            data: null,
            data_booking_1: [],
            data_booking_2: [],
            data_rent_1: [],
            data_rent_2: [],
            data_bike: [],
            data_equipment: [],

            booking_2: "",
            rent_1: "",
            rent_2: "",

            date_rent_1_from: "",
            date_rent_1_to: "",
            date_rent_2_from: "",
            date_rent_2_to: "",

            date_booking_1_from: "",
            date_booking_1_to: "",
            date_booking_2_from: "",
            date_booking_2_to: "",


            date_rent_1: "",
            date_rent_2: "",
            date_booking_1: "",
            date_booking_2: "",

            total_rent: 0,
            balance_bike: 0,
            total_booking: 0,
            total_daily: 0,
            total_monthly: 0,
            total_yearly: 0,

            // การจอง Booking
            modal_create_booking: false,
            modal_update_booking: false,
            modal_delete_booking: false,
            modal_detail_booking: false,
            modal_car_booking: false,
            modal_promise_booking: false,

            tab: "สัญญาการจอง",
            product_index: -1,
            type: "",
            update_type: "",

            id: "",
            id_card: "",
            full_name: "",
            email: "",
            phone: "",
            brand_id: "",
            model_id: "",
            pick_up_date: new Date(),
            pick_up_time: format_date(new Date(), "hh:mm"),
            pick_up_location: "",
            return_date: new Date(new Date().setDate(new Date().getDate() + 1)),
            return_time: format_date(new Date(), "hh:mm"),
            return_location: "",
            daily_price: "",
            deposit_pay: "",
            payment_date: "",
            payment_time: "",
            payment_picture: [],
            booking_status: "",
            deposit_price: "",
            booking_price: "",
            traffic_ticket_price: 0,
            booking_number: "",

            days: 0,

            search_bike: "",

            selected_bike: null,

            payment_list: [],

            show_image: "",
            modal_picture_booking: false,

            // การเช่า Rent
            modal_update_rent: false,
            modal_payment_rent: false,
            modal_scale_rent: false,
            modal_return_rent: false,
            modal_print_rent: false,
            modal_detail_rent: false,
            modal_change_rent: false,
            modal_delete_rent: false,

            page: 1,
            loading: false,

            filter_search: "",
            filter_rental_status: "",
            filter_date: "",

            data: null,
            data_bank: null,
            data_detail: null,

            tab: "สัญญาการจอง",
            status_pay: "",
            rental_id: "",
            rental_number: "",
            address: "",
            birthday: null,
            district: "",
            email: "",
            full_name: "",
            id_card: "",
            is_active: true,
            member_code: "",
            member_id: "",
            member_status: 1,
            phone: "",
            picture: "",
            province: "",
            sub_district: "",
            username: "",
            verify_status: true,
            zip_code: "",
            picture: "",

            facebook: "",
            id_line: "",
            nationality: "",
            card_issue: "",
            date_issue: "",
            date_expire: "",
            temporary_accommodation: "",

            pick_up_date: new Date(),
            pick_up_time: format_date(new Date(), "hh:mm"),
            pick_up_location: "",
            return_date: new Date(new Date().setDate(new Date().getDate() + 1)),
            return_time: format_date(new Date(), "hh:mm"),
            return_location: "",

            rental_type: "",
            mileage: 0,
            oil_volume: 0,
            rental_price: 0,
            guarantee_type: 1,
            guarantee_amount: 0,
            equipment_fee: 0,
            discount_price: 0,
            delivery_price: 0,
            recommen_id: "",
            total_price: 0,
            signature: "",

            total_price_equipment: 0,
            total_qty_equipment: 0,

            detail_picture: ["", "", "", "", "", "", "", "", "", ""],

            selected_bike: null,

            search_bike: "",
            rental_status: "",

            days: 0,

            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา 
            return_date_new: null, // วันคืนรถใหม่
            return_time_new: null, // เวลาคืนรถใหม่
            rental_type: "daily", // ประเภทการเช่า
            rental_price: 0, // ราคาเช่ารถ
            remark: "",
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            bank_name: "",
            payment_type: null,
            payment_date: "",
            payment_time: "",
            payment_picture: [],
            discount: 0,
            discount_pay: 0,
            spend_points: 0, // แต้มที่ใช้
            change_points: 0, // แต้มแลกส่วนลด
            total_price: 0,
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            overdue_booking: 0,
            change: 0,
            event_type: 1,

            refund_cost: 0,

            use_point: false,
            point: 0,

            use_sk_wallet: false,
            sk_wallet: 0,

            array_price: {
                daily_price: 0,
                weekly_price: 0,
                monthly_price: 0
            },
            wallet: null,
            user_type: "",
            dropdowns1: -1,
            dropdowns2: -1,
            dropdowns3: -1,
            dropdowns4: -1,

            filter_search_1: "",
            filter_search_2: "",
            tab_return: 0,
            modal_picture: false,

            signature64: "",
            detail_picture64: "",
        }
        this.divRef1 = React.createRef();
        this.divRef2 = React.createRef();
        this.divRef3 = React.createRef();
        this.divRef4 = React.createRef();
        this.divRef5 = React.createRef();
        this.divRef6 = React.createRef();
        this.divRef7 = React.createRef();
        this.divRef8 = React.createRef();
        this.divRef9 = React.createRef();
        this.divRef10 = React.createRef();
        this.divRef11 = React.createRef();
        this.divRef12 = React.createRef();
        this.divRef13 = React.createRef();
        this.divRef14 = React.createRef();
        this.divRef15 = React.createRef();
        this.divRef16 = React.createRef();
        this.divRef17 = React.createRef();
        this.divRef18 = React.createRef();
        this.divRef19 = React.createRef();
        this.divRef20 = React.createRef();
        this.divRef21 = React.createRef();
        this.divRef22 = React.createRef();
        this.divRef23 = React.createRef();
        this.divRef24 = React.createRef();
        this.divRef25 = React.createRef();
        this.divRef26 = React.createRef();
        this.divRef27 = React.createRef();
        this.divRef28 = React.createRef();
        this.divRef29 = React.createRef();
        this.divRef30 = React.createRef();
        this.divRef31 = React.createRef();
        this.divRef32 = React.createRef();
        this.divRef33 = React.createRef();
        this.divRef34 = React.createRef();
        this.divRef35 = React.createRef();
        this.divRef36 = React.createRef();
        this.close_dropdown = this.close_dropdown.bind(this);
    }
    async componentDidMount() {
        document.addEventListener('mousedown', this.close_dropdown);
        let user_type = getStorage("user") && getStorage("user").user_type ? getStorage("user").user_type : null;
        this.setState({ user_type: user_type });

        this.setState({ loading: true });
        await this.GetBikeAll();
        await this.GetBankAll();
        await this.GetDashboard();
        await this.GetEquipmentAll();
        await this.GetBookingAll();
        await this.GetRentAll();
        this.setState({ loading: false });
    }
    componentWillUnmount() {
        document.addEventListener('mousedown', this.close_dropdown);
    }
    close_dropdown = (event) => {

        if ((this.divRef1.current && this.divRef1.current.contains(event.target)) ||
            (this.divRef2.current && this.divRef2.current.contains(event.target)) ||
            (this.divRef3.current && this.divRef3.current.contains(event.target)) ||
            (this.divRef4.current && this.divRef4.current.contains(event.target)) ||
            (this.divRef5.current && this.divRef5.current.contains(event.target)) ||
            (this.divRef6.current && this.divRef6.current.contains(event.target)) ||
            (this.divRef7.current && this.divRef7.current.contains(event.target)) ||
            (this.divRef8.current && this.divRef8.current.contains(event.target)) ||
            (this.divRef9.current && this.divRef9.current.contains(event.target)) ||
            (this.divRef10.current && this.divRef10.current.contains(event.target)) ||
            (this.divRef11.current && this.divRef11.current.contains(event.target)) ||
            (this.divRef12.current && this.divRef12.current.contains(event.target)) ||
            (this.divRef13.current && this.divRef13.current.contains(event.target)) ||
            (this.divRef14.current && this.divRef14.current.contains(event.target)) ||
            (this.divRef15.current && this.divRef15.current.contains(event.target)) ||
            (this.divRef16.current && this.divRef16.current.contains(event.target)) ||
            (this.divRef17.current && this.divRef17.current.contains(event.target)) ||
            (this.divRef18.current && this.divRef18.current.contains(event.target)) ||
            (this.divRef19.current && this.divRef19.current.contains(event.target)) ||
            (this.divRef20.current && this.divRef20.current.contains(event.target)) ||
            (this.divRef21.current && this.divRef21.current.contains(event.target)) ||
            (this.divRef22.current && this.divRef22.current.contains(event.target)) ||
            (this.divRef23.current && this.divRef23.current.contains(event.target)) ||
            (this.divRef24.current && this.divRef24.current.contains(event.target)) ||
            (this.divRef25.current && this.divRef25.current.contains(event.target)) ||
            (this.divRef26.current && this.divRef26.current.contains(event.target)) ||
            (this.divRef27.current && this.divRef27.current.contains(event.target)) ||
            (this.divRef28.current && this.divRef28.current.contains(event.target)) ||
            (this.divRef29.current && this.divRef29.current.contains(event.target)) ||
            (this.divRef30.current && this.divRef30.current.contains(event.target)) ||
            (this.divRef31.current && this.divRef31.current.contains(event.target)) ||
            (this.divRef32.current && this.divRef32.current.contains(event.target)) ||
            (this.divRef33.current && this.divRef33.current.contains(event.target)) ||
            (this.divRef34.current && this.divRef34.current.contains(event.target)) ||
            (this.divRef35.current && this.divRef35.current.contains(event.target)) ||
            (this.divRef36.current && this.divRef36.current.contains(event.target))) { } else {
            this.setState({ dropdowns1: -1, dropdowns2: -1, dropdowns3: -1, dropdowns4: -1 })
        }
    }
    GetDashboard = async () => {
        let result = await GET(tokens, "v1/TH/dashboard/filter-search", null);
        if (result && result.status) {

            this.setState({
                total_daily: result.data.data.total_daily,
                total_monthly: result.data.data.total_monthly,
                total_yearly: result.data.data.total_yearly,
                balance_bike: result.data.data.balance_bike
            })
        }
    }
    GetEquipmentAll = async () => {
        let result = await GET(tokens, "v1/TH/equipment/filter-search/page/0", null);
        if (result && result.status) {
            this.setState({ data_equipment: result.data.data.map(item => { return { ...item, equipment_qty: 0, booking_equipment_id: "" } }) })
        }
    }
    GetBookingAll = async () => {
        let data = {
            data_search: "",
            booking_status: "",
        }
        let result = await GET(tokens, "v1/TH/booking/filter-search/page/0", data);
        if (result && result.status) {
            let data_booking_1 = result.data.data.filter(e => e.booking_status === 4)
            let data_booking_2 = result.data.data.filter(e => e.booking_status === 1 || e.booking_status === 2 || e.booking_status === 3)
            this.setState({
                data_booking_1: data_booking_1.sort(fieldSorter(["pick_up_date", "pick_up_time"])),
                data_booking_2: data_booking_2.sort(fieldSorter(["pick_up_date", "pick_up_time"])),
                total_booking: result.data.data.filter(e => e.booking_status === 3 || e.booking_status === 4).length
            })
        }
    }
    GetRentAll = async () => {
        let data = {
            data_search: "",
            pick_up_date: "",
            rental_status: "",
        }
        let result = await GET(tokens, "v1/TH/rental-manage/dashboard", data);
        if (result && result.status) {
            let data_rent_1 = result.data.data.filter(e => e.rental_status === 4 || e.rental_status === 8 || e.rental_status === 3)
            let data_rent_2 = result.data.data.filter(e => e.rental_status === 5 || e.rental_status === 1 || e.rental_status === 7)
            this.setState({
                data_rent_1: data_rent_1.sort(fieldSorter(["return_date", "return_time"])),
                data_rent_2: data_rent_2.sort(fieldSorter(["return_date", "return_time"])),
                total_rent: result.data.data.filter(e => e.rental_status === 1 || e.rental_status === 2 || e.rental_status === 3 || e.rental_status === 4 || e.rental_status === 5 || e.rental_status === 7 || e.rental_status === 8 || e.rental_status === 9 || e.rental_status === 12).length
            }) 
        }
    }
    GetBikeAll = async () => {
        let data = {
            start_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : null,
            end_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null,
            search: ""
        }
        let result = await GET(tokens, "v1/TH/rental-manage/total-bike-rental", data);
        if (result && result.status) { 
            this.setState({ data_bike: result.data.data });
        }
    }
    GetBankAll = async () => {
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bank-account/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_bank: result.data.data.map(e => { return { value: e.bank_id, label: e.account_number + " " + e.bank_name + " สาขา" + e.branch_name } }) })
        }
    }

    // การจอง Booking
    GetMemberByIDCard = async () => {
        if (this.state.id_card === "") { return }
        this.setState({ loading: true });
        let data = {
            data_search: this.state.id_card,
        }
        let result = await GET(tokens, "v1/TH/user-member/filter-search/page/0", data);
        if (result && result.status) {
            if (result.data.data.filter(e => e.id_card === this.state.id_card).length === 1) {
                this.setState({
                    id_card: result.data.data.filter(e => e.id_card === this.state.id_card)[0].id_card,
                    full_name: result.data.data.filter(e => e.id_card === this.state.id_card)[0].full_name,
                    email: result.data.data.filter(e => e.id_card === this.state.id_card)[0].email,
                    phone: result.data.data.filter(e => e.id_card === this.state.id_card)[0].phone,
                    traffic_ticket_price: total(result.data.data.filter(e => e.id_card === this.state.id_card)[0].traffic_ticket.filter(e => e.ticket_status !== 2), "traffic_fines")
                });
            } else {
                this.setState({ full_name: "", email: "", phone: "" });
                alert("warning", "แจ้งเตือน", "ไม่พบข้อมูลสมาชิก");
            }
        }
        this.setState({ loading: false });
    }
    Booking_Create = async () => {
        if (validate(this.state, ["id_card", "full_name", "phone", "selected_bike", "pick_up_location", "return_location"])) { return };
        if (this.state.email !== "" && !validateEmail(this.state.email)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        }
        if (this.state.phone !== "" && this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบเบอร์โทรศัพท์ให้ถูกต้อง")
            return
        }
        this.setState({ loading: true });
        let data = {
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            email: this.state.email,
            phone: this.state.phone,
            brand_id: this.state.selected_bike.brand_id,
            model_id: this.state.selected_bike.model_id,
            pick_up_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : "",
            pick_up_time: this.state.pick_up_time,
            pick_up_location: this.state.pick_up_location,
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : "",
            return_time: this.state.return_time,
            return_location: this.state.return_location,
            daily_price: this.state.selected_bike.daily_price,
            title_picture: this.state.selected_bike ? this.state.selected_bike.title_picture : "",
            traffic_ticket_price: 0,
        }
        let result = await POST(tokens, "v1/TH/booking/create", data);
        if (result && result.status) {
            success_url("/admin/list-booking")
        }
        this.setState({ loading: false });

    }
    Booking_Update = async () => {
        let booking_status = this.state.booking_status;
        if (booking_status != 5) {
            if (validate(this.state, ["id_card", "full_name", "phone", "selected_bike", "pick_up_location", "return_location"])) { return };
            if (this.state.email !== "" && !validateEmail(this.state.email)) {
                alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
                return
            }
        }
        if (booking_status === 1 && Number(this.state.deposit_price) !== 0) {
            booking_status = 2;
        } else if (booking_status === 3 && this.state.deposit_pay && this.state.payment_date && this.state.payment_time && this.state.update_type !== "แก้ไข") {
            booking_status = 4;
        }
        this.setState({ loading: true });
        let data = {
            booking_id: this.state.id,
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            email: this.state.email,
            phone: this.state.phone,
            brand_id: this.state.selected_bike.brand_id,
            model_id: this.state.selected_bike.model_id,
            pick_up_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : "",
            pick_up_time: this.state.pick_up_time,
            pick_up_location: this.state.pick_up_location,
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : "",
            return_time: this.state.return_time,
            return_location: this.state.return_location,
            daily_price: this.state.selected_bike.daily_price,
            title_picture: this.state.selected_bike ? this.state.selected_bike.title_picture : "",
            deposit_pay: Number(this.state.deposit_pay),
            payment_date: this.state.payment_date,
            payment_time: this.state.payment_time,
            payment_picture: this.state.payment_picture,
            booking_status: booking_status,
            deposit_price: Number(this.state.deposit_price),
            booking_price: Number(this.state.booking_price),
            traffic_ticket_price: Number(this.state.traffic_ticket_price),
            admin_remark: (this.state.booking_status === 2 || this.state.booking_status === 3 || this.state.booking_status === 4) && this.state.old_daily_price < this.state.selected_bike.daily_price ? "รอตรวจสอบ" : ""
        }
        let result = await PUT(tokens, "v1/TH/booking/update", data);
        if (result && result.status) {
            success_url("/admin/list-booking")
        }
        this.setState({ loading: false });
    }
    Booking_import_file = (e) => {
        this.Booking_changePhoto(e.target.files[0])
        document.getElementById("file_booking").value = "";
        document.getElementById("file_booking").file = null;
    }
    Booking_drop_file = (e) => {
        e.preventDefault();
        this.Booking_changePhoto(e.dataTransfer.items[0].getAsFile())
    }
    Booking_changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            this.setState({
                payment_picture: e.target.result.split(",")[1],
            })
        }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    this.setState({ payment_picture: uri.split(",")[1] })
                },
                "base64"
            );
        });
    }

    // การเช่า Rent 
    GetById = async (id, type) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/rental-manage/detail/" + id, null);
        if (result && result.status) {
            let item = result.data.data;
            let detail_picture = item.detail_picture.map(e => { return e.picture });
            for (let i = 0; i < 10; i++) {
                if (item.detail_picture.length <= i) {
                    detail_picture.push("")
                }
            }

      let detail_picture64 = item.detail_picture64?item.detail_picture64.map((e) => {
        return "data:image/png;base64,"+e.picture64;
      }):[];
      for (let i = 0; i < 10; i++) {
        if (item.detail_picture64?item.detail_picture64.length <= i:false) {
          detail_picture64.push("");
        }
      }
            this.setState({
                created_by: item.created_by,
                address: item.address,
                admin_name: item.admin_name,
                detail: item,
                rental_id: id,
                rental_number: item.rental_number,
                booking_number: item.booking_number,
                id_card: item.id_card,
                full_name: item.full_name,
                birthday: item.birthday,
                nationality: item.nationality,
                card_issue: item.card_issue,
                date_issue: item.date_issue,
                date_expire: item.date_expire,
                temporary_accommodation: item.temporary_accommodation,
                facebook: item.facebook,
                phone: item.phone,
                email: item.email,
                id_line: item.id_line,
                selected_bike: {
                    brand_name: item.brand_name,
                    model_name: item.model_name,
                    license_plate: item.license_plate,
                    chassis_number: item.chassis_number,
                    bike_color: item.bike_color,
                    cc_size: item.cc_size,
                    bike_year: item.bike_year,
                    title_picture: item.title_picture,
                    daily_price: item.daily_price,
                    bike_channel_name: item.bike_channel_name
                },
                pick_up_date: item.pick_up_date,
                pick_up_time: item.pick_up_time,
                pick_up_location: item.pick_up_location,
                return_date: item.return_date,
                return_time: item.return_time,
                return_location: item.return_location,

                rental_type: item.rental_type === "daily" ? { value: "daily", label: "รายวัน" } :
                    item.rental_type === "weekly" ? { value: "weekly", label: "รายสัปดาห์" } :
                        item.rental_type === "monthly" ? { value: "monthly", label: "รายเดือน" } : null,
                mileage: item.mileage,
                oil_volume: item.oil_volume,
                rental_price: item.rental_price,
                guarantee_type: item.guarantee_type === 1 ? { value: "1", label: "เงินประกัน" } :
                    item.guarantee_type === 2 ? { value: "2", label: "สำเนาบัตรประชาชน" } :
                        item.guarantee_type === 3 ? { value: "3", label: "บัตรประชาชนตัวจริง" } :
                            item.guarantee_type === 4 ? { value: "4", label: "บัตรข้าราชการ" } :
                                item.guarantee_type === 5 ? { value: "5", label: "บัตรนักศึกษา" } :
                                    item.guarantee_type === 6 ? { value: "6", label: "พาสปอร์ตตัวจริง" } :
                                        item.guarantee_type === 7 ? { value: "7", label: "สำเนาพาสปอร์ต" } :
                                            item.guarantee_type === 8 ? { value: "8", label: "ใบขับขี่" } :
                                                item.guarantee_type === 9 ? { value: "9", label: "ใบขับขี่ตัวจริง" } :
                                                    item.guarantee_type === 10 ? { value: "10", label: "เงินหยวน" } :
                                                        item.guarantee_type === 11 ? { value: "11", label: "เงินดอลล่า" } :
                                                            item.guarantee_type === 12 ? { value: "12", label: "เงินยูโร" } : null,
                guarantee_amount: item.guarantee_amount,
                equipment_fee: item.equipment_fee,
                discount_price: item.discount_price,
                delivery_price: item.delivery_price,
                recommen_id: item.recommen_id,
                total_price: item.total_price,
                signature: item.signature,
                signature64: item.signature64,
                equipment_list: item.equipment_list,
                total_price_equipment: item.total_price_equipment,
                overdue_booking: item.overdue_booking,
                total_pay_booking: item.total_pay_booking,
                total_qty_equipment: item.total_qty_equipment,
                detail_picture: detail_picture,
                detail_picture64: detail_picture64,
                title_picture: item.title_picture,
                rental_status: item.rental_status,
                other_price: item.other_price ? item.other_price : 0,

                days: days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en"))),

                array_price: {
                    daily_price: item.daily_price,
                    weekly_price: item.weekly_price,
                    monthly_price: item.monthly_price
                },
                old_array_price: {
                    daily_price: item.daily_price,
                    weekly_price: item.weekly_price,
                    monthly_price: item.monthly_price
                },

                wallet: {
                    point: item.points,
                    sk_wallet: item.sk_wallet
                },
                old_price: item.rental_price
            })
            if (type === "เปลี่ยนรถ/สถานที่") {
                setTimeout(() => {
                    if (this.state.selected_bike) {
                        if (this.state.rental_type.value === "daily") {
                            this.setState({ old_price: this.state.array_price.daily_price * this.state.days, rental_price: this.state.array_price.daily_price * this.state.days })
                        } else if (this.state.rental_type.value === "weekly") {
                            if (this.state.array_price.weekly_price === 0) {
                                this.setState({ old_price: this.state.array_price.daily_price * this.state.days, rental_price: this.state.array_price.daily_price * this.state.days })
                            } else {
                                if (this.state.days >= 7) {
                                    let price = 0;
                                    price += Number((this.state.days / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                    price += (this.state.days % 7) * this.state.array_price.daily_price;
                                    this.setState({ old_price: price, rental_price: price })
                                } else {
                                    this.setState({ old_price: this.state.array_price.weekly_price, rental_price: this.state.array_price.weekly_price })
                                }
                            }
                        } else if (this.state.rental_type.value === "monthly") {
                            if (this.state.array_price.monthly_price === 0) {
                                this.setState({ old_price: this.state.array_price.daily_price * this.state.days, rental_price: this.state.array_price.daily_price * this.state.days })
                            } else {
                                if (this.state.days > 30) {
                                    let price = 0;
                                    price += Number((this.state.days / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                    price += Number(((this.state.days % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                    price += ((this.state.days % 30) % 7) * this.state.array_price.daily_price;
                                    this.setState({ old_price: price, rental_price: price })
                                } else {
                                    this.setState({ old_price: this.state.array_price.monthly_price, rental_price: this.state.array_price.monthly_price })
                                }
                            }
                        }
                    }
                }, 10);
            }
        }
        this.setState({ loading: false });
    }
    GetListById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/cashier/detail/" + id, null);
        if (result && result.status) {
            let array = result.data.data.filter(e => e.event_type === 2 && e.admin_remark !== "แคชเชียร์ ยืนยันการชำระเงิน" && e.admin_remark !== "แคชเชียร์ ขยายสัญญาไม่อนุมัติ" && e.admin_remark !== "พนักงาน ขยายสัญญารอเงินมัดจำ" && e.admin_remark !== "พนักงาน ขยายสัญญาไม่อนุมัติ" && e.admin_remark !== "")
            this.setState({ data_detail: array, data_detail2: result.data.data })
            if (this.state.rental_status === 5 || this.state.rental_status === 12) {
                let item = result.data.data;
                item = item[item.length - 1];
                if (item.event_extend) {
                    this.setState({
                        return_date: item.event_extend.return_date,
                        return_time: item.event_extend.return_time,
                        return_date_new: item.event_extend.return_date_new,
                        return_time_new: item.event_extend.return_time_new,
                        bank_name: item.bank_name ? this.state.data_bank.filter(e => e.value === item.bank_name)[0] : "",
                        payment_date: item.payment_date,
                        payment_time: item.payment_time,
                        payment_type: item.payment_type === 1 ? { value: "1", label: "เงินสด" } :
                            item.payment_type === 2 ? { value: "2", label: "เงินโอน" } :
                                item.payment_type === 3 ? { value: "3", label: "บัตรเครดิต" } : null,
                        payment_picture: item.payment_picture,
                        total_pay: item.total_pay,
                        total_pay_transfer: item.total_pay_transfer,
                        total_pay_cradit: item.total_pay_cradit,
                        rental_price: item.total_price,
                        status_pay2: { value: "คิดเงิน", label: "คิดเงิน" },
                        remark: item.event_extend.remark,
                        discount_pay: item.event_extend.discount,
                    })
                    if (this.state.rental_status === 5) {
                        let days = days_between(
                            new Date(format_date(item.event_extend.return_date, "yyyy,mm,dd", "en")),
                            new Date(format_date(item.event_extend.return_date_new, "yyyy,mm,dd", "en")),
                            item.event_extend.return_time,
                            item.event_extend.return_time_new
                        )
                        let hours_price = 0;
                        let rental_price = 0;
                        if (days.hour === 0 && days.minute >= 0) {
                            //ไม่ต้องหัก 
                        } else if (days.hour === 1 && days.minute >= 0) {
                            if (this.state.array_price.daily_price < 400) {
                                hours_price = 50; //หัก 50 บาท
                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                hours_price = 100; //หัก 100 บาท
                            } else if (this.state.array_price.daily_price > 700) {
                                hours_price = 200; //หัก 200 บาท
                            }
                        } else if (days.hour === 2 && days.minute >= 0) {
                            if (this.state.array_price.daily_price < 400) {
                                hours_price = 100; //หัก 50 บาท
                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                hours_price = 200; //หัก 100 บาท
                            } else if (this.state.array_price.daily_price > 700) {
                                hours_price = 400; //หัก 200 บาท
                            }
                        } else if (days.hour === 3 && days.minute >= 0) {
                            if (this.state.array_price.daily_price < 400) {
                                hours_price = 150; //หัก 50 บาท
                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                hours_price = 300; //หัก 100 บาท
                            } else if (this.state.array_price.daily_price > 700) {
                                hours_price = 600; //หัก 200 บาท
                            }
                        } else if (days.hour >= 4) {
                            hours_price = this.state.array_price.daily_price;//หัก daily_price
                        }
                        setTimeout(() => {
                            if (this.state.selected_bike) {
                                if (this.state.rental_type.value === "daily") {
                                    rental_price = this.state.array_price.daily_price * days.day;
                                    rental_price += hours_price;
                                } else if (this.state.rental_type.value === "weekly") {
                                    if (this.state.array_price.weekly_price === 0) {
                                        rental_price = this.state.array_price.daily_price * days.day;
                                        rental_price += hours_price;
                                    } else {
                                        if (days.day >= 7) {
                                            rental_price += Number((days.day / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                            rental_price += (days.day % 7) * this.state.array_price.daily_price;
                                            if (days.day % 7 === 0) {
                                                rental_price += hours_price;
                                            }
                                        } else {
                                            rental_price = this.state.array_price.weekly_price;
                                        }
                                    }
                                } else if (this.state.rental_type.value === "monthly") {
                                    if (this.state.array_price.monthly_price === 0) {
                                        rental_price = this.state.array_price.daily_price * days.day;
                                        rental_price += hours_price;
                                    } else {
                                        if (days.day >= 30) {
                                            rental_price += Number((days.day / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                            rental_price += Number(((days.day % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                            rental_price += ((days.day % 30) % 7) * this.state.array_price.daily_price;
                                            if (days.day % 30 === 0) {
                                                rental_price += hours_price;
                                            }
                                        } else {
                                            rental_price = this.state.array_price.monthly_price;
                                        }
                                    }
                                }
                            }
                            this.setState({ rental_price: rental_price })
                        }, 10);
                    }
                }

            } else if (this.state.rental_status === 9) {
                let item = result.data.data;
                item = item[item.length - 1];
                this.setState({
                    admin_remark: item.admin_remark,
                    total_pay: item.total_pay,
                    total_pay_transfer: item.total_pay_transfer,
                    total_pay_cradit: item.total_pay_cradit,
                    payment_type: item.payment_type === 1 ? { value: "1", label: "เงินสด" } :
                        item.payment_type === 2 ? { value: "2", label: "เงินโอน" } :
                            item.payment_type === 3 ? { value: "3", label: "บัตรเครดิต" } : null,
                    payment_picture: item.payment_picture,
                    payment_date: item.payment_date,
                    payment_time: item.payment_time,
                    status_pay: { value: "ชำระ", label: "ชำระ" },
                    old_price: Number(item.admin_remark.split("||")[1])
                })
            }
            let event4 = result.data.data.filter(e => e.event_type === 4 && e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูล");
            this.setState({ service_charge_pay: total(event4, "total_price") })
            let event4_overdue = result.data.data.filter(e => e.event_type === 4 && e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูลค้างชำระ");
            this.setState({ service_charge_overdue: total(event4_overdue, "total_price") })
            this.setState({ change_location: result.data.data.filter(e => e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูล" || e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูลค้างชำระ") })

            this.setState({ change_date: result.data.data.filter(e => e.admin_remark === "พนักงาน เปลี่ยนวันเวลาคืนรถ") })
            setTimeout(() => {
                this.calculate();
                this.getDay();
            }, 10);
            let detail = this.state.detail;
            detail.transaction_list = result.data.data;
            this.setState({ detail: detail })
        }
        this.setState({ loading: false });
    }
    Payment = async () => {
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.bank_name === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกบัญชี");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_date === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่โอน");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_time === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาที่โอน");
            return;
        }


        if ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet) - Number(this.state.total_price) < 0) {
            alert("warning", "แจ้งเตือน", "เงินที่ชำระไม่เพียงพอ");
            return;
        }
        if (!this.state.payment_picture && (Number(this.state.total_pay_transfer) !== 0)) {
            alert("warning", "แจ้งเตือน", "กรุณาอัพโหลดภาพหลักฐาน");
            return;
        }

        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let return_point = 0;
        if (Number(this.state.rental_price) - Number(point * 20) - Number(this.state.sk_wallet) >= 300) {
            let price_point = Number(this.state.rental_price) - Number(point * 20) - Number(this.state.sk_wallet);
            price_point = price_point - (price_point % 300);
            return_point = price_point / 300;
        }
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 1,
            // ชำระ/ตอนคืน
            refund_cost: Number(this.state.refund_cost), // ยอดเงินคืนก่อนกำหนด
            other_expenses: Number(this.state.other_expenses), // ค่าใช้จ่ายอื่นๆ
            fuel_cost: Number(this.state.fuel_cost), // ค่าน้ำมันรถ
            damage_cost: Number(this.state.damage_cost), // ค่าความเสียหาย
            service_charge: Number(this.state.service_charge), // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null, // วันคืนรถเดิม
            return_time: this.state.return_time ? this.state.return_time : null, // เวลาคืนรถเดิม
            return_date_new: this.state.return_date_new ? format_date(this.state.return_date_new, "yyyy-mm-dd", "en") : null, // วันคืนรถใหม่
            return_time_new: this.state.return_time_new ? this.state.return_time_new : null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: Number(this.state.overdue_booking),
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            payment_type: this.state.payment_type ? Number(this.state.payment_type.value) : 0,
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_picture: this.state.payment_picture,
            discount: Number(this.state.discount_pay),
            return_points: return_point, // แต้มคืนให้ลูกค้า
            spend_points: Number(point), // แต้มที่ใช้
            change_points: Number(point * 20), // แต้มแลกส่วนลด
            total_price: Number(this.state.total_price),
            total_pay: Number(this.state.total_pay),
            total_pay_transfer: Number(this.state.total_pay_transfer),
            total_pay_cradit: Number(this.state.total_pay_cradit),
            change: (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet) - Number(this.state.total_price),
            event_type: 1,
            payment_list: [],
            return_wallet: 0,
            spend_wallet: Number(this.state.sk_wallet),

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "พนักงาน ชำระเงิน",
            admin_name: getStorage("user").full_name,
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    // ขยายสัญญา อนุมัติ ชำระ
    Scale01 = async () => {
        if (!this.state.return_date_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่คืนรถใหม่");
            return;
        }
        if (!this.state.return_time_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาคืนรถใหม่");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.bank_name === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกบัญชี");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_date === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่โอน");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_time === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาที่โอน");
            return;
        }
        if (!this.state.status_pay2) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกคิดค่าขยายสัญญา");
            return;
        }


        if (this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน") {
            if (((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet))) < 0) {
                alert("warning", "แจ้งเตือน", "เงินที่ชำระไม่เพียงพอ");
                return;
            }
        }
        if (!this.state.payment_picture && Number(this.state.total_pay_transfer) !== 0) {
            alert("warning", "แจ้งเตือน", "กรุณาอัพโหลดภาพหลักฐาน");
            return;
        }

        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 1,
            // ชำระ/ตอนคืน
            refund_cost: Number(this.state.refund_cost), // ยอดเงินคืนก่อนกำหนด
            other_expenses: Number(this.state.other_expenses), // ค่าใช้จ่ายอื่นๆ
            fuel_cost: Number(this.state.fuel_cost), // ค่าน้ำมันรถ
            damage_cost: Number(this.state.damage_cost), // ค่าความเสียหาย
            service_charge: Number(this.state.service_charge), // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null, // วันคืนรถเดิม
            return_time: this.state.return_time ? this.state.return_time : null, // เวลาคืนรถเดิม
            return_date_new: this.state.return_date_new ? format_date(this.state.return_date_new, "yyyy-mm-dd", "en") : null, // วันคืนรถใหม่
            return_time_new: this.state.return_time_new ? this.state.return_time_new : null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            discount: Number(this.state.discount_pay),
            return_points: 0, // แต้มคืนให้ลูกค้า
            total_price: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.rental_price) - Number(this.state.discount_pay) : 0,
            event_type: 2,
            payment_list: [],
            return_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "พนักงาน ขยายสัญญาชำระ",
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_type: this.state.payment_type ? Number(this.state.payment_type.value) : 0,
            payment_picture: this.state.payment_picture,
            total_pay: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.total_pay) : 0,
            total_pay_transfer: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.total_pay_transfer) : 0,
            total_pay_cradit: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.total_pay_cradit) : 0,
            spend_points: Number(point),
            change_points: Number(point * 20),
            change: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet) - Number(this.state.discount_pay))) < 0 ? 0 : (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet) - Number(this.state.discount_pay)) : 0,
            spend_wallet: Number(this.state.sk_wallet),
            admin_name: getStorage("user").full_name,
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    // ขยายสัญญา อนุมัติ ไม่ชำระ
    Scale02 = async () => {
        if (!this.state.return_date_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่คืนรถใหม่");
            return;
        }
        if (!this.state.return_time_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาคืนรถใหม่");
            return;
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 7,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null, // วันคืนรถเดิม
            return_time: this.state.return_time ? this.state.return_time : null, // เวลาคืนรถเดิม
            return_date_new: this.state.return_date_new ? format_date(this.state.return_date_new, "yyyy-mm-dd", "en") : null, // วันคืนรถใหม่
            return_time_new: this.state.return_time_new ? this.state.return_time_new : null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            discount: Number(this.state.discount_pay),
            return_points: 0, // แต้มคืนให้ลูกค้า
            total_price: Number(this.state.rental_price) - Number(this.state.discount_pay),
            event_type: 2,
            payment_list: [],
            return_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "พนักงาน ขยายสัญญาไม่ชำระ",
            bank_name: "",
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_type: 0,
            payment_picture: [],
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            spend_points: 0,
            change_points: 0,
            change: 0,
            spend_wallet: 0,
            admin_name: getStorage("user").full_name,
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    // ขยายสัญญา ไม่อนุมัติ
    Scale03 = async () => {
        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 3,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null, // วันคืนรถเดิม
            return_time: this.state.return_time ? this.state.return_time : null, // เวลาคืนรถเดิม
            return_date_new: null, // วันคืนรถใหม่
            return_time_new: null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: 0, // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            discount: 0,
            return_points: 0, // แต้มคืนให้ลูกค้า
            total_price: 0,
            event_type: 2,
            payment_list: [],
            return_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "พนักงาน ขยายสัญญาไม่อนุมัติ",
            bank_name: "",
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_type: 0,
            payment_picture: [],
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            spend_points: 0,
            change_points: 0,
            change: 0,
            spend_wallet: 0,
            admin_name: getStorage("user").full_name,
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    // ขยายสัญญา รอเงินมัดจำ
    Scale04 = async () => {
        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 12,
            // ชำระ/ตอนคืน
            refund_cost: Number(this.state.refund_cost), // ยอดเงินคืนก่อนกำหนด
            other_expenses: Number(this.state.other_expenses), // ค่าใช้จ่ายอื่นๆ
            fuel_cost: Number(this.state.fuel_cost), // ค่าน้ำมันรถ
            damage_cost: Number(this.state.damage_cost), // ค่าความเสียหาย
            service_charge: Number(this.state.service_charge), // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null, // วันคืนรถเดิม
            return_time: this.state.return_time ? this.state.return_time : null, // เวลาคืนรถเดิม
            return_date_new: this.state.return_date_new ? format_date(this.state.return_date_new, "yyyy-mm-dd", "en") : null, // วันคืนรถใหม่
            return_time_new: this.state.return_time_new ? this.state.return_time_new : null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            discount: Number(this.state.discount_pay),
            return_points: 0, // แต้มคืนให้ลูกค้า
            total_price: Number(this.state.rental_price),
            event_type: 2,
            payment_list: [],
            return_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "พนักงาน ขยายสัญญารอเงินมัดจำ",
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_type: this.state.payment_type ? Number(this.state.payment_type.value) : 0,
            payment_picture: this.state.payment_picture,
            total_pay: Number(this.state.total_pay),
            total_pay_transfer: Number(this.state.total_pay_transfer),
            total_pay_cradit: Number(this.state.total_pay_cradit),
            spend_points: Number(point),
            change_points: Number(point * 20),
            change: ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet))) < 0 ? 0 : (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet)),
            spend_wallet: Number(this.state.sk_wallet),
            admin_name: getStorage("user").full_name,
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    ReturnCar = async () => {
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.bank_name === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกบัญชี");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_date === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่โอน");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_time === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาที่โอน");
            return;
        }
        if ((
            (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (
                Number(this.state.fuel_cost) +
                Number(this.state.damage_cost) +
                Number(this.state.service_charge) +
                Number(this.state.service_charge_overdue) +
                Number(this.state.other_expenses) -
                Number(this.state.discount_pay) -
                Number(this.state.guarantee_amount) +
                Number(this.state.rental_price_overdue) +
                (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0) -
                Number(this.state.discount_pay) -
                (Number(this.state.point) * 20) -
                Number(this.state.sk_wallet)
            )
        ) < 0) {
            alert("warning", "แจ้งเตือน", "เงินที่ชำระไม่เพียงพอ");
            return;
        }

        if (!this.state.payment_picture && (Number(this.state.total_pay_transfer) !== 0)) {
            alert("warning", "แจ้งเตือน", "กรุณาอัพโหลดภาพหลักฐาน");
            return;
        }
        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 6,
            // ชำระ/ตอนคืน
            refund_cost: Number(this.state.refund_cost), // ยอดเงินคืนก่อนกำหนด
            other_expenses: Number(this.state.other_expenses), // ค่าใช้จ่ายอื่นๆ
            fuel_cost: Number(this.state.fuel_cost), // ค่าน้ำมันรถ
            damage_cost: Number(this.state.damage_cost), // ค่าความเสียหาย
            service_charge: Number(this.state.service_charge) + Number(this.state.service_charge_overdue), // ค่าบริการรับส่ง
            rental_price_overdue: Number(this.state.rental_price_overdue),//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null, // วันคืนรถเดิม
            return_time: this.state.return_time ? this.state.return_time : null, // เวลาคืนรถเดิม
            return_date_new: this.state.return_date_new ? format_date(this.state.return_date_new, "yyyy-mm-dd", "en") : null, // วันคืนรถใหม่
            return_time_new: this.state.return_time_new ? this.state.return_time_new : null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: 0, // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            bank_name: this.state.bank_name ? this.state.bank_name.value : null,
            payment_type: this.state.payment_type ? Number(this.state.payment_type.value) : 0,
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_picture: this.state.payment_picture,
            discount: Number(this.state.discount_pay),
            return_points: 0, // แต้มคืนให้ลูกค้า
            spend_points: Number(point), // แต้มที่ใช้
            change_points: Number(point * 20), // แต้มแลกส่วนลด
            total_price: (Number(this.state.fuel_cost) +
                Number(this.state.damage_cost) +
                Number(this.state.service_charge) +
                Number(this.state.service_charge_overdue) +
                Number(this.state.other_expenses) -
                Number(this.state.discount) -
                Number(this.state.guarantee_amount) +
                Number(this.state.rental_price_overdue) -
                Number(this.state.discount_pay) +
                (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
            ) < 0 ? 0 :
                (Number(this.state.fuel_cost) +
                    Number(this.state.damage_cost) +
                    Number(this.state.service_charge) +
                    Number(this.state.service_charge_overdue) +
                    Number(this.state.other_expenses) -
                    Number(this.state.discount) -
                    Number(this.state.guarantee_amount) +
                    Number(this.state.rental_price_overdue) -
                    Number(this.state.discount_pay) +
                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)),
            total_pay: Number(this.state.total_pay),
            total_pay_transfer: Number(this.state.total_pay_transfer),
            total_pay_cradit: Number(this.state.total_pay_cradit),
            change: ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (
                Number(this.state.fuel_cost) +
                Number(this.state.damage_cost) +
                Number(this.state.service_charge) +
                Number(this.state.service_charge_overdue) +
                Number(this.state.other_expenses) -
                Number(this.state.discount) -
                Number(this.state.guarantee_amount) +
                Number(this.state.rental_price_overdue) -
                Number(this.state.discount_pay)) +
                (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)) < 0 ? 0 :
                (
                    (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (
                        Number(this.state.fuel_cost) +
                        Number(this.state.damage_cost) +
                        Number(this.state.service_charge) +
                        Number(this.state.service_charge_overdue) +
                        Number(this.state.other_expenses) -
                        Number(this.state.discount) -
                        Number(this.state.guarantee_amount) +
                        Number(this.state.rental_price_overdue) +
                        (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0) -
                        Number(this.state.discount_pay)) -
                    (Number(this.state.point) * 20) -
                    Number(this.state.sk_wallet)
                ),
            event_type: 3,
            payment_list: [],
            return_wallet: 0,
            spend_wallet: Number(this.state.sk_wallet),

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "พนักงาน คืนรถ",
            admin_name: getStorage("user").full_name,
            admin_return_name: getStorage("user").full_name,
            return_remark: this.state.return_remark,
            last_return_date: format_date(new Date(), "yyyy-mm-dd hh:mm:ss", "en")
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    Location = async () => {
        if (this.state.payment_type && this.state.payment_type.value === "2" && this.state.bank_name === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกบัญชี");
            return;
        }
        if (this.state.payment_type && this.state.payment_type.value === "2" && this.state.payment_date === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่โอน");
            return;
        }
        if (this.state.payment_type && this.state.payment_type.value === "2" && this.state.payment_time === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาที่โอน");
            return;
        }
        if (!this.state.payment_picture && (this.state.payment_type && this.state.payment_type.value === "2")) {
            alert("warning", "แจ้งเตือน", "กรุณาอัพโหลดภาพหลักฐาน");
            return;
        }



        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        let total_price = 0;
        if (this.state.status_pay && this.state.status_pay.value === "คิดส่วนต่าง") {
            total_price += this.state.rental_price - this.state.old_price;
        }
        total_price += Number(this.state.service_charge);

        if (this.state.status_pay2 && this.state.status_pay2.value === "ชำระ") {
            if (((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet)) < total_price) {
                alert("warning", "แจ้งเตือน", "ยอดเงินที่ชำระไม่เพียงพอ");
                return;
            }
            if ((this.state.point || this.state.sk_wallet) && ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet)) < total_price) {
                alert("warning", "แจ้งเตือน", "ยอดเงินที่ชำระไม่เพียงพอ");
                return;
            }
        }
        // return
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: total_price === 0 ? this.state.rental_status : 1,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: Number(this.state.service_charge), // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null, // วันคืนรถเดิม
            return_time: this.state.return_time ? this.state.return_time : null, // เวลาคืนรถเดิม
            return_date_new: null, // วันคืนรถใหม่
            return_time_new: null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: JSON.stringify(this.state.old_array_price),
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            payment_type: this.state.payment_type ? Number(this.state.payment_type.value) : 0,
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_picture: this.state.payment_picture,
            discount: 0,
            return_points: 0, // แต้มคืนให้ลูกค้า
            spend_points: Number(point), // แต้มที่ใช้
            change_points: Number(point * 20), // แต้มแลกส่วนลด
            total_price: total_price,
            total_pay: Number(this.state.total_pay),
            total_pay_transfer: Number(this.state.total_pay_transfer),
            total_pay_cradit: Number(this.state.total_pay_cradit),
            change: ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet)) - total_price,
            event_type: 4,
            payment_list: [],
            return_wallet: 0,
            spend_wallet: Number(this.state.sk_wallet),

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: this.state.status_pay2 && this.state.status_pay2.value === "ไม่ชำระ" ? "พนักงาน เปลี่ยนแปลงข้อมูลค้างชำระ" : "พนักงาน เปลี่ยนแปลงข้อมูล",
            admin_name: getStorage("user").full_name,
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        } 
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    Delete = async () => {
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 11,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null, // วันคืนรถเดิม
            return_time: this.state.return_time ? this.state.return_time : null, // เวลาคืนรถเดิม
            return_date_new: null, // วันคืนรถใหม่
            return_time_new: null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: 0, // ราคาเช่ารถ
            remark: "",
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            bank_name: "",
            payment_type: 0,
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_picture: [],
            discount: 0,
            return_points: 0, // แต้มคืนให้ลูกค้า
            spend_points: 0, // แต้มที่ใช้
            change_points: 0, // แต้มแลกส่วนลด
            total_price: 0,
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            change: 0,
            event_type: 1,
            payment_list: [],
            return_wallet: 0,
            spend_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "พนักงาน ยกเลิกสัญญา",
            admin_name: getStorage("user").full_name,
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    UpdateCar = async () => {
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            detail_picture: this.state.detail_picture.filter(e => e !== ""),
        }
        let result = await PUT(tokens, "v1/TH/rental-manage/update", data);
        this.setState({ loading: false });

    }
    import_file2 = (e, type) => {
        if (this.state.detail_picture.filter(item => item.length !== 0).length + e.target.files.length > 10) {
            alert("warning", "แจ้งเตือน", "สามารถอัพโหลดรูปได้สูงสุด 10 รูป");
            return;
        }
        if (e.target.files.length === 1) {
            this.changePhoto2(e.target.files[0], type)
            document.getElementById("file_sub").value = "";
            document.getElementById("file_sub").file = null;
        } else {
            this.multiple_file2(0, e.target.files.length, e.target.files);
        }
    }
    multiple_file2 = async (index, max, file) => {
        await this.changePhoto2(file[index], "sub");
        if (index + 1 < max) {
            setTimeout(() => {
                this.multiple_file2(index + 1, max, file);
            }, 50);
        }
    }
    changePhoto2 = async (file, type) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => { }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    let detail_picture = this.state.detail_picture;
                    let index = detail_picture.findIndex(item => item === "");
                    if (index !== -1) {
                        detail_picture[index] = uri.split(",")[1];
                    }
                    this.setState({
                        detail_picture: detail_picture
                    })
                },
                "base64"
            );
        });
    }

    import_file3 = (e, type) => { 
        if (e.target.files.length === 1) {
            this.changePhoto3(e.target.files[0], type)
            document.getElementById("file_sub2").value = "";
            document.getElementById("file_sub2").file = null;
        } else {
            this.multiple_file3(0, e.target.files.length, e.target.files);
        }
    }
    multiple_file3 = async (index, max, file) => {
        await this.changePhoto3(file[index], "sub2");
        if (index + 1 < max) {
            setTimeout(() => {
                this.multiple_file3(index + 1, max, file);
            }, 50);
        }
    }
    changePhoto3 = async (file, type) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => { }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    let payment_picture = this.state.payment_picture;
                    payment_picture.push(
                        {
                            picture_id: "",
                            picture: uri.split(",")[1]
                        }
                    )
                    this.setState({
                        payment_picture: payment_picture
                    })
                },
                "base64"
            );
        });
    }
    delete_image = (picture) => {
        let data = this.state.detail_picture;
        let index = data.findIndex(item => item === picture);
        data[index] = "";
        let array = [];
        for (let item of data) {
            if (item !== "") {
                array.push(item);
            }
        }
        array = [...array, "", "", "", "", "", "", "", "", "", ""];
        array = array.filter((item, index) => index < 10);
        setTimeout(() => {
            this.setState({ detail_picture: data });
        }, 100);
    }
    changeDateTime = async () => {
        if (!this.state.return_date_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่คืนรถใหม่");
            return;
        }
        if (!this.state.return_time_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาคืนรถใหม่");
            return;
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 3,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม 
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.return_date + "T" + this.state.return_time,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            discount: 0,
            return_points: 0, // แต้มคืนให้ลูกค้า
            total_price: 0,
            event_type: 2,
            payment_list: [],
            return_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "พนักงาน เปลี่ยนวันเวลาคืนรถ",
            bank_name: "",
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_type: 0,
            payment_picture: [],
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            spend_points: 0,
            change_points: 0,
            change: 0,
            spend_wallet: 0,
            return_date_new: this.state.return_date_new,
            return_time_new: this.state.return_time_new,
            admin_name: getStorage("user").full_name,
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    calculate = () => {
        let total_rent_price = this.state.rental_price + (this.state.data_detail2 ? total(this.state.data_detail2.filter(e => e.admin_remark === "แคชเชียร์ ขยายสัญญาชำระ" || (e.admin_remark === "แคชเชียร์ ยืนยันการชำระเงิน" && e.event_type === 2)), "total_pay") + total(this.state.data_detail2.filter(e => e.admin_remark === "แคชเชียร์ ขยายสัญญาชำระ" || (e.admin_remark === "แคชเชียร์ ยืนยันการชำระเงิน" && e.event_type === 2)), "total_pay_transfer") + total(this.state.data_detail2.filter(e => e.admin_remark === "แคชเชียร์ ขยายสัญญาชำระ" || (e.admin_remark === "แคชเชียร์ ยืนยันการชำระเงิน" && e.event_type === 2)), "total_pay_cradit") : 0);
        let item = {
            pick_up_date: this.state.pick_up_date + "T" + this.state.pick_up_time,
            return_date: this.state.return_date + "T" + this.state.return_time,
            daily_price: Number(this.state.array_price.daily_price),
            weekly_price: Number(this.state.array_price.weekly_price),
            monthly_price: Number(this.state.array_price.monthly_price),
            rental_type: this.state.rental_type.value
        }
        if (this.state.change_date.length !== 0) {
            item.return_date = this.state.change_date[0].event_extend.remark;
        }

        if (this.state.change_location.length !== 0) {
            item.daily_price = Number(JSON.parse(this.state.change_location[0].event_return.remark).daily_price);
            item.weekly_price = Number(JSON.parse(this.state.change_location[0].event_return.remark).weekly_price);
            item.monthly_price = Number(JSON.parse(this.state.change_location[0].event_return.remark).monthly_price);
        }

        let date = new Date();
        let total_day = days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en")), format_date(item.pick_up_date, "hh:mm"), format_date(item.return_date, "hh:mm"));
        let rental_day = days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), format_date(item.pick_up_date, "hh:mm"), format_date(date, "hh:mm"));
        let left_day = days_between_return_car(new Date(format_date(date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en")), format_date(date, "hh:mm"), format_date(item.return_date, "hh:mm"));
        let left_day2 = null;
        if (Number(this.state.pick_up_time.slice(0, 5).replaceAll(":", "")) < Number(this.state.return_time.slice(0, 5).replaceAll(":", ""))) {
            left_day2 = days_between_return_car(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), format_date(item.return_date, "hh:mm"), format_date(date, "hh:mm"))
        } else {
            left_day2 = days_between_return_car(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), format_date(item.pick_up_date, "hh:mm"), format_date(date, "hh:mm"))
        }

        if (left_day.type === "ก่อนกำหนด") {
            let total = 0;
            let discount = 0;

            if (rental_day.day === 0) {
                discount = item.daily_price;
            } else {
                // การหักเงิน
                if (rental_day.hour === 0 && rental_day.minute >= 0) {
                    //ไม่ต้องหัก 
                } else if (rental_day.hour === 1 && rental_day.minute >= 0) {
                    discount = 50; //หัก 50 บาท
                } else if (rental_day.hour === 2 && rental_day.minute >= 0) {
                    discount = 100; //หัก 100 บาท
                } else if (rental_day.hour === 3 && rental_day.minute >= 0) {
                    discount = 150; //หัก 150 บาท
                } else if (rental_day.hour >= 4) {
                    discount = item.daily_price;//หัก daily_price
                }
            }
            if (item.rental_type === "daily") {
                total = item.daily_price * rental_day.day;
            } else if (item.rental_type === "weekly") {
                if (item.weekly_price === 0) {
                    total = item.daily_price * rental_day.day;
                } else {
                    total += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                    if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                        total += (rental_day.day % 7) * item.daily_price;
                    } else {
                        total += item.weekly_price;
                    }
                }
            } else if (item.rental_type === "monthly") {
                if (item.monthly_price === 0 && item.weekly_price === 0) {
                    total = item.daily_price * rental_day.day;
                } else if (item.monthly_price === 0 && item.weekly_price !== 0) {
                    total += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                    if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                        total += (rental_day.day % 7) * item.daily_price;
                    } else {
                        total += item.weekly_price;
                    }
                } else {
                    if (((rental_day.day % 30) % 7) * item.daily_price < item.weekly_price) {
                        total += ((rental_day.day % 30) % 7) * item.daily_price;
                    } else {
                        total += item.weekly_price;
                    }
                    if ((Number(((rental_day.day % 30) / 7).toString().split(".")[0]) * item.weekly_price) + total < item.monthly_price) {
                        total += Number(((rental_day.day % 30) / 7).toString().split(".")[0]) * item.weekly_price;
                    } else {
                        total = item.monthly_price;
                    }
                    total += Number((rental_day.day / 30).toString().split(".")[0]) * item.monthly_price;
                }
            }
            this.setState({ refund_cost: total_rent_price - (total + discount) > 0 ? total_rent_price - (total + discount) : 0 })
        } else if (left_day.type === "เกินกำหนด") {
            let total = 0;
            let discount = 0;
            let rental_price_overdue = 0;

            if (left_day2.hour === 0 && left_day2.minute >= 0) {
                //ไม่ต้องหัก
            } else if (left_day2.hour === 1 && left_day2.minute >= 0) {
                if (item.daily_price < 400) {
                    discount = 50; //หัก 50 บาท
                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                    discount = 100; //หัก 100 บาท
                } else if (item.daily_price > 700) {
                    discount = 200; //หัก 200 บาท
                }
            } else if (left_day2.hour === 2 && left_day2.minute >= 0) {
                if (item.daily_price < 400) {
                    discount = 100; //หัก 50 บาท
                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                    discount = 200; //หัก 100 บาท
                } else if (item.daily_price > 700) {
                    discount = 400; //หัก 200 บาท
                }
            } else if (left_day2.hour === 3 && left_day2.minute >= 0) {
                if (item.daily_price < 400) {
                    discount = 150; //หัก 50 บาท
                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                    discount = 300; //หัก 100 บาท
                } else if (item.daily_price > 700) {
                    discount = 600; //หัก 200 บาท
                }
            } else if (left_day2.hour >= 4) {
                discount = item.daily_price;//หัก daily_price
            }
            if (item.rental_type === "daily") {
                total = item.daily_price * left_day2.day;
                if (format_date(new Date(), "yyyy-mm-dd") === format_date(item.return_date, "yyyy-mm-dd")) {
                    if (Number(this.state.return_time.slice(0, 5).replaceAll(":", "")) < Number(this.state.pick_up_time.slice(0, 5).replaceAll(":", "")) && Number(format_date(new Date(), "hh:mm").replaceAll(":", "")) < Number(this.state.pick_up_time.slice(0, 5).replaceAll(":", ""))) {
                        discount = 0;
                    }
                }
            } else if (item.rental_type === "weekly") {
                if (item.weekly_price === 0) {
                    total = item.daily_price * left_day2.day;
                } else {
                    if ((total_day.day % 7) < 7 && (total_day.day % 7) !== 0) {
                        let max_day = new Date(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).setDate(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).getDate() + (7 - (total_day.day % 7))));
                        if (max_day.getTime() < new Date().getTime()) {
                            let left_day3 = days_between_return_car(new Date(format_date(max_day, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), this.state.return_time.slice(0, 5), format_date(date, "hh:mm"));
                            total += Number((left_day3.day / 7).toString().split(".")[0]) * item.weekly_price;
                            if ((left_day3.day % 7) * item.daily_price < item.weekly_price) {
                                total += (left_day3.day % 7) * item.daily_price;
                            } else {
                                total += item.weekly_price;
                            }
                        } else { 
                            total = 0;
                            rental_price_overdue += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                            if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                                rental_price_overdue += (rental_day.day % 7) * item.daily_price;
                            } else {
                                rental_price_overdue += item.weekly_price;
                            }
                            this.setState({ refund_cost: total_rent_price - rental_price_overdue - discount < 0 ? 0 : total_rent_price - rental_price_overdue - discount })
                            return 
                        }
                    } else {
                        total += Number((left_day2.day / 7).toString().split(".")[0]) * item.weekly_price;
                        if ((left_day2.day % 7) * item.daily_price < item.weekly_price) {
                            total += (left_day2.day % 7) * item.daily_price;
                        } else {
                            total += item.weekly_price;
                        }
                    }
                }
            } else if (item.rental_type === "monthly") {
                if (item.monthly_price === 0 && item.weekly_price === 0) {
                    total = item.daily_price * left_day2.day;
                } else if (item.monthly_price === 0 && item.weekly_price !== 0) {
                    if ((total_day.day % 7) < 7 && (total_day.day % 7) !== 0) {
                        let max_day = new Date(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).setDate(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).getDate() + (7 - (total_day.day % 7))));
                        if (max_day.getTime() < new Date().getTime()) {
                            let left_day3 = days_between_return_car(new Date(format_date(max_day, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), this.state.return_time.slice(0, 5), format_date(date, "hh:mm"));
                            total += Number((left_day3.day / 7).toString().split(".")[0]) * item.weekly_price;
                            if ((left_day3.day % 7) * item.daily_price < item.weekly_price) {
                                total += (left_day3.day % 7) * item.daily_price;
                            } else {
                                total += item.weekly_price;
                            }
                        } else { 
                            total = 0;
                            rental_price_overdue += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                            if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                                rental_price_overdue += (rental_day.day % 7) * item.daily_price;
                            } else {
                                rental_price_overdue += item.weekly_price;
                            }
                            this.setState({ refund_cost: total_rent_price - rental_price_overdue - discount < 0 ? 0 : total_rent_price - rental_price_overdue - discount })
                            return 
                        }
                    } else {
                        total += Number((left_day2.day / 7).toString().split(".")[0]) * item.weekly_price;
                        if ((left_day2.day % 7) * item.daily_price < item.weekly_price) {
                            total += (left_day2.day % 7) * item.daily_price;
                        } else {
                            total += item.weekly_price;
                        }
                    }
                } else {
                    // ต้องทำ********************************
                    if ((total_day.day % 30) < 30 && (total_day.day % 30) !== 0) {
                        let max_day = new Date(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).setDate(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).getDate() + (7 - (total_day.day % 7))));
                        // if (max_day.getDate() < new Date().getDate()) {
                        //     let left_day3 = days_between_return_car(new Date(format_date(max_day, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), this.state.return_time.slice(0, 5), format_date(date, "hh:mm"));

                        //     total += Number((left_day3.day / 7).toString().split(".")[0]) * item.weekly_price;
                        //     if ((left_day3.day % 7) * item.daily_price < item.weekly_price) {
                        //         total += (left_day3.day % 7) * item.daily_price;
                        //     } else {
                        //         total += item.weekly_price;
                        //     }
                        // } else {
                        //     total = 0;
                        //     rental_price_overdue += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                        //     if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                        //         rental_price_overdue += (rental_day.day % 7) * item.daily_price;
                        //     } else {
                        //         rental_price_overdue += item.weekly_price;
                        //     }
                        //     this.setState({ refund_cost: total_rent_price - rental_price_overdue < 0 ? 0 : total_rent_price - rental_price_overdue })


                        // }
                    } else {
                        if (((left_day2.day % 30) % 7) * item.daily_price < item.weekly_price) {
                            total += ((left_day2.day % 30) % 7) * item.daily_price;
                        } else {
                            total += item.weekly_price;
                        }
                        if ((Number(((left_day2.day % 30) / 7).toString().split(".")[0]) * item.weekly_price) + total < item.monthly_price) {
                            total += Number(((left_day2.day % 30) / 7).toString().split(".")[0]) * item.weekly_price;
                        } else {
                            total = item.monthly_price;
                        }
                        total += Number((left_day2.day / 30).toString().split(".")[0]) * item.monthly_price;
                    }
                }
            }
            this.setState({ rental_price_overdue: (total + discount) > 0 ? (total + discount) : 0 })
        }
    }
    getDay = () => {
        let days = days_between(
            new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
            this.state.pick_up_time,
            this.state.return_time
        )
        this.setState({
            days: days.day,
            hours: days.hour,
            minutes: days.minute
        });
    }
    clear_form() {
        this.setState({
            modal_update: false,
            modal_payment: false,
            modal_scale: false,
            modal_return: false,
            modal_print: false,
            modal_detail: false,
            modal_change: false,
            modal_delete: false,

            page: 1,
            loading: false,

            filter_search: "",

            tab: "สัญญาการจอง",

            // การจอง Booking
            modal_create_booking: false,
            modal_update_booking: false,
            modal_delete_booking: false,
            modal_detail_booking: false,
            modal_car_booking: false,
            modal_promise_booking: false,

            tab: "สัญญาการจอง",
            product_index: -1,
            type: "",
            update_type: "",

            id: "",
            id_card: "",
            full_name: "",
            email: "",
            phone: "",
            brand_id: "",
            model_id: "",
            pick_up_date: new Date(),
            pick_up_time: format_date(new Date(), "hh:mm"),
            pick_up_location: "",
            return_date: new Date(new Date().setDate(new Date().getDate() + 1)),
            return_time: format_date(new Date(), "hh:mm"),
            return_location: "",
            daily_price: "",
            deposit_pay: "",
            payment_date: "",
            payment_time: "",
            payment_picture: [],
            booking_status: "",
            deposit_price: "",
            booking_price: "",
            traffic_ticket_price: 0,
            booking_number: "",

            days: 0,

            search_bike: "",

            selected_bike: null,

            payment_list: [],

            show_image: "",
            modal_picture_booking: false,

            // การเช่า Rent
            modal_update_rent: false,
            modal_payment_rent: false,
            modal_scale_rent: false,
            modal_return_rent: false,
            modal_print_rent: false,
            modal_detail_rent: false,
            modal_change_rent: false,
            modal_delete_rent: false,

            page: 1,
            loading: false,

            filter_search: "",
            filter_rental_status: "",
            filter_date: "",

            data: null,
            data_bank: null,
            data_detail: null,

            tab: "สัญญาการจอง",
            status_pay: "",
            rental_id: "",
            rental_number: "",
            address: "",
            birthday: null,
            district: "",
            email: "",
            full_name: "",
            id_card: "",
            is_active: true,
            member_code: "",
            member_id: "",
            member_status: 1,
            phone: "",
            picture: "",
            province: "",
            sub_district: "",
            username: "",
            verify_status: true,
            zip_code: "",
            picture: "",

            facebook: "",
            id_line: "",
            nationality: "",
            card_issue: "",
            date_issue: "",
            date_expire: "",
            temporary_accommodation: "",

            pick_up_date: new Date(),
            pick_up_time: "",
            pick_up_location: "",
            return_date: new Date(),
            return_time: "",
            return_location: "",

            rental_type: "",
            mileage: 0,
            oil_volume: 0,
            rental_price: 0,
            guarantee_type: 1,
            guarantee_amount: 0,
            equipment_fee: 0,
            discount_price: 0,
            delivery_price: 0,
            recommen_id: "",
            total_price: 0,
            signature: "",

            total_price_equipment: 0,
            total_qty_equipment: 0,

            detail_picture: ["", "", "", "", "", "", "", "", "", ""],

            selected_bike: null,

            search_bike: "",
            rental_status: "",

            days: 0,

            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: null, // วันคืนรถเดิม
            return_time: null, // เวลาคืนรถเดิม
            return_date_new: null, // วันคืนรถใหม่
            return_time_new: null, // เวลาคืนรถใหม่
            rental_type: "daily", // ประเภทการเช่า
            rental_price: 0, // ราคาเช่ารถ
            remark: "",
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            bank_name: "",
            payment_type: null,
            payment_date: "",
            payment_time: "",
            payment_picture: [],
            discount: 0,
            discount_pay: 0,
            spend_points: 0, // แต้มที่ใช้
            change_points: 0, // แต้มแลกส่วนลด
            total_price: 0,
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            overdue_booking: 0,
            change: 0,
            event_type: 1,

            refund_cost: 0,

            use_point: false,
            point: 0,

            use_sk_wallet: false,
            sk_wallet: 0,

            array_price: {
                daily_price: 0,
                weekly_price: 0,
                monthly_price: 0
            },
            wallet: null
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="แดชบอร์ด" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>แดชบอร์ด</b></h4>
                            <label className="mb-2"> วันที่ ณ ปัจจุบัน {format_date(new Date(), "dd/mm/yyyy เวลา hh:mm น.")}</label>
                        </div>
                        <div className="row">
                            {/* จำนวนรถคงเหลือ */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
                                <div className="card bg-success border-0 px-1 py-2 w-100">
                                    <div className="d-flex row mx-0 justify-content-between">
                                        <div className="px-2" style={{ width: 155 }}>
                                            <h5 style={{ width: 155 }} className="text-white">{this.state.balance_bike ? this.state.balance_bike : "0"}</h5>
                                            <h6 className="text-white">จำนวนรถคงเหลือ</h6>
                                        </div>
                                        <label className="icon text-white text-42">{"\uf21c"}</label>
                                    </div>
                                </div>
                            </div>
                            {/* รถที่ปล่อยเช่า */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
                                <div className="card bg-purple border-0 px-1 py-2 w-100">
                                    <div className="d-flex row mx-0 justify-content-between">
                                        <div className="px-2" style={{ width: 155 }}>
                                            <h5 style={{ width: 155 }} className="text-white">{this.state.total_rent ? this.state.total_rent : "0"}</h5>
                                            <h6 className="text-white">รถที่ปล่อยเช่า</h6>
                                        </div>
                                        <label className="icon text-white text-42">{"\uf21c"}</label>
                                    </div>
                                </div>
                            </div>
                            {/* จำนวนรถที่จอง */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
                                <div className="card bg-primary border-0 px-1 py-2 w-100">
                                    <div className="d-flex row mx-0 justify-content-between">
                                        <div className="px-2" style={{ width: 155 }}>
                                            <h5 style={{ width: 155 }} className="text-white">{this.state.total_booking ? this.state.total_booking : "0"}</h5>
                                            <h6 className="text-white">จำนวนรถที่จอง</h6>
                                        </div>
                                        <label className="icon text-white text-42">{"\uf21c"}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.user_type === "owner" ? (
                            <div className="row">
                                {/* รายได้/วัน */}
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
                                    <div className="card bg-info border-0 px-1 py-2 w-100">
                                        <div className="d-flex row mx-0 justify-content-between">
                                            <div className="px-2" style={{ width: 155 }}>
                                                <h5 style={{ width: 155 }} className="text-white">฿ {toFixed(this.state.total_daily)}</h5>
                                                <h6 className="text-white">รายได้/วัน</h6>
                                            </div>
                                            <label className="icon text-white text-42">{"\uf017"}</label>
                                        </div>
                                    </div>
                                </div>
                                {/* รายได้/เดือน */}
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
                                    <div className="card bg-danger border-0 px-1 py-2 w-100">
                                        <div className="d-flex row mx-0 justify-content-between">
                                            <div className="px-2" style={{ width: 155 }}>
                                                <h5 style={{ width: 155 }} className="text-white">฿ {toFixed(this.state.total_monthly)}</h5>
                                                <h6 className="text-white">รายได้/เดือน</h6>
                                            </div>
                                            <label className="icon text-white text-42">{"\uf073"}</label>
                                        </div>
                                    </div>
                                </div>
                                {/* รายได้/ปี */}
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
                                    <div className="card bg-warning border-0 px-1 py-2 w-100">
                                        <div className="d-flex row mx-0 justify-content-between">
                                            <div className="px-2" style={{ width: 155 }}>
                                                <h5 style={{ width: 155 }} className="text-dark">฿ {toFixed(this.state.total_yearly)}</h5>
                                                <h6 className="text-dark">รายได้/ปี</h6>
                                            </div>
                                            <label className="icon text-dark text-42">{"\uf643"}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="row">
                            {/* ข้อมูลการเช่า 1 */}
                            <div className="col-12 col-md-6 mb-2">
                                <h4 className="mb-0"><b>กำหนดการคืนรถ</b></h4>
                                <div className="d-flex flex-wrap align-items-center">
                                    <div className="wpx-150 px-0 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ date_rent_1_from: e });
                                                if (new Date(this.state.date_rent_1_to).getTime() < new Date(e).getTime()) {
                                                    this.setState({ date_rent_1_to: "" })
                                                }
                                            }}
                                            value={this.state.date_rent_1_from}
                                        />
                                    </div>
                                    <span className="mx-1">ถึง</span>
                                    <div className="wpx-150 px-0 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ date_rent_1_to: e });
                                            }}
                                            value={this.state.date_rent_1_to}
                                            min={this.state.date_rent_1_from}
                                        />
                                    </div>
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-150 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search_1: e.target.value }); }}
                                        value={this.state.filter_search_1}
                                    />
                                </div>
                                <div className="card border-0">
                                    <div className="card-header bg-contain pb-0">
                                        <div className="row">
                                            {/* เกินกำหนด */}
                                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-2 px-1">
                                                <div className={this.state.rent_1 === 4 || this.state.rent_1 === "" ? "card status-rent-4 text-white border-0 px-2 py-1 pointer" : "card bg-secondary text-white border-0 px-2 py-1 pointer"}
                                                    onClick={() => {
                                                        if (this.state.rent_1 === 4) {
                                                            this.setState({ rent_1: "" });
                                                        } else {
                                                            this.setState({ rent_1: 4 })
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">เกินกำหนด</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_rent_1.filter(e => e.rental_status === 4).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* คืนรถค้างจ่าย */}
                                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-2 px-1">
                                                <div className={this.state.rent_1 === 8 || this.state.rent_1 === "" ? "card status-rent-8 text-white border-0 px-2 py-1 pointer" : "card bg-secondary text-white border-0 px-2 py-1 pointer"}
                                                    onClick={() => {
                                                        if (this.state.rent_1 === 8) {
                                                            this.setState({ rent_1: "" });
                                                        } else {
                                                            this.setState({ rent_1: 8 })
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">คืนรถค้างจ่าย</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_rent_1
                                                            .filter(item => (this.state.date_rent_1_from === "" || this.state.date_rent_1_to === "") ? format_date(new Date()) === format_date(item.return_date) : true)
                                                            .filter(item => (this.state.date_rent_1_from !== "" && this.state.date_rent_1_to !== "") ? (new Date(this.state.date_rent_1_from).getTime() <= new Date(item.return_date).getTime() && new Date(item.return_date).getTime() <= new Date(this.state.date_rent_1_to).getTime()) : true)
                                                            .filter(e => this.state.filter_search_1 === "" || this.state.filter_search_1 === e.bike_channel_name)
                                                            .filter(e => e.rental_status === 8).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* อยู่ระหว่างเช่า */}
                                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-2 px-1">
                                                <div className={this.state.rent_1 === 3 || this.state.rent_1 === "" ? "card status-rent-3 text-white border-0 px-2 py-1 pointer" : "card bg-secondary text-white border-0 px-2 py-1 pointer"}
                                                    onClick={() => {
                                                        if (this.state.rent_1 === 3) {
                                                            this.setState({ rent_1: "" });
                                                        } else {
                                                            this.setState({ rent_1: 3 })
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">อยู่ระหว่างเช่า</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_rent_1
                                                            .filter(item => (this.state.date_rent_1_from === "" || this.state.date_rent_1_to === "") ? format_date(new Date()) === format_date(item.return_date) : true)
                                                            .filter(item => (this.state.date_rent_1_from !== "" && this.state.date_rent_1_to !== "") ? (new Date(this.state.date_rent_1_from).getTime() <= new Date(item.return_date).getTime() && new Date(item.return_date).getTime() <= new Date(this.state.date_rent_1_to).getTime()) : true)
                                                            .filter(e => this.state.filter_search_1 === "" || this.state.filter_search_1 === e.bike_channel_name)
                                                            .filter(e => e.rental_status === 3).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-2 overflow-scroll none-scroll dashboard" style={{ height: 510 }}>
                                        {this.state.data_rent_1 &&
                                            this.state.data_rent_1
                                                .filter(item => this.state.rent_1 === "" || this.state.rent_1 === item.rental_status)
                                                .filter(item => this.state.filter_search_1 === "" || this.state.filter_search_1 === item.bike_channel_name)
                                                .filter(item => (this.state.date_rent_1_from === "" || this.state.date_rent_1_to === "") && item.rental_status !== 4 ? format_date(new Date()) === format_date(item.return_date) : true)
                                                .filter(item => (this.state.date_rent_1_from !== "" && this.state.date_rent_1_to !== "") && item.rental_status !== 4 ? (new Date(this.state.date_rent_1_from).getTime() <= new Date(item.return_date).getTime() && new Date(item.return_date).getTime() <= new Date(this.state.date_rent_1_to).getTime()) : true)
                                                .map(((item, index) => (
                                                    <div className="card bg-white p-2 mt-2">
                                                        <div className="d-flex row">
                                                            <div className="w-100 d-flex justify-content-between">
                                                                <div className="d-flex row mx-0">
                                                                    <div className="wpx-180 px-0"><b>เลขใบเช่า : </b><label className="text-orange">{item.rental_number ? item.rental_number : "-"}</label></div>
                                                                    <div className="px-0"><b>ผู้เช่า : </b><label>{item.full_name ? item.full_name : "-"}</label></div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    {status_rent(item.rental_status)}
                                                                    <div className="position-relative">
                                                                        <label ref={this.divRef1} className="icon text-14 px-2 pt-1 pointer" onClick={() => {
                                                                            this.setState({ dropdowns1: this.state.dropdowns1 === index ? -1 : index })
                                                                        }}>{"\uf141"}</label>
                                                                        {this.state.dropdowns1 === index && (
                                                                            <div className="bg-white position-absolute shadow" style={{ top: 40, right: 12, zIndex: 1000 }}>
                                                                                {item.admin_remark !== "พนักงาน เปลี่ยนวันเวลาคืนรถ" && item.rental_status === 2 && (
                                                                                    <button ref={this.divRef2} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={() => {
                                                                                            window.location.href = "/admin/manage-rent?id=" + item.rental_id + "&type=update&page=dashboard"
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        แก้ไข
                                                                                    </button>
                                                                                )}
                                                                                {(item.rental_status === 2 || item.rental_status === 9) && (
                                                                                    <button ref={this.divRef3} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            await this.GetById(item.rental_id);
                                                                                            this.setState({ modal_payment_rent: true, use_point: false, })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        ชำระเงิน
                                                                                    </button>
                                                                                )}
                                                                                {item.admin_remark !== "พนักงาน เปลี่ยนวันเวลาคืนรถ" && (item.rental_status === 3 || item.rental_status === 4 || item.rental_status === 5 || item.rental_status === 7 || item.rental_status === 8 || item.rental_status === 12) && (
                                                                                    <button ref={this.divRef4} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            await this.clear_form();
                                                                                            await this.GetById(item.rental_id);
                                                                                            if (item.rental_status === 5 || item.rental_status === 12) {
                                                                                                await this.GetListById(item.rental_id);
                                                                                            } else {
                                                                                                this.setState({ rental_price: 0 })
                                                                                            }
                                                                                            this.setState({ modal_scale_rent: true })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        {item.rental_status === 5 || item.rental_status === 12 ? "ตรวจสอบขยายสัญญา" : "ขยายสัญญา"}
                                                                                    </button>
                                                                                )}
                                                                                {item.rental_status < 10 && item.rental_status !== 6 && item.rental_status !== 9 && item.rental_status !== 2 && (
                                                                                    <button ref={this.divRef5} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            this.setState({
                                                                                                fuel_cost: "",
                                                                                                damage_cost: "",
                                                                                                service_charge: "",
                                                                                                other_expenses: "",
                                                                                                discount: "",
                                                                                                payment_type: "",
                                                                                                bank_name: "",
                                                                                                payment_date: "",
                                                                                                payment_time: "",
                                                                                                total_pay: "",
                                                                                                total_pay_transfer: "",
                                                                                                total_pay_cradit: "",
                                                                                                rental_price_overdue: 0,
                                                                                                refund_cost: 0,
                                                                                                tab_return: 0,
                                                                                            })
                                                                                            await this.GetById(item.rental_id);
                                                                                            await this.GetListById(item.rental_id);
                                                                                            this.setState({ modal_return_rent: true })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        คืนรถ
                                                                                    </button>
                                                                                )}
                                                                                {/* {item.rental_status === 10 && ( */}
                                                                                <button ref={this.divRef6} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                    onClick={async () => {
                                                                                        await this.GetById(item.rental_id);
                                                                                        await this.GetListById(item.rental_id);
                                                                                        this.setState({ modal_detail: true })
                                                                                    }}>
                                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                                    รายละเอียด
                                                                                </button>
                                                                                {/* )} */}
                                                                                <button ref={this.divRef7} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left view"
                                                                                    onClick={async () => {
                                                                                        await this.GetById(item.rental_id);
                                                                                        // let image = [];
                                                                                        // if (!window.location.href.includes(":300")) {
                                                                                        //     for (let i of this.state.detail_picture.filter(e => e !== "")) {
                                                                                        //         image.push(await getimgBase64(i))
                                                                                        //     }
                                                                                        // }
                                                                                        // let signature = !window.location.href.includes(":300") && this.state.signature ? await getimgBase64(this.state.signature) : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACAQMAAABIeJ9nAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAMSURBVHicY2BgYAAAAAQAAfYXOFUAAAAASUVORK5CYII=";
                                                                                        
                                                                                        let image = this.state.detail_picture64.filter((e) => e !== "") 
                                                                                        let signature =  this.state.signature64 ? "data:image/png;base64,"+this.state.signature64 : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACAQMAAABIeJ9nAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAMSURBVHicY2BgYAAAAAQAAfYXOFUAAAAASUVORK5CYII=";
                                         
                                                                                        let total_day = days_between(new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")), format_date(this.state.pick_up_date, "hh:mm"), format_date(this.state.return_date, "hh:mm"));

                                                                                        print_paper01(
                                                                                            {
                                                                                                created_by: this.state.created_by,
                                                                                                address: this.state.address,
                                                                                                admin_name: this.state.admin_name,
                                                                                                rental_id: this.state.rental_id,
                                                                                                rental_number: this.state.rental_number,
                                                                                                booking_number: this.state.booking_number,
                                                                                                id_card: this.state.id_card,
                                                                                                full_name: this.state.full_name,
                                                                                                birthday: this.state.birthday,
                                                                                                nationality: this.state.nationality,
                                                                                                card_issue: this.state.card_issue,
                                                                                                date_issue: this.state.date_issue,
                                                                                                date_expire: this.state.date_expire,
                                                                                                temporary_accommodation: this.state.temporary_accommodation,
                                                                                                facebook: this.state.facebook,
                                                                                                phone: this.state.phone,
                                                                                                email: this.state.email,
                                                                                                id_line: this.state.id_line,
                                                                                                selected_bike: this.state.selected_bike,
                                                                                                pick_up_date: this.state.pick_up_date,
                                                                                                pick_up_time: this.state.pick_up_time,
                                                                                                pick_up_location: this.state.pick_up_location,
                                                                                                return_date: this.state.return_date,
                                                                                                return_time: this.state.return_time,
                                                                                                return_location: this.state.return_location,
                                                                                                rental_type: this.state.rental_type,
                                                                                                mileage: this.state.mileage,
                                                                                                oil_volume: this.state.oil_volume,
                                                                                                rental_price: this.state.rental_price,
                                                                                                guarantee_type: this.state.guarantee_type,
                                                                                                guarantee_amount: this.state.guarantee_amount,
                                                                                                equipment_fee: this.state.equipment_fee,
                                                                                                discount_price: this.state.discount_price,
                                                                                                delivery_price: this.state.delivery_price,
                                                                                                overdue_booking: this.state.overdue_booking,
                                                                                                recommen_id: this.state.recommen_id,
                                                                                                total_price: this.state.total_price,
                                                                                                signature: signature,
                                                                                                equipment_list: this.state.equipment_list,
                                                                                                total_price_equipment: this.state.total_price_equipment,
                                                                                                total_qty_equipment: this.state.total_qty_equipment,
                                                                                                detail_picture: image,
                                                                                                title_picture: this.state.title_picture,
                                                                                                rental_status: this.state.rental_status,
                                                                                                days: total_day
                                                                                            }
                                                                                        )
                                                                                    }}>
                                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                                    พิมพ์สัญญา
                                                                                </button>
                                                                                {/* <button ref={this.divRef8} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left view"
                                                                                    onClick={() => {
                                                                                        window.location.href = "/admin/manage-rent?id=" + item.rental_id + "&type=detail&page=dashboard"
                                                                                    }}>
                                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                                    รายละเอียด
                                                                                </button> */}
                                                                                {item.rental_status < 10 && item.rental_status !== 6 && item.rental_status !== 1 && item.rental_status !== 2 && (
                                                                                    <button ref={this.divRef9} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            await this.clear_form();
                                                                                            await this.GetById(item.rental_id, "เปลี่ยนรถ/สถานที่");
                                                                                            if (item.rental_status === 9) {
                                                                                                await this.GetListById(item.rental_id);
                                                                                            }
                                                                                            this.setState({ modal_location: true, service_charge: 0, status_pay: { value: "คิดส่วนต่าง", label: "คิดส่วนต่าง" }, status_pay2: { value: "ชำระ", label: "ชำระ" } })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf3c5"}</label>
                                                                                        {item.rental_status === 9 ? "ตรวจสอบการชำระ" : "เปลี่ยนรถ/สถานที่"}
                                                                                    </button>
                                                                                )}
                                                                                {(item.rental_status === 3 || item.rental_status === 7 || item.rental_status === 8) && (
                                                                                    <button ref={this.divRef10} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            await this.clear_form();
                                                                                            await this.GetById(item.rental_id);
                                                                                            await this.GetListById(item.rental_id);
                                                                                            this.setState({ modal_date_time: true })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        เปลี่ยนวันคืนรถ
                                                                                    </button>
                                                                                )}
                                                                                {getStorage("user") && getStorage("user").user_type === "owner" && (
                                                                                    <button ref={this.divRef11} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left delete"
                                                                                        onClick={async () => {
                                                                                            await this.GetById(item.rental_id);
                                                                                            this.setState({ modal_delete_rent: true, rental_id: item.rental_id })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf15b"}</label>
                                                                                        ยกเลิกรายการ
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex row mx-0">
                                                                <div className="wpx-180 px-0 mb-1">
                                                                    <b>วันที่เช่า</b><label>: {item.pick_up_date ? format_date(item.pick_up_date) : "-"} {item.pick_up_time ? item.pick_up_time.slice(0, 5) : "-"}</label>
                                                                </div>
                                                                <div className="wpx-180 px-0 mb-1">
                                                                    <b>วันที่คืน</b><label>: {item.return_date ? format_date(item.return_date) : "-"} {item.return_time ? item.return_time.slice(0, 5) : "-"}</label>
                                                                </div>
                                                                <div className="wpx-190 px-0 mb-1">
                                                                    <b>ค่าประกัน</b><label>: {
                                                                        item.guarantee_type === 1 ? toFixed(item.guarantee_amount) + " บาท" :
                                                                            item.guarantee_type === 2 ? "สำเนาบัตรประชาชน" :
                                                                                item.guarantee_type === 3 ? "บัตรประชาชนตัวจริง" :
                                                                                    item.guarantee_type === 4 ? "บัตรข้าราชการ" :
                                                                                        item.guarantee_type === 5 ? "บัตรนักศึกษา" :
                                                                                            item.guarantee_type === 6 ? "พาสปอร์ตตัวจริง" :
                                                                                                item.guarantee_type === 7 ? "สำเนาพาสปอร์ต" :
                                                                                                    item.guarantee_type === 8 ? "ใบขับขี่" :
                                                                                                        item.guarantee_type === 9 ? "ใบขับขี่ตัวจริง" :
                                                                                                            item.guarantee_type === 10 ? "เงินหยวน" :
                                                                                                                item.guarantee_type === 11 ? "เงินดอลล่า" :
                                                                                                                    item.guarantee_type === 12 ? "เงินยูโร" : "-"
                                                                    }</label>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex row mx-0">
                                                                <div className="wpx-180 px-0 mb-1">
                                                                    <b>ยี่ห้อ</b><label>: {item.brand_name ? item.brand_name : "-"}</label>
                                                                </div>
                                                                <div className="wpx-180 px-0 mb-1">
                                                                    <b>รุ่น</b><label>: {item.model_name ? item.model_name : "-"}</label>
                                                                </div>
                                                                <div className="wpx-180 px-0 mb-1">
                                                                    <b>ทะเบียน</b><label>: {item.license_plate ? item.license_plate : "-"}</label>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex row mx-0">
                                                                <div className="mr-4 px-0 mb-1">
                                                                    <b>หมายเลขรถ</b><label>: {item.bike_channel_name ? item.bike_channel_name : "-"}</label>
                                                                </div>
                                                                <div className="mr-4 px-0 mb-1">
                                                                    <b>สถานที่รับรถ</b><label>: {item.pick_up_location ? item.pick_up_location : "-"}</label>
                                                                </div>
                                                                <div className="mr-4 px-0 mb-1">
                                                                    <b>สถานที่คืนรถ</b><label>: {item.return_location ? item.return_location : "-"}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )))}
                                        <div style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                            {/* ข้อมูลการจอง 1 */}
                            <div className="col-12 col-md-6 mb-2">

                                <h4 className="mb-1"><b>ข้อมูลการจอง</b></h4>
                                <div className="d-flex flex-wrap align-items-center">
                                    <div className="wpx-150 px-0 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ date_booking_1: e });
                                            }}
                                            value={this.state.date_booking_1}
                                        />
                                    </div>
                                </div>
                                <div className="card border-0">
                                    <div className="card-header bg-contain pb-0">
                                        <div className="row">
                                            {/* อนุมัติ */}
                                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-2 px-1">
                                                <div className="card status-booking-4 text-white border-0 px-2 py-1 pointer">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">อนุมัติรอเช่า</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_booking_1.filter(e => e.booking_status === 4).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-2 overflow-scroll none-scroll" style={{ height: 510 }}>
                                        {this.state.data_booking_1 &&
                                            this.state.data_booking_1
                                                .filter(item => this.state.date_booking_1 === "" || (new Date(item.pick_up_date).getTime() <= new Date(this.state.date_booking_1).getTime() && new Date(this.state.date_booking_1).getTime() <= new Date(item.return_date).getTime()))
                                                .map(((item, index) => (
                                                    <div className="card bg-white p-2 mt-2">
                                                        <div className="d-flex row">
                                                            <div className="w-100 d-flex justify-content-between">
                                                                <div className="d-flex row mx-0">
                                                                    <div className="wpx-180 px-0"><b>เลขใบเช่า : </b><label className="text-orange">{item.booking_number ? item.booking_number : "-"}</label></div>
                                                                    <div className="px-0"><b>ผู้เช่า : </b><label>{item.full_name ? item.full_name : "-"}</label></div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    {status_booking(item.booking_status)}
                                                                    <div className="position-relative">
                                                                        <label ref={this.divRef12} className="icon text-14 px-2 pt-1 pointer" onClick={() => {
                                                                            this.setState({ dropdowns2: this.state.dropdowns2 === index ? -1 : index })
                                                                        }}>{"\uf141"}</label>
                                                                        {this.state.dropdowns2 === index && (
                                                                            <div className="bg-white position-absolute shadow" style={{ top: 40, right: 12, zIndex: 1000 }}>
                                                                                <button ref={this.divRef13} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-180 text-left view" onClick={() => {
                                                                                    this.setState({ id_card: item.id_card });
                                                                                    setTimeout(async () => {
                                                                                        await this.GetMemberByIDCard();
                                                                                        let payment_list = item.payment_list.length !== 0 ? item.payment_list[0] : null;
                                                                                        let days = days_between(
                                                                                            new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                                                                                            new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en"))),
                                                                                            item.pick_up_time,
                                                                                            item.return_time
                                                                                        )
                                                                                        let hours_price = 0;
                                                                                        if (days.day === 0) {
                                                                                            hours_price = item.daily_price;
                                                                                        } else {
                                                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                                                //ไม่ต้องหัก 
                                                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                                                if (item.daily_price < 400) {
                                                                                                    hours_price = 50; //หัก 50 บาท
                                                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                                    hours_price = 100; //หัก 100 บาท
                                                                                                } else if (item.daily_price > 700) {
                                                                                                    hours_price = 200; //หัก 200 บาท
                                                                                                }
                                                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                                                if (item.daily_price < 400) {
                                                                                                    hours_price = 100; //หัก 50 บาท
                                                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                                    hours_price = 200; //หัก 100 บาท
                                                                                                } else if (item.daily_price > 700) {
                                                                                                    hours_price = 400; //หัก 200 บาท
                                                                                                }
                                                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                                                if (item.daily_price < 400) {
                                                                                                    hours_price = 150; //หัก 50 บาท
                                                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                                    hours_price = 300; //หัก 100 บาท
                                                                                                } else if (item.daily_price > 700) {
                                                                                                    hours_price = 600; //หัก 200 บาท
                                                                                                }
                                                                                            } else if (days.hour >= 4) {
                                                                                                hours_price = item.daily_price;//หัก daily_price
                                                                                            }
                                                                                        }
                                                                                        this.setState({
                                                                                            modal_detail_booking: true,
                                                                                            id: item.booking_id,
                                                                                            id_card: item.id_card,
                                                                                            full_name: item.full_name,
                                                                                            email: item.email,
                                                                                            phone: item.phone,
                                                                                            brand_id: item.brand_id,
                                                                                            model_id: item.model_id,
                                                                                            pick_up_date: item.pick_up_date,
                                                                                            pick_up_time: item.pick_up_time,
                                                                                            pick_up_location: item.pick_up_location,
                                                                                            return_date: item.return_date,
                                                                                            return_time: item.return_time,
                                                                                            return_location: item.return_location,
                                                                                            daily_price: item.daily_price,
                                                                                            deposit_pay: item.deposit_pay,
                                                                                            payment_date: payment_list ? payment_list.payment_date : null,
                                                                                            payment_time: payment_list ? payment_list.payment_time : "",
                                                                                            payment_picture: payment_list ? payment_list.payment_picture : [],
                                                                                            booking_status: item.booking_status,
                                                                                            deposit_price: item.deposit_price ? item.deposit_price : (item.daily_price * days.day) + hours_price + item.delivery_price + item.equipment_fee,
                                                                                            booking_price: (item.daily_price * days.day) + hours_price + item.delivery_price + item.equipment_fee,
                                                                                            traffic_ticket_price: item.traffic_ticket_price ? item.traffic_ticket_price : this.state.traffic_ticket_price,

                                                                                            days: days.day,
                                                                                            hours: days.hour,
                                                                                            total_price: (item.daily_price * days.day) + hours_price,

                                                                                            search_bike: "",

                                                                                            selected_bike: {
                                                                                                brand_id: item.brand_id,
                                                                                                brand_name: item.brand_name,
                                                                                                model_id: item.model_id,
                                                                                                model_name: item.model_name,
                                                                                                title_picture: item.title_picture,
                                                                                                daily_price: item.daily_price
                                                                                            },
                                                                                            tab: "สัญญาการจอง",
                                                                                            payment_list: item.payment_list,

                                                                                            delivery_price: item.delivery_price,
                                                                                            total_price_equipment: item.equipment_fee,
                                                                                            old_daily_price: item.daily_price
                                                                                        })
                                                                                        if (item.equipment_list) {
                                                                                            let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
                                                                                            for (let eq of item.equipment_list) {
                                                                                                let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                                                                                                if (index !== -1) {
                                                                                                    data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                                                                                                    data_equipment[index].equipment_qty = eq.equipment_qty;
                                                                                                }
                                                                                            }
                                                                                            this.setState({ data_equipment: data_equipment });
                                                                                        }
                                                                                    }, 10);
                                                                                }}>
                                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                                    รายละเอียด
                                                                                </button>

                                                                                {item.booking_status === 4 && (
                                                                                    <button ref={this.divRef6} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit" onClick={() => {
                                                                                        window.location.href = "/admin/manage-rent?booking_id=" + item.booking_id +
                                                                                            "&booking_price=" + item.booking_price +
                                                                                            "&deposit_price=" + total(item.payment_list, "deposit_price") +
                                                                                            "&traffic_ticket_price=" + item.traffic_ticket_price +
                                                                                            "&equipment_fee=" + item.equipment_fee +
                                                                                            "&delivery_price=" + item.delivery_price +
                                                                                            "&equipment_list=" + JSON.stringify(item.equipment_list);
                                                                                    }}>
                                                                                        <label className="icon mx-2">{"\uf15b"}</label>
                                                                                        ทำสัญญาเช่า
                                                                                    </button>
                                                                                )}
                                                                                {item.booking_status <= 3 && (
                                                                                    <button ref={this.divRef18} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit" onClick={() => {
                                                                                        let payment_list = item.payment_list.length !== 0 ? item.payment_list[0] : null;

                                                                                        this.setState({
                                                                                            modal_delete_booking: true,
                                                                                            booking_number: item.booking_number,
                                                                                            id: item.booking_id,
                                                                                            id_card: item.id_card,
                                                                                            full_name: item.full_name,
                                                                                            email: item.email,
                                                                                            phone: item.phone,
                                                                                            brand_id: item.brand_id,
                                                                                            model_id: item.model_id,
                                                                                            pick_up_date: item.pick_up_date,
                                                                                            pick_up_time: item.pick_up_time,
                                                                                            pick_up_location: item.pick_up_location,
                                                                                            return_date: item.return_date,
                                                                                            return_time: item.return_time,
                                                                                            return_location: item.return_location,
                                                                                            daily_price: item.daily_price,
                                                                                            deposit_pay: item.deposit_pay,
                                                                                            payment_date: payment_list ? payment_list.payment_date : null,
                                                                                            payment_time: payment_list ? payment_list.payment_time : "",
                                                                                            payment_picture: payment_list ? payment_list.payment_picture : [],
                                                                                            booking_status: 5,
                                                                                            deposit_price: item.deposit_price,
                                                                                            booking_price: item.booking_price,
                                                                                            traffic_ticket_price: item.traffic_ticket_price,
                                                                                            days: days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en"))),

                                                                                            search_bike: "",

                                                                                            selected_bike: {
                                                                                                brand_id: item.brand_id,
                                                                                                brand_name: item.brand_name,
                                                                                                model_id: item.model_id,
                                                                                                model_name: item.model_name,
                                                                                                title_picture: item.title_picture,
                                                                                                daily_price: item.daily_price
                                                                                            },
                                                                                            tab: "สัญญาการจอง",
                                                                                            payment_list: item.payment_list,
                                                                                            update_type: "ยกเลิกรายการ",

                                                                                            delivery_price: item.delivery_price,
                                                                                            total_price_equipment: item.equipment_fee,
                                                                                            old_daily_price: item.daily_price
                                                                                        })
                                                                                        if (item.equipment_list) {
                                                                                            let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
                                                                                            for (let eq of item.equipment_list) {
                                                                                                let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                                                                                                if (index !== -1) {
                                                                                                    data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                                                                                                    data_equipment[index].equipment_qty = eq.equipment_qty;
                                                                                                }
                                                                                            }
                                                                                            this.setState({ data_equipment: data_equipment });
                                                                                        }
                                                                                    }}>
                                                                                        <label className="icon mx-2">{"\uf15b"}</label>
                                                                                        ยกเลิกรายการ
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex row mx-0">
                                                                <div className="wpx-180 px-0 mb-1">
                                                                    <b>วันที่เช่า</b><label>: {item.pick_up_date ? format_date(item.pick_up_date) : "-"} {item.pick_up_time ? item.pick_up_time.slice(0, 5) : "-"}</label>
                                                                </div>
                                                                <div className="wpx-180 px-0 mb-1">
                                                                    <b>วันที่คืน</b><label>: {item.return_date ? format_date(item.return_date) : "-"} {item.return_time ? item.return_time.slice(0, 5) : "-"}</label>
                                                                </div>
                                                                <div className="wpx-180 px-0 mb-1">
                                                                    <b>ยี่ห้อ</b><label>: {item.brand_name ? item.brand_name : "-"}</label>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex row mx-0">
                                                                <div className="mr-4 px-0 mb-1">
                                                                    <b>รุ่น</b><label>: {item.model_name ? item.model_name : "-"}</label>
                                                                </div>
                                                                <div className="mr-4 px-0 mb-1">
                                                                    <b>สถานที่รับรถ</b><label>: {item.pick_up_location ? item.pick_up_location : "-"}</label>
                                                                </div>
                                                                <div className="mr-4 px-0 mb-1">
                                                                    <b>สถานที่คืนรถ</b><label>: {item.return_location ? item.return_location : "-"}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )))}

                                        <div style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                            {/* ข้อมูลการเช่า 2 */}
                            <div className="col-12 col-md-6 mb-2">
                                <h4 className="mb-1"><b>ข้อมูลการเช่า</b></h4>
                                <div className="d-flex flex-wrap align-items-center">
                                    <div className="wpx-150 px-0 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ date_rent_2_from: e });
                                                if (new Date(this.state.date_rent_2_to).getTime() < new Date(e).getTime()) {
                                                    this.setState({ date_rent_2_to: "" })
                                                }
                                            }}
                                            value={this.state.date_rent_2_from}
                                        />
                                    </div>
                                    <span className="mx-1">ถึง</span>
                                    <div className="wpx-150 px-0 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ date_rent_2_to: e });
                                            }}
                                            value={this.state.date_rent_2_to}
                                            min={this.state.date_rent_2_from}
                                        />
                                    </div>
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-150 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search_2: e.target.value }); }}
                                        value={this.state.filter_search_2}
                                    />
                                </div>
                                <div className="card border-0">
                                    <div className="card-header bg-contain pb-0">
                                        <div className="row">
                                            {/* รอตรวจสอบขยายสัญญา */}
                                            <div className="col-12 col-sm-6 col-lg-4 mb-2 px-1">
                                                <div className={this.state.rent_2 === 5 || this.state.rent_2 === "" ? "card  status-rent-5 text-white border-0 px-2 py-1 pointer" : "card bg-secondary text-white border-0 px-2 py-1 pointer"}
                                                    onClick={() => {
                                                        if (this.state.rent_2 === 5) {
                                                            this.setState({ rent_2: "" });
                                                        } else {
                                                            this.setState({ rent_2: 5 })
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">รอตรวจสอบขยายสัญญา</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_rent_2
                                                            .filter(item => (this.state.date_rent_2_from !== "" && this.state.date_rent_2_to !== "") ? (new Date(this.state.date_rent_2_from).getTime() <= new Date(item.return_date).getTime() && new Date(item.return_date).getTime() <= new Date(this.state.date_rent_2_to).getTime()) : true)
                                                            .filter(e => this.state.filter_search_2 === "" || this.state.filter_search_2 === e.bike_channel_name)
                                                            .filter(e => e.rental_status === 5).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* รอยืนยันการชำระ */}
                                            <div className="col-12 col-sm-6 col-lg-4 mb-2 px-1">
                                                <div className={this.state.rent_2 === 1 || this.state.rent_2 === "" ? "card  status-rent-1 text-white border-0 px-2 py-1 pointer" : "card bg-secondary text-white border-0 px-2 py-1 pointer"}
                                                    onClick={() => {
                                                        if (this.state.rent_2 === 1) {
                                                            this.setState({ rent_2: "" });
                                                        } else {
                                                            this.setState({ rent_2: 1 })
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">รอยืนยันการชำระ</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_rent_2
                                                            .filter(item => (this.state.date_rent_2_from !== "" && this.state.date_rent_2_to !== "") ? (new Date(this.state.date_rent_2_from).getTime() <= new Date(item.return_date).getTime() && new Date(item.return_date).getTime() <= new Date(this.state.date_rent_2_to).getTime()) : true)
                                                            .filter(e => this.state.filter_search_2 === "" || this.state.filter_search_2 === e.bike_channel_name)
                                                            .filter(e => e.rental_status === 1).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ขยายสัญญาค้างจ่าย */}
                                            <div className="col-12 col-lg-4 mb-2 px-1">
                                                <div className={this.state.rent_2 === 7 || this.state.rent_2 === "" ? "card  status-rent-7 text-white border-0 px-2 py-1 pointer" : "card bg-secondary text-white border-0 px-2 py-1 pointer"}
                                                    onClick={() => {
                                                        if (this.state.rent_2 === 7) {
                                                            this.setState({ rent_2: "" });
                                                        } else {
                                                            this.setState({ rent_2: 7 })
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">ขยายสัญญาค้างจ่าย</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_rent_2
                                                            .filter(item => (this.state.date_rent_2_from !== "" && this.state.date_rent_2_to !== "") ? (new Date(this.state.date_rent_2_from).getTime() <= new Date(item.return_date).getTime() && new Date(item.return_date).getTime() <= new Date(this.state.date_rent_2_to).getTime()) : true)
                                                            .filter(e => this.state.filter_search_2 === "" || this.state.filter_search_2 === e.bike_channel_name)
                                                            .filter(e => e.rental_status === 7).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-2 overflow-scroll none-scroll" style={{ height: 510 }}>
                                        {this.state.data_rent_2 &&
                                            this.state.data_rent_2
                                                .filter(item => (this.state.date_rent_2_from !== "" && this.state.date_rent_2_to !== "") ? (new Date(this.state.date_rent_2_from).getTime() <= new Date(item.return_date).getTime() && new Date(item.return_date).getTime() <= new Date(this.state.date_rent_2_to).getTime()) : true)
                                                .filter(item => this.state.rent_2 === "" || this.state.rent_2 === item.rental_status)
                                                .filter(e => this.state.filter_search_2 === "" || this.state.filter_search_2 === e.bike_channel_name)
                                                .map(((item, index) => (
                                                    <div className="card bg-white p-2 mt-2">
                                                        <div className="d-flex row">
                                                            <div className="w-100 d-flex justify-content-between">
                                                                <div className="d-flex row mx-0">
                                                                    <div className="wpx-180 px-0"><b>เลขใบเช่า : </b><label className="text-orange">{item.rental_number ? item.rental_number : "-"}</label></div>
                                                                    <div className="px-0"><b>ผู้เช่า : </b><label>{item.full_name ? item.full_name : "-"}</label></div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    {status_rent(item.rental_status)}
                                                                    <div className="position-relative">
                                                                        <label ref={this.divRef19} className="icon text-14 px-2 pt-1 pointer" onClick={() => {
                                                                            this.setState({ dropdowns3: this.state.dropdowns3 === index ? -1 : index })
                                                                        }}>{"\uf141"}</label>
                                                                        {this.state.dropdowns3 === index && (
                                                                            <div className="bg-white position-absolute shadow" style={{ top: 40, right: 12, zIndex: 1000 }}>
                                                                                {item.admin_remark !== "พนักงาน เปลี่ยนวันเวลาคืนรถ" && item.rental_status === 2 && (
                                                                                    <button ref={this.divRef20} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={() => {
                                                                                            window.location.href = "/admin/manage-rent?id=" + item.rental_id + "&type=update&page=dashboard"
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        แก้ไข
                                                                                    </button>
                                                                                )}
                                                                                {(item.rental_status === 2 || item.rental_status === 9) && (
                                                                                    <button ref={this.divRef21} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            await this.GetById(item.rental_id);
                                                                                            this.setState({ modal_payment_rent: true, use_point: false, })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        ชำระเงิน
                                                                                    </button>
                                                                                )}
                                                                                {item.admin_remark !== "พนักงาน เปลี่ยนวันเวลาคืนรถ" && (item.rental_status === 3 || item.rental_status === 4 || item.rental_status === 5 || item.rental_status === 7 || item.rental_status === 8 || item.rental_status === 12) && (
                                                                                    <button ref={this.divRef22} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            await this.clear_form();
                                                                                            await this.GetById(item.rental_id);
                                                                                            if (item.rental_status === 5 || item.rental_status === 12) {
                                                                                                await this.GetListById(item.rental_id);
                                                                                            } else {
                                                                                                this.setState({ rental_price: 0 })
                                                                                            }
                                                                                            this.setState({ modal_scale_rent: true })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        {item.rental_status === 5 || item.rental_status === 12 ? "ตรวจสอบขยายสัญญา" : "ขยายสัญญา"}
                                                                                    </button>
                                                                                )}
                                                                                {item.rental_status < 10 && item.rental_status !== 6 && item.rental_status !== 9 && item.rental_status !== 2 && (
                                                                                    <button ref={this.divRef23} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            this.setState({
                                                                                                fuel_cost: "",
                                                                                                damage_cost: "",
                                                                                                service_charge: "",
                                                                                                other_expenses: "",
                                                                                                discount: "",
                                                                                                payment_type: "",
                                                                                                bank_name: "",
                                                                                                payment_date: "",
                                                                                                payment_time: "",
                                                                                                total_pay: "",
                                                                                                total_pay_transfer: "",
                                                                                                total_pay_cradit: "",
                                                                                                rental_price_overdue: 0,
                                                                                                refund_cost: 0,
                                                                                                tab_return: 0,
                                                                                                return_remark: ""
                                                                                            })
                                                                                            await this.GetById(item.rental_id);
                                                                                            await this.GetListById(item.rental_id);
                                                                                            this.setState({ modal_return_rent: true })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        คืนรถ
                                                                                    </button>
                                                                                )}
                                                                                {/* {item.rental_status === 10 && ( */}
                                                                                <button ref={this.divRef24} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                    onClick={async () => {
                                                                                        await this.GetById(item.rental_id);
                                                                                        await this.GetListById(item.rental_id);
                                                                                        this.setState({ modal_detail: true })
                                                                                    }}>
                                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                                    รายละเอียด
                                                                                </button>
                                                                                {/* )} */}
                                                                                <button ref={this.divRef25} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left view"
                                                                                    onClick={async () => {
                                                                                        await this.GetById(item.rental_id);
                                                                                        // let image = [];
                                                                                        // if (!window.location.href.includes(":300")) {
                                                                                        //     for (let i of this.state.detail_picture.filter(e => e !== "")) {
                                                                                        //         image.push(await getimgBase64(i))
                                                                                        //     }
                                                                                        // }
                                                                                        // let signature = !window.location.href.includes(":300") && this.state.signature ? await getimgBase64(this.state.signature) : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACAQMAAABIeJ9nAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAMSURBVHicY2BgYAAAAAQAAfYXOFUAAAAASUVORK5CYII=";
                                                                                        
                                                                                        let image = this.state.detail_picture64.filter((e) => e !== "") 
                                                                                        let signature =  this.state.signature64 ? "data:image/png;base64,"+this.state.signature64 : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACAQMAAABIeJ9nAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAMSURBVHicY2BgYAAAAAQAAfYXOFUAAAAASUVORK5CYII=";
                                        
                                                                                        let total_day = days_between(new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")), format_date(this.state.pick_up_date, "hh:mm"), format_date(this.state.return_date, "hh:mm"));

                                                                                        print_paper01(
                                                                                            {
                                                                                                created_by: this.state.created_by,
                                                                                                address: this.state.address,
                                                                                                admin_name: this.state.admin_name,
                                                                                                rental_id: this.state.rental_id,
                                                                                                rental_number: this.state.rental_number,
                                                                                                booking_number: this.state.booking_number,
                                                                                                id_card: this.state.id_card,
                                                                                                full_name: this.state.full_name,
                                                                                                birthday: this.state.birthday,
                                                                                                nationality: this.state.nationality,
                                                                                                card_issue: this.state.card_issue,
                                                                                                date_issue: this.state.date_issue,
                                                                                                date_expire: this.state.date_expire,
                                                                                                temporary_accommodation: this.state.temporary_accommodation,
                                                                                                facebook: this.state.facebook,
                                                                                                phone: this.state.phone,
                                                                                                email: this.state.email,
                                                                                                id_line: this.state.id_line,
                                                                                                selected_bike: this.state.selected_bike,
                                                                                                pick_up_date: this.state.pick_up_date,
                                                                                                pick_up_time: this.state.pick_up_time,
                                                                                                pick_up_location: this.state.pick_up_location,
                                                                                                return_date: this.state.return_date,
                                                                                                return_time: this.state.return_time,
                                                                                                return_location: this.state.return_location,
                                                                                                rental_type: this.state.rental_type,
                                                                                                mileage: this.state.mileage,
                                                                                                oil_volume: this.state.oil_volume,
                                                                                                rental_price: this.state.rental_price,
                                                                                                guarantee_type: this.state.guarantee_type,
                                                                                                guarantee_amount: this.state.guarantee_amount,
                                                                                                equipment_fee: this.state.equipment_fee,
                                                                                                discount_price: this.state.discount_price,
                                                                                                delivery_price: this.state.delivery_price,
                                                                                                overdue_booking: this.state.overdue_booking,
                                                                                                recommen_id: this.state.recommen_id,
                                                                                                total_price: this.state.total_price,
                                                                                                signature: signature,
                                                                                                equipment_list: this.state.equipment_list,
                                                                                                total_price_equipment: this.state.total_price_equipment,
                                                                                                total_qty_equipment: this.state.total_qty_equipment,
                                                                                                detail_picture: image,
                                                                                                title_picture: this.state.title_picture,
                                                                                                rental_status: this.state.rental_status,
                                                                                                days: total_day
                                                                                            }
                                                                                        )
                                                                                    }}>
                                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                                    พิมพ์สัญญา
                                                                                </button>
                                                                                {/* <button ref={this.divRef26} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left view"
                                                                                    onClick={() => {
                                                                                        window.location.href = "/admin/manage-rent?id=" + item.rental_id + "&type=detail&page=dashboard"
                                                                                    }}>
                                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                                    รายละเอียด
                                                                                </button> */}
                                                                                {item.rental_status < 10 && item.rental_status !== 6 && item.rental_status !== 1 && item.rental_status !== 2 && (
                                                                                    <button ref={this.divRef27} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            await this.clear_form();
                                                                                            await this.GetById(item.rental_id, "เปลี่ยนรถ/สถานที่");
                                                                                            if (item.rental_status === 9) {
                                                                                                await this.GetListById(item.rental_id);
                                                                                            }
                                                                                            this.setState({ modal_location: true, service_charge: 0, status_pay: { value: "คิดส่วนต่าง", label: "คิดส่วนต่าง" }, status_pay2: { value: "ชำระ", label: "ชำระ" } })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf3c5"}</label>
                                                                                        {item.rental_status === 9 ? "ตรวจสอบการชำระ" : "เปลี่ยนรถ/สถานที่"}
                                                                                    </button>
                                                                                )}
                                                                                {(item.rental_status === 3 || item.rental_status === 7 || item.rental_status === 8) && (
                                                                                    <button ref={this.divRef28} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit"
                                                                                        onClick={async () => {
                                                                                            await this.clear_form();
                                                                                            await this.GetById(item.rental_id);
                                                                                            await this.GetListById(item.rental_id);
                                                                                            this.setState({ modal_date_time: true })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                                        เปลี่ยนวันคืนรถ
                                                                                    </button>
                                                                                )}
                                                                                {getStorage("user") && getStorage("user").user_type === "owner" && (
                                                                                    <button ref={this.divRef29} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left delete"
                                                                                        onClick={async () => {
                                                                                            await this.GetById(item.rental_id);
                                                                                            this.setState({ modal_delete_rent: true, rental_id: item.rental_id })
                                                                                        }}>
                                                                                        <label className="icon mx-2">{"\uf15b"}</label>
                                                                                        ยกเลิกรายการ
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex row mx-0">
                                                                <div className="wpx-180 px-0">
                                                                    <b>วันที่เช่า</b><label>: {item.pick_up_date ? format_date(item.pick_up_date) : "-"} {item.pick_up_time ? item.pick_up_time.slice(0, 5) : "-"}</label>
                                                                </div>
                                                                <div className="wpx-180 px-0">
                                                                    <b>วันที่คืน</b><label>: {item.return_date ? format_date(item.return_date) : "-"} {item.return_time ? item.return_time.slice(0, 5) : "-"}</label>
                                                                </div>
                                                                <div className="wpx-180 px-0">
                                                                    <b>ค่าประกัน</b><label>: {
                                                                        item.guarantee_type === 1 ? toFixed(item.guarantee_amount) + " บาท" :
                                                                            item.guarantee_type === 2 ? "สำเนาบัตรประชาชน" :
                                                                                item.guarantee_type === 3 ? "บัตรประชาชนตัวจริง" :
                                                                                    item.guarantee_type === 4 ? "บัตรข้าราชการ" :
                                                                                        item.guarantee_type === 5 ? "บัตรนักศึกษา" :
                                                                                            item.guarantee_type === 6 ? "พาสปอร์ตตัวจริง" :
                                                                                                item.guarantee_type === 7 ? "สำเนาพาสปอร์ต" :
                                                                                                    item.guarantee_type === 8 ? "ใบขับขี่" :
                                                                                                        item.guarantee_type === 9 ? "ใบขับขี่ตัวจริง" :
                                                                                                            item.guarantee_type === 10 ? "เงินหยวน" :
                                                                                                                item.guarantee_type === 11 ? "เงินดอลล่า" :
                                                                                                                    item.guarantee_type === 12 ? "เงินยูโร" : "-"
                                                                    }</label>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex row mx-0">
                                                                <div className="wpx-180 px-0">
                                                                    <b>ยี่ห้อ</b><label>: {item.brand_name ? item.brand_name : "-"}</label>
                                                                </div>
                                                                <div className="wpx-180 px-0">
                                                                    <b>รุ่น</b><label>: {item.model_name ? item.model_name : "-"}</label>
                                                                </div>
                                                                <div className="wpx-190 px-0">
                                                                    <b>ทะเบียน</b><label>: {item.license_plate ? item.license_plate : "-"}</label>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex row mx-0">
                                                                <div className="mr-4 px-0">
                                                                    <b>หมายเลขรถ</b><label>: {item.bike_channel_name ? item.bike_channel_name : "-"}</label>
                                                                </div>
                                                                <div className="mr-4 px-0">
                                                                    <b>สถานที่รับรถ</b><label>: {item.pick_up_location ? item.pick_up_location : "-"}</label>
                                                                </div>
                                                                <div className="mr-4 px-0">
                                                                    <b>สถานที่คืนรถ</b><label>: {item.return_location ? item.return_location : "-"}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )))}
                                        <div style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                            {/* ข้อมูลการจอง 2 */}
                            <div className="col-12 col-md-6 mb-2">
                                <h4 className="mb-1"><b>ข้อมูลการจอง</b></h4>
                                <div className="d-flex  flex-wrap align-items-center">
                                    <div className="wpx-150 px-0 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ date_booking_2: e });
                                            }}
                                            value={this.state.date_booking_2}
                                        />
                                    </div>
                                </div>
                                <div className="card border-0">
                                    <div className="card-header bg-contain pb-0">
                                        <div className="row">
                                            {/* รอตรวจสอบ */}
                                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-2 px-1">
                                                <div className={this.state.booking_2 === 1 || this.state.booking_2 === "" ? "card status-booking-1 text-white border-0 px-2 py-1 pointer" : "card bg-secondary text-white border-0 px-2 py-1 pointer"}
                                                    onClick={() => {
                                                        if (this.state.booking_2 === 1) {
                                                            this.setState({ booking_2: "" });
                                                        } else {
                                                            this.setState({ booking_2: 1 })
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">รอตรวจสอบ</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_booking_2
                                                            .filter(item => this.state.date_booking_2 === "" || (new Date(item.pick_up_date).getTime() <= new Date(this.state.date_booking_2).getTime() && new Date(this.state.date_booking_2).getTime() <= new Date(item.return_date).getTime()))
                                                            .filter(e => e.booking_status === 1).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* รอเงินมัดจำ */}
                                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-2 px-1">
                                                <div className={this.state.booking_2 === 2 || this.state.booking_2 === "" ? "card status-booking-2 text-white border-0 px-2 py-1 pointer" : "card bg-secondary text-white border-0 px-2 py-1 pointer"}
                                                    onClick={() => {
                                                        if (this.state.booking_2 === 2) {
                                                            this.setState({ booking_2: "" });
                                                        } else {
                                                            this.setState({ booking_2: 2 })
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">รอเงินมัดจำ</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_booking_2
                                                            .filter(item => this.state.date_booking_2 === "" || (new Date(item.pick_up_date).getTime() <= new Date(this.state.date_booking_2).getTime() && new Date(this.state.date_booking_2).getTime() <= new Date(item.return_date).getTime()))
                                                            .filter(e => e.booking_status === 2).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* รออนุมัติ */}
                                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-2 px-1">
                                                <div className={this.state.booking_2 === 3 || this.state.booking_2 === "" ? "card status-booking-3 text-white border-0 px-2 py-1 pointer" : "card bg-secondary text-white border-0 px-2 py-1 pointer"}
                                                    onClick={() => {
                                                        if (this.state.booking_2 === 3) {
                                                            this.setState({ booking_2: "" });
                                                        } else {
                                                            this.setState({ booking_2: 3 })
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">รออนุมัติ</h6>
                                                        <h3 className="mb-0"><b>{this.state.data_booking_2
                                                            .filter(item => this.state.date_booking_2 === "" || (new Date(item.pick_up_date).getTime() <= new Date(this.state.date_booking_2).getTime() && new Date(this.state.date_booking_2).getTime() <= new Date(item.return_date).getTime()))
                                                            .filter(e => e.booking_status === 3).length}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-2 overflow-scroll none-scroll" style={{ height: 510 }}>
                                        {this.state.data_booking_2 && this.state.data_booking_2
                                            .filter(item => this.state.date_booking_2 === "" || (new Date(item.pick_up_date).getTime() <= new Date(this.state.date_booking_2).getTime() && new Date(this.state.date_booking_2).getTime() <= new Date(item.return_date).getTime()))
                                            .filter(item => this.state.booking_2 === "" || this.state.booking_2 === item.booking_status)
                                            .map(((item, index) => (
                                                <div className="card bg-white p-2 mt-2">
                                                    <div className="d-flex row">
                                                        <div className="w-100 d-flex justify-content-between">
                                                            <div className="d-flex row mx-0">
                                                                <div className="wpx-180 px-0"><b>เลขใบเช่า : </b><label className="text-orange mr-4">{item.booking_number ? item.booking_number : "-"}</label></div>
                                                                <div className="px-0"><b>ผู้เช่า : </b><label>{item.full_name ? item.full_name : "-"}</label></div>
                                                            </div>
                                                            <div className="d-flex">
                                                                {status_booking(item.booking_status)}
                                                                <div className="position-relative">
                                                                    <label ref={this.divRef30} className="icon text-14 px-2 pt-1 pointer" onClick={() => {
                                                                        this.setState({ dropdowns4: this.state.dropdowns4 === index ? -1 : index })
                                                                    }}>{"\uf141"}</label>
                                                                    {this.state.dropdowns4 === index && (
                                                                        <div className="bg-white position-absolute shadow" style={{ top: 40, right: 12, zIndex: 1000 }}>
                                                                            <button ref={this.divRef31} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left view" onClick={() => {
                                                                                this.setState({ id_card: item.id_card });
                                                                                setTimeout(async () => {
                                                                                    await this.GetMemberByIDCard();
                                                                                    let payment_list = item.payment_list.length !== 0 ? item.payment_list[0] : null;
                                                                                    let days = days_between(
                                                                                        new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                                                                                        new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en"))),
                                                                                        item.pick_up_time,
                                                                                        item.return_time
                                                                                    )
                                                                                    let hours_price = 0;
                                                                                    if (days.day === 0) {
                                                                                        hours_price = item.daily_price;
                                                                                    } else {
                                                                                        if (days.hour === 0 && days.minute >= 0) {
                                                                                            //ไม่ต้องหัก 
                                                                                        } else if (days.hour === 1 && days.minute >= 0) {
                                                                                            if (item.daily_price < 400) {
                                                                                                hours_price = 50; //หัก 50 บาท
                                                                                            } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                                hours_price = 100; //หัก 100 บาท
                                                                                            } else if (item.daily_price > 700) {
                                                                                                hours_price = 200; //หัก 200 บาท
                                                                                            }
                                                                                        } else if (days.hour === 2 && days.minute >= 0) {
                                                                                            if (item.daily_price < 400) {
                                                                                                hours_price = 100; //หัก 50 บาท
                                                                                            } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                                hours_price = 200; //หัก 100 บาท
                                                                                            } else if (item.daily_price > 700) {
                                                                                                hours_price = 400; //หัก 200 บาท
                                                                                            }
                                                                                        } else if (days.hour === 3 && days.minute >= 0) {
                                                                                            if (item.daily_price < 400) {
                                                                                                hours_price = 150; //หัก 50 บาท
                                                                                            } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                                hours_price = 300; //หัก 100 บาท
                                                                                            } else if (item.daily_price > 700) {
                                                                                                hours_price = 600; //หัก 200 บาท
                                                                                            }
                                                                                        } else if (days.hour >= 4) {
                                                                                            hours_price = item.daily_price;//หัก daily_price
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        modal_detail_booking: true,
                                                                                        id: item.booking_id,
                                                                                        id_card: item.id_card,
                                                                                        full_name: item.full_name,
                                                                                        email: item.email,
                                                                                        phone: item.phone,
                                                                                        brand_id: item.brand_id,
                                                                                        model_id: item.model_id,
                                                                                        pick_up_date: item.pick_up_date,
                                                                                        pick_up_time: item.pick_up_time,
                                                                                        pick_up_location: item.pick_up_location,
                                                                                        return_date: item.return_date,
                                                                                        return_time: item.return_time,
                                                                                        return_location: item.return_location,
                                                                                        daily_price: item.daily_price,
                                                                                        deposit_pay: item.deposit_pay,
                                                                                        payment_date: payment_list ? payment_list.payment_date : null,
                                                                                        payment_time: payment_list ? payment_list.payment_time : "",
                                                                                        payment_picture: payment_list ? payment_list.payment_picture : [],
                                                                                        booking_status: item.booking_status,
                                                                                        deposit_price: item.deposit_price ? item.deposit_price : (item.daily_price * days.day) + hours_price + item.delivery_price + item.equipment_fee,
                                                                                        booking_price: (item.daily_price * days.day) + hours_price + item.delivery_price + item.equipment_fee,
                                                                                        traffic_ticket_price: item.traffic_ticket_price ? item.traffic_ticket_price : this.state.traffic_ticket_price,

                                                                                        days: days.day,
                                                                                        hours: days.hour,
                                                                                        total_price: (item.daily_price * days.day) + hours_price,

                                                                                        search_bike: "",

                                                                                        selected_bike: {
                                                                                            brand_id: item.brand_id,
                                                                                            brand_name: item.brand_name,
                                                                                            model_id: item.model_id,
                                                                                            model_name: item.model_name,
                                                                                            title_picture: item.title_picture,
                                                                                            daily_price: item.daily_price
                                                                                        },
                                                                                        tab: "สัญญาการจอง",
                                                                                        payment_list: item.payment_list,

                                                                                        delivery_price: item.delivery_price,
                                                                                        total_price_equipment: item.equipment_fee,
                                                                                        old_daily_price: item.daily_price
                                                                                    })
                                                                                    if (item.equipment_list) {
                                                                                        let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
                                                                                        for (let eq of item.equipment_list) {
                                                                                            let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                                                                                            if (index !== -1) {
                                                                                                data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                                                                                                data_equipment[index].equipment_qty = eq.equipment_qty;
                                                                                            }
                                                                                        }
                                                                                        this.setState({ data_equipment: data_equipment });
                                                                                    }
                                                                                }, 10);
                                                                            }}>
                                                                                <label className="icon mx-2">{"\uf543"}</label>
                                                                                รายละเอียด
                                                                            </button>

                                                                            {item.booking_status === 4 && (
                                                                                <button ref={this.divRef6} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit" onClick={() => {
                                                                                    window.location.href = "/admin/manage-rent?booking_id=" + item.booking_id +
                                                                                        "&booking_price=" + item.booking_price +
                                                                                        "&deposit_price=" + total(item.payment_list, "deposit_price") +
                                                                                        "&traffic_ticket_price=" + item.traffic_ticket_price +
                                                                                        "&equipment_fee=" + item.equipment_fee +
                                                                                        "&delivery_price=" + item.delivery_price +
                                                                                        "&equipment_list=" + JSON.stringify(item.equipment_list);
                                                                                }}>
                                                                                    <label className="icon mx-2">{"\uf15b"}</label>
                                                                                    ทำสัญญาเช่า
                                                                                </button>
                                                                            )}
                                                                            {item.booking_status <= 3 && (
                                                                                <button ref={this.divRef36} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit" onClick={() => {
                                                                                    let payment_list = item.payment_list.length !== 0 ? item.payment_list[0] : null;

                                                                                    this.setState({
                                                                                        modal_delete_booking: true,
                                                                                        booking_number: item.booking_number,
                                                                                        id: item.booking_id,
                                                                                        id_card: item.id_card,
                                                                                        full_name: item.full_name,
                                                                                        email: item.email,
                                                                                        phone: item.phone,
                                                                                        brand_id: item.brand_id,
                                                                                        model_id: item.model_id,
                                                                                        pick_up_date: item.pick_up_date,
                                                                                        pick_up_time: item.pick_up_time,
                                                                                        pick_up_location: item.pick_up_location,
                                                                                        return_date: item.return_date,
                                                                                        return_time: item.return_time,
                                                                                        return_location: item.return_location,
                                                                                        daily_price: item.daily_price,
                                                                                        deposit_pay: item.deposit_pay,
                                                                                        payment_date: payment_list ? payment_list.payment_date : null,
                                                                                        payment_time: payment_list ? payment_list.payment_time : "",
                                                                                        payment_picture: payment_list ? payment_list.payment_picture : [],
                                                                                        booking_status: 5,
                                                                                        deposit_price: item.deposit_price,
                                                                                        booking_price: item.booking_price,
                                                                                        traffic_ticket_price: item.traffic_ticket_price,
                                                                                        days: days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en"))),

                                                                                        search_bike: "",

                                                                                        selected_bike: {
                                                                                            brand_id: item.brand_id,
                                                                                            brand_name: item.brand_name,
                                                                                            model_id: item.model_id,
                                                                                            model_name: item.model_name,
                                                                                            title_picture: item.title_picture,
                                                                                            daily_price: item.daily_price
                                                                                        },
                                                                                        tab: "สัญญาการจอง",
                                                                                        payment_list: item.payment_list,
                                                                                        update_type: "ยกเลิกรายการ",

                                                                                        delivery_price: item.delivery_price,
                                                                                        total_price_equipment: item.equipment_fee,
                                                                                        old_daily_price: item.daily_price
                                                                                    })
                                                                                    if (item.equipment_list) {
                                                                                        let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
                                                                                        for (let eq of item.equipment_list) {
                                                                                            let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                                                                                            if (index !== -1) {
                                                                                                data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                                                                                                data_equipment[index].equipment_qty = eq.equipment_qty;
                                                                                            }
                                                                                        }
                                                                                        this.setState({ data_equipment: data_equipment });
                                                                                    }
                                                                                }}>
                                                                                    <label className="icon mx-2">{"\uf15b"}</label>
                                                                                    ยกเลิกรายการ
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex row mx-0">
                                                            <div className="wpx-180 px-0">
                                                                <b>วันที่เช่า</b><label>: {item.pick_up_date ? format_date(item.pick_up_date) : "-"} {item.pick_up_time ? item.pick_up_time.slice(0, 5) : "-"}</label>
                                                            </div>
                                                            <div className="wpx-180 px-0">
                                                                <b>วันที่คืน</b><label>: {item.return_date ? format_date(item.return_date) : "-"} {item.return_time ? item.return_time.slice(0, 5) : "-"}</label>
                                                            </div>
                                                            <div className="wpx-180 px-0">
                                                                <b>ยี่ห้อ</b><label>: {item.brand_name ? item.brand_name : "-"}</label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex row mx-0">
                                                            <div className="mr-4 px-0">
                                                                <b>รุ่น</b><label>: {item.model_name ? item.model_name : "-"}</label>
                                                            </div>
                                                            <div className="mr-4 px-0">
                                                                <b>สถานที่รับรถ</b><label>: {item.pick_up_location ? item.pick_up_location : "-"}</label>
                                                            </div>
                                                            <div className="mr-4 px-0">
                                                                <b>สถานที่คืนรถ</b><label>: {item.return_location ? item.return_location : "-"}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )))}
                                        <div style={{ height: 100 }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* การจอง Booking */}
                {/* เพิ่ม แก้ไข */}
                <Modal
                    show={this.state.modal_create_booking || this.state.modal_update_booking || this.state.modal_detail_booking}
                    onHide={() => { this.setState({ modal_create_booking: false, modal_update_booking: false, modal_detail_booking: false }) }}
                    size="lg"
                >
                    <Modal.Header> <Modal.Title>
                        <b>{this.state.modal_create_booking ? "เพิ่มการจองรถ" : this.state.modal_update_booking ? "แก้ไขการจองรถ" : "รายละเอียดการจองรถ"}
                        </b>
                        {!this.state.modal_create_booking &&
                            (this.state.booking_status === 0 ? (
                                <span className="bg-gray rounded px-2 py-1 text-white ml-3">ไม่เลือก</span>
                            ) : this.state.booking_status === 1 ? (
                                <span className="bg-blue rounded px-2 py-1 text-white ml-3">รอตรวจสอบ</span>
                            ) : this.state.booking_status === 2 ? (
                                <span className="bg-orange rounded px-2 py-1 text-white ml-3">รอเงินจอง</span>
                            ) : this.state.booking_status === 3 ? (
                                <span className="bg-yellow rounded px-2 py-1 text-white ml-3">รออนุมัติ</span>
                            ) : this.state.booking_status === 4 ? (
                                <span className="bg-green rounded px-2 py-1 text-white ml-3">อนุมัติ</span>
                            ) : this.state.booking_status === 5 ? (
                                <span className="bg-danger rounded px-2 py-1 text-white ml-3">ยกเลิก</span>
                            ) : this.state.booking_status === 6 ? (
                                <span className="bg-purple rounded px-2 py-1 text-white ml-3">ค้างชำระ</span>
                            ) : this.state.booking_status === 7 ? (
                                <span className="bg-primary rounded px-2 py-1 text-white ml-3">มีสัญญาเช่า</span>
                            ) : null)}
                    </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {!this.state.modal_create_booking && (
                            <div className="w-100 d-flex mb-3 border-bottom">
                                {this.state.tab === "สัญญาการจอง" ? (
                                    <div className="bg-contain text-orange p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "สัญญาการจอง" })
                                        }}>สัญญาการจอง</div>
                                ) : (
                                    <div className="bg-white text-dark p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "สัญญาการจอง" })
                                        }}>สัญญาการจอง</div>
                                )}

                                {this.state.tab === "สลิปเงินจอง" ? (
                                    <div className="bg-contain text-orange p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "สลิปเงินจอง" })
                                        }}>สลิปเงินจอง</div>
                                ) : (
                                    <div className="bg-white text-dark p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "สลิปเงินจอง" })
                                        }}>สลิปเงินจอง</div>
                                )}

                                {this.state.tab === "ประวัติเงินจอง" ? (
                                    <div className="bg-contain text-orange p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "ประวัติเงินจอง" })
                                        }}>ประวัติเงินจอง</div>
                                ) : (
                                    <div className="bg-white text-dark p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "ประวัติเงินจอง" })
                                        }}>ประวัติเงินจอง</div>
                                )}
                            </div>
                        )}
                        {this.state.tab === "สัญญาการจอง" ? (
                            <div className="row">
                                <div className="col-12 col-lg-5">
                                    <div className="d-flex justify-content-between">
                                        <h4><b>ข้อมูลบุคคล</b></h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* รหัสบัตรประชาชน หรือ พาสปอร์ต */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">รหัสบัตรประชาชน หรือ พาสปอร์ต{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ id_card: e.target.value })
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.GetMemberByIDCard()
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        this.GetMemberByIDCard()
                                                    }}
                                                    value={this.state.id_card}
                                                    maxLength={13}
                                                    readOnly={this.state.booking_status > 2}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* ชื่อ- นามสกุล */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">ชื่อ- นามสกุล{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ full_name: e.target.value })
                                                    }}
                                                    value={this.state.full_name}
                                                    readOnly={this.state.booking_status > 2}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* เบอร์โทรศัพท์ */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">เบอร์โทรศัพท์{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ phone: e.target.value })
                                                    }}
                                                    value={this.state.phone}
                                                    readOnly={this.state.booking_status > 2}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* อีเมล */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">อีเมล :</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ email: e.target.value })
                                                    }}
                                                    value={this.state.email}
                                                    readOnly={this.state.booking_status > 2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7">
                                    <div className="d-flex justify-content-between">
                                        <h4><b>ข้อมูลรถ</b></h4>
                                        {this.state.selected_bike && this.state.booking_status <= 4 && (
                                            <button className="btn btn-outline-orange px-3"
                                                onClick={() => {
                                                    this.setState({ modal_car_booking: true, modal_create_booking: false, modal_update_booking: false, type: this.state.modal_create_booking ? "create" : "update", search_bike: "" }); setTimeout(() => {
                                                        this.GetBikeAll();
                                                    }, 10);
                                                }}>
                                                แก้ไข
                                            </button>
                                        )}
                                    </div>
                                    {!this.state.selected_bike ? (
                                        <div className="w-100 card  p-3 pt-1 mb-3">
                                            <div className="d-flex align-items-end position-relative mx-auto" style={{ height: 300, width: 300 }}>
                                                <img src={EMPTY} className="img-responsive position-absolute" style={{ width: 300, zIndex: 999, opacity: 0.08, objectFit: "contain" }} />
                                                <div className="w-100 text-center pb-5" style={{ zIndex: 1000 }}>
                                                    <h4>** ยังไม่ได้เลือกรถ **</h4>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center mb-3">
                                                <button
                                                    className="btn btn-orange wpx-200"
                                                    onClick={() => {
                                                        this.setState({
                                                            modal_car_booking: true,
                                                            modal_create_booking: false,
                                                            modal_update_booking: false,
                                                            type: this.state.modal_create_booking ? "create" : "update",
                                                            pick_up_date: this.state.pick_up_date ? this.state.pick_up_date : new Date(),
                                                            pick_up_time: this.state.pick_up_time ? this.state.pick_up_time : format_date(new Date(), "hh:mm"),
                                                            return_date: this.state.return_date ? this.state.return_date : new Date(new Date().setDate(new Date().getDate() + 1)),
                                                            return_time: this.state.return_time ? this.state.return_time : format_date(new Date(), "hh:mm"),
                                                            search_bike: ""
                                                        });
                                                        setTimeout(() => {
                                                            this.GetBikeAll();
                                                        }, 10);
                                                    }}>เลือกรถ</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3">
                                                <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-responsive w-100 rounded" style={{ height: "100%", objectFit: "contain", maxHeight: 300 }} />
                                            </div>
                                            <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                                <h6><b>รายละเอียดรถ</b></h6>
                                                <div className="row">
                                                    <div className="col-12 d-flex mb-2">
                                                        <b className="wpx-50">ยี่ห้อ</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex mb-2">
                                                        <b className="wpx-50">ชื่อรุ่น</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end mb-2">
                                                        <h4 className="text-orange">{this.state.selected_bike ? this.state.selected_bike.daily_price : "-"}  บาท/วัน</h4>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />
                                                <h6><b>วันที่รับ / คืนรถ</b></h6>
                                                <div className="d-flex justify-content-between">
                                                    <div className="w-100">
                                                        <b className="wpx-40">วันที่รับรถ</b>
                                                        <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time.slice(0, 5) + " น." : "-"}</label><br />
                                                        <b className="my-2">สถานที่รับรถ{required}</b>
                                                        <input
                                                            type="text"
                                                            className="form-control w-100 mb-2"
                                                            placeholder="เช่น สนามบินเชียงใหม่"
                                                            onChange={(e) => { this.setState({ pick_up_location: e.target.value }); }}
                                                            value={this.state.pick_up_location}
                                                            readOnly={this.state.booking_status > 2} />
                                                    </div>
                                                    <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                        <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                    </div>
                                                    <div className="w-100">
                                                        <b className="wpx-40">วันที่คืนรถ</b>
                                                        <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time.slice(0, 5) + " น." : "-"}</label><br />
                                                        <b className="my-2">สถานที่คืนรถ{required}</b>
                                                        <input
                                                            type="text"
                                                            className="form-control w-100 mb-2"
                                                            placeholder="เช่น สนามบินเชียงใหม่"
                                                            onChange={(e) => { this.setState({ return_location: e.target.value }); }}
                                                            value={this.state.return_location}
                                                            readOnly={this.state.booking_status > 2} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>

                                {/* อุปกรณ์รถ */}
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="mb-0"><b>อุปกรณ์รถ</b></h4>
                                    </div>
                                    <hr />
                                    <div className="w-100 py-3 mb-3">
                                        <div className="row mx-0">
                                            {this.state.data_equipment && this.state.data_equipment.map((item, index) => (
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 col-xxl-6 mb-2 px-1">
                                                    <div className="card bg-white w-100 p-2">
                                                        <div className="d-flex row mx-0 align-items-center w-100">
                                                            <div className="d-flex">
                                                                <img src={item.picture} className="wpx-90 hpx-90 rounded" />
                                                                <div>
                                                                    <h5 className="ml-3" style={{ minWidth: 190 }}>{item.equipment_name}</h5>
                                                                    <label className="ml-3 text-orange">ราคา {item.equipment_price} บาท</label>
                                                                </div>
                                                            </div>
                                                            <div className="ml-auto card bg-contain border-0 p-1">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="d-flex align-items-center justify-content-center mr-3">
                                                                        <h1 className={item.equipment_qty === 0 ? "text-right text-secondary mb-1" : "text-right text-orange mb-1"} style={{ width: 50 }}><b>{item.equipment_qty}</b></h1>
                                                                        <h6 className={item.equipment_qty === 0 ? "text-secondary mb-0 ml-2" : "text-orange mb-0 ml-2"}>ชิ้น</h6>
                                                                    </div>
                                                                    {this.state.booking_status <= 2 && (
                                                                        <div className="d-flex">
                                                                            <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                onClick={() => {
                                                                                    let data_equipment = this.state.data_equipment;
                                                                                    data_equipment[index].equipment_qty -= data_equipment[index].equipment_qty === 0 ? 0 : 1;

                                                                                    let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                    for (let data of data_equipment) {
                                                                                        total_qty_equipment += data.equipment_qty;
                                                                                        total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                    }
                                                                                    this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                }}>-</button>
                                                                            <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                onClick={() => {
                                                                                    let data_equipment = this.state.data_equipment;
                                                                                    data_equipment[index].equipment_qty += 1;

                                                                                    let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                    for (let data of data_equipment) {
                                                                                        total_qty_equipment += data.equipment_qty;
                                                                                        total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                    }
                                                                                    this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                }}>+</button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {this.state.tab === "สลิปเงินจอง" ? (
                            <div className="row">
                                {/* ยอดเงินจองลูกค้า */}
                                <div className="col-12">
                                    <h4><b>สลิปหลักฐาน</b></h4>
                                    <div className="row">
                                        {this.state.payment_picture.map((item, index) => (
                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                <div
                                                    className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                >
                                                    <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                        <img
                                                            alt="รูปภาพสลิปหลักฐาน"
                                                            src={item.picture.includes("https://") ? item.picture : "data:image/png;base64," + item.picture}
                                                            className="w-100 hpx-140 rounded overflow-hidden"
                                                            style={{ objectFit: "contain" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    modal_picture2: true,
                                                                    modal_detail_booking: false,
                                                                    index: index
                                                                })
                                                            }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <hr />
                                    <div className="row">
                                        {/* เงินจอง */}
                                        <div className="col-12 col-md-4 mb-2">
                                            <label className="w-100 mb-2">เงินจอง :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.deposit_pay}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-12 col-md-4 mb-2">
                                            <label className="w-100 mb-2">วันที่ :</label><br />
                                            <DatePickerTH
                                                className="form-control w-100"
                                                value={this.state.payment_date}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-12 col-md-4 mb-2">
                                            <label className="w-100 mb-2">เวลา :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="time"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.payment_time}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {this.state.tab === "ประวัติเงินจอง" ? (
                            <table className="table table-borderless table-striped" style={{ marginBottom: 100 }}>
                                <thead>
                                    <tr>
                                        <th>วันที่/เวลา</th>
                                        <th>เงินจอง</th>
                                        <th>รูปภาพ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.payment_list && this.state.payment_list.map((item, index) => (
                                        <tr>
                                            <td>{item.payment_date ? format_date(item.payment_date) + " " + item.payment_time : ""}</td>
                                            <td>{toFixed(item.deposit_pay)}</td>
                                            <td>
                                                {item.payment_picture.map((picture_item, picture_index) => (
                                                    <img
                                                        onClick={() => {
                                                            this.setState({
                                                                modal_picture2: true,
                                                                modal_detail_booking: false,
                                                                index: picture_index
                                                            })
                                                        }}
                                                        src={picture_item.picture}
                                                        className="wpx-30 hpx-30 img-fluid mb-3 pointer mx-1"></img>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : null}
                        <div className="col-12">
                            <div className="w-100 p-3 bg-orange text-white mb-3">
                                {this.state.modal_detail_booking && (
                                    <div className="d-flex align-items-center justify-content-end mb-2">
                                        <label className="mb-0 mr-2">เงินจองที่จะต้องชำระ</label>
                                        <input
                                            type="text"
                                            className="form-control wpx-250 text-end"
                                            onChange={(e) => {
                                                if (!float(e.target.value)) {
                                                    return
                                                }
                                                this.setState({ deposit_price: e.target.value });
                                            }}
                                            value={this.state.deposit_price}
                                            readOnly={this.state.booking_status > 2} />
                                        <label className="mb-0 ml-2">บาท</label>
                                    </div>
                                )}
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">ค่าบริการรับส่ง</label>
                                    <input
                                        type="text"
                                        className="form-control wpx-250 text-end"
                                        onChange={(e) => {
                                            if (!float(e.target.value)) {
                                                return
                                            }
                                            this.setState({ delivery_price: e.target.value });
                                        }}
                                        value={this.state.delivery_price}
                                        readOnly={this.state.booking_status > 2} />
                                    <label className="mb-0 ml-2">บาท</label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">ค่าอุปกรณ์</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.total_price_equipment)}</b></h3>
                                    <label className="mb-0 ml-2">บาท</label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">ค่าเช่า</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.total_price)}</b></h3>
                                    <label className="mb-0 ml-2">บาท</label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">ค่าปรับใบสั่ง</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.traffic_ticket_price)}</b></h3>
                                    <label className="mb-0 ml-2">บาท</label>
                                </div>
                                <div className="d-flex w-100 justify-content-end">
                                    <div className="d-flex align-items-center">
                                        <label className="mb-0 mr-2">จำนวนวันที่เช่า</label>
                                        <h3 className="mb-0"><b>{this.state.days}</b></h3>
                                        <label className="mb-0 ml-2 mr-4">วัน</label>
                                        <h3 className="mb-0"><b>{this.state.hours}</b></h3>
                                        <label className="mb-0 ml-2 mr-4">ชั่วโมง</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="mb-0 mr-2">ยอดชำระเงินทั้งหมด</label>
                                        <h3 className="mb-0"><b>{toFixed(Number(this.state.total_price) + Number(this.state.total_price_equipment) + Number(this.state.delivery_price) + Number(this.state.traffic_ticket_price))}</b></h3>
                                        <label className="mb-0 ml-2">บาท</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create_booking: false, modal_update_booking: false, modal_detail_booking: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        if (this.state.modal_create_booking) {
                                            this.Booking_Create();
                                        } else {
                                            this.Booking_Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create_booking ? "เพิ่ม" :
                                        this.state.booking_status === 1 ? "ยืนยันเงินจอง" :
                                            this.state.booking_status === 2 ? "ยืนยันเงินจอง" :
                                                this.state.booking_status === 3 ? "อนุมัติ" :
                                                    "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* เลือกรถ */}
                <Modal show={this.state.modal_car_booking} onHide={() => {
                    this.setState({ modal_car_booking: false });
                    if (this.state.type === "create") {
                        this.setState({ modal_create_booking: true })
                    } else {
                        this.setState({ modal_update_booking: true })
                    }
                }} size="lg">
                    <Modal.Body>
                        <div className="w-100 d-flex justify-content-between mb-2">
                            <h3><b>เลือกรถ</b></h3>
                            <label className="icon text-24 text-danger pointer px-3" onClick={() => {
                                this.setState({ modal_car_booking: false });
                                if (this.state.type === "create") {
                                    this.setState({ modal_create_booking: true })
                                } else {
                                    this.setState({ modal_update_booking: true })
                                }
                            }}>{"\uf00d"}</label>
                        </div>
                        {/* <hr className="my-2" /> */}
                        <div className="w-100">
                            {/* ค้นหา */}
                            <div className="row bg-orange mb-3">
                                <div className="col-12 p-3 mx-auto">
                                    <div className="card border-0 px-4 py-2 bg-white shadow">
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">วันที่รับรถ{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ pick_up_date: e });
                                                        if (Number(format_date(e, "yyyymmdd")) >= Number(format_date(this.state.return_date, "yyyymmdd"))) {
                                                            this.setState({ return_date: format_date(new Date(format_date(e, "yyyy,mm,dd")).setDate(new Date(format_date(e, "yyyy,mm,dd")).getDate() + 1), "yyyy-mm-dd", "en") });
                                                        }
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.pick_up_date}
                                                    clearable={false}
                                                    min={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">เวลารับรถ{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => { this.setState({ pick_up_time: e.target.value }); }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.pick_up_time} />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">วันที่คืนรถ{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ return_date: e });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.return_date}
                                                    clearable={false}
                                                    min={this.state.pick_up_date}
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">เวลาคืนรถ{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => { this.setState({ return_time: e.target.value }); }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.return_time} />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 px-1">
                                                <b className="text-white mb-2">.</b>
                                                <button className="btn btn-orange w-100 mb-2" onClick={() => {
                                                    this.GetBikeAll();
                                                    if (this.state.selected_bike) {
                                                        this.setState({ modal_car: false, days: days_between(new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en"))) });
                                                        if (this.state.type === "create") {
                                                            this.setState({ modal_create: true })
                                                        } else {
                                                            this.setState({ modal_update: true })
                                                        }
                                                    }
                                                }}>
                                                    ค้นหา
                                                    <label className="icon ml-2">{"\uf002"}</label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* รายการรถ */}
                            <div className="row">
                                <div className="col-12 pb-5 px-4">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <h4><b>ผลการค้นหา: รถว่างทั้งหมด</b></h4>
                                            <label className="text-secondary mb-3">พบรถว่าง {this.state.data_bike.length} คัน</label>
                                        </div>
                                        <div className="wpx-250 pb-2">
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-2"
                                                placeholder="เช่น ยี่ห้อ,รุ่น"
                                                onChange={(e) => { this.setState({ search_bike: e.target.value }); }}
                                                value={this.state.search_bike} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.data_bike && this.state.data_bike.filter(item => JSON.stringify(item).toUpperCase().includes(this.state.search_bike.toUpperCase()) || this.state.search_bike === "").map((item, index) => (
                                            <div className="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 mb-3 px-1">
                                                <div className="card bg-white shadow overflow-hidden border-0 position-relative"
                                                    onClick={() => {
                                                        // if (this.state.pick_up_location === "") {
                                                        //     alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่รับรถ");
                                                        //     return;
                                                        // } else if (this.state.return_location === "") {
                                                        //     alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่คืนรถ");
                                                        //     return;
                                                        // } 

                                                        this.setState({ modal_car: false, selected_bike: item, days: days_between(new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en"))) });
                                                        if (this.state.type === "create") {
                                                            this.setState({ modal_create: true })
                                                        } else {
                                                            this.setState({ modal_update: true })
                                                        }
                                                    }}
                                                // onMouseOver={() => { this.setState({ product_index: index }) }}
                                                // onMouseLeave={() => { this.setState({ product_index: -1 }) }}
                                                >
                                                    <img className="d-none d-sm-block" src={item.title_picture ? item.title_picture : CAR} style={{ height: 240, objectFit: "cover" }} />
                                                    <img className="d-block d-sm-none" src={item.title_picture ? item.title_picture : CAR} style={{ height: 180, objectFit: "cover" }} />
                                                    <div className="p-2">
                                                        <div style={{ height: 23, overflow: "hidden" }}>
                                                            <h6 className="mb-1 d-block d-sm-none"><b>{item.brand_name}</b> {item.model_name}</h6>
                                                            <h5 className="mb-1 d-none d-sm-block"><b>{item.brand_name}</b> {item.model_name}</h5>
                                                        </div>
                                                        <hr className="my-2" />
                                                        <div className="d-flex row justify-content-between mx-0">
                                                            <div className="p-0 d-flex align-items-end">
                                                                <h4 className="mb-0 text-orange"><b>฿{item.daily_price ? toFixed(item.daily_price) : "0.00"}</b></h4>
                                                                <small className="text-secondary ml-2">ต่อวัน</small>
                                                            </div>
                                                            <div className="p-0 d-flex align-items-end">
                                                                <small className="text-secondary ml-2">คงเหลือ {item.balance_app} คัน</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {index === this.state.product_index && (
                                                        <div
                                                            className="fade-in-product rounded pointer position-absolute d-flex align-items-center justify-content-center"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                left: 0,
                                                                top: 0,
                                                                borderWidth: 4,
                                                                borderStyle: "solid",
                                                                borderColor: "#fd7e14"
                                                            }}>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* ลบการจอง */}
                <Modal show={this.state.modal_delete_booking} onHide={() => { this.setState({ modal_delete_booking: false }) }}>
                    <Modal.Header><Modal.Title><b>ยกเลิกรายการ</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                ท่านแจ้งยกเลิกรายการจองใช่หรือไม่
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete_booking: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Booking_Update() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* ทำสัญญา */}
                <Modal show={this.state.modal_promise_booking} onHide={() => { this.setState({ modal_promise_booking: false }) }}>
                    <Modal.Header><Modal.Title><b>ยกเลิกรายการ</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                ท่านต้องการทำสัญญาเช่าหมายเลข "{this.state.booking_number}" ใช่หรือไม่
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_promise_booking: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-orange w-100" onClick={() => { this.Booking_Update() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* ดูรูปการจอง */}
                <Modal show={this.state.modal_picture_booking} onHide={() => { this.setState({ modal_picture_booking: false, modal_detail_booking: true }) }}>
                    <Modal.Header><Modal.Title><b>รูปสลิป</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        {this.state.show_image ? (
                            <img src={this.state.show_image} className="w-100 img-fluid mb-3"></img>
                        ) : null}
                        <div className="w-100 d-flex">
                            <button className="btn btn-outline-orange w-100 px-5" onClick={() => { this.setState({ modal_picture_booking: false, modal_detail_booking: true }) }}>
                                ปิดหน้าต่าง
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* การเช่า Rent */}
                {/* ชำระเงิน */}
                <Modal show={this.state.modal_payment_rent} onHide={() => { this.setState({ modal_payment_rent: false }) }} size="lg">
                    <Modal.Body>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <h3><b>รับชำระใบเช่า RT20211120512</b></h3>
                            {status_rent(this.state.rental_status)}
                        </div>
                        <hr />
                        <div className="row">
                            {/* รหัสบัตรประชาชน หรือ พาสปอร์ต */}
                            <div className="col-12 col-md-6">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">รหัสบัตรประชาชน หรือ พาสปอร์ต{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ id_card: e.target.value })
                                        }}
                                        value={this.state.id_card}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* ชื่อ- นามสกุล */}
                            <div className="col-12 col-md-6">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">ชื่อ- นามสกุล{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ full_name: e.target.value })
                                        }}
                                        value={this.state.full_name}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* เบอร์โทรศัพท์ */}
                            <div className="col-12 col-md-6">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">เบอร์โทรศัพท์{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ phone: e.target.value })
                                        }}
                                        value={this.state.phone}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* อีเมล */}
                            <div className="col-12 col-md-6">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">อีเมล{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                        value={this.state.email}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* ข้อมูลรถ */}
                            <div className="col-12">
                                <h4><b>ข้อมูลรถ</b></h4>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3 text-center">
                                        <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-fluid w-100 rounded" style={{ maxHeight: 300, objectFit: "contain" }} />
                                    </div>
                                    <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                        <h6><b>รายละเอียดรถ</b></h6>
                                        <div className="row">
                                            <div className="col-12 d-flex mb-2">
                                                <b className="wpx-50">ยี่ห้อ</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">ชื่อรุ่น</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">ทะเบียน</b><label>: {this.state.selected_bike ? this.state.selected_bike.license_plate : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">สี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_color : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">ปี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_year + 543 : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-100">หมายเลขรถ</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_channel_name ? this.state.selected_bike.bike_channel_name : this.state.selected_bike.channel_name : "-"}</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6><b>วันที่รับ / คืนรถ</b></h6>
                                        <div className="d-flex justify-content-between">
                                            <div className="w-100">
                                                <b className="wpx-40">วันที่รับรถ</b>
                                                <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                <b className="my-2">สถานที่รับรถ</b>
                                                <label className="ml-2 text-orange">{this.state.pick_up_location ? this.state.pick_up_location : "-"} </label>
                                            </div>
                                            <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                <label className="icon text-24 text-orange">{"\uf356"}</label>
                                            </div>
                                            <div className="w-100">
                                                <b className="wpx-40">วันที่คืนรถ</b>
                                                <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                <b className="my-2">สถานที่คืนรถ</b>
                                                <label className="ml-2 text-orange">{this.state.return_location ? this.state.return_location : "-"} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ฟอร์มชำระเงิน */}
                            <div className="col-12">
                                <h5 className="mt-3"><b>ฟอร์มชำระเงิน</b></h5>
                                <hr />
                                <div className="row mx-0">
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ราคาเช่ารถ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">ราคาเช่ารถ
                                                {this.state.rental_type &&
                                                    this.state.rental_type.value === "daily" ? " ( รายวัน ) " :
                                                    this.state.rental_type.value === "weekly" ? " ( รายสัปดาห์ ) " :
                                                        this.state.rental_type.value === "monthly" ? " ( รายเดือน ) " : " "}
                                                :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.rental_price)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค้ำประกัน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">ค้ำประกัน :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.guarantee_type &&
                                                    this.state.guarantee_type.value === "1" ?
                                                    "เงินประกัน ( " + toFixed(this.state.guarantee_amount) + " บาท )" :
                                                    this.state.guarantee_type.value === "2" ?
                                                        "สำเนาบัตรประชาชน" :
                                                        this.state.guarantee_type.value === "3" ?
                                                            "บัตรประชาชนตัวจริง" :
                                                            this.state.guarantee_type.value === "41" ?
                                                                "บัตรข้าราชการ" :
                                                                this.state.guarantee_type.value === "5" ?
                                                                    "บัตรนักศึกษา" :
                                                                    this.state.guarantee_type.value === "6" ?
                                                                        "พาสปอร์ตตัวจริง" :
                                                                        this.state.guarantee_type.value === "7" ?
                                                                            "สำเนาพาสปอร์ต" :
                                                                            this.state.guarantee_type.value === "8" ?
                                                                                "ใบขับขี่" :
                                                                                this.state.guarantee_type.value === "9" ?
                                                                                    "ใบขับขี่ตัวจริง" :
                                                                                    this.state.guarantee_type.value === "10" ?
                                                                                        "เงินหยวน" :
                                                                                        this.state.guarantee_type.value === "11" ?
                                                                                            "เงินดอลล่า" :
                                                                                            this.state.guarantee_type.value === "12" ?
                                                                                                "เงินยูโร" :
                                                                                                ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค่าอุปกรณ์ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">ค่าอุปกรณ์ :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.equipment_fee)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค่าขนส่ง */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">ค่าบริการรับส่ง :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.delivery_price)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ส่วนลด */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">ส่วนลด :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.discount_price)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค่าอื่น ๆ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">ค่าอื่น ๆ :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.other_price)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    {this.state.total_pay_booking ? (
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* ยอดจองชำระแล้ว */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">ยอดจองชำระแล้ว :</label><br />
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    value={toFixed(this.state.total_pay_booking)}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* เงินสด */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">เงินสด :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ total_pay: e.target.value })
                                                }}
                                                value={this.state.total_pay}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* เงินโอน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">เงินโอน :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ total_pay_transfer: e.target.value })
                                                }}
                                                value={this.state.total_pay_transfer}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* บัตรเครดิต */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">บัตรเครดิต :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ total_pay_cradit: e.target.value })
                                                }}
                                                value={this.state.total_pay_cradit}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* วันที่โอน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">วันที่โอน :</label><br />
                                            <DatePickerTH
                                                className="form-control"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ payment_date: e });
                                                }}
                                                value={this.state.payment_date}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* เวลาที่โอน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">เวลาที่โอน :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="time"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.payment_time}
                                                onChange={(e) => { this.setState({ payment_time: e.target.value }) }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* บัญชี */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">บัญชี :</label><br />
                                            <Select
                                                className="form-control select-search bg-white mb-2"
                                                styles={select_style}
                                                options={this.state.data_bank ? this.state.data_bank : []}
                                                onChange={(e) => {
                                                    this.setState({ bank_name: e });
                                                }}
                                                value={this.state.bank_name}
                                            ></Select>
                                        </div>
                                    </div>
                                </div>
                                {this.state.wallet && this.state.wallet.point ? (
                                    <div className="row mx-0">
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* ใช้แต้ม */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">ใช้แต้ม :</label><br />
                                                <div className="d-flex align-items-center">
                                                    <Switch onChange={(e) => {
                                                        this.setState({
                                                            use_point: e,
                                                            point: 0
                                                        })
                                                    }} checked={this.state.use_point} />
                                                    <label className="mx-2">แต้มสะสม <b>{toFixed(this.state.wallet.point)}</b> แต้ม</label>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.use_point && (
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* จำนวนแต้ม */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">จำนวนแต้ม : <small>(10 แต้ม เท่ากับ 200 บาท)</small></label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            if (Number(e.target.value) > this.state.wallet.point) {
                                                                e.target.value = this.state.wallet.point;
                                                            }
                                                            this.setState({ point: e.target.value })
                                                        }}
                                                        onBlur={() => {
                                                            let point = 0;
                                                            if (Number(this.state.point) % 10 !== 0) {
                                                                point = Number(this.state.point) - (Number(this.state.point) % 10);
                                                            } else {
                                                                point = this.state.point;
                                                            }
                                                            this.setState({ point: point });
                                                        }}
                                                        value={this.state.point}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.use_point && (
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* แลกส่วนลดได้ */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        value={toFixed(Number(this.state.point) * 20)}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                                {this.state.wallet && this.state.wallet.sk_wallet ? (
                                    <div className="row mx-0">
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* ใช้เงินสะสม */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">ใช้เงินสะสม :</label><br />
                                                <div className="d-flex align-items-center">
                                                    <Switch onChange={(e) => {
                                                        this.setState({
                                                            use_sk_wallet: e,
                                                            sk_wallet: 0
                                                        })
                                                    }} checked={this.state.use_sk_wallet} />
                                                    <label className="mx-2">เงินสะสม <b>{toFixed(this.state.wallet.sk_wallet)}</b> บาท</label>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.use_sk_wallet && (
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ต้องการใช้เงินสะสม */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ต้องการใช้เงินสะสม :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            if (Number(e.target.value) > this.state.wallet.sk_wallet) {
                                                                e.target.value = this.state.wallet.sk_wallet;
                                                            }
                                                            this.setState({ sk_wallet: e.target.value })
                                                        }}
                                                        value={this.state.sk_wallet}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.use_sk_wallet && (
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* แลกส่วนลดได้ */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        value={toFixed(Number(this.state.sk_wallet))}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                                <div className="col-12 pb-3">
                                    <div className="row">
                                        <input className="d-none" type="file" id="file_sub2" accept="image/*" onChange={(e) => { this.import_file3(e, "sub2") }} multiple />
                                        {this.state.payment_picture.map((item, index) => (
                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                <div
                                                    className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                >
                                                    <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                        <img
                                                            alt="รูปภาพสลิปหลักฐาน"
                                                            src={item.picture.includes("https://") ? item.picture : "data:image/png;base64," + item.picture}
                                                            className="w-100 hpx-140 rounded overflow-hidden"
                                                            style={{ objectFit: "contain" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    modal_picture3: true,
                                                                    modal_payment_rent: false,
                                                                    type_modal: "modal_payment_rent",
                                                                    index: index
                                                                })
                                                            }}
                                                        ></img>
                                                        <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => {
                                                            let payment_picture = this.state.payment_picture;
                                                            payment_picture.splice(index, 1);
                                                            this.setState({ payment_picture: payment_picture })
                                                        }}>
                                                            {"\uf00d"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {this.state.payment_picture.length === 0 ? (
                                            <div
                                                className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                onClick={() => { document.getElementById("file_sub2").click() }}
                                            >
                                                <label className="icon text-orange">{"\uf03e"}</label>
                                                <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                            </div>
                                        ) : (
                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                <div
                                                    className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                    onClick={() => { document.getElementById("file_sub2").click() }}
                                                >
                                                    <label className="icon text-orange">{"\uf03e"}</label>
                                                    <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 px-1">
                                        <div className="card bg-orange p-3 mb-3">
                                            <div className="row">
                                                <div className="col-8 text-center">
                                                    <label className="text-white">ยอดชำระเงินทั้งหมด</label>
                                                    <div className="w-100 card bg-white text-center">
                                                        <h3 className="text-orange"><b>{toFixed(this.state.total_price)}</b></h3>
                                                    </div>
                                                </div>
                                                <div className="col-4 text-center">
                                                    <label className="text-white">เงินทอน</label>
                                                    <h3 className="text-white"><b>{toFixed((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet) - Number(this.state.total_price))}</b></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_payment_rent: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.Payment();
                                    }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* ขยายสัญญา */}
                <Modal show={this.state.modal_scale_rent} onHide={() => { this.setState({ modal_scale_rent: false }) }} size="lg">
                    <Modal.Body>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <h3><b>ขยายสัญญาใบเช่า {this.state.rental_number}</b></h3>
                            {status_rent(this.state.rental_status)}
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12 col-xl-7">
                                <div className="row">
                                    <div className="col-5">
                                        <label className="w-100 mb-1">วันที่เริ่มต้นการเช่า :</label><br />
                                        <label className="w-100 mb-1">{format_date(this.state.pick_up_date, "dd/mm/yyyy")} {this.state.pick_up_time}</label>
                                    </div>
                                    <div className="col-5">
                                        <label className="w-100 mb-1">วันที่สิ้นสุดการเช่า :</label><br />
                                        <label className="w-100 mb-1">{format_date(this.state.return_date, "dd/mm/yyyy")} {this.state.return_time}</label>
                                    </div>
                                    <div className="col-2">
                                        <label className="w-100 mb-1">จำนวน :</label><br />
                                        <label className="w-100 mb-1">{this.state.days} วัน <label className="text-success">{this.state.return_date_new ? "(+" + days_between(new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")), new Date(format_date(this.state.return_date_new, "yyyy,mm,dd", "en"))) + " วัน)" : ""}</label></label>
                                    </div>
                                    <div className="col-12"><hr /></div>
                                    <div className="col-6 col-md-4">
                                        {/* วันที่คืนรถใหม่ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">วันที่คืนรถใหม่ :</label><br />
                                            <DatePickerTH
                                                className="form-control validate"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ return_date_new: e });
                                                    setTimeout(() => {
                                                        // let day = this.state.return_date_new ? days_between(new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")), new Date(format_date(this.state.return_date_new, "yyyy,mm,dd", "en"))) : 0
                                                        // if (this.state.selected_bike) {
                                                        //     if (this.state.rental_type.value === "daily") {
                                                        //         this.setState({ rental_price: this.state.array_price.daily_price * day })
                                                        //     } else if (this.state.rental_type.value === "weekly") {
                                                        //         if (this.state.array_price.weekly_price === 0) {
                                                        //             this.setState({ rental_price: this.state.array_price.daily_price * day })
                                                        //         } else {
                                                        //             if (day >= 7) {
                                                        //                 let price = 0;
                                                        //                 price += Number((day / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                        //                 price += (day % 7) * this.state.array_price.daily_price;
                                                        //                 this.setState({ rental_price: price })
                                                        //             } else {
                                                        //                 this.setState({ rental_price: this.state.array_price.weekly_price })
                                                        //             }
                                                        //         }
                                                        //     } else if (this.state.rental_type.value === "monthly") {
                                                        //         if (this.state.array_price.monthly_price === 0) {
                                                        //             this.setState({ rental_price: this.state.array_price.daily_price * day })
                                                        //         } else {
                                                        //             if (day > 30) {
                                                        //                 let price = 0;
                                                        //                 price += Number((day / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                        //                 price += Number(((day % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                        //                 price += ((day % 30) % 7) * this.state.array_price.daily_price;
                                                        //                 this.setState({ rental_price: price })
                                                        //             } else {
                                                        //                 this.setState({ rental_price: this.state.array_price.monthly_price })
                                                        //             }
                                                        //         }
                                                        //     }
                                                        // }
                                                        let days = days_between(
                                                            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                            new Date(format_date(this.state.return_date_new, "yyyy,mm,dd", "en")),
                                                            this.state.return_time,
                                                            this.state.return_time_new
                                                        )
                                                        let hours_price = 0;
                                                        let rental_price = 0;
                                                        if (days.hour === 0 && days.minute >= 0) {
                                                            //ไม่ต้องหัก 
                                                        } else if (days.hour === 1 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 50; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 100; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 200; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 2 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 100; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 200; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 400; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 3 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 150; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 300; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 600; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour >= 4) {
                                                            hours_price = this.state.array_price.daily_price;//หัก daily_price
                                                        }
                                                        setTimeout(() => {
                                                            if (this.state.selected_bike) {
                                                                if (this.state.rental_type.value === "daily") {
                                                                    rental_price = this.state.array_price.daily_price * days.day;
                                                                    rental_price += hours_price;
                                                                } else if (this.state.rental_type.value === "weekly") {
                                                                    if (this.state.array_price.weekly_price === 0) {
                                                                        rental_price = this.state.array_price.daily_price * days.day;
                                                                        rental_price += hours_price;
                                                                    } else {
                                                                        if (days.day >= 7) {
                                                                            rental_price += Number((days.day / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                            rental_price += (days.day % 7) * this.state.array_price.daily_price;
                                                                            if (days.day % 7 === 0) {
                                                                                rental_price += hours_price;
                                                                            }
                                                                        } else {
                                                                            rental_price = this.state.array_price.weekly_price;
                                                                        }
                                                                    }
                                                                } else if (this.state.rental_type.value === "monthly") {
                                                                    if (this.state.array_price.monthly_price === 0) {
                                                                        rental_price = this.state.array_price.daily_price * days.day;
                                                                        rental_price += hours_price;
                                                                    } else {
                                                                        if (days.day >= 30) {
                                                                            rental_price += Number((days.day / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                                            rental_price += Number(((days.day % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                            rental_price += ((days.day % 30) % 7) * this.state.array_price.daily_price;
                                                                            if (days.day % 30 === 0) {
                                                                                rental_price += hours_price;
                                                                            }
                                                                        } else {
                                                                            rental_price = this.state.array_price.monthly_price;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            this.setState({ rental_price: rental_price })
                                                        }, 10);
                                                    }, 10);
                                                }}
                                                value={this.state.return_date_new}
                                                min={new Date(this.state.return_date)}
                                                disabled={this.state.rental_status === 12}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        {/* เวลาคืนรถใหม่ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">เวลาคืนรถใหม่ :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="time"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ return_time_new: e.target.value });
                                                    setTimeout(() => {
                                                        let days = days_between(
                                                            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                            new Date(format_date(this.state.return_date_new, "yyyy,mm,dd", "en")),
                                                            this.state.return_time,
                                                            this.state.return_time_new
                                                        )
                                                        let hours_price = 0;
                                                        let rental_price = 0;
                                                        if (days.hour === 0 && days.minute >= 0) {
                                                            //ไม่ต้องหัก 
                                                        } else if (days.hour === 1 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 50; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 100; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 200; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 2 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 100; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 200; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 400; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 3 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 150; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 300; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 600; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour >= 4) {
                                                            hours_price = this.state.array_price.daily_price;//หัก daily_price
                                                        }
                                                        setTimeout(() => {
                                                            if (this.state.selected_bike) {
                                                                if (this.state.rental_type.value === "daily") {
                                                                    rental_price = this.state.array_price.daily_price * days.day;
                                                                    rental_price += hours_price;
                                                                } else if (this.state.rental_type.value === "weekly") {
                                                                    if (this.state.array_price.weekly_price === 0) {
                                                                        rental_price = this.state.array_price.daily_price * days.day;
                                                                        rental_price += hours_price;
                                                                    } else {
                                                                        if (days.day >= 7) {
                                                                            rental_price += Number((days.day / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                            rental_price += (days.day % 7) * this.state.array_price.daily_price;
                                                                            if (days.day % 7 === 0) {
                                                                                rental_price += hours_price;
                                                                            }
                                                                        } else {
                                                                            rental_price = this.state.array_price.weekly_price;
                                                                        }
                                                                    }
                                                                } else if (this.state.rental_type.value === "monthly") {
                                                                    if (this.state.array_price.monthly_price === 0) {
                                                                        rental_price = this.state.array_price.daily_price * days.day;
                                                                        rental_price += hours_price;
                                                                    } else {
                                                                        if (days.day >= 30) {
                                                                            rental_price += Number((days.day / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                                            rental_price += Number(((days.day % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                            rental_price += ((days.day % 30) % 7) * this.state.array_price.daily_price;
                                                                            if (days.day % 30 === 0) {
                                                                                rental_price += hours_price;
                                                                            }
                                                                        } else {
                                                                            rental_price = this.state.array_price.monthly_price;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            this.setState({ rental_price: rental_price })
                                                        }, 10);
                                                    }, 10);
                                                }}
                                                value={this.state.return_time_new}
                                                disabled={this.state.rental_status === 12}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        {/* ประเภทการเช่า */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ประเภทการเช่า :</label><br />
                                            <Select
                                                className="form-control select-search bg-white mb-2"
                                                styles={select_style}
                                                options={[{ value: "daily", label: "รายวัน" }, { value: "weekly", label: "รายสัปดาห์" }, { value: "monthly", label: "รายเดือน" }]}
                                                onChange={(e) => {
                                                    this.setState({ rental_type: e });
                                                    setTimeout(() => {
                                                        let days = days_between(
                                                            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                            new Date(format_date(this.state.return_date_new, "yyyy,mm,dd", "en")),
                                                            this.state.return_time,
                                                            this.state.return_time_new
                                                        )
                                                        let hours_price = 0;
                                                        let rental_price = 0;
                                                        if (days.hour === 0 && days.minute >= 0) {
                                                            //ไม่ต้องหัก 
                                                        } else if (days.hour === 1 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 50; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 100; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 200; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 2 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 100; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 200; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 400; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 3 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 150; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 300; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 600; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour >= 4) {
                                                            hours_price = this.state.array_price.daily_price;//หัก daily_price
                                                        }
                                                        setTimeout(() => {
                                                            if (this.state.selected_bike) {
                                                                if (this.state.rental_type.value === "daily") {
                                                                    rental_price = this.state.array_price.daily_price * days.day;
                                                                    rental_price += hours_price;
                                                                } else if (this.state.rental_type.value === "weekly") {
                                                                    if (this.state.array_price.weekly_price === 0) {
                                                                        rental_price = this.state.array_price.daily_price * days.day;
                                                                        rental_price += hours_price;
                                                                    } else {
                                                                        if (days.day >= 7) {
                                                                            rental_price += Number((days.day / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                            rental_price += (days.day % 7) * this.state.array_price.daily_price;
                                                                            if (days.day % 7 === 0) {
                                                                                rental_price += hours_price;
                                                                            }
                                                                        } else {
                                                                            rental_price = this.state.array_price.weekly_price;
                                                                        }
                                                                    }
                                                                } else if (this.state.rental_type.value === "monthly") {
                                                                    if (this.state.array_price.monthly_price === 0) {
                                                                        rental_price = this.state.array_price.daily_price * days.day;
                                                                        rental_price += hours_price;
                                                                    } else {
                                                                        if (days.day >= 30) {
                                                                            rental_price += Number((days.day / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                                            rental_price += Number(((days.day % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                            rental_price += ((days.day % 30) % 7) * this.state.array_price.daily_price;
                                                                            if (days.day % 30 === 0) {
                                                                                rental_price += hours_price;
                                                                            }
                                                                        } else {
                                                                            rental_price = this.state.array_price.monthly_price;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            this.setState({ rental_price: rental_price })
                                                        }, 10);
                                                    }, 10);
                                                }}
                                                value={this.state.rental_type}
                                                isDisabled={this.state.readonly || this.state.rental_status === 12}
                                            ></Select>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    {/* คิดค่าขยายสัญญา */}
                                    <div className="w-100 mb-3">
                                        <label className="w-100 mb-1">คิดค่าขยายสัญญา :</label><br />
                                        <Select
                                            className="form-control validate select-search"
                                            styles={select_style}
                                            options={[
                                                { value: "คิดเงิน", label: "คิดเงิน" },
                                                { value: "ไม่คิดเงิน", label: "ไม่คิดเงิน" }
                                            ]}
                                            onChange={(e) => {
                                                this.setState({
                                                    status_pay2: e,
                                                    payment_type: { value: "1", label: "เงินสด" },
                                                    bank_name: "",
                                                    payment_date: "",
                                                    payment_time: "",
                                                    total_pay: "",
                                                    total_pay_transfer: "",
                                                    total_pay_cradit: "",
                                                    use_point: false,
                                                    point: "",
                                                    use_sk_wallet: false,
                                                    sk_wallet: "",
                                                    status_pay: { value: "ชำระ", label: "ชำระ" },
                                                    discount_pay: 0
                                                });
                                            }}
                                            value={this.state.status_pay2}
                                            isDisabled={this.state.rental_status === 12}
                                        ></Select>
                                    </div>
                                </div>
                                {this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" && (
                                    <div>
                                        <div className="row">

                                            <div className="col-6">
                                                {/* เงินสด */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">เงินสด :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({ total_pay: e.target.value })
                                                        }}
                                                        value={this.state.total_pay}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                {/* เงินโอน */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">เงินโอน :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({ total_pay_transfer: e.target.value })
                                                        }}
                                                        value={this.state.total_pay_transfer}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                {/* บัตรเครดิต */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">บัตรเครดิต :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({ total_pay_cradit: e.target.value })
                                                        }}
                                                        value={this.state.total_pay_cradit}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                {/* วันที่โอน */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">วันที่โอน :</label><br />
                                                    <DatePickerTH
                                                        className="form-control"
                                                        placeholder="พิมพ์คำค้นหา..."
                                                        onChange={(e) => {
                                                            this.setState({ payment_date: e });
                                                        }}
                                                        value={this.state.payment_date}
                                                        disabled={this.state.rental_status === 12}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                {/* เวลาที่โอน */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">เวลาที่โอน :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="time"
                                                        placeholder="กรอกข้อมูล"
                                                        value={this.state.payment_time}
                                                        onChange={(e) => { this.setState({ payment_time: e.target.value }) }}
                                                        disabled={this.state.rental_status === 12}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                {/* บัญชี */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">บัญชี :</label><br />
                                                    <Select
                                                        className="form-control select-search bg-white mb-2"
                                                        styles={select_style}
                                                        options={this.state.data_bank ? this.state.data_bank : []}
                                                        onChange={(e) => {
                                                            this.setState({ bank_name: e });
                                                        }}
                                                        value={this.state.bank_name}
                                                        isDisabled={this.state.rental_status === 12}
                                                    ></Select>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                {/* ส่วนลด */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ส่วนลด :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({ discount_pay: e.target.value })
                                                        }}
                                                        value={this.state.discount_pay}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.rental_status !== 12 && (
                                            <div>
                                                {this.state.wallet && this.state.wallet.point ? (
                                                    <div className="row mx-0">
                                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                                            {/* ใช้แต้ม */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-2">ใช้แต้ม :</label><br />
                                                                <div className="d-flex align-items-center">
                                                                    <Switch onChange={(e) => {
                                                                        this.setState({
                                                                            use_point: e,
                                                                            point: 0
                                                                        })
                                                                    }} checked={this.state.use_point} />
                                                                    <label className="mx-2">แต้มสะสม <b>{toFixed(this.state.wallet.point)}</b> แต้ม</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(this.state.use_point) ? (
                                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                                {/* จำนวนแต้ม */}
                                                                <div className="w-100 mb-2">
                                                                    <label className="w-100 mb-2">จำนวนแต้ม : <small>(10 แต้ม เท่ากับ 200 บาท)</small></label><br />
                                                                    <input
                                                                        className="form-control validate"
                                                                        type="text"
                                                                        placeholder="กรอกข้อมูล"
                                                                        onChange={(e) => {
                                                                            if (!float(e.target.value)) {
                                                                                return;
                                                                            }
                                                                            if (Number(e.target.value) > this.state.wallet.point) {
                                                                                e.target.value = this.state.wallet.point;
                                                                            }
                                                                            this.setState({ point: e.target.value })
                                                                        }}
                                                                        onBlur={() => {
                                                                            let point = 0;
                                                                            if (Number(this.state.point) % 10 !== 0) {
                                                                                point = Number(this.state.point) - (Number(this.state.point) % 10);
                                                                            } else {
                                                                                point = this.state.point;
                                                                            }
                                                                            this.setState({ point: point });
                                                                        }}
                                                                        value={this.state.point}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        {this.state.use_point ? (
                                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                                {/* แลกส่วนลดได้ */}
                                                                <div className="w-100 mb-2">
                                                                    <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                                    <input
                                                                        className="form-control validate"
                                                                        type="text"
                                                                        placeholder="กรอกข้อมูล"
                                                                        value={toFixed(Number(this.state.point) * 20)}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                                {this.state.wallet && this.state.wallet.sk_wallet ? (
                                                    <div className="row mx-0">
                                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                                            {/* ใช้เงินสะสม */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-2">ใช้เงินสะสม :</label><br />
                                                                <div className="d-flex align-items-center">
                                                                    <Switch onChange={(e) => {
                                                                        this.setState({
                                                                            use_sk_wallet: e,
                                                                            sk_wallet: 0
                                                                        })
                                                                    }} checked={this.state.use_sk_wallet} />
                                                                    <label className="mx-2">เงินสะสม <b>{toFixed(this.state.wallet.sk_wallet)}</b> บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.use_sk_wallet ? (
                                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                                {/* ต้องการใช้เงินสะสม */}
                                                                <div className="w-100 mb-2">
                                                                    <label className="w-100 mb-2">ต้องการใช้เงินสะสม :</label><br />
                                                                    <input
                                                                        className="form-control validate"
                                                                        type="text"
                                                                        placeholder="กรอกข้อมูล"
                                                                        onChange={(e) => {
                                                                            if (!float(e.target.value)) {
                                                                                return;
                                                                            }
                                                                            if (Number(e.target.value) > this.state.wallet.sk_wallet) {
                                                                                e.target.value = this.state.wallet.sk_wallet;
                                                                            }
                                                                            this.setState({ sk_wallet: e.target.value })
                                                                        }}
                                                                        value={this.state.sk_wallet}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        {this.state.use_sk_wallet ? (
                                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                                {/* แลกส่วนลดได้ */}
                                                                <div className="w-100 mb-2">
                                                                    <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                                    <input
                                                                        className="form-control validate"
                                                                        type="text"
                                                                        placeholder="กรอกข้อมูล"
                                                                        value={toFixed(Number(this.state.sk_wallet))}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                        <div className="col-12">
                                            {/* สถานะการชำระ */}
                                            <div className="w-100 mb-3">
                                                <label className="w-100 mb-1">สถานะการชำระ :</label><br />
                                                <Select
                                                    className="form-control validate select-search"
                                                    styles={select_style}
                                                    options={[
                                                        { value: "ชำระ", label: "ชำระ" },
                                                        { value: "ค้างชำระ", label: "ค้างชำระ" }
                                                    ]}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            status_pay: e,
                                                        });
                                                    }}
                                                    value={this.state.status_pay}
                                                    isDisabled={this.state.rental_status === 12}
                                                ></Select>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* หมายเหตุ */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">หมายเหตุ :</label><br />
                                    <textarea
                                        className="form-control validate"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ remark: e.target.value })
                                        }}
                                        value={this.state.remark}
                                        disabled={this.state.rental_status === 12}
                                    />
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 px-1">
                                        <div className="card bg-orange p-3 mb-3">
                                            <div className="row">
                                                <div className="col-8 text-center">
                                                    <label className="text-white">ยอดชำระเงินทั้งหมด</label>
                                                    <div className="w-100 card bg-white text-center">
                                                        <h3 className="text-orange"><b>{this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน" ? "0.00" : toFixed(Number(this.state.rental_price) - Number(this.state.discount_pay))}</b></h3>
                                                    </div>
                                                </div>
                                                <div className="col-4 text-center">
                                                    <label className="text-white">เงินทอน</label>
                                                    <h3 className="text-white"><b>{this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน" ? "0.00" : toFixed((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet) - Number(this.state.discount_pay)))}</b></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5 pb-3">
                                <div className="row">
                                    <input className="d-none" type="file" id="file_sub2" accept="image/*" onChange={(e) => { this.import_file3(e, "sub2") }} multiple />
                                    {this.state.payment_picture.map((item, index) => (
                                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                            <div
                                                className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                            >
                                                <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                    <img
                                                        alt="รูปภาพสลิปหลักฐาน"
                                                        src={item.picture.includes("https://") ? item.picture : "data:image/png;base64," + item.picture}
                                                        className="w-100 hpx-140 rounded overflow-hidden"
                                                        style={{ objectFit: "contain" }}
                                                        onClick={() => {
                                                            this.setState({
                                                                modal_picture3: true,
                                                                modal_scale_rent: false,
                                                                type_modal: "modal_scale_rent",
                                                                index: index
                                                            })
                                                        }}
                                                    ></img>
                                                    <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => {
                                                        let payment_picture = this.state.payment_picture;
                                                        payment_picture.splice(index, 1);
                                                        this.setState({ payment_picture: payment_picture })
                                                    }}>
                                                        {"\uf00d"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {this.state.payment_picture.length === 0 ? (
                                        <div
                                            className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                            onClick={() => { document.getElementById("file_sub2").click() }}
                                        >
                                            <label className="icon text-orange">{"\uf03e"}</label>
                                            <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                        </div>
                                    ) : (
                                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                            <div
                                                className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                onClick={() => { document.getElementById("file_sub2").click() }}
                                            >
                                                <label className="icon text-orange">{"\uf03e"}</label>
                                                <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        {this.state.rental_status === 5 ? (
                            <div className="w-100 d-flex">
                                <div className="w-50 p-1">
                                    <button
                                        className="btn btn-outline-orange w-100"
                                        onClick={() => {
                                            this.setState({ modal_scale_rent: false })
                                        }}
                                    >
                                        ยกเลิก
                                    </button>
                                </div>
                                <div className="w-50 p-1">
                                    <button
                                        className="btn btn-warning w-100"
                                        onClick={() => {
                                            this.Scale04();
                                        }}
                                    >
                                        รอเงินมัดจำ
                                    </button>
                                </div>
                                <div className="w-50 p-1">
                                    <button
                                        className="btn btn-danger w-100"
                                        onClick={() => {
                                            this.Scale03();
                                        }}
                                    >
                                        ไม่อนุมัติ
                                    </button>
                                </div>
                                <div className="w-50 p-1">
                                    <button
                                        className="btn btn-success w-100"
                                        onClick={() => {
                                            if ((this.state.status_pay && this.state.status_pay.value === "ชำระ")) {
                                                this.Scale01();
                                            } else {
                                                this.Scale02();
                                            }
                                        }}
                                    >
                                        อนุมัติ
                                    </button>
                                </div>
                            </div>
                        ) : this.state.rental_status === 12 ? (
                            <div className="w-100 d-flex">
                                <div className="w-50 p-1 mx-auto">
                                    <button
                                        className="btn btn-outline-orange w-100"
                                        onClick={() => {
                                            this.setState({ modal_scale_rent: false })
                                        }}
                                    >
                                        ปิดหน้าต่าง
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="w-100 d-flex">
                                <div className="w-50 p-1">
                                    <button
                                        className="btn btn-outline-orange w-100"
                                        onClick={() => {
                                            this.setState({ modal_scale_rent: false })
                                        }}
                                    >
                                        ยกเลิก
                                    </button>
                                </div>
                                <div className="w-50 p-1">
                                    <button
                                        className="btn btn-orange w-100"
                                        onClick={() => {
                                            if ((this.state.status_pay && this.state.status_pay.value === "ชำระ")) {
                                                this.Scale01();
                                            } else {
                                                this.Scale02();
                                            }
                                        }}
                                    >
                                        ขยายสัญญา
                                    </button>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
                {/* คืนรถ */}
                <Modal show={this.state.modal_return_rent} onHide={() => { this.setState({ modal_return_rent: false }) }} dialogClassName="modal-w95">
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                    <h3><b>ใบเช่า {this.state.rental_number}</b></h3>{status_rent(this.state.rental_status)}
                                </div>
                                <hr />
                                <div className="w-100 d-flex mb-3 border-bottom">
                                    {this.state.tab_return === 0 ? (
                                        <div className="bg-contain text-orange p-3 text-center pointer w-50"
                                            style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid" }}
                                            onClick={() => {
                                                this.setState({ tab_return: 0 })
                                            }}>รายละเอียดการคืนสินค้า</div>
                                    ) : (
                                        <div className="bg-white text-dark p-3 text-center pointer w-50"
                                            style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid" }}
                                            onClick={() => {
                                                this.setState({ tab_return: 0 })
                                            }}>รายละเอียดการคืนสินค้า</div>
                                    )}

                                    {this.state.tab_return === 1 ? (
                                        <div className="bg-contain text-orange p-3 text-center pointer w-50"
                                            style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid" }}
                                            onClick={() => {
                                                this.setState({ tab_return: 1 })
                                            }}>สภาพรถ</div>
                                    ) : (
                                        <div className="bg-white text-dark p-3 text-center pointer w-50"
                                            style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid" }}
                                            onClick={() => {
                                                this.setState({ tab_return: 1 })
                                            }}>สภาพรถ</div>
                                    )}
                                </div>
                                {this.state.tab_return === 0 && (
                                    <div className="row mb-3">
                                        {/* รหัสบัตรประชาชน หรือ พาสปอร์ต */}
                                        <div className="col-12 col-md-6">
                                            <div className="w-100 mb-3">
                                                <label className="w-100 mb-1">รหัสบัตรประชาชน หรือ พาสปอร์ต{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ id_card: e.target.value })
                                                    }}
                                                    value={this.state.id_card}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        {/* ชื่อ- นามสกุล */}
                                        <div className="col-12 col-md-6">
                                            <div className="w-100 mb-3">
                                                <label className="w-100 mb-1">ชื่อ- นามสกุล{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ full_name: e.target.value })
                                                    }}
                                                    value={this.state.full_name}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        {/* เบอร์โทรศัพท์ */}
                                        <div className="col-12 col-md-6">
                                            <div className="w-100 mb-3">
                                                <label className="w-100 mb-1">เบอร์โทรศัพท์{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ phone: e.target.value })
                                                    }}
                                                    value={this.state.phone}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        {/* อีเมล */}
                                        <div className="col-12 col-md-6">
                                            <div className="w-100 mb-3">
                                                <label className="w-100 mb-1">อีเมล{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ email: e.target.value })
                                                    }}
                                                    value={this.state.email}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        {/* ข้อมูลรถ */}
                                        <div className="col-12">
                                            <h4><b>ข้อมูลรถ</b></h4>
                                            <hr />
                                            <div className="row">
                                                <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3">
                                                    <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-responsive w-100 rounded" style={{ height: "100%", maxHeight: 300, objectFit: "contain" }} />
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                                    <h6><b>รายละเอียดรถ</b></h6>
                                                    <div className="row">
                                                        <div className="col-12 d-flex mb-2">
                                                            <b className="wpx-50">ยี่ห้อ</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">ชื่อรุ่น</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">ทะเบียน</b><label>: {this.state.selected_bike ? this.state.selected_bike.license_plate : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">สี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_color : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">ปี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_year + 543 : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-100">หมายเลขรถ</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_channel_name ? this.state.selected_bike.bike_channel_name : this.state.selected_bike.channel_name : "-"}</label>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <h6><b>วันที่รับ / คืนรถ</b></h6>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="w-100">
                                                            <b className="wpx-40">วันที่รับรถ</b>
                                                            <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                            <b className="my-2">สถานที่รับรถ</b>
                                                            <h4 className="ml-2 text-orange">{this.state.pick_up_location ? this.state.pick_up_location : "-"} </h4>
                                                        </div>
                                                        <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                            <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                        </div>
                                                        <div className="w-100">
                                                            <b className="wpx-40">วันที่คืนรถ</b>
                                                            <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                            <b className="my-2">สถานที่คืนรถ</b>
                                                            <h4 className="ml-2 text-orange">{this.state.return_location ? this.state.return_location : "-"} </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.tab_return === 1 && (
                                    <div className="card-body p-3">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="mb-0"><b>สภาพรถ</b></h4>
                                        </div>
                                        <hr />
                                        <div className="w-100 pt-3">
                                            <div className="row mx-0">
                                                <div className="col-12 mr-auto">
                                                    <div className="row">
                                                        {this.state.detail_picture.filter(e => e.length !== 0).map((item, index) => (
                                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                                <div
                                                                    className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                                    onClick={() => {
                                                                        this.setState({ index: index, modal_picture: true, modal_return_rent: false })
                                                                    }}
                                                                >
                                                                    <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                                        <img alt="รูปโปรไฟล์" src={item.includes("https://") ? item : "data:image/png;base64," + item} className="w-100 hpx-140 rounded overflow-hidden" style={{ objectFit: "contain" }}></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <h4><b>ยอดเงินมัดจำลูกค้า</b></h4>
                                        <div className="table-responsive">
                                            <table className="table table-borderless table-striped">
                                                <thead>
                                                    <tr className="bg-dark text-white">
                                                        <th className="text-center">เลขที่ใบมัดจำ</th>
                                                        <th className="text-center">วันที่ขยายสัญญา</th>
                                                        <th className="text-center">ยอดมัดจำ</th>
                                                        <th className="text-center">สถานะ</th>
                                                        <th className="text-center">หมายเหตุ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(!this.state.data_detail || this.state.data_detail.length === 0) && (
                                                        <tr> <td colSpan={6} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                                    )}
                                                    {this.state.data_detail && this.state.data_detail.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{item.payment_number ? item.payment_number : "-"}</td>
                                                            <td className="text-center">{item.event_extend ? format_date(item.event_extend.return_date_new) + " " + item.event_extend.return_time_new : "-"}</td>
                                                            <td className="text-center">{item.total_price ? toFixed(item.total_price) : "-"}</td>
                                                            <td className="text-center">
                                                                {item.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || item.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ" ? (
                                                                    <span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ชำระ</span>
                                                                ) : (
                                                                    <span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span>
                                                                )}
                                                            </td>
                                                            <td className="text-center">{item.event_extend ? item.event_extend.remark : "-"}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ราคาเช่ารถ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ราคาเช่ารถ :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ rental_price: e.target.value })
                                                }}
                                                value={this.state.rental_price}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* จำนวนเงินค้ำประกัน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">จำนวนเงินค้ำประกัน :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ guarantee_amount: e.target.value })
                                                }}
                                                value={this.state.guarantee_amount}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าเช่าเกินกำหนด */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ค่าเช่าเกินกำหนด :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ rental_price_overdue: e.target.value })
                                                }}
                                                value={this.state.rental_price_overdue}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าเปลี่ยนสถานที่/รถชำระแล้ว */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ค่าเปลี่ยนสถานที่/รถชำระแล้ว :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                readOnly
                                                value={this.state.service_charge_pay}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าเปลี่ยนสถานที่/รถค้างชำระ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ค่าเปลี่ยนสถานที่/รถค้างชำระ :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                readOnly
                                                value={this.state.service_charge_overdue}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าน้ำมัน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ค่าน้ำมัน :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ fuel_cost: e.target.value })
                                                }}
                                                value={this.state.fuel_cost}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าเสียหาย */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ค่าเสียหาย :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ damage_cost: e.target.value })
                                                }}
                                                value={this.state.damage_cost}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าบริการรับส่ง */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ค่าบริการรับส่ง :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ service_charge: e.target.value })
                                                }}
                                                value={this.state.service_charge}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าอื่นๆ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ค่าอื่นๆ :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ other_expenses: e.target.value })
                                                }}
                                                value={this.state.other_expenses}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-3">
                                        {/* ส่วนลด */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ส่วนลด :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ discount_pay: e.target.value })
                                                }}
                                                value={this.state.discount_pay}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* เงินสด */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">เงินสด :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ total_pay: e.target.value })
                                                }}
                                                value={this.state.total_pay}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* เงินโอน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">เงินโอน :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ total_pay_transfer: e.target.value })
                                                }}
                                                value={this.state.total_pay_transfer}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* บัตรเครดิต */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">บัตรเครดิต :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ total_pay_cradit: e.target.value })
                                                }}
                                                value={this.state.total_pay_cradit}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-6">
                                        {/* วันที่โอน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">วันที่โอน :</label><br />
                                            <DatePickerTH
                                                className="form-control"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ payment_date: e });
                                                }}
                                                value={this.state.payment_date}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* เวลาที่โอน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">เวลาที่โอน :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="time"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.payment_time}
                                                onChange={(e) => { this.setState({ payment_time: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* บัญชี */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">บัญชี :</label><br />
                                            <Select
                                                className="form-control select-search bg-white mb-2"
                                                styles={select_style}
                                                options={this.state.data_bank ? this.state.data_bank : []}
                                                onChange={(e) => {
                                                    this.setState({ bank_name: e });
                                                }}
                                                value={this.state.bank_name}
                                            ></Select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {/* หมายเหตุ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">หมายเหตุ :</label><br />
                                            <textarea
                                                className="form-control validate"
                                                placeholder="กรอกข้อมูล"
                                                rows={5}
                                                value={this.state.return_remark}
                                                onChange={(e) => { this.setState({ return_remark: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {(this.state.wallet && this.state.wallet.point) ? (
                                    <div className="row mx-0">
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* ใช้แต้ม */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">ใช้แต้ม :</label><br />
                                                <div className="d-flex align-items-center">
                                                    <Switch onChange={(e) => {
                                                        this.setState({
                                                            use_point: e,
                                                            point: 0
                                                        })
                                                    }} checked={this.state.use_point} />
                                                    <label className="mx-2">แต้มสะสม <b>{toFixed(this.state.wallet.point)}</b> แต้ม</label>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.use_point && (
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* จำนวนแต้ม */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">จำนวนแต้ม : <small>(10 แต้ม เท่ากับ 200 บาท)</small></label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            if (Number(e.target.value) > this.state.wallet.point) {
                                                                e.target.value = this.state.wallet.point;
                                                            }
                                                            this.setState({ point: e.target.value })
                                                        }}
                                                        onBlur={() => {
                                                            let point = 0;
                                                            if (Number(this.state.point) % 10 !== 0) {
                                                                point = Number(this.state.point) - (Number(this.state.point) % 10);
                                                            } else {
                                                                point = this.state.point;
                                                            }
                                                            this.setState({ point: point });
                                                        }}
                                                        value={this.state.point}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.use_point && (
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* แลกส่วนลดได้ */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        value={toFixed(Number(this.state.point) * 20)}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                                {(this.state.wallet && this.state.wallet.sk_wallet) ? (
                                    <div className="row mx-0">
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* ใช้เงินสะสม */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">ใช้เงินสะสม :</label><br />
                                                <div className="d-flex align-items-center">
                                                    <Switch onChange={(e) => {
                                                        this.setState({
                                                            use_sk_wallet: e,
                                                            sk_wallet: 0
                                                        })
                                                    }} checked={this.state.use_sk_wallet} />
                                                    <label className="mx-2">เงินสะสม <b>{toFixed(this.state.wallet.sk_wallet)}</b> บาท</label>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.use_sk_wallet && (
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ต้องการใช้เงินสะสม */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ต้องการใช้เงินสะสม :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            if (Number(e.target.value) > this.state.wallet.sk_wallet) {
                                                                e.target.value = this.state.wallet.sk_wallet;
                                                            }
                                                            this.setState({ sk_wallet: e.target.value })
                                                        }}
                                                        value={this.state.sk_wallet}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.use_sk_wallet && (
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* แลกส่วนลดได้ */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        value={toFixed(Number(this.state.sk_wallet))}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                                <div className="col-12 pb-3">
                                    <div className="row">
                                        <input className="d-none" type="file" id="file_sub2" accept="image/*" onChange={(e) => { this.import_file3(e, "sub2") }} multiple />
                                        {this.state.payment_picture.map((item, index) => (
                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                <div
                                                    className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                >
                                                    <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                        <img
                                                            alt="รูปภาพสลิปหลักฐาน"
                                                            src={item.picture.includes("https://") ? item.picture : "data:image/png;base64," + item.picture}
                                                            className="w-100 hpx-140 rounded overflow-hidden"
                                                            style={{ objectFit: "contain" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    modal_picture3: true,
                                                                    modal_return_rent: false,
                                                                    type_modal: "modal_return_rent",
                                                                    index: index
                                                                })
                                                            }}
                                                        ></img>
                                                        <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => {
                                                            let payment_picture = this.state.payment_picture;
                                                            payment_picture.splice(index, 1);
                                                            this.setState({ payment_picture: payment_picture })
                                                        }}>
                                                            {"\uf00d"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {this.state.payment_picture.length === 0 ? (
                                            <div
                                                className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                onClick={() => { document.getElementById("file_sub2").click() }}
                                            >
                                                <label className="icon text-orange">{"\uf03e"}</label>
                                                <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                            </div>
                                        ) : (
                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                <div
                                                    className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                    onClick={() => { document.getElementById("file_sub2").click() }}
                                                >
                                                    <label className="icon text-orange">{"\uf03e"}</label>
                                                    <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card bg-orange p-3 mb-3">
                            <div className="row">
                                <div className="col-3 text-center">
                                    <div className="d-flex">
                                        <div className="w-50">
                                            <label className="text-white mb-1">วันที่เช่า</label>
                                            <h3 className="text-white"><b>
                                                {this.state.days} วัน {this.state.hours} ชั่วโมง {this.state.minutes} นาที
                                            </b></h3>
                                        </div>
                                        <div className="w-50">
                                            <label className="text-white mb-1">ประเภทการเช่า</label>
                                            <h5 className="text-white mt-2"><b>
                                                {
                                                    this.state.rental_type.value === "daily" ? "รายวัน" :
                                                        this.state.rental_type.value === "weekly" ? "รายสัปดาห์ (7วัน)" :
                                                            this.state.rental_type.value === "monthly" ? "รายเดือน (30วัน)" : "-"
                                                }
                                            </b></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 text-center">
                                    <label className="text-white mb-1">ยอดเงินคืนก่อนกำหนด</label>
                                    <div className="w-100 card bg-white text-center">
                                        <h3 className="text-orange"><b>
                                            {
                                                toFixed(
                                                    Number(this.state.refund_cost)
                                                )
                                            }
                                        </b></h3>
                                    </div>
                                </div>
                                <div className="col-3 text-center">
                                    <label className="text-white mb-1">ยอดชำระเงินทั้งหมด</label>
                                    <div className="w-100 card bg-white text-center">
                                        <h3 className="text-orange"><b>
                                            {
                                                (
                                                    Number(this.state.fuel_cost) +
                                                    Number(this.state.damage_cost) +
                                                    Number(this.state.service_charge) +
                                                    Number(this.state.service_charge_overdue) +
                                                    Number(this.state.other_expenses) -
                                                    Number(this.state.discount_pay) +
                                                    Number(this.state.rental_price_overdue) +
                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                ) < 0 ? "0.00" :
                                                    toFixed(
                                                        Number(this.state.fuel_cost) +
                                                        Number(this.state.damage_cost) +
                                                        Number(this.state.service_charge) +
                                                        Number(this.state.service_charge_overdue) +
                                                        Number(this.state.other_expenses) -
                                                        Number(this.state.discount_pay) +
                                                        Number(this.state.rental_price_overdue) +
                                                        (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                    )
                                            }
                                        </b></h3>
                                    </div>
                                </div>
                                <div className="col-3 text-center">
                                    <label className="text-white mb-1">เงินทอน</label>
                                    <h3 className="text-white"><b>
                                        {
                                            toFixed(
                                                (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (
                                                    Number(this.state.fuel_cost) +
                                                    Number(this.state.damage_cost) +
                                                    Number(this.state.service_charge) +
                                                    Number(this.state.service_charge_overdue) +
                                                    Number(this.state.other_expenses) -
                                                    Number(this.state.discount_pay) -
                                                    Number(this.state.guarantee_amount) -
                                                    (Number(this.state.point) * 20) -
                                                    Number(this.state.sk_wallet) +
                                                    Number(this.state.rental_price_overdue) +
                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                )
                                            )

                                        }
                                    </b></h3>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_return_rent: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.ReturnCar();
                                    }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* เปลี่ยนรถ/สถานที่ */}
                <Modal show={this.state.modal_location} onHide={() => { this.setState({ modal_location: false }) }} size="lg">
                    <Modal.Body>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <h3><b>เปลี่ยนรถ/สถานที่</b></h3>
                            {status_rent(this.state.rental_status)}
                        </div>
                        <hr />
                        <div className="row">
                            {/* รหัสบัตรประชาชน หรือ พาสปอร์ต */}
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">รหัสบัตรประชาชน หรือ พาสปอร์ต{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ id_card: e.target.value })
                                        }}
                                        value={this.state.id_card}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* ชื่อ- นามสกุล */}
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">ชื่อ- นามสกุล{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ full_name: e.target.value })
                                        }}
                                        value={this.state.full_name}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* เบอร์โทรศัพท์ */}
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">เบอร์โทรศัพท์{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ phone: e.target.value })
                                        }}
                                        value={this.state.phone}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* อีเมล */}
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">อีเมล{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                        value={this.state.email}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* ข้อมูลรถ */}
                            <div className="col-12">
                                <div className="d-flex justify-content-between">
                                    <h4><b>ข้อมูลรถ</b></h4>
                                    {this.state.rental_status !== 9 && (
                                        <button className="btn btn-outline-orange px-3"
                                            onClick={() => {
                                                this.setState({ modal_car: true, modal_location: false, search_bike: "" });
                                                setTimeout(() => {
                                                    this.GetBikeAll()
                                                }, 10);
                                            }}>
                                            แก้ไข
                                        </button>
                                    )}
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3 text-center">
                                        <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-fluid w-100 rounded" style={{ maxHeight: 300, objectFit: "contain" }} />
                                    </div>
                                    <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                        <h6><b>รายละเอียดรถ</b></h6>
                                        <div className="row">
                                            <div className="col-12 d-flex mb-2">
                                                <b className="wpx-50">ยี่ห้อ</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">ชื่อรุ่น</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">ทะเบียน</b><label>: {this.state.selected_bike ? this.state.selected_bike.license_plate : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">สี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_color : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">ปี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_year + 543 : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-100">หมายเลขรถ</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_channel_name ? this.state.selected_bike.bike_channel_name : this.state.selected_bike.channel_name : "-"}</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6><b>วันที่รับ / คืนรถ</b></h6>
                                        <div className="d-flex justify-content-between">
                                            <div className="w-100">
                                                <b className="wpx-40">วันที่รับรถ</b>
                                                <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                <b className="my-2">สถานที่รับรถ</b>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    value={this.state.pick_up_location}
                                                    onChange={(e) => { this.setState({ pick_up_location: e.target.value }) }}
                                                />
                                            </div>
                                            <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                <label className="icon text-24 text-orange">{"\uf356"}</label>
                                            </div>
                                            <div className="w-100">
                                                <b className="wpx-40">วันที่คืนรถ</b>
                                                <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                <b className="my-2">สถานที่คืนรถ</b>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    value={this.state.return_location}
                                                    onChange={(e) => { this.setState({ return_location: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-end mt-3 mb-2">
                                            <h4 className="text-orange">{this.state.selected_bike ? this.state.selected_bike.daily_price : "-"}  บาท/วัน</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 px-1">
                                        <div className="card bg-orange w-100 p-2">
                                            <div className="row">
                                                <div className="col-4 text-center">
                                                    <label className="text-white">จำนวน (วัน)</label>
                                                    <h3 className="text-white"><b>{this.state.days}</b></h3>
                                                </div>
                                                <div className="col-4 text-center">
                                                    <label className="text-white">ราคารถเก่า</label>
                                                    <h3 className="text-white"><b>{toFixed(this.state.old_price)}</b></h3>
                                                </div>
                                                <div className="col-4 text-center">
                                                    <label className="text-white">ราคารถใหม่</label>
                                                    <h3 className="text-white"><b>{toFixed(this.state.rental_price)}</b></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* สภาพรถ */}
                            <div className="col-12">
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-3">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="mb-0"><b>สภาพรถ</b></h4>
                                        </div>
                                        <hr />
                                        <div className="w-100 pt-3">
                                            <div className="row mx-0">
                                                <input className="d-none" type="file" id="file_sub" accept="image/*" onChange={(e) => { this.import_file2(e, "sub") }} multiple />
                                                <div className="col-12 mr-auto">
                                                    <div className="row">
                                                        {this.state.detail_picture.filter(e => e.length !== 0).map((item, index) => (
                                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                                <div
                                                                    className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                                >
                                                                    <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                                        <img alt="รูปโปรไฟล์" src={item.includes("https://") ? item : "data:image/png;base64," + item} className="w-100 hpx-140 rounded overflow-hidden" style={{ objectFit: "contain" }}></img>
                                                                        <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.delete_image(item) }}>
                                                                            {"\uf00d"}
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {this.state.detail_picture.filter(e => e.length !== 0).length !== 10 && (
                                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                                <div
                                                                    className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                                    onClick={() => { document.getElementById("file_sub").click() }}
                                                                >
                                                                    <label className="icon text-orange">{"\uf03e"}</label>
                                                                    <label className="text-orange text-12">รูปภาพสภาพรถ</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {this.state.detail_picture.filter((e, index) => e.length === 0 && index !== 9).map((item, index) => (
                                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                                <div className="card w-100 hpx-150 bg-contain">
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-7">
                                {/* เปลี่ยนรถ */}
                                <h5 className="mt-3"><b>เปลี่ยนรถ</b></h5>
                                <hr />
                                <div className="row mx-0">
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค่าบริการรับส่ง */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ค่าส่วนต่างการเปลี่ยนรถ :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.rental_price - this.state.old_price)}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* คิดค่าส่วนต่างรวมในยอดเงิน */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">คิดค่าส่วนต่างรวมในยอดเงิน :</label><br />
                                            <Select
                                                className="form-control validate select-search"
                                                styles={select_style}
                                                options={[
                                                    { value: "คิดส่วนต่าง", label: "คิดส่วนต่าง" },
                                                    { value: "ไม่คิดส่วนต่าง", label: "ไม่คิดส่วนต่าง" }
                                                ]}
                                                onChange={(e) => {
                                                    this.setState({
                                                        status_pay: e,
                                                    });
                                                }}
                                                value={this.state.status_pay}
                                                isDisabled={this.state.rental_price - this.state.old_price < 0}
                                            ></Select>
                                        </div>
                                    </div>
                                </div>
                                {/* เปลี่ยนสถานที่ */}
                                <h5 className="mt-3"><b>เปลี่ยนสถานที่</b></h5>
                                <hr />
                                <div className="row mx-0">
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค่าบริการรับส่ง */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">ค่าบริการรับส่ง :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ service_charge: e.target.value })
                                                }}
                                                value={this.state.service_charge}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* ฟอร์มชำระเงิน */}
                                <h5 className="mt-3"><b>ฟอร์มชำระเงิน</b></h5>
                                <hr />
                                <div className="row mx-0">
                                    <div className="col-12 col-sm-6 px-1">
                                        {/* สถานะการชำระเงิน */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">สถานะการชำระเงิน :</label><br />
                                            <Select
                                                className="form-control validate select-search"
                                                styles={select_style}
                                                options={[
                                                    { value: "ชำระ", label: "ชำระ" },
                                                    { value: "ไม่ชำระ", label: "ไม่ชำระ" }
                                                ]}
                                                onChange={(e) => {
                                                    this.setState({
                                                        status_pay2: e,
                                                        payment_type: "",
                                                        bank_name: "",
                                                        payment_date: "",
                                                        payment_time: "",
                                                        total_pay: 0,
                                                        total_pay_transfer: 0,
                                                        total_pay_cradit: 0,
                                                        use_point: false,
                                                        point: 0,
                                                        use_sk_wallet: false,
                                                        sk_wallet: 0
                                                    });
                                                }}
                                                value={this.state.status_pay2}
                                            ></Select>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 px-1">
                                        {/* หมายเหตุ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">หมายเหตุ :</label><br />
                                            <Select
                                                className="form-control validate select-search"
                                                styles={select_style}
                                                options={[
                                                    { value: "รถเสีย", label: "รถเสีย" },
                                                    { value: "เปลี่ยนรถ", label: "เปลี่ยนรถ" }
                                                ]}
                                                onChange={(e) => {
                                                    this.setState({
                                                        remark: e,
                                                    });
                                                }}
                                                value={this.state.remark}
                                            ></Select>
                                        </div>
                                    </div>
                                </div>
                                {this.state.status_pay2 && this.state.status_pay2.value === "ชำระ" && (
                                    <div>
                                        <div className="row mx-0">
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* เงินสด */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">เงินสด :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({ total_pay: e.target.value })
                                                        }}
                                                        value={this.state.total_pay}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* เงินโอน */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">เงินโอน :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({ total_pay_transfer: e.target.value })
                                                        }}
                                                        value={this.state.total_pay_transfer}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* บัตรเครดิต */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">บัตรเครดิต :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({ total_pay_cradit: e.target.value })
                                                        }}
                                                        value={this.state.total_pay_cradit}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* วันที่โอน */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">วันที่โอน :</label><br />
                                                    <DatePickerTH
                                                        className="form-control"
                                                        placeholder="พิมพ์คำค้นหา..."
                                                        onChange={(e) => {
                                                            this.setState({ payment_date: e });
                                                        }}
                                                        value={this.state.payment_date}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* เวลาที่โอน */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">เวลาที่โอน :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="time"
                                                        placeholder="กรอกข้อมูล"
                                                        value={this.state.payment_time}
                                                        onChange={(e) => { this.setState({ payment_time: e.target.value }) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* บัญชี */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">บัญชี :</label><br />
                                                    <Select
                                                        className="form-control select-search bg-white mb-2"
                                                        styles={select_style}
                                                        options={this.state.data_bank ? this.state.data_bank : []}
                                                        onChange={(e) => {
                                                            this.setState({ bank_name: e });
                                                        }}
                                                        value={this.state.bank_name}
                                                    ></Select>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.wallet && this.state.wallet.point ? (
                                            <div className="row mx-0">
                                                <div className="col-12 col-sm-6 col-md-4 px-1">
                                                    {/* ใช้แต้ม */}
                                                    <div className="w-100 mb-2">
                                                        <label className="w-100 mb-2">ใช้แต้ม :</label><br />
                                                        <div className="d-flex align-items-center">
                                                            <Switch onChange={(e) => {
                                                                this.setState({
                                                                    use_point: e,
                                                                    point: 0
                                                                })
                                                            }} checked={this.state.use_point} />
                                                            <label className="mx-2">แต้มสะสม <b>{toFixed(this.state.wallet.point)}</b> แต้ม</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.use_point && (
                                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                                        {/* จำนวนแต้ม */}
                                                        <div className="w-100 mb-2">
                                                            <label className="w-100 mb-2">จำนวนแต้ม : <small>(10 แต้ม เท่ากับ 200 บาท)</small></label><br />
                                                            <input
                                                                className="form-control validate"
                                                                type="text"
                                                                placeholder="กรอกข้อมูล"
                                                                onChange={(e) => {
                                                                    if (!float(e.target.value)) {
                                                                        return;
                                                                    }
                                                                    if (Number(e.target.value) > this.state.wallet.point) {
                                                                        e.target.value = this.state.wallet.point;
                                                                    }
                                                                    this.setState({ point: e.target.value })
                                                                }}
                                                                onBlur={() => {
                                                                    let point = 0;
                                                                    if (Number(this.state.point) % 10 !== 0) {
                                                                        point = Number(this.state.point) - (Number(this.state.point) % 10);
                                                                    } else {
                                                                        point = this.state.point;
                                                                    }
                                                                    this.setState({ point: point });
                                                                }}
                                                                value={this.state.point}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {this.state.use_point && (
                                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                                        {/* แลกส่วนลดได้ */}
                                                        <div className="w-100 mb-2">
                                                            <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                            <input
                                                                className="form-control validate"
                                                                type="text"
                                                                placeholder="กรอกข้อมูล"
                                                                value={toFixed(Number(this.state.point) * 20)}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                        {this.state.wallet && this.state.wallet.sk_wallet ? (
                                            <div className="row mx-0">
                                                <div className="col-12 col-sm-6 col-md-4 px-1">
                                                    {/* ใช้เงินสะสม */}
                                                    <div className="w-100 mb-2">
                                                        <label className="w-100 mb-2">ใช้เงินสะสม :</label><br />
                                                        <div className="d-flex align-items-center">
                                                            <Switch onChange={(e) => {
                                                                this.setState({
                                                                    use_sk_wallet: e,
                                                                    sk_wallet: 0
                                                                })
                                                            }} checked={this.state.use_sk_wallet} />
                                                            <label className="mx-2">เงินสะสม <b>{toFixed(this.state.wallet.sk_wallet)}</b> บาท</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.use_sk_wallet && (
                                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                                        {/* ต้องการใช้เงินสะสม */}
                                                        <div className="w-100 mb-2">
                                                            <label className="w-100 mb-2">ต้องการใช้เงินสะสม :</label><br />
                                                            <input
                                                                className="form-control validate"
                                                                type="text"
                                                                placeholder="กรอกข้อมูล"
                                                                onChange={(e) => {
                                                                    if (!float(e.target.value)) {
                                                                        return;
                                                                    }
                                                                    if (Number(e.target.value) > this.state.wallet.sk_wallet) {
                                                                        e.target.value = this.state.wallet.sk_wallet;
                                                                    }
                                                                    this.setState({ sk_wallet: e.target.value })
                                                                }}
                                                                value={this.state.sk_wallet}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {this.state.use_sk_wallet && (
                                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                                        {/* แลกส่วนลดได้ */}
                                                        <div className="w-100 mb-2">
                                                            <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                            <input
                                                                className="form-control validate"
                                                                type="text"
                                                                placeholder="กรอกข้อมูล"
                                                                value={toFixed(Number(this.state.sk_wallet))}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-xl-5 py-3">
                                <div className="row">
                                    <input className="d-none" type="file" id="file_sub2" accept="image/*" onChange={(e) => { this.import_file3(e, "sub2") }} multiple />
                                    {this.state.payment_picture.map((item, index) => (
                                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                            <div
                                                className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                            >
                                                <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                    <img
                                                        alt="รูปภาพสลิปหลักฐาน"
                                                        src={item.picture.includes("https://") ? item.picture : "data:image/png;base64," + item.picture}
                                                        className="w-100 hpx-140 rounded overflow-hidden"
                                                        style={{ objectFit: "contain" }}
                                                        onClick={() => {
                                                            this.setState({
                                                                modal_picture3: true,
                                                                modal_location: false,
                                                                type_modal: "modal_location",
                                                                index: index
                                                            })
                                                        }}
                                                    ></img>
                                                    <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => {
                                                        let payment_picture = this.state.payment_picture;
                                                        payment_picture.splice(index, 1);
                                                        this.setState({ payment_picture: payment_picture })
                                                    }}>
                                                        {"\uf00d"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {this.state.payment_picture.length === 0 ? (
                                        <div
                                            className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                            onClick={() => { document.getElementById("file_sub2").click() }}
                                        >
                                            <label className="icon text-orange">{"\uf03e"}</label>
                                            <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                        </div>
                                    ) : (
                                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                            <div
                                                className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                onClick={() => { document.getElementById("file_sub2").click() }}
                                            >
                                                <label className="icon text-orange">{"\uf03e"}</label>
                                                <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="card bg-orange p-3 mb-3">
                            <div className="row">
                                <div className="col-4 text-center">
                                    <label className="text-white mb-1">ยอดเงินคืนก่อนกำหนด</label>
                                    <div className="w-100 card bg-white text-center">
                                        <h3 className="text-orange"><b>
                                            {
                                                toFixed(Number(this.state.refund_cost))
                                            }
                                        </b></h3>
                                    </div>
                                </div>
                                <div className="col-4 text-center">
                                    <label className="text-white mb-1">ยอดชำระเงินทั้งหมด</label>
                                    <div className="w-100 card bg-white text-center">
                                        <h3 className="text-orange">
                                            <b>
                                                {
                                                    (this.state.status_pay && this.state.status_pay.value === "คิดส่วนต่าง") ?
                                                        toFixed((this.state.rental_price - this.state.old_price) + Number(this.state.service_charge)) :
                                                        toFixed(Number(this.state.service_charge))
                                                }
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-4 text-center">
                                    <label className="text-white mb-1">เงินทอน</label>
                                    <h3 className="text-white"><b>
                                        {
                                            (this.state.status_pay && this.state.status_pay.value === "คิดส่วนต่าง") ?
                                                toFixed(((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet)) - ((this.state.rental_price - this.state.old_price) + (Number(this.state.service_charge)))) :
                                                toFixed(((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet)) - (Number(this.state.service_charge)))
                                        }
                                    </b></h3>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_location: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={async () => {

                                        await this.UpdateCar();
                                        this.Location();
                                    }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* เปลี่ยนวันเวลาคืน */}
                <Modal show={this.state.modal_date_time} onHide={() => { this.setState({ modal_date_time: false }) }}>
                    <Modal.Body>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <h3><b>เปลี่ยนวันเวลาคืนรถ {this.state.rental_number}</b></h3>
                        </div>
                        {status_rent(this.state.rental_status)}
                        <hr />
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-5">
                                        <label className="w-100 mb-1">วันที่เริ่มต้นการเช่า :</label><br />
                                        <label className="w-100 mb-1">{format_date(this.state.pick_up_date, "dd/mm/yyyy")} {this.state.pick_up_time}</label>
                                    </div>
                                    <div className="col-5">
                                        <label className="w-100 mb-1">วันที่สิ้นสุดการเช่า :</label><br />
                                        <label className="w-100 mb-1">{format_date(this.state.return_date, "dd/mm/yyyy")} {this.state.return_time}</label>
                                    </div>
                                    <div className="col-2">
                                        <label className="w-100 mb-1">จำนวน :</label><br />
                                        <label className="w-100 mb-1">{this.state.days} วัน</label>
                                    </div>
                                    <div className="col-12"><hr /></div>
                                    <div className="col-6">
                                        {/* วันที่คืนรถใหม่ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">วันที่คืนรถใหม่ :</label><br />
                                            <DatePickerTH
                                                className="form-control validate"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ return_date_new: e });
                                                    if (format_date(this.state.return_date_new) === format_date(e)) {
                                                        if (this.state.return_time_new && Number(this.state.return_time.slice(0, 5).replaceAll(":", "")) < Number(this.state.return_time_new.slice(0, 5).replaceAll(":", ""))) {
                                                            alert("warning", "แจ้งเตือน", "ไม่สามารถเลือกเวลาที่มากกว่าวันที่สิ้นสุดการเช่าได้")
                                                            this.setState({ return_time_new: this.state.return_time });
                                                            return
                                                        }
                                                    }
                                                }}
                                                value={this.state.return_date_new}
                                                min={new Date(this.state.pick_up_date).setDate(new Date(this.state.pick_up_date).getDate() - 1)}
                                                max={new Date(this.state.return_date)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* เวลาคืนรถใหม่ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">เวลาคืนรถใหม่ :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="time"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (format_date(this.state.return_date_new) === format_date(this.state.return_date)) {
                                                        if (Number(this.state.return_time.slice(0, 5).replaceAll(":", "")) < Number(e.target.value.slice(0, 5).replaceAll(":", ""))) {
                                                            alert("warning", "แจ้งเตือน", "ไม่สามารถเลือกเวลาที่มากกว่าวันที่สิ้นสุดการเช่าได้")
                                                            this.setState({ return_time_new: this.state.return_time });
                                                            return
                                                        }
                                                    }
                                                    this.setState({ return_time_new: e.target.value });
                                                }}
                                                value={this.state.return_time_new}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* คืนก่อนกำหนด {this.state.rental_price} */}
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_date_time: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.changeDateTime();
                                    }}
                                >
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete_rent} onHide={() => { this.setState({ modal_delete_rent: false }) }}>
                    <Modal.Header><Modal.Title><b>ยกเลิกรายการ</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                ท่านแจ้งยกเลิกรายการเช่า “{this.state.rental_number}” ใช่หรือไม่
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete_rent: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* รายละเอียด หลังคืนรถ */}
                <Modal show={this.state.modal_detail} onHide={() => { this.setState({ modal_detail: false }) }} dialogClassName="modal-w95">
                    <Modal.Body>
                        {this.state.detail && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                        <h3><b>รายละเอียดใบเช่า {this.state.rental_number}</b></h3>{status_rent(this.state.rental_status)}
                                    </div>
                                    <hr />
                                </div>
                                {/* ข้อมูลผู้เช่า */}
                                <div className="col-12">
                                    <h4 className="mb-3"><b>ข้อมูลผู้เช่า</b></h4>
                                    <div className="row mx-0">
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>รหัสบัตรประชาชน หรือ พาสปอร์ต : </b><br />
                                            <span>{this.state.detail.id_card ? this.state.detail.id_card : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>ชื่อ- นามสกุล : </b><br />
                                            <span>{this.state.detail.full_name ? this.state.detail.full_name : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>วันเกิด : </b><br />
                                            <span>{this.state.detail.birthday ? this.state.detail.birthday : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>สัญชาติ : </b><br />
                                            <span>{this.state.detail.nationality ? this.state.detail.nationality : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>ผู้ออกบัตร : </b><br />
                                            <span>{this.state.detail.card_issue ? this.state.detail.card_issue : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>วันออกบัตร : </b><br />
                                            <span>{this.state.detail.date_issue ? this.state.detail.date_issue : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>วันที่หมดอายุ : </b><br />
                                            <span>{this.state.detail.date_expire ? this.state.detail.date_expire : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>ที่พักอาศัยชั่วคราว : </b><br />
                                            <span>{this.state.detail.temporary_accommodation ? this.state.detail.temporary_accommodation : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>facebook : </b><br />
                                            <span>{this.state.detail.facebook ? this.state.detail.facebook : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>เบอร์โทรศัพท์ : </b><br />
                                            <span>{this.state.detail.phone ? this.state.detail.phone : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>อีเมล : </b><br />
                                            <span>{this.state.detail.email ? this.state.detail.email : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mb-2 px-1">
                                            <b>ไลน์ไอดี : </b><br />
                                            <span>{this.state.detail.id_line ? this.state.detail.id_line : "-"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12"><hr /></div>
                                {/* ข้อมูลรถ */}
                                <div className="col-12 col-md-8">
                                    <h4 className="mb-3"><b>ข้อมูลรถ</b></h4>
                                    <div className="row mx-0">
                                        <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3">
                                            <img src={this.state.detail ? this.state.detail.title_picture ? this.state.detail.title_picture : CAR : CAR} className="img-responsive w-100 rounded" style={{ height: "100%", objectFit: "contain", backgroundColor: "#dddddd" }} />
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                            <h6><b>รายละเอียดรถ</b></h6>
                                            <div className="row">
                                                <div className="col-12 d-flex mb-2">
                                                    <b className="wpx-50">ยี่ห้อ</b><label>: {this.state.detail ? this.state.detail.brand_name : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-50">ชื่อรุ่น</b><label>: {this.state.detail ? this.state.detail.model_name : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-50">ทะเบียน</b><label>: {this.state.detail ? this.state.detail.license_plate : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-50">สี</b><label>: {this.state.detail ? this.state.detail.bike_color : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-50">ปี</b><label>: {this.state.detail ? this.state.detail.bike_year + 543 : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-100">หมายเลขรถ</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_channel_name ? this.state.selected_bike.bike_channel_name : this.state.selected_bike.channel_name : "-"}</label>
                                                </div>
                                                <div className="col-12 d-flex justify-content-end">
                                                    <h4 className="text-orange">{this.state.detail ? this.state.detail.daily_price : "-"}  บาท/วัน</h4>
                                                </div>
                                            </div>
                                            <hr />
                                            <h6><b>วันที่รับ / คืนรถ</b></h6>
                                            <div className="d-flex justify-content-between">
                                                <div className="w-100">
                                                    <b className="wpx-40">วันที่รับรถ</b>
                                                    <label className="ml-2">{this.state.detail.pick_up_date ? format_date(this.state.detail.pick_up_date, "dd mmm yyyy") : "-"} {this.state.detail.pick_up_time ? this.state.detail.pick_up_time : "-"}</label><br />
                                                    <b className="my-2">สถานที่รับรถ{required}</b>
                                                    <h5 className="my-2 text-orange">{this.state.detail.pick_up_location}</h5>
                                                </div>
                                                <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                    <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                </div>
                                                <div className="w-100">
                                                    <b className="wpx-40">วันที่คืนรถ</b>
                                                    <label className="ml-2">{this.state.detail.return_date ? format_date(this.state.detail.return_date, "dd mmm yyyy") : "-"} {this.state.detail.return_time ? this.state.detail.return_time : "-"}</label><br />
                                                    <b className="my-2">สถานที่คืนรถ{required}</b>
                                                    <h5 className="my-2 text-orange">{this.state.detail.return_location}</h5>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* อุปกรณ์รถ */}
                                <div className="col-12 col-md-4">
                                    <h4 className="mb-3"><b>อุปกรณ์รถ</b></h4>
                                    <div className="row mx-0">
                                        <table className="table table-borderless table-striped">
                                            <thead>
                                                <tr className="text-white bg-dark">
                                                    <th className="text-center">อุปกรณ์</th>
                                                    <th className="text-center">จำนวน</th>
                                                    <th className="text-center">ราคา</th>
                                                    <th className="text-center">ราคารวม</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.detail.equipment_list.map((item, index) => (
                                                    <tr>
                                                        <td className="text-left">{item.equipment_name}</td>
                                                        <td className="text-center">{item.equipment_qty}</td>
                                                        <td className="text-center">{toFixed(item.equipment_price)}</td>
                                                        <td className="text-center">{toFixed(item.equipment_qty * item.equipment_price)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="w-100 d-flex align-items-end justify-content-end">
                                            <label>รวมค่าอุปกรณ์ทั้งหมด</label>
                                            <h4 className="mx-2 mb-0"><b>{toFixed(this.state.detail.total_price_equipment)}</b></h4>
                                            <label>บาท</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12"><hr /></div>
                                {/* รายละเอียดการเช่า */}
                                <div className="col-12">
                                    <h4 className="mb-3"><b>รายละเอียดการเช่า</b></h4>
                                    <div className="row mx-0">
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>ระยะเวลาเช่า : </b>
                                            <span>{this.state.detail.id_card ? this.state.detail.id_card : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>เลขไมล์ : </b>
                                            <span>{this.state.detail.mileage ? this.state.detail.mileage : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>ปริมาณน้ำมัน (%) : </b>
                                            <span>{this.state.detail.oil_volume ? this.state.detail.oil_volume : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>ราคาเช่า : </b>
                                            <span>{this.state.detail.rental_price ? toFixed(this.state.detail.rental_price) + " บาท" : "0.00"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>ประเภทการค้ำประกัน : </b>
                                            <span>
                                                {
                                                    this.state.detail ?
                                                        this.state.detail.guarantee_type === 1 ? "เงินประกัน" :
                                                            this.state.detail.guarantee_type === 2 ? "สำเนาบัตรประชาชน" :
                                                                this.state.detail.guarantee_type === 3 ? "บัตรประชาชนตัวจริง" :
                                                                    this.state.detail.guarantee_type === 4 ? "บัตรข้าราชการ" :
                                                                        this.state.detail.guarantee_type === 5 ? "บัตรนักศึกษา" :
                                                                            this.state.detail.guarantee_type === 6 ? "พาสปอร์ตตัวจริง" :
                                                                                this.state.detail.guarantee_type === 7 ? "สำเนาพาสปอร์ต" :
                                                                                    this.state.detail.guarantee_type === 8 ? "ใบขับขี่" :
                                                                                        this.state.detail.guarantee_type === 9 ? "ใบขับขี่ตัวจริง" :
                                                                                            this.state.detail.guarantee_type === 10 ? "เงินหยวน" :
                                                                                                this.state.detail.guarantee_type === 11 ? "เงินดอลล่า" :
                                                                                                    this.state.detail.guarantee_type === 12 ? "เงินยูโร" :
                                                                                                        "" :
                                                        ""
                                                }
                                            </span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>จำนวนเงินค้ำประกัน : </b>
                                            <span>{this.state.detail.guarantee_amount ? toFixed(this.state.detail.guarantee_amount) + " บาท" : "0.00"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>ค่าอุปกรณ์ : </b>
                                            <span>{this.state.detail.equipment_fee ? toFixed(this.state.detail.equipment_fee) + " บาท" : "0.00"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>ค่าบริการรับส่ง : </b>
                                            <span>{this.state.detail.delivery_price ? toFixed(this.state.detail.delivery_price) + " บาท" : "0.00"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>ค่าอื่นๆ : </b>
                                            <span>{this.state.detail.other_price ? toFixed(this.state.detail.other_price) + " บาท" : "0.00"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>ส่วนลด : </b>
                                            <span>{this.state.detail.discount_pay ? toFixed(this.state.detail.discount_pay) + " บาท" : "0.00"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>ผู้แนะนำ : </b>
                                            <span>{this.state.detail.agent_name ? this.state.detail.agent_name : "-"}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-1 d-flex justify-content-between">
                                            <b>พนักงาน : </b>
                                            <span>{this.state.detail.admin_name ? this.state.detail.admin_name : "-"}</span>
                                        </div>
                                        <div className="col-12">
                                            <div className="card bg-orange w-100 py-3 px-3">
                                                <div className="text-riht d-flex align-items-center justify-content-end">
                                                    <label className="text-white mx-1">ยอดชำระเงินทั้งหมด</label>
                                                    <h3 className="text-white ml-auto mr-1 mb-0"><b>{this.state.detail.total_price ? toFixed(this.state.detail.total_price) : "-"}</b></h3>
                                                    <label className="text-white mx-1">บาท</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12"><hr /></div>
                                {/* รายละเอียดการเช่า (ชำระเงิน) */}
                                <div className="col-12">
                                    <h4 className="mb-3"><b>รายละเอียดการเช่า (ชำระเงิน)</b></h4>
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr className="text-white bg-dark">
                                                <th className="text-center">ใบเสร็จ</th>
                                                <th className="text-center">วันเวลา</th>
                                                <th className="text-center">ยอดเงิน</th>
                                                <th className="text-center">พนักงาน</th>
                                                <th className="text-center">หมายเหตุ</th>
                                                <th className="text-center wpx-70">รูปสลิป</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.detail.transaction_list && this.state.detail.transaction_list.filter(item => item.event_type === 1).map((item, index) => (
                                                <tr>
                                                    <td className="text-left">{item.payment_number}</td>
                                                    <td className="text-center">{format_date(item.payment_date) + item.payment_time + " น."}</td>
                                                    <td className="text-center">{toFixed(item.total_price)}</td>
                                                    <td className="text-center">{item.admin_name}</td>
                                                    <td className="text-center">{item.admin_remark}</td><td className="text-center">
                                                        {item.payment_picture[0] && (
                                                            <img
                                                                alt="รูปภาพสลิปหลักฐาน"
                                                                src={item.payment_picture[0].picture.includes("https://") ? item.payment_picture[0].picture : "data:image/png;base64," + item.payment_picture[0].picture}
                                                                className="wpx-30 hpx-30 bg-secondary-light rounded overflow-hidden"
                                                                style={{ objectFit: "contain" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_picture2: true,
                                                                        modal_detail: false,
                                                                        type_modal: "modal_detail",
                                                                        payment_picture: item.payment_picture,
                                                                        index: index
                                                                    })
                                                                }}
                                                            ></img>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12"><hr /></div>
                                {/* รายละเอียดการขยายสัญญา (ชำระเงิน) */}
                                <div className="col-12">
                                    <h4 className="mb-3"><b>รายละเอียดการขยายสัญญา (ชำระเงิน)</b></h4>
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr className="text-white bg-dark">
                                                <th className="text-center">ใบเสร็จ</th>
                                                <th className="text-center">วันเวลา</th>
                                                <th className="text-center">ยอดเงิน</th>
                                                <th className="text-center">ชำระด้วยคะแนน</th>
                                                <th className="text-center">ชำระด้วยยอดเงินสะสม</th>
                                                <th className="text-center">วันเวลาที่ขยาย</th>
                                                <th className="text-center">พนักงาน</th>
                                                <th className="text-center">หมายเหตุ</th>
                                                <th className="text-center wpx-70">รูปสลิป</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.detail.transaction_list && this.state.detail.transaction_list.filter(item => item.event_type === 2).map((item, index) => (
                                                <tr>
                                                    <td className="text-left">{item.payment_number}</td>
                                                    <td className="text-center">{format_date(item.payment_date) + item.payment_time + " น."}</td>
                                                    <td className="text-center">{toFixed(item.total_price)}</td>
                                                    <td className="text-center">{item.spend_points * 20}</td>
                                                    <td className="text-center">{toFixed(item.spend_wallet)}</td>
                                                    <td className="text-center">{item.event_extend.return_date_new && item.event_extend.return_time_new && format_date(item.event_extend.return_date_new) + item.event_extend.return_time_new + " น."}</td>
                                                    <td className="text-center">{item.admin_name}</td>
                                                    <td className="text-center">{item.admin_remark}</td><td className="text-center">
                                                        {item.payment_picture[0] && (
                                                            <img
                                                                alt="รูปภาพสลิปหลักฐาน"
                                                                src={item.payment_picture[0].picture.includes("https://") ? item.payment_picture[0].picture : "data:image/png;base64," + item.payment_picture[0].picture}
                                                                className="wpx-30 hpx-30 bg-secondary-light rounded overflow-hidden"
                                                                style={{ objectFit: "contain" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_picture2: true,
                                                                        modal_detail: false,
                                                                        type_modal: "modal_detail",
                                                                        payment_picture: item.payment_picture,
                                                                        index: index
                                                                    })
                                                                }}
                                                            ></img>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12"><hr /></div>
                                {/* รายละเอียดการเปลี่ยนสถานที่/รถ (ชำระเงิน) */}
                                <div className="col-12">
                                    <h4 className="mb-3"><b>รายละเอียดการเปลี่ยนสถานที่/รถ (ชำระเงิน)</b></h4>
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr className="text-white bg-dark">
                                                <th className="text-center">ใบเสร็จ</th>
                                                <th className="text-center">วันเวลา</th>
                                                <th className="text-center">ยอดเงิน</th>
                                                <th className="text-center">พนักงาน</th>
                                                <th className="text-center">หมายเหตุ</th>
                                                <th className="text-center wpx-70">รูปสลิป</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.detail.transaction_list && this.state.detail.transaction_list.filter(item => item.event_type === 4).map((item, index) => (
                                                <tr>
                                                    <td className="text-left">{item.payment_number}</td>
                                                    <td className="text-center">{format_date(item.payment_date) + item.payment_time + " น."}</td>
                                                    <td className="text-center">{toFixed(item.total_price)}</td>
                                                    <td className="text-center">{item.admin_name}</td>
                                                    <td className="text-center">{item.admin_remark}</td><td className="text-center">
                                                        {item.payment_picture[0] && (
                                                            <img
                                                                alt="รูปภาพสลิปหลักฐาน"
                                                                src={item.payment_picture[0].picture.includes("https://") ? item.payment_picture[0].picture : "data:image/png;base64," + item.payment_picture[0].picture}
                                                                className="wpx-30 hpx-30 bg-secondary-light rounded overflow-hidden"
                                                                style={{ objectFit: "contain" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_picture2: true,
                                                                        modal_detail: false,
                                                                        type_modal: "modal_detail",
                                                                        payment_picture: item.payment_picture,
                                                                        index: index
                                                                    })
                                                                }}
                                                            ></img>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12"><hr /></div>
                                {/* รายละเอียดการคืน (ชำระเงิน) */}
                                <div className="col-12">
                                    <h4 className="mb-3"><b>รายละเอียดการคืน (ชำระเงิน)</b></h4>
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr className="text-white bg-dark">
                                                <th className="text-center">ใบเสร็จ</th>
                                                <th className="text-center">วันเวลา</th>
                                                <th className="text-center">ยอดเงิน</th>
                                                <th className="text-center">พนักงาน</th>
                                                <th className="text-center">หมายเหตุ</th>
                                                <th className="text-center wpx-70">รูปสลิป</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.detail.transaction_list && this.state.detail.transaction_list.filter(item => item.event_type === 3).map((item, index) => (
                                                <tr>
                                                    <td className="text-left">{item.payment_number}</td>
                                                    <td className="text-center">{format_date(item.payment_date) + item.payment_time + " น."}</td>
                                                    <td className="text-center">{toFixed(item.total_price)}</td>
                                                    <td className="text-center">{item.admin_name}</td>
                                                    <td className="text-center">{item.admin_remark}</td><td className="text-center">
                                                        {item.payment_picture[0] && (
                                                            <img
                                                                alt="รูปภาพสลิปหลักฐาน"
                                                                src={item.payment_picture[0].picture.includes("https://") ? item.payment_picture[0].picture : "data:image/png;base64," + item.payment_picture[0].picture}
                                                                className="wpx-30 hpx-30 bg-secondary-light rounded overflow-hidden"
                                                                style={{ objectFit: "contain" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_picture2: true,
                                                                        modal_detail: false,
                                                                        type_modal: "modal_detail",
                                                                        payment_picture: item.payment_picture,
                                                                        index: index
                                                                    })
                                                                }}
                                                            ></img>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex justify-content-center">
                            <button
                                className="btn btn-outline-orange px-5"
                                onClick={() => {
                                    this.setState({ modal_detail: false })
                                }}
                            >
                                ปิดหน้าต่าง
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* car */}
                <Modal show={this.state.modal_car} onHide={() => { this.setState({ modal_car: false, modal_location: true }); }} size="lg">
                    <Modal.Body>
                        <div className="w-100 d-flex justify-content-between mb-2">
                            <h3><b>เลือกรถ</b></h3>
                            <label className="icon text-24 text-danger pointer px-3" onClick={() => {
                                this.setState({ modal_car: false, modal_location: true });
                            }}>{"\uf00d"}</label>
                        </div>
                        <div className="w-100">
                            {/* ค้นหา */}
                            <div className="row bg-orange mb-3">
                                <div className="col-12 p-3 mx-auto">
                                    <div className="card border-0 px-4 py-2 bg-white shadow">
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">วันที่รับรถ{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ pick_up_date: e });
                                                        if (Number(format_date(e, "yyyymmdd")) >= Number(format_date(this.state.return_date, "yyyymmdd"))) {
                                                            this.setState({ return_date: format_date(new Date(format_date(e, "yyyy,mm,dd")).setDate(new Date(format_date(e, "yyyy,mm,dd")).getDate() + 1), "yyyy-mm-dd", "en") });
                                                        }
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.pick_up_date}
                                                    clearable={false}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">เวลารับรถ{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => {
                                                        this.setState({ pick_up_time: e.target.value });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.pick_up_time}
                                                    readOnly />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">วันที่คืนรถ{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ return_date: e });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.return_date}
                                                    clearable={false}
                                                    min={new Date(this.state.pick_up_date).setDate(new Date(this.state.pick_up_date).getDate() - 1)}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">เวลาคืนรถ{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => {
                                                        this.setState({ return_time: e.target.value });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.return_time}
                                                    readOnly />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 px-1">
                                                <b className="text-white mb-2">.</b>
                                                <button className="btn btn-orange w-100 mb-2" onClick={() => {
                                                    this.GetBikeAll()
                                                    if (this.state.selected_bike) {
                                                        this.setState({
                                                            days: days_between(new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en"))),
                                                        });
                                                        setTimeout(() => {
                                                            let rental_price = 0;
                                                            if (this.state.selected_bike) {
                                                                if (this.state.rental_type.value === "daily") {
                                                                    rental_price = this.state.selected_bike.daily_price * this.state.days;
                                                                } else if (this.state.rental_type.value === "weekly") {
                                                                    if (this.state.selected_bike.weekly_price === 0) {
                                                                        rental_price = this.state.selected_bike.daily_price * this.state.days;
                                                                    } else {
                                                                        if (this.state.days >= 7) {
                                                                            let price = 0;
                                                                            price += Number((this.state.days / 7).toString().split(".")[0]) * this.state.selected_bike.weekly_price;
                                                                            price += (this.state.days % 7) * this.state.selected_bike.daily_price;
                                                                            rental_price = price;
                                                                        } else {
                                                                            rental_price = this.state.selected_bike.weekly_price;
                                                                        }
                                                                    }
                                                                } else if (this.state.rental_type.value === "monthly") {
                                                                    if (this.state.selected_bike.monthly_price === 0) {
                                                                        rental_price = this.state.selected_bike.daily_price * this.state.days;
                                                                    } else {
                                                                        if (this.state.days > 30) {
                                                                            let price = 0;
                                                                            price += Number((this.state.days / 30).toString().split(".")[0]) * this.state.selected_bike.monthly_price;
                                                                            price += Number(((this.state.days % 30) / 7).toString().split(".")[0]) * this.state.selected_bike.weekly_price;
                                                                            price += ((this.state.days % 30) % 7) * this.state.selected_bike.daily_price;
                                                                            rental_price = price;
                                                                        } else {
                                                                            rental_price = this.state.selected_bike.monthly_price;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            if (rental_price <= this.state.old_price) {
                                                                this.setState({
                                                                    modal_car: false,
                                                                    modal_location: true,
                                                                    rental_price: rental_price,

                                                                })
                                                            } else {
                                                                alert("warning", "แจ้งเตือน", "ไม่สามารถเลือกรถที่ราคาสูงกว่าราคาปัจจุบันได้");
                                                                return
                                                            }
                                                        }, 10);
                                                        setTimeout(() => {
                                                            this.setState({ status_pay: this.state.rental_price - this.state.old_price < 0 ? { value: "ไม่คิดส่วนต่าง", label: "ไม่คิดส่วนต่าง" } : this.state.status_pay })
                                                        }, 50);
                                                    }
                                                }}>
                                                    ค้นหา
                                                    <label className="icon ml-2">{"\uf002"}</label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* รายการรถ */}
                            <div className="row">
                                <div className="col-12 pb-3 px-4">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <h4><b>ผลการค้นหา: รถว่างทั้งหมด</b></h4>
                                            <label className="text-secondary mb-3">พบรถว่าง {this.state.data_bike.length} คัน</label>
                                        </div>
                                        <div className="wpx-250 pb-2">
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-2"
                                                placeholder="เช่น ยี่ห้อ,รุ่น"
                                                onChange={(e) => { this.setState({ search_bike: e.target.value }); }}
                                                value={this.state.search_bike} />
                                        </div>
                                    </div>
                                    <div className="row overflow-scroll" style={{ maxHeight: "70vh" }}>
                                        {this.state.data_bike && this.state.data_bike.filter(item => this.state.search_bike === "" || (item.channel_name === this.state.search_bike) || item.model_name.toUpperCase().includes(this.state.search_bike.toUpperCase()) || item.brand_name.toUpperCase().includes(this.state.search_bike.toUpperCase()) || item.license_plate.includes(this.state.search_bike)).map((item, index) => (
                                            <div className="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 mb-3 px-1">
                                                <div className="card bg-white shadow overflow-hidden border-0 position-relative"
                                                    onClick={() => {
                                                        this.setState({
                                                            days: days_between(new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en"))),
                                                        });
                                                        setTimeout(() => {
                                                            let rental_price = 0;
                                                            if (this.state.selected_bike) {
                                                                if (this.state.rental_type.value === "daily") {
                                                                    rental_price = item.daily_price * this.state.days;
                                                                } else if (this.state.rental_type.value === "weekly") {
                                                                    if (item.weekly_price === 0) {
                                                                        rental_price = item.daily_price * this.state.days;
                                                                    } else {
                                                                        if (this.state.days >= 7) {
                                                                            let price = 0;
                                                                            price += Number((this.state.days / 7).toString().split(".")[0]) * item.weekly_price;
                                                                            price += (this.state.days % 7) * item.daily_price;
                                                                            rental_price = price;
                                                                        } else {
                                                                            rental_price = item.weekly_price;
                                                                        }
                                                                    }
                                                                } else if (this.state.rental_type.value === "monthly") {
                                                                    if (item.monthly_price === 0) {
                                                                        rental_price = item.daily_price * this.state.days;
                                                                    } else {
                                                                        if (this.state.days > 30) {
                                                                            let price = 0;
                                                                            price += Number((this.state.days / 30).toString().split(".")[0]) * item.monthly_price;
                                                                            price += Number(((this.state.days % 30) / 7).toString().split(".")[0]) * item.weekly_price;
                                                                            price += ((this.state.days % 30) % 7) * item.daily_price;
                                                                            rental_price = price;
                                                                        } else {
                                                                            rental_price = item.monthly_price;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            if (rental_price <= this.state.old_price) {
                                                                this.setState({
                                                                    modal_car: false,
                                                                    modal_location: true,
                                                                    rental_price: rental_price,

                                                                    selected_bike: item,
                                                                    array_price: {
                                                                        daily_price: item.daily_price,
                                                                        weekly_price: item.weekly_price,
                                                                        monthly_price: item.monthly_price
                                                                    }
                                                                })
                                                            } else {
                                                                alert("warning", "แจ้งเตือน", "ไม่สามารถเลือกรถที่ราคาสูงกว่าราคาปัจจุบันได้");
                                                                return
                                                            }
                                                        }, 10);
                                                        setTimeout(() => {
                                                            this.setState({ status_pay: this.state.rental_price - this.state.old_price < 0 ? { value: "ไม่คิดส่วนต่าง", label: "ไม่คิดส่วนต่าง" } : this.state.status_pay })
                                                        }, 50);
                                                    }}
                                                // onMouseOver={() => { this.setState({ product_index: index }) }}
                                                // onMouseLeave={() => { this.setState({ product_index: -1 }) }}
                                                >
                                                    <img className="d-none d-sm-block" src={item.title_picture ? item.title_picture : CAR} style={{ height: 240, objectFit: "cover" }} />
                                                    <img className="d-block d-sm-none" src={item.title_picture ? item.title_picture : CAR} style={{ height: 180, objectFit: "cover" }} />
                                                    <div className="p-2">
                                                        <div className="d-flex row justify-content-between">
                                                            <label style={{ minWidth: 165 }}>หมายเลขรถ : {item.channel_name}</label>
                                                        </div>
                                                        <div style={{ height: 23, overflow: "hidden" }}>
                                                            <h6 className="mb-1 d-block d-sm-none"><b>{item.brand_name}</b> {item.model_name}</h6>
                                                            <h5 className="mb-1 d-none d-sm-block"><b>{item.brand_name}</b> {item.model_name}</h5>
                                                        </div>
                                                        <hr className="my-2" />
                                                        <div className="d-flex row justify-content-between">
                                                            <label style={{ minWidth: 165 }}>ทะเบียน : {item.license_plate}</label>
                                                            <label>{item.cc_size} cc.</label>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <label>สี : {item.bike_color}</label>
                                                            <label>ปี : {item.bike_year + 543}</label>
                                                        </div>
                                                        <hr className="my-2" />
                                                        <div className="d-flex row justify-content-between mx-0">
                                                            <div className="p-0 d-flex align-items-end">
                                                                <h4 className="mb-0 text-orange"><b>฿{toFixed(item.daily_price)}</b></h4>
                                                                <small className="text-secondary ml-2">ต่อวัน</small>
                                                            </div>
                                                            {/* <div className="p-0 d-flex align-items-end">
                                                                <small className="text-secondary ml-2">คงเหลือ {item.balance_app} คัน</small>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    {index === this.state.product_index && (
                                                        <div
                                                            className="fade-in-product rounded pointer position-absolute d-flex align-items-center justify-content-center"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                left: 0,
                                                                top: 0,
                                                                borderWidth: 4,
                                                                borderStyle: "solid",
                                                                borderColor: "#fd7e14"
                                                            }}>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* picture */}
                <Modal show={this.state.modal_picture} onHide={() => { this.setState({ modal_picture: false, modal_detail: true }) }} size={"xl"}>
                    <Modal.Header><Modal.Title><b>สภาพรถ</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === 0) {
                                        index = this.state.detail_picture.filter(item => item !== "").length - 1;
                                    } else {
                                        index -= 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {"<"}
                            </div>
                            {this.state.detail_picture ? (
                                <img src={this.state.detail_picture[this.state.index]} className="w-75 mb-3" style={{ height: 500, objectFit: "contain" }}></img>
                            ) : null}
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === this.state.detail_picture.filter(item => item !== "").length - 1) {
                                        index = 0;
                                    } else {
                                        index += 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {">"}
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center overflow-scroll">
                            {this.state.detail_picture.filter(item => item !== "").map((item, index) => (
                                <div className={this.state.index === index ? "wpx-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center overflow-hidden" : "wpx-100 hpx-100 d-flex align-items-center justify-content-center overflow-hidden"}>
                                    <img src={item} className="w-100 mb-3 wpx-100 hpx-100 mx-2 pointer" style={{ objectFit: "contain" }} onClick={() => { this.setState({ index: index }) }}></img>
                                </div>
                            ))}
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <button className="btn btn-outline-orange px-5" onClick={() => { this.setState({ modal_picture: false, modal_return: true }) }}>
                                ปิดหน้าต่าง
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* picture */}
                <Modal show={this.state.modal_picture2} onHide={() => { this.setState({ modal_picture2: false, modal_detail_booking: true }) }}>
                    <Modal.Header><Modal.Title><b>รูปสลิป</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === 0) {
                                        index = this.state.payment_picture.length - 1;
                                    } else {
                                        index -= 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {"<"}
                            </div>
                            {this.state.payment_picture && this.state.payment_picture[this.state.index] ? (
                                <img src={this.state.payment_picture[this.state.index].picture} className="w-75 mb-3" style={{ height: 500, objectFit: "contain" }}></img>
                            ) : null}
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === this.state.payment_picture.length - 1) {
                                        index = 0;
                                    } else {
                                        index += 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {">"}
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center overflow-scroll">
                            {this.state.payment_picture.map((item, index) => (
                                <div className={this.state.index === index ? "wpx-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center overflow-hidden" : "wpx-100 hpx-100 d-flex align-items-center justify-content-center overflow-hidden"}>
                                    <img src={item.picture} className="w-100 mb-3 wpx-100 hpx-100 mx-2 pointer" style={{ objectFit: "contain" }} onClick={() => { this.setState({ index: index }) }}></img>
                                </div>
                            ))}
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <button className="btn btn-outline-orange px-5" onClick={() => { this.setState({ modal_picture2: false, modal_detail_booking: true }) }}>
                                ปิดหน้าต่าง
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* picture */}
                <Modal show={this.state.modal_picture3}
                    onHide={() => {
                        this.setState({ modal_picture3: false });
                        if (this.state.type_modal === "modal_payment_rent") {
                            this.setState({ modal_payment_rent: true });
                        } else if (this.state.type_modal === "modal_scale_rent") {
                            this.setState({ modal_scale_rent: true });
                        }
                        else if (this.state.type_modal === "modal_return_rent") {
                            this.setState({ modal_return_rent: true });
                        }
                        else if (this.state.type_modal === "modal_location") {
                            this.setState({ modal_location: true });
                        }
                        else if (this.state.type_modal === "modal_detail") {
                            this.setState({ modal_detail: true });
                        }
                    }} size={"xl"}>
                    <Modal.Header><Modal.Title><b>รูปสลิป</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === 0) {
                                        index = this.state.payment_picture.length - 1;
                                    } else {
                                        index -= 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {"<"}
                            </div>
                            {this.state.payment_picture && this.state.payment_picture[this.state.index] ? (
                                <img src={this.state.payment_picture[this.state.index].picture} className="w-75 mb-3" style={{ height: 500, objectFit: "contain" }}></img>
                            ) : null}
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === this.state.payment_picture.length - 1) {
                                        index = 0;
                                    } else {
                                        index += 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {">"}
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center overflow-scroll">
                            {this.state.payment_picture.map((item, index) => (
                                <div className={this.state.index === index ? "wpx-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center overflow-hidden" : "wpx-100 hpx-100 d-flex align-items-center justify-content-center overflow-hidden"}>
                                    <img src={item.picture} className="w-100 mb-3 wpx-100 hpx-100 mx-2 pointer" style={{ objectFit: "contain" }} onClick={() => { this.setState({ index: index }) }}></img>
                                </div>
                            ))}
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <button className="btn btn-outline-orange px-5"
                                onClick={() => {
                                    this.setState({ modal_picture3: false });
                                    if (this.state.type_modal === "modal_payment_rent") {
                                        this.setState({ modal_payment_rent: true });
                                    } else if (this.state.type_modal === "modal_scale_rent") {
                                        this.setState({ modal_scale_rent: true });
                                    }
                                    else if (this.state.type_modal === "modal_return_rent") {
                                        this.setState({ modal_return_rent: true });
                                    }
                                    else if (this.state.type_modal === "modal_location") {
                                        this.setState({ modal_location: true });
                                    }
                                    else if (this.state.type_modal === "modal_detail") {
                                        this.setState({ modal_detail: true });
                                    }
                                }}>
                                ปิดหน้าต่าง
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
