import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    format_date,
    DatePickerTH,
    toFixed,
    total,
    status_booking
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";

import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
    FCIconic: {
        normal: "FCIconic-Light.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Italic.ttf",
        bolditalics: "FCIconic-Italic.ttf",
    },
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
}

export default class ListWithdraw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            data: [],

            filter_search: "",
            filter_date: "",
            filter_booking_status: ""
        }
        this.tableRef = React.createRef();
    }
    async componentDidMount() {
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            booking_status: this.state.filter_booking_status ? this.state.filter_booking_status.value : "",
        }
        let result = await GET(tokens, "v1/TH/booking/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data: result.data.data })
        }
        this.setState({ loading: false });
    }
    pdf = () => {
        let table = this.state.data.filter(item => this.state.filter_date === "" || format_date(new Date(item.pick_up_date), "dd-mm-yyyy") === format_date(new Date(this.state.filter_date), "dd-mm-yyyy"))
            .map((item, index) => {
                return [
                    { text: index + 1, alignment: "center" },
                    { text: item.pick_up_date ? format_date(item.pick_up_date) : "-", alignment: "center" },
                    { text: item.booking_number ? item.booking_number : "-", alignment: "center" },
                    { text: item.pick_up_date && item.return_date ? format_date(item.pick_up_date) + " - " + format_date(item.return_date) : "-", alignment: "center" },
                    { text: item.license_plate + " " + item.brand_name + " " + item.model_name, alignment: "left" },
                    { text: item.id_card || item.full_name ? item.id_card + " : " + item.full_name : "-", alignment: "left" },
                    { text: item.booking_price ? toFixed(item.booking_price) : "0.00", alignment: "right" },
                    { text: item.deposit_pay ? toFixed(item.deposit_pay) : "0.00", alignment: "right" },
                    {
                        text: item.booking_status === 0 ? "ไม่เลือก" :
                            item.booking_status === 1 ? "รอตรวจสอบ" :
                                item.booking_status === 2 ? "รอเงินมัดจำ" :
                                    item.booking_status === 3 ? "รออนุมัติ" :
                                        item.booking_status === 4 ? "อนุมัติ" :
                                            item.booking_status === 5 ? "ยกเลิก" :
                                                item.booking_status === 6 ? "ค้างชำระ" :
                                                    item.booking_status === 7 ? "มีสัญญาเช่า" :
                                                        "", alignment: "center"
                    }
                ]
            });

        var docDefinition = {
            content: [

                { text: "รายงานการจองประจำวัน", alignment: "left", fontSize: 24, bold: true },
                " ",
                {
                    table: {
                        headerRows: 1,
                        widths: ['4%', '9%', '11%', '17%', '16%', '17%', '8%', '8%', '9%'],
                        body: [
                            [
                                { text: "ลำดับ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "วันที่", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "เลขที่จอง", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "วันที่จอง", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ข้อมูลรถ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ลูกค้า", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ยอดจอง", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ยอดชำระ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "สถานะ", alignment: "center", fillColor: "#F2F2F2" }
                            ],
                            ...table,
                            [
                                { text: "รวมยอดจอง", alignment: "left", colSpan: 6 },
                                "",
                                "",
                                "",
                                "",
                                "",
                                { text: toFixed(total(this.state.data, "booking_price")), alignment: "right" },
                                { text: toFixed(total(this.state.data, "deposit_pay")), alignment: "right" },
                                ""
                            ],
                        ],
                    }
                },
            ],
            // , alignment: "justify" 
            defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: 'landscape',
        }
        pdfMake.createPdf(docDefinition).open()
    }
    excel = () => {

    }
    clear_form() {
        this.setState({
            loading: false,

            filter_search: ""
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายงานการจองประจำวัน" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายงานการจองประจำวัน</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/report" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.pdf() }}>
                                    PDF
                                </button>
                                <DownloadTableExcel
                                    filename="รายงานการจองประจำวัน"
                                    sheet="รายงานการจองประจำวัน"
                                    currentTableRef={this.tableRef.current}
                                >
                                    <button type="button" className="btn btn-dark px-4 mx-1 mb-3" onClick={() => { this.excel() }}>
                                        EXCEL
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        value={this.state.filter_search}
                                    />
                                    <div className="wpx-200 px-0 mx-1 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_date: e });
                                            }}
                                            value={this.state.filter_date}
                                        />
                                    </div>
                                    <Select
                                        className="form-control select-search bg-white wpx-200 border-0 mx-1 mb-2"
                                        styles={select_style}
                                        options={[
                                            { value: "1", label: "รอตรวจสอบ" },
                                            { value: "2", label: "รอเงินมัดจำ" },
                                            { value: "3", label: "รออนุมัติ" },
                                            { value: "4", label: "อนุมัติ" },
                                            { value: "5", label: "ยกเลิก" },
                                            { value: "6", label: "ค้างชำระ" },
                                            { value: "7", label: "มีสัญญาเช่า" },
                                        ]}
                                        onChange={(e) => {
                                            this.setState({ filter_booking_status: e });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_booking_status}
                                        isClearable
                                    ></Select>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_date: "",
                                                filter_booking_status: "",
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped" ref={this.tableRef}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">วันที่</th>
                                                <th className="text-center">เลขที่การจอง</th>
                                                <th className="text-center">วันที่จอง</th>
                                                <th className="text-center">ข้อมูลรถ</th>
                                                <th className="text-center">ลูกค้า</th>
                                                <th className="text-center">ยอดจอง</th>
                                                <th className="text-center">ยอดชำระ</th>
                                                <th className="text-center">สถานะ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={9} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data
                                                .filter(item => this.state.filter_date === "" || format_date(new Date(item.pick_up_date), "dd-mm-yyyy") === format_date(new Date(this.state.filter_date), "dd-mm-yyyy"))
                                                .map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">{item.pick_up_date ? format_date(item.pick_up_date) : "-"}</td>
                                                        <td className="text-center">{item.booking_number ? item.booking_number : "-"}</td>
                                                        <td className="text-center">{item.pick_up_date && item.return_date ? format_date(item.pick_up_date) + " - " + format_date(item.return_date) : "-"}</td>
                                                        <td className="text-center">{item.license_plate + " " + item.brand_name + " " + item.model_name}</td>
                                                        <td className="text-center">{item.id_card || item.full_name ? item.id_card + " : " + item.full_name : "-"}</td>
                                                        <td className="text-center">{item.booking_price ? toFixed(item.booking_price) : "0.00"}</td>
                                                        <td className="text-center">{item.deposit_pay ? toFixed(item.deposit_pay) : "0.00"}</td>
                                                        <td className="text-center"> {status_booking(item.booking_status)} </td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td className="text-center">รวมยอดจอง</td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center">{toFixed(total(this.state.data, "booking_price"))}</td>
                                                <td className="text-center">{toFixed(total(this.state.data, "deposit_pay"))}</td>
                                                <td className="text-center"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
