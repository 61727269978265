/* eslint-disable jsx-a11y/anchor-is-valid */
import PLANE from "../assets/images/airplane.png"
import TRAIN from "../assets/images/train.png"
import HOTEL from "../assets/images/hotel.png"
import QRCODE from "../assets/images/qrcode.png"
import APPSTORE from "../assets/images/app-store.png"
import PLAYSTORE from "../assets/images/play-store.png"
import RENT1 from "../assets/images/rent1.png"
import RENT2 from "../assets/images/rent2.png"
import RENT3 from "../assets/images/rent3.png"
import RENT4 from "../assets/images/rent4.png"
import RENT5 from "../assets/images/rent5.png"
import React, { Component } from "react"
import Footer from "../components/Footer"
import {
  format_date,
  required,
  DatePickerTH,
  removeStorage,
  locale
} from "../components/CustomComponent.js"
import "../assets/css/bootstrap.css"
export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      loading: false,

      modal_login: false,
      modal_alert: false,

      pick_up_date: new Date(),
      pick_up_time: format_date(new Date(), "hh:mm"),
      pick_up_location: "",
      return_date: new Date(new Date().setDate(new Date().getDate() + 1)),
      return_time: format_date(new Date(), "hh:mm"),
      return_location: "",
    }
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const permission = localStorage.getItem("permission");
    if (token) {
      if (!user) {
        removeStorage();
      } else if (!user.user_type) {
        window.location.href = "/user/choose-car";
      } else {
        localStorage.setItem("locale", "th");
        window.location.href = "/admin/dashboard";
      }
    } else {
      removeStorage();
    }
  }
  handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="w-100 position-fixed bg-dark" style={{ zIndex: 1010 }}>
          <div className="w-100 px-3">
            <div className="w-100 d-flex row align-items-center justify-content-between py-2">
              <h3 className="mb-2 text-light pointer" onClick={() => { window.location.href = "/index" }}><b>S.K.SERVICE</b></h3>
              <div className="d-none d-sm-none d-md-flex ">
                <label className="mx-2 text-light pointer" onClick={() => { this.handleClickScroll("index") }}>{global[locale]["หน้าแรก"]}</label>
                <label className="mx-2 text-light pointer" onClick={() => { this.handleClickScroll("service") }}>{global[locale]["บริการ"]}</label>
                <label className="mx-2 text-light pointer" onClick={() => { this.handleClickScroll("time") }}>{global[locale]["เวลารับส่ง"]}</label>
                <label className="mx-2 text-light pointer" onClick={() => { this.handleClickScroll("rent") }}>{global[locale]["วิธีการเช่า"]}</label>
              </div>
              <div className="d-flex">
                <button className="btn btn-light mr-1 px-3" onClick={() => { window.location.href = "/register" }}>
                  {global[locale]["สมัครสมาชิก"]} </button>
                <button className="btn btn-orange px-3" onClick={() => { window.location.href = "/login" }}>
                  {global[locale]["เข้าสู่ระบบ"]} </button>
              </div>
            </div>
          </div>
        </div>
        {/* ค้นหา */}
        <div className="pt-5" id="index">
          <div className="row background" style={{ paddingTop: 100 }}>
            <div className="col-12 col-md-10 py-5 px-4 mt-5 mx-auto">
              <div className="card border-0 px-4 py-3 shadow mt-5">
                <div className="row">
                  <div className="col-12 col-sm-6 px-1">
                    <b className="text-orange mb-2">{global[locale]["สถานที่รับรถ"]}</b>
                    <input type="text" className="form-control w-100 mb-2" placeholder={global[locale]["เช่น สนามบินเชียงใหม่"]}></input>
                  </div>
                  <div className="col-12 col-sm-6 px-1">
                    <b className="text-orange mb-2">{global[locale]["สถานที่คืนรถ"]}</b>
                    <input type="text" className="form-control w-100 mb-2" placeholder={global[locale]["เช่น สนามบินเชียงใหม่"]}></input>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                    <b className="text-orange mb-2">{global[locale]["วันที่รับรถ"]}{required}</b>
                    <DatePickerTH
                      className="form-control w-100"
                      onChange={(e) => {
                        this.setState({ pick_up_date: e });
                        if (Number(format_date(e, "yyyymmdd")) >= Number(format_date(this.state.return_date, "yyyymmdd"))) {
                          this.setState({ return_date: format_date(new Date(format_date(e, "yyyy,mm,dd")).setDate(new Date(format_date(e, "yyyy,mm,dd")).getDate() + 1), "yyyy-mm-dd", "en") });
                        }
                      }}
                      value={this.state.pick_up_date}
                      clearable={false}
                      min={new Date(new Date().setDate(new Date().getDate() - 1))}
                    />
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                    <b className="text-orange mb-2">{global[locale]["เวลารับรถ"]}{required}</b>
                    <input
                      type="time"
                      className="form-control w-100 mb-2"
                      onChange={(e) => { this.setState({ pick_up_time: e.target.value }); }}
                      onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                      value={this.state.pick_up_time} />
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                    <b className="text-orange mb-2">{global[locale]["วันที่คืนรถ"]}{required}</b>
                    <DatePickerTH
                      className="form-control w-100"
                      onChange={(e) => {
                        this.setState({ return_date: e });
                      }}
                      value={this.state.return_date}
                      clearable={false}
                      min={this.state.pick_up_date}
                    />
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                    <b className="text-orange mb-2">{global[locale]["เวลาคืนรถ"]}{required}</b>
                    <input
                      type="time"
                      className="form-control w-100 mb-2"
                      onChange={(e) => { this.setState({ return_time: e.target.value }); }}
                      onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                      value={this.state.return_time} />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 px-1">
                    <div style={{ height: 22 }}></div>
                    <button className="btn btn-orange w-100 mb-2" onClick={() => {
                      window.location.href = "/car-list"
                    }}>
                      {global[locale]["ค้นหา"]}
                      <label className="icon ml-2">{"\uf002"}</label>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* บริการของร้าน */}
        <div className="row bg-dark text-white" id="service">
          <div className="col-12 col-md-1"></div>
          <div className="col-12 col-md-10 py-5 px-4">
            <div className="w-100 text-center">
              <h3 className="mb-3"><b>{global[locale]["บริการของร้าน"]}</b></h3>
              <h6 className="mb-3"><b>{global[locale]["เวลาบริการรับส่ง 08.30-18.00 น."]}</b></h6>
              <hr className="wpx-200 mx-auto text-orange" />
              <div className="w-75 mx-auto">
                <h6 style={{ lineHeight: 1.6 }}>
                  {global[locale]["📍 สนามบิน 🛩 มีคาบริการ 150 ฿"]}<br />
                  {global[locale]["📍 สถานีรถไฟ 🚂 ฟรี"]}<br />
                  {global[locale]["📍 สถานีขนส่งอาเขต 🚌 มารับเองหน้าร้านได้เลย หน้าร้านเปิด 06.00น.-23.30 น."]}<br />
                  {global[locale]["📍 โรงแรม 🏨 เช็คพิกัดก่อน ไกลๆมีคาบริการ"]}<br />
                  {global[locale]["S.K. Service บริการให้เช่ามอเตอร์ไซค์ ในจังหวัดเชียงใหม่ เปิดบริการทุกวันไม่มีวันหยุด หน้าร้านเปิดตั้งแต่ 06.00-23.30 น. รถของเราทุกคันได้รับการดูแลจากช่างผู้ช านาญของที่ร้านเองก่อนให้ลูกค้าเช่า ดังนั้นจึงปลอดภัยและมีสภาพพร้อมใช้ตลอดเวลา ต้อนรับทั้งคนไทยและชาวต่างชาติ อีกทั้งมีบริการรับ-ส่งเวลา08.30-18.00 น. (กรุณาโทรสอบถามพื้นที่ให้บริการก่อน) ถ้าคุณมีโอกาสมาเที่ยวเชียงใหม่ อย่าลืมมาใช้บริการของเราสนใจติดต่อ 098-753-2665, 089-558-0007"]}
                </h6>
              </div>
            </div>
          </div>
        </div>
        {/* เวลาบริการรับส่ง */}
        <div className="row background2" id="time">
          <div className="col-12 col-md-9 py-5 px-4 mx-auto">
            <div className="w-100 text-center">
              <h3 className="mb-3 text-white"><b>{global[locale]["เวลาบริการรับส่ง 08.30-18.00 น."]}</b></h3>
              <hr className="wpx-200 mx-auto text-orange" />
              <div className="row mt-4">
                <div className="col-12 col-sm-4 mb-3 text-white">
                  <div className="card bg-dark-light shadow py-3 px-3">
                    <div className="row">
                      <div className="col-4 col-sm-12">
                        <div className="wpx-120 hpx-120 mx-auto mb-3 border-0">
                          <img src={PLANE} style={{ width: 120 }} />
                        </div>
                      </div>
                      <div className="col-8 col-sm-12 px-3">
                        <h4 className="mb-3"><b>{global[locale]["สนามบิน"]}</b></h4>
                        <h6 style={{ lineHeight: 1.6 }}>{global[locale]["บริการส่งลูกค้าถึงสนามบิน"]}<br />{global[locale]["มีค่าบริการส่งรถ"]} <br />150 ฿</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-4 mb-3 text-white">
                  <div className="card bg-dark-light shadow py-3 px-3">
                    <div className="row">
                      <div className="col-4 col-sm-12">
                        <div className="wpx-120 hpx-120 mx-auto mb-3 border-0">
                          <img src={TRAIN} style={{ width: 120 }} />
                        </div>
                      </div>
                      <div className="col-8 col-sm-12 px-3">
                        <h4 className="mb-3"><b>{global[locale]["สถานีรถไฟ"]}</b></h4>
                        < h6 style={{ lineHeight: 1.6 }}>{global[locale]["บริการส่งลูกค้าถึงสถานีรถไฟ"]}<br />{global[locale]["มีค่าบริการส่งรถ"]}<br />50 ฿</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-4 mb-3 text-white">
                  <div className="card bg-dark-light shadow py-3 px-3">
                    <div className="row">
                      <div className="col-4 col-sm-12">
                        <div className="wpx-120 hpx-120 mx-auto mb-3 border-0">
                          <img src={HOTEL} style={{ width: 120 }} />
                        </div>
                      </div>
                      <div className="col-8 col-sm-12 px-3">
                        <h4 className="mb-3"><b>{global[locale]["โรงแรม"]}</b></h4>
                        < h6 style={{ lineHeight: 1.6 }}>{global[locale]["บริการส่งลูกค้าถึงโรงแรมของท่าน"]}<br />{global[locale]["มีค่าบริการส่งรถ"]}<br />{global[locale]["ค่าบริการคิดตามระยะทาง"]}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* วิธีการเช่า */}
        <div className="row bg-dark text-white" id="rent">
          <div className="col-12 col-md-11 py-5 px-4 mx-auto">
            <div className="w-100 text-center">
              <h3 className="mb-3"><b>{global[locale]["วิธีการเช่า"]}</b></h3>
              <h6 className="mb-3">{global[locale]["เตรียมข้อมูลที่ต้องใช้สำหรับการจองรถให้พร้อม ชื่อของคุณ วันที่และเวลารับรถ สถานที่รับรถ เบอร์โทรศัพท์ อีเมล์ ข้อมูลอื่นๆ เช่น เที่ยวบิน, ชื่อโรงแรม, Line ID (ถ้ามี)"]}</h6>
              <hr className="w-50 mx-auto text-orange" />
              <div className="row mt-4 d-flex justify-content-center">
                <div className="col-12 col-md-4 col-lg-4 col-xxl-2 mb-3">
                  <div className="card bg-dark-light shadow py-3 px-3">
                    <div className="row">
                      <div className="col-4 col-sm-12">
                        <div className="wpx-70 hpx-70 mx-auto mb-3 border-0">
                          <img src={RENT1} style={{ width: 70 }} />
                        </div>
                      </div>
                      <div className="col-8 col-sm-12 px-3 d-flex">
                        <h1 className="text-orange mr-1"><b>1</b></h1>
                        <h6 style={{ lineHeight: 1.6 }} className="text-left">{global[locale]["ทางร้านรับจองโดยการโอนค่าเช่าบางส่วนหรือทั้งหมด"]}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 col-xxl-2 mb-3">
                  <div className="card bg-dark-light shadow py-3 px-3">
                    <div className="row">
                      <div className="col-4 col-sm-12">
                        <div className="wpx-70 hpx-70 mx-auto mb-3 border-0">
                          <img src={RENT2} style={{ width: 70 }} />
                        </div>
                      </div>
                      <div className="col-8 col-sm-12 px-3 d-flex">
                        <h1 className="text-orange mr-1"><b>2</b></h1>
                        <h6 style={{ lineHeight: 1.6 }} className="text-left">{global[locale]["เงินมัดจำจ่ายหน้างานวันทำสัญญาเช่ารถ"]}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 col-xxl-2 mb-3">
                  <div className="card bg-dark-light shadow py-3 px-3">
                    <div className="row">
                      <div className="col-4 col-sm-12">
                        <div className="wpx-70 hpx-70 mx-auto mb-3 border-0">
                          <img src={RENT3} style={{ width: 70 }} />
                        </div>
                      </div>
                      <div className="col-8 col-sm-12 px-3 d-flex">
                        <h1 className="text-orange mr-1"><b>3</b></h1>
                        <h6 style={{ lineHeight: 1.6 }} className="text-left">{global[locale]["เมื่อลูกค้าโอนเงินจองแล้วทางร้านจะล็อครถให้ทันที"]}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 col-xxl-2 mb-3">
                  <div className="card bg-dark-light shadow py-3 px-3">
                    <div className="row">
                      <div className="col-4 col-sm-12">
                        <div className="wpx-70 hpx-70 mx-auto mb-3 border-0">
                          <img src={RENT4} style={{ width: 70 }} />
                        </div>
                      </div>
                      <div className="col-8 col-sm-12 px-3 d-flex">
                        <h1 className="text-orange mr-1"><b>4</b></h1>
                        <h6 style={{ lineHeight: 1.6 }} className="text-left">{global[locale]["หากลูกค้าไม่สามารถมาตามวันเวลาที่จองได้ ลูกค้าสามารถเลื่อนวันเช่ารถได้ หรือ เปลี่ยนคนเช่าได้ #ไม่สามารถคืนเงินได้"]}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 col-xxl-2 mb-3">
                  <div className="card bg-dark-light shadow py-3 px-3">
                    <div className="row">
                      <div className="col-4 col-sm-12">
                        <div className="wpx-70 hpx-70 mx-auto mb-3 border-0">
                          <img src={RENT5} style={{ width: 70 }} />
                        </div>
                      </div>
                      <div className="col-8 col-sm-12 px-3 d-flex">
                        <h1 className="text-orange mr-1"><b>5</b></h1>
                        <h6 style={{ lineHeight: 1.6 }} className="text-left">{global[locale]["โอนเงินแล้วรบกวนลูกค้าส่งสลิปการโอนแจ้ง ชื่อ/เบอร์ติดต่อ/วันเวลาและสถานที่ที่จะเช่ารถ"]}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* QRCODE */}
        <div className="row bg-orange">
          <div className="col-12 col-md-1"></div>
          <div className="col-12 col-md-10 py-5 px-4">
            <div className="w-100 text-center">
              <h3 className="mb-3 text-white"><b>{global[locale]["สแกนเลย"]}</b></h3>
              <hr className="text-white" />
              <input type="search" className="form-control mx-auto bg-white shadow" style={{ width: 300 }} value="S.K.SERVICE" />
              <div className="d-flex row justify-content-center mt-4">
                <div className="p-3">
                  <img src={QRCODE} style={{ width: 200 }} className="mb-3" /><br />
                  <img className="pointer" src={APPSTORE} style={{ width: 200 }} onClick={() => { window.open("https://www.apple.com/th/app-store/", "_blank") }} />
                </div>
                <div className="p-3">
                  <img src={QRCODE} style={{ width: 200 }} className="mb-3" /><br />
                  <img className="pointer" src={PLAYSTORE} style={{ width: 200 }} onClick={() => { window.open("https://play.google.com/", "_blank") }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER */}
        <Footer />
      </div >
    )
  }
}
