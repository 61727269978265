import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarCustomer.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    required,
    DatePickerTH,
    locale
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";

export default class ListTrafficTicket extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_detail: false,

            page: 1,
            loading: false,

            data: null,
            data_car: [],

            filter_search: "",
            filter_pick_up_date: "",
            filter_return_date: "",

            traffic_ticket_id: "",
            rental_number: "",
            license_plate: "",
            rental_date: "",
            rental_time: "",
            id_card: "",
            full_name: "",
            picture: "",
            traffic_fines: "",
            ticket_status: 0,
        }
    }
    async componentDidMount() {
        await this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            pick_up_date: this.state.filter_pick_up_date,
            return_date: this.state.filter_return_date
        }
        let result = await GET(tokens, "v1/TH/traffic-ticket/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_detail: false,

            page: 1,
            loading: false,


            filter_search: "",
            filter_pick_up_date: "",
            filter_return_date: "",

            rental_number: "",
            license_plate: "",
            rental_date: "",
            rental_time: "",
            id_card: "",
            full_name: "",
            picture: "",
            traffic_fines: ""

        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="เมนูจัดการข้อมูลใบสั่ง" />
                {/* BODY */}
                <div className="body background3 min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3 text-white"><b>{global[locale]["เมนูจัดการข้อมูลใบสั่ง"]}</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* วันเช่ารถ */}
                                    <div className=" wpx-200 px-0 mx-1 mb-2">
                                        <DatePickerTH
                                            className="form-control bg-white border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_pick_up_date: e, page: 1 });
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }}
                                            onBlur={() => {
                                                this.GetAll();
                                            }}
                                            value={this.state.filter_pick_up_date}
                                        />
                                    </div>
                                    {/* วันคืนรถ */}
                                    <div className=" wpx-200 px-0 mx-1 mb-2">
                                        <DatePickerTH
                                            className="form-control bg-white border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_return_date: e, page: 1 });
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }}
                                            onBlur={() => {
                                                this.GetAll();
                                            }}
                                            value={this.state.filter_return_date}
                                        />
                                    </div>
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>{global[locale]["ค้นหา"]}</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_pick_up_date: "",
                                                filter_return_date: "",
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>{global[locale]["รีเซ็ท"]}</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">{global[locale]["เลขที่สัญญาเช่า"]}</th>
                                                <th className="text-center">{global[locale]["วันที่เช่า"]}</th>
                                                <th className="text-center">{global[locale]["วันที่คืน"]}</th>
                                                <th className="text-center">{global[locale]["ชื่อ"]}</th>
                                                <th className="text-center">{global[locale]["สถานะ"]}</th>
                                                <th className="text-center">{global[locale]["จัดการ"]}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={6} className="text-center"> {global[locale]["ไม่มีข้อมูล"]} </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.rental_data ? item.rental_data.rental_number : "-"}</td>
                                                    <td className="text-center">{item.rental_data ? item.rental_data.pick_up_date : "-"}</td>
                                                    <td className="text-center">{item.rental_data ? item.rental_data.return_date : "-"}</td>
                                                    <td className="text-center">{item.rental_data ? item.rental_data.full_name : "-"}</td>
                                                    <td className="text-center">
                                                        {item.ticket_status === 0 && (<span className="bg-secondary rounded px-2 py-1 text-white">{global[locale]["ไม่เลือก"]}</span>)}
                                                        {item.ticket_status === 1 && (<span className="bg-warning rounded px-2 py-1 text-white">{global[locale]["รอติดต่อ"]}</span>)}
                                                        {item.ticket_status === 2 && (<span className="bg-success rounded px-2 py-1 text-white">{global[locale]["ชำระแล้ว"]}</span>)}
                                                        {item.ticket_status === 3 && (<span className="bg-danger rounded px-2 py-1 text-white">{global[locale]["ยกเลิก"]}</span>)}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <label className="wpx-30 hpx-30 bg-primary-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 view"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        rental_number: item.rental_data.rental_number,
                                                                        license_plate: item.rental_data.license_plate,
                                                                        rental_date: item.rental_data.pick_up_date,
                                                                        rental_time: item.rental_data.pick_up_time,
                                                                        id_card: item.rental_data.id_card,
                                                                        full_name: item.rental_data.full_name,
                                                                        picture: item.picture,
                                                                        modal_detail: true
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-primary pointer"
                                                                > {"\uf570"} </label>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> {global[locale]["แสดง"]} {this.state.data.end_index} {global[locale]["รายการ"]} {global[locale]["จาก"]} {this.state.data.count} {global[locale]["รายการ"]} </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* create */}
                <Modal
                    show={this.state.modal_detail}
                    onHide={() => { this.setState({ modal_detail: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>{global[locale]["รายละเอียด"]}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                {/* ทะเบียนรถ */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">{global[locale]["ทะเบียนรถ"]}{required}:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        value={this.state.license_plate}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                {/* วันที่เช่า */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">{global[locale]["วันที่เช่า"]} :</label>
                                    <DatePickerTH
                                        id="date"
                                        className="form-control"
                                        placeholder="พิมพ์คำค้นหา..."
                                        value={this.state.rental_date}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                {/* เวลาที่เช่า */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">{global[locale]["เวลาที่เช่า"]} :</label>
                                    <input
                                        className="form-control"
                                        type="time"
                                        placeholder="กรอกข้อมูล"
                                        value={this.state.rental_time}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        {/* สัญญาเช่า */}
                        <div className="w-100 mb-3">
                            <label className="w-100">{global[locale]["สัญญาเช่า"]}{required}:</label>
                            <Select
                                className="form-control select-search bg-white"
                                styles={select_style}
                                options={this.state.data_car ? this.state.data_car.filter(e => (this.state.rental_date === "" || (new Date(this.state.rental_date).getTime() >= new Date(e.pick_up_date).getTime() && new Date(this.state.rental_date).getTime() <= new Date(e.return_date).getTime()))) : []}
                                value={this.state.rental_number}
                                isClearable
                                isDisabled
                            ></Select>
                        </div>
                        {/* เลขบัตรประชาชน/เลขพาสปอร์ต */}
                        <div className="w-100 mb-3">
                            <label className="w-100">{global[locale]["เลขบัตรประชาชน/เลขพาสปอร์ต"]}{required}:</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                value={this.state.id_card}
                                readOnly
                            />
                        </div>
                        {/* ชื่อ-นามสกุล */}
                        <div className="w-100 mb-3">
                            <label className="w-100">{global[locale]["ชื่อ-นามสกุล"]}{required}:</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                value={this.state.full_name}
                                readOnly
                            />
                        </div>
                        {/* จำนวนเงิน */}
                        <div className="w-100 mb-3">
                            <label className="w-100">{global[locale]["จำนวนเงิน"]}{required}:</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                value={this.state.traffic_fines}
                                readOnly
                            />
                        </div>
                        {this.state.picture === "" ? (
                            <div className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5 mb-3 pointer">
                                <label className="icon text-orange">{"\uf03e"}</label>
                                <label className="text-orange">{global[locale]["อัปโหลดภาพใบสั่ง"]}</label>
                            </div>
                        ) : (
                            <div
                                className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                style={{ height: "100%" }}
                            >
                                <div className="position-relative w-100">
                                    <img alt="รูปใบสั่ง" src={this.state.picture.includes("https://") ? this.state.picture : "data:image/png;base64," + this.state.picture} className="w-100 rounded overflow-hidden"></img>
                                </div>
                            </div>
                        )}
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex justify-content-center">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_detail: false })
                                    }}
                                >
                                    {global[locale]["ปิดหน้าต่าง"]}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
