import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
    POST,
    PUT,
    DELETE,
    validate,
    required,
    success_url,
    NumberZero
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";
import CAR from "../../../assets/images/bike.png";
import EMPTY from "../../../assets/images/empty.png";

export default class ListStorage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            data: null,
            data_bike: [],

            filter_search: "",

            filter_search_car: "",

            id: "",
            channel_name: "",
            description: "",
            bike_id: "",
            is_active: true,

        }
    }
    async componentDidMount() {
        await this.GetAll();
        await this.GetBikeAll();
    }
    GetAll = async () => {
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
        }
        let result = await GET(tokens, "v1/TH/bike-channel/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
    }
    GetBikeAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: "",
            channel_id: ""
        }
        let result = await GET(tokens, "v1/TH/bike-manage/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_bike: result.data.data })
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        if (validate(this.state, ["channel_name"])) { return };
        this.setState({ loading: true });
        let data = {
            channel_name: this.state.channel_name,
            description: this.state.description,
            bike_id: this.state.bike_id ? this.state.bike_id.bike_id : ""
        }
        let result = await POST(tokens, "v1/TH/bike-channel/create", data);
        if (result && result.status) {
            success_url("/master/list-storage")
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        if (validate(this.state, ["channel_name"])) { return };
        this.setState({ loading: true });
        let data = {
            channel_id: this.state.id,
            channel_name: this.state.channel_name,
            description: this.state.description,
            bike_id: this.state.bike_id ? this.state.bike_id.bike_id : "",
            is_active: this.state.is_active,
        }
        let result = await PUT(tokens, "v1/TH/bike-channel/update", data);
        if (result && result.status) {
            success_url("/master/list-storage")
        }
        this.setState({ loading: false });
    }
    Delete = async () => {
        this.setState({ loading: true });
        let result = await DELETE(tokens, 'v1/TH/bike-channel/delete/' + this.state.id, null);
        if (result && result.status) {
            success_url("/master/list-storage")
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            filter_search: "",

            filter_search_car: "",

            id: "",
            channel_name: "",
            description: "",
            bike_id: "",
            is_active: true,

        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ช่องเก็บรถ" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ช่องเก็บรถ</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add" onClick={() => { this.clear_form(); this.setState({ modal_create: true }) }}>
                                    เพิ่มช่องเก็บรถ
                                </button>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="ชื่อช่อง"
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">ชื่อช่อง</th>
                                                <th className="text-center">ยี่ห้อ</th>
                                                <th className="text-center">รุ่น</th>
                                                <th className="text-center">ป้ายทะเบียน</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={6} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{NumberZero(((this.state.page - 1) * 10) + index + 1, 5)}</td>
                                                    <td className="text-center">{item.channel_name ? item.channel_name : "-"}</td>
                                                    <td className="text-center">{item.bike_info ? item.bike_info.brand_name : "-"}</td>
                                                    <td className="text-center">{item.bike_info ? item.bike_info.model_name : "-"}</td>
                                                    <td className="text-center">{item.bike_info ? item.bike_info.license_plate : "-"}</td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <label className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={() => {
                                                                    this.clear_form();
                                                                    setTimeout(() => {
                                                                        this.setState({
                                                                            modal_update: true,

                                                                            id: item.channel_id,
                                                                            channel_name: item.channel_name,
                                                                            description: item.description,
                                                                            is_active: item.is_active,
                                                                        })
                                                                        if (item.bike_info) {
                                                                            this.setState({ bike_id: this.state.data_bike.filter(e => e.bike_id === item.bike_info.bike_id)[0] })
                                                                        }
                                                                    }, 10);
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer"
                                                                > {"\uf304"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-danger-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 delete"
                                                                onClick={() => { this.setState({ modal_delete: true, id: item.channel_id, }) }}>
                                                                <label
                                                                    className="icon mx-2 text-danger pointer"
                                                                > {"\uf1f8"} </label>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* create or update */}
                <Modal
                    show={this.state.modal_create || this.state.modal_update}
                    onHide={() => { this.setState({ modal_create: false, modal_update: false }) }}
                    size="lg"
                >
                    <Modal.Header> <Modal.Title> <b>{this.state.modal_create ? "เพิ่มข้อมูลช่องเก็บรถ" : "แก้ไขข้อมูลช่องเก็บรถ"}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                {/* ชื่อช่องเก็บรถ */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-110">ชื่อช่องเก็บรถ{required}:</label>
                                    <input
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ channel_name: e.target.value })
                                        }}
                                        value={this.state.channel_name}
                                    />
                                </div>
                                {/* คำอธิบาย */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-110">คำอธิบาย{required}:</label>
                                    <textarea
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ description: e.target.value })
                                        }}
                                        value={this.state.description}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-7">
                                {!this.state.bike_id ? (
                                    <div className="w-100 card  p-3 pt-1 mb-3">
                                        <div className="d-flex align-items-end position-relative mx-auto" style={{ height: 150, width: 150 }}>
                                            <img src={EMPTY} className="img-responsive position-absolute" style={{ width: 150, zIndex: 999, opacity: 0.08, objectFit: "contain" }} />
                                            <div className="w-100 text-center pb-5" style={{ zIndex: 1000 }}>
                                                <h6>** ยังไม่ได้เลือกรถ **</h6>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="w-100 card  p-2 mb-3">
                                        <div className="row">
                                            <div className="col-12 col-md-4 mb-3">
                                                <img src={this.state.bike_id.title_picture ? this.state.bike_id.title_picture : CAR} className="img-responsive w-100 rounded" />
                                            </div>
                                            <div className="col-12 col-md-8 mb-3">
                                                <h6><b>รายละเอียดรถ</b></h6>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-6 col-xxl-6 d-flex mb-2">
                                                        <b className="wpx-80">ยี่ห้อ</b><label>: {this.state.bike_id.brand_name}</label>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 col-xxl-6 d-flex mb-2">
                                                        <b className="wpx-80">สี</b><label>: {this.state.bike_id.bike_color}</label>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 col-xxl-6 d-flex mb-2">
                                                        <b className="wpx-80">เลขทะเบียน</b><label>: {this.state.bike_id.license_plate}</label>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 col-xxl-6 d-flex mb-2">
                                                        <b className="wpx-80">ชื่อรุ่น</b><label>: {this.state.bike_id.model_name}</label>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 col-xxl-6 d-flex mb-2">
                                                        <b className="wpx-80">ขนาดเครื่อง</b><label>: {this.state.bike_id.cc_size}</label>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 col-xxl-6 d-flex mb-2">
                                                        <b className="wpx-80">ปี</b><label>: {this.state.bike_id.bike_year}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search_car: e.target.value }); }}
                                        value={this.state.filter_search_car}
                                    />
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive pb-5" style={{ maxHeight: 400 }}>
                                    <table className="table table-borderless table-striped" style={{ marginBottom: 100 }}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">รูปภาพ</th>
                                                <th className="text-center">สี</th>
                                                <th className="text-center">ยี่ห้อ</th>
                                                <th className="text-center">รุ่น</th>
                                                <th className="text-center">ป้ายทะเบียน</th>
                                                <th className="text-center">เครื่องยนต์</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={8} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data_bike && this.state.data_bike
                                                .filter(item => JSON.stringify(item).includes(this.state.filter_search_car))
                                                .filter(item => item.bike_channel_id === "" || (this.state.bike_id && this.state.bike_id.bike_id === item.bike_id))
                                                .map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{NumberZero(((this.state.page - 1) * 10) + index + 1, 5)}</td>
                                                        <td className="text-center"><img className="wpx-30 hpx-30 rounded-circle" src={item.title_picture ? item.title_picture : CAR} style={{ objectFit: "cover" }} alt="รูปภาพ" />
                                                        </td>
                                                        <td className="text-center">{item.bike_color ? item.bike_color : "-"}</td>
                                                        <td className="text-center">{item.brand_name ? item.brand_name : "-"}</td>
                                                        <td className="text-center">{item.model_name ? item.model_name : "-"}</td>
                                                        <td className="text-center">{item.license_plate ? item.license_plate : "-"}</td>
                                                        <td className="text-center">{item.cc_size ? item.cc_size : "-"}</td>
                                                        <td className="text-center">
                                                            <button className={"btn px-3 " + (this.state.bike_id && this.state.bike_id.bike_id === item.bike_id ? "btn-orange" : "btn-outline-orange")} onClick={() => { this.setState({ bike_id: item }) }}>เลือก</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        if (this.state.modal_create) {
                                            this.Create();
                                        } else {
                                            this.Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบข้อมูล</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการลบข้อมูลดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
