import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    format_date,
    DatePickerTH,
    toFixed,
    alert
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
    FCIconic: {
        normal: "FCIconic-Light.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Italic.ttf",
        bolditalics: "FCIconic-Italic.ttf",
    },
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
}

export default class ListWithdraw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            data: [],
            data_admin:[],

            filter_search: "",
            filter_date: format_date(new Date(), "yyyy-mm-dd", "en"),
            rental_type: "",

            admin_name:""
        }
        this.tableRef = React.createRef();
    }
    async componentDidMount() {
        await this.GetAll();
        await this.GetAdminAll();
    }
    GetAll = async () => {
        if(!this.state.filter_date){
            alert("warning","แจ้งเตือน","กรุณาเลือกวันที่");
            return
        }
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            pick_up_date: this.state.filter_date,
            rental_status: "",
        }
        let result = await GET(tokens, "v1/TH/rental-manage/filter-search/page/0", data);
        if (result && result.status) { 
            this.setState({ data: result.data.data })
        }
        this.setState({ loading: false });
    }
    GetAdminAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: "",
            user_type: ""
        }
        let result = await GET(tokens, "v1/TH/user-admin/filter-search/page/0", data);
        if (result && result.status) { 
            this.setState({ data_admin: result.data.data.filter(e=>e.user_type === "cashier").map(item=>{return {value:item.full_name,label:item.full_name}}) })
        }
        this.setState({ loading: false });
    }
    pdf = () => {
        let table = this.state.data.filter(item => !this.state.admin_name || item.admin_name=== this.state.admin_name.label)
            .filter(item => !this.state.rental_type || (this.state.rental_type.value === "รายการที่สิ้นสุด" && item.rental_status === 10) || (this.state.rental_type.value === "รายการที่ดำเนินการ" && item.rental_status !== 10))
            .map((item, index) => {
                return [
                    { text: index + 1, alignment: "center" },
                    { text: item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-", alignment: "center" },
                    { text: item.rental_number ? item.rental_number : "-", alignment: "center" },
                    { text: item.license_plate + " " + item.brand_name + " " + item.model_name, alignment: "left" },
                    { text: item.full_name ? item.full_name : "-", alignment: "left" },
                    { text: item.agent_name ? item.agent_name : "-", alignment: "left" },
                    { text: item.equipment_list ? item.equipment_list.map(e => { return e.equipment_name + " X " + e.equipment_qty + " (" + (e.equipment_price * e.equipment_qty) + ")\n" }) : "-", alignment: "left" },
                    { text: item.rental_price ? toFixed(item.rental_price) : "0.00", alignment: "left" },
                    { text: item.guarantee_amount ? toFixed(item.guarantee_amount) : "0.00", alignment: "left" },
                    { text: item.discount_price ? toFixed(item.discount_price) : "0.00", alignment: "left" },
                    { text: item.created_by ? item.created_by : "-", alignment: "left" },
                    { text: item.rental_status === 10 ? "รายการที่\nสิ้นสุด" : "รายการที่\nดำเนินการ", alignment: "left" },
                ]
            });
        var docDefinition = {
            content: [

                { text: "รายงานสัญญาเช่า", alignment: "left", fontSize: 24, bold: true },
                " ",
                {
                    table: {
                        headerRows: 1,
                        widths: ['4%', '8%', '11%', '11%', '11%', '11%', '14%', '6%', '7%', '5%', '5%', '7%'],
                        body: [
                            [
                                { text: "ลำดับ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "วันที่", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "เลขที่สัญญาเช่า", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ข้อมูลรถ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ลูกค้า", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "Agent", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "อุปกรณ์", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ยอดเช่า", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "เงินประกัน", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ส่วนลด", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ผู้ทำสัญญา", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "สถานะ", alignment: "center", fillColor: "#F2F2F2" }
                            ],
                            ...table,
                        ],
                    }
                },
            ],
            // , alignment: "justify" 
            defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: 'landscape',
        }
        pdfMake.createPdf(docDefinition).open()
    }
    excel = () => {

    }
    clear_form() {
        this.setState({
            loading: false,

            filter_search: ""
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายงานสัญญาเช่า" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายงานสัญญาเช่า</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/report" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.pdf() }}>
                                    PDF
                                </button>
                                <DownloadTableExcel
                                    filename="รายงานสัญญาเช่า"
                                    sheet="รายงานสัญญาเช่า"
                                    currentTableRef={this.tableRef.current}
                                >
                                    <button type="button" className="btn btn-dark px-4 mx-1 mb-3" onClick={() => { this.excel() }}>
                                        EXCEL
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        value={this.state.filter_search}
                                    />
                                    <div className="wpx-200 px-0 mx-1 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_date: e });
                                            }}
                                            value={this.state.filter_date}
                                        />
                                    </div>
                                    <Select
                                        className="form-control select-search bg-white border-0 wpx-200 mx-1 mb-2"
                                        styles={select_style}
                                        options={[
                                            { value: "รายการที่สิ้นสุด", label: "รายการที่สิ้นสุด" },
                                            { value: "รายการที่ดำเนินการ", label: "รายการที่ดำเนินการ" },
                                        ]}
                                        onChange={(e) => {
                                            this.setState({
                                                rental_type: e,
                                            });
                                        }}
                                        value={this.state.rental_type}
                                    ></Select>
                                    <Select
                                        className="form-control select-search bg-white border-0 wpx-200 mx-1 mb-2"
                                        styles={select_style}
                                        options={this.state.data_admin}
                                        onChange={(e) => {
                                            this.setState({
                                                admin_name: e,
                                            });
                                        }}
                                        value={this.state.admin_name}
                                        isClearable
                                    ></Select>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_date: "",
                                                rental_type: "",
                                                admin_name:""
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped" ref={this.tableRef}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">วันที่</th>
                                                <th className="text-center">เลขที่สัญญาเช่า</th>
                                                <th className="text-center">ข้อมูลรถ</th>
                                                <th className="text-center">ลูกค้า</th>
                                                <th className="text-center">Agent</th>
                                                <th className="text-center">อุปกรณ์</th>
                                                <th className="text-center">ยอดเช่า</th>
                                                <th className="text-center">เงินประกัน</th>
                                                <th className="text-center">ส่วนลด</th>
                                                <th className="text-center">ผู้ทำสัญญา</th>
                                                <th className="text-center">สถานะ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={12} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.filter(item => !this.state.admin_name || item.admin_name=== this.state.admin_name.label).filter(item => !this.state.rental_type || (this.state.rental_type.value === "รายการที่สิ้นสุด" && item.rental_status === 10) || (this.state.rental_type.value === "รายการที่ดำเนินการ" && item.rental_status !== 10))
                                                .map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">{item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-"}</td>
                                                        <td className="text-center">{item.rental_number ? item.rental_number : "-"}</td>
                                                        <td className="text-center">{item.license_plate + " " + item.brand_name + " " + item.model_name}</td>
                                                        <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                                                        <td className="text-center">{item.agent_name ? item.agent_name : "-"}</td>
                                                        <td className="text-left"><ol>{item.equipment_list ? item.equipment_list.map(e => { return (<li>{e.equipment_name + " X " + e.equipment_qty + " (" + (e.equipment_price * e.equipment_qty) + ")"}</li>) }) : "-"}</ol></td>
                                                        <td className="text-center">{item.rental_price ? toFixed(item.rental_price) : "0.00"}</td>
                                                        <td className="text-center">{item.guarantee_amount ? toFixed(item.guarantee_amount) : "0.00"}</td>
                                                        <td className="text-center">{item.discount_price ? toFixed(item.discount_price) : "0.00"}</td>
                                                        <td className="text-center">{item.created_by ? item.created_by : "-"}</td>
                                                        <td className="text-center">{item.rental_status === 10 ? "รายการที่สิ้นสุด" : "รายการที่ดำเนินการ"}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
