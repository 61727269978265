import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    format_date,
    required,
    success_url,
    NumberZero,
    toFixed
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";

export default class ListDeposit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_picture:false,
            page: 1,
            loading: false,

            data: null,

            data_search: "",

            payment_picture:[],
            index : 0
        }
    }
    async componentDidMount() {
        await this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.data_search.replaceAll("#", "%23"),
            pick_up_date: "",
            return_date: ""
        }
        let result = await GET(tokens, "v1/TH/deposit-receipt/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    Create = () => {
        success_url("/admin/list-deposit")
    }
    Delete = () => {
        success_url("/admin/list-deposit")
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            page: 1,
            loading: false,
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ใบมัดจำ" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ใบมัดจำ</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ data_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.data_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                data_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">วันที่</th>
                                                <th className="text-center">เลขที่ใบมัดจำ</th>
                                                <th className="text-center">เลขที่สัญญาเช่า</th>
                                                <th className="text-center">ลูกค้า</th>
                                                <th className="text-center">ยอดบิล</th>
                                                <th className="text-center">สถานะ</th>
                                                <th className="text-center">หมายเหตุ</th>
                                                <th className="text-center">รูปสลิป</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={7} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{NumberZero(((this.state.page - 1) * 10) + index + 1, 5)}</td>
                                                    <td className="text-center">{item.payment_date ? format_date(item.payment_date) : "-"}</td>
                                                    <td className="text-center">{item.payment_number ? item.payment_number : "-"}</td>
                                                    <td className="text-center">{item.rental_number ? item.rental_number : "-"}</td>
                                                    <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                                                    <td className="text-center">{item.total_price ? toFixed(item.total_price) : "0.00"}</td>
                                                    <td className="text-center">{item.total_pay + item.total_pay_transfer + item.total_pay_cradit === 0 ?
                                                        (<span className="bg-warning rounded px-2 py-1 text-white">รอชำระ</span>) :
                                                        item.total_price - (item.total_pay + item.total_pay_transfer + item.total_pay_cradit) !== 0 ?
                                                            (<span className="bg-orange rounded px-2 py-1 text-white">ค้างชำระ</span>) :
                                                            (<span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span>)
                                                    }</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">
                                                        {item.payment_picture.length !==0 &&(
                                                    <div className="d-flex justify-content-center view">
                                                            <label
                                                                className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1"
                                                                onClick={() => { 
                                                                    this.setState({modal_picture:true,payment_picture:item.payment_picture,index:0})
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer" > {"\uf03e"} </label>
                                                            </label> 
                                                        </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}
{/* picture */}
<Modal
          show={this.state.modal_picture}
          onHide={() => {
            this.setState({ modal_picture: false, modal_detail: true });
          }}
          size={"xl"}
        >
          <Modal.Header>
            <Modal.Title>
              <b>สภาพรถ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                onClick={() => {
                  let index = this.state.index;
                  if (index === 0) {
                    index = this.state.payment_picture.filter((item) => item !== "").length - 1;
                  } else {
                    index -= 1;
                  }
                  this.setState({ index: index });
                }}
              >
                {"<"}
              </div>
              {this.state.payment_picture[this.state.index] ? <img src={this.state.payment_picture[this.state.index].picture} className="w-75 mb-3" style={{ height: 500, objectFit: "contain" }}></img> : null}
              <div
                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                onClick={() => {
                  let index = this.state.index;
                  if (index === this.state.payment_picture.filter((item) => item !== "").length - 1) {
                    index = 0;
                  } else {
                    index += 1;
                  }
                  this.setState({ index: index });
                }}
              >
                {">"}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center overflow-scroll">
              {this.state.payment_picture
                .filter((item) => item !== "")
                .map((item, index) => (
                  <div className={this.state.index === index ? "wpx-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center overflow-hidden" : "wpx-100 hpx-100 d-flex align-items-center justify-content-center overflow-hidden"}>
                    <img
                      src={item.picture}
                      className="w-100 mb-3 wpx-100 hpx-100 mx-2 pointer"
                      style={{ objectFit: "contain" }}
                      onClick={() => {
                        this.setState({ index: index });
                      }}
                    ></img>
                  </div>
                ))}
            </div>
            <div className="w-100 d-flex justify-content-center mt-3">
              <button
                className="btn btn-outline-orange px-5"
                onClick={() => {
                  this.setState({ modal_picture: false });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>
            </div >
        )
    }
}
