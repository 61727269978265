import React, { Component } from "react"
import Footer from "../components/Footer"
import {
  success_url,
  alert,
  POST,
  removeStorage,
  locale
} from "../components/CustomComponent.js"
import "../assets/css/bootstrap.css"
export default class Forgot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      loading: false,
    }
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const permission = localStorage.getItem("permission");
    if (token) {
      if (user.user_type === "customer") {
        window.location.href = "/user/list-rent";
      } else {
        localStorage.setItem("locale", "th");
        window.location.href = "/admin/dashboard";
      }
    } else {
      removeStorage();
    }
  }

  Forget = async () => {
    if (this.state.email.trim() === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล")
      return
    }
    this.setState({ loading: true });
    let data = {
      email: this.state.email
    }
    let result = await POST("", "v1/TH/forgot-password/req", data);
    if (result && result.status) {
      success_url("/login", "success", "สำเร็จ", "ส่งลิงก์รีเซ็ตรหัสผ่านเข้าไปยังอีเมล \"" + this.state.email + "\"  แล้ว")
    }
    this.setState({ loading: false });
  }
  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="d-flex w-100 position-fixed bg-dark" style={{ zIndex: 1010 }}>
          <div className="w-100 px-4">
            <div className="w-100 d-flex align-items-center justify-content-between py-2">
              <h3 className="mb-0 text-light pointer" onClick={() => { window.location.href = "/index" }}><b>S.K.SERVICE</b></h3>

            </div>
          </div>
        </div>
        <div className="row background3 pt-5" style={{ height: "100vh" }}>
          <div className="col-12 col-md-1"></div>
          <div className="col-12 py-5 px-4">
            <div className="center w-100">
              <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-10 mx-auto">
                <div className="card shadow">
                  <div className="col-12 p-4 text-center">
                    <div className="text-center"> <h3 className="mb-4"> <b>{global[locale]["ลืมรหัสผ่าน"]}</b>  </h3></div>
                    <label className="mb-3">{global[locale]["รีเซ็ตรหัสผ่านด้วยอีเมลที่ใ้ช้งาน"]}</label>
                    <input
                      className="form-control mb-4"
                      type="text"
                      placeholder={global[locale]["อีเมล"]}
                      onChange={(e) => {
                        this.setState({ email: e.target.value })
                      }}
                      value={this.state.email}
                    />
                    <button type="button" className="btn btn-orange w-100" onClick={() => { this.Forget() }}>{global[locale]["รีเซ็ตรหัสผ่าน"]}</button>
                    <div className="w-100 text-center mt-4">
                      {global[locale]["มีบัญชีอยู่แล้วใช่หรือไม่?"]} <label className="px-2 text-orenge pointer" onClick={() => { window.location.href = "/login" }}>{global[locale]["ลงชื่อเข้าใช้"]}</label>
                    </div>
                    <div class="w-100 text-center mt-2">
                      <small class="text-secondary">{global.version}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
