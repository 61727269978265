import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
    format_date,
    toFixed,
    total
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
    FCIconic: {
        normal: "FCIconic-Light.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Italic.ttf",
        bolditalics: "FCIconic-Italic.ttf",
    },
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
}

export default class ListWithdraw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            data: [],

            filter_search: "",
            filter_date: "",
            filter_rental_status: ""
        }
        this.tableRef = React.createRef();
    }
    async componentDidMount() {
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/user-agent/group-bill/page/0", null);
        if (result && result.status) {
            let data = result.data.data;
            let array = [];
            for (let item of data) {
                array = [...array, ...item.payment_list.map(e => { return { ...e, admin_name: item.admin_name, bill_number: item.bill_number, datetime_create: item.datetime_create } })]
            }
            this.setState({ data: array })
        }
        this.setState({ loading: false });
    }
    pdf = () => {
        let table = this.state.data.filter(item => this.state.filter_search === "" || (item.bill_number.includes(this.state.filter_search)) || (item.payment_number.replaceAll("DP", "RT").includes(this.state.filter_search)) || (item.agent_name.includes(this.state.filter_search)))
            .map((item, index) => {
                return [
                    { text: index + 1, alignment: "center" },
                    { text: item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-", alignment: "center" },
                    { text: item.bill_number ? item.bill_number : "-", alignment: "center" },
                    { text: item.payment_number ? item.payment_number.replaceAll("DP", "RT") : "-", alignment: "left" },
                    { text: item.total_pay || item.total_pay_transfer || item.total_pay_cradit ? toFixed(item.total_pay + item.total_pay_transfer + item.total_pay_cradit) : "0.00", alignment: "left" },
                    { text: "จ่ายเรียบร้อย", alignment: "left" },
                    { text: item.admin_name ? item.admin_name : "-", alignment: "left" },
                    { text: item.agent_name ? item.agent_name : "-", alignment: "left" },
                ]
            });
        var docDefinition = {
            content: [

                { text: "รายงานค่าตอบแทน AGENT", alignment: "left", fontSize: 24, bold: true },
                " ",
                {
                    table: {
                        headerRows: 1,
                        widths: ['4%', '17%', '15%', '15%', '10%', '13%', '13%', '13%'],
                        body: [
                            [
                                { text: "ลำดับ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "วันที่", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "เลขที่ใบเบิกเงิน", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "เลขที่สัญญาเช่า", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ยอดบิล", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "สถานะ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ผู้ส่งเงิน", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ผู้รับเงิน", alignment: "center", fillColor: "#F2F2F2" }
                            ],
                            ...table,
                        ],
                    }
                },
            ],
            // , alignment: "justify" 
            defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: 'landscape',
        }
        pdfMake.createPdf(docDefinition).open()
    }
    excel = () => {

    }
    clear_form() {
        this.setState({
            loading: false,

            filter_search: ""
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายงานค่าตอบแทน AGENT" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายงานค่าตอบแทน AGENT</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/report" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.pdf() }}>
                                    PDF
                                </button>
                                <DownloadTableExcel
                                    filename="รายงานค่าตอบแทน AGENT"
                                    sheet="รายงานค่าตอบแทน AGENT"
                                    currentTableRef={this.tableRef.current}
                                >
                                    <button type="button" className="btn btn-dark px-4 mx-1 mb-3" onClick={() => { this.excel() }}>
                                        EXCEL
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_date: "",
                                                filter_booking_status: "",
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped" ref={this.tableRef}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">วันที่</th>
                                                <th className="text-center">เลขที่ใบเบิกเงิน</th>
                                                <th className="text-center">เลขที่สัญญาเช่า</th>
                                                <th className="text-center">ยอดบิล</th>
                                                <th className="text-center">สถานะ</th>
                                                <th className="text-center">ผู้ส่งเงิน</th>
                                                <th className="text-center">ผู้รับเงิน</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={8} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data
                                                .filter(item => this.state.filter_search === "" || (item.bill_number.includes(this.state.filter_search)) || (item.payment_number.replaceAll("DP", "RT").includes(this.state.filter_search)) || (item.agent_name.includes(this.state.filter_search)))
                                                .map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">{item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-"}</td>
                                                        <td className="text-center">{item.bill_number ? item.bill_number : "-"}</td>
                                                        <td className="text-center">{item.payment_number ? item.payment_number.replaceAll("DP", "RT") : "-"} </td>
                                                        <td className="text-center">{item.total_pay || item.total_pay_transfer || item.total_pay_cradit ? toFixed(item.total_pay + item.total_pay_transfer + item.total_pay_cradit) : "0.00"}</td>
                                                        <td className="text-center">จ่ายเรียบร้อย</td>
                                                        <td className="text-center">{item.admin_name ? item.admin_name : "-"}</td>
                                                        <td className="text-center">{item.agent_name ? item.agent_name : "-"}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
