/* eslint-disable jsx-a11y/alt-text */
import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarCustomer.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    alert,
    GET,
    format_date,
    float,
    required,
    DatePickerTH,
    locale
} from "../../components/CustomComponent.js";
import CAR from "../../assets/images/bike.png"
import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class ChooseCar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_detail: false,
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,


            //ค้นหารถ
            data_bike: null,
            pick_up_date: new Date(),
            pick_up_time: format_date(new Date(), "hh:mm"),
            pick_up_location: "",
            return_date: new Date(new Date().setDate(new Date().getDate() + 1)),
            return_time: format_date(new Date(), "hh:mm"),
            return_location: "",

            product_index: -1,

            close: false,
            end_date_booking_mc: "",
            start_date_booking_mc: ""

        }
    }
    async componentDidMount() {
        await this.GetBikeAll()
        await this.GetSettingAll();
    }
    GetSettingAll = async () => {
        let result = await GET(tokens, "v1/TH/company/detail", {});
        if (result && result.status) {
            let data = result.data.data;
            if (new Date(data.start_date_booking_mc).getTime() <= new Date().getTime() && new Date().getTime() <= new Date(data.end_date_booking_mc).getTime()) {
                this.setState({
                    close: true,
                    end_date_booking_mc: data.end_date_booking_mc,
                    start_date_booking_mc: data.start_date_booking_mc,
                })
            }
        }
    }
    GetBikeAll = async () => {
        this.setState({ loading: true });
        let data = {
            start_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", 'en') + " " + this.state.pick_up_time : null,
            end_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", 'en') + " " + this.state.return_time : null,
            search: ""
        }
        let result = await GET(tokens, "v1/TH/bike-manage/filter-search-public/page/0", data);
        if (result && result.status) {
            this.setState({ data_bike: result.data })

        }
        this.setState({ loading: false });
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_import: false,

            page: 1,
            loading: false,

        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1={global[locale]["หน้าหลัก"]} page2={global[locale]["การจองรถ"]} />
                {/* BODY */}
                <div className="body background3 min-vh-100">
                    {/* ค้นหา */}
                    <div className="row">
                        <div className="col-12 col-md-10 p-3 mx-auto">
                            <div className="card border-0 px-4 py-2 bg-white shadow">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 px-1">
                                        <b className="text-orange mb-2">{global[locale]["สถานที่รับรถ"]}{required}</b>
                                        <input
                                            type="text"
                                            className="form-control w-100 mb-2"
                                            placeholder="เช่น สนามบินเชียงใหม่"
                                            onChange={(e) => { this.setState({ pick_up_location: e.target.value }); }}
                                            value={this.state.pick_up_location} />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 px-1">
                                        <b className="text-orange mb-2">{global[locale]["สถานที่คืนรถ"]}{required}</b>
                                        <input
                                            type="text"
                                            className="form-control w-100 mb-2"
                                            placeholder="เช่น สนามบินเชียงใหม่"
                                            onChange={(e) => { this.setState({ return_location: e.target.value }); }}
                                            value={this.state.return_location} />
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                        <b className="text-orange mb-2">{global[locale]["วันที่รับรถ"]}{required}</b>
                                        <DatePickerTH
                                            className="form-control w-100"
                                            onChange={(e) => {
                                                this.setState({ pick_up_date: e });
                                                if (Number(format_date(e, "yyyymmdd")) >= Number(format_date(this.state.return_date, "yyyymmdd"))) {
                                                    this.setState({ return_date: format_date(new Date(format_date(e, "yyyy,mm,dd")).setDate(new Date(format_date(e, "yyyy,mm,dd")).getDate() + 1), "yyyy-mm-dd", "en") });
                                                }
                                                setTimeout(() => {
                                                    this.GetBikeAll()
                                                }, 10);
                                            }}
                                            value={this.state.pick_up_date}
                                            clearable={false}
                                            min={new Date(new Date().setDate(new Date().getDate() - 1))}
                                        />
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                        <b className="text-orange mb-2">{global[locale]["เวลารับรถ"]}{required}</b>
                                        <input
                                            type="time"
                                            className="form-control w-100 mb-2"
                                            onChange={(e) => { this.setState({ pick_up_time: e.target.value }); }}
                                            onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                            value={this.state.pick_up_time} />
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                        <b className="text-orange mb-2">{global[locale]["วันที่คืนรถ"]}{required}</b>
                                        <DatePickerTH
                                            className="form-control w-100"
                                            onChange={(e) => {
                                                this.setState({ return_date: e });
                                                setTimeout(() => {
                                                    this.GetBikeAll()
                                                }, 10);
                                            }}
                                            value={this.state.return_date}
                                            clearable={false}
                                            min={new Date(this.state.pick_up_date).setDate(new Date(this.state.pick_up_date).getDate() - 1)}
                                        />
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                        <b className="text-orange mb-2">{global[locale]["เวลาคืนรถ"]}{required}</b>
                                        <input
                                            type="time"
                                            className="form-control w-100 mb-2"
                                            onChange={(e) => { this.setState({ return_time: e.target.value }); }}
                                            onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                            value={this.state.return_time} />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 px-1">
                                        <b className="text-white mb-2">.</b>
                                        <button className="btn btn-orange w-100 mb-2" onClick={() => {
                                            if (this.state.pick_up_location === "") {
                                                alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่รับรถ");
                                                return;
                                            } else if (this.state.return_location === "") {
                                                alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่คืนรถ");
                                                return;
                                            }
                                            this.GetBikeAll()
                                        }}>
                                            {global[locale]["ค้นหา"]}
                                            <label className="icon ml-2">{"\uf002"}</label>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* รายการรถ */}
                    <div className="row">
                        <div className="col-12 col-md-1"></div>
                        <div className="col-12 col-md-10 pb-5 px-4">
                            <div className="row">
                                {this.state.data_bike && this.state.data_bike.data.map((item, index) => (
                                    <div className="product col-6 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 mb-3 px-2">
                                        <div className="card bg-white shadow overflow-hidden border-0 position-relative"
                                            onClick={() => {
                                                if (this.state.close) {
                                                    alert("warning", "แจ้งเตือน", "ไม่สามารถจองได้เนื่องจากระบบ ปิดการจองรถ ตั้งแต่วันที่ " + format_date(this.state.start_date_booking_mc) + "-" + format_date(this.state.end_date_booking_mc))
                                                } else {
                                                    window.location.href = "/user/create-booking?brand_id=" + item.brand_id +
                                                        "&model_id=" + item.model_id +
                                                        "&pick_up_location=" + this.state.pick_up_location +
                                                        "&pick_up_time=" + this.state.pick_up_time +
                                                        "&pick_up_date=" + this.state.pick_up_date +
                                                        "&return_location=" + this.state.return_location +
                                                        "&return_time=" + this.state.return_time +
                                                        "&return_date=" + this.state.return_date
                                                }
                                            }}
                                        // onMouseOver={() => {
                                        //     this.setState({ product_index: index })
                                        // }}
                                        // onMouseLeave={() => {
                                        //     this.setState({ product_index: -1 })

                                        // }}
                                        >
                                            <img className="d-none d-sm-block" src={item.title_picture ? item.title_picture : CAR} style={{ height: 240, objectFit: "cover" }} />
                                            <img className="d-block d-sm-none" src={item.title_picture ? item.title_picture : CAR} style={{ height: 180, objectFit: "cover" }} />
                                            <div className="p-2">
                                                <div style={{ height: 23, overflow: "hidden" }}>
                                                    <h6 className="mb-1 d-block d-sm-none"><b>{item.brand_name}</b> {item.model_name}</h6>
                                                    <h5 className="mb-1 d-none d-sm-block"><b>{item.brand_name}</b> {item.model_name}</h5>
                                                </div>
                                                <hr className="my-2" />
                                                <div className="d-flex row justify-content-between mx-0">
                                                    <div className="p-0 d-flex align-items-end">
                                                        <h4 className="mb-0 text-orange"><b>฿{item.daily_price}</b></h4>
                                                        <small className="text-secondary ml-2">{global[locale]["ต่อวัน"]}</small>
                                                    </div>
                                                    <div className="p-0 d-flex align-items-end">
                                                        <small className="text-secondary ml-2">{global[locale]["คงเหลือ"]} {item.balance_app} {global[locale]["คัน"]}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            {index === this.state.product_index && (
                                                <div
                                                    className="fade-in-product rounded pointer position-absolute d-flex align-items-center justify-content-center"
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        left: 0,
                                                        top: 0,
                                                        borderWidth: 4,
                                                        borderStyle: "solid",
                                                        borderColor: "#fd7e14"
                                                    }}>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL */}
                {/* create or update */}
                <Modal
                    show={this.state.modal_create || this.state.modal_update}
                    onHide={() => { this.setState({ modal_create: false, modal_update: false }) }}
                    dialogClassName="modal-w"
                >
                    <Modal.Header> <Modal.Title> <b>{this.state.modal_create ? "เพิ่มผู้ใช้งาน" : "แก้ไขผู้ใช้งาน"}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {/* ซ้าย */}
                            <div className="col-12 col-lg-6">
                                {/* ชื่อ-นามสกุล */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">ชื่อ-นามสกุล{required} :</label>
                                    <input
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ full_name: e.target.value })
                                        }}
                                        value={this.state.full_name}
                                    />
                                </div>
                                {/* เลขบัตรประชาชน */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">เลขบัตรประชาชน :</label>
                                    <input
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ id_card: e.target.value })
                                        }}
                                        maxLength={13}
                                        value={this.state.id_card}
                                    />
                                </div>
                                {/* ที่อยู่ */}
                                <div className="w-100 d-flex  mb-3">
                                    <label className="wpx-140">ที่อยู่ :</label>
                                    <input
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ address: e.target.value })
                                        }}
                                        value={this.state.address}
                                    />
                                    {/* <textarea
                                        className="form-control  validate"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ address: e.target.value })
                                        }}
                                        value={this.state.address}
                                        rows={4}
                                    ></textarea> */}
                                </div>
                                {/* ขนาดห้อง (ตรม.) */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">ขนาดห้อง (ตรม.) :</label>
                                    <input
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            if (float(e.target.value)) {
                                                this.setState({ room_size: e.target.value })
                                            }
                                        }}
                                        maxLength={13}
                                        value={this.state.room_size}
                                    />
                                </div>
                                {/* โทรศัพท์บ้าน */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">โทรศัพท์บ้าน :</label>
                                    <input
                                        className="form-control "
                                        type="tel"
                                        maxLength={10}
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => { this.setState({ home_phone: e.target.value }) }}
                                        value={this.state.home_phone}
                                    />
                                </div>
                                {/* โทรศัพท์มือถือ */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">โทรศัพท์มือถือ{required} :</label>
                                    <input
                                        className="form-control  validate"
                                        type="tel"
                                        maxLength={10}
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => { this.setState({ mobile_phone: e.target.value }) }}
                                        value={this.state.mobile_phone}
                                    />
                                </div>
                                {/* วันที่ส่งมอบ */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">วันที่ส่งมอบ :</label>
                                    <DatePickerTH
                                        className="form-control bg-contain border-0 w-100"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ delivery_date: e }); }}
                                        value={this.state.delivery_date}
                                    />
                                </div>
                                {/* วันที่มารับมอบ */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">วันที่มารับมอบ :</label>
                                    <DatePickerTH
                                        className="form-control bg-contain border-0 w-100"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ receive_date: e }); }}
                                        value={this.state.receive_date}
                                    />
                                </div>
                                {/* วันที่โอน */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">วันที่โอน :</label>
                                    <DatePickerTH
                                        className="form-control bg-contain border-0 w-100"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ transfer_date: e }); }}
                                        value={this.state.transfer_date}
                                    />
                                </div>
                            </div>
                            {/* ขวา */}
                            <div className="col-12 col-lg-6">
                                {/* สถานะการโอน */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">สถานะการโอน :</label>
                                    <Select
                                        className="form-control bg-contain border-0 select-search"
                                        styles={select_style}
                                        options={[
                                            { value: 1, label: "โอนกรรมสิทธิ์แล้ว" },
                                            { value: 2, label: "ยังไม่โอนกรรมสิทธิ์" },
                                            { value: 3, label: "ห้องว่าง" }
                                        ]}
                                        onChange={(e) => {
                                            this.setState({ status_transfer: e });
                                        }}
                                        value={this.state.status_transfer}
                                    ></Select>
                                </div>
                                {/* เลขที่เงินกู้ */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">เลขที่เงินกู้ :</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ loan_number: e.target.value })
                                        }}
                                        value={this.state.loan_number}
                                    />
                                </div>
                                {/* วงเงินกู้ */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">วงเงินกู้ :</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            if (float(e.target.value)) {
                                                this.setState({ credit_limit: e.target.value })
                                            }
                                        }}
                                        value={this.state.credit_limit}
                                    />
                                </div>
                                {/* เงินงวด */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">เงินงวด :</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            if (float(e.target.value)) {
                                                this.setState({ installment: e.target.value })
                                            }
                                        }}
                                        value={this.state.installment}
                                    />
                                </div>
                                {/* วันที่ทำสัญญา */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">วันที่ทำสัญญา :</label>
                                    <DatePickerTH
                                        className="form-control bg-contain border-0 w-100"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ contract_date: e }); }}
                                        value={this.state.contract_date}
                                    />
                                </div>
                                {/* ซื้ออาคารโดย */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">ซื้ออาคารโดย :</label>
                                    <Select
                                        className="form-control bg-contain border-0 select-search"
                                        styles={select_style}
                                        options={[
                                            { value: 1, label: "เงินสด" },
                                            { value: 2, label: "ผ่อนชำระกับธนาคาร" },
                                            { value: 3, label: "ผู้เช่าซื้อกับการเคหะแห่งชาติ" },
                                            { value: 4, label: "ซื้อต่อกับลูกค้าเดิม" },
                                            { value: 5, label: "อื่น ๆ" },
                                        ]}
                                        onChange={(e) => { this.setState({ status_buy_building: e }) }}
                                        value={this.state.status_buy_building}
                                    ></Select>
                                </div>
                                {/* อาคาร */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">อาคาร{required} :</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            if (float(e.target.value)) {
                                                this.setState({ building: e.target.value })
                                            }
                                        }}
                                        value={this.state.building}
                                        readOnly={this.state.modal_update}
                                    />
                                </div>
                                {/* ชั้น */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">ชั้น{required} :</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            if (float(e.target.value)) {
                                                this.setState({ floor: e.target.value })
                                            }
                                        }}
                                        value={this.state.floor}
                                        readOnly={this.state.modal_update}
                                    />
                                </div>
                                {/* บ้านเลขที่ */}
                                <div className="w-100 d-flex align-items-center  mb-3">
                                    <label className="wpx-140">บ้านเลขที่{required} :</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ address_number: e.target.value })
                                        }}
                                        value={this.state.address_number}
                                        readOnly={this.state.modal_update}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-primary w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-primary w-100"
                                    onClick={() => {
                                        if (this.state.modal_create) {
                                            this.Create();
                                        } else {
                                            this.Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบข้อมูลลูกค้า</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                ท่านแน่ในว่าต้องการลบข้อมูลลูกค้า
                                หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-primary w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
