import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import Select from "react-select";
import Swal from "sweetalert2";
import {
    tokens,
    select_style,
    success,
    alert,
    GET,
    POST,
    PUT,
    format_date,
    float,
    validate,
    required,
    DatePickerTH,
    success_url,
    toFixed,
    total,
    validateEmail,
    days_between,
    status_booking,
    validateENNumber,
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import CAR from "../../assets/images/bike.png";
import EMPTY from "../../assets/images/empty.png";
import SignatureCanvas from 'react-signature-canvas';

import province from "../../assets/files/province.json"
import district from "../../assets/files/district.json"
import subdistrict from "../../assets/files/subdistrict.json"

export default class CreateUpdateRentCar extends Component {
    constructor(props) {
        super(props)
        this.sigPad = {}
        this.state = {
            readonly: false,
            change_car: true,
            update: false,

            modal_car: false,
            modal_detail: false,
            modal_signature: false,
            modal_create_user: false,

            page: 1,
            loading: false,

            data: null,
            data_booking: [],
            product_index: -1,
            data_bike: [],
            data_equipment: [],
            data_agent: [],

            rental_number: "",
            address: "",
            birthday: null,
            district: "",
            email: "",
            full_name: "",
            id_card: "",
            is_active: true,
            member_code: "",
            member_id: "",
            member_status: 1,
            phone: "",
            picture: "",
            province: "",
            sub_district: "",
            username: "",
            verify_status: true,
            zip_code: "",

            facebook: "",
            id_line: "",
            nationality: "",
            card_issue: "",
            date_issue: "",
            date_expire: "",
            temporary_accommodation: "",
            points: 0,
            sk_wallet: 0,

            pick_up_date: new Date(),
            pick_up_time: "",
            pick_up_location: "",
            return_date: new Date().setDate(new Date().getDate() + 1),
            return_time: "",
            return_location: "",

            rental_type: { value: "daily", label: "รายวัน" },
            mileage: 0,
            oil_volume: 100,
            rental_price: 0,
            guarantee_type: null,
            guarantee_amount: 0,
            equipment_fee: 0,
            discount_price: 0,
            delivery_price: 0,
            other_price: 0,
            recommen_id: "",
            total_price: 0,
            signature: "",
            discount_pay: 0,
            overdue_booking: 0,

            total_price_equipment: 0,
            total_qty_equipment: 0,

            detail_picture: ["", "", "", "", "", "", "", "", "", ""],

            selected_bike: null,

            search_bike: "",


            days: 0,

            booking_id: "",
            deposit_pay: "",
            payment_date: "",
            payment_time: "",
            payment_picture: "",
            deposit_price: "",
            booking_price: "",
            traffic_ticket_price: "",

            date: "",
            time: "",

            array_price: {
                daily_price: 0,
                weekly_price: 0,
                monthly_price: 0
            },

            array_bf_price: {
                daily_price: 0,
                weekly_price: 0,
                monthly_price: 0
            },
            wallet: null,
            booking_number: "",

            id: "",
            id_card: "",
            full_name: "",
            birthday: "",
            address: "",
            province: "",
            district: "",
            sub_district: "",
            zip_code: "",
            email: "",
            phone: "",
            username: "",
            password: "",
            member_status: "",
            profile_picture: "",
            hours: 0,
            minutes: 0,

            id2: "",
            id_card2: "",
            full_name2: "",
            birthday2: "",
            address2: "",
            province2: "",
            district2: "",
            sub_district2: "",
            zip_code2: "",
            email2: "",
            phone2: "",
            username2: "",
            password2: "",
            member_status2: "",
            profile_picture2: "",

        }
    }
    async componentDidMount() {
        this.setState({ loading: true });
        await this.GetBikeAll();
        await this.GetAgentAll();
        await this.GetEquipmentAll();
        if (this.props.location.query.id) {
            await this.GetById(this.props.location.query.id)
            this.setState({ update: true });
            await this.GetBookingAll();
        }
        if (this.props.location.query.booking_id) {
            await this.GetBookingById(this.props.location.query.booking_id)
            await this.GetBookingAll();
        }
        if (this.state.selected_bike && this.state.selected_bike.license_plate) {
            this.setState({
                array_price: this.state.data_bike
                    .filter(e => e.license_plate === this.state.selected_bike.license_plate)
                    .map(e => {
                        return {
                            daily_price: e.daily_price,
                            weekly_price: e.weekly_price,
                            monthly_price: e.monthly_price
                        }
                    })[0]
            })
            let days = days_between(
                new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
                new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                this.state.pick_up_time,
                this.state.return_time
            )
            let hours_price = 0;
            let rental_price = 0;
            if (days.day === 0) {
                hours_price = this.state.array_price.daily_price;
            } else {
                if (days.hour === 0 && days.minute >= 0) {
                    //ไม่ต้องหัก 
                } else if (days.hour === 1 && days.minute >= 0) {
                    if (this.state.array_price.daily_price < 400) {
                        hours_price = 50; //หัก 50 บาท
                    } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                        hours_price = 100; //หัก 100 บาท
                    } else if (this.state.array_price.daily_price > 700) {
                        hours_price = 200; //หัก 200 บาท
                    }
                } else if (days.hour === 2 && days.minute >= 0) {
                    if (this.state.array_price.daily_price < 400) {
                        hours_price = 100; //หัก 50 บาท
                    } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                        hours_price = 200; //หัก 100 บาท
                    } else if (this.state.array_price.daily_price > 700) {
                        hours_price = 400; //หัก 200 บาท
                    }
                } else if (days.hour === 3 && days.minute >= 0) {
                    if (this.state.array_price.daily_price < 400) {
                        hours_price = 150; //หัก 50 บาท
                    } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                        hours_price = 300; //หัก 100 บาท
                    } else if (this.state.array_price.daily_price > 700) {
                        hours_price = 600; //หัก 200 บาท
                    }
                } else if (days.hour >= 4) {
                    hours_price = this.state.array_price.daily_price;//หัก daily_price
                }
            }
            setTimeout(() => {
                if (this.state.selected_bike) {
                    if (this.state.rental_type.value === "daily") {
                        rental_price = this.state.array_price.daily_price * this.state.days;
                        rental_price += hours_price;
                    } else if (this.state.rental_type.value === "weekly") {
                        if (this.state.array_price.weekly_price === 0) {
                            rental_price = this.state.array_price.daily_price * this.state.days;
                            rental_price += hours_price;
                        } else {
                            if (this.state.days >= 7) {
                                rental_price += Number((this.state.days / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                rental_price += (this.state.days % 7) * this.state.array_price.daily_price;
                                rental_price += hours_price;
                            } else {
                                rental_price = this.state.array_price.weekly_price;
                            }
                        }
                    } else if (this.state.rental_type.value === "monthly") {
                        if (this.state.array_price.monthly_price === 0) {
                            rental_price = this.state.array_price.daily_price * this.state.days;
                            rental_price += hours_price;
                        } else {
                            if (this.state.days >= 30) {
                                rental_price += Number((this.state.days / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                rental_price += Number(((this.state.days % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                rental_price += ((this.state.days % 30) % 7) * this.state.array_price.daily_price;
                                rental_price += hours_price;
                            } else {
                                rental_price = this.state.array_price.monthly_price;
                            }
                        }
                    }
                }
                this.setState({ rental_price: rental_price })
            }, 10);
        }
        if (this.props.location.query.type === "detail") {
            this.setState({ readonly: true, change_car: false })
        }
        if (this.props.location.query.booking_price && this.props.location.query.deposit_price) {
            this.setState({ overdue_booking: (Number(this.props.location.query.booking_price) + Number(this.props.location.query.traffic_ticket_price)) - Number(this.props.location.query.deposit_price) })
        }
        if (this.props.location.query.delivery_price) {
            this.setState({ delivery_price: Number(this.props.location.query.delivery_price) })
        }
        if (this.props.location.query.equipment_fee) {
            this.setState({ equipment_fee: Number(this.props.location.query.equipment_fee) })
        }
        if (this.props.location.query.equipment_list) {
            let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
            let total_price_equipment = 0, total_qty_equipment = 0;
            for (let eq of JSON.parse(this.props.location.query.equipment_list)) {
                let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                if (index !== -1) {
                    data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                    data_equipment[index].equipment_qty = eq.equipment_qty;
                    total_qty_equipment += eq.equipment_qty;
                    total_price_equipment += eq.equipment_price * eq.equipment_qty;
                }
            }
            this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
        }
        this.setState({ loading: false });
        if (this.state.id_card) {
            this.GetMemberByIDCard();
        }
    }
    GetMemberByIDCard = async () => {
        if (this.state.id_card === "") {
            return
        }
        this.setState({ loading: true });
        let data = {
            data_search: this.state.id_card,
        }
        let result = await GET(tokens, "v1/TH/user-member/filter-search/page/0", data);
        if (result && result.status) {
            if (result.data.data.filter(e => e.id_card === this.state.id_card).length === 1) {
                if (total(result.data.data.filter(e => e.id_card === this.state.id_card)[0].traffic_ticket.filter(e => e.ticket_status !== 2), "traffic_fines") !== 0) {
                    this.setState({
                        address: "",
                        birthday: "",
                        district: "",
                        email: "",
                        full_name: "",
                        id_card: "",
                        is_active: "",
                        member_code: "",
                        member_id: "",
                        member_status: "",
                        phone: "",
                        nationality: "",
                        card_issue: "",
                        date_issue: "",
                        date_expire: "",
                        temporary_accommodation: "",
                        facebook: "",
                        id_line: "",
                        picture: "",
                        province: "",
                        sub_district: "",
                        username: "",
                        verify_status: "",
                        zip_code: "",
                        loading: false,
                        points: 0,
                        sk_wallet: 0
                    });
                    alert("warning", "แจ้งเตือน", "มีค่าปรับค้างชำระจำนวน " + toFixed(total(result.data.data[0].traffic_ticket.filter(e => e.ticket_status !== 2), "traffic_fines")) + " บาท ไม่สามารถเช่ารถได้");
                    return
                }
                if (result.data.data.filter(e => e.id_card === this.state.id_card)[0].member_status === 0) {
                    alert("warning", "แจ้งเตือน", "ไม่สามารถทำการเช่าได้เนื่องจากลูกค้าติดแบล็กลิสต์");
                    this.setState({ loading: false })
                    return
                }
                let data = result.data.data.filter(e => e.id_card === this.state.id_card)[0];
                this.setState({
                    address: data.address,
                    birthday: data.birthday,
                    district: data.district,
                    email: data.email,
                    full_name: data.full_name,
                    picture: data.picture.replaceAll("%0D", "").replaceAll("%0d", ""),
                    id_card: data.id_card,
                    is_active: data.is_active,
                    member_code: data.member_code,
                    member_id: data.member_id,
                    member_status: data.member_status,
                    phone: data.phone,
                    nationality: data.nationality,
                    card_issue: data.card_issue,
                    date_issue: data.date_issue,
                    date_expire: data.date_expire,
                    temporary_accommodation: data.temporary_accommodation,
                    facebook: data.facebook,
                    id_line: data.id_line,
                    province: data.province,
                    sub_district: data.sub_district,
                    username: data.username,
                    verify_status: data.verify_status,
                    zip_code: data.zip_code,
                    points: data.points,
                    sk_wallet: data.sk_wallet
                })
                setTimeout(() => {
                    this.GetBookingAll();
                }, 10);
            } else {
                this.setState({
                    address: "",
                    birthday: "",
                    district: "",
                    email: "",
                    full_name: "",
                    id_card: "",
                    is_active: "",
                    member_code: "",
                    member_id: "",
                    member_status: "",
                    phone: "",
                    nationality: "",
                    card_issue: "",
                    date_issue: "",
                    date_expire: "",
                    temporary_accommodation: "",
                    facebook: "",
                    id_line: "",
                    picture: "",
                    province: "",
                    sub_district: "",
                    username: "",
                    verify_status: "",
                    zip_code: "",
                    loading: false,
                    points: 0,
                    sk_wallet: 0
                });
                alert("warning", "แจ้งเตือน", "ไม่พบข้อมูลสมาชิก");
                return
            }

        }
        this.setState({ loading: false });
    }
    GetBookingAll = async () => {
        let data = {
            data_search: this.state.id_card,
        }
        let result = await GET(tokens, "v1/TH/booking/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_booking: result.data.data })
        }
    }
    GetAgentAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/user-agent/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_agent: result.data.data.map(e => { return { value: e.agent_id, label: e.agent_code + " : " + e.full_name } }) })
        }
        this.setState({ loading: false });
    }
    GetBikeAll = async () => {
        let data = {
            start_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : null,
            end_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null
        }
        let result = await GET(tokens, "v1/TH/rental-manage/total-bike-rental", data);
        if (result && result.status) {
            this.setState({ data_bike: result.data.data }) 
        }
    }
    GetEquipmentAll = async () => {
        let result = await GET(tokens, "v1/TH/equipment/filter-search/page/0", null);
        if (result && result.status) {
            this.setState({ data_equipment: result.data.data.map(item => { return { ...item, equipment_qty: 0, rental_equipment_id: "" } }) })
        }
    }
    GetById = async (id) => {
        let result = await GET(tokens, "v1/TH/rental-manage/detail/" + id, null);
        if (result && result.status) {
            let item = result.data.data;

            let data_equipment = this.state.data_equipment; 
            for (let eq of item.equipment_list) {
                let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                if (index !== -1) {
                    data_equipment[index].equipment_qty = eq.equipment_qty;
                    data_equipment[index].rental_equipment_id = eq.rental_equipment_id;
                }
            }
            this.setState({ data_equipment: data_equipment });

            if (this.state.data_bike.filter(e => e.license_plate === item.license_plate).length === 0) {
                let data_bike = this.state.data_bike;
                data_bike.push({
                    bike_color: item.bike_color,
                    bike_year: item.bike_year,
                    brand_id: item.brand_id,
                    brand_name: item.brand_name,
                    cc_size: item.cc_size,
                    chassis_number: item.chassis_number,
                    daily_price: item.daily_price,
                    license_plate: item.license_plate,
                    model_id: item.model_id,
                    model_name: item.model_name,
                    monthly_price: item.monthly_price,
                    title_picture: item.title_picture,
                    weekly_price: item.weekly_price,
                    channel_name: item.bike_channel_name
                })
                this.setState({ data_bike: data_bike });
            }
            let detail_picture = item.detail_picture.map(e => { return e.picture });
            for (let i = 0; i < 10; i++) {
                if (item.detail_picture.length <= i) {
                    detail_picture.push("")
                }
            }

            let days = days_between(
                new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")),
                new Date(format_date(item.return_date, "yyyy,mm,dd", "en")),
                item.pick_up_time,
                item.return_time
            )
            setTimeout(() => { 
                this.setState({
                    rental_number: item.rental_number,
                    booking_number: item.booking_number,
                    id_card: item.id_card,
                    full_name: item.full_name,
                    picture: item.picture,
                    birthday: item.birthday,
                    nationality: item.nationality,
                    card_issue: item.card_issue,
                    date_issue: item.date_issue,
                    date_expire: item.date_expire,
                    temporary_accommodation: item.temporary_accommodation,
                    address: item.address,
                    facebook: item.facebook,
                    phone: item.phone,
                    email: item.email,
                    id_line: item.id_line,
                    selected_bike: this.state.data_bike.filter(e => e.license_plate === item.license_plate)[0],
                    pick_up_date: item.pick_up_date,
                    pick_up_time: item.pick_up_time,
                    pick_up_location: item.pick_up_location,
                    return_date: item.return_date,
                    return_time: item.return_time,
                    return_location: item.return_location,

                    rental_type: item.rental_type === "daily" ? { value: "daily", label: "รายวัน" } :
                        item.rental_type === "weekly" ? { value: "weekly", label: "รายสัปดาห์" } :
                            item.rental_type === "monthly" ? { value: "monthly", label: "รายเดือน" } : { value: "daily", label: "รายวัน" },
                    mileage: item.mileage,
                    oil_volume: item.oil_volume,
                    rental_price: item.rental_price,
                    guarantee_type: item.guarantee_type === 1 ? { value: "1", label: "เงินประกัน" } :
                        item.guarantee_type === 2 ? { value: "2", label: "สำเนาบัตรประชาชน" } :
                            item.guarantee_type === 3 ? { value: "3", label: "บัตรประชาชนตัวจริง" } :
                                item.guarantee_type === 4 ? { value: "4", label: "บัตรข้าราชการ" } :
                                    item.guarantee_type === 5 ? { value: "5", label: "บัตรนักศึกษา" } :
                                        item.guarantee_type === 6 ? { value: "6", label: "พาสปอร์ตตัวจริง" } :
                                            item.guarantee_type === 7 ? { value: "7", label: "สำเนาพาสปอร์ต" } :
                                                item.guarantee_type === 8 ? { value: "8", label: "ใบขับขี่" } :
                                                    item.guarantee_type === 9 ? { value: "9", label: "ใบขับขี่ตัวจริง" } :
                                                        item.guarantee_type === 10 ? { value: "10", label: "เงินหยวน" } :
                                                            item.guarantee_type === 11 ? { value: "11", label: "เงินดอลล่า" } :
                                                                item.guarantee_type === 12 ? { value: "12", label: "เงินยูโร" } : null,
                    guarantee_amount: item.guarantee_amount,
                    guarantee_money: item.guarantee_money,
                    equipment_fee: item.equipment_fee,
                    discount_price: item.discount_price,
                    other_price: item.other_price ? item.other_price : 0,
                    delivery_price: item.delivery_price,
                    recommen_id: item.recommen_id ? this.state.data_agent.filter(e => e.value === item.recommen_id)[0] : "",
                    total_price: item.total_price,
                    signature: item.signature,
                    equipment_list: item.equipment_list,
                    total_price_equipment: item.total_price_equipment,
                    total_qty_equipment: item.total_qty_equipment,
                    detail_picture: detail_picture,
                    title_picture: item.title_picture,
                    discount_pay: item.discount_pay,
                    days: days.day,
                    hours: days.hour,
                    minutes: days.minute,
                })
            }, 10);
        }
    }
    GetBookingById = async (id) => {
        let result = await GET(tokens, "v1/TH/booking/detail/" + id, null);
        if (result && result.status) {
            let item = result.data.data;
            if (this.state.data_bike.filter(e => e.license_plate === item.license_plate).length === 0) {
                let data_bike = this.state.data_bike;
                data_bike.push({
                    bike_color: item.bike_color,
                    bike_year: item.bike_year,
                    brand_id: item.brand_id,
                    brand_name: item.brand_name,
                    cc_size: item.cc_size,
                    chassis_number: item.chassis_number,
                    daily_price: item.daily_price,
                    license_plate: item.license_plate,
                    model_id: item.model_id,
                    model_name: item.model_name,
                    monthly_price: item.monthly_price,
                    title_picture: item.title_picture,
                    weekly_price: item.weekly_price,
                    channel_name: item.channel_name
                })
                this.setState({ data_bike: data_bike });
            }
            let days = days_between(
                new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")),
                new Date(format_date(item.return_date, "yyyy,mm,dd", "en")),
                item.pick_up_time,
                item.return_time
            )
            this.setState({
                booking_id: id,
                booking_number: item.booking_number,
                id_card: item.id_card,
                full_name: item.full_name,
                phone: item.phone,
                email: item.email,
                selected_bike: this.state.data_bike.filter(e => e.license_plate === item.license_plate)[0],
                pick_up_date: item.pick_up_date,
                pick_up_time: item.pick_up_time,
                pick_up_location: item.pick_up_location,
                return_date: item.return_date,
                return_time: item.return_time,
                return_location: item.return_location,
                other_price: item.other_price ? item.other_price : 0,

                days: days.day,
                hours: days.hour,
                minutes: days.minute,

                deposit_pay: item.deposit_pay,
                payment_date: item.payment_date,
                payment_time: item.payment_time,
                payment_picture: item.payment_picture,
                deposit_price: item.deposit_price,
                booking_price: item.booking_price,
                traffic_ticket_price: item.traffic_ticket_price,
                rental_type: { value: "daily", label: "รายวัน" },

            })
        }

    }

    CreateUser = async () => {
        if (validate(this.state, ["id_card2", "full_name2", "phone2", "username2"])) { return };
        if (this.state.phone2.length > 0 && this.state.phone2.length < 10) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน")
            return
        } else if (this.state.email2.length !== 0 && !validateEmail(this.state.email2)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        } else if (this.state.password2.length < 4) {
            alert("warning", "แจ้งเตือน", "รหัสผ่านต้องมากกว่า 4 ตัวขึ้นไป")
            return
        } else if (!this.state.email2 && !this.state.username2) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล หรือ ชื่อผู้ใช้");
            return
        }
        this.setState({ loading: true });
        let data = {
            id_card: this.state.id_card2,
            full_name: this.state.full_name2,
            birthday: this.state.birthday2 ? this.state.birthday2 : null,
            address: this.state.address2,
            province: this.state.province2 ? this.state.province2.value : "",
            district: this.state.district2 ? this.state.district2.value : "",
            sub_district: this.state.sub_district2 ? this.state.sub_district2.value : "",
            zip_code: this.state.zip_code2,
            email: this.state.email2,
            phone: this.state.phone2,
            picture: this.state.profile_picture2,
            username: this.state.username2,
            password: this.state.password2,
            is_active: true,
            nationality: this.state.nationality2,
            card_issue: this.state.card_issue2,
            date_issue: this.state.date_issue2,
            date_expire: this.state.date_expire2,
            facebook: this.state.facebook2,
            id_line: this.state.id_line2,
        }
        let result = await POST(tokens, "v1/TH/user-member/create", data);
        if (result && result.status) {
            success();
            this.setState({
                id_card: this.state.id_card2,
                full_name2: "",
                birthday2: "",
                address2: "",
                province2: "",
                district2: "",
                sub_district2: "",
                zip_code2: "",
                email2: "",
                phone2: "",
                picture2: "",
                username2: "",
                password2: "",
                nationality2: "",
                card_issue2: "",
                date_issue2: "",
                date_expire2: "",
                facebook2: "",
                id_line2: "",
                is_active: true,
                modal_create_user: false
            })
            setTimeout(() => {
                this.GetMemberByIDCard();
            }, 10);
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        if (!this.state.id_card) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสบัตรประชาชน")
            return
        }
        if (!this.state.full_name) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุล")
            return
        }
        if (!this.state.phone) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์")
            return
        }
        if (this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
            return
        }
        if (!this.state.selected_bike) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกรถ")
            return
        }
        if (!this.state.pick_up_location) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่รับรถ")
            return
        }
        if (!this.state.return_location) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่คืนรถ")
            return
        }
        if (this.state.email !== "" && !validateEmail(this.state.email)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        }
        if (Number(this.state.mileage) === 0) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเลขไมล์")
            return
        }
        if (!this.state.rental_type) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการเช่า")
            return
        }
        if (!this.state.oil_volume) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกปริมาณน้ำมันรถ")
            return
        }
        if (!this.state.guarantee_type) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการค้ำประกัน")
            return
        }
        if (!this.state.signature) {
            alert("warning", "แจ้งเตือน", "กรุณาเพิ่มลายเซ้นต์")
            return
        }
        this.setState({ loading: true });
        let data = {
            booking_number: this.state.booking_number,
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            picture: this.state.picture,
            birthday: this.state.birthday ? format_date(this.state.birthday, "yyyy-mm-dd", "en") : null,
            nationality: this.state.nationality,
            card_issue: this.state.card_issue,
            date_issue: this.state.date_issue ? format_date(this.state.date_issue, "yyyy-mm-dd", "en") : null,
            date_expire: this.state.date_expire ? format_date(this.state.date_expire, "yyyy-mm-dd", "en") : null,
            temporary_accommodation: this.state.temporary_accommodation,
            address: this.state.address,
            facebook: this.state.facebook,
            phone: this.state.phone,
            email: this.state.email,
            id_line: this.state.id_line,

            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : null,
            pick_up_time: this.state.pick_up_time,
            pick_up_location: this.state.pick_up_location,
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null,
            return_time: this.state.return_time,
            return_location: this.state.return_location,

            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily",
            mileage: Number(this.state.mileage),
            oil_volume: Number(this.state.oil_volume),
            rental_price: Number(this.state.rental_price),
            guarantee_type: this.state.guarantee_type ? this.state.guarantee_type.value : "",
            guarantee_amount: Number(this.state.guarantee_amount),
            guarantee_money: Number(this.state.guarantee_money),
            equipment_fee: Number(this.state.equipment_fee),
            discount_price: Number(this.state.discount_price),
            delivery_price: Number(this.state.delivery_price),
            other_price: Number(this.state.other_price),
            recommen_id: this.state.recommen_id ? this.state.recommen_id.value : "",
            total_price: this.state.booking_number ?
                (Number(this.state.rental_price) + Number(this.state.equipment_fee) + Number(this.state.delivery_price) + Number(this.state.guarantee_amount) - Number(this.state.discount_price) + Number(this.state.other_price) - Number(this.props.location.query.deposit_price)) :
                (Number(this.state.rental_price) + Number(this.state.equipment_fee) + Number(this.state.delivery_price) + Number(this.state.guarantee_amount) - Number(this.state.discount_price) + Number(this.state.other_price)),
            signature: this.state.signature ? this.state.signature : "",
            equipment_list: this.state.data_equipment.filter(e => e.equipment_qty > 0),
            total_price_equipment: Number(this.state.total_price_equipment),
            total_qty_equipment: Number(this.state.total_qty_equipment),
            discount_pay: 0,
            overdue_booking: this.state.overdue_booking,
            total_pay_booking: this.props.location.query.deposit_price ? Number(this.props.location.query.deposit_price) : 0,

            detail_picture: this.state.detail_picture.filter(e => e !== ""),

            bf_daily_price: 0,
            bf_weekly_price: 0,
            bf_monthly_price: 0,
        }
        let result = await POST(tokens, "v1/TH/rental-manage/create", data);
        if (result && result.status) {
            success_url("/admin/manage-rent")
        }
        this.setState({ loading: false });

    }
    Update = async () => {
        if (!this.state.id_card) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสบัตรประชาชน")
            return
        }
        if (!this.state.full_name) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุล")
            return
        }
        if (!this.state.phone) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์")
            return
        }
        if (this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
            return
        }
        if (!this.state.selected_bike) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกรถ")
            return
        }
        if (!this.state.pick_up_location) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่รับรถ")
            return
        }
        if (!this.state.return_location) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่คืนรถ")
            return
        }
        if (this.state.email !== "" && !validateEmail(this.state.email)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        }
        if (Number(this.state.mileage) === 0) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเลขไมล์")
            return
        }
        if (!this.state.rental_type) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการเช่า")
            return
        }
        if (!this.state.oil_volume) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกปริมาณน้ำมันรถ")
            return
        }
        if (!this.state.guarantee_type) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการค้ำประกัน")
            return
        }
        if (!this.state.signature) {
            alert("warning", "แจ้งเตือน", "กรุณาเพิ่มลายเซ้นต์")
            return
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.props.location.query.id,
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            picture: this.state.picture,
            birthday: this.state.birthday ? format_date(this.state.birthday, "yyyy-mm-dd", "en") : null,
            nationality: this.state.nationality,
            card_issue: this.state.card_issue,
            date_issue: this.state.date_issue ? format_date(this.state.date_issue, "yyyy-mm-dd", "en") : null,
            date_expire: this.state.date_expire ? format_date(this.state.date_expire, "yyyy-mm-dd", "en") : null,
            temporary_accommodation: this.state.temporary_accommodation,
            address: this.state.address,
            facebook: this.state.facebook,
            phone: this.state.phone,
            email: this.state.email,
            id_line: this.state.id_line,

            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : null,
            pick_up_time: this.state.pick_up_time,
            pick_up_location: this.state.pick_up_location,
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null,
            return_time: this.state.return_time,
            return_location: this.state.return_location,

            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily",
            mileage: Number(this.state.mileage),
            oil_volume: Number(this.state.oil_volume),
            rental_price: Number(this.state.rental_price),
            guarantee_type: this.state.guarantee_type ? Number(this.state.guarantee_type.value) : "",
            guarantee_amount: Number(this.state.guarantee_amount),
            guarantee_money: Number(this.state.guarantee_money),
            equipment_fee: Number(this.state.equipment_fee),
            discount_price: Number(this.state.discount_price),
            delivery_price: Number(this.state.delivery_price),
            other_price: Number(this.state.other_price),
            recommen_id: this.state.recommen_id ? this.state.recommen_id.value : "",
            total_price: (Number(this.state.rental_price) + Number(this.state.equipment_fee) + Number(this.state.delivery_price) + Number(this.state.guarantee_amount) - Number(this.state.discount_price) + Number(this.state.other_price) + Number(this.state.overdue_booking)),
            signature: this.state.signature ? this.state.signature : "",
            equipment_list: this.state.data_equipment.filter(e => e.equipment_qty > 0),
            total_price_equipment: Number(this.state.total_price_equipment),
            total_qty_equipment: Number(this.state.total_qty_equipment),
            discount_pay: Number(this.state.discount_pay),
            overdue_booking: 0,
            total_pay_booking: 0,

            detail_picture: this.state.detail_picture.filter(e => e !== ""),

            bf_daily_price: this.state.array_bf_price.daily_price,
            bf_weekly_price: this.state.array_bf_price.weekly_price,
            bf_monthly_price: this.state.array_bf_price.monthly_price
        }
        let result = await PUT(tokens, "v1/TH/rental-manage/update", data);
        if (result && result.status) {
            success_url("/admin/manage-rent")
        }
        this.setState({ loading: false });

    }
    trim = () => {
        this.setState({ signature: this.sigPad.getTrimmedCanvas().toDataURL('image/png').split(",")[1] })
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => {
            this.GetAll()
        }, 20)
    }
    clear_form() {
        this.setState({
            modal_car: false,
            modal_detail: false,

            page: 1,
            loading: false,
            product_index: -1

        })
    }
    import_file = (e, type) => {
        if (this.state.detail_picture.filter(item => item.length !== 0).length + e.target.files.length > 10) {
            alert("warning", "แจ้งเตือน", "สามารถอัพโหลดรูปได้สูงสุด 10 รูป");
            return;
        }
        if (e.target.files.length === 1) {
            this.changePhoto(e.target.files[0], type)
            document.getElementById("file_id_card").value = "";
            document.getElementById("file_id_card").file = null;
            document.getElementById("file_sub").value = "";
            document.getElementById("file_sub").file = null;
            if (document.getElementById("file_profile")) {
                document.getElementById("file_profile").value = "";
                document.getElementById("file_profile").file = null;
            }
        } else {
            this.multiple_file(0, e.target.files.length, e.target.files);
        }
    }
    multiple_file = async (index, max, file) => {
        await this.changePhoto(file[index], "sub");
        if (index + 1 < max) {
            setTimeout(() => {
                this.multiple_file(index + 1, max, file);
            }, 50);
        }
    }
    changePhoto = async (file, type) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => { }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    if (type === "id_card") {
                        this.setState({ picture: uri.split(",")[1] })
                    } else if (type === "main") {
                        this.setState({ title_picture: uri.split(",")[1] })
                    } else if (type === "profile") {
                        this.setState({ profile_picture2: uri.split(",")[1] })
                    } else {
                        let detail_picture = this.state.detail_picture;
                        let index = detail_picture.findIndex(item => item === "");
                        if (index !== -1) {
                            detail_picture[index] = uri.split(",")[1];
                        }
                        this.setState({
                            detail_picture: detail_picture
                        })
                    }

                },
                "base64"
            );
        });
    }
    delete_image = (picture) => {
        let data = this.state.detail_picture;
        let index = data.findIndex(item => item === picture);
        data[index] = "";
        let array = [];
        for (let item of data) {
            if (item !== "") {
                array.push(item);
            }
        }
        array = [...array, "", "", "", "", "", "", "", "", "", ""];
        array = array.filter((item, index) => index < 10);
        setTimeout(() => {
            this.setState({ detail_picture: data });
        }, 100);
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2={this.state.update ? "แก้ไขสัญญาเช่า" : "สร้างสัญญาเช่า"} />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="row">
                        <div className="col-12 col-lg-10 mx-auto">
                            <div className="p-3">
                                {/* หัวข้อ และ ปุ่มสร้าง */}
                                <div className="d-flex row mx-0 align-items-center justify-content-between test">
                                    <h4 className="mb-3"><b>{this.state.update ? "แก้ไขสัญญาเช่า : " + this.state.rental_number : "สร้างสัญญาเช่า"}</b></h4>
                                </div>
                                {/* ข้อมูลผู้เช่า */}
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-3 pb-1">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0"><b>ข้อมูลผู้เช่า</b></h4>
                                            <button type="button" className="btn btn-orange px-4 mx-1 add"
                                                onClick={async () => {
                                                    this.setState({
                                                        modal_create_user: true,
                                                        id2: "",
                                                        id_card2: "",
                                                        full_name2: "",
                                                        birthday2: "",
                                                        address2: "",
                                                        province2: "",
                                                        district2: "",
                                                        sub_district2: "",
                                                        zip_code2: "",
                                                        email2: "",
                                                        phone2: "",
                                                        username2: "",
                                                        password2: "",
                                                        member_status2: "",
                                                        profile_picture2: "",
                                                    })
                                                }}>
                                                เพิ่มสมาชิก
                                            </button>
                                        </div>
                                        <hr />
                                        <div className="row mx-0">
                                            <div className="col-12 col-lg-6 col-xl-3 mb-2 px-1">
                                                {this.state.picture === "" ? (
                                                    <div
                                                        className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5 pointer"
                                                        style={{ height: "100%" }}
                                                        onClick={() => { if (!this.state.readonly) { document.getElementById("file_id_card").click() } }}>
                                                        <label className="icon text-orange">{"\uf03e"}</label>
                                                        <label className="text-orange">รูปบัตรประชาชนหรือพาสปอร์ต</label>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                                        style={{ height: "100%" }}
                                                    >
                                                        <div className="position-relative w-100">
                                                            <img alt="รูปบัตรประชาชนหรือพาสปอร์ต" src={this.state.picture.includes("https://") ? this.state.picture : "data:image/png;base64," + this.state.picture} className="w-100 rounded overflow-hidden"></img>
                                                            {!this.state.readonly && (
                                                                <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.setState({ picture: "" }) }}>
                                                                    {"\uf00d"}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <input className="d-none" type="file" id="file_id_card" accept="image/*" onChange={(e) => { this.import_file(e, "id_card") }} />
                                            </div>
                                            <div className="col-12 col-lg-6 col-xl-9 mb-2 px-1">
                                                <div className="row mx-0">
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">รหัสบัตรประชาชน หรือ พาสปอร์ต :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ id_card: e.target.value })
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    document.getElementById("full_name").focus();
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                if (!this.state.modal_detail && !this.state.readonly) {
                                                                    this.GetMemberByIDCard()
                                                                }
                                                            }}
                                                            value={this.state.id_card}
                                                            maxLength={13}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">ชื่อ- นามสกุล :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            id="full_name"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ full_name: e.target.value })
                                                            }}
                                                            value={this.state.full_name}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">วันเกิด :</label><br />
                                                        <DatePickerTH
                                                            className="form-control validate"
                                                            onChange={(e) => {
                                                                this.setState({ birthday: e });
                                                            }}
                                                            value={this.state.birthday}
                                                            disabled={this.state.readonly}
                                                            max={new Date()}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">สัญชาติ :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ nationality: e.target.value })
                                                            }}
                                                            value={this.state.nationality}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">ที่อยู่ตามบัตรประชาชน :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ address: e.target.value })
                                                            }}
                                                            value={this.state.address}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">ตำบล :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ sub_district: e.target.value })
                                                            }}
                                                            value={this.state.sub_district}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">อำเภอ :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ district: e.target.value })
                                                            }}
                                                            value={this.state.district}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">จังหวัด :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ province: e.target.value })
                                                            }}
                                                            value={this.state.province}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">รหัสไปรษณีย์ :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ zip_code: e.target.value })
                                                            }}
                                                            value={this.state.zip_code}
                                                            readOnly
                                                        />
                                                    </div>
                                                    {/* <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">ผู้ออกบัตร :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ card_issue: e.target.value })
                                                            }}
                                                            value={this.state.card_issue}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div> */}
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">วันออกบัตร :</label><br />
                                                        <DatePickerTH
                                                            className="form-control validate"
                                                            onChange={(e) => {
                                                                this.setState({ date_issue: e });
                                                            }}
                                                            value={this.state.date_issue}
                                                            disabled={this.state.readonly}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">วันที่หมดอายุ :</label><br />
                                                        <DatePickerTH
                                                            className="form-control validate"
                                                            onChange={(e) => {
                                                                this.setState({ date_expire: e });
                                                            }}
                                                            value={this.state.date_expire}
                                                            disabled={this.state.readonly}
                                                        />
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">เบอร์โทรศัพท์ :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="tel"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ phone: e.target.value })
                                                            }}
                                                            value={this.state.phone}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">อีเมล :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="email"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ email: e.target.value })
                                                            }}
                                                            value={this.state.email}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">facebook :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ facebook: e.target.value })
                                                            }}
                                                            value={this.state.facebook}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">ไลน์ไอดี :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ id_line: e.target.value })
                                                            }}
                                                            value={this.state.id_line}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-2 px-1">
                                                        <label className="w-100 mb-1">ที่พักอาศัยชั่วคราว :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ temporary_accommodation: e.target.value })
                                                            }}
                                                            value={this.state.temporary_accommodation}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                    <div className="col-12 px-1">
                                                        <div className="card bg-orange p-2">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 text-center">
                                                                    <label className="text-white mb-2">เงินมัดจำคงเหลือ</label><br />
                                                                    <h3 className="text-white"><b>{toFixed(Number(this.state.sk_wallet))}</b></h3>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 text-center">
                                                                    <label className="text-white mb-2">แต้มสะสม</label><br />
                                                                    <h3 className="text-white"><b>{Number(this.state.points)}</b></h3>
                                                                </div>
                                                                <div className="col-12 col-md-4 col-lg-12 col-xl-4 text-center">
                                                                    <label className="text-white mb-2">ข้อมูลการจองรถ</label><br />
                                                                    <button className="btn btn-light w-100" onClick={() => {
                                                                        this.setState({ modal_detail: true })
                                                                    }}>ข้อมูลการจองรถ
                                                                        {this.state.data_booking.length !== 0 && (
                                                                            <small className="text-white hpx-30 px-2 rounded bg-danger ml-1">
                                                                                {this.state.data_booking.length > 99 ? "99+" : this.state.data_booking.length}
                                                                            </small>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ข้อมูลรถ */}
                                <div className="card border-0 mb-3">
                                    <div className="card-body  p-3 pb-1">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0"><b>ข้อมูลรถ</b></h4>
                                            {this.state.selected_bike && this.state.change_car && (
                                                <button className="btn btn-outline-orange px-3"
                                                    onClick={() => { this.setState({ modal_car: true, search_bike: "" }) }}>
                                                    แก้ไข
                                                </button>
                                            )} </div>
                                        <hr />
                                        {!this.state.selected_bike ? (
                                            <div className="w-100 card p-3 pt-1 mb-3">
                                                <div className="d-flex align-items-end position-relative mx-auto" style={{ height: 300, width: 300 }}>
                                                    <img src={EMPTY} className="img-responsive position-absolute" style={{ width: 300, zIndex: 999, opacity: 0.08, objectFit: "contain" }} />
                                                    <div className="w-100 text-center pb-5" style={{ zIndex: 1000 }}>
                                                        <h4>** ยังไม่ได้เลือกรถ **</h4>
                                                    </div>
                                                </div>

                                                {this.state.change_car && (
                                                    <div className="d-flex justify-content-center mb-3">
                                                        <button className="btn btn-orange wpx-200"
                                                            onClick={() => {
                                                                this.setState({
                                                                    modal_car: true,
                                                                    pick_up_date: this.state.pick_up_date ? this.state.pick_up_date : new Date(),
                                                                    pick_up_time: this.state.pick_up_time ? this.state.pick_up_time : format_date(new Date(), "hh:mm"),
                                                                    return_date: this.state.return_date ? this.state.return_date : new Date(new Date().setDate(new Date().getDate() + 1)),
                                                                    return_time: this.state.return_time ? this.state.return_time : format_date(new Date(), "hh:mm"),
                                                                    search_bike: ""
                                                                })
                                                            }}>เลือกรถ</button>
                                                    </div>

                                                )}
                                            </div>
                                        ) : (
                                            <div className="row">
                                                <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3">
                                                    <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-responsive w-100 rounded" style={{ height: "100%", objectFit: "contain" }} />
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                                    <h6><b>รายละเอียดรถ</b></h6>
                                                    <div className="row">
                                                        <div className="col-12 d-flex mb-2">
                                                            <b className="wpx-50">ยี่ห้อ</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">ชื่อรุ่น</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">ทะเบียน</b><label>: {this.state.selected_bike ? this.state.selected_bike.license_plate : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">สี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_color : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">ปี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_year + 543 : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-100">หมายเลขรถ</b><label>: {this.state.selected_bike ? this.state.selected_bike.channel_name : "-"}</label>
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-end mb-2">
                                                            <h4 className="text-orange">{this.state.selected_bike ? this.state.selected_bike.daily_price : "-"}  บาท/วัน</h4>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <h6><b>วันที่รับ / คืนรถ</b></h6>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="w-100">
                                                            <b className="wpx-40">วันที่รับรถ</b>
                                                            <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                            <b className="my-2">สถานที่รับรถ{required}</b>
                                                            <input
                                                                type="text"
                                                                className="form-control w-100 mb-2"
                                                                placeholder="เช่น สนามบินเชียงใหม่"
                                                                onChange={(e) => { this.setState({ pick_up_location: e.target.value }); }}
                                                                value={this.state.pick_up_location}
                                                                readOnly={this.state.readonly} />
                                                        </div>
                                                        <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                            <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                        </div>
                                                        <div className="w-100">
                                                            <b className="wpx-40">วันที่คืนรถ</b>
                                                            <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                            <b className="my-2">สถานที่คืนรถ{required}</b>
                                                            <input
                                                                type="text"
                                                                className="form-control w-100 mb-2"
                                                                placeholder="เช่น สนามบินเชียงใหม่"
                                                                onChange={(e) => { this.setState({ return_location: e.target.value }); }}
                                                                value={this.state.return_location}
                                                                readOnly={this.state.readonly} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card bg-orange w-100 p-2">
                                                        <div className="row">
                                                            <div className="col-4 col-md-2 text-center">
                                                                <label className="text-white">จำนวน (วัน)</label>
                                                                <h3 className="text-white"><b>{this.state.days}</b></h3>
                                                            </div>
                                                            <div className="col-4 col-md-2 text-center">
                                                                <label className="text-white">จำนวน (ชั่วโมง)</label>
                                                                <h3 className="text-white"><b>{this.state.hours}</b></h3>
                                                            </div>
                                                            <div className="col-4 col-md-2 text-center">
                                                                <label className="text-white">จำนวน (นาที)</label>
                                                                <h3 className="text-white"><b>{this.state.minutes}</b></h3>
                                                            </div>
                                                            <div className="col-12 col-md-6 text-center">
                                                                <label className="text-white">ราคา</label>
                                                                <h3 className="text-white"><b>{toFixed(this.state.rental_price)}</b></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* แบบฟอร์มการเช่ารถ */}
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-3">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="mb-0"><b>แบบฟอร์มการเช่ารถ</b></h4>
                                        </div>
                                        <hr />
                                        <div className="row mx-0">
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ประเภทการเช่า */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ประเภทการเช่า{required}:</label><br />
                                                    <Select
                                                        className="form-control select-search bg-white mb-2"
                                                        styles={select_style}
                                                        options={[{ value: "daily", label: "รายวัน" }, { value: "weekly", label: "รายสัปดาห์" }, { value: "monthly", label: "รายเดือน" }]}
                                                        onChange={(e) => {
                                                            this.setState({ rental_type: e });
                                                            // setTimeout(() => {
                                                            //     if (this.state.selected_bike) {
                                                            //         if (this.state.rental_type.value === "daily") {
                                                            //             this.setState({ rental_price: this.state.array_price.daily_price * this.state.days })
                                                            //         } else if (this.state.rental_type.value === "weekly") {
                                                            //             if (this.state.array_price.weekly_price === 0) {
                                                            //                 this.setState({ rental_price: this.state.array_price.daily_price * this.state.days })
                                                            //             } else {
                                                            //                 if (this.state.days >= 7) {
                                                            //                     let price = 0;
                                                            //                     price += Number((this.state.days / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                            //                     price += (this.state.days % 7) * this.state.array_price.daily_price;
                                                            //                     this.setState({ rental_price: price })
                                                            //                 } else {
                                                            //                     this.setState({ rental_price: this.state.array_price.weekly_price })
                                                            //                 }
                                                            //             }
                                                            //         } else if (this.state.rental_type.value === "monthly") {
                                                            //             if (this.state.array_price.monthly_price === 0) {
                                                            //                 this.setState({ rental_price: this.state.array_price.daily_price * this.state.days })
                                                            //             } else {
                                                            //                 if (this.state.days > 30) {
                                                            //                     let price = 0;
                                                            //                     price += Number((this.state.days / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                            //                     price += Number(((this.state.days % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                            //                     price += ((this.state.days % 30) % 7) * this.state.array_price.daily_price;
                                                            //                     this.setState({ rental_price: price })
                                                            //                 } else {
                                                            //                     this.setState({ rental_price: this.state.array_price.monthly_price })
                                                            //                 }
                                                            //             }
                                                            //         }
                                                            //     }
                                                            // }, 10);
                                                            let days = days_between(
                                                                new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
                                                                new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                                this.state.pick_up_time,
                                                                this.state.return_time
                                                            )
                                                            let hours_price = 0;
                                                            let rental_price = 0;

                                                            if (days.day === 0) {
                                                                hours_price = this.state.array_price.daily_price;
                                                            } else {
                                                                if (days.hour === 0 && days.minute >= 0) {
                                                                    //ไม่ต้องหัก 
                                                                } else if (days.hour === 1 && days.minute >= 0) {
                                                                    if (this.state.array_price.daily_price < 400) {
                                                                        hours_price = 50; //หัก 50 บาท
                                                                    } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                        hours_price = 100; //หัก 100 บาท
                                                                    } else if (this.state.array_price.daily_price > 700) {
                                                                        hours_price = 200; //หัก 200 บาท
                                                                    }
                                                                } else if (days.hour === 2 && days.minute >= 0) {
                                                                    if (this.state.array_price.daily_price < 400) {
                                                                        hours_price = 100; //หัก 50 บาท
                                                                    } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                        hours_price = 200; //หัก 100 บาท
                                                                    } else if (this.state.array_price.daily_price > 700) {
                                                                        hours_price = 400; //หัก 200 บาท
                                                                    }
                                                                } else if (days.hour === 3 && days.minute >= 0) {
                                                                    if (this.state.array_price.daily_price < 400) {
                                                                        hours_price = 150; //หัก 50 บาท
                                                                    } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                        hours_price = 300; //หัก 100 บาท
                                                                    } else if (this.state.array_price.daily_price > 700) {
                                                                        hours_price = 600; //หัก 200 บาท
                                                                    }
                                                                } else if (days.hour >= 4) {
                                                                    hours_price = this.state.array_price.daily_price;//หัก daily_price
                                                                }
                                                            }
                                                            setTimeout(() => {
                                                                if (this.state.selected_bike) {
                                                                    if (this.state.rental_type.value === "daily") {
                                                                        rental_price = this.state.array_price.daily_price * this.state.days;
                                                                        rental_price += hours_price;
                                                                    } else if (this.state.rental_type.value === "weekly") {
                                                                        if (this.state.array_price.weekly_price === 0) {
                                                                            rental_price = this.state.array_price.daily_price * this.state.days;
                                                                            rental_price += hours_price;
                                                                        } else {
                                                                            if (this.state.days >= 7) {
                                                                                rental_price += Number((this.state.days / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                rental_price += (this.state.days % 7) * this.state.array_price.daily_price;
                                                                                rental_price += hours_price;
                                                                            } else {
                                                                                rental_price = this.state.array_price.weekly_price;
                                                                            }
                                                                        }
                                                                    } else if (this.state.rental_type.value === "monthly") {
                                                                        if (this.state.array_price.monthly_price === 0) {
                                                                            rental_price = this.state.array_price.daily_price * this.state.days;
                                                                            rental_price += hours_price;
                                                                        } else {
                                                                            if (this.state.days >= 30) {
                                                                                rental_price += Number((this.state.days / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                                                rental_price += Number(((this.state.days % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                rental_price += ((this.state.days % 30) % 7) * this.state.array_price.daily_price;
                                                                                rental_price += hours_price;
                                                                            } else {
                                                                                rental_price = this.state.array_price.monthly_price;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                this.setState({ rental_price: rental_price })
                                                            }, 10);
                                                        }}
                                                        value={this.state.rental_type}
                                                        isDisabled={this.state.readonly}
                                                    ></Select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* เลขไมล์ */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">เลขไมล์{required}:</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return
                                                            }
                                                            this.setState({ mileage: e.target.value })
                                                        }}
                                                        value={this.state.mileage}
                                                        readOnly={this.state.readonly}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ปริมาณน้ำมันรถ (%) */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ปริมาณน้ำมันรถ (%){required}:</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return
                                                            }
                                                            if (Number(e.target.value) > 100) {
                                                                e.target.value = "100";
                                                            }
                                                            this.setState({ oil_volume: e.target.value })
                                                        }}
                                                        value={this.state.oil_volume}
                                                        readOnly={this.state.readonly}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ราคาเช่ารถ */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ราคาเช่ารถ :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return
                                                            }
                                                            this.setState({ rental_price: e.target.value })
                                                        }}
                                                        value={this.state.rental_price}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ประเภทการค้ำประกัน */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ประเภทการค้ำประกัน{required}:</label><br />
                                                    <Select
                                                        className="form-control select-search bg-white mb-2"
                                                        styles={select_style}
                                                        options={[
                                                            { value: "1", label: "เงินประกัน" },
                                                            { value: "2", label: "สำเนาบัตรประชาชน" },
                                                            { value: "3", label: "บัตรประชาชนตัวจริง" },
                                                            { value: "4", label: "บัตรข้าราชการ" },
                                                            { value: "5", label: "บัตรนักศึกษา" },
                                                            { value: "6", label: "พาสปอร์ตตัวจริง" },
                                                            { value: "7", label: "สำเนาพาสปอร์ต" },
                                                            { value: "8", label: "ใบขับขี่" },
                                                            { value: "9", label: "ใบขับขี่ตัวจริง" },
                                                            { value: "10", label: "เงินหยวน" },
                                                            { value: "11", label: "เงินดอลล่า" },
                                                            { value: "12", label: "เงินยูโร" }
                                                        ]}
                                                        onChange={(e) => {
                                                            this.setState({ guarantee_type: e, guarantee_amount: 0, guarantee_money: 0 });
                                                        }}
                                                        value={this.state.guarantee_type}
                                                        isDisabled={this.state.readonly}
                                                    ></Select>
                                                </div>
                                            </div>
                                            {this.state.guarantee_type && (this.state.guarantee_type.value === "10" || this.state.guarantee_type.value === "11" || this.state.guarantee_type.value === "12") && (
                                                <div className="col-12 col-sm-6 col-md-4 px-1">
                                                    {/* จำนวนเงินค้ำประกัน */}
                                                    <div className="w-100 mb-2">
                                                        <label className="w-100 mb-2">จำนวนเงินค้ำประกัน :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                if (!float(e.target.value)) {
                                                                    return
                                                                }
                                                                this.setState({ guarantee_money: e.target.value })
                                                            }}
                                                            value={this.state.guarantee_money}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.guarantee_type && this.state.guarantee_type.value !== "10" && this.state.guarantee_type.value !== "11" && this.state.guarantee_type.value !== "12" && (
                                                <div className="col-12 col-sm-6 col-md-4 px-1">
                                                    {/* จำนวนเงินค้ำประกัน */}
                                                    <div className="w-100 mb-2">
                                                        <label className="w-100 mb-2">จำนวนเงินค้ำประกัน :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                if (!float(e.target.value)) {
                                                                    return
                                                                }
                                                                this.setState({ guarantee_amount: e.target.value })
                                                            }}
                                                            value={this.state.guarantee_amount}
                                                            readOnly={this.state.readonly}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ค่าอุปกรณ์ */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ค่าอุปกรณ์ :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return
                                                            }
                                                            this.setState({ equipment_fee: e.target.value })
                                                        }}
                                                        value={this.state.equipment_fee}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ค่าบริการรับส่ง */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ค่าบริการรับส่ง :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return
                                                            }
                                                            this.setState({ delivery_price: e.target.value })
                                                        }}
                                                        value={this.state.delivery_price}
                                                        readOnly={this.state.readonly}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ค่าอื่น ๆ */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ค่าอื่น ๆ :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return
                                                            }
                                                            this.setState({ other_price: e.target.value })
                                                        }}
                                                        value={this.state.other_price}
                                                        readOnly={this.state.readonly}
                                                    />
                                                </div>
                                            </div>
                                            {this.props.location.query.deposit_price ? (
                                                <div className="col-12 col-sm-6 col-md-4 px-1">
                                                    {/* ยอดจองที่ชำระ */}
                                                    <div className="w-100 mb-2">
                                                        <label className="w-100 mb-2">ยอดจองที่ชำระ :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            value={toFixed(Number(this.props.location.query.deposit_price))}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ส่วนลด */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ส่วนลด :</label><br />
                                                    <input
                                                        className="form-control validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return
                                                            }
                                                            this.setState({ discount_price: e.target.value })
                                                        }}
                                                        value={this.state.discount_price}
                                                        readOnly={this.state.readonly}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 px-1">
                                                {/* ผู้แนะนำ */}
                                                <div className="w-100 mb-2">
                                                    <label className="w-100 mb-2">ผู้แนะนำ :</label><br />
                                                    <Select
                                                        className="form-control select-search bg-white mb-2"
                                                        styles={select_style}
                                                        options={this.state.data_agent}
                                                        onChange={(e) => {
                                                            this.setState({ recommen_id: e });
                                                        }}
                                                        value={this.state.recommen_id}
                                                        isDisabled={this.state.readonly}
                                                    ></Select>
                                                </div>
                                            </div>
                                            <div className="col-12 px-1">
                                                <div className="card bg-orange w-100 py-3 px-3">
                                                    <div className="text-riht d-flex align-items-center justify-content-end">
                                                        <label className="text-white mx-1">ยอดชำระเงินทั้งหมด</label>
                                                        <h3 className="text-white ml-auto mr-1 mb-0"><b>
                                                            {this.props.location.query.deposit_price ?
                                                                toFixed(Number(this.state.rental_price) + Number(this.state.equipment_fee) + Number(this.state.delivery_price) + Number(this.state.guarantee_amount) - Number(this.state.discount_price) + Number(this.state.other_price) - Number(this.props.location.query.deposit_price)) :
                                                                toFixed(Number(this.state.rental_price) + Number(this.state.equipment_fee) + Number(this.state.delivery_price) + Number(this.state.guarantee_amount) - Number(this.state.discount_price) + Number(this.state.other_price))}</b></h3>
                                                        <label className="text-white mx-1">บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* อุปกรณ์รถ */}
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-3">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="mb-0"><b>อุปกรณ์รถ</b></h4>
                                        </div>
                                        <hr />
                                        <div className="w-100 py-3 mb-3">
                                            <div className="row mx-0">
                                                {this.state.data_equipment && this.state.data_equipment.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 col-xxl-6 mb-2 px-1">
                                                        <div className="card bg-white w-100 p-2">
                                                            <div className="d-flex row mx-0 align-items-center w-100">
                                                                <div className="d-flex">
                                                                    <img src={item.picture} className="wpx-90 hpx-90 rounded" />
                                                                    <div>
                                                                        <h5 className="ml-3" style={{ minWidth: 190 }}>{item.equipment_name}</h5>
                                                                        <label className="ml-3 text-orange">ราคา {item.equipment_price} บาท</label>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-auto card bg-contain border-0 p-1">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="d-flex align-items-center justify-content-center mr-3">
                                                                            <h1 className={item.equipment_qty === 0 ? "text-right text-secondary mb-1" : "text-right text-orange mb-1"} style={{ width: 50 }}><b>{item.equipment_qty}</b></h1>
                                                                            <h6 className={item.equipment_qty === 0 ? "text-secondary mb-0 ml-2" : "text-orange mb-0 ml-2"}>ชิ้น</h6>
                                                                        </div>
                                                                        {!this.state.readonly && (
                                                                            <div className="d-flex">
                                                                                <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                    onClick={() => {
                                                                                        let data_equipment = this.state.data_equipment;
                                                                                        data_equipment[index].equipment_qty -= data_equipment[index].equipment_qty === 0 ? 0 : 1;

                                                                                        let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                        for (let data of data_equipment) {
                                                                                            total_qty_equipment += data.equipment_qty;
                                                                                            total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                        }
                                                                                        this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                    }}>-</button>
                                                                                <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                    onClick={() => {
                                                                                        let data_equipment = this.state.data_equipment;
                                                                                        data_equipment[index].equipment_qty += 1;

                                                                                        let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                        for (let data of data_equipment) {
                                                                                            total_qty_equipment += data.equipment_qty;
                                                                                            total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                        }
                                                                                        this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                    }}>+</button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="col-12 px-1">
                                                    <div className="card bg-orange w-100 p-2">
                                                        <div className="row">
                                                            <div className="col-6 text-center">
                                                                <label className="text-white">จำนวน</label>
                                                                <h3 className="text-white"><b>{this.state.total_qty_equipment}</b></h3>
                                                            </div>
                                                            <div className="col-6 text-center">
                                                                <label className="text-white">ราคา</label>
                                                                <h3 className="text-white"><b>{toFixed(this.state.total_price_equipment)}</b></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* สภาพรถ */}
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-3">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="mb-0"><b>สภาพรถ</b></h4>
                                        </div>
                                        <hr />
                                        <div className="w-100 pt-3">
                                            <div className="row mx-0">
                                                <input className="d-none" type="file" id="file_sub" accept="image/*" onChange={(e) => { this.import_file(e, "sub") }} multiple />
                                                <div className="col-12 mr-auto">
                                                    <div className="row">
                                                        {this.state.detail_picture.filter(e => e.length !== 0).map((item, index) => (
                                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                                <div
                                                                    className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                                >
                                                                    <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                                        <img alt="รูปโปรไฟล์" src={item.includes("https://") ? item : "data:image/png;base64," + item} className="w-100 hpx-140 rounded overflow-hidden" style={{ objectFit: "contain" }}></img>
                                                                        {this.state.change_car && (
                                                                            <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.delete_image(item) }}>
                                                                                {"\uf00d"}
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {this.state.detail_picture.filter(e => e.length !== 0).length !== 10 && (
                                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                                <div
                                                                    className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                                    onClick={() => { if (this.state.change_car) { document.getElementById("file_sub").click() } }}
                                                                >
                                                                    <label className="icon text-orange">{"\uf03e"}</label>
                                                                    <label className="text-orange text-12">รูปภาพสภาพรถ</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {this.state.detail_picture.filter((e, index) => e.length === 0 && index !== 9).map((item, index) => (
                                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                                <div className="card w-100 hpx-150 bg-contain">
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ลายมือชื่ิอผู้เช่า */}
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-3">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h4 className="mb-0"><b>ลายมือชื่ิอผู้เช่า</b></h4>
                                            {!this.state.readonly && (
                                                <button className="btn btn-orange" onClick={() => {
                                                    Swal.fire({
                                                        icon: "warning",
                                                        title: "แจ้งเตือน",
                                                        text: "ยืนยันการล้างลายมือชื่ิอผู้เช่าหรือไม่",
                                                        confirmButtonText: "ตกลง",
                                                        cancelButtonText: "ยกเลิก",
                                                        showCancelButton: true,
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            this.setState({ signature: "" })
                                                        }
                                                    });
                                                }}>ล้างลายมือ</button>
                                            )}
                                        </div>
                                        <hr />
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                {this.state.signature === "" ? (
                                                    <div className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center pointer" style={{ height: 400 }} onClick={() => { this.setState({ modal_signature: true }) }}>
                                                        <label className="icon text-orange">{"\uf03e"}</label>
                                                        <label className="text-orange">ลายชื่อชื่อผู้เช่า</label>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                                    >
                                                        <div className="position-relative w-100 p-4">
                                                            <img alt="รูปลายชื่อชื่อผู้เช่า" src={this.state.signature.includes("https://") ? this.state.signature : "data:image/png;base64," + this.state.signature} className="w-100 img-fluid" style={{ height: 400, objectFit: "contain" }}></img>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ปุ่ม */}
                                <div className="w-100 d-flex add">
                                    <div className="w-50 p-1">
                                        <button
                                            className="btn btn-outline-orange w-100"
                                            onClick={() => {
                                                if (this.props.location.query.page === "dashboard") {
                                                    window.location.href = "/admin/dashboard";
                                                } else if (this.props.location.query.page === "rent") {
                                                    window.location.href = "/admin/list-rent";
                                                } else {
                                                    window.location.href = "/admin/manage-rent";
                                                }
                                            }}
                                        >
                                            ยกเลิก
                                        </button>
                                    </div>
                                    <div className="w-50 p-1">
                                        <button
                                            className="btn btn-orange w-100"
                                            onClick={() => {
                                                if (this.state.update) {
                                                    this.Update();
                                                } else {
                                                    this.Create();
                                                }

                                            }}
                                        >
                                            บันทึก
                                        </button>
                                    </div>
                                </div>
                            </div></div>
                    </div>
                </div>

                {/* create or update */}
                <Modal
                    show={this.state.modal_create_user}
                    onHide={() => { this.setState({ modal_create_user: false }) }}
                    size="lg"
                >
                    <Modal.Header> <Modal.Title> <b>เพิ่มสมาชิก</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-3">
                                <label className="w-100">รูปบัตรประชาชน</label>

                                {this.state.profile_picture2 === "" ? (
                                    <div
                                        className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5 mb-3"
                                        style={{ height: "80%" }}
                                        onClick={() => { document.getElementById("file_profile").click() }}
                                    >
                                        <label className="icon text-orange">{"\uf03e"}</label>
                                        <label className="text-orange">อัปโหลดภาพหลักฐาน</label>
                                    </div>
                                ) : (
                                    <div
                                        className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                    >
                                        <div className="position-relative w-100">
                                            <img alt="รูปโปรไฟล์" src={this.state.profile_picture2.includes("https://") ? this.state.profile_picture2 : "data:image/png;base64," + this.state.profile_picture2} className="w-100 rounded overflow-hidden"></img>
                                            <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.setState({ profile_picture2: "" }) }}>
                                                {"\uf00d"}
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <input className="d-none" type="file" id="file_profile" accept="image/*" onChange={(e) => { this.import_file(e, "profile") }} />
                            </div>
                            <div className="col-12 col-md-12 col-lg-9">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* รหัสบัตรประชาชนหรือพาสปอร์ต */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">รหัสบัตรประชาชนหรือพาสปอร์ต{required}:</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                maxLength={13}
                                                onChange={(e) => {
                                                    if (!validateENNumber(e.target.value) && e.target.value !== "") {
                                                        return
                                                    }
                                                    this.setState({ id_card2: e.target.value })
                                                }}
                                                value={this.state.id_card2}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* ชื่อ-นามสกุล */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">ชื่อ-นามสกุล{required}:</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ full_name2: e.target.value })
                                                }}
                                                value={this.state.full_name2}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* วัน /เดือน /ปีเกิด */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">วัน /เดือน /ปีเกิด :</label>
                                            <DatePickerTH
                                                className="form-control"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ birthday2: e });
                                                }}
                                                value={this.state.birthday2}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">สัญชาติ :</label><br />
                                        <input
                                            className="form-control validate"
                                            type="text"
                                            placeholder="กรอกข้อมูล"
                                            onChange={(e) => {
                                                this.setState({ nationality2: e.target.value })
                                            }}
                                            value={this.state.nationality2}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* ที่อยู่ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">ที่อยู่ :</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ address2: e.target.value })
                                                }}
                                                value={this.state.address2}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">จังหวัด : </label>
                                        <Select
                                            className="form-control select-search"
                                            styles={select_style}
                                            options={province.map(item => { { return { value: item.name_th, label: item.name_th, id: item.province_id } } })}
                                            onChange={(e) => {
                                                this.setState({ province2: e, district2: null, sub_district2: null, zip_code2: "" });
                                            }}
                                            value={this.state.province2}
                                        ></Select>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100"> อำเภอ / เขต : </label>
                                        <Select
                                            className={"form-control select-search" + (this.state.province2 ? "" : " bg-contain")}
                                            styles={select_style}
                                            options={district.filter(item => this.state.province2 && this.state.province2.id === item.province_id).map(item => { { return { value: item.name_th, label: item.name_th, id: item.district_id } } })}
                                            onChange={(e) => {
                                                this.setState({ district2: e, sub_district2: null, zip_code2: "" });
                                            }}
                                            value={this.state.district2}
                                            isDisabled={!this.state.province2}
                                        ></Select>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">ตำบล / แขวง : </label>

                                        <Select
                                            className={"form-control select-search" + (this.state.district2 ? "" : " bg-contain")}
                                            styles={select_style}
                                            options={subdistrict.filter(item => this.state.district2 && this.state.district2.id === item.district_id).map(item => { { return { value: item.name_th, label: item.name_th, zip_code: item.zip_code, id: item.sub_district_id } } })}
                                            onChange={(e) => {
                                                this.setState({ sub_district2: e, zip_code2: e.zip_code.toString() });
                                            }}
                                            value={this.state.sub_district2}
                                            isDisabled={!this.state.district2}
                                        ></Select>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">รหัสไปรษณีย์ : </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="รหัสไปรษณีย์"
                                            onChange={(e) => {
                                                this.setState({ zip_code2: e.target.value })
                                            }}
                                            value={this.state.zip_code2}
                                            disabled
                                        />
                                    </div>
                                    {/* <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">ผู้ออกบัตร :</label><br />
                                        <input
                                            className="form-control validate"
                                            type="text"
                                            placeholder="กรอกข้อมูล"
                                            onChange={(e) => {
                                                this.setState({ card_issue2: e.target.value })
                                            }}
                                            value={this.state.card_issue2}
                                        />
                                    </div> */}
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">วันออกบัตร :</label><br />
                                        <DatePickerTH
                                            className="form-control validate"
                                            onChange={(e) => {
                                                this.setState({ date_issue2: e });
                                            }}
                                            value={this.state.date_issue2}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">วันที่หมดอายุ :</label><br />
                                        <DatePickerTH
                                            className="form-control validate"
                                            onChange={(e) => {
                                                this.setState({ date_expire2: e });
                                            }}
                                            value={this.state.date_expire2}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* เบอร์โทรศัพท์ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">เบอร์โทรศัพท์{required}:</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                maxLength={20}
                                                onChange={(e) => {
                                                    // if (!float(e.target.value)) {
                                                    //     return
                                                    // }
                                                    this.setState({ phone2: e.target.value })
                                                }}
                                                value={this.state.phone2}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* อีเมล */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">อีเมล :</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ email2: e.target.value })
                                                }}
                                                value={this.state.email2}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">facebook :</label><br />
                                        <input
                                            className="form-control validate"
                                            type="text"
                                            placeholder="กรอกข้อมูล"
                                            onChange={(e) => {
                                                this.setState({ facebook2: e.target.value })
                                            }}
                                            value={this.state.facebook2}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">ไลน์ไอดี :</label><br />
                                        <input
                                            className="form-control validate"
                                            type="text"
                                            placeholder="กรอกข้อมูล"
                                            onChange={(e) => {
                                                this.setState({ id_line2: e.target.value })
                                            }}
                                            value={this.state.id_line2}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* ชื่อผู้ใช้ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">ชื่อผู้ใช้{required}:</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!validateENNumber(e.target.value) && e.target.value !== "") {
                                                        return
                                                    }
                                                    this.setState({ username2: e.target.value })
                                                }}
                                                value={this.state.username2}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* รหัสผ่าน */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">รหัสผ่าน{required}:</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ password2: e.target.value })
                                                }}
                                                value={this.state.password2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create_user: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.CreateUser();
                                    }}
                                >
                                    เพิ่ม
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* car */}
                <Modal show={this.state.modal_car} onHide={() => {
                    this.setState({ modal_car: false });

                    if (this.state.selected_bike) {
                        let days = days_between(
                            new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
                            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                            this.state.pick_up_time,
                            this.state.return_time
                        )
                        let hours_price = 0;
                        let rental_price = 0;
                        this.setState({
                            modal_car: false,
                            days: days.day,
                            hours: days.hour,
                            minutes: days.minute,
                        });
                        if (days.day === 0) {
                            hours_price = this.state.selected_bike.daily_price;
                        } else {
                            if (days.hour === 0 && days.minute >= 0) {
                                //ไม่ต้องหัก 
                            } else if (days.hour === 1 && days.minute >= 0) {
                                if (this.state.array_price.daily_price < 400) {
                                    hours_price = 50; //หัก 50 บาท
                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                    hours_price = 100; //หัก 100 บาท
                                } else if (this.state.array_price.daily_price > 700) {
                                    hours_price = 200; //หัก 200 บาท
                                }
                            } else if (days.hour === 2 && days.minute >= 0) {
                                if (this.state.array_price.daily_price < 400) {
                                    hours_price = 100; //หัก 50 บาท
                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                    hours_price = 200; //หัก 100 บาท
                                } else if (this.state.array_price.daily_price > 700) {
                                    hours_price = 400; //หัก 200 บาท
                                }
                            } else if (days.hour === 3 && days.minute >= 0) {
                                if (this.state.array_price.daily_price < 400) {
                                    hours_price = 150; //หัก 50 บาท
                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                    hours_price = 300; //หัก 100 บาท
                                } else if (this.state.array_price.daily_price > 700) {
                                    hours_price = 600; //หัก 200 บาท
                                }
                            } else if (days.hour >= 4) {
                                hours_price = this.state.selected_bike.daily_price;//หัก daily_price
                            }
                        }
                        setTimeout(() => {
                            if (this.state.selected_bike) {
                                if (this.state.rental_type.value === "daily") {
                                    rental_price = this.state.array_price.daily_price * this.state.days;
                                    rental_price += hours_price;
                                } else if (this.state.rental_type.value === "weekly") {
                                    if (this.state.array_price.weekly_price === 0) {
                                        rental_price = this.state.array_price.daily_price * this.state.days;
                                        rental_price += hours_price;
                                    } else {
                                        if (this.state.days >= 7) {
                                            rental_price += Number((this.state.days / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                            rental_price += (this.state.days % 7) * this.state.array_price.daily_price;
                                            if (this.state.days % 7 === 0) {
                                                rental_price += hours_price;
                                            }
                                        } else {
                                            rental_price = this.state.array_price.weekly_price;
                                        }
                                    }
                                } else if (this.state.rental_type.value === "monthly") {
                                    if (this.state.array_price.monthly_price === 0) {
                                        rental_price = this.state.array_price.daily_price * this.state.days;
                                        rental_price += hours_price;
                                    } else {
                                        if (this.state.days >= 30) {
                                            rental_price += Number((this.state.days / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                            rental_price += Number(((this.state.days % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                            rental_price += ((this.state.days % 30) % 7) * this.state.array_price.daily_price;
                                            if (this.state.days % 30 === 0) {
                                                rental_price += hours_price;
                                            }
                                        } else {
                                            rental_price = this.state.array_price.monthly_price;
                                        }
                                    }
                                }
                            }
                            this.setState({ rental_price: rental_price })
                        }, 10);
                    }
                }} size="lg">
                    <Modal.Body>
                        <div className="w-100 d-flex justify-content-between mb-2">
                            <h3><b>เลือกรถ</b></h3>
                            <label className="icon text-24 text-danger pointer px-3" onClick={() => {
                                this.setState({ modal_car: false });
                            }}>{"\uf00d"}</label>
                        </div>
                        {/* <hr className="my-2" /> */}
                        <div className="w-100">
                            {/* ค้นหา */}
                            <div className="row bg-orange mb-3">
                                <div className="col-12 p-3 mx-auto">
                                    <div className="card border-0 px-4 py-2 bg-white shadow">
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">วันที่รับรถ{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ pick_up_date: e });
                                                        if (Number(format_date(e, "yyyymmdd")) >= Number(format_date(this.state.return_date, "yyyymmdd"))) {
                                                            this.setState({ return_date: format_date(new Date(format_date(e, "yyyy,mm,dd")).setDate(new Date(format_date(e, "yyyy,mm,dd")).getDate() + 1), "yyyy-mm-dd", "en") });
                                                        }
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.pick_up_date}
                                                    clearable={false}
                                                    min={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                    disabled={this.state.readonly}
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">เวลารับรถ{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => {
                                                        this.setState({ pick_up_time: e.target.value });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.pick_up_time}
                                                    readOnly={this.state.readonly} />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">วันที่คืนรถ{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ return_date: e });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.return_date}
                                                    clearable={false}
                                                    min={new Date(this.state.pick_up_date).setDate(new Date(this.state.pick_up_date).getDate() - 1)}
                                                    disabled={this.state.readonly}
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">เวลาคืนรถ{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => {
                                                        this.setState({ return_time: e.target.value });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.return_time}
                                                    readOnly={this.state.readonly} />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 px-1">
                                                <b className="text-white mb-2">.</b>
                                                <button className="btn btn-orange w-100 mb-2" onClick={() => {
                                                    this.GetBikeAll()
                                                    if (this.state.selected_bike) {
                                                        let days = days_between(
                                                            new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
                                                            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                            this.state.pick_up_time,
                                                            this.state.return_time
                                                        )
                                                        let hours_price = 0;
                                                        let rental_price = 0;
                                                        this.setState({
                                                            modal_car: false,
                                                            days: days.day,
                                                            hours: days.hour,
                                                            minutes: days.minute,
                                                        });
                                                        if (days.day === 0) {
                                                            hours_price = this.state.selected_bike.daily_price;
                                                        } else {
                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                //ไม่ต้องหัก 
                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                if (this.state.array_price.daily_price < 400) {
                                                                    hours_price = 50; //หัก 50 บาท
                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                    hours_price = 100; //หัก 100 บาท
                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                    hours_price = 200; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                if (this.state.array_price.daily_price < 400) {
                                                                    hours_price = 100; //หัก 50 บาท
                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                    hours_price = 200; //หัก 100 บาท
                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                    hours_price = 400; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                if (this.state.array_price.daily_price < 400) {
                                                                    hours_price = 150; //หัก 50 บาท
                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                    hours_price = 300; //หัก 100 บาท
                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                    hours_price = 600; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour >= 4) {
                                                                hours_price = this.state.selected_bike.daily_price;//หัก daily_price
                                                            }
                                                        }
                                                        setTimeout(() => {
                                                            if (this.state.selected_bike) {
                                                                if (this.state.rental_type.value === "daily") {
                                                                    rental_price = this.state.array_price.daily_price * this.state.days;
                                                                    rental_price += hours_price;
                                                                } else if (this.state.rental_type.value === "weekly") {
                                                                    if (this.state.array_price.weekly_price === 0) {
                                                                        rental_price = this.state.array_price.daily_price * this.state.days;
                                                                        rental_price += hours_price;
                                                                    } else {
                                                                        if (this.state.days >= 7) {
                                                                            rental_price += Number((this.state.days / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                            rental_price += (this.state.days % 7) * this.state.array_price.daily_price;
                                                                            if (this.state.days % 7 === 0) {
                                                                                rental_price += hours_price;
                                                                            }
                                                                        } else {
                                                                            rental_price = this.state.array_price.weekly_price;
                                                                        }
                                                                    }
                                                                } else if (this.state.rental_type.value === "monthly") {
                                                                    if (this.state.array_price.monthly_price === 0) {
                                                                        rental_price = this.state.array_price.daily_price * this.state.days;
                                                                        rental_price += hours_price;
                                                                    } else {
                                                                        if (this.state.days >= 30) {
                                                                            rental_price += Number((this.state.days / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                                            rental_price += Number(((this.state.days % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                            rental_price += ((this.state.days % 30) % 7) * this.state.array_price.daily_price;
                                                                            if (this.state.days % 30 === 0) {
                                                                                rental_price += hours_price;
                                                                            }
                                                                        } else {
                                                                            rental_price = this.state.array_price.monthly_price;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            this.setState({ rental_price: rental_price })
                                                        }, 10);
                                                    }
                                                }}>
                                                    ค้นหา
                                                    <label className="icon ml-2">{"\uf002"}</label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* รายการรถ */}
                            <div className="row">
                                <div className="col-12 pb-3 px-4">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <h4><b>ผลการค้นหา: รถว่างทั้งหมด</b></h4>
                                            <label className="text-secondary mb-3">พบรถว่าง {this.state.data_bike.length} คัน</label>
                                        </div>
                                        <div className="wpx-250 pb-2">
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-2"
                                                placeholder="เช่น ยี่ห้อ,รุ่น"
                                                onChange={(e) => { this.setState({ search_bike: e.target.value }); }}
                                                value={this.state.search_bike} />
                                        </div>
                                    </div>
                                    <div className="row overflow-scroll" style={{ maxHeight: "70vh" }}>
                                        {this.state.data_bike &&
                                            this.state.data_bike.filter(item => this.state.search_bike === "" ||
                                                (item.channel_name === this.state.search_bike) ||
                                                item.model_name.toUpperCase().includes(this.state.search_bike.toUpperCase()) ||
                                                item.brand_name.toUpperCase().includes(this.state.search_bike.toUpperCase()) ||
                                                item.license_plate.includes(this.state.search_bike))
                                                .map((item, index) => (
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 mb-3 px-1">
                                                        <div className="card bg-white shadow overflow-hidden border-0 position-relative"
                                                            onClick={() => {
                                                                let days = days_between(
                                                                    new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
                                                                    new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                                    this.state.pick_up_time,
                                                                    this.state.return_time
                                                                )
                                                                let hours_price = 0;
                                                                let rental_price = 0;
                                                                this.setState({
                                                                    modal_car: false,
                                                                    selected_bike: item,
                                                                    days: days.day,
                                                                    hours: days.hour,
                                                                    minutes: days.minute,
                                                                    array_price: {
                                                                        daily_price: item.daily_price,
                                                                        weekly_price: item.weekly_price,
                                                                        monthly_price: item.monthly_price
                                                                    }
                                                                });

                                                                setTimeout(() => {
                                                                    if (days.day === 0) {
                                                                        hours_price = item.daily_price;
                                                                    } else {
                                                                        if (days.hour === 0 && days.minute >= 0) {
                                                                            //ไม่ต้องหัก 
                                                                        } else if (days.hour === 1 && days.minute >= 0) {
                                                                            if (this.state.array_price.daily_price < 400) {
                                                                                hours_price = 50; //หัก 50 บาท
                                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                hours_price = 100; //หัก 100 บาท
                                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                                hours_price = 200; //หัก 200 บาท
                                                                            }
                                                                        } else if (days.hour === 2 && days.minute >= 0) {
                                                                            if (this.state.array_price.daily_price < 400) {
                                                                                hours_price = 100; //หัก 50 บาท
                                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                hours_price = 200; //หัก 100 บาท
                                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                                hours_price = 400; //หัก 200 บาท
                                                                            }
                                                                        } else if (days.hour === 3 && days.minute >= 0) {
                                                                            if (this.state.array_price.daily_price < 400) {
                                                                                hours_price = 150; //หัก 50 บาท
                                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                hours_price = 300; //หัก 100 บาท
                                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                                hours_price = 600; //หัก 200 บาท
                                                                            }
                                                                        } else if (days.hour >= 4) {
                                                                            hours_price = item.daily_price;//หัก daily_price
                                                                        }
                                                                    }
                                                                    if (this.state.selected_bike) {
                                                                        if (this.state.rental_type.value === "daily") {
                                                                            rental_price = this.state.array_price.daily_price * this.state.days;
                                                                            rental_price += hours_price;
                                                                        } else if (this.state.rental_type.value === "weekly") {
                                                                            if (this.state.array_price.weekly_price === 0) {
                                                                                rental_price = this.state.array_price.daily_price * this.state.days;
                                                                                rental_price += hours_price;
                                                                            } else {
                                                                                if (this.state.days >= 7) {
                                                                                    rental_price += Number((this.state.days / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                    rental_price += (this.state.days % 7) * this.state.array_price.daily_price;
                                                                                    if (this.state.days % 7 === 0) {
                                                                                        rental_price += hours_price;
                                                                                    }
                                                                                } else {
                                                                                    rental_price = this.state.array_price.weekly_price;
                                                                                }
                                                                            }
                                                                        } else if (this.state.rental_type.value === "monthly") {
                                                                            if (this.state.array_price.monthly_price === 0) {
                                                                                rental_price = this.state.array_price.daily_price * this.state.days;
                                                                                rental_price += hours_price;
                                                                            } else {
                                                                                if (this.state.days >= 30) {
                                                                                    rental_price += Number((this.state.days / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                                                    rental_price += Number(((this.state.days % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                    rental_price += ((this.state.days % 30) % 7) * this.state.array_price.daily_price;
                                                                                    if (this.state.days % 30 === 0) {
                                                                                        rental_price += hours_price;
                                                                                    }
                                                                                } else {
                                                                                    rental_price = this.state.array_price.monthly_price;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    this.setState({ rental_price: rental_price })
                                                                }, 10);
                                                            }}
                                                        // onMouseOver={() => { this.setState({ product_index: index }) }}
                                                        // onMouseLeave={() => { this.setState({ product_index: -1 }) }}
                                                        >
                                                            <img className="d-none d-sm-block" src={item.title_picture ? item.title_picture : CAR} style={{ height: 240, objectFit: "cover" }} />
                                                            <img className="d-block d-sm-none" src={item.title_picture ? item.title_picture : CAR} style={{ height: 180, objectFit: "cover" }} />
                                                            <div className="p-2">
                                                                <div style={{ height: 23, overflow: "hidden" }}>
                                                                    <h6 className="mb-1 d-block d-sm-none"><b>{item.brand_name}</b> {item.model_name}</h6>
                                                                    <h5 className="mb-1 d-none d-sm-block"><b>{item.brand_name}</b> {item.model_name}</h5>
                                                                </div>
                                                                <hr className="my-2" />
                                                                <div className="d-flex row justify-content-between">
                                                                    <label style={{ minWidth: 165 }}>หมายเลขรถ : {item.channel_name}</label>
                                                                </div>
                                                                <div className="d-flex row justify-content-between">
                                                                    <label style={{ minWidth: 165 }}>ทะเบียน : {item.license_plate}</label>
                                                                    <label>{item.cc_size} cc.</label>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <label>สี : {item.bike_color}</label>
                                                                    <label>ปี : {item.bike_year + 543}</label>
                                                                </div>
                                                                <hr className="my-2" />
                                                                <div className="d-flex row justify-content-between mx-0">
                                                                    <div className="p-0 d-flex align-items-end">
                                                                        <h4 className="mb-0 text-orange"><b>฿{toFixed(item.daily_price)}</b></h4>
                                                                        <small className="text-secondary ml-2">ต่อวัน</small>
                                                                    </div>
                                                                    {/* <div className="p-0 d-flex align-items-end">
                                                                <small className="text-secondary ml-2">คงเหลือ {item.balance_app} คัน</small>
                                                            </div> */}
                                                                </div>
                                                            </div>
                                                            {index === this.state.product_index && (
                                                                <div
                                                                    className="fade-in-product rounded pointer position-absolute d-flex align-items-center justify-content-center"
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        left: 0,
                                                                        top: 0,
                                                                        borderWidth: 4,
                                                                        borderStyle: "solid",
                                                                        borderColor: "#fd7e14"
                                                                    }}>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* ข้อมูลการจองรถ */}
                <Modal
                    show={this.state.modal_detail}
                    onHide={() => { this.setState({ modal_detail: false }) }}
                    size="lg"
                >
                    <Modal.Header> <Modal.Title> <b>ข้อมูลการจองรถ</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                {/* วันที่ */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-50">วันที่ :</label>
                                    <DatePickerTH
                                        className="form-control validate"
                                        onChange={(e) => {
                                            this.setState({ date: e });
                                        }}
                                        value={this.state.date}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                {/* เวลา */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-50">เวลา:</label>
                                    <input
                                        className="form-control validate"
                                        type="time"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ time: e.target.value })
                                        }}
                                        value={this.state.time}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive pb-5">
                            <table className="table table-borderless table-striped">
                                <thead className="bg-dark text-white">
                                    <tr>
                                        <th className="text-center">เลขที่การเช่า</th>
                                        <th className="text-center">วันที่เช่า</th>
                                        <th className="text-center">วันที่คืน</th>
                                        <th className="text-center">รหัสบัตรประชาชน</th>
                                        <th className="text-center">ชื่อ-นามสกุล</th>
                                        <th className="text-center">E-mail</th>
                                        <th className="text-center">ข้อมูลรถ</th>
                                        <th className="text-center">สถานะ</th>
                                        <th className="text-center">ค่าประกัน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!this.state.data_booking || this.state.data_booking.length === 0) && (
                                        <tr> <td colSpan={9} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                    )}
                                    {this.state.data_booking && this.state.data_booking.map((item, index) => (
                                        <tr key={index}>
                                            <td className="text-center">{item.booking_number ? item.booking_number : "-"}</td>
                                            <td className="text-center">{item.pick_up_date ? format_date(item.pick_up_date) : "-"}</td>
                                            <td className="text-center">{item.return_date ? format_date(item.return_date) : "-"}</td>
                                            <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                                            <td className="text-center">{item.id_card ? item.id_card : "-"}</td>
                                            <td className="text-center">{item.email ? item.email : "-"}</td>
                                            <td className="text-center">{item.brand_name || item.model_name ? item.brand_name + " " + item.model_name : "-"}</td>
                                            <td className="text-center">
                                                {status_booking(item.booking_status)}
                                            </td>
                                            <td className="text-center">{item.deposit_pay ? toFixed(item.deposit_pay) : "-"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                {/* <tfoot>
                                    <tr>
                                        <td colSpan={9} className="text-right">
                                            <div className="px-3 d-flex align-items-end justify-content-end">
                                                <label>รวมทั้งสิ้น</label>
                                                <h3 className="mx-2 mb-0"><b>6,400.00</b></h3>
                                                <label>บาท</label>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot> */}
                            </table>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="p-1 mx-auto">
                                <button
                                    className="btn btn-outline-orange px-4"
                                    onClick={() => {
                                        this.setState({ modal_detail: false })
                                    }}
                                >
                                    ปิดหน้าต่าง
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* ลายมือชื่อผู้เช่า */}
                <Modal show={this.state.modal_signature} >
                    <Modal.Header> <Modal.Title> <b>ลายมือชื่อผู้เช่า</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center" >
                            <SignatureCanvas
                                penColor='black'
                                canvasProps={{ height: 500, className: 'w-100 sigCanvas' }}
                                clearOnResize={false}
                                ref={(ref) => { this.sigPad = ref }}
                                onEnd={(e) => { this.trim() }} />
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_signature: false, signature: "" })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_signature: false })
                                    }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
