import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import Select from "react-select";
import Swal from "sweetalert2";
import {
    tokens,
    select_style,
    success_url,
    alert,
    GET,
    POST,
    format_date,
    DatePickerTH,
    getStorage,
    toFixed,
    total,
    days_between,
    status_rent,
    float
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import Resizer from "react-image-file-resizer";
import { Modal } from "react-bootstrap";

let intv = null;
export default class ListBankAccount extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_close: false,

            page: 1,
            loading: false,

            data: null,

            filter_search: "",
            filter_rental_status: "",
            filter_date: "",

            admin_name: "",
            rental_id: "",
            rental_number: "",
            address: "",
            birthday: null,
            district: "",
            email: "",
            full_name: "",
            id_card: "",
            is_active: true,
            member_code: "",
            member_id: "",
            member_status: 1,
            phone: "",
            picture: "",
            province: "",
            sub_district: "",
            username: "",
            verify_status: true,
            zip_code: "",
            picture: "",

            facebook: "",
            id_line: "",
            nationality: "",
            card_issue: "",
            date_issue: "",
            date_expire: "",
            temporary_accommodation: "",

            pick_up_date: new Date(),
            pick_up_time: "",
            pick_up_location: "",
            return_date: new Date(),
            return_time: "",
            return_location: "",

            rental_type: "",
            mileage: 0,
            oil_volume: 0,
            rental_price: 0,
            guarantee_type: 1,
            guarantee_amount: 0,
            equipment_fee: 0,
            discount_price: 0,
            delivery_price: 0,
            other_price: 0,
            recommen_id: "",
            total_price: 0,
            signature: "",
            deposit_price: 0,
            scale_days: 0,
            deposit_pay: 0,
            damage_cost: 0,
            fuel_cost: 0,

            total_price_equipment: 0,
            total_qty_equipment: 0,

            detail_picture: ["", "", "", "", "", "", "", "", "", ""],

            selected_bike: null,

            search_bike: "",
            rental_status: "",

            days: 0,
            point: 0,
            discount: 0,

            overdue_booking: 0,
            datetime_update: "",
            data_detail: null,
            discount_pay: 0,

            time: new Date(),
            remark: "",
            detail_picture_work: ["", "", ""],
            agent_id: "",
            status_bill_agent: false,
            rental_price_pay: true,
            change_date: [],
            event_type: 0,

            income_money: "",

            total_pay_close: 0
        }
    }
    async componentDidMount() {
        await this.GetAll();
        intv = setInterval(() => {
            this.setState({ time: new Date() })
        }, 1000);
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            pick_up_date: this.state.filter_date,
            rental_status: this.state.filter_rental_status ? Number(this.state.filter_rental_status.value) : "",
        }
        let result = await GET(tokens, "v1/TH/cashier/filter-search/page/" + this.state.page, data);
        if (result && result.status) { 
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    GetById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/rental-manage/detail/" + id, null);
        if (result && result.status) {
            let item = result.data.data;
            let detail_picture = item.detail_picture.map(e => { return e.picture });
            for (let i = 0; i < 10; i++) {
                if (item.detail_picture.length <= i) {
                    detail_picture.push("")
                }
            }
            this.setState({
                rental_id: id,
                status_bill_agent: item.status_bill_agent,
                agent_id: item.agent_id,
                rental_number: item.rental_number,
                booking_number: item.booking_number,
                id_card: item.id_card,
                full_name: item.full_name,
                birthday: item.birthday,
                nationality: item.nationality,
                card_issue: item.card_issue,
                date_issue: item.date_issue,
                date_expire: item.date_expire,
                temporary_accommodation: item.temporary_accommodation,
                facebook: item.facebook,
                phone: item.phone,
                email: item.email,
                id_line: item.id_line,
                selected_bike: {
                    brand_name: item.brand_name,
                    model_name: item.model_name,
                    license_plate: item.license_plate,
                    chassis_number: item.chassis_number,
                    bike_color: item.bike_color,
                    cc_size: item.cc_size,
                    bike_year: item.bike_year,
                    title_picture: item.title_picture,
                    daily_price: item.rental_price / days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en"))),
                    bike_channel_name: item.bike_channel_name
                },
                pick_up_date: item.pick_up_date,
                pick_up_time: item.pick_up_time,
                pick_up_location: item.pick_up_location,
                return_date: item.return_date,
                return_time: item.return_time,
                return_location: item.return_location,

                rental_type: item.rental_type === "daily" ? { value: "daily", label: "รายวัน" } :
                    item.rental_type === "weekly" ? { value: "weekly", label: "รายสัปดาห์" } :
                        item.rental_type === "monthly" ? { value: "monthly", label: "รายเดือน" } : null,
                mileage: item.mileage,
                oil_volume: item.oil_volume,
                rental_price: item.rental_price,
                guarantee_type: item.guarantee_type === 1 ? { value: "1", label: "เงินประกัน" } :
                    item.guarantee_type === 2 ? { value: "2", label: "สำเนาบัตรประชาชน" } :
                        item.guarantee_type === 3 ? { value: "3", label: "บัตรประชาชนตัวจริง" } :
                            item.guarantee_type === 4 ? { value: "4", label: "บัตรข้าราชการ" } :
                                item.guarantee_type === 5 ? { value: "5", label: "บัตรนักศึกษา" } :
                                    item.guarantee_type === 6 ? { value: "6", label: "พาสปอร์ตตัวจริง" } :
                                        item.guarantee_type === 7 ? { value: "7", label: "สำเนาพาสปอร์ต" } :
                                            item.guarantee_type === 8 ? { value: "8", label: "ใบขับขี่" } :
                                                item.guarantee_type === 9 ? { value: "9", label: "ใบขับขี่ตัวจริง" } :
                                                    item.guarantee_type === 10 ? { value: "10", label: "เงินหยวน" } :
                                                        item.guarantee_type === 11 ? { value: "11", label: "เงินดอลล่า" } :
                                                            item.guarantee_type === 12 ? { value: "12", label: "เงินยูโร" } : null,
                guarantee_amount: item.guarantee_amount,
                equipment_fee: item.equipment_fee,
                discount_price: item.discount_price,
                delivery_price: item.delivery_price,
                recommen_id: item.recommen_id,
                total_price: item.total_price,
                signature: item.signature,
                equipment_list: item.equipment_list,
                total_price_equipment: item.total_price_equipment,
                overdue_booking: item.overdue_booking,
                total_pay_booking: item.total_pay_booking,
                total_qty_equipment: item.total_qty_equipment,
                detail_picture: detail_picture,
                title_picture: item.title_picture,
                rental_status: item.rental_status,
                other_price: item.other_price ? item.other_price : 0,
                datetime_update: item.datetime_update,

                days: days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en"))),

                array_price: {
                    daily_price: item.daily_price,
                    weekly_price: item.weekly_price,
                    monthly_price: item.monthly_price
                },

                wallet: {
                    point: item.points,
                    sk_wallet: item.sk_wallet
                },
            })
            let late_day = days_between(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")), new Date(format_date(new Date(), "yyyy,mm,dd", "en"))) - 1;
            let refund_cost = 0;
            // ถ้าวันคืนไม่เกิน
            if (item.rental_type === "daily") {
                refund_cost = item.daily_price * late_day;
            } else if (item.rental_type === "weekly") {
                if (item.weekly_price === 0) {
                    refund_cost = item.daily_price * late_day;
                } else {
                    if (late_day >= 7) {
                        let price = 0;
                        price += Number((late_day / 7).toString().split(".")[0]) * item.weekly_price;
                        price += (late_day % 7) * item.daily_price;
                        refund_cost = price;
                    } else {
                        refund_cost = item.weekly_price;
                    }
                }
            } else if (item.rental_type === "monthly") {
                if (item.monthly_price === 0) {
                    refund_cost = item.daily_price * late_day;
                } else {
                    if (late_day > 30) {
                        let price = 0;
                        price += Number((late_day / 30).toString().split(".")[0]) * item.monthly_price;
                        price += Number(((late_day % 30) / 7).toString().split(".")[0]) * item.weekly_price;
                        price += ((late_day % 30) % 7) * item.daily_price;
                        refund_cost = price;
                    } else {
                        refund_cost = item.monthly_price;
                    }
                }
            }
            if (late_day > 0) {
                // คืนเงิน 
                this.setState({ refund_cost: refund_cost });
            } else {
                // เกินกำหนด 
                this.setState({ rental_price_overdue: refund_cost });
            }
        }
        this.setState({ loading: false });
    }
    GetListById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/cashier/detail/" + id, null);
        if (result && result.status) {
            let array = result.data.data.filter(e => e.event_type === 2 && e.admin_remark !== "แคชเชียร์ ยืนยันการชำระเงิน" && e.admin_remark !== "แคชเชียร์ ขยายสัญญาไม่อนุมัติ" && e.admin_remark !== "พนักงาน ขยายสัญญารอเงินมัดจำ" && e.admin_remark !== "พนักงาน ขยายสัญญาไม่อนุมัติ" && e.admin_remark !== "")
            this.setState({ data_detail: array })
            let item = result.data.data;
            item = item.length !== 0 ? item[item.length - 1] : null;
            if (item) {
                if (this.state.rental_status === 1) {
                    if (item.event_type === 1) {
                        this.setState({
                            total_pay: item.total_pay + item.total_pay_transfer + item.total_pay_cradit + this.state.total_pay_booking,
                            payment_date: item.payment_date,
                            payment_time: item.payment_time,
                            service_charge: 0
                        })
                    } else if (item.event_type === 2) {
                        this.setState({
                            rental_price: 0,
                            deposit_price: item.total_pay + item.total_pay_transfer + item.total_pay_cradit + item.spend_wallet + (item.spend_points * 20),
                            payment_date: item.payment_date,
                            payment_time: item.payment_time,
                            guarantee_amount: 0,
                            total_price_equipment: 0,
                            delivery_price: 0,
                            other_price: 0,
                            total_pay_booking: 0,
                            discount_price: 0,
                            service_charge: 0
                        })
                    } else if (item.event_type === 4) {
                        this.setState({
                            rental_price_pay: item.change < 0 ? false : true,
                            rental_price: item.total_price - item.event_return.service_charge,
                            total_pay: item.total_pay + item.total_pay_transfer + item.total_pay_cradit,
                            payment_date: item.payment_date,
                            payment_time: item.payment_time,
                            service_charge: item.event_return.service_charge,

                            guarantee_amount: 0,
                            total_price_equipment: 0,
                            delivery_price: 0,
                            other_price: 0,
                            total_pay_booking: 0,
                            deposit_price: 0,
                            discount_price: 0,
                        })
                    }
                } else if (this.state.rental_status === 6) {
                    this.setState({
                        refund_cost: item.event_return.refund_cost,
                        rental_price_overdue: item.event_return.rental_price_overdue,
                        other_expenses: item.event_return.other_expenses,
                        fuel_cost: item.event_return.fuel_cost,
                        damage_cost: item.event_return.damage_cost,
                        service_charge: item.event_return.service_charge,
                        discount_pay: item.discount
                    })
                } else if (this.state.rental_status === 9) {
                    this.setState({
                        total_pay: item.total_pay + item.total_pay_transfer + item.total_pay_cradit,
                        payment_date: item.payment_date,
                        payment_time: item.payment_time,
                        service_charge: 0
                    })
                }
            }
            this.setState({ change_date: result.data.data.filter(e => e.admin_remark === "พนักงาน เปลี่ยนวันเวลาคืนรถ") })
        }
        this.setState({ loading: false });
        setTimeout(() => {
            document.getElementById("cashier-body").scrollIntoView();
        }, 500);
    }
    GetActive = async (link) => {
        let body = {
            rental_number: this.state.rental_number
        }
        let result = await GET(tokens, "v1/TH/data-active/all", body);
        if (result && result.status) {
            if (result.data.data.length !== 0) {
                if (getStorage("user").full_name === result.data.data[0].admin_name) {
                    this.Active(link);
                } else {
                    Swal.fire({
                        icon: "warning",
                        title: "แจ้งเตือน",
                        text: "\"" + result.data.data[0].admin_name + "\" ใช้งานใบเช่านี้อยู่ ยืนยันการทำงานนี้ต่อหรือไม่",
                        confirmButtonText: "ตกลง",
                        cancelButtonText: "ยกเลิก",
                        showCancelButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.Active(link);
                        }
                    });
                }
            } else {
                this.Active(link);
            }
        }
    }
    Active = async (link) => {
        this.setState({ loading: true });
        let data = {
            user_id: getStorage("user") ? getStorage("user").user_id : "",
            rental_number: this.state.rental_number,
            event_name: "cashier"
        }
        let result = await POST(tokens, "v1/TH/data-active/create", data);
        if (result && result.status) {
            window.location.href = (link)
        }
        this.setState({ loading: false });
    }
    OpenWork = async () => {
        this.setState({ loading: true });
        // let data = {
        //     full_name: getStorage("user") ? getStorage("user").full_name : "",
        //     cost_import: 0,// เงินลิ้นชัก
        //     change: 0,//เงินทอน
        //     deposit_price: 0,//เงินฝาก
        //     rental_price: [0, 0, 0], //ค่าเช่า
        //     guarantee_amount: [0, 0, 0], //ค่าค้ำประกัน
        //     return_guarantee_amount: 0, //คืนค่าค้ำประกัน
        //     equipment_fee: [0, 0, 0], //ค่าอุปกรณ์
        //     delivery_price: [0, 0, 0], //ค่าขนส่ง
        //     fuel_cost: [0, 0, 0], //ค่าน้ำมัน
        //     damage_cost: [0, 0, 0], //ค่าเสียหาย
        //     service_charge: [0, 0, 0], //ค่าบริการรับส่ง
        //     other_expenses: [0, 0, 0], //ค่าอื่นๆ
        //     rental_price_overdue: [0, 0, 0], //ค่าเช่าเกินกำหนด
        //     scale_price: [0, 0, 0], //ค่าขยายสัญญา
        //     discount: 0, //ส่วนลด
        //     point: 0, //ใช้แต้ม
        //     wallet: 0, //ใช้เงินสะสม
        //     agent: [0, 0],  //เบิกค่าแนะนำ
        //     total_price: 0, //ราคาทั้งหมด 
        //     total_pay: [0, 0, 0], //ราคาทั้งหมด
        //     cashier_status: 1,
        //     remark: "",
        //     detail_picture: []
        // }
        // let result = await POST(tokens, "v1/TH/cashier/active-shift", data);
        // if (result && result.status) {
            localStorage.setItem("cashier", this.state.time)
            clearInterval(intv);
            let cashier_summary = getStorage("cashier_summary");
            cashier_summary.income_money = Number(this.state.income_money);
            localStorage.setItem("cashier_summary", JSON.stringify(cashier_summary));
            success_url("/admin/cashier")
        // }
        this.setState({ loading: false });
    }
    CloseWork = async () => {
        this.setState({ loading: true });
        let data = {
            full_name: getStorage("user") ? getStorage("user").full_name : "",
            cost_import: 0,// เงินลิ้นชัก
            change: 0,//เงินทอน
            deposit_price: 0,//เงินฝาก
            rental_price: getStorage("cashier_summary").rental_price, //ค่าเช่า
            guarantee_amount: getStorage("cashier_summary").guarantee_amount, //ค่าค้ำประกัน
            return_guarantee_amount: getStorage("cashier_summary").return_guarantee_amount, //คืนค่าค้ำประกัน
            equipment_fee: getStorage("cashier_summary").equipment_fee, //ค่าอุปกรณ์
            delivery_price: getStorage("cashier_summary").delivery_price, //ค่าขนส่ง
            fuel_cost: getStorage("cashier_summary").fuel_cost, //ค่าน้ำมัน
            damage_cost: getStorage("cashier_summary").damage_cost, //ค่าเสียหาย
            service_charge: getStorage("cashier_summary").service_charge, //ค่าบริการรับส่ง
            other_expenses: getStorage("cashier_summary").other_expenses, //ค่าอื่นๆ
            rental_price_overdue: getStorage("cashier_summary").rental_price_overdue, //ค่าเช่าเกินกำหนด
            scale_price: getStorage("cashier_summary").scale_price, //ค่าขยายสัญญา
            discount: getStorage("cashier_summary").discount, //ส่วนลด
            point: getStorage("cashier_summary").point, //ใช้แต้ม
            wallet: getStorage("cashier_summary").wallet, //ใช้เงินสะสม
            agent: getStorage("cashier_summary").agent, //ค่าแนะนำ
            total_price: Number(total(getStorage("cashier_summary").rental_price) +
                total(getStorage("cashier_summary").guarantee_amount) +
                total(getStorage("cashier_summary").equipment_fee) +
                total(getStorage("cashier_summary").delivery_price) +
                total(getStorage("cashier_summary").fuel_cost) +
                total(getStorage("cashier_summary").damage_cost) +
                total(getStorage("cashier_summary").service_charge) +
                total(getStorage("cashier_summary").other_expenses) +
                total(getStorage("cashier_summary").rental_price_overdue) +
                total(getStorage("cashier_summary").scale_price) +
                getStorage("cashier_summary").point +
                getStorage("cashier_summary").wallet -
                getStorage("cashier_summary").discount -
                total(getStorage("cashier_summary").agent)), //ราคาทั้งหมด
            total_pay: [Number(this.state.total_pay_close), getStorage("cashier_summary").total_pay[1], getStorage("cashier_summary").total_pay[2]], //ราคาทั้งหมด
            cashier_status: 2,
            remark: this.state.remark,
            detail_picture: this.state.detail_picture_work.filter(e => e !== ""),
            datetime_create: format_date(getStorage("cashier"), "yyyy-mm-dd hh:mm","en"),
            datetime_update: format_date(new Date(),  "yyyy-mm-dd hh:mm","en"),
        }
        let result = await POST(tokens, "v1/TH/cashier/active-shift", data);
        if (result && result.status) {
            localStorage.removeItem("cashier")
            localStorage.removeItem("cashier_summary")
            clearInterval(intv);
            success_url("/admin/cashier")
        }
        this.setState({ loading: false });
    }

    import_file = (e) => {
        if (this.state.detail_picture_work.filter(item => item.length !== 0).length + e.target.files.length > 3) {
            alert("warning", "แจ้งเตือน", "สามารถอัพโหลดรูปได้สูงสุด 3 รูป");
            return;
        }
        if (e.target.files.length === 1) {
            this.changePhoto(e.target.files[0])
            document.getElementById("file").value = "";
            document.getElementById("file").file = null;
        } else {
            this.multiple_file(0, e.target.files.length, e.target.files);
        }
    }
    multiple_file = async (index, max, file) => {
        await this.changePhoto(file[index]);
        if (index + 1 < max) {
            setTimeout(() => {
                this.multiple_file(index + 1, max, file);
            }, 50);
        }
    }
    changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => { }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    let detail_picture_work = this.state.detail_picture_work;
                    let index = detail_picture_work.findIndex(item => item === "");
                    if (index !== -1) {
                        detail_picture_work[index] = uri.split(",")[1];
                    }
                    this.setState({
                        detail_picture_work: detail_picture_work
                    })
                },
                "base64"
            );
        });
    }
    delete_image = (picture) => {
        let data = this.state.detail_picture_work;
        let index = data.findIndex(item => item === picture);
        data[index] = "";
        let array = [];
        for (let item of data) {
            if (item !== "") {
                array.push(item);
            }
        }
        array = [...array, "", "", ""];
        array = array.filter((item, index) => index < 3);
        setTimeout(() => {
            this.setState({ detail_picture_work: data });
        }, 100);
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_close: false,

            page: 1,
            loading: false
        })
    }
    cal_date = () => {
        let date = days_between(format_date(getStorage("cashier"), "yyyy-mm-dd", "en"), format_date(new Date(), "yyyy-mm-dd", "en"), format_date(getStorage("cashier"), "hh:mm:ss"), format_date(new Date(), "hh:mm:ss"))
        return (date.day + " วัน " + date.hour + " ชั่วโมง " + date.minute + " นาที " + date.second + " วินาที");
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="แคชเชียร์" />
                {/* BODY */}
                {!getStorage("cashier") && (
                    <div className="body bg-light min-vh-100">
                        <div className="p-3">
                            {/* หัวข้อ และ ปุ่มเพิ่ม */}
                            <div className="d-flex row mx-0 align-items-center justify-content-between test">
                                <h4 className="mb-3">
                                    <b>แคชเชียร์</b>
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto">
                                    <div className="card border-0 shadow p-3 mt-5">
                                        <h4 className="mb-4"><b>พนักงาน : {getStorage("user") ? getStorage("user").full_name : "-"}</b></h4>
                                        <div className="d-flex justify-content-between mb-3">
                                            <b>วันเวลาที่เริ่มกะ</b>
                                            <label>{format_date(this.state.time, "dd mmmm yyyy hh:mm:ss")} น.</label>
                                        </div>
                                        <div className="d-flex w-100 align-items-center">
                                            <label className="wpx-100 mb-2">จำนวนเงินเข้า</label>
                                            <input
                                                type="text"
                                                className="form-control bg-white w-100 mb-2 mx-2 text-right"
                                                placeholder="จำนวนเงินเข้า"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return
                                                    }
                                                    this.setState({ income_money: e.target.value });
                                                }}
                                                value={this.state.income_money}
                                            />
                                            <label className="mb-2">บาท</label>
                                        </div>
                                        <button type="button" className="btn btn-orange px-4 mx-1" onClick={() => { this.OpenWork(); }}>
                                            เปิดกะ
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {getStorage("cashier") && (
                    <div className="body bg-light min-vh-100">
                        <div className="p-3">
                            {/* หัวข้อ และ ปุ่มเพิ่ม */}
                            <div className="d-flex row mx-0 align-items-center justify-content-between test">
                                <h4 className="mb-3">
                                    <b>แคชเชียร์</b>
                                </h4>
                                <div className="d-flex">
                                    <button type="button" className="btn btn-danger px-4 mx-1 mb-3" onClick={() => { this.setState({ modal_close: true, total_pay_close: Number((getStorage("cashier_summary").total_pay[0])) }) }}>
                                        ปิดกะ
                                    </button>
                                </div>
                            </div>
                            <div className="card  mb-3 px-3 pt-3 pb-1 view border-0">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    <Select
                                        className="form-control select-search bg-white wpx-200 mx-1 mb-2"
                                        styles={select_style}
                                        options={[
                                            { value: "1", label: "รอยืนยันการชำระ" },
                                            { value: "2", label: "รอชำระ" },
                                            { value: "3", label: "อยู่ระหว่างเช่า" },
                                            { value: "4", label: "เกินกำหนด" },
                                            { value: "5", label: "รอตรวจสอบขยายสัญญา" },
                                            { value: "6", label: "รอยืนยันการชำระ (คืนรถ)" },
                                            { value: "7", label: "ขยายสัญญาค้างจ่าย" },
                                            { value: "8", label: "รอคืนรถค้างจ่าย" },
                                            { value: "9", label: "รอตรวจสอบเปลี่ยนแปลงข้อมูล" },
                                            { value: "10", label: "คืนรถเรียบร้อย" },
                                            { value: "12", label: "รอมัดจำขยายสัญญา" },
                                        ]}
                                        onChange={(e) => {
                                            this.setState({ filter_rental_status: e, page: 1 });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_rental_status}
                                        isClearable
                                    ></Select>
                                    <div className="wpx-200 mx-1 mb-2 px-0">
                                        <DatePickerTH
                                            className="form-control bg-white"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_date: e, page: 1 });
                                            }}
                                            onBlur={() => {
                                                this.GetAll();
                                            }}
                                            value={this.state.filter_date}
                                        />
                                    </div>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1, rental_number: "" });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_rental_status: "",
                                                filter_date: "",
                                                rental_number: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className={this.state.rental_number === "" ? "col-12 mb-3" : "col-12 col-lg-7 mb-3"}>
                                    <div className="card border-0 overflow-hidden pb-3">
                                        <div className="card-body p-0">
                                            {/* ตาราง */}
                                            <div className="table-responsive">
                                                <table className="table table-borderless table-striped">
                                                    <thead className="bg-orange text-white">
                                                        <tr>
                                                            <th className="text-center py-3">เลขที่การเช่า</th>
                                                            <th className="text-center py-3">วันที่เช่า</th>
                                                            <th className="text-center py-3">ชื่อผู้เช่า</th>
                                                            <th className="text-center py-3">ข้อมูลรถ</th>
                                                            <th className="text-center py-3">สถานะ</th>
                                                            <th className="text-center py-3">จัดการ</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(!this.state.data || this.state.data.length === 0) && (
                                                            <tr> <td colSpan={5} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                                        )}
                                                        {this.state.data && this.state.data.data.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="text-center">{item.rental_number ? item.rental_number : "-"}</td>
                                                                <td className="text-center">{item.pick_up_date ? format_date(item.pick_up_date) + " " + item.pick_up_time : "-"}</td>
                                                                <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                                                                <td className="text-center">{item.bike_channel_name ? item.bike_channel_name : ""} {item.license_plate ? item.license_plate : ""} {item.brand_name ? item.brand_name : ""} {item.model_name ? item.model_name : ""}</td>
                                                                <td className="text-center">{status_rent(item.rental_status, item.event_type)}</td>
                                                                <td className="text-center">
                                                                    <div className="d-flex justify-content-center">
                                                                        <label className="wpx-30 hpx-30 bg-primary-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1"
                                                                            onClick={async () => {
                                                                                this.setState({ rental_id: item.rental_id, rental_number: item.rental_number, event_type: item.event_type });
                                                                                await this.GetById(item.rental_id);
                                                                                // if (item.rental_status === 1 || item.rental_status === 6 || item.rental_status === 7 || item.rental_status === 9) {
                                                                                await this.GetListById(item.rental_id);
                                                                                // }
                                                                            }}
                                                                        >
                                                                            <label
                                                                                className="icon mx-2 text-primary pointer"
                                                                            > {"\uf543"} </label>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* pagination */}
                                        {this.state.data && this.state.data.data.length !== 0 && (
                                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {this.state.rental_number && (
                                    <div className="col-12 col-lg-5 mb-3" id="cashier-body">
                                        {/* รอยืนยันการชำระ */}
                                        {this.state.rental_status === 1 && (
                                            <div className="card border-0">
                                                <div className="card-header bg-dark">
                                                    <div className="d-flex">
                                                        <h4 className="text-white mb-0 mr-2"><b>{this.state.rental_number}</b></h4>
                                                        {status_rent(this.state.rental_status, this.state.event_type)}
                                                    </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    {/* ตาราง */}
                                                    <div className="table-responsive mb-3">
                                                        <table className="table table-borderless table-striped">
                                                            <thead className="bg-orange text-white">
                                                                <tr>
                                                                    <th className="text-center">รายการ</th>
                                                                    <th className="text-center">วันที่ชำระ</th>
                                                                    <th className="text-center">สถานะ</th>
                                                                    <th className="text-center">จำนวนเงิน</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-left">ค่าเช่ารถ</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center">{this.state.rental_price_pay || this.state.rental_price === 0 ? (<span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span>) : (<span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span>)}</td>
                                                                    <td className="text-center">{toFixed(this.state.rental_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">เงินค้ำประกัน</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.guarantee_amount)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าอุปกรณ์</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.total_price_equipment)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าขนส่ง</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.delivery_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าอื่น ๆ</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.other_price)}</td>
                                                                </tr>
                                                                {this.state.total_pay_booking !== 0 && (
                                                                    <tr>
                                                                        <td className="text-left">ยอดจองชำระแล้ว</td>
                                                                        <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                        <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                        <td className="text-center">{toFixed(this.state.total_pay_booking)}</td>
                                                                    </tr>
                                                                )}
                                                                {this.state.deposit_price ? (
                                                                    <tr>
                                                                        <td className="text-left">ค่าขยายสัญญา {this.state.scale_days ? " ( " + this.state.scale_days + " วัน )" : ""}</td>
                                                                        <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                        <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                        <td className="text-center">{toFixed(this.state.deposit_price)}</td>
                                                                    </tr>
                                                                ) : null}
                                                                {this.state.service_charge ? (
                                                                    <tr>
                                                                        <td className="text-left">ค่าบริการรับส่ง</td>
                                                                        <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                        <td className="text-center">{this.state.rental_price_pay || this.state.service_charge === 0 ? (<span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span>) : (<span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span>)}</td>
                                                                        <td className="text-center">{toFixed(this.state.service_charge)}</td>
                                                                    </tr>
                                                                ) : null}
                                                                <tr>
                                                                    <td className="text-left">ส่วนลด</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"></td>
                                                                    <td className="text-center">{toFixed(this.state.discount_price)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="card-footer bg-orange px-3">
                                                    <div className="row">
                                                        <div className="col-6 px-1">
                                                            <div className="card bg-white p-2">
                                                                <div className="text-center">
                                                                    <label className="mb-2">ชำระแล้ว</label>
                                                                    <h2 className="mx-2 mb-0 text-success">
                                                                        {toFixed((this.state.rental_price_pay ? this.state.rental_price + this.state.service_charge : 0) + this.state.guarantee_amount + this.state.total_price_equipment + this.state.delivery_price + this.state.other_price - this.state.discount_price + this.state.deposit_price)}
                                                                    </h2>
                                                                    <label>บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-1">
                                                            <div className="card bg-white p-2">
                                                                <div className="text-center">
                                                                    <label className="mb-2">ค้างชำระ</label>
                                                                    <h2 className="mx-2 mb-0">{toFixed(!this.state.rental_price_pay ? this.state.rental_price + this.state.service_charge : 0)}
                                                                    </h2>
                                                                    <label>บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* รอชำระ */}
                                        {this.state.rental_status === 2 && (
                                            <div className="card border-0">
                                                <div className="card-header bg-dark">
                                                    <div className="d-flex">
                                                        <h4 className="text-white mb-0 mr-2"><b>{this.state.rental_number}</b></h4>
                                                        {status_rent(this.state.rental_status)}
                                                    </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    {/* ตาราง */}
                                                    <div className="table-responsive mb-3">
                                                        <table className="table table-borderless table-striped">
                                                            <thead className="bg-orange text-white">
                                                                <tr>
                                                                    <th className="text-center">รายการ</th>
                                                                    <th className="text-center">วันที่ชำระ</th>
                                                                    <th className="text-center">สถานะ</th>
                                                                    <th className="text-center">จำนวนเงิน</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-left">ค่าเช่ารถ</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                                    <td className="text-center">{toFixed(this.state.rental_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">เงินค้ำประกัน</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                                    <td className="text-center">{toFixed(this.state.guarantee_amount)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าอุปกรณ์</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                                    <td className="text-center">{toFixed(this.state.total_price_equipment)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าขนส่ง</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                                    <td className="text-center">{toFixed(this.state.delivery_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าอื่น ๆ</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                                    <td className="text-center">{toFixed(this.state.other_price)}</td>
                                                                </tr>
                                                                {this.state.total_pay_booking !== 0 && (
                                                                    <tr>
                                                                        <td className="text-left">ยอดจองชำระแล้ว</td>
                                                                        <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                        <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                        <td className="text-center">{toFixed(this.state.total_pay_booking)}</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td className="text-left">ส่วนลด</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"></td>
                                                                    <td className="text-center">{toFixed(this.state.discount_price)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="card-footer bg-orange px-3">
                                                    <div className="row">
                                                        <div className="col-6 px-1">
                                                            <div className="card bg-white p-2">
                                                                <div className="text-center">
                                                                    <label className="mb-2">ชำระแล้ว</label>
                                                                    <h2 className="mx-2 mb-0 text-success">
                                                                        {toFixed(this.state.total_pay_booking)}
                                                                    </h2>
                                                                    <label>บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-1">
                                                            <div className="card bg-white p-2">
                                                                <div className="text-center">
                                                                    <label className="mb-2">ค้างชำระ</label>
                                                                    <h2 className="mx-2 mb-0"> {toFixed(this.state.total_price)}
                                                                    </h2>
                                                                    <label>บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* รอยืนยันการชำระ (คืนรถ) */}
                                        {this.state.rental_status === 6 && (
                                            <div className="card border-0">
                                                <div className="card-header bg-dark">
                                                    <div className="d-flex">
                                                        <h4 className="text-white mb-0 mr-2"><b>{this.state.rental_number}</b></h4>
                                                        {status_rent(this.state.rental_status)}
                                                    </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    {/* ตาราง */}
                                                    <div className="table-responsive mb-3">
                                                        <table className="table table-borderless table-striped">
                                                            <thead className="bg-orange text-white">
                                                                <tr>
                                                                    <th className="text-center">รายการ</th>
                                                                    <th className="text-center">วันที่ชำระ</th>
                                                                    <th className="text-center">สถานะ</th>
                                                                    <th className="text-center">จำนวนเงิน</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-left">ค่าเช่ารถ</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.rental_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">เงินค้ำประกัน</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.guarantee_amount)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าเช่าเกินกำหนด</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.rental_price_overdue)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าน้ำมัน</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.fuel_cost)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าเสียหาย</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.damage_cost)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าบริการรับส่ง</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.service_charge)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าอื่น ๆ</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.other_expenses)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่ามัดจำ</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{this.state.data_detail ? toFixed(total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price")) : "0.00"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ส่วนลด</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"></td>
                                                                    <td className="text-center">{toFixed(this.state.discount_pay)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ยอดเงินคืนก่อนกำหนด</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"></td>
                                                                    <td className="text-center">{toFixed(this.state.refund_cost)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="card-footer bg-orange px-3">
                                                    <div className="row">
                                                        <div className="col-6 px-1">
                                                            <div className="card bg-white p-2">
                                                                <div className="text-center">
                                                                    <label className="mb-2">ชำระแล้ว</label>
                                                                    <h2 className="mx-2 mb-0 text-success">
                                                                        {
                                                                            (
                                                                                Number(this.state.fuel_cost) +
                                                                                Number(this.state.damage_cost) +
                                                                                Number(this.state.service_charge) +
                                                                                Number(this.state.other_expenses) -
                                                                                Number(this.state.discount_pay) +
                                                                                Number(this.state.rental_price_overdue) +
                                                                                (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                            ) < 0 ? "0.00" :
                                                                                toFixed(
                                                                                    Number(this.state.fuel_cost) +
                                                                                    Number(this.state.damage_cost) +
                                                                                    Number(this.state.service_charge) +
                                                                                    Number(this.state.other_expenses) -
                                                                                    Number(this.state.discount_pay) +
                                                                                    Number(this.state.rental_price_overdue) +
                                                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                                )
                                                                        }
                                                                    </h2>
                                                                    <label>บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-1">
                                                            <div className="card bg-white p-2">
                                                                <div className="text-center">
                                                                    <label className="mb-2">ค้างชำระ</label>
                                                                    <h2 className="mx-2 mb-0"> 0.00
                                                                    </h2>
                                                                    <label>บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* รอตรวจสอบเปลี่ยนแปลงข้อมูล */}
                                        {this.state.rental_status === 9 && (
                                            <div className="card border-0">
                                                <div className="card-header bg-dark">
                                                    <div className="d-flex">
                                                        <h4 className="text-white mb-0 mr-2"><b>{this.state.rental_number}</b></h4>
                                                        {status_rent(this.state.rental_status)}
                                                    </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    {/* ตาราง */}
                                                    <div className="table-responsive mb-3">
                                                        <table className="table table-borderless table-striped">
                                                            <thead className="bg-orange text-white">
                                                                <tr>
                                                                    <th className="text-center">รายการ</th>
                                                                    <th className="text-center">วันที่ชำระ</th>
                                                                    <th className="text-center">สถานะ</th>
                                                                    <th className="text-center">จำนวนเงิน</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-left">ค่าเช่ารถ</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.rental_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">ค่าบริการรับส่ง</td>
                                                                    <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                    <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                    <td className="text-center">{toFixed(this.state.service_charge)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="card-footer bg-orange px-3">
                                                    <div className="row">
                                                        <div className="col-6 px-1">
                                                            <div className="card bg-white p-2">
                                                                <div className="text-center">
                                                                    <label className="mb-2">ชำระแล้ว</label>
                                                                    <h2 className="mx-2 mb-0 text-success">
                                                                        {toFixed(this.state.rental_price + this.state.service_charge)}
                                                                    </h2>
                                                                    <label>บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-1">
                                                            <div className="card bg-white p-2">
                                                                <div className="text-center">
                                                                    <label className="mb-2">ค้างชำระ</label>
                                                                    <h2 className="mx-2 mb-0">0.00
                                                                    </h2>
                                                                    <label>บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="mb-3"></div>
                                        {this.state.rental_status === 3 || this.state.rental_status === 4 || this.state.rental_status === 5 || this.state.rental_status === 7 || this.state.rental_status === 8 ? (
                                            <div className="w-100">
                                                {this.state.change_date.length === 0 && (
                                                    <button
                                                        className="btn btn-orange w-100 mb-3"
                                                        onClick={() => { this.GetActive("/admin/cashier-detail?id=" + this.state.rental_id + "&type=scale") }}>ขยายสัญญา</button>
                                                )}
                                                <button
                                                    className="btn btn-dark w-100 mb-3"
                                                    onClick={() => { this.GetActive("/admin/cashier-detail?id=" + this.state.rental_id + "&type=return") }}>คืนรถ</button>
                                            </div>
                                        ) : this.state.rental_status === 1 || this.state.rental_status === 2 || this.state.rental_status === 6 ? (
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-dark w-100 mb-3"
                                                    onClick={() => { this.GetActive("/admin/cashier-detail?id=" + this.state.rental_id) }}>สรุปการชำระ</button>
                                            </div>
                                        ) : this.state.rental_status === 9 ? (
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-dark w-100 mb-3"
                                                    onClick={() => { this.GetActive("/admin/cashier-detail?id=" + this.state.rental_id) }}>ตรวจสอบการเปลี่ยนแปลง</button>
                                            </div>
                                        ) : (
                                            <div className="w-100">
                                            </div>
                                        )}
                                        {(this.state.rental_status !== 1 && this.state.rental_status !== 2) && this.state.agent_id && (
                                            <div className="position-relative">
                                                <button
                                                    className={this.state.status_bill_agent ? "btn btn-secondary w-100 mb-3" : "btn btn-warning w-100 mb-3"}
                                                    onClick={() => { this.GetActive("/admin/cashier-detail?id=" + this.state.rental_id + "&type=agent") }}>เบิกค่าแนะนำ</button>
                                                {this.state.status_bill_agent && (
                                                    <span className="status-rent-10 rounded px-2 py-1 hpx-30 text-white position-absolute" style={{ top: -4, right: -4 }}>เบิกค่าแนะนำแล้ว</span>
                                                )}
                                            </div>
                                        )}
                                        <div className="w-100">
                                            <button
                                                className="btn btn-primary w-100 mb-3"
                                                onClick={() => { window.open("/admin/manage-rent?id=" + this.state.rental_id + "&type=detail&page=rent", "_blank") }}>รายละเอียด</button>
                                            <button
                                                className="btn btn-success w-100 mb-3"
                                                onClick={() => { window.open("/download-rent?id=" + this.state.rental_id, "_blank") }}>พิมพ์สัญญา</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {/* MODAL */}
                {/* ปิดกะ */}
                <Modal show={this.state.modal_close} onHide={() => { this.setState({ modal_close: false }) }}>
                    <Modal.Header> <Modal.Title> <b>ปิดกะ</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex justify-content-between mb-3">
                            <b>พนักงาน</b>
                            <label>{getStorage("user") ? getStorage("user").full_name : "-"}</label>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                            <b>วันเวลาที่เริ่มกะ</b>
                            <label>{getStorage("cashier") ? format_date(getStorage("cashier"), "dd mmmm yyyy hh:mm:ss") : ""} น.</label>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                            <b>ระยะเวลากะ</b>
                            <label>{this.cal_date()}</label>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                            <b>จำนวนเงินเข้า</b>
                            <label>{toFixed(getStorage("cashier_summary").income_money)} บาท</label>
                        </div>
                        <hr />
                        <table className="table table-borderless table-striped">
                            <thead className="bg-orange text-white">
                                <tr>
                                    <th className="text-center py-3">รายการ</th>
                                    <th className="text-center py-3">เงินสด</th>
                                    <th className="text-center py-3">เงินโอน</th>
                                    <th className="text-center py-3">บัตรเครดิต</th>
                                    <th className="text-center py-3">รวม</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-left">1.ค่าเช่า</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").rental_price))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">2.ค่าค้ำประกัน</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").guarantee_amount))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">3.ค่าอุปกรณ์</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").equipment_fee))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">4.ค่าขนส่ง</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").delivery_price))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">5.ค่าน้ำมัน</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").fuel_cost))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">6.ค่าเสียหาย</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").damage_cost))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">7.ค่าบริการรับส่ง</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").service_charge))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">8.ค่าอื่นๆ</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").other_expenses))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">9.ค่าเช่าเกินกำหนด</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").rental_price_overdue))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">10.ค่าขยายสัญญา</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").scale_price))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">11.ส่วนลด</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(getStorage("cashier_summary").discount)}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">12.ส่วนลดจากแต้ม</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(getStorage("cashier_summary").point)}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">13.ส่วนลดจากเงินสะสม</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(getStorage("cashier_summary").wallet)}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">14.เบิกค่าแนะนำ</td>
                                    <td className="text-center">{toFixed(getStorage("cashier_summary").agent[0])}</td>
                                    <td className="text-center">{toFixed(getStorage("cashier_summary").agent[1])}</td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(total(getStorage("cashier_summary").agent))}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">15.คืนค่าค้ำประกัน</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{toFixed(getStorage("cashier_summary").return_guarantee_amount)}</td>
                                </tr>
                                <tr>
                                    <td className="text-left">รวมทั้งสิ้น</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">
                                        <h3><b>{toFixed(
                                            (total(getStorage("cashier_summary").rental_price)+
                                            total(getStorage("cashier_summary").guarantee_amount)+
                                            total(getStorage("cashier_summary").equipment_fee)+
                                            total(getStorage("cashier_summary").delivery_price)+
                                            total(getStorage("cashier_summary").fuel_cost)+
                                            total(getStorage("cashier_summary").damage_cost)+
                                            total(getStorage("cashier_summary").service_charge)+
                                            total(getStorage("cashier_summary").other_expenses)+
                                            total(getStorage("cashier_summary").rental_price_overdue)+
                                            total(getStorage("cashier_summary").scale_price))-
                                            (getStorage("cashier_summary").discount+
                                            getStorage("cashier_summary").point+
                                            getStorage("cashier_summary").wallet+
                                            total(getStorage("cashier_summary").agent)) 
                                        )
                                        }
                                        </b></h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left">ยอดเงินรวมทั้งหมด</td>
                                    <td className="text-center">
                                        {toFixed(getStorage("cashier_summary").total_pay[0])}
                                    </td>
                                    <td className="text-center">
                                        {toFixed(getStorage("cashier_summary").total_pay[1])}
                                    </td>
                                    <td className="text-center">
                                        {toFixed(getStorage("cashier_summary").total_pay[2])}
                                    </td>
                                    <td className="text-center">
                                        {toFixed(
                                            getStorage("cashier_summary").total_pay[0] +
                                            getStorage("cashier_summary").total_pay[1] +
                                            getStorage("cashier_summary").total_pay[2])
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="w-100 my-2">
                            <label className="w-100 mb-1">จำนวนเงินจริงในลิ้นชัก :</label><br />
                            <input
                                type="text"
                                className="form-control validate"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    if (!float(e.target.value)) {
                                        return
                                    }
                                    this.setState({ total_pay_close: e.target.value })
                                }}
                                value={this.state.total_pay_close}
                            />
                        </div>
                        <div className="w-100 my-2">
                            <label className="w-100 mb-1">หมายเหตุ :</label><br />
                            <textarea
                                className="form-control validate"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ remark: e.target.value })
                                }}
                                value={this.state.remark}
                            ></textarea>
                        </div>
                        <div className="w-100 mb-2">
                            <label className="w-100 mb-1">รูปภาพแนบ :</label><br />
                            <div className="w-100 card pt-3">
                                <div className="row mx-0">
                                    <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e) }} multiple />
                                    <div className="col-12 mr-auto">
                                        <div className="row">
                                            {this.state.detail_picture_work.filter(e => e.length !== 0).map((item, index) => (
                                                <div className="col-6 col-sm-4 col-md-4 col-lg-4 mb-2 px-1">
                                                    <div
                                                        className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                    >
                                                        <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                            <img alt="รูปโปรไฟล์" src={item.includes("https://") ? item : "data:image/png;base64," + item} className="w-100 hpx-140 rounded overflow-hidden" style={{ objectFit: "contain" }}></img>
                                                            <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.delete_image(item) }}>
                                                                {"\uf00d"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {this.state.detail_picture_work.filter(e => e.length !== 0).length !== 3 && (
                                                <div className="col-6 col-sm-4 col-md-4 col-lg-4 mb-2 px-1">
                                                    <div
                                                        className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                        onClick={() => { document.getElementById("file").click() }}
                                                    >
                                                        <label className="icon text-orange">{"\uf03e"}</label>
                                                        <label className="text-orange text-12">รูปภาพสภาพรถ</label>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.detail_picture_work.filter((e, index) => e.length === 0 && index !== 2).map((item, index) => (
                                                <div className="col-6 col-sm-4 col-md-4 col-lg-4 mb-2 px-1">
                                                    <div className="card w-100 hpx-150 bg-contain">
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-danger w-100"
                                    onClick={() => {
                                        this.setState({ modal_close: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-danger w-100"
                                    onClick={() => {
                                        this.CloseWork()
                                    }}
                                >
                                    ปิดกะ
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
