import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
    format_date,
    NumberZero
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';

export default class ListLog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            loading: false,

            data: null

        }
    }
    async componentDidMount() {
        this.GetAll();
    }

    GetAll = async () => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/transaction/filter-search/page/" + this.state.page, null);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            page: 1,
            loading: false,

        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ประวัติผู้ใช้งาน" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ประวัติผู้ใช้งาน</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">วันที่</th>
                                                <th className="text-center">รายละเอียด</th>
                                                <th className="text-center">พนักงาน</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={4} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{NumberZero(((this.state.page - 1) * 10) + index + 1, 5)}</td>
                                                    <td className="text-center">{item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm น.") : "-"}</td>
                                                    <td className="text-center">{item.event_detail ? item.event_detail : "-"}</td>
                                                    <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
            </div >
        )
    }
}
