/* eslint-disable jsx-a11y/anchor-is-valid */
import LOGO from "../assets/images/logo.png"
import React, { Component } from "react"
import Footer from "../components/Footer"
import MD5 from "crypto-js/md5";
import {
  alert,
  POST,
  removeStorage,
  locale
} from "../components/CustomComponent.js"
import "../assets/css/bootstrap.css"
export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      password: "",
      loading: false,

      modal_login: false,
      modal_alert: false,

      show: true,
    }
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (token) {
      if (!user) {
        removeStorage();
      } else if (!user.user_type) {
        window.location.href = "/user/choose-car";
      } else {
        localStorage.setItem("locale", "th");
        window.location.href = "/admin/dashboard";
      }
    } else {
      removeStorage();
    }
  }


  Login = async () => {
    if (this.state.username.trim() === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล/ชื่อผู้ใช้")
      return
    } else if (this.state.password.trim() === "") {
      alert("warning", "แจ้งเตือน", "รหัสผ่าน")
      return
    }
    // if (this.state.email === "customer") {
    //   window.location.href = "/user/list-rent";
    // } else {
    //   window.location.href = "/admin/dashboard";
    // }
    this.setState({ loading: true });
    let data = {
      username: this.state.username,
      password: this.state.password,
      remember: true,
    }
    let result = await POST("", "v1/TH/login", data);
    if (result && result.status) {
      if (result.data.detail.is_active === true) {
        let user = {
          user_id: result.data.detail.user_id,
          user_code: result.data.detail.user_code,
          user_type: result.data.detail.user_type,
          full_name: result.data.detail.full_name,
          address: result.data.detail.address,
          email: result.data.detail.email,
          phone: result.data.detail.phone,
          id_card: result.data.detail.id_card,
          address: result.data.detail.address,
          birthday: result.data.detail.birthday,
          district: result.data.detail.district,
          member_code: result.data.detail.member_code,
          member_id: result.data.detail.member_id,
          member_status: result.data.detail.member_status,
          picture: result.data.detail.picture,
          province: result.data.detail.province,
          sub_district: result.data.detail.sub_district,
          username: result.data.detail.username,
          zip_code: result.data.detail.zip_code,
          sk_wallet: result.data.detail.sk_wallet,
          points: result.data.detail.points,
        }

        localStorage.setItem("5f4dcc3b5aa765d61d8327deb882cf99", MD5(this.state.password).toString());
        localStorage.setItem("token", "token " + result.data.token);
        localStorage.setItem("user", JSON.stringify(user));

        if (!result.data.detail.user_type) {
          window.location.href = "/user/list-rent";
        } else {
          localStorage.setItem("permission", JSON.stringify(result.data.detail.role_data.permission));
          localStorage.setItem("locale", "th");
          window.location.href = "/admin/dashboard";
        }
      } else {
        alert("warning", "แจ้งเตือน", "ผู้ใช้ถูกปิดการใช้งาน โปรดติดต่อเจ้าหน้าที่")
      }
    }
    this.setState({ loading: false });
  }
  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="d-flex w-100 position-fixed bg-dark" style={{ zIndex: 1010 }}>
          <div className="w-100 px-4">
            <div className="w-100 d-flex align-items-center justify-content-between py-2">
              <h3 className="mb-0 text-light pointer" onClick={() => { window.location.href = "/index" }}><b>S.K.SERVICE</b></h3>
            </div>
          </div>
        </div>
        <div className="row background3 pt-5" style={{ height: "100vh" }}>
          <div className="col-12 col-md-1"></div>
          <div className="col-12 py-5 px-4">
            <div className="center w-100">
              <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-12 mx-auto">
                <div className=" d-flex justify-content-center mb-3">
                  <img src={LOGO} style={{ width: 170 }} />
                </div>
                <div className="card shadow">
                  <div className="col-12 p-4">
                    <h3 className="mb-4 text-center">
                      <b>{global[locale]["เข้าสู่ระบบ"]}</b>
                    </h3>
                    <label className="mb-2">{global[locale]["ชื่อผู้ใช้"]} :</label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder={global[locale]["ชื่อผู้ใช้"]}
                      onChange={(e) => {
                        this.setState({ username: e.target.value })
                      }}
                    />
                    <label className="mb-2">{global[locale]["รหัสผ่าน"]} :</label>
                    <div className="position-relative">
                      <input
                        className="form-control mb-3"
                        type={this.state.show ? "password" : "text"}
                        placeholder={global[locale]["รหัสผ่าน"]}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            this.Login()
                          } else {
                            this.setState({ password: e.target.value })
                          }
                        }}
                      />
                      <label
                        className="position-absolute icon pointer"
                        style={{ right: 12, top: 8 }}
                        onClick={() => {
                          this.setState({ show: !this.state.show })
                        }}>{this.state.show ? "\uf070" : "\uf06e"}</label>
                    </div>
                    <button type="button" className="btn btn-orange w-100 mb-3" onClick={() => { this.Login() }}>{global[locale]["ลงชื่อเข้าใช้"]}
                      <label className="icon ml-2">{"\uf023"}</label></button>
                    <div className="text-center mb-3">
                      <label className="px-2 text-orenge pointer" onClick={() => { window.location.href = "/forget" }}>{global[locale]["ลืมรหัสผ่าน?"]}</label>
                    </div>
                    <div className="w-100 text-center">
                      {global[locale]["ยังไม่มีบัญชีใช่หรือไม่?"]} <label className="px-2 text-orenge pointer" onClick={() => { window.location.href = "/register" }}>{global[locale]["สมัครสมาชิก"]}</label>
                    </div>
                    <div className="w-100 text-center mt-2">
                      <small className="text-secondary">{global.version}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div >
    )
  }
}
