import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
    POST,
    PUT,
    DELETE,
    float,
    validate,
    required,
    success_url,
    NumberZero
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";
import CAR from "../../../assets/images/bike.png";
import Resizer from "react-image-file-resizer";

export default class ListEquipment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            filter_search: "",

            data: null,

            id: "",
            equipment_name: "",
            equipment_price: "",
            picture: "",
            is_active: false
        }
    }
    async componentDidMount() {
        this.GetAll();

    }
    GetAll = async () => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/equipment/filter-search/page/" + this.state.page, null);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        if (validate(this.state, ["equipment_name", "equipment_price"])) { return };
        this.setState({ loading: true });
        let data = {
            equipment_name: this.state.equipment_name,
            equipment_price: this.state.equipment_price,
            picture: this.state.picture,
        }
        let result = await POST(tokens, "v1/TH/equipment/create", data);
        if (result && result.status) {
            success_url("/master/list-equipment")
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        if (validate(this.state, ["equipment_name", "equipment_price"])) { return };
        this.setState({ loading: true });
        let data = {
            equipment_id: this.state.id,
            equipment_name: this.state.equipment_name,
            equipment_price: this.state.equipment_price,
            picture: this.state.picture,
            is_active: this.state.is_active
        }
        let result = await PUT(tokens, "v1/TH/equipment/update", data);
        if (result && result.status) {
            success_url("/master/list-equipment")
        }
        this.setState({ loading: false });
    }
    Delete = async () => {
        this.setState({ loading: true });
        let result = await DELETE(tokens, 'v1/TH/equipment/delete/' + this.state.id, null);
        if (result && result.status) {
            success_url("/master/list-equipment")
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            filter_search: "",

            page: 1,
            loading: false,

            id: "",
            equipment_name: "",
            equipment_price: "",
            picture: "",
            is_active: false
        })
    }


    import_file = (e) => {
        this.changePhoto(e.target.files[0])
        document.getElementById("file").value = "";
        document.getElementById("file").file = null;
    }
    drop_file = (e) => {
        e.preventDefault();
        this.setState({ over: false });
        this.changePhoto(e.dataTransfer.items[0].getAsFile())
    }
    changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            this.setState({
                picture: e.target.result.split(",")[1],
            })
        }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    this.setState({ picture: uri.split(",")[1] })
                },
                "base64"
            );
        });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="อุปกรณ์" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>อุปกรณ์</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add" onClick={() => { this.clear_form(); this.setState({ modal_create: true }) }}>
                                    เพิ่มอุปกรณ์
                                </button>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="ชื่อ"
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">รูปภาพ</th>
                                                <th className="text-center">ชื่ออุปกรณ์</th>
                                                <th className="text-center">ราคา</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={5} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.filter(item => item.equipment_name.includes(this.state.filter_search)).map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{NumberZero(((this.state.page - 1) * 10) + index + 1, 5)}</td>
                                                    <td className="text-center"><img className="wpx-30 hpx-30 rounded-circle" src={item.picture ? item.picture : CAR} style={{ objectFit: "cover" }} alt="รูปภาพ" />
                                                    </td>
                                                    <td className="text-center">{item.equipment_name ? item.equipment_name : "-"}</td>
                                                    <td className="text-center">{item.equipment_price ? item.equipment_price : "-"}</td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <label className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_update: true,
                                                                        id: item.equipment_id,
                                                                        equipment_name: item.equipment_name,
                                                                        equipment_price: item.equipment_price,
                                                                        picture: item.picture,
                                                                        is_active: item.is_active
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer"
                                                                > {"\uf304"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-danger-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 delete"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_delete: true,
                                                                        id: item.equipment_id,
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-danger pointer"
                                                                > {"\uf1f8"} </label>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* create or update */}
                <Modal
                    show={this.state.modal_create || this.state.modal_update}
                    onHide={() => { this.setState({ modal_create: false, modal_update: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>{this.state.modal_create ? "เพิ่มอุปกรณ์" : "แก้ไขอุปกรณ์"}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {/* ชื่ออุปกรณ์ */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">รูปภาพอุปกรณ์{required}:</label>
                            {this.state.picture === "" ? (
                                <div
                                    className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5 mb-3"
                                    style={{ height: "80%" }}
                                    onClick={() => { document.getElementById("file").click() }}
                                    onDrop={(e) => this.drop_file(e)}
                                    onDragOver={(e) => { this.setState({ over: true }); e.preventDefault() }}
                                    onDragLeave={(e) => { this.setState({ over: false }); e.preventDefault() }}
                                >
                                    <label className="icon text-orange">{"\uf03e"}</label>
                                    <label className="text-orange">อัปโหลดรูปภาพอุปกรณ์</label>
                                </div>
                            ) : (
                                <div
                                    className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                >
                                    <div className="position-relative w-100">
                                        <img alt="รูปภาพอุปกรณ์" src={this.state.picture.includes("https://") ? this.state.picture : "data:image/png;base64," + this.state.picture} className="w-100 rounded overflow-hidden"></img>
                                        <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.setState({ picture: "" }) }}>
                                            {"\uf00d"}
                                        </button>
                                    </div>
                                </div>
                            )}
                            <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e) }} />
                        </div>
                        {/* ชื่ออุปกรณ์ */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ชื่ออุปกรณ์{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ equipment_name: e.target.value })
                                }}
                                value={this.state.equipment_name}
                            />
                        </div>
                        {/* ราคา */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ราคา{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    if (!float(e.target.value)) {
                                        return
                                    }
                                    this.setState({ equipment_price: e.target.value })
                                }}
                                value={this.state.equipment_price}
                            />
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        if (this.state.modal_create) {
                                            this.Create();
                                        } else {
                                            this.Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบข้อมูล</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการลบข้อมูลดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
