import { Link } from "react-router";
import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Logout, getStorage, locale } from "./CustomComponent.js"
import { Modal } from "react-bootstrap";
import "../assets/css/bootstrap.css";
import "./global.js";

import TH from "../assets/images/th.svg";
import EN from "../assets/images/en.svg";

export default class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
      modal_logout: false,
    };
  }
  componentDidMount() {
    let user_type = getStorage("user") ? getStorage("user").user_type : null;
    if (user_type === null) {
      window.location.href = "/";
    } else if (!user_type) {
    } else {
      window.location.href = "/admin/dashboard";
    }
  }
  icon = (icon) => { return (<label className="icon">{icon}</label>) }
  render() {
    return (
      <div>
        <div className="w-100 mx-0 px-0 position-fixed d-flex justify-content-between bg-navbar shadow px-4 py-2" style={{ height: 60, zIndex: 1010 }}>
          <div className="align-items-center d-flex row mx-0">
            {this.props.sidebar !== "false" && !window.location.pathname.includes("pos") && (
              <label className="d-block d-lg-none d-xl-none mr-3 mt-2" onClick={() => { this.setState({ sidebar: !this.state.sidebar }); }}>
                <span className="icon">{"\uf0c9"}</span>
              </label>
            )}
            <div className="position-relative">
              <h3><b>S.K.SERVICE</b></h3>
              <small className="position-absolute" style={{ right: 0, bottom: -5 }}> {global.version}</small>
            </div>
            <div className="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex">
              <small>{this.props.page1}</small>
              <small className="ml-2">{this.props.page2 ? " > " + this.props.page2 : ""}</small>
              <small className="ml-2">{this.props.page3 ? " > " + this.props.page3 : ""}</small>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-2 align-items-center d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex">
              {getStorage("user") ? getStorage("user").full_name : "-"}
            </span>
            <label className="icon text-18 p-2"
              onClick={() => { this.setState({ modal_logout: true }) }}>{"\uf2f5"}</label>
            <div className="dropdowns">
              <span className="dropbtns pointer">
                <img src={locale === "th" ? TH : EN} className="ml-3 wpx-20 hpx-20 rounded-circle mr-2" style={{ objectFit: "cover" }} />
              </span>
              <div className="dropdowns-content wpx-250 bg-white">
                <a className="pointer text-left" onClick={() => {
                  localStorage.setItem("locale", "th");
                  window.location.reload();
                }}> <img src={TH} className="wpx-20 hpx-20 rounded-circle mr-2" style={{ objectFit: "cover" }} />ภาษาไทย</a>
                <a className="pointer text-left" onClick={() => {
                  localStorage.setItem("locale", "en");
                  window.location.reload();
                }}> <img src={EN} className="wpx-20 hpx-20 rounded-circle mr-2" style={{ objectFit: "cover" }} />English</a>
              </div>
            </div>
          </div>
        </div>
        <ProSidebar className="full-screen" breakPoint="lg" toggled={this.state.sidebar} onFocus={() => { this.setState({ sidebar: true }); }}>
          <Menu>
            {/* MENU */}
            <MenuItem icon={this.icon("\uf21c")} active={window.location.pathname.includes("/user/choose-car")}><Link to="/user/choose-car">{global[locale]["เลือกรถ"]}</Link></MenuItem>
            <MenuItem icon={this.icon("\uf518")} active={window.location.pathname.includes("/user/list-booking")}><Link to="/user/list-booking">{global[locale]["การจองรถ"]}</Link></MenuItem>
            <MenuItem icon={this.icon("\uf02d")} active={window.location.pathname.includes("/user/list-rent")}><Link to="/user/list-rent">{global[locale]["การเช่ารถ"]}</Link></MenuItem>
            <MenuItem icon={this.icon("\uf02d")} active={window.location.pathname.includes("/user/list-traffic-ticket")}><Link to="/user/list-traffic-ticket">{global[locale]["ข้อมูลใบสั่ง"]}</Link></MenuItem>
            <MenuItem icon={this.icon("\uf02d")} active={window.location.pathname.includes("/user/list-history-point")}><Link to="/user/list-history-point">{global[locale]["ประวัติแต้ม/คะแนน"]}</Link></MenuItem>
            <MenuItem icon={this.icon("\uf2bd")} active={window.location.pathname.includes("/user/profile")}><Link to="/user/profile">{global[locale]["โปรไฟล์"]}</Link></MenuItem>
          </Menu>
        </ProSidebar>
        {/* MODAL */}
        <Modal show={this.state.modal_logout} onHide={() => { this.setState({ modal_logout: false }) }}>
          <Modal.Header><Modal.Title><b>{global[locale]["ออกจากระบบ"]}</b></Modal.Title></Modal.Header>
          <Modal.Body>
            <div className="w-100  mb-3">
              <label>{global[locale]["ยืนยันการออกจากระบบหรือไม่"]}</label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_logout: false }) }}>{global[locale]["ยกเลิก"]}</button>
              </div>
              <div className="w-50 p-1">
                <button className="btn btn-orange w-100" onClick={() => { Logout() }}>{global[locale]["ออกจากระบบ"]}</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}