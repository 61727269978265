import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import {
    getStorage
} from "../../components/CustomComponent.js";
import React, { Component } from "react";

export default class Master extends Component {
    constructor(props) {
        super(props)

        this.state = {

            page: 1,
            loading: false,

            permission: []
        }
    }
    async componentDidMount() {
        console.log("master")
        let user_type = getStorage("user").user_type
        if (user_type === "owner") {
            this.setState({
                permission: ["admin", "dashboard", "cashier", "manage-rent", "list-rent", "list-booking", "list-receipt", "list-withdraw", "list-deposit", "list-collateral", "list-tax-notice", "list-traffic-ticket", "list-log", "master", "list-admin", "list-role", "manage-info", "list-member", "list-agent", "list-bank-account", "list-banner", "list-car", "list-brand", "list-model","list-equipment", "list-storage", "list-car-warehouse"]
            })
        } else {
            let permission = await getStorage("permission").filter(e => e.can_view).map(e => { return e.menu_name })
            this.setState({ permission: permission })
        }
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="การจัดการระบบ" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>การจัดการระบบ</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-body p-3">
                                <hr />
                                <div className="row">
                                    {this.state.permission.findIndex(e => e === "list-admin") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-admin" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf0c0"}</label><br />
                                                ข้อมูลผู้ใช้งาน
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "list-role") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-role" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf09c"}</label><br />
                                                สิทธิ์การเข้าถึงข้อมูล
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "manage-info") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/manage-info" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf109"}</label><br />
                                                ข้อมูลบริษัท
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    {this.state.permission.findIndex(e => e === "list-member") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-member" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf2c2"}</label><br />
                                                ข้อมูลสมาชิก
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "list-agent") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-agent" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf2bd"}</label><br />
                                                ข้อมูล Agent
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "list-bank-account") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-bank-account" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf19c"}</label><br />
                                                ข้อมูลบัญชีธนาคาร
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "list-banner") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-banner" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf302"}</label><br />
                                                แบนเนอร์
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    {this.state.permission.findIndex(e => e === "list-brand") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-brand" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf21c"}</label><br />
                                                ยี่ห้อรถ
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "list-model") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-model" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf09c"}</label><br />
                                                รุ่นรถ
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "list-car") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-car" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf46d"}</label><br />
                                                ข้อมูลรถ
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "list-equipment") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-equipment" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf807"}</label><br />
                                                อุปกรณ์
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "list-storage") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-storage" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf0c7"}</label><br />
                                                ช่องเก็บรถ
                                            </div>
                                        </div>
                                    )}
                                    {this.state.permission.findIndex(e => e === "list-car-warehouse") !== -1 && (
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                            <div
                                                className="master hpx-150 w-100"
                                                onClick={() => { window.location.href = "/master/list-car-warehouse" }} >
                                                <label className="icon text-48 mb-2 text-orange">{"\uf494"}</label><br />
                                                คลังรถ
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
