import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
    POST,
    PUT,
    DELETE,
    success_url
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Resizer from "react-image-file-resizer";

export default class ListBrand extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_delete: false,
            update: false,
            index: "",
            page: 1,
            loading: false,
            picture: "",
            id: "",
            data: []
        }
    }
    async componentDidMount() {
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/banner/gallery-picture", null);
        if (result && result.status) {
            this.setState({ data: result.data.data })
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        this.setState({ loading: true });
        let data = {
            picture: this.state.picture,
        }
        let result = await POST(tokens, "v1/TH/banner/create", data);
        if (result && result.status) {
            success_url("/master/list-banner");
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        this.setState({ loading: true });
        let data = {
            picture_list: this.state.data.map((item => { return item.banner_id })),
        }
        let result = await PUT(tokens, "v1/TH/banner/update", data);
        if (result && result.status) {
            success_url("/master/list-banner");
        }
        this.setState({ loading: false });
    }
    Delete = async () => {
        this.setState({ loading: true });
        let result = await DELETE(tokens, 'v1/TH/banner/delete/' + this.state.id, null);
        if (result && result.status) {
            success_url("/master/list-banner");
        }
        this.setState({ loading: false });
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            page: 1,
            loading: false,
        })
    }

    change_position = (start, end) => {
        if (start === end) {
            return
        }
        let data = this.state.data;
        const data_start = data[start];
        let array = [];
        data.splice(Number(start), 1);
        for (let i = 0; i < data.length; i++) {
            if (i === Number(end)) {
                array.push(data_start);
                array.push(data[i]);
            } else {
                array.push(data[i]);
            }
        }
        if (data.length === Number(end)) {
            array.push(data_start);
        }

        this.setState({ data: array });
    }

    import_file = (e) => {
        this.changePhoto(e.target.files[0])
        document.getElementById("file").value = "";
        document.getElementById("file").file = null;
    }
    changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            this.setState({
                picture: e.target.result.split(",")[1],
            })
            setTimeout(() => {
                this.Create();
            }, 10);
        }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    this.setState({ picture: uri.split(",")[1] })
                },
                "base64"
            );
        });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="แบนเนอร์" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ภาพแบนเนอร์</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                {!this.state.update && (
                                    <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add" onClick={() => { document.getElementById("file").click() }}>
                                        เพิ่มภาพแบนเนอร์
                                    </button>
                                )}

                                <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e) }} />

                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-body p-3">
                                <div className="row">
                                    {this.state.data.map((item, index) => (
                                        <div className="col-6 col-md-4 col-xl-3 mb-3 position-relative" draggable>
                                            <img
                                                id={index}
                                                alt="car"
                                                className="w-100 hpx-200 rounded"
                                                src={item.picture}
                                                style={{ objectFit: "cover" }}
                                                draggable
                                                onDragStart={(e) => { this.setState({ index: e.target.id }) }}
                                                onDragOver={(e) => { e.target.style = "object-fit: cover;opacity:0.7;"; e.preventDefault() }}
                                                onDragLeave={(e) => { e.target.style = "object-fit: cover;opacity:1;"; e.preventDefault() }}
                                                onDrop={(e) => { this.change_position(this.state.index, e.target.id); this.setState({ update: true }); e.target.style = "object-fit: cover;opacity:1"; e.preventDefault(); }}
                                            />
                                            {!this.state.update && (<div className="position-absolute bg-white px-1 rounded" style={{ top: -10, right: 4 }}>
                                                <label className="icon text-18 p-2 text-danger delete" onClick={() => { this.setState({ modal_delete: true, id: item.banner_id }) }}>{"\uf00d"}</label>
                                            </div>)}
                                        </div>
                                    ))}
                                </div>
                                {this.state.update && (
                                    <div className="w-100 d-flex justify-content-end edit">
                                        <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.reload(); }}>
                                            ยกเลิก
                                        </button>
                                        <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.Update(); }}>
                                            บันทึก
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL */}

                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบข้อมูล</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการลบข้อมูลดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
