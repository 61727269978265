import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    POST,
    PUT,
    float,
    validate,
    required,
    DatePickerTH,
    success_url
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import Resizer from "react-image-file-resizer";
import { Modal } from "react-bootstrap";

export default class ListTrafficTicket extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_detail: false,
            modal_delete: false,

            page: 1,
            loading: false,

            data: null,
            data_car: [],

            filter_search: "",
            filter_pick_up_date: "",
            filter_return_date: "",

            traffic_ticket_id: "",
            rental_number: "",
            license_plate: "",
            rental_date: "",
            rental_time: "",
            id_card: "",
            full_name: "",
            picture: "",
            traffic_fines: "",
            ticket_status: 0,
        }
    }
    async componentDidMount() {
        await this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            pick_up_date: this.state.filter_pick_up_date,
            return_date: this.state.filter_return_date
        }
        let result = await GET(tokens, "v1/TH/traffic-ticket/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }

    GetRentAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.license_plate.replaceAll("#", "%23"),
            pick_up_date: "",
            rental_status: "",
        }
        let result = await GET(tokens, "v1/TH/rental-manage/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_car: result.data.data.map(e => { return { ...e, label: e.rental_number, value: e.rental_number } }) })

            setTimeout(() => {
                if (this.state.rental_number) {
                    this.setState({ rental_number: this.state.data_car.filter(e => e.value === this.state.rental_number)[0] })
                }
            }, 10);
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        if (validate(this.state, ["license_plate", "rental_number", "picture", "traffic_fines"])) { return };
        this.setState({ loading: true });
        let data = {
            rental_number: this.state.rental_number ? this.state.rental_number.value : "",
            traffic_fines: Number(this.state.traffic_fines),
            picture: this.state.picture
        }
        let result = await POST(tokens, "v1/TH/traffic-ticket/create", data);
        if (result && result.status) {
            success_url("/admin/list-traffic-ticket");
        }
        this.setState({ loading: false });
    }

    Update = async () => {
        if (validate(this.state, ["license_plate", "rental_number", "picture", "traffic_fines"])) { return };
        this.setState({ loading: true });
        let data = {
            traffic_ticket_id: this.state.traffic_ticket_id,
            rental_number: this.state.rental_number ? this.state.rental_number.value : "",
            traffic_fines: Number(this.state.traffic_fines),
            picture: this.state.picture,
            ticket_status: 2
        }
        let result = await PUT(tokens, "v1/TH/traffic-ticket/update", data);
        if (result && result.status) {
            success_url("/admin/list-traffic-ticket");
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_detail: false,
            modal_delete: false,

            page: 1,
            loading: false,


            filter_search: "",
            filter_pick_up_date: "",
            filter_return_date: "",

            rental_number: "",
            license_plate: "",
            rental_date: "",
            rental_time: "",
            id_card: "",
            full_name: "",
            picture: "",
            traffic_fines: ""

        })
    }
    import_file = (e) => {
        this.changePhoto(e.target.files[0])
        document.getElementById("file").value = "";
        document.getElementById("file").file = null;

    }
    changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => { }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    this.setState({ picture: uri.split(",")[1] })
                },
                "base64"
            );
        });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="เมนูจัดการข้อมูลใบสั่ง" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>เมนูจัดการข้อมูลใบสั่ง</b></h4>
                            <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add" onClick={() => {
                                this.clear_form();
                                setTimeout(() => {
                                    this.setState({ modal_create: true })
                                }, 10);
                            }}>
                                เพิ่มใบสั่ง
                            </button>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* วันเช่ารถ */}
                                    <div className=" wpx-200 px-0 mx-1 mb-2">
                                        <DatePickerTH
                                            className="form-control bg-white border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_pick_up_date: e, page: 1 });
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }}
                                            onBlur={() => {
                                                this.GetAll();
                                            }}
                                            value={this.state.filter_pick_up_date}
                                        />
                                    </div>
                                    {/* วันคืนรถ */}
                                    <div className=" wpx-200 px-0 mx-1 mb-2">
                                        <DatePickerTH
                                            className="form-control bg-white border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_return_date: e, page: 1 });
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }}
                                            onBlur={() => {
                                                this.GetAll();
                                            }}
                                            value={this.state.filter_return_date}
                                        />
                                    </div>
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_pick_up_date: "",
                                                filter_return_date: "",
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">เลขที่สัญญาเช่า</th>
                                                <th className="text-center">วันที่เช่า</th>
                                                <th className="text-center">วันที่คืน</th>
                                                <th className="text-center">ชื่อ</th>
                                                <th className="text-center">สถานะ</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={6} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.rental_data ? item.rental_data.rental_number : "-"}</td>
                                                    <td className="text-center">{item.rental_data ? item.rental_data.pick_up_date : "-"}</td>
                                                    <td className="text-center">{item.rental_data ? item.rental_data.return_date : "-"}</td>
                                                    <td className="text-center">{item.rental_data ? item.rental_data.full_name : "-"}</td>
                                                    <td className="text-center">
                                                        {item.ticket_status === 0 && (<span className="bg-secondary rounded px-2 py-1 text-white">ไม่เลือก</span>)}
                                                        {item.ticket_status === 1 && (<span className="bg-warning rounded px-2 py-1 text-white">รอติดต่อ</span>)}
                                                        {item.ticket_status === 2 && (<span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span>)}
                                                        {item.ticket_status === 3 && (<span className="bg-danger rounded px-2 py-1 text-white">ยกเลิก</span>)}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <label className="wpx-30 hpx-30 bg-primary-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 view"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        rental_number: item.rental_data.rental_number,
                                                                        license_plate: item.rental_data.license_plate,
                                                                        rental_date: item.rental_data.pick_up_date,
                                                                        rental_time: item.rental_data.pick_up_time,
                                                                        id_card: item.rental_data.id_card,
                                                                        full_name: item.rental_data.full_name,
                                                                        picture: item.picture,
                                                                        traffic_fines: item.traffic_fines,
                                                                        modal_detail: true
                                                                    })
                                                                    setTimeout(() => {
                                                                        this.GetRentAll();
                                                                    }, 10);
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-primary pointer"
                                                                > {"\uf570"} </label>
                                                            </label>
                                                            {item.ticket_status !== 2 && (
                                                                <label className="wpx-30 hpx-30 bg-danger-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            rental_number: item.rental_data.rental_number,
                                                                            license_plate: item.rental_data.license_plate,
                                                                            rental_date: item.rental_data.pick_up_date,
                                                                            rental_time: item.rental_data.pick_up_time,
                                                                            id_card: item.rental_data.id_card,
                                                                            full_name: item.rental_data.full_name,
                                                                            picture: item.picture,
                                                                            traffic_fines: item.traffic_fines,
                                                                            ticket_status: item.ticket_status,
                                                                            traffic_ticket_id: item.traffic_ticket_id,
                                                                            modal_delete: true
                                                                        })
                                                                        setTimeout(() => {
                                                                            this.GetRentAll();
                                                                        }, 10);
                                                                    }}>
                                                                    <label
                                                                        className="icon mx-2 text-danger pointer"
                                                                    > {"\uf09c"} </label>
                                                                </label>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* create */}
                <Modal
                    show={this.state.modal_create || this.state.modal_detail}
                    onHide={() => { this.setState({ modal_create: false, modal_detail: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>{this.state.modal_create ? "เพิ่มใบสั่ง" : "รายละเอียด"}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                {/* ทะเบียนรถ */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">ทะเบียนรถ{required}:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ license_plate: e.target.value })
                                        }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("date").focus();
                                            }
                                        }}
                                        onBlur={() => {
                                            if (this.state.modal_create) {
                                                this.setState({
                                                    rental_number: "",
                                                    id_card: "",
                                                    full_name: "",
                                                })
                                                this.GetRentAll();
                                            }
                                        }}
                                        value={this.state.license_plate}
                                        readOnly={this.state.modal_detail}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                {/* วันที่เช่า */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">วันที่เช่า :</label>
                                    <DatePickerTH
                                        id="date"
                                        className="form-control"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => {
                                            this.setState({
                                                rental_date: e,
                                                rental_number: "",
                                                id_card: "",
                                                full_name: "",
                                            });
                                            if (!e) {
                                                this.setState({ rental_time: "" })
                                            }
                                        }}
                                        value={this.state.rental_date}
                                        disabled={this.state.modal_detail}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                {/* เวลาที่เช่า */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">เวลาที่เช่า :</label>
                                    <input
                                        className="form-control"
                                        type="time"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({
                                                rental_time: e.target.value,
                                                rental_number: "",
                                                id_card: "",
                                                full_name: "",
                                            })
                                        }}
                                        value={this.state.rental_time}
                                        readOnly={this.state.modal_detail}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* สัญญาเช่า */}
                        <div className="w-100 mb-3">
                            <label className="w-100">สัญญาเช่า{required}:</label>
                            <Select
                                className="form-control select-search bg-white"
                                styles={select_style}
                                options={this.state.data_car ? this.state.data_car.filter(e => (this.state.rental_date === "" || (new Date(this.state.rental_date).getTime() >= new Date(e.pick_up_date).getTime() && new Date(this.state.rental_date).getTime() <= new Date(e.return_date).getTime()))) : []}
                                onChange={(e) => {
                                    this.setState({
                                        rental_number: e,
                                        id_card: e.id_card,
                                        full_name: e.full_name
                                    });
                                }}
                                value={this.state.rental_number}
                                isClearable
                                isDisabled={this.state.modal_detail}
                            ></Select>
                        </div>
                        {/* เลขบัตรประชาชน/เลขพาสปอร์ต */}
                        <div className="w-100 mb-3">
                            <label className="w-100">เลขบัตรประชาชน/เลขพาสปอร์ต{required}:</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ id_card: e.target.value })
                                }}
                                value={this.state.id_card}
                                readOnly
                            />
                        </div>
                        {/* ชื่อ-นามสกุล */}
                        <div className="w-100 mb-3">
                            <label className="w-100">ชื่อ-นามสกุล{required}:</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ full_name: e.target.value })
                                }}
                                value={this.state.full_name}
                                readOnly
                            />
                        </div>
                        {/* จำนวนเงิน */}
                        <div className="w-100 mb-3">
                            <label className="w-100">จำนวนเงิน{required}:</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    if (!float(e.target.value)) {
                                        return
                                    }
                                    this.setState({ traffic_fines: e.target.value })
                                }}
                                value={this.state.traffic_fines}
                                readOnly={this.state.modal_detail}
                            />
                        </div>
                        {this.state.picture === "" ? (
                            <div className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5 mb-3 pointer"
                                onClick={() => { document.getElementById("file").click() }}>
                                <label className="icon text-orange">{"\uf03e"}</label>
                                <label className="text-orange">อัปโหลดภาพใบสั่ง</label>
                            </div>
                        ) : (
                            <div
                                className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                style={{ height: "100%" }}
                            >
                                <div className="position-relative w-100">
                                    <img alt="รูปใบสั่ง" src={this.state.picture.includes("https://") ? this.state.picture : "data:image/png;base64," + this.state.picture} className="w-100 rounded overflow-hidden"></img>
                                    {this.state.modal_create && (
                                        <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.setState({ picture: "" }) }}>
                                            {"\uf00d"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                        <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e, "id_card") }} />

                        {/* ปุ่ม */}
                        <div className="w-100 d-flex justify-content-center">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_detail: false })
                                    }}
                                >
                                    {this.state.modal_create ? "ยกเลิก" : "ปิดหน้าต่าง"}
                                </button>
                            </div>
                            {this.state.modal_create && (
                                <div className="w-50 p-1">
                                    <button
                                        className="btn btn-orange w-100"
                                        onClick={() => {
                                            if (this.state.modal_create) {
                                                this.Create();
                                            } else {
                                                this.Update();
                                            }
                                        }}
                                    >
                                        {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                                    </button>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันชำระค่าปรับ</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการชำระค่าปรับดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-orange w-100" onClick={() => { this.Update() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
