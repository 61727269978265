import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    format_date,
    DatePickerTH,
    toFixed,
    total
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
    FCIconic: {
        normal: "FCIconic-Light.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Italic.ttf",
        bolditalics: "FCIconic-Italic.ttf",
    },
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
}

export default class ListWithdraw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            data: [],

            filter_search: "",
            filter_date: format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_type: "",

            start_datetime: format_date(new Date(), "yyyy-mm-dd hh:mm", "en"),
            end_datetime: format_date(new Date(), "yyyy-mm-dd hh:mm", "en"),
            admin_name: "",

            data_admin:[],
        }
        this.tableRef = React.createRef();
    }
    async componentDidMount() {
        await this.GetAll();
        await this.GetAdminAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("RE", "DP").replaceAll("#", "%23"),
            // filter_date: this.state.filter_date,
            start_datetime: this.state.start_datetime.replaceAll("T"," "),
            end_datetime: this.state.end_datetime.replaceAll("T"," "),
            admin_name: this.state.admin_name?this.state.admin_name.value:"",
        }
        let result = await GET(tokens, "v1/TH/receipt/filter-search/page/0", data);
        if (result && result.status) { 
            this.setState({ data: result.data.data })
        }
        this.setState({ loading: false });
    } 
    GetAdminAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: "",
            user_type: ""
        }
        let result = await GET(tokens, "v1/TH/user-admin/filter-search/page/0", data);
        if (result && result.status) { 
            this.setState({ data_admin: result.data.data.filter(e=>e.user_type === "cashier").map(item=>{return {value:item.full_name,label:item.full_name}}) })
        }
        this.setState({ loading: false });
    }
    pdf = () => {
        let table = this.state.data.filter(item => !this.state.payment_type || item.payment_type.toString() === this.state.payment_type.value).map((item, index) => {
            return [
                { text: item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss") : "-", alignment: "center" },
                { text: item.payment_number ? item.payment_number.replaceAll("DP", "RE") : "-", alignment: "center" },
                { text: item.full_name ? item.full_name : "-", alignment: "left" },
                { text: item.rental_number ? item.rental_number.replaceAll("ใบเช่า : ", "") : "-", alignment: "left" },
                { text: item.guarantee_amount && item.event_type === 1 ? toFixed(item.guarantee_amount) : "0.00", alignment: "right" },
                { text: item.guarantee_amount && item.event_type === 3 ? toFixed(item.guarantee_amount) : "0.00", alignment: "right" },
                { text: item.total_price ? toFixed(item.total_price - (item.event_type === 1 ? item.guarantee_amount : 0)) : "0.00", alignment: "right" },
                { text: item.discount ? toFixed(item.discount) : "0.00", alignment: "right" },
                { text: item.total_pay || item.total_pay_transfer || item.total_pay_cradit ? toFixed(item.total_pay + item.total_pay_transfer + item.total_pay_cradit) : "0.00", alignment: "right" },
                { text: item.admin_name?item.admin_name:"-", alignment: "center" },
            ]
        });
        var docDefinition = {
            content: [

                { text: "รายได้ประจำวัน", alignment: "left", fontSize: 24, bold: true },
                " ",
                {
                    table: {
                        headerRows: 1,
                        widths: [ '15%', '10%', '12%', '12%', '8%', '8%', '8%', '8%', '8%','11%'],
                        body: [
                            [
                                { text: "วันที่", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "เลขที่ใบเสร็จ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ลูกค้า", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "Ref No.", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ค่าประกัน", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "คืนค่าประกัน", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ยอดที่ต้องชำระทั้งสิ้น", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ส่วนลด", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ยอดรับชำระ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ชื่อแคชเชียร์", alignment: "center", fillColor: "#F2F2F2" },
                            ],
                            ...table, 
                            [
                                { text: "รวมทั้งสิ้น", alignment: "left", fillColor: "#F2F2F2",colSpan:4 },
                                "",
                                "",
                                "",
                                { text: toFixed(total(this.state.data.filter(item=>item.guarantee_amount && item.event_type === 1),"guarantee_amount")), alignment: "right", fillColor: "#F2F2F2" },
                                { text: toFixed(total(this.state.data.filter(item=>item.guarantee_amount && item.event_type === 3),"guarantee_amount")), alignment: "right", fillColor: "#F2F2F2" },
                                { text: toFixed(total(this.state.data,"total_price")-total(this.state.data.filter(item=>item.guarantee_amount && item.event_type === 1),"guarantee_amount")), alignment: "right", fillColor: "#F2F2F2" },
                                { text: toFixed(total(this.state.data,"discount")), alignment: "right", fillColor: "#F2F2F2" },
                                { text: toFixed(total(this.state.data,"total_pay")+total(this.state.data,"total_pay_transfer")+total(this.state.data,"total_pay_cradit")), alignment: "right", fillColor: "#F2F2F2" },
                                "",
                            ],
                        ],
                    }
                },
            ], 
            // , alignment: "justify" 
            defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: 'landscape',
        }
        pdfMake.createPdf(docDefinition).open()
    }
    excel = () => {

    }
    clear_form() {
        this.setState({
            loading: false,

            filter_search: ""
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายได้ประจำวัน" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายได้ประจำวัน</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/report" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.pdf() }}>
                                    PDF
                                </button>
                                <DownloadTableExcel
                                    filename="รายได้ประจำวัน"
                                    sheet="รายได้ประจำวัน"
                                    currentTableRef={this.tableRef.current}
                                >
                                    <button type="button" className="btn btn-dark px-4 mx-1 mb-3" onClick={() => { this.excel() }}>
                                        EXCEL
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        value={this.state.filter_search}
                                    />
                                    <div className="wpx-200 px-0 mx-1 mb-2">
                                        <input
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            type="datetime-local"
                                            onChange={(e) => {
                                                this.setState({ start_datetime: e.target.value });
                                            }}
                                            value={this.state.start_datetime}
                                        />
                                    </div>
                                    <div className="wpx-200 px-0 mx-1 mb-2">
                                    <input
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            type="datetime-local"
                                            onChange={(e) => {
                                                this.setState({ end_datetime: e.target.value });
                                            }}
                                            value={this.state.end_datetime}
                                        />
                                    </div>
                                    <Select
                                        className="form-control select-search bg-white border-0 wpx-200 mx-1 mb-2"
                                        styles={select_style}
                                        options={this.state.data_admin}
                                        onChange={(e) => {
                                            this.setState({
                                                admin_name: e,
                                            });
                                        }}
                                        value={this.state.admin_name}
                                        isClearable
                                    ></Select>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_date: "",
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped" ref={this.tableRef}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">วันที่</th>
                                                <th className="text-center">เลขที่ใบเสร็จ</th>
                                                <th className="text-center">ลูกค้า</th>
                                                <th className="text-center">Ref No.</th>
                                                <th className="text-center">ค่าประกัน</th>
                                                <th className="text-center">คืนค่าประกัน</th>
                                                <th className="text-center">ยอดที่ต้องชำระทั้งสิ้น</th>
                                                <th className="text-center">ส่วนลด</th>
                                                <th className="text-center">ยอดรับชำระ</th>
                                                <th className="text-center">ชื่อแคชเชียร์</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={10} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss") : "-"}</td>
                                                    <td className="text-center">{item.payment_number ? item.payment_number.replaceAll("DP", "RE") : "-"}</td>
                                                    <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                                                    <td className="text-center">{item.rental_number ? item.rental_number.replaceAll("ใบเช่า : ", "") : "-"}</td>
                                                    <td className="text-center">{item.guarantee_amount && item.event_type === 1 ? toFixed(item.guarantee_amount) : "0.00"}</td>
                                                    <td className="text-center">{item.guarantee_amount && item.event_type === 3 ? toFixed(item.guarantee_amount) : "0.00"}</td>
                                                    <td className="text-center">{item.total_price ? toFixed(item.total_price - (item.event_type === 1 ? item.guarantee_amount : 0)) : "0.00"}</td>
                                                    <td className="text-center">{item.discount ? toFixed(item.discount) : "0.00"}</td>
                                                    <td className="text-center">{item.total_pay || item.total_pay_transfer || item.total_pay_cradit ? toFixed(item.total_pay + item.total_pay_transfer + item.total_pay_cradit) : "0.00"}</td>
                                                    <td className="text-center">{item.admin_name?item.admin_name:"-"}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                    <td className="text-center" colSpan={4}>รวมทั้งสิ้น</td> 
                                                    <td className="text-center">{toFixed(total(this.state.data.filter(item=>item.guarantee_amount && item.event_type === 1),"guarantee_amount"))}</td>
                                                    <td className="text-center">{toFixed(total(this.state.data.filter(item=>item.guarantee_amount && item.event_type === 3),"guarantee_amount"))}</td>
                                                    <td className="text-center">{toFixed(total(this.state.data,"total_price")-total(this.state.data.filter(item=>item.guarantee_amount && item.event_type === 1),"guarantee_amount"))}</td>
                                                    <td className="text-center">{toFixed(total(this.state.data,"discount"))}</td>
                                                    <td className="text-center">{toFixed(total(this.state.data,"total_pay")+total(this.state.data,"total_pay_transfer")+total(this.state.data,"total_pay_cradit"))}</td>
                                                    <td className="text-center"></td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
