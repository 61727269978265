import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    alert,
    GET,
    POST,
    PUT,
    DELETE,
    float,
    validate,
    required,
    success_url,
    validateENNumber
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";
import User from "../../../assets/images/user.png";
import Switch from "react-switch";
import Resizer from "react-image-file-resizer";

export default class ListAdmin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_password: false,
            modal_delete: false,

            page: 1,
            loading: false,

            data: null,
            data_role: [],
            data_user_type: [
                { value: "", label: "ทั้งหมด" },
                { value: "admin", label: "admin" },
                { value: "manager", label: "ผู้จัดการ" },
                { value: "cashier", label: "แคชเชียร์" },
                { value: "employee", label: "พนักงาน" }
            ],

            filter_search: "",
            filter_user_type: { value: "", label: "ทั้งหมด" },
            id: "",

            user_type: "",
            role: "",
            full_name: "",
            address: "",
            phone: "",
            email: "",
            username: "",
            password: "",
            is_active: false,
            picture: ""
        }
    }
    async componentDidMount() {
        await this.GetAll();
        await this.GetRoleAll();
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_password: false,
            modal_delete: false,

            page: 1,
            loading: false,

            filter_search: "",
            filter_user_type: { value: "", label: "ทั้งหมด" },
            id: "",

            user_type: "",
            user_code: "",
            role: "",
            full_name: "",
            address: "",
            phone: "",
            email: "",
            username: "",
            password: "",
            is_active: false,
            picture: "",

            over: false
        })
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search,
            user_type: this.state.filter_user_type ? this.state.filter_user_type.value : ""
        }
        let result = await GET(tokens, "v1/TH/user-admin/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    GetRoleAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search
        }
        let result = await GET(tokens, "v1/TH/role-manage/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data_role: result.data.data.map(item => { return { value: item.role_id, label: item.role_name } }) })
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        if (validate(this.state, ["username", "password", "user_type", "role_id", "full_name"])) { return };
        if (this.state.phone.length > 0 && this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน")
            return
        } else if (this.state.password.length < 4) {
            alert("warning", "แจ้งเตือน", "รหัสผ่านต้องมากกว่า 4 ตัวขึ้นไป")
            return
        }
        this.setState({ loading: true });
        let data = {
            username: this.state.username,
            password: this.state.password,
            user_type: this.state.user_type ? this.state.user_type.value : "",
            role_id: this.state.role ? this.state.role.value : "",
            full_name: this.state.full_name,
            address: this.state.address,
            phone: this.state.phone,
            email: this.state.email,
            is_active: true,
            picture: this.state.picture
        }
        let result = await POST(tokens, "v1/TH/user-admin/create", data);
        if (result && result.status) {
            success_url("/master/list-admin");
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        if (validate(this.state, ["user_type", "role_id", "full_name"])) { return };
        if (this.state.phone.length > 0 && this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน")
            return
        } else if (this.state.password.length !== 0 && this.state.password.length < 4) {
            alert("warning", "แจ้งเตือน", "รหัสผ่านต้องมากกว่า 4 ตัวขึ้นไป")
            return
        }
        this.setState({ loading: true });
        let data = {
            user_id: this.state.id,
            username: this.state.username,
            password: this.state.password,
            user_type: this.state.user_type ? this.state.user_type.value : "",
            role_id: this.state.role ? this.state.role.value : "",
            full_name: this.state.full_name,
            address: this.state.address,
            phone: this.state.phone,
            email: this.state.email,
            is_active: this.state.is_active,
            picture: this.state.picture
        }
        let result = await PUT(tokens, "v1/TH/user-admin/update", data);
        if (result && result.status) {
            success_url("/master/list-admin");
        }
        this.setState({ loading: false });
    }
    Delete = async () => {
        this.setState({ loading: true });
        let result = await DELETE(tokens, 'v1/TH/user-admin/delete/' + this.state.id, null);
        if (result && result.status) {
            success_url("/master/list-admin")
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    import_file = (e) => {
        this.changePhoto(e.target.files[0])
        document.getElementById("file").value = "";
        document.getElementById("file").file = null;
    }
    drop_file = (e) => {
        e.preventDefault();
        this.setState({ over: false });
        this.changePhoto(e.dataTransfer.items[0].getAsFile())
    }
    changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            this.setState({
                picture: e.target.result.split(",")[1],
            })
        }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    this.setState({ picture: uri.split(",")[1] })
                },
                "base64"
            );
        });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ข้อมูลผู้ใช้งาน" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ข้อมูลผู้ใช้งาน</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add" onClick={async () => { await this.clear_form(); this.setState({ modal_create: true }) }}>
                                    เพิ่มผู้ใช้งาน
                                </button>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="รหัสผู้ใช้,ชื่อ-นามสกุล"
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    <Select
                                        className="form-control select-search bg-white wpx-200 border-0 mx-1 mb-2"
                                        styles={select_style}
                                        options={this.state.data_user_type}
                                        onChange={(e) => {
                                            this.setState({ filter_user_type: e });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_user_type}
                                    ></Select>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_user_type: { value: "", label: "ทั้งหมด" },
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">รหัสผู้ใช้</th>
                                                <th className="text-center">รูปภาพ</th>
                                                <th className="text-left">ชื่อ-นามสกุล</th>
                                                <th className="text-left">สิทธิ์การใช้งาน</th>
                                                <th className="text-left">ประเภท</th>
                                                <th className="text-center">สถานะ</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={7} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.user_code}</td>
                                                    <td className="text-center">
                                                        <img className="wpx-30 hpx-30 rounded-circle" src={item.picture ? item.picture : User} style={{ objectFit: "cover" }} />
                                                    </td>
                                                    <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                                                    <td className="text-left">{item.role_data ? item.role_data.role_name : "-"}</td>
                                                    <td className="text-left">{item.user_type ? this.state.data_user_type.filter(e => e.value === item.user_type)[0].label : "-"}</td>
                                                    <td className="text-center">
                                                        {item.is_active ? (
                                                            <span className="bg-green rounded px-2 py-1 text-white">
                                                                ใช้งาน
                                                            </span>
                                                        ) : (<span className="bg-red rounded px-2 py-1 text-white">
                                                            ปิดการใช้งาน
                                                        </span>)}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <label className="wpx-30 hpx-30 bg-primary-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={async () => {
                                                                    this.setState({
                                                                        modal_password: true,
                                                                        id: item.user_id,
                                                                        user_code: item.user_code,
                                                                        user_type: this.state.data_user_type.filter(e => e.value === item.user_type)[0],
                                                                        role: this.state.data_role.filter(e => e.value === item.role_data.role_id)[0],
                                                                        full_name: item.full_name,
                                                                        address: item.address,
                                                                        phone: item.phone,
                                                                        email: item.email,
                                                                        is_active: item.is_active,
                                                                        picture: item.picture,
                                                                        username: item.username
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-primary pointer"
                                                                > {"\uf084"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={async () => {
                                                                    this.setState({
                                                                        modal_update: true,
                                                                        id: item.user_id,
                                                                        user_code: item.user_code,
                                                                        user_type: this.state.data_user_type.filter(e => e.value === item.user_type)[0],
                                                                        role: this.state.data_role.filter(e => e.value === item.role_data.role_id)[0],
                                                                        full_name: item.full_name,
                                                                        address: item.address,
                                                                        phone: item.phone,
                                                                        email: item.email,
                                                                        is_active: item.is_active,
                                                                        picture: item.picture,
                                                                        username: item.username
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer"
                                                                > {"\uf304"} </label>
                                                            </label>
                                                            {!item.is_active && (
                                                                <label className="wpx-30 hpx-30 bg-danger-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 delete"
                                                                    onClick={() => { this.setState({ modal_delete: true, id: item.user_id, full_name: item.full_name, }) }}>
                                                                    <label
                                                                        className="icon mx-2 text-danger pointer"
                                                                    > {"\uf1f8"} </label>
                                                                </label>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* create or update */}
                <Modal
                    show={this.state.modal_create || this.state.modal_update || this.state.modal_password}
                    onHide={() => { this.setState({ modal_create: false, modal_update: false, modal_password: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>{this.state.modal_create ? "เพิ่มผู้ใช้งาน" : this.state.modal_update ? "แก้ไขผู้ใช้งาน" : "แก้ไขรหัสผ่าน"}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {(this.state.modal_create || this.state.modal_update) && (
                            <>
                                {/* รูปโปรไฟล์ */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">รูปโปรไฟล์:</label>
                                    {this.state.picture === "" ? (
                                        <div
                                            className={this.state.over ? "wpx-100 hpx-100 bg-orange-light border-2 border-secondary rounded border-dash d-flex justify-content-center align-items-center" :
                                                "wpx-100 hpx-100 pointer bg-contain border-2 border-secondary rounded border-dash d-flex justify-content-center align-items-center"}
                                            onClick={() => { document.getElementById("file").click() }}
                                            onDrop={(e) => this.drop_file(e)}
                                            onDragOver={(e) => { this.setState({ over: true }); e.preventDefault() }}
                                            onDragLeave={(e) => { this.setState({ over: false }); e.preventDefault() }}
                                        >
                                            <div className="text-center pointer">
                                                <label className="icon pointer"> {"\uf0ee"} </label><br />
                                                อัปโหลด
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="card position-relative wpx-100">
                                            <img alt="รูปโปรไฟล์" src={this.state.picture.includes("https://") ? this.state.picture : "data:image/png;base64," + this.state.picture} className="wpx-100 hpx-100 rounded overflow-hidden"></img>
                                            <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.setState({ picture: "" }) }}>
                                                {"\uf00d"}
                                            </button>
                                        </div>
                                    )}

                                    <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e) }} />
                                    <div className="px-2">
                                        <label><label className="icon mr-2"> {"\uf05a"} </label> ภาพที่แนะนำคือ 360x360 พิกเซล และขนาดไม่เกิน 2MB</label>
                                    </div>
                                </div>
                                {/* รหัสผู้ใช้งาน */}
                                {this.state.modal_update && (
                                    <div className="w-100 d-flex align-items-center mb-3">
                                        <label className="wpx-140">รหัสผู้ใช้งาน:</label>
                                        <label className="wpx-140 text-secondary">{this.state.user_code}</label>
                                    </div>
                                )}
                                {/* ประเภท */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">ประเภท{required}:</label>
                                    <Select
                                        className="form-control validate select-search"
                                        styles={select_style}
                                        options={this.state.data_user_type.filter(item => item.label !== "ทั้งหมด")}
                                        onChange={(e) => {
                                            this.setState({ user_type: e });
                                        }}
                                        value={this.state.user_type}
                                    ></Select>
                                </div>
                                {/* บทบาท */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">บทบาท{required}:</label>
                                    <Select
                                        className="form-control validate select-search"
                                        styles={select_style}
                                        options={this.state.data_role}
                                        onChange={(e) => {
                                            this.setState({ role: e });
                                        }}
                                        value={this.state.role}
                                    ></Select>
                                </div>
                                {/* ชื่อ-นามสกุล */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">ชื่อ-นามสกุล{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ full_name: e.target.value })
                                        }}
                                        value={this.state.full_name}
                                    />
                                </div>
                                {/* ที่อยู่ */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">ที่อยู่:</label>
                                    <textarea
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ address: e.target.value })
                                        }}
                                        value={this.state.address}
                                    />
                                </div>
                                {/* เบอร์โทร */}
                                <div className="w-100 d-flex align-items-center mb-3">
                                    <label className="wpx-140">เบอร์โทร:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        maxLength={10}
                                        onChange={(e) => {
                                            if (!float(e.target.value)) {
                                                return
                                            }
                                            this.setState({ phone: e.target.value })
                                        }}
                                        value={this.state.phone}
                                    />
                                </div>
                            </>
                        )}
                        {/* ชื่อผู้ใช้ */}
                        {(this.state.modal_create || this.state.modal_password) && (
                            <div className="w-100 d-flex align-items-center mb-3">
                                <label className="wpx-140">ชื่อผู้ใช้{required}:</label>
                                <input
                                    className="form-control validate"
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                        if (!validateENNumber(e.target.value) && e.target.value !== "") {
                                            return
                                        }
                                        this.setState({ username: e.target.value })
                                    }}
                                    value={this.state.username}
                                    readOnly={this.state.modal_password}
                                />
                            </div>
                        )}
                        {/* รหัสผ่าน */}
                        {(this.state.modal_create || this.state.modal_password) && (
                            <div className="w-100 d-flex align-items-center mb-3">
                                <label className="wpx-140">รหัสผ่าน{required}:</label>
                                <input
                                    className="form-control validate"
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                        this.setState({ password: e.target.value })
                                    }}
                                    value={this.state.password}
                                />
                            </div>
                        )}
                        {/* สถานะ */}
                        {this.state.modal_update && (
                            <div className="w-100 d-flex align-items-center mb-3">
                                <label className="wpx-140">สถานะ:</label>
                                <Switch onChange={(e) => {
                                    this.setState({
                                        is_active: e,
                                    })
                                }} checked={this.state.is_active} />
                            </div>
                        )}
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false, modal_password: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        if (this.state.modal_create) {
                                            this.Create();
                                        } else {
                                            this.Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบข้อมูล</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                ท่านแน่ในว่าต้องการลบผู้ใช้งาน “{this.state.full_name}” หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
