import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarCustomer.js";
import {
    tokens,
    alert_url,
    alert,
    GET,
    POST,
    format_date,
    float,
    validate,
    required,
    DatePickerTH,
    getStorage,
    success_url,
    locale,
    toFixed,
    validateEmail,
    days_between
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import CAR from "../../assets/images/bike.png"
import EMPTY from "../../assets/images/empty.png";

export default class CreateBooking extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_detail: false,
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_car: false,

            page: 1,
            loading: false,

            data_bike: [],
            data_equipment: [],
            selected_bike: null,

            days: 0,

            search_bike: "",

            id_card: "",
            full_name: "",
            phone: "",
            email: "",

            pick_up_date: this.props.location.query.pick_up_date,
            pick_up_time: this.props.location.query.pick_up_time,
            pick_up_location: this.props.location.query.pick_up_location,
            return_date: this.props.location.query.return_date,
            return_time: this.props.location.query.return_time,
            return_location: this.props.location.query.return_location,

            close: false,
            end_date_booking_mc: "",
            start_date_booking_mc: "",
            total_price: 0,
            hours: 0,
            minutes: 0,
            total_price_equipment: 0,
            delivery_price: 0,
        }
    }
    async componentDidMount() {
        let user = getStorage("user");
        this.setState({
            id_card: user.id_card,
            full_name: user.full_name,
            phone: user.phone,
            email: user.email,
        })
        if (user.member_status === 0) {
            alert_url("/user/list-booking", "warning", "แจ้งเตือน", "ไม่สามารถทำการจองได้เนื่องจากลูกค้าติดแบล็กลิสต์");
            return
        }
        await this.GetBikeAll();
        await this.GetEquipmentAll();
        let days = days_between(
            new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
            this.state.pick_up_time,
            this.state.return_time
        )
        let hours_price = 0;
        if (days.day === 0) {
            hours_price = this.state.selected_bike.daily_price;
        } else {
            if (days.hour === 0 && days.minute >= 0) {
                //ไม่ต้องหัก 
            } else if (days.hour === 1 && days.minute >= 0) {
                hours_price = 50; //หัก 50 บาท
            } else if (days.hour === 2 && days.minute >= 0) {
                hours_price = 100; //หัก 100 บาท
            } else if (days.hour === 3 && days.minute >= 0) {
                hours_price = 150; //หัก 150 บาท
            } else if (days.hour >= 4) {
                hours_price = this.state.selected_bike.daily_price;//หัก daily_price
            }
        }

        this.setState({
            days: days.day,
            hours: days.hour,
            minutes: days.minute,
            total_price: (this.state.selected_bike.daily_price * days.day) + hours_price
        });
        await this.GetSettingAll();
    }
    GetEquipmentAll = async () => {
        let result = await GET(tokens, "v1/TH/equipment/filter-search/page/0", null);
        if (result && result.status) {
            this.setState({ data_equipment: result.data.data.map(item => { return { ...item, equipment_qty: 0 } }) })
        }
    }
    GetSettingAll = async () => {
        let result = await GET(tokens, "v1/TH/company/detail", {});
        if (result && result.status) {
            let data = result.data.data;
            if (new Date(data.start_date_booking_mc).getTime() <= new Date().getTime() && new Date().getTime() <= new Date(data.end_date_booking_mc).getTime()) {
                this.setState({
                    close: true,
                    end_date_booking_mc: data.end_date_booking_mc,
                    start_date_booking_mc: data.start_date_booking_mc,
                })
            }
        }
    }
    GetBikeAll = async () => {
        let data = {
            start_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", 'en') + " " + this.state.pick_up_time : null,
            end_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", 'en') + " " + this.state.return_time : null,
            search: ""
        }
        let result = await GET(tokens, "v1/TH/bike-manage/filter-search-public/page/0", data);
        if (result && result.status) {
            this.setState({ data_bike: result.data.data });
            if (result.data.data) {
                this.setState({ selected_bike: result.data.data.filter(item => item.brand_id === this.props.location.query.brand_id && item.model_id === this.props.location.query.model_id)[0] })
            }
        }
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_import: false,

            page: 1,
            loading: false,

        })
    }
    Create = async () => {
        if (validate(this.state, ["id_card", "full_name", "phone", "selected_bike", "pick_up_location", "return_location"])) { return };
        if (this.state.email !== "" && !validateEmail(this.state.email)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        }
        this.setState({ loading: true });
        let data = {
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            email: this.state.email,
            phone: this.state.phone,
            brand_id: this.state.selected_bike.brand_id,
            model_id: this.state.selected_bike.model_id,
            pick_up_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : "",
            pick_up_time: this.state.pick_up_time,
            pick_up_location: this.state.pick_up_location,
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : "",
            return_time: this.state.return_time,
            return_location: this.state.return_location,
            daily_price: this.state.selected_bike.daily_price,
            title_picture: this.state.selected_bike ? this.state.selected_bike.title_picture : "",
            traffic_ticket_price: 0,
            delivery_price: Number(this.state.delivery_price),
            equipment_fee: Number(this.state.total_price_equipment),
            equipment_list: this.state.data_equipment.filter(e => e.equipment_qty > 0)
        }
        let result = await POST(tokens, "v1/TH/booking/create", data);
        if (result && result.status) {
            success_url("/user/list-booking")
        }
        this.setState({ loading: false });

    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1={global[locale]["หน้าหลัก"]} page2={global[locale]["เพิ่มการจอง"]} />
                {/* BODY */}
                <div className="body background3 min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3 text-white"><b>{global[locale]["เพิ่มการจอง"]}</b></h4>
                            <button className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/user/choose-car" }}>
                                <label className="icon mr-2" > {"\uf060"} </label>
                                {global[locale]["ย้อนกลับ"]}
                            </button>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-6 mb-3">
                                <div className="card border-0 mb-3">
                                    <div className="card-header bg-contain pb-0">
                                        <h5 className="mb-3"><b>{global[locale]["ข้อมูลผู้จอง"]}</b></h5>
                                    </div>
                                    <div className="card-body p-3">
                                        <div className="row mx-0">
                                            <div className="col-12 d-flex align-items-center mb-3">
                                                <label className="wpx-150">{global[locale]["รหัสบัตรประชาชนหรือพาสปอร์ต"]} : </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={global[locale]["รหัสบัตรประชาชนหรือพาสปอร์ต"]}
                                                    maxLength={13}
                                                    onChange={(e) => {
                                                        this.setState({ id_card: e.target.value })
                                                    }}
                                                    value={this.state.id_card}
                                                />
                                            </div>
                                            <div className="col-12 d-flex align-items-center mb-3">
                                                <label className="wpx-150">{global[locale]["ชื่อ-นามสกุล"]} : </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={global[locale]["ชื่อ-นามสกุล"]}
                                                    onChange={(e) => {
                                                        this.setState({ full_name: e.target.value })

                                                    }}
                                                    value={this.state.full_name}
                                                />
                                            </div>
                                            <div className="col-12 d-flex align-items-center mb-3">
                                                <label className="wpx-150">{global[locale]["เบอร์โทรศัพท์"]} : </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={global[locale]["เบอร์โทรศัพท์"]}
                                                    onChange={(e) => {
                                                        this.setState({ phone: e.target.value })
                                                    }}
                                                    value={this.state.phone}
                                                />
                                            </div>
                                            <div className="col-12 d-flex align-items-center mb-3">
                                                <label className="wpx-150">{global[locale]["อีเมล"]} : </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={global[locale]["อีเมล"]}
                                                    onChange={(e) => {
                                                        this.setState({ email: e.target.value })
                                                    }}
                                                    value={this.state.email}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card border-0">
                                    <div className="card-header bg-contain pb-0">
                                        <h5 className="mb-3"><b>{global[locale]["อุปกรณ์"]}</b></h5>
                                    </div>
                                    <div className="card-body p-3">

                                        <div className="w-100 py-3 mb-3">
                                            <div className="row mx-0">
                                                {this.state.data_equipment && this.state.data_equipment.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 col-xxl-6 mb-2 px-1">
                                                        <div className="card bg-white w-100 p-2">
                                                            <div className="d-flex row mx-0 align-items-center w-100">
                                                                <div className="d-flex">
                                                                    <img src={item.picture} className="wpx-90 hpx-90 rounded" />
                                                                    <div>
                                                                        <h5 className="ml-3" style={{ minWidth: 190 }}>{item.equipment_name}</h5>
                                                                        <label className="ml-3 text-orange">{global[locale]["ราคา"]} {item.equipment_price} {global[locale]["บาท"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-auto card bg-contain border-0 p-1">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="d-flex align-items-center justify-content-center mr-3">
                                                                            <h1 className={item.equipment_qty === 0 ? "text-right text-secondary mb-1" : "text-right text-orange mb-1"} style={{ width: 50 }}><b>{item.equipment_qty}</b></h1>
                                                                            <h6 className={item.equipment_qty === 0 ? "text-secondary mb-0 ml-2" : "text-orange mb-0 ml-2"}>{global[locale]["ชิ้น"]}</h6>
                                                                        </div>
                                                                        {!this.state.readonly && (
                                                                            <div className="d-flex">
                                                                                <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                    onClick={() => {
                                                                                        let data_equipment = this.state.data_equipment;
                                                                                        data_equipment[index].equipment_qty -= data_equipment[index].equipment_qty === 0 ? 0 : 1;

                                                                                        let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                        for (let data of data_equipment) {
                                                                                            total_qty_equipment += data.equipment_qty;
                                                                                            total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                        }
                                                                                        this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                    }}>-</button>
                                                                                <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                    onClick={() => {
                                                                                        let data_equipment = this.state.data_equipment;
                                                                                        data_equipment[index].equipment_qty += 1;

                                                                                        let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                        for (let data of data_equipment) {
                                                                                            total_qty_equipment += data.equipment_qty;
                                                                                            total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                        }
                                                                                        this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                    }}>+</button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 mb-3">
                                <div className="card border-0 mb-3">
                                    <div className="card-header bg-contain pb-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="mb-3"><b>{global[locale]["ข้อมูลรถ"]}</b></h5>
                                            {this.state.selected_bike && (
                                                <button className="btn btn-outline-orange px-3" onClick={() => { this.setState({ modal_car: true, search_bike: "" }) }}>
                                                    {global[locale]["แก้ไข"]}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="card-body p-3">
                                        {!this.state.selected_bike ? (
                                            <div className="w-100">
                                                <div className="d-flex align-items-end position-relative mx-auto" style={{ height: 300, width: 300 }}>
                                                    <img src={EMPTY} className="img-responsive position-absolute" style={{ width: 300, zIndex: 999, opacity: 0.08, objectFit: "contain" }} />
                                                    <div className="w-100 text-center pb-5" style={{ zIndex: 1000 }}>
                                                        <h4>** {global[locale]["ยังไม่ได้เลือกรถ"]} **</h4>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center mb-3">
                                                    <button
                                                        className="btn btn-orange wpx-200"
                                                        onClick={() => {
                                                            this.setState({
                                                                modal_car: true,
                                                                pick_up_date: this.state.pick_up_date ? this.state.pick_up_date : new Date(),
                                                                pick_up_time: this.state.pick_up_time ? this.state.pick_up_time : format_date(new Date(), "hh:mm"),
                                                                return_date: this.state.return_date ? this.state.return_date : new Date(),
                                                                return_time: this.state.return_time ? this.state.return_time : format_date(new Date(), "hh:mm"),
                                                                search_bike: ""
                                                            });
                                                            setTimeout(() => {
                                                                this.GetBikeAll();
                                                            }, 10);
                                                        }}>{global[locale]["เลือกรถ"]}</button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="row">
                                                <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3">
                                                    <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-responsive w-100 rounded" style={{ height: "100%", objectFit: "contain" }} />
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                                    <h6><b>{global[locale]["รายละเอียดรถ"]}</b></h6>
                                                    <div className="row">
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">{global[locale]["ยี่ห้อ"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                                        </div>
                                                        <div className="col-6 d-flex mb-2">
                                                            <b className="wpx-50">{global[locale]["ชื่อรุ่น"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-end">
                                                            <h4 className="text-orange">{this.state.selected_bike ? this.state.selected_bike.daily_price : "-"}  {global[locale]["บาท/วัน"]}</h4>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <h6><b>{global[locale]["วันที่รับ / คืนรถ"]}</b></h6>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="w-100">
                                                            <b className="wpx-40">{global[locale]["วันที่รับรถ"]}</b>
                                                            <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                            <b className="my-2">{global[locale]["สถานที่รับรถ"]}{required}</b>
                                                            <input
                                                                type="text"
                                                                className="form-control w-100 mb-2"
                                                                placeholder="เช่น สนามบินเชียงใหม่"
                                                                onChange={(e) => { this.setState({ pick_up_location: e.target.value }); }}
                                                                value={this.state.pick_up_location}
                                                                readOnly={this.state.modal_detail || this.state.booking_status > 2} />
                                                        </div>
                                                        <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                            <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                        </div>
                                                        <div className="w-100">
                                                            <b className="wpx-40">{global[locale]["วันที่คืนรถ"]}</b>
                                                            <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                            <b className="my-2">{global[locale]["สถานที่คืนรถ"]}{required}</b>
                                                            <input
                                                                type="text"
                                                                className="form-control w-100 mb-2"
                                                                placeholder="เช่น สนามบินเชียงใหม่"
                                                                onChange={(e) => { this.setState({ return_location: e.target.value }); }}
                                                                value={this.state.return_location}
                                                                readOnly={this.state.modal_detail || this.state.booking_status > 2} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="card border-0 mb-3 bg-white">
                                    <div className="card-body p-3 text-orange">
                                        <div className="d-flex align-items-center justify-content-end mb-2">
                                            <label className="mb-0 mr-2">{global[locale]["ค่าบริการรับส่ง"]}</label>
                                            <input
                                                type="text"
                                                className="form-control wpx-250 text-end"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return
                                                    }
                                                    this.setState({ delivery_price: e.target.value });
                                                }}
                                                value={this.state.delivery_price}
                                                readOnly={this.state.booking_status > 2} />
                                            <label className="mb-0 ml-2">{global[locale]["บาท"]}</label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end mb-2">
                                            <label className="mb-0 mr-2">{global[locale]["ค่าอุปกรณ์"]}</label>
                                            <h3 className="mb-0"><b>{toFixed(this.state.total_price_equipment)}</b></h3>
                                            <label className="mb-0 ml-2">{global[locale]["บาท"]}</label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end mb-2">
                                            <label className="mb-0 mr-2">{global[locale]["ค่าเช่า"]}</label>
                                            <h3 className="mb-0"><b>{toFixed(this.state.total_price)}</b></h3>
                                            <label className="mb-0 ml-2">{global[locale]["บาท"]}</label>
                                        </div>
                                        <div className=" d-flex align-items-center justify-content-between">
                                            <div className="d-flex">
                                                <div className="text-center mx-2">
                                                    <label className="text-orange">{global[locale]["จำนวน (วัน)"]}</label>
                                                    <h3 className="text-orange"><b>{this.state.days}</b></h3>
                                                </div>
                                                <div className="text-center mx-2">
                                                    <label className="text-orange">{global[locale]["จำนวน (ชั่วโมง)"]}</label>
                                                    <h3 className="text-orange"><b>{this.state.hours}</b></h3>
                                                </div>
                                                <div className="text-center mx-2">
                                                    <label className="text-orange">{global[locale]["จำนวน (นาที)"]}</label>
                                                    <h3 className="text-orange"><b>{this.state.minutes}</b></h3>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <h6 className="text-orange mb-0 mr-3"><b>{global[locale]["ยอดชำระเงินทั้งหมด"]}</b></h6>

                                                <h6 className="text-orange mb-0"><b className="d-flex align-items-center"> <h3 className="mb-0 mr-2"><b>{toFixed(Number(this.state.total_price) + Number(this.state.total_price_equipment) + Number(this.state.delivery_price))}</b></h3> {global[locale]["บาท"]}</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100">
                                    {!this.state.close ? (
                                        <button className="btn btn-orange w-100" onClick={() => { this.Create() }}>{global[locale]["จอง"]}</button>
                                    ) : (<span className="text-danger add">{global[locale]["ไม่สามารถจองได้เนื่องจากระบบ ปิดการจองรถ ตั้งแต่วันที่"]} {format_date(this.state.start_date_booking_mc)} - {format_date(this.state.end_date_booking_mc)}</span>)}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* car */}
                <Modal show={this.state.modal_car} onHide={() => {
                    this.setState({ modal_car: false });
                    if (this.state.type === "create") {
                        this.setState({ modal_create: true })
                    } else {
                        this.setState({ modal_update: true })
                    }
                }} size="lg">
                    <Modal.Body>
                        <div className="w-100 d-flex justify-content-between mb-2">
                            <h3><b>{global[locale]["เลือกรถ"]}</b></h3>
                            <label className="icon text-24 text-danger pointer px-3" onClick={() => {
                                this.setState({ modal_car: false });
                                if (this.state.type === "create") {
                                    this.setState({ modal_create: true })
                                } else {
                                    this.setState({ modal_update: true })
                                }
                            }}>{"\uf00d"}</label>
                        </div>
                        {/* <hr className="my-2" /> */}
                        <div className="w-100">
                            {/* ค้นหา */}
                            <div className="row bg-orange mb-3">
                                <div className="col-12 p-3 mx-auto">
                                    <div className="card border-0 px-4 py-2 bg-white shadow">
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">{global[locale]["วันที่รับรถ"]}{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ pick_up_date: e });
                                                        if (Number(format_date(e, "yyyymmdd")) >= Number(format_date(this.state.return_date, "yyyymmdd"))) {
                                                            this.setState({ return_date: format_date(new Date(format_date(e, "yyyy,mm,dd")).setDate(new Date(format_date(e, "yyyy,mm,dd")).getDate() + 1), "yyyy-mm-dd", "en") });
                                                        }
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.pick_up_date}
                                                    clearable={false}
                                                    min={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">{global[locale]["เวลารับรถ"]}{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => { this.setState({ pick_up_time: e.target.value }); }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.pick_up_time} />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">{global[locale]["วันที่คืนรถ"]}{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ return_date: e });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.return_date}
                                                    clearable={false}
                                                    min={new Date(this.state.pick_up_date).setDate(new Date(this.state.pick_up_date).getDate() - 1)}

                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">{global[locale]["เวลาคืนรถ"]}{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => { this.setState({ return_time: e.target.value }); }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.return_time} />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 px-1">
                                                <b className="text-white mb-2">.</b>
                                                <button className="btn btn-orange w-100 mb-2" onClick={() => {
                                                    this.GetBikeAll();
                                                    if (this.state.selected_bike) {
                                                        let days = days_between(
                                                            new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
                                                            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                            this.state.pick_up_time,
                                                            this.state.return_time
                                                        )
                                                        let hours_price = 0;
                                                        if (days.day === 0) {
                                                            hours_price = this.state.selected_bike.daily_price;
                                                        } else {
                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                //ไม่ต้องหัก 
                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                hours_price = 50; //หัก 50 บาท
                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                hours_price = 100; //หัก 100 บาท
                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                hours_price = 150; //หัก 150 บาท
                                                            } else if (days.hour >= 4) {
                                                                hours_price = this.state.selected_bike.daily_price;//หัก daily_price
                                                            }
                                                        }

                                                        this.setState({
                                                            modal_car: false,
                                                            days: days.day,
                                                            hours: days.hour,
                                                            minutes: days.minute,
                                                            total_price: (this.state.selected_bike.daily_price * days.day) + hours_price
                                                        });
                                                        if (this.state.type === "create") {
                                                            this.setState({ modal_create: true })
                                                        } else {
                                                            this.setState({ modal_update: true })
                                                        }
                                                    }
                                                }}>
                                                    {global[locale]["ค้นหา"]}
                                                    <label className="icon ml-2">{"\uf002"}</label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* รายการรถ */}
                            <div className="row">
                                <div className="col-12 pb-5 px-4">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <h4><b>{global[locale]["ผลการค้นหา: รถว่างทั้งหมด"]}</b></h4>
                                            <label className="text-secondary mb-3">{global[locale]["พบรถว่าง"]} {this.state.data_bike.length} {global[locale]["คัน"]}</label>
                                        </div>
                                        <div className="wpx-250 pb-2">
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-2"
                                                placeholder="เช่น ยี่ห้อ,รุ่น"
                                                onChange={(e) => { this.setState({ search_bike: e.target.value }); }}
                                                value={this.state.search_bike} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.data_bike && this.state.data_bike.filter(item => JSON.stringify(item).toUpperCase().includes(this.state.search_bike.toUpperCase()) || this.state.search_bike === "").map((item, index) => (
                                            <div className="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 mb-3 px-1">
                                                <div className="card bg-white shadow overflow-hidden border-0 position-relative"
                                                    onClick={() => {
                                                        let days = days_between(
                                                            new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
                                                            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                            this.state.pick_up_time,
                                                            this.state.return_time
                                                        )
                                                        let hours_price = 0;
                                                        if (days.day === 0) {
                                                            hours_price = item.daily_price;
                                                        } else {
                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                //ไม่ต้องหัก 
                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                hours_price = 50; //หัก 50 บาท
                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                hours_price = 100; //หัก 100 บาท
                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                hours_price = 150; //หัก 150 บาท
                                                            } else if (days.hour >= 4) {
                                                                hours_price = item.daily_price;//หัก daily_price
                                                            }
                                                        }

                                                        this.setState({
                                                            modal_car: false,
                                                            selected_bike: item,
                                                            days: days.day,
                                                            hours: days.hour,
                                                            minutes: days.minute,
                                                            total_price: (item.daily_price * days.day) + hours_price
                                                        });
                                                        if (this.state.type === "create") {
                                                            this.setState({ modal_create: true })
                                                        } else {
                                                            this.setState({ modal_update: true })
                                                        }
                                                    }}
                                                // onMouseOver={() => { this.setState({ product_index: index }) }}
                                                // onMouseLeave={() => { this.setState({ product_index: -1 }) }}
                                                >
                                                    <img className="d-none d-sm-block" src={item.title_picture ? item.title_picture : CAR} style={{ height: 240, objectFit: "cover" }} />
                                                    <img className="d-block d-sm-none" src={item.title_picture ? item.title_picture : CAR} style={{ height: 180, objectFit: "cover" }} />
                                                    <div className="p-2">
                                                        <div style={{ height: 23, overflow: "hidden" }}>
                                                            <h6 className="mb-1 d-block d-sm-none"><b>{item.brand_name}</b> {item.model_name}</h6>
                                                            <h5 className="mb-1 d-none d-sm-block"><b>{item.brand_name}</b> {item.model_name}</h5>
                                                        </div>
                                                        <hr className="my-2" />
                                                        <div className="d-flex row justify-content-between mx-0">
                                                            <div className="p-0 d-flex align-items-end">
                                                                <h4 className="mb-0 text-orange"><b>฿{toFixed(item.daily_price)}</b></h4>
                                                                <small className="text-secondary ml-2">{global[locale]["ต่อวัน"]}</small>
                                                            </div>
                                                            <div className="p-0 d-flex align-items-end">
                                                                <small className="text-secondary ml-2">{global[locale]["คงเหลือ"]} {item.balance_app} {global[locale]["คัน"]}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {index === this.state.product_index && (
                                                        <div
                                                            className="fade-in-product rounded pointer position-absolute d-flex align-items-center justify-content-center"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                left: 0,
                                                                top: 0,
                                                                borderWidth: 4,
                                                                borderStyle: "solid",
                                                                borderColor: "#fd7e14"
                                                            }}>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
