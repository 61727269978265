import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    PUT,
    success_url
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class ListCarWarehouse extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_update: false,

            loading: false,

            data: null,
            data_brand: [],
            data_model: [],

            filter_search: "",
            filter_brand_id: "",
            filter_model_id: "",

            update: false

        }
    }
    async componentDidMount() {
        await this.GetAll();
        await this.GetBrandAll();
        await this.GetModelAll();
    }
    GetBrandAll = async () => {
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bike-brand/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_brand: result.data.data.map(item => { return { label: item.brand_name, value: item.brand_id } }) })
        }
    }
    GetModelAll = async () => {
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bike-model/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_model: result.data.data.map(item => { return { label: item.model_data.model_name, value: item.model_data.model_id, brand_name: item.brand_name, brand_id: item.brand_id } }) })
        }
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            brand_id: this.state.filter_brand_id ? this.state.filter_brand_id.value : "",
            model_id: this.state.filter_model_id ? this.state.filter_model_id.value : "",
        }
        let result = await GET(tokens, "v1/TH/bike-warehouse/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        this.setState({ loading: true });
        let data = {
            warehouse_list: this.state.data ? this.state.data.data.map(item => {
                return {
                    warehouse_id: item.warehouse_id,
                    total_bike: item.total_bike,
                    booking_store: item.booking_store,
                    booking_app: item.booking_app
                }
            }) : []
        }
        let result = await PUT(tokens, "v1/TH/bike-warehouse/update", data);
        if (result && result.status) {
            success_url("/master/list-car-warehouse")
        }
        this.setState({ loading: false });

    }
    clear_form() {
        this.setState({
            loading: false,

        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="คลังรถ" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>คลังรถ</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                {this.state.update ? (
                                    <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.setState({ modal_update: true }) }}>
                                        บันทึก
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3 edit" onClick={() => { this.setState({ update: true }) }}>
                                        แก้ไข
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    <Select
                                        className="form-control select-search bg-white wpx-200 border-0 mx-1 mb-2"
                                        styles={select_style}
                                        options={this.state.data_brand}
                                        onChange={(e) => {
                                            this.setState({ filter_brand_id: e, page: 1 });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_brand_id}
                                    ></Select>
                                    <Select
                                        className="form-control select-search bg-white wpx-200 border-0 mx-1 mb-2"
                                        styles={select_style}
                                        options={this.state.data_model}
                                        onChange={(e) => {
                                            this.setState({ filter_model_id: e, page: 1 });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_model_id}
                                    ></Select>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_brand_id: "",
                                                filter_model_id: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ยี่ห้อรถ</th>
                                                <th className="text-center">รุ่นรถ</th>
                                                <th className="text-center">จำนวนรถทั้งหมด</th>
                                                <th className="text-center">จำนวนการเช่า</th>
                                                <th className="text-center">จำนวนการจอง</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={5} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.brand_name ? item.brand_name : "-"}</td>
                                                    <td className="text-center">{item.model_name ? item.model_name : "-"}
                                                    </td>
                                                    <td className="text-center">{item.total_bike ? item.total_bike : "0"}</td>
                                                    <td className="text-center">
                                                        {this.state.update ? (
                                                            <div className="d-flex justify-content-center">
                                                                <label className={"wpx-20 hpx-20 rounded-circle text-white " + (item.booking_store === 0 ? "bg-gray-300" : "bg-orange pointer")}
                                                                    onClick={() => {
                                                                        let data = this.state.data;
                                                                        if (data.data[index].booking_store !== 0) {
                                                                            data.data[index].booking_store -= 1;
                                                                            data.data[index].booking_app += 1;
                                                                        }
                                                                        this.setState({ data: data })
                                                                    }}>
                                                                    -
                                                                </label>
                                                                <label className="wpx-30 text-center">{item.booking_store ? item.booking_store : "0"}</label>
                                                                <label className={"wpx-20 hpx-20 rounded-circle text-white " + (item.booking_store === item.total_bike ? "bg-gray-300" : "bg-orange pointer")}
                                                                    onClick={() => {
                                                                        let data = this.state.data;
                                                                        if (data.data[index].booking_store !== item.total_bike) {
                                                                            if (data.data[index].booking_app !== 0 && data.data[index].booking_app + data.data[index].booking_store === item.total_bike) {
                                                                                data.data[index].booking_app -= 1;
                                                                            }
                                                                            data.data[index].booking_store += 1;
                                                                        }
                                                                        this.setState({ data: data })
                                                                    }}>
                                                                    +
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <label className="wpx-30 text-center">{item.booking_store ? item.booking_store : "0"}</label>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {this.state.update ? (
                                                            <div className="d-flex justify-content-center">
                                                                <label className={"wpx-20 hpx-20 rounded-circle text-white " + (item.booking_app === 0 ? "bg-gray-300" : "bg-orange pointer")}
                                                                    onClick={() => {
                                                                        let data = this.state.data;
                                                                        if (data.data[index].booking_app !== 0) {
                                                                            data.data[index].booking_app -= 1;
                                                                            data.data[index].booking_store += 1;
                                                                        }
                                                                        this.setState({ data: data })
                                                                    }}>
                                                                    -
                                                                </label>
                                                                <label className="wpx-30 text-center">{item.booking_app ? item.booking_app : "0"}</label>
                                                                <label className={"wpx-20 hpx-20 rounded-circle text-white " + (item.booking_app === item.total_bike ? "bg-gray-300" : "bg-orange pointer")}
                                                                    onClick={() => {
                                                                        let data = this.state.data;
                                                                        if (data.data[index].booking_app !== item.total_bike) {
                                                                            if (data.data[index].booking_store !== 0 && data.data[index].booking_app + data.data[index].booking_store === item.total_bike) {
                                                                                data.data[index].booking_store -= 1;
                                                                            }
                                                                            data.data[index].booking_app += 1;
                                                                        }
                                                                        this.setState({ data: data })
                                                                    }}>
                                                                    +
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <label className="wpx-30 text-center">{item.booking_app ? item.booking_app : "0"}</label>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL */}
                {/* update */}
                <Modal show={this.state.modal_update} onHide={() => { this.setState({ modal_update: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการแก้ไขข้อมูล</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการแก้ไขข้อมูลดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_update: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-orange w-100" onClick={() => { this.Update() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
