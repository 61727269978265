import React, { Component } from "react";
import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin";
import {
    tokens,
    success_url,
    GET,
    POST,
    PUT,
    validate,
    required,
} from "../../../components/CustomComponent.js";
export default class CreateRoleUserScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            role_name: "",
            role_description: "",
            role_detail: global.role
        };
    }
    componentDidMount() {
        if (this.props.location.query.id) {
            this.GetById(this.props.location.query.id)
        }
    }
    GetById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/role-manage/detail/" + id, null);
        if (result && result.status) {
            let role = this.state.role_detail;
            for (let data of result.data.data.permission) {
                role.find(e => {
                    if (e.menu_name === data.menu_name) {
                        e.can_view = data.can_view;
                        e.can_add = data.can_add;
                        e.can_edit = data.can_edit;
                        e.can_delete = data.can_delete;
                        e.can_download = data.can_download;
                        e.can_approved = data.can_approved;
                    }
                })
            }
            this.setState({
                role_name: result.data.data.role_name,
                role_description: result.data.data.role_description,
                role_detail: role,
            })
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        if (validate(this.state, ["role_name"])) { return };
        this.setState({ loading: true });
        let data = {
            role_name: this.state.role_name,
            role_description: this.state.role_description,
            permission: this.state.role_detail.filter(e => !e.title).map((item) => {
                return {
                    menu_name: item.menu_name,
                    can_view: item.can_view,
                    can_add: item.can_add,
                    can_edit: item.can_edit,
                    can_delete: item.can_delete,
                    can_download: item.can_download,
                    can_approved: item.can_approved,
                };
            }),
        }
        let result = await POST(tokens, "v1/TH/role-manage/create", data);
        if (result && result.status) {
            success_url("/master/list-role");
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        if (validate(this.state, ["role_name"])) { return };
        this.setState({ loading: true });
        let data = {
            role_id: this.props.location.query.id,
            role_name: this.state.role_name,
            role_description: this.state.role_description,
            permission: this.state.role_detail.filter(e => !e.title).map((item) => {
                return {
                    menu_name: item.menu_name,
                    can_view: item.can_view,
                    can_add: item.can_add,
                    can_edit: item.can_edit,
                    can_delete: item.can_delete,
                    can_download: item.can_download,
                    can_approved: item.can_approved,
                };
            }),
        }
        let result = await PUT(tokens, "v1/TH/role-manage/update", data);
        if (result && result.status) {
            success_url("/master/list-role");
        }
        this.setState({ loading: false });
    }
    clear_form() {
        this.setState({
            loading: false,
            role_name: "",
            role_description: "",
            permission: global.role_detail
        });
    }
    select_role = (type, index) => {
        let role = this.state.role_detail;
        if (type === "all") {
            role[index]["can_view"] = !role[index]["can_view"];
            role[index]["can_add"] = !role[index]["can_add"];
            role[index]["can_edit"] = !role[index]["can_edit"];
            role[index]["can_delete"] = !role[index]["can_delete"];
            role[index]["can_download"] = !role[index]["can_download"];
            role[index]["can_approved"] = !role[index]["can_approved"];
        } else {
            role[index][type] = !role[index][type];
        }
        this.setState({ role_detail: role })
    }
    select_all_role = (type, checked) => {
        let role = this.state.role_detail;
        if (type === "all") {
            for (let r of role) {
                if (r) {
                    r["can_view"] = checked;
                    r["can_add"] = checked;
                    r["can_edit"] = checked;
                    r["can_delete"] = checked;
                    r["can_download"] = checked;
                    r["can_approved"] = checked;
                }
            }
        } else {
            for (let r of role) {
                if (r) {
                    r[type] = checked;
                }
            }
        }
        this.setState({ role_detail: role })
    }
    render() {
        return (
            <div className="full bg-contain fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ข้อมูลผู้ใช้" page3="รายชื่อผู้ใช้ทั้งหมด" />
                {/* BODY */}
                <div className="body mb-4 pb-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-xl-10 p-3 mx-auto">
                            {/* หัวข้อ */}
                            <div className="d-flex row mx-0 align-items-center justify-content-between test">
                                <h4 className="mb-3"><b>เพิ่มบทบาทผู้ใช้งาน</b></h4>
                                <button className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/master/list-role" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                            </div>
                            {/* กรอกข้อมูลด้านบน */}
                            <div className="w-100 p-3">
                                <div className="card rounded border-0">
                                    <div className="card-body">
                                        {/* ชื่อบทบาท */}
                                        <div className="d-flex row mx-0 align-items-center mb-3">
                                            <div className="wpx-120">
                                                <label>ชื่อบทบาท{required}:</label>
                                            </div>
                                            <div className="col-12 col-md-8 col-lg-6 col-xl-6">
                                                <input
                                                    type="text"
                                                    className="form-control validate"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => { this.setState({ role_name: e.target.value }); }}
                                                    value={this.state.role_name}
                                                ></input>
                                            </div>
                                        </div>
                                        {/* รายละเอียด */}
                                        <div className="d-flex row mx-0 align-items-center">
                                            <div className="wpx-120">
                                                <label>รายละเอียด:</label>
                                            </div>
                                            <div className="col-12 col-md-8 col-lg-6 col-xl-6">
                                                <textarea
                                                    className="form-control validate"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => { this.setState({ role_description: e.target.value }); }}
                                                    value={this.state.role_description}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ตาราง */}
                            <div className="w-100 p-3" style={{ marginBottom: 100 }}>
                                <div className="card rounded border-0">
                                    <div className="card-bodyp-0 overflow-hidden">
                                        <div className="table-responsive">
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr className="bg-orange text-white">
                                                        <th className="text-left">เมนูจัดการ</th>
                                                        <th className="text-center wpx-70">
                                                            <label>ทั้งหมด</label><br />
                                                            <input
                                                                className="hpx-20"
                                                                type="checkbox"
                                                                onChange={(e) => { this.select_all_role("all", e.target.checked) }}
                                                                checked={this.state.role_detail.filter(e => e["can_view"] === false || e["can_add"] === false || e["can_edit"] === false || e["can_delete"] === false || e["can_download"] === false || e["can_approved"] === false).length === 0} />
                                                        </th>
                                                        <th className="text-center wpx-70">
                                                            <label>ดู</label><br />
                                                            <input className="hpx-20" type="checkbox" onChange={(e) => { this.select_all_role("can_view", e.target.checked) }} checked={this.state.role_detail.filter(e => e && e["can_view"] === false).length === 0} />
                                                        </th>
                                                        <th className="text-center wpx-70">
                                                            <label>เพิ่ม</label><br />
                                                            <input className="hpx-20" type="checkbox" onChange={(e) => { this.select_all_role("can_add", e.target.checked) }} checked={this.state.role_detail.filter(e => e && e["can_add"] === false).length === 0} />
                                                        </th>
                                                        <th className="text-center wpx-70">
                                                            <label>แก้ไข</label><br />
                                                            <input className="hpx-20" type="checkbox" onChange={(e) => { this.select_all_role("can_edit", e.target.checked) }} checked={this.state.role_detail.filter(e => e && e["can_edit"] === false).length === 0} />
                                                        </th>
                                                        <th className="text-center wpx-70">
                                                            <label>ลบ</label><br />
                                                            <input className="hpx-20" type="checkbox" onChange={(e) => { this.select_all_role("can_delete", e.target.checked) }} checked={this.state.role_detail.filter(e => e && e["can_delete"] === false).length === 0} />
                                                        </th>
                                                        <th className="text-center wpx-70">
                                                            <label>ดาวน์โหลด</label><br />
                                                            <input className="hpx-20" type="checkbox" onChange={(e) => { this.select_all_role("can_download", e.target.checked) }} checked={this.state.role_detail.filter(e => e && e["can_download"] === false).length === 0} />
                                                        </th>
                                                        <th className="text-center wpx-70">
                                                            <label>ยืนยัน</label><br />
                                                            <input className="hpx-20" type="checkbox" onChange={(e) => { this.select_all_role("can_approved", e.target.checked) }} checked={this.state.role_detail.filter(e => e && e["can_approved"] === false).length === 0} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.role_detail.map((item, index) => (
                                                        <tr className={item.title ? "bg-contain" : ""}>
                                                            <td>{item.name_th}</td>
                                                            <td className="text-center">
                                                                {!item.title && (
                                                                    <input type="checkbox" onChange={(e) => { this.select_role("all", index) }} checked={item["can_view"] && item["can_add"] && item["can_edit"] && item["can_delete"] && item["can_download"] && item["can_approved"]} />
                                                                )}
                                                            </td>
                                                            <td className="text-center">
                                                                {!item.title && (
                                                                    <input type="checkbox" onChange={(e) => { this.select_role("can_view", index) }} checked={item["can_view"]} />
                                                                )} </td>
                                                            <td className="text-center">
                                                                {!item.title && (
                                                                    <input type="checkbox" onChange={(e) => { this.select_role("can_add", index) }} checked={item["can_add"]} />
                                                                )}</td>
                                                            <td className="text-center">
                                                                {!item.title && (
                                                                    <input type="checkbox" onChange={(e) => { this.select_role("can_edit", index) }} checked={item["can_edit"]} />
                                                                )} </td>
                                                            <td className="text-center">
                                                                {!item.title && (
                                                                    <input type="checkbox" onChange={(e) => { this.select_role("can_delete", index) }} checked={item["can_delete"]} />
                                                                )} </td>
                                                            <td className="text-center">
                                                                {!item.title && (
                                                                    <input type="checkbox" onChange={(e) => { this.select_role("can_download", index) }} checked={item["can_download"]} />
                                                                )} </td>
                                                            <td className="text-center">
                                                                {!item.title && (
                                                                    <input type="checkbox" onChange={(e) => { this.select_role("can_approved", index) }} checked={item["can_approved"]} />
                                                                )} </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ปุ่มด้านล่าง */}
                <div className="body px-3 pt-3 bg-white position-fixed" style={{ bottom: 0 }}>
                    <div className="row mx-0">
                        <div className="ml-auto col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-2 mb-3">
                            <button className="btn btn-outline-orange w-100" onClick={() => { window.location.href = "/master/list-role"; }}>
                                ยกเลิก
                            </button>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-2 mb-3">
                            <button className="btn btn-orange w-100" onClick={() => {
                                if (this.props.location.query.id) {
                                    this.Update();
                                } else {
                                    this.Create();
                                }
                            }} >
                                {this.props.location.query.id ? "บันทึก" : "เพิ่ม"}

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
