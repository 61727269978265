import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarCustomer.js";
import {
    tokens,
    GET,
    format_date,
    toFixed,
    locale
} from "../../components/CustomComponent.js";
import React, { Component } from "react";

export default class ListHistoryPoint extends Component {
    constructor(props) {
        super(props)

        this.state = {

            page: 1,
            loading: false,

            data: null,
            data_car: [],

            filter_search: "",
            filter_pick_up_date: "",
            filter_return_date: "",

            traffic_ticket_id: "",
            rental_number: "",
            license_plate: "",
            rental_date: "",
            rental_time: "",
            id_card: "",
            full_name: "",
            picture: "",
            traffic_fines: "",
            ticket_status: 0,
        }
    }
    async componentDidMount() {
        await this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {}
        let result = await GET(tokens, "v1/TH/receipt/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({

            page: 1,
            loading: false,


            filter_search: "",
            filter_pick_up_date: "",
            filter_return_date: "",

            rental_number: "",
            license_plate: "",
            rental_date: "",
            rental_time: "",
            id_card: "",
            full_name: "",
            picture: "",
            traffic_fines: ""

        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ประวัติแต้ม/คะแนน" />
                {/* BODY */}
                <div className="body background3 min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3 text-white"><b>{global[locale]["ประวัติแต้ม/คะแนน"]}</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">{global[locale]["วันที่"]}</th>
                                                <th className="text-center">{global[locale]["เลขที่เอกสาร"]}</th>
                                                <th className="text-center">{global[locale]["ยอดเงิน"]}</th>
                                                <th className="text-center">{global[locale]["เงินสะสม"]}</th>
                                                <th className="text-center">{global[locale]["แต้ม"]}</th>
                                                <th className="text-center">{global[locale]["หมายเหตุ"]}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={6} className="text-center"> {global[locale]["ไม่มีข้อมูล"]} </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.filter(item => item.return_wallet || item.return_points).map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-"}</td>
                                                    <td className="text-center">{item.payment_number ? item.payment_number.replaceAll("DP", "RE") : "-"}</td>
                                                    <td className="text-center">{item.rental_price ? toFixed(item.rental_price) : "0.00"}</td>
                                                    <td className="text-center">{item.return_wallet ? toFixed(item.return_wallet) : "0.00"}</td>
                                                    <td className="text-center">{item.return_points ? item.return_points : "0"}</td>
                                                    <td className="text-center">{item.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ" ? "คืนรถ" : "เช่า"}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {/* {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )} */}
                    </div>
                </div>
            </div >
        )
    }
}
