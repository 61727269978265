import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import {
    GET,
    print_receipt
} from "../../components/CustomComponent.js";
import React, { Component } from "react";

export default class ListReceipt extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            data: null,

            filter_search: ""

        }
    }
    async componentDidMount() {
        let data = {
            data_search: this.props.location.query.id.replaceAll("RE", "DP"),
        }
        let result = await GET(null, "v1/TH/receipt/filter-search-public/page/0", data);
        if (result && result.status) { 
            print_receipt(result.data.data[0]);
        }

    }
    render() {
        return (
            <div></div>
        )
    }
}
