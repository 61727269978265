import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select, { components } from "react-select";
import {
    tokens,
    select_style,
    success_url,
    GET,
    POST,
    PUT,
    DELETE,
    float,
    validate,
    required,
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";

import BANK from "../../../assets/images/bank.png";
import bank002 from "../../../assets/bank/002.png";
import bank004 from "../../../assets/bank/004.png";
import bank006 from "../../../assets/bank/006.png";
import bank011 from "../../../assets/bank/011.png";
import bank014 from "../../../assets/bank/014.png";
import bank022 from "../../../assets/bank/022.png";
import bank024 from "../../../assets/bank/024.png";
import bank025 from "../../../assets/bank/025.png";
import bank030 from "../../../assets/bank/030.png";
import bank033 from "../../../assets/bank/033.png";
import bank034 from "../../../assets/bank/034.png";
import bank066 from "../../../assets/bank/066.png";
import bank067 from "../../../assets/bank/067.png";
import bank069 from "../../../assets/bank/069.png";
import bank070 from "../../../assets/bank/070.png";
import bank071 from "../../../assets/bank/071.png";
import bank073 from "../../../assets/bank/073.png";

const { SingleValue, Option } = components;
const IconSingleValue = (props) => (
    <SingleValue {...props}>
        <img src={props.data.icon} style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }} />
        {props.data.label}
    </SingleValue>
);

const IconOption = (props) => (
    <Option {...props}>
        <img src={props.data.icon} style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }} />
        {props.data.label}
    </Option>
);

export default class ListBankAccount extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            filter_search: "",

            data: null,
            data_bank: [
                { value: "ธนาคารกรุงเทพ", label: "ธนาคารกรุงเทพ", icon: bank002 },
                { value: "ธนาคารกสิกรไทย", label: "ธนาคารกสิกรไทย", icon: bank004 },
                { value: "ธนาคารกรุงไทย", label: "ธนาคารกรุงไทย", icon: bank006 },
                { value: "ธนาคารทหารไทยธนชาต", label: "ธนาคารทหารไทยธนชาต", icon: bank011 },
                { value: "ธนาคารไทยพาณิชย์", label: "ธนาคารไทยพาณิชย์", icon: bank014 },
                { value: "ธนาคารซีไอเอ็มบีไทย", label: "ธนาคารซีไอเอ็มบีไทย", icon: bank022 },
                { value: "ธนาคารยูโอบี", label: "ธนาคารยูโอบี", icon: bank024 },
                { value: "ธนาคารกรุงศรีอยุธยา", label: "ธนาคารกรุงศรีอยุธยา", icon: bank025 },
                { value: "ธนาคารออมสิน", label: "ธนาคารออมสิน", icon: bank030 },
                { value: "ธนาคารอาคารสงเคราะห์", label: "ธนาคารอาคารสงเคราะห์", icon: bank033 },
                { value: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", label: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", icon: bank034 },
                { value: "ธนาคารอิสลามแห่งประเทศไทย", label: "ธนาคารอิสลามแห่งประเทศไทย", icon: bank066 },
                { value: "ธนาคารทิสโก้", label: "ธนาคารทิสโก้", icon: bank067 },
                { value: "ธนาคารเกียรตินาคินภัทร", label: "ธนาคารเกียรตินาคินภัทร", icon: bank069 },
                { value: "ธนาคารไอซีบีซี (ไทย)", label: "ธนาคารไอซีบีซี (ไทย)", icon: bank070 },
                { value: "ธนาคารไทยเครดิตเพื่อรายย่อย", label: "ธนาคารไทยเครดิตเพื่อรายย่อย", icon: bank071 },
                { value: "ธนาคารแลนด์ แอนด์ เฮ้าส์", label: "ธนาคารแลนด์ แอนด์ เฮ้าส์", icon: bank073 },
            ],

            id: "",
            bank_name: "",
            account_number: "",
            branch_name: "",
            picture: "",
            is_active: true
        }
    }
    async componentDidMount() {
        this.GetAll();

    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search,
        }
        let result = await GET(tokens, "v1/TH/bank-account/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        if (validate(this.state, ["bank_name", "account_number", "branch_name"])) { return };
        this.setState({ loading: true });
        let data = {
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            account_number: this.state.account_number,
            branch_name: this.state.branch_name,
            picture: this.state.picture,
        }
        let result = await POST(tokens, "v1/TH/bank-account/create", data);
        if (result && result.status) {
            success_url("/master/list-bank-account")
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        if (validate(this.state, ["bank_name", "account_number", "branch_name"])) { return };
        this.setState({ loading: true });
        let data = {
            bank_id: this.state.id,
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            account_number: this.state.account_number,
            branch_name: this.state.branch_name,
            picture: this.state.picture,
            is_active: this.state.is_active
        }
        let result = await PUT(tokens, "v1/TH/bank-account/update", data);
        if (result && result.status) {
            success_url("/master/list-bank-account")
        }
        this.setState({ loading: false });
    }
    Delete = async () => {
        this.setState({ loading: true });
        let result = await DELETE(tokens, 'v1/TH/bank-account/delete/' + this.state.id, null);
        if (result && result.status) {
            success_url("/master/list-bank-account")
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            id: "",
            bank_name: "",
            account_number: "",
            branch_name: "",
            picture: "",
            is_active: true
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="บัญชีธนาคาร" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>บัญชีธนาคาร</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add" onClick={() => { this.clear_form(); this.setState({ modal_create: true }) }}>
                                    เพิ่มธนาคาร
                                </button>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="ชื่อธนาคาร,เลขบัญชี,สาขา"
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ชื่อธนาคาร</th>
                                                <th className="text-center">logo</th>
                                                <th className="text-center">เลขบัญชี</th>
                                                <th className="text-center">สาขา</th>
                                                {/* <th className="text-center">สถานะ</th> */}
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={5} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.bank_name ? item.bank_name : "-"}</td>
                                                    <td className="text-center"><img className="wpx-30 hpx-30 rounded-circle" src={this.state.data_bank.filter(e => e.value === item.bank_name).length !== 0 ? this.state.data_bank.filter(e => e.value === item.bank_name)[0].icon : BANK} style={{ objectFit: "cover" }} />
                                                    </td>
                                                    <td className="text-center">{item.account_number ? item.account_number : "-"}</td>
                                                    <td className="text-center">{item.branch_name ? item.branch_name : "-"}</td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <label className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_update: true,
                                                                        id: item.bank_id,
                                                                        bank_name: this.state.data_bank.filter(e => e.value === item.bank_name).length !== 0 ? this.state.data_bank.filter(e => e.value === item.bank_name)[0] : null,
                                                                        account_number: item.account_number,
                                                                        branch_name: item.branch_name,
                                                                        is_active: item.is_active
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer"
                                                                > {"\uf304"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-danger-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 delete"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_delete: true,
                                                                        id: item.bank_id,
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-danger pointer"
                                                                > {"\uf1f8"} </label>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* create or update */}
                <Modal
                    show={this.state.modal_create || this.state.modal_update}
                    onHide={() => { this.setState({ modal_create: false, modal_update: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>{this.state.modal_create ? "เพิ่มธนาคาร" : "แก้ไขธนาคาร"}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {/* ชื่อธนาคาร */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ชื่อธนาคาร{required}:</label>
                            <Select
                                className="form-control  validate select-search"
                                styles={select_style}
                                options={this.state.data_bank}
                                components={{ SingleValue: IconSingleValue, Option: IconOption }}
                                onChange={(e) => {
                                    this.setState({ bank_name: e });
                                }}
                                value={this.state.bank_name}
                            ></Select>
                        </div>
                        {/* เลขบัญชี */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">เลขบัญชี{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    if (!float(e.target.value)) {
                                        return
                                    }
                                    this.setState({ account_number: e.target.value })
                                }}
                                value={this.state.account_number}
                                maxLength={20}
                            />
                        </div>
                        {/* สาขา */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">สาขา{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ branch_name: e.target.value })
                                }}
                                value={this.state.branch_name}
                                maxLength={100}
                            />
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        if (this.state.modal_create) {
                                            this.Create();
                                        } else {
                                            this.Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบข้อมูล</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการลบข้อมูลดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
