import { Link } from "react-router";
import React, { Component } from "react";
import Swal from "sweetalert2";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { alert_url, Logout, getStorage, role } from "./CustomComponent.js"
import { Modal } from "react-bootstrap";
import "../assets/css/bootstrap.css";
import "./global.js";

export default class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
      modal_logout: false,
      permission: []
    };
  }
  async componentDidMount() {
    let user_type = getStorage("user") && getStorage("user").user_type ? getStorage("user").user_type : null;
    if (user_type === null) {
      window.location.href = "/";
    } else if (!user_type) {
      window.location.href = "/user/choose-car";
    } else if (user_type !== "owner") {
      let role_name = window.location.href.split("?")[0].split("/").pop();
      if (await role(role_name, "view") === false && role_name !== "master" && role_name.includes("report")) {
        if (!role_name.includes("manage-role") && !role_name.includes("cashier-detail")) {
          alert_url("/admin/dashboard", "warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึงเมนูนี้");
        } else {
          if (role_name.includes("manage-role?id=") && await role("list-role", "edit") === false) {
            alert_url("/admin/dashboard", "warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึงเมนูนี้");
          }
          if (role_name.includes("manage-role") && await role("list-role", "add") === false) {
            alert_url("/admin/dashboard", "warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึงเมนูนี้");
          }
          if (role_name.includes("cashier-detail?id=") && await role("cashier", "edit") === false) {
            alert_url("/admin/dashboard", "warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึงเมนูนี้");
          }
          if (role_name.includes("cashier-detail") && await role("cashier", "add") === false) {
            alert_url("/admin/dashboard", "warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึงเมนูนี้");
          }
        }
      }
      if (role_name.includes("report") && await role("report", "view") === false) {
        alert_url("/admin/dashboard", "warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึงเมนูนี้");
      }
      this.set_role();
      let permission = await getStorage("permission").filter(e => e.can_view).map(e => { return e.menu_name })
      if (permission && permission.findIndex(e => e === "list-admin" || e === "list-role" || e === "manage-info" || e === "list-member" || e === "list-agent" || e === "list-bank-account" || e === "list-banner" || e === "list-car" || e === "list-brand" || e === "list-model" || e === "list-storage" || e === "list-car-warehouse")) {
        permission.push("master");
      }
      this.setState({ permission: permission })
    } else {
      this.setState({
        permission: ["admin", "dashboard", "cashier", "manage-rent", "list-rent", "list-booking", "list-receipt", "list-withdraw", "list-deposit", "list-collateral", "list-tax-notice", "list-traffic-ticket", "list-log", "master", "report", "list-admin", "list-role", "manage-info", "list-member", "list-agent", "list-bank-account", "list-banner", "list-car", "list-brand", "list-model", "list-storage", "list-car-warehouse"]
      })
    }
  }
  async componentDidUpdate() {
    let user_type = getStorage("user") ? getStorage("user").user_type : null;
    if (user_type !== "owner") {
      this.set_role();
    }
  }
  set_role = async () => {
    let role_name = window.location.href.split("?")[0].split("/").pop();
    if (await role(role_name, "add") === false) {
      let add = document.getElementsByClassName("add");
      for (let e of add) {
        e.classList.add("d-none");
      }
    }
    if (await role(role_name, "edit") === false) {
      let edit = document.getElementsByClassName("edit");
      for (let e of edit) {
        e.classList.add("d-none");
      }
    }
    if (await role(role_name, "delete") === false) {
      let deletes = document.getElementsByClassName("delete");
      for (let e of deletes) {
        e.classList.add("d-none");
      }
    }
    if (await role(role_name, "download") === false) {
      let download = document.getElementsByClassName("download");
      for (let e of download) {
        e.classList.add("d-none");
      }
    }
    if (await role(role_name, "approved") === false) {
      let approved = document.getElementsByClassName("approved");
      for (let e of approved) {
        e.classList.add("d-none");
      }
    }
  }
  icon = (icon) => { return (<label className="icon">{icon}</label>) }
  render() {
    return (
      <div>
        <div className="w-100 mx-0 px-0 position-fixed d-flex justify-content-between bg-navbar shadow px-4 py-2" style={{ height: 60, zIndex: 1010 }}>
          <div className="align-items-center d-flex row mx-0">
            {((this.props.sidebar !== "false" && !window.location.pathname.includes("pos")) || window.location.pathname.includes("list-deposit")) && (
              <label className="d-block d-lg-none d-xl-none mt-2" onClick={() => { this.setState({ sidebar: !this.state.sidebar }); }}>
                <span className="icon">{"\uf0c9"}</span>
              </label>
            )}
            <div className="position-relative">
              <h3><b>S.K.SERVICE</b></h3>
              <small className="position-absolute" style={{ right: 0, bottom: -5 }}> {global.version}</small>
            </div>
            <div className="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex">
              <small>{this.props.page1}</small>
              <small className="ml-2">{this.props.page2 ? " > " + this.props.page2 : ""}</small>
              <small className="ml-2">{this.props.page3 ? " > " + this.props.page3 : ""}</small>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-2 align-items-center d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex">
              {getStorage("user") ? getStorage("user").full_name : "firstname lastname"}
            </span>
            <div className="text-right">
              <span className="mb-0 align-items-center d-flex d-sm-none d-md-none d-lg-none d-xl-none">
                {getStorage("user") ? getStorage("user").full_name : "firstname lastname"}
              </span>
              <label className="icon text-18 px-2"
                onClick={() => {
                  if (getStorage("cashier")) {
                    Swal.fire({
                      icon: "warning",
                      title: "แจ้งเตือน",
                      text: "ยังไม่ได้ปิดกะ หากออกจากระบบข้อมูลการเปิดกะจะหายไป ยืนยันการทำงานนี้ต่อหรือไม่",
                      confirmButtonText: "ตกลง",
                      cancelButtonText: "ยกเลิก",
                      showCancelButton: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.setState({ modal_logout: true })
                      }
                    });
                  } else {
                    this.setState({ modal_logout: true })
                  }
                }}>{"\uf2f5"}</label>
            </div>
          </div>
        </div>
        <ProSidebar className="full-screen" breakPoint="lg" toggled={this.state.sidebar} onFocus={() => { this.setState({ sidebar: true }); }}>

          <Menu>
            {/* MENU */}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "dashboard") !== -1 && (<MenuItem icon={this.icon("\uf627")} active={window.location.pathname.includes("/admin/dashboard")}><Link to="/admin/dashboard">แดชบอร์ด</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "cashier") !== -1 && (<MenuItem icon={this.icon("\uf788")} active={window.location.pathname.includes("/admin/cashier")}><Link to="/admin/cashier">แคชเชียร์</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "manage-rent") !== -1 && (<MenuItem icon={this.icon("\uf518")} active={window.location.pathname.includes("/admin/manage-rent")}><Link to="/admin/manage-rent">สร้างสัญญาเช่า</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-rent") !== -1 && (<MenuItem icon={this.icon("\uf518")} active={window.location.pathname.includes("/admin/list-rent")}><Link to="/admin/list-rent">รายการเช่ารถ</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-booking") !== -1 && (<MenuItem icon={this.icon("\uf02d")} active={window.location.pathname.includes("/admin/list-booking")}><Link to="/admin/list-booking">รายการจองรถ</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-receipt") !== -1 && (<MenuItem icon={this.icon("\uf543")} active={window.location.pathname.includes("/admin/list-receipt")}><Link to="/admin/list-receipt">ใบเสร็จรับเงินการเช่า</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-receipt") !== -1 && (<MenuItem icon={this.icon("\uf543")} active={window.location.pathname.includes("/admin/receipt-booking")}><Link to="/admin/receipt-booking">ใบเสร็จรับเงินการจอง</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-withdraw") !== -1 && (<MenuItem icon={this.icon("\uf733")} active={window.location.pathname.includes("/admin/list-withdraw")}><Link to="/admin/list-withdraw">ใบเบิกเงิน</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-deposit") !== -1 && (<MenuItem icon={this.icon("\uf555")} active={window.location.pathname.includes("/admin/list-deposit")}><Link to="/admin/list-deposit">ใบมัดจำ</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-collateral") !== -1 && (<MenuItem icon={this.icon("\uf0d6")} active={window.location.pathname.includes("/admin/list-collateral")}><Link to="/admin/list-collateral">ข้อมูลเงินประกัน</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-tax-notice") !== -1 && (<MenuItem icon={this.icon("\uf732")} active={window.location.pathname.includes("/admin/list-tax-notice")}><Link to="/admin/list-tax-notice">แจ้งเตือนการต่อภาษี</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-traffic-ticket") !== -1 && (<MenuItem icon={this.icon("\uf737")} active={window.location.pathname.includes("/admin/list-traffic-ticket")}><Link to="/admin/list-traffic-ticket">จัดการข้อมูลใบสั่ง</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "list-log") !== -1 && (<MenuItem icon={this.icon("\uf19c")} active={window.location.pathname.includes("/admin/list-log")}><Link to="/admin/list-log">ประวัติผู้ใช้งาน</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "master") !== -1 && (<MenuItem icon={this.icon("\uf013")} active={window.location.pathname.includes("/admin/master")}><Link to="/admin/master">จัดการระบบ</Link></MenuItem>)}
            {this.state.permission && this.state.permission.length > 1 && this.state.permission.findIndex(e => e === "report") !== -1 && (<MenuItem icon={this.icon("\uf659")} active={window.location.pathname.includes("/admin/report")}><Link to="/admin/report">รายงาน</Link></MenuItem>)}
          </Menu>
        </ProSidebar>
        {/* MODAL */}
        <Modal show={this.state.modal_logout} onHide={() => { this.setState({ modal_logout: false }) }}>
          <Modal.Header><Modal.Title><b>ออกจากระบบ</b></Modal.Title></Modal.Header>
          <Modal.Body>
            <div className="w-100  mb-3">
              <label>ยืนยันการออกจากระบบหรือไม่</label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_logout: false }) }}>ยกเลิก</button>
              </div>
              <div className="w-50 p-1">
                <button className="btn btn-orange w-100" onClick={() => {
                  localStorage.removeItem("cashier")
                  localStorage.removeItem("cashier_summary");
                  Logout()
                }}>ออกจากระบบ</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}