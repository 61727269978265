import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
    toFixed,
    total,
    format_date,
    days_between
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
    FCIconic: {
        normal: "FCIconic-Light.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Italic.ttf",
        bolditalics: "FCIconic-Italic.ttf",
    },
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
}

export default class ListWithdraw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            data: [],
            data_traffic: [],

            filter_search: "",
            filter_date: "",
            filter_rental_status: "",
        }
        this.tableRef = React.createRef();
    }
    async componentDidMount() {
        await this.GetTrafficTicketAll();
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
        }
        let result = await GET(tokens, "v1/TH/user-member/filter-search/page/999999", data);
        if (result && result.status) {
            let count = result.data.data.length;
            let array = result.data.data.map(item => {
                return {
                    ...item,
                    traffic_fines: this.state.data_traffic.filter(e => e.rental_data.id_card === item.id_card && e.ticket_status === 1)
                }
            });
            this.loop(array, 500);


        }
        this.setState({ loading: false });
    }
    loop = (array, total) => {
        this.setState({
            data: array.filter((item, index) => index < total)
        })
        setTimeout(() => {
            if (total < array.length && total <= 3000) {
                this.loop(array, total + 500);
            } else if (total >= 3000 && total !== array.length) {
                this.loop(array, array.length);
            }
        }, 2000);
    }
    GetTrafficTicketAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            pick_up_date: "",
            return_date: ""
        }
        let result = await GET(tokens, "v1/TH/traffic-ticket/filter-search/page/0", data);
        if (result && result.status) { 
            this.setState({ data_traffic: result.data.data })
        }
        this.setState({ loading: false });
    }
    pdf = () => {
        let table = this.state.data.map((item, index) => {
            return [
                { text: index + 1, alignment: "center" },
                { text: item.id_card ? item.id_card : "-", alignment: "center" },
                { text: item.full_name ? item.full_name : "-", alignment: "center" },
                { text: item.address ? item.address + (item.sub_district ? " ต." + item.sub_district : "") + (item.district ? " อ." + item.district : "") + (item.province ? " จ." + item.province : "") + (item.zip_code ? " " + item.zip_code : "") : "-", alignment: "left" },
                { text: item.email ? item.email : "-", alignment: "left" },
                { text: item.phone ? item.phone : "-", alignment: "left" },
                { text: item.points ? item.points : "0", alignment: "left" },
                { text: toFixed(total(item.traffic_fines, "traffic_fines")), alignment: "left" },
                { text: "ลูกค้าทั่วไป (ปกติ)", alignment: "left" },
            ]
        });
        var docDefinition = {
            content: [

                { text: "รายงานทะเบียนลูกค้า", alignment: "left", fontSize: 24, bold: true },
                " ",
                {
                    table: {
                        headerRows: 1,
                        widths: ['4%', '7%', '14%', '20%', '14%', '14%', '6%', '7%', '14%'],
                        body: [
                            [
                                { text: "ลำดับ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "รหัสบัตรประชาชน", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ชื่อ - นามสกุล", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ที่อยู่", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "อีเมล", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "เบอร์โทรศัพท์", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "แต้ม", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ใบสั่ง", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "สถานะ", alignment: "center", fillColor: "#F2F2F2" }
                            ],
                            ...table,
                        ],
                    }
                },
            ],
            // , alignment: "justify" 
            defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: 'landscape',
        }
        pdfMake.createPdf(docDefinition).open()
    }
    excel = () => {

    }
    clear_form() {
        this.setState({
            loading: false,

            filter_search: ""
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายงานทะเบียนลูกค้า" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายงานทะเบียนลูกค้า</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/report" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.pdf() }}>
                                    PDF
                                </button>
                                <DownloadTableExcel
                                    filename="รายงานทะเบียนลูกค้า"
                                    sheet="รายงานทะเบียนลูกค้า"
                                    currentTableRef={this.tableRef.current}
                                >
                                    <button type="button" className="btn btn-dark px-4 mx-1 mb-3" onClick={() => { this.excel() }}>
                                        EXCEL
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_date: "",
                                                filter_booking_status: "",
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped" ref={this.tableRef}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">รหัสบัตรประชาชน</th>
                                                <th className="text-center">ชื่อ - นามสกุล</th>
                                                <th className="text-center">ที่อยู่</th>
                                                <th className="text-center">อีเมล</th>
                                                <th className="text-center">เบอร์โทรศัพท์</th>
                                                <th className="text-center">แต้ม</th>
                                                <th className="text-center">ใบสั่ง</th>
                                                <th className="text-center">สถานะ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={9} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data
                                                .map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">{item.id_card ? item.id_card : "-"}</td>
                                                        <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                                                        <td className="text-center">{item.address ? item.address + (item.sub_district ? " ต." + item.sub_district : "") + (item.district ? " อ." + item.district : "") + (item.province ? " จ." + item.province : "") + (item.zip_code ? " " + item.zip_code : "") : "-"} </td>
                                                        <td className="text-center">{item.email ? item.email : "-"}</td>
                                                        <td className="text-center">{item.phone ? item.phone : "-"}</td>
                                                        <td className="text-center">{item.points ? item.points : "0"}</td>
                                                        <td className="text-center">{toFixed(total(item.traffic_fines, "traffic_fines"))}</td>
                                                        <td className="text-center">ลูกค้าทั่วไป (ปกติ)</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
