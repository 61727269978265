import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarCustomer.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    alert,
    GET,
    POST,
    format_date,
    float,
    required,
    DatePickerTH,
    success_url,
    locale,
    toFixed,
    total,
    days_between,
    status_rent,
    getimgBase64,
    print_paper01
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import Resizer from "react-image-file-resizer";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";
import CAR from "../../assets/images/bike.png";

export default class ListRent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_detail: false,
            modal_detail2: false,
            modal_scale: false,

            page: 1,
            loading: false,

            data: null,
            data_bike: [],
            filter_search: "",

            status_pay: "",
            rental_id: "",
            rental_number: "",
            address: "",
            birthday: null,
            district: "",
            email: "",
            full_name: "",
            id_card: "",
            is_active: true,
            member_code: "",
            member_id: "",
            member_status: 1,
            phone: "",
            picture: "",
            province: "",
            sub_district: "",
            username: "",
            verify_status: true,
            zip_code: "",
            picture: "",

            facebook: "",
            id_line: "",
            nationality: "",
            card_issue: "",
            date_issue: "",
            date_expire: "",
            temporary_accommodation: "",

            pick_up_date: new Date(),
            pick_up_time: "",
            pick_up_location: "",
            return_date: new Date(),
            return_time: "",
            return_location: "",

            rental_type: "",
            mileage: 0,
            oil_volume: 0,
            rental_price: 0,
            guarantee_type: 1,
            guarantee_amount: 0,
            equipment_fee: 0,
            discount_price: 0,
            delivery_price: 0,
            recommen_id: "",
            total_price: 0,
            signature: "",

            total_price_equipment: 0,
            total_qty_equipment: 0,

            detail_picture: ["", "", "", "", "", "", "", "", "", ""],

            selected_bike: null,

            search_bike: "",
            rental_status: "",

            days: 0,

            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: null, // วันคืนรถเดิม
            return_time: null, // เวลาคืนรถเดิม
            return_date_new: null, // วันคืนรถใหม่
            return_time_new: null, // เวลาคืนรถใหม่
            rental_type: "daily", // ประเภทการเช่า
            rental_price: 0, // ราคาเช่ารถ
            remark: "",
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            bank_name: "",
            payment_type: null,
            payment_date: "",
            payment_time: "",
            payment_picture: [],
            discount: 0,
            discount_pay: 0,
            spend_points: 0, // แต้มที่ใช้
            change_points: 0, // แต้มแลกส่วนลด
            total_price: 0,
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            overdue_booking: 0,
            change: 0,
            event_type: 1,

            refund_cost: 0,

            use_point: false,
            point: 0,

            use_sk_wallet: false,
            sk_wallet: 0,

            array_price: {
                daily_price: 0,
                weekly_price: 0,
                monthly_price: 0
            },
            wallet: {
                point: 0,
                sk_wallet: 0
            },
            old_price: 0,
            old_pick_up_location: "",
            old_return_location: "",
            old_selected_bike: null,
            dropdowns: -1
        }
        this.divRef1 = React.createRef();
        this.divRef2 = React.createRef();
        this.divRef3 = React.createRef();
        this.divRef4 = React.createRef();
        this.close_dropdown = this.close_dropdown.bind(this);
    }
    async componentDidMount() {
        document.addEventListener('mousedown', this.close_dropdown);
        await this.GetAll();
        await this.GetBankAll();
    }
    componentWillUnmount() {
        document.addEventListener('mousedown', this.close_dropdown);
    }
    close_dropdown = (event) => {

        if ((this.divRef1.current && this.divRef1.current.contains(event.target)) ||
            (this.divRef2.current && this.divRef2.current.contains(event.target)) ||
            (this.divRef3.current && this.divRef3.current.contains(event.target)) ||
            (this.divRef4.current && this.divRef4.current.contains(event.target))) { } else {
            this.setState({ dropdowns: -1 })
        }
    }

    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            pick_up_date: "",
            rental_status: "",
        }
        let result = await GET(tokens, "v1/TH/rental-manage/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    GetBankAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bank-account/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_bank: result.data.data.map(e => { return { value: e.bank_id, label: e.account_number + " " + e.bank_name + " สาขา" + e.branch_name } }) })
        }
        this.setState({ loading: false });
    }
    GetById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/rental-manage/detail/" + id, null);
        if (result && result.status) {
            let item = result.data.data;
            let detail_picture = item.detail_picture.map(e => { return e.picture });
            for (let i = 0; i < 10; i++) {
                if (item.detail_picture.length <= i) {
                    detail_picture.push("")
                }
            }
            this.setState({
                created_by: item.created_by,
                address: item.address,
                admin_name: item.admin_name,
                rental_id: id,
                rental_number: item.rental_number,
                booking_number: item.booking_number,
                id_card: item.id_card,
                full_name: item.full_name,
                birthday: item.birthday,
                nationality: item.nationality,
                card_issue: item.card_issue,
                date_issue: item.date_issue,
                date_expire: item.date_expire,
                temporary_accommodation: item.temporary_accommodation,
                facebook: item.facebook,
                phone: item.phone,
                email: item.email,
                id_line: item.id_line,
                selected_bike: {
                    brand_name: item.brand_name,
                    model_name: item.model_name,
                    license_plate: item.license_plate,
                    chassis_number: item.chassis_number,
                    bike_color: item.bike_color,
                    cc_size: item.cc_size,
                    bike_year: item.bike_year,
                    title_picture: item.title_picture,
                    daily_price: item.daily_price,
                    bike_channel_name: item.bike_channel_name
                },
                old_selected_bike: {
                    brand_name: item.brand_name,
                    model_name: item.model_name,
                    license_plate: item.license_plate,
                    chassis_number: item.chassis_number,
                    bike_color: item.bike_color,
                    cc_size: item.cc_size,
                    bike_year: item.bike_year,
                    title_picture: item.title_picture,
                    daily_price: item.daily_price,
                    bike_channel_name: item.bike_channel_name
                },
                old_price: item.rental_price,
                pick_up_date: item.pick_up_date,
                pick_up_time: item.pick_up_time,
                pick_up_location: item.pick_up_location,
                old_pick_up_location: item.pick_up_location,
                return_date: item.return_date,
                return_time: item.return_time,
                return_location: item.return_location,
                old_return_location: item.return_location,

                rental_type: item.rental_type === "daily" ? { value: "daily", label: "รายวัน" } :
                    item.rental_type === "weekly" ? { value: "weekly", label: "รายสัปดาห์" } :
                        item.rental_type === "monthly" ? { value: "monthly", label: "รายเดือน" } : null,
                mileage: item.mileage,
                oil_volume: item.oil_volume,
                rental_price: item.rental_price,
                guarantee_type: item.guarantee_type === 1 ? { value: "1", label: "เงินประกัน" } :
                    item.guarantee_type === 2 ? { value: "2", label: "สำเนาบัตรประชาชน" } :
                        item.guarantee_type === 3 ? { value: "3", label: "บัตรประชาชนตัวจริง" } :
                            item.guarantee_type === 4 ? { value: "4", label: "บัตรข้าราชการ" } :
                                item.guarantee_type === 5 ? { value: "5", label: "บัตรนักศึกษา" } :
                                    item.guarantee_type === 6 ? { value: "6", label: "พาสปอร์ตตัวจริง" } :
                                        item.guarantee_type === 7 ? { value: "7", label: "สำเนาพาสปอร์ต" } :
                                            item.guarantee_type === 8 ? { value: "8", label: "ใบขับขี่" } :
                                                item.guarantee_type === 9 ? { value: "9", label: "ใบขับขี่ตัวจริง" } :
                                                    item.guarantee_type === 10 ? { value: "10", label: "เงินหยวน" } :
                                                        item.guarantee_type === 11 ? { value: "11", label: "เงินดอลล่า" } :
                                                            item.guarantee_type === 12 ? { value: "12", label: "เงินยูโร" } : null,
                guarantee_amount: item.guarantee_amount,
                equipment_fee: item.equipment_fee,
                discount_price: item.discount_price,
                delivery_price: item.delivery_price,
                recommen_id: item.recommen_id,
                total_price: item.total_price,
                signature: item.signature,
                equipment_list: item.equipment_list,
                total_price_equipment: item.total_price_equipment,
                overdue_booking: item.overdue_booking,
                total_qty_equipment: item.total_qty_equipment,
                detail_picture: detail_picture,
                title_picture: item.title_picture,
                rental_status: item.rental_status,
                other_price: item.other_price ? item.other_price : 0,

                days: days_between(
                    new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                    new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en"))),

                ),

                array_price: {
                    daily_price: item.daily_price,
                    weekly_price: item.weekly_price,
                    monthly_price: item.monthly_price
                },

                wallet: {
                    point: item.points,
                    sk_wallet: item.sk_wallet
                },
            })
            let late_day = days_between(
                new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en"))),
                new Date(),


            );

            let refund_cost = 0;
            // ถ้าวันคืนไม่เกิน
            if (item.rental_type === "daily") {
                refund_cost = item.daily_price * late_day;
            } else if (item.rental_type === "weekly") {
                if (item.weekly_price === 0) {
                    refund_cost = item.daily_price * late_day;
                } else {
                    if (late_day >= 7) {
                        let price = 0;
                        price += Number((late_day / 7).toString().split(".")[0]) * item.weekly_price;
                        price += (late_day % 7) * item.daily_price;
                        refund_cost = price;
                    } else {
                        refund_cost = item.weekly_price;
                    }
                }
            } else if (item.rental_type === "monthly") {
                if (item.monthly_price === 0) {
                    refund_cost = item.daily_price * late_day;
                } else {
                    if (late_day > 30) {
                        let price = 0;
                        price += Number((late_day / 30).toString().split(".")[0]) * item.monthly_price;
                        price += Number(((late_day % 30) / 7).toString().split(".")[0]) * item.weekly_price;
                        price += ((late_day % 30) % 7) * item.daily_price;
                        refund_cost = price;
                    } else {
                        refund_cost = item.monthly_price;
                    }
                }
            }
            if (late_day > 0) {
                // คืนเงิน 
                this.setState({ refund_cost: refund_cost });
            } else {
                // เกินกำหนด 
                this.setState({ rental_price_overdue: refund_cost });
            }
        }
        this.setState({ loading: false });
    }

    GetBikeAll = async () => {
        let data = {
            start_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : null,
            end_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null
        }
        let result = await GET(tokens, "v1/TH/rental-manage/total-bike-rental", data);
        if (result && result.status) {
            this.setState({ data_bike: result.data.data })
        }
    }
    GetListById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/cashier/detail/" + id, null);
        if (result && result.status) {
            let array = result.data.data.filter(e => e.event_type === 2)
            const data_array = Object.values(array.reduce((p, v) => {
                const old = p[v.event_extend.return_date_new];
                if (!old) { p[v.event_extend.return_date_new] = { ...v, overdue: (v.total_price - (v.total_pay + v.total_pay_transfer + v.total_pay_cradit) + v.discount + v.change) }; }
                else { p[v.event_extend.return_date_new] = { ...v, overdue: (v.total_price - (v.total_pay + v.total_pay_transfer + v.total_pay_cradit) + v.discount + v.change) }; }
                return p;
            }, {}));


            this.setState({ data_detail: data_array })
            if (this.state.rental_status === 5 || this.state.rental_status === 12) {
                let item = result.data.data;
                item = item[item.length - 1];
                if (item.event_extend) {
                    this.setState({
                        return_date: item.event_extend.return_date,
                        return_time: item.event_extend.return_time,
                        return_date_new: item.event_extend.return_date_new,
                        return_time_new: item.event_extend.return_time_new,
                        bank_name: item.bank_name ? this.state.data_bank.filter(e => e.value === item.bank_name)[0] : "",
                        payment_date: item.payment_date,
                        payment_time: item.payment_time,
                        payment_type: item.payment_type === 1 ? { value: "1", label: "เงินสด" } :
                            item.payment_type === 2 ? { value: "2", label: "เงินโอน" } :
                                item.payment_type === 3 ? { value: "3", label: "บัตรเครดิต" } : null,
                        payment_picture: item.payment_picture,
                        total_pay: item.event_extend.rental_pay,
                        total_pay_transfer: item.total_pay_transfer,
                        total_pay_cradit: item.total_pay_cradit,
                        rental_price: item.event_extend.rental_price,
                        discount_pay: item.event_extend.discount,
                    })
                }
            }
            this.setState({
                point: total(result.data.data, "spend_points"),
                sk_wallet: total(result.data.data, "spend_wallet"),
                use_point: total(result.data.data, "spend_points") !== 0,
                use_sk_wallet: total(result.data.data, "spend_wallet") !== 0,
            })
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => {
            this.GetAll()
        }, 20)
    }
    clear_form() {
        this.setState({
            modal_detail: false,
            modal_detail2: false,
            modal_scale: false,

            page: 1,
            loading: false,

        })
    }
    Scale = async () => {
        if (!this.state.return_date_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่คืนรถใหม่");
            return;
        }
        if (!this.state.return_time_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาคืนรถใหม่");
            return;
        }
        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 5,
            // ชำระ/ตอนคืน
            refund_cost: Number(this.state.refund_cost), // ยอดเงินคืนก่อนกำหนด
            other_expenses: Number(this.state.other_expenses), // ค่าใช้จ่ายอื่นๆ
            fuel_cost: Number(this.state.fuel_cost), // ค่าน้ำมันรถ
            damage_cost: Number(this.state.damage_cost), // ค่าความเสียหาย
            service_charge: Number(this.state.service_charge), // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม
            return_date_new: this.state.return_date_new, // วันคืนรถใหม่
            return_time_new: this.state.return_time_new, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            payment_type: this.state.payment_type ? Number(this.state.payment_type.value) : 0,
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : null,
            payment_time: this.state.payment_time ? this.state.payment_time : null,
            payment_picture: this.state.payment_picture,
            discount: Number(this.state.discount_pay),
            return_points: 0, // แต้มคืนให้ลูกค้า
            spend_points: Number(point), // แต้มที่ใช้
            change_points: Number(point * 20), // แต้มแลกส่วนลด
            total_price: Number(this.state.rental_price),
            total_pay: Number(this.state.total_pay),
            total_pay_transfer: Number(this.state.total_pay_transfer),
            total_pay_cradit: Number(this.state.total_pay_cradit),
            change: ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet))) < 0 ? 0 : (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet)),
            event_type: 2,
            payment_list: [],
            return_wallet: 0,
            spend_wallet: Number(this.state.sk_wallet),

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "",
            admin_name: "",
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/user/list-rent")
        }
        this.setState({ loading: false });
    }

    import_file = (e, type) => { 
        if (e.target.files.length === 1) {
            this.changePhoto(e.target.files[0], type)
            document.getElementById("file_sub2").value = "";
            document.getElementById("file_sub2").file = null;
        } else {
            this.multiple_file(0, e.target.files.length, e.target.files);
        }
    }
    multiple_file = async (index, max, file) => {
        await this.changePhoto(file[index], "sub2");
        if (index + 1 < max) {
            setTimeout(() => {
                this.multiple_file(index + 1, max, file);
            }, 50);
        }
    }
    changePhoto = async (file, type) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => { }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    let payment_picture = this.state.payment_picture;
                    payment_picture.push(
                        {
                            picture_id: "",
                            picture: uri.split(",")[1]
                        }
                    )
                    this.setState({
                        payment_picture: payment_picture
                    })
                },
                "base64"
            );
        });
    }
    GetMemberByIDCard = async () => {
        if (this.state.id_card === "") {
            return
        }
        this.setState({ loading: true });
        let data = {
            data_search: this.state.id_card,
        }
        let result = await GET(tokens, "v1/TH/user-member/filter-search/page/0", data);
        if (result && result.status) {
            if (result.data.data.filter(e => e.id_card === this.state.id_card).length === 1) {
                let data = result.data.data.filter(e => e.id_card === this.state.id_card)[0];
                this.setState({
                    district: data.district,
                    province: data.province,
                    sub_district: data.sub_district,
                    zip_code: data.zip_code,
                })
            }

        }
        this.setState({ loading: false });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1={global[locale]["หน้าหลัก"]} page2={global[locale]["การเช่า"]} />
                {/* BODY */}
                <div className="body background3 min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3 text-white"><b>{global[locale]["การเช่า"]}</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* วันที่ */}
                                    <div className="wpx-200 mx-1 px-0">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            onChange={(e) => {
                                                this.setState({ filter_transfer_date: e, page: 1 });
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }}
                                            onBlur={() => {
                                                this.GetAll();
                                            }}
                                            value={this.state.filter_transfer_date}
                                        />
                                    </div>
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder={global[locale]["พิมพ์คำค้นหา..."]}
                                        onChange={(e) => { this.setState({ filter_data_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_data_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>{global[locale]["ค้นหา"]}</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_data_search: "",
                                                filter_transfer_date: "",
                                                filter_status_transfer: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>{global[locale]["รีเซ็ท"]}</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive pb-5">
                                    <table className="table table-borderless table-striped" style={{ marginBottom: 100 }}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">{global[locale]["เลขที่การเช่า"]}</th>
                                                <th className="text-center">{global[locale]["วันที่เช่า"]}</th>
                                                <th className="text-center">{global[locale]["วันที่คืน"]}</th>
                                                <th className="text-center">{global[locale]["ข้อมูลรถ"]}</th>
                                                <th className="text-center">{global[locale]["สถานะ"]}</th>
                                                <th className="text-center">{global[locale]["จัดการ"]}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={5} className="text-center"> {global[locale]["ไม่มีข้อมูล"]} </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.rental_number ? item.rental_number : "-"}</td>
                                                    <td className="text-center">{item.pick_up_date ? format_date(item.pick_up_date) : "-"}</td>
                                                    <td className="text-center">{item.return_date ? format_date(item.return_date) : "-"}</td>
                                                    <td className="text-center">{item.brand_name || item.model_name ? item.brand_name + " " + item.model_name : "-"}</td>
                                                    <td className="text-center position-relative">
                                                        {status_rent(item.rental_status === 1 ? 3 : item.rental_status)} </td>
                                                    <td className="text-center position-relative">
                                                        <label ref={this.divRef1} className="icon text-18 p-2 pointer" onClick={() => {
                                                            this.setState({ dropdowns: this.state.dropdowns === index ? -1 : index })
                                                        }}>{"\uf141"}</label>
                                                        {this.state.dropdowns === index && (
                                                            <div className="bg-white position-absolute shadow" style={{ top: 40, right: 12, zIndex: 1000 }}>
                                                                {(item.rental_status === 3 || item.rental_status === 4 || item.rental_status === 5 || item.rental_status === 7 || item.rental_status === 8 || item.rental_status === 12) && (
                                                                    <button ref={this.divRef2} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left" onClick={async () => {
                                                                        await this.clear_form();
                                                                        await this.GetById(item.rental_id);
                                                                        if (item.rental_status === 5 || item.rental_status === 12) {
                                                                            await this.GetListById(item.rental_id);
                                                                        } else {
                                                                            this.setState({ rental_price: 0, payment_type: { value: "2", label: "เงินโอน" } })
                                                                        }
                                                                        this.setState({ modal_scale: true })
                                                                    }}>
                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                        {global[locale]["ขยายสัญญา"]}
                                                                    </button>
                                                                )}
                                                                <button ref={this.divRef3} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left" onClick={async () => {
                                                                    await this.GetById(item.rental_id);
                                                                    await this.GetMemberByIDCard(this.state.id_card);

                                                                    let image = [];
                                                                    if (!window.location.href.includes(":300")) {
                                                                        for (let i of this.state.detail_picture.filter(e => e !== "")) {
                                                                            image.push(await getimgBase64(i))
                                                                        }
                                                                    }
                                                                    let signature = !window.location.href.includes(":300") && this.state.signature ? await getimgBase64(this.state.signature) : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACAQMAAABIeJ9nAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAMSURBVHicY2BgYAAAAAQAAfYXOFUAAAAASUVORK5CYII=";
                                                                    let total_day = days_between(
                                                                        new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                                                                        new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en"))),
                                                                        format_date(this.state.pick_up_date, "hh:mm"),
                                                                        format_date(this.state.return_date, "hh:mm"));

                                                                    print_paper01(
                                                                        {
                                                                            created_by: this.state.created_by,
                                                                            address: this.state.address,
                                                                            admin_name: this.state.admin_name,
                                                                            rental_id: this.state.rental_id,
                                                                            rental_number: this.state.rental_number,
                                                                            booking_number: this.state.booking_number,
                                                                            id_card: this.state.id_card,
                                                                            full_name: this.state.full_name,
                                                                            birthday: this.state.birthday,
                                                                            nationality: this.state.nationality,
                                                                            card_issue: this.state.card_issue,
                                                                            date_issue: this.state.date_issue,
                                                                            date_expire: this.state.date_expire,
                                                                            temporary_accommodation: this.state.temporary_accommodation,
                                                                            facebook: this.state.facebook,
                                                                            phone: this.state.phone,
                                                                            email: this.state.email,
                                                                            id_line: this.state.id_line,
                                                                            selected_bike: this.state.selected_bike,
                                                                            pick_up_date: this.state.pick_up_date,
                                                                            pick_up_time: this.state.pick_up_time,
                                                                            pick_up_location: this.state.pick_up_location,
                                                                            return_date: this.state.return_date,
                                                                            return_time: this.state.return_time,
                                                                            return_location: this.state.return_location,
                                                                            rental_type: this.state.rental_type,
                                                                            mileage: this.state.mileage,
                                                                            oil_volume: this.state.oil_volume,
                                                                            rental_price: this.state.rental_price,
                                                                            guarantee_type: this.state.guarantee_type,
                                                                            guarantee_amount: this.state.guarantee_amount,
                                                                            equipment_fee: this.state.equipment_fee,
                                                                            discount_price: this.state.discount_price,
                                                                            delivery_price: this.state.delivery_price,
                                                                            overdue_booking: this.state.overdue_booking,
                                                                            recommen_id: this.state.recommen_id,
                                                                            total_price: this.state.total_price,
                                                                            signature: signature,
                                                                            equipment_list: this.state.equipment_list,
                                                                            total_price_equipment: this.state.total_price_equipment,
                                                                            total_qty_equipment: this.state.total_qty_equipment,
                                                                            detail_picture: image,
                                                                            title_picture: this.state.title_picture,
                                                                            rental_status: this.state.rental_status,
                                                                            days: total_day,

                                                                            district: this.state.district,
                                                                            province: this.state.province,
                                                                            sub_district: this.state.sub_district,
                                                                            zip_code: this.state.zip_code,
                                                                        }
                                                                    )
                                                                }}>
                                                                    <label className="icon mx-2">{"\uf02f"}</label>
                                                                    {global[locale]["พิมพ์สัญญา"]}
                                                                </button>
                                                                <button ref={this.divRef4} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left"
                                                                    onClick={async () => {
                                                                        if (item.rental_status === 10) {
                                                                            this.setState({
                                                                                fuel_cost: "",
                                                                                damage_cost: "",
                                                                                service_charge: "",
                                                                                other_expenses: "",
                                                                                discount: "",
                                                                                payment_type: "",
                                                                                bank_name: "",
                                                                                payment_date: "",
                                                                                payment_time: "",
                                                                                total_pay: "",
                                                                                total_pay_transfer: "",
                                                                                total_pay_cradit: "",
                                                                                rental_price_overdue: 0
                                                                            })
                                                                            await this.GetById(item.rental_id);
                                                                            await this.GetListById(item.rental_id);
                                                                            this.setState({ modal_return: true })
                                                                        } else {
                                                                            await this.GetById(item.rental_id);
                                                                            await this.GetListById(item.rental_id);
                                                                            this.setState({ modal_payment: true })
                                                                        }
                                                                    }}>
                                                                    <label className="icon mx-2">{"\uf15b"}</label>
                                                                    {global[locale]["รายละเอียด"]}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* pagination */}
                            {this.state.data && this.state.data.data.length !== 0 && (
                                <div className="d-flex justify-content-between row mx-0 w-100 px-3 text-white my-3">
                                    <div><label> {global[locale]["แสดง"]} {this.state.data.end_index} {global[locale]["รายการ"]} {global[locale]["จาก"]} {this.state.data.count} {global[locale]["รายการ"]} </label></div>
                                    <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                                </div>
                            )}
                        </div>
                    </div>

                </div>
                {/* MODAL */}
                {/* ชำระเงิน */}
                <Modal show={this.state.modal_payment} onHide={() => { this.setState({ modal_payment: false }) }} size="lg">
                    <Modal.Body>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <h3><b>{global[locale]["รับชำระใบเช่า"]} {this.state.rental_number}</b></h3>
                            {status_rent(this.state.rental_status === 1 ? 3 : this.state.rental_status)}
                        </div>
                        <hr />
                        <div className="row">
                            {/* รหัสบัตรประชาชน หรือ พาสปอร์ต */}
                            <div className="col-12 col-md-6">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">{global[locale]["รหัสบัตรประชาชน หรือ พาสปอร์ต"]}{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ id_card: e.target.value })
                                        }}
                                        value={this.state.id_card}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* ชื่อ- นามสกุล */}
                            <div className="col-12 col-md-6">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">{global[locale]["ชื่อ - นามสกุล"]}{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ full_name: e.target.value })
                                        }}
                                        value={this.state.full_name}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* เบอร์โทรศัพท์ */}
                            <div className="col-12 col-md-6">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">{global[locale]["เบอร์โทรศัพท์"]}{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ phone: e.target.value })
                                        }}
                                        value={this.state.phone}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* อีเมล */}
                            <div className="col-12 col-md-6">
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">{global[locale]["อีเมล"]}{required}:</label>
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                        value={this.state.email}
                                        readOnly
                                    />
                                </div>
                            </div>
                            {/* ข้อมูลรถ */}
                            <div className="col-12">
                                <h4><b>{global[locale]["ข้อมูลรถ"]}</b></h4>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3 text-center">
                                        <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-fluid w-100 rounded" style={{ maxHeight: 300, objectFit: "contain" }} />
                                    </div>
                                    <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                        <h6><b>รายละเอียดรถ</b></h6>
                                        <div className="row">
                                            <div className="col-12 d-flex mb-2">
                                                <b className="wpx-50">{global[locale]["ยี่ห้อ"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">{global[locale]["ชื่อรุ่น"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">{global[locale]["ทะเบียน"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.license_plate : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">{global[locale]["สี"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_color : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-50">{global[locale]["ปี"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_year + 543 : "-"}</label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <b className="wpx-100">{global[locale]["หมายเลขรถ"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_channel_name : "-"}</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6><b>{global[locale]["วันที่รับ / คืนรถ"]}</b></h6>
                                        <div className="d-flex justify-content-between">
                                            <div className="w-100">
                                                <b className="wpx-40">{global[locale]["วันที่รับรถ"]}</b>
                                                <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                <b className="my-2">{global[locale]["สถานที่รับรถ"]}</b>
                                                <label className="ml-2 text-orange">{this.state.pick_up_location ? this.state.pick_up_location : "-"} </label>
                                            </div>
                                            <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                <label className="icon text-24 text-orange">{"\uf356"}</label>
                                            </div>
                                            <div className="w-100">
                                                <b className="wpx-40">{global[locale]["วันที่คืนรถ"]}</b>
                                                <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                <b className="my-2">{global[locale]["สถานที่คืนรถ"]}</b>
                                                <label className="ml-2 text-orange">{this.state.return_location ? this.state.return_location : "-"} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ฟอร์มชำระเงิน */}
                            <div className="col-12">
                                <h5 className="mt-3"><b>{global[locale]["ฟอร์มชำระเงิน"]}</b></h5>
                                <hr />
                                <div className="row mx-0">
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ราคาเช่ารถ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["ราคาเช่ารถ"]}
                                                {this.state.rental_type &&
                                                    this.state.rental_type.value === "daily" ? " ( รายวัน ) " :
                                                    this.state.rental_type.value === "weekly" ? " ( รายสัปดาห์ ) " :
                                                        this.state.rental_type.value === "monthly" ? " ( รายเดือน ) " : " "}
                                                :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.rental_price)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค้ำประกัน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["ค้ำประกัน"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.guarantee_type &&
                                                    this.state.guarantee_type.value === "1" ?
                                                    "เงินประกัน ( " + toFixed(this.state.guarantee_amount) + " บาท )" :
                                                    this.state.guarantee_type.value === "2" ?
                                                        "สำเนาบัตรประชาชน" :
                                                        this.state.guarantee_type.value === "3" ?
                                                            "บัตรประชาชนตัวจริง" :
                                                            this.state.guarantee_type.value === "41" ?
                                                                "บัตรข้าราชการ" :
                                                                this.state.guarantee_type.value === "5" ?
                                                                    "บัตรนักศึกษา" :
                                                                    this.state.guarantee_type.value === "6" ?
                                                                        "พาสปอร์ตตัวจริง" :
                                                                        this.state.guarantee_type.value === "7" ?
                                                                            "สำเนาพาสปอร์ต" :
                                                                            this.state.guarantee_type.value === "8" ?
                                                                                "ใบขับขี่" :
                                                                                this.state.guarantee_type.value === "9" ?
                                                                                    "ใบขับขี่ตัวจริง" :
                                                                                    this.state.guarantee_type.value === "10" ?
                                                                                        "เงินหยวน" :
                                                                                        this.state.guarantee_type.value === "11" ?
                                                                                            "เงินดอลล่า" :
                                                                                            this.state.guarantee_type.value === "12" ?
                                                                                                "เงินยูโร" :
                                                                                                ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค่าอุปกรณ์ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["ค่าอุปกรณ์"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.equipment_fee)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค่าขนส่ง */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["ค่าบริการรับส่ง"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.delivery_price)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ส่วนลด */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["ส่วนลด"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.discount_price)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ค่าอื่น ๆ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["ค่าอื่น ๆ"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={toFixed(this.state.other_price)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    {this.state.total_pay_booking && (
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* ยอดจองค้างชำระ */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">{global[locale]["ยอดจองชำระแล้ว"]} :</label><br />
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    value={toFixed(this.state.total_pay_booking)}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ใช้แต้ม */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["ใช้แต้ม"]} :</label><br />
                                            <div className="d-flex align-items-center">
                                                <Switch onChange={(e) => {
                                                    this.setState({
                                                        use_point: e,
                                                        point: 0
                                                    })
                                                }} checked={this.state.use_point} disabled />
                                                <label className="mx-2">{global[locale]["แต้มสะสม"]} <b>{toFixed(this.state.wallet.point)}</b> {global[locale]["แต้ม"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.use_point && (
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* จำนวนแต้ม */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">{global[locale]["จำนวนแต้ม"]} : <small>({global[locale]["10 แต้ม เท่ากับ 200 บาท"]})</small></label><br />
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        if (!float(e.target.value)) {
                                                            return;
                                                        }
                                                        if (Number(e.target.value) > this.state.wallet.point) {
                                                            e.target.value = this.state.wallet.point;
                                                        }
                                                        this.setState({ point: e.target.value })
                                                    }}
                                                    onBlur={() => {
                                                        let point = 0;
                                                        if (Number(this.state.point) % 10 !== 0) {
                                                            point = Number(this.state.point) - (Number(this.state.point) % 10);
                                                        } else {
                                                            point = this.state.point;
                                                        }
                                                        this.setState({ point: point });
                                                    }}
                                                    value={this.state.point}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {this.state.use_point && (
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* แลกส่วนลดได้ */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">{global[locale]["แลกส่วนลดได้"]} :</label><br />
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    value={toFixed(Number(this.state.point) * 20)}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-sm-6 col-md-4 px-1">
                                        {/* ใช้เงินสะสม */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["ใช้เงินสะสม"]} :</label><br />
                                            <div className="d-flex align-items-center">
                                                <Switch onChange={(e) => {
                                                    this.setState({
                                                        use_sk_wallet: e,
                                                        sk_wallet: 0
                                                    })
                                                }} checked={this.state.use_sk_wallet} disabled />
                                                <label className="mx-2">{global[locale]["เงินสะสม"]} <b>{toFixed(this.state.wallet.sk_wallet)}</b> {global[locale]["บาท"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.use_sk_wallet && (
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* ต้องการใช้เงินสะสม */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">{global[locale]["ต้องการใช้เงินสะสม"]} :</label><br />
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        if (!float(e.target.value)) {
                                                            return;
                                                        }
                                                        if (Number(e.target.value) > this.state.wallet.sk_wallet) {
                                                            e.target.value = this.state.wallet.sk_wallet;
                                                        }
                                                        this.setState({ sk_wallet: e.target.value })
                                                    }}
                                                    value={this.state.sk_wallet}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {this.state.use_sk_wallet && (
                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                            {/* แลกส่วนลดได้ */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">{global[locale]["แลกส่วนลดได้"]} :</label><br />
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    value={toFixed(Number(this.state.sk_wallet))}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 px-1">
                                        <div className="card bg-orange p-3 mb-3">
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <label className="text-white">{global[locale]["ยอดชำระเงินทั้งหมด"]}</label>
                                                    <div className="w-100 card bg-white text-center">
                                                        <h3 className="text-orange"><b>{toFixed(Number(this.state.rental_price) + Number(this.state.equipment_fee) + Number(this.state.delivery_price) + Number(this.state.guarantee_amount) - Number(this.state.discount_price) + Number(this.state.other_price) + Number(this.state.overdue_booking))}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex justify-content-center">
                            <button
                                className="btn btn-outline-orange px-5"
                                onClick={() => {
                                    this.setState({ modal_payment: false })
                                }}
                            >
                                {global[locale]["ปิดหน้าต่าง"]}
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* คืนรถ */}
                <Modal show={this.state.modal_return} onHide={() => { this.setState({ modal_return: false }) }} dialogClassName="modal-w95">
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                    <h3><b>{global[locale]["ใบเช่า"]} {this.state.rental_number}</b></h3>
                                    {status_rent(this.state.rental_status === 1 ? 3 : this.state.rental_status)}
                                </div>
                                <hr />
                                <div className="row mb-3">
                                    {/* รหัสบัตรประชาชน หรือ พาสปอร์ต */}
                                    <div className="col-12 col-md-6">
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">{global[locale]["รหัสบัตรประชาชน หรือ พาสปอร์ต"]}{required}:</label>
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ id_card: e.target.value })
                                                }}
                                                value={this.state.id_card}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    {/* ชื่อ- นามสกุล */}
                                    <div className="col-12 col-md-6">
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">{global[locale]["ชื่อ - นามสกุล"]}{required}:</label>
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ full_name: e.target.value })
                                                }}
                                                value={this.state.full_name}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    {/* เบอร์โทรศัพท์ */}
                                    <div className="col-12 col-md-6">
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">{global[locale]["เบอร์โทรศัพท์"]}{required}:</label>
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ phone: e.target.value })
                                                }}
                                                value={this.state.phone}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    {/* อีเมล */}
                                    <div className="col-12 col-md-6">
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">{global[locale]["อีเมล"]}{required}:</label>
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ email: e.target.value })
                                                }}
                                                value={this.state.email}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    {/* ข้อมูลรถ */}
                                    <div className="col-12">
                                        <h4><b>{global[locale]["ข้อมูลรถ"]}</b></h4>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3">
                                                <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-responsive w-100 rounded" style={{ height: "100%", objectFit: "contain" }} />
                                            </div>
                                            <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                                <h6><b>{global[locale]["รายละเอียดรถ"]}</b></h6>
                                                <div className="row">
                                                    <div className="col-12 d-flex mb-2">
                                                        <b className="wpx-50">{global[locale]["ยี่ห้อ"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex mb-2">
                                                        <b className="wpx-50">{global[locale]["ชื่อรุ่น"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex mb-2">
                                                        <b className="wpx-50">{global[locale]["ทะเบียน"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.license_plate : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex mb-2">
                                                        <b className="wpx-50">{global[locale]["สี"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_color : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex mb-2">
                                                        <b className="wpx-50">{global[locale]["ปี"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_year + 543 : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex mb-2">
                                                        <b className="wpx-100">{global[locale]["หมายเลขรถ"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_channel_name : "-"}</label>
                                                    </div>
                                                </div>
                                                <hr />
                                                <h6><b>{global[locale]["วันที่รับ / คืนรถ"]}</b></h6>
                                                <div className="d-flex justify-content-between">
                                                    <div className="w-100">
                                                        <b className="wpx-40">{global[locale]["วันที่รับรถ"]}</b>
                                                        <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                        <b className="my-2">{global[locale]["สถานที่รับรถ"]}</b>
                                                        <label className="ml-2 text-orange">{this.state.pick_up_location ? this.state.pick_up_location : "-"} </label>
                                                    </div>
                                                    <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                        <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                    </div>
                                                    <div className="w-100">
                                                        <b className="wpx-40">{global[locale]["วันที่คืนรถ"]}</b>
                                                        <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                        <b className="my-2">{global[locale]["สถานที่คืนรถ"]}</b>
                                                        <label className="ml-2 text-orange">{this.state.return_location ? this.state.return_location : "-"} </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <h4><b>{global[locale]["ยอดเงินมัดจำ"]}</b></h4>
                                        <div className="table-responsive">
                                            <table className="table table-borderless table-striped">
                                                <thead>
                                                    <tr className="bg-dark text-white">
                                                        <th className="text-center">{global[locale]["เลขที่ใบมัดจำ"]}</th>
                                                        <th className="text-center">{global[locale]["วันที่ขยายสัญญา"]}</th>
                                                        <th className="text-center">{global[locale]["หมายเหตุ"]}</th>
                                                        <th className="text-center">{global[locale]["ยอดมัดจำ"]}</th>
                                                        <th className="text-center">{global[locale]["สถานะ"]}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(!this.state.data_detail || this.state.data_detail.length === 0) && (
                                                        <tr> <td colSpan={6} className="text-center"> {global[locale]["ไม่มีข้อมูล"]} </td> </tr>
                                                    )}
                                                    {this.state.data_detail && this.state.data_detail.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{item.payment_number ? item.payment_number : "-"}</td>
                                                            <td className="text-center">{item.event_extend ? format_date(item.event_extend.return_date_new) + " " + item.event_extend.return_time_new : "-"}</td>
                                                            <td className="text-center">{item.event_extend ? item.event_extend.remark : "-"}</td>
                                                            <td className="text-center">{item.total_price ? toFixed(item.total_price + item.discount) : "-"}</td>
                                                            <td className="text-center">
                                                                <span className="bg-success rounded px-2 py-1 text-white">{global[locale]["ชำระแล้ว"]}</span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ราคาเช่ารถ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">{global[locale]["ราคาเช่ารถ"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ rental_price: e.target.value })
                                                }}
                                                value={this.state.rental_price}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* จำนวนเงินค้ำประกัน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">{global[locale]["จำนวนเงินค้ำประกัน"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ guarantee_amount: e.target.value })
                                                }}
                                                value={this.state.guarantee_amount}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าเช่าเกินกำหนด */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">{global[locale]["ค่าเช่าเกินกำหนด"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ rental_price_overdue: e.target.value })
                                                }}
                                                value={this.state.rental_price_overdue}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าน้ำมัน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">{global[locale]["ค่าน้ำมัน"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ fuel_cost: e.target.value })
                                                }}
                                                value={this.state.fuel_cost}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าเสียหาย */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">{global[locale]["ค่าเสียหาย"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ damage_cost: e.target.value })
                                                }}
                                                value={this.state.damage_cost}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าบริการรับส่ง */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">{global[locale]["ค่าบริการรับส่ง"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ service_charge: e.target.value })
                                                }}
                                                value={this.state.service_charge}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {/* ค่าอื่นๆ */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">{global[locale]["ค่าอื่นๆ"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ other_expenses: e.target.value })
                                                }}
                                                value={this.state.other_expenses}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-3">
                                        {/* ส่วนลด */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-1">{global[locale]["ส่วนลด"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ discount_pay: e.target.value })
                                                }}
                                                value={this.state.discount_pay}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-orange p-3 mb-3">
                            <div className="row">
                                <div className="col-6 text-center">
                                    <label className="text-white mb-1">{global[locale]["ยอดเงินคืนก่อนกำหนด"]}</label>
                                    <div className="w-100 card bg-white text-center">
                                        <h3 className="text-orange"><b>
                                            {
                                                toFixed(
                                                    Number(this.state.refund_cost)
                                                )
                                            }
                                        </b></h3>
                                    </div>
                                </div>
                                <div className="col-6 text-center">
                                    <label className="text-white mb-1">{global[locale]["ยอดชำระเงินทั้งหมด"]}</label>
                                    <div className="w-100 card bg-white text-center">
                                        <h3 className="text-orange"><b>
                                            {
                                                (
                                                    Number(this.state.fuel_cost) +
                                                    Number(this.state.damage_cost) +
                                                    Number(this.state.service_charge) +
                                                    Number(this.state.other_expenses) -
                                                    Number(this.state.discount_pay) -
                                                    Number(this.state.guarantee_amount) +
                                                    (this.state.data_detail ? total(this.state.data_detail, "overdue") : 0)
                                                ) < 0 ? "0.00" :
                                                    toFixed(
                                                        Number(this.state.fuel_cost) +
                                                        Number(this.state.damage_cost) +
                                                        Number(this.state.service_charge) +
                                                        Number(this.state.other_expenses) -
                                                        Number(this.state.discount_pay) -
                                                        Number(this.state.guarantee_amount) -
                                                        (Number(this.state.point) * 20) -
                                                        Number(this.state.sk_wallet) +
                                                        (this.state.data_detail ? total(this.state.data_detail, "overdue") : 0)
                                                    )
                                            }
                                        </b></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex justify-content-center">
                            <button
                                className="btn btn-outline-orange px-5"
                                onClick={() => {
                                    this.setState({ modal_return: false })
                                }}
                            >
                                {global[locale]["ปิดหน้าต่าง"]}
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* ขยายสัญญา */}
                <Modal
                    show={this.state.modal_scale}
                    onHide={() => { this.setState({ modal_scale: false }) }}
                    size="lg"
                >
                    <Modal.Body>

                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <h3><b>{global[locale]["ขยายสัญญาใบเช่า"]} {this.state.rental_number}</b></h3>
                            {status_rent(this.state.rental_status === 1 ? 3 : this.state.rental_status)}
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12 col-xl-7">
                                <div className="row">
                                    <div className="col-5">
                                        <label className="w-100 mb-1">{global[locale]["วันที่เริ่มต้นการเช่า"]} :</label><br />
                                        <label className="w-100 mb-1">{format_date(this.state.pick_up_date, "dd/mm/yyyy")} {this.state.pick_up_time}</label>
                                    </div>
                                    <div className="col-5">
                                        <label className="w-100 mb-1">{global[locale]["วันที่สิ้นสุดการเช่า"]} :</label><br />
                                        <label className="w-100 mb-1">{format_date(this.state.return_date, "dd/mm/yyyy")} {this.state.return_time}</label>
                                    </div>
                                    <div className="col-2">
                                        <label className="w-100 mb-1">{global[locale]["จำนวน"]} :</label><br />
                                        <label className="w-100 mb-1">{this.state.days + 1} {global[locale]["วัน"]} <label className="text-success">{this.state.return_date_new ? "(+" + days_between(new Date(Number(format_date(this.state.return_date, "yyyy,mm,dd", "en"))),
                                            new Date(Number(format_date(this.state.return_date_new, "yyyy,mm,dd", "en"))),
                                        ) + " วัน)" : ""}</label></label>
                                    </div>
                                    <div className="col-12"><hr /></div>
                                    <div className="col-6">
                                        {/* วันที่คืนรถใหม่ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">{global[locale]["วันที่คืนรถใหม่"]} :</label><br />
                                            <DatePickerTH
                                                className="form-control validate"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ return_date_new: e });
                                                    setTimeout(() => {
                                                        let days = days_between(
                                                            new Date(Number(format_date(this.state.return_date, "yyyy,mm,dd", "en"))),
                                                            new Date(Number(format_date(this.state.return_date_new, "yyyy,mm,dd", "en"))),
                                                            this.state.return_time,
                                                            this.state.return_time_new
                                                        )
                                                        let hours_price = 0;
                                                        let rental_price = 0;
                                                        if (days.hour === 0 && days.minute >= 0) {
                                                            //ไม่ต้องหัก 
                                                        } else if (days.hour === 1 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 50; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 100; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 200; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 2 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 100; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 200; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 400; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 3 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 150; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 300; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 600; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour >= 4) {
                                                            hours_price = this.state.array_price.daily_price;//หัก daily_price
                                                        }
                                                        setTimeout(() => {
                                                            rental_price = this.state.array_price.daily_price * days.day;
                                                            rental_price += hours_price;
                                                            this.setState({ rental_price: rental_price })
                                                        }, 10);
                                                    }, 10);
                                                }}
                                                value={this.state.return_date_new}
                                                min={new Date(this.state.return_date).setDate(new Date(this.state.return_date).getDate() - 1)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* เวลาคืนรถใหม่ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100 mb-1">{global[locale]["เวลาคืนรถใหม่"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="time"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ return_time_new: e.target.value })
                                                    setTimeout(() => {
                                                        let days = days_between(
                                                            new Date(Number(format_date(this.state.return_date, "yyyy,mm,dd", "en"))),
                                                            new Date(Number(format_date(this.state.return_date_new, "yyyy,mm,dd", "en"))),
                                                            this.state.return_time,
                                                            this.state.return_time_new
                                                        )
                                                        let hours_price = 0;
                                                        let rental_price = 0;
                                                        if (days.hour === 0 && days.minute >= 0) {
                                                            //ไม่ต้องหัก 
                                                        } else if (days.hour === 1 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 50; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 100; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 200; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 2 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 100; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 200; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 400; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour === 3 && days.minute >= 0) {
                                                            if (this.state.array_price.daily_price < 400) {
                                                                hours_price = 150; //หัก 50 บาท
                                                            } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                hours_price = 300; //หัก 100 บาท
                                                            } else if (this.state.array_price.daily_price > 700) {
                                                                hours_price = 600; //หัก 200 บาท
                                                            }
                                                        } else if (days.hour >= 4) {
                                                            hours_price = this.state.array_price.daily_price;//หัก daily_price
                                                        }
                                                        setTimeout(() => {
                                                            rental_price = this.state.array_price.daily_price * days.day;
                                                            rental_price += hours_price;
                                                            this.setState({ rental_price: rental_price })
                                                        }, 10);
                                                    }, 10);
                                                }}
                                                value={this.state.return_time_new}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* เงินสด */}
                                    {/* <div className="col-6">
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">เงินสด :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ total_pay: e.target.value })
                                                }}
                                                value={this.state.total_pay}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-6">
                                        {/* เงินโอน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["เงินโอน"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ total_pay_transfer: e.target.value })
                                                }}
                                                value={this.state.total_pay_transfer}
                                            />
                                        </div>
                                    </div>
                                    {/* บัตรเครดิต */}
                                    {/* <div className="col-6">
                                       
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">บัตรเครดิต :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return;
                                                    }
                                                    this.setState({ total_pay_cradit: e.target.value })
                                                }}
                                                value={this.state.total_pay_cradit}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-6">
                                        {/* วันที่โอน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["วันที่โอน"]} :</label><br />
                                            <DatePickerTH
                                                className="form-control"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ payment_date: e });
                                                }}
                                                value={this.state.payment_date}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* เวลาที่โอน */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["เวลาที่โอน"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="time"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.payment_time}
                                                onChange={(e) => { this.setState({ payment_time: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* บัญชี */}
                                        <div className="w-100 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["บัญชี"]} :</label><br />
                                            <Select
                                                className="form-control select-search bg-white mb-2"
                                                styles={select_style}
                                                options={this.state.data_bank ? this.state.data_bank : []}
                                                onChange={(e) => {
                                                    this.setState({ bank_name: e });
                                                }}
                                                value={this.state.bank_name}
                                            ></Select>
                                        </div>
                                    </div>
                                </div>
                                {/* หมายเหตุ */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-1">{global[locale]["หมายเหตุ"]} :</label><br />
                                    <textarea
                                        className="form-control validate"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ remark: e.target.value })
                                        }}
                                        value={this.state.remark}
                                    />
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 px-1">
                                        <div className="card bg-orange p-3 mb-3">
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <label className="text-white">{global[locale]["ยอดชำระเงินทั้งหมด"]}</label>
                                                    <div className="w-100 card bg-white text-center">
                                                        <h3 className="text-orange"><b>{toFixed(Number(this.state.rental_price))}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5 pb-3">
                                <div className="row">
                                    <input className="d-none" type="file" id="file_sub2" accept="image/*" onChange={(e) => { this.import_file(e, "sub2") }} multiple />
                                    {this.state.payment_picture.map((item, index) => (
                                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                            <div
                                                className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                            >
                                                <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                    <img
                                                        alt="รูปภาพสลิปหลักฐาน"
                                                        src={item.picture.includes("https://") ? item.picture : "data:image/png;base64," + item.picture}
                                                        className="w-100 hpx-140 rounded overflow-hidden"
                                                        style={{ objectFit: "contain" }}
                                                        onClick={() => {
                                                            this.setState({
                                                                modal_picture2: true,
                                                                modal_payment: false,
                                                                type_modal: "modal_payment",
                                                                index: index
                                                            })
                                                        }}
                                                    ></img>
                                                    <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => {
                                                        let payment_picture = this.state.payment_picture;
                                                        payment_picture.splice(index, 1);
                                                        this.setState({ payment_picture: payment_picture })
                                                    }}>
                                                        {"\uf00d"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {this.state.payment_picture.length === 0 ? (
                                        <div
                                            className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                            onClick={() => { document.getElementById("file_sub2").click() }}
                                        >
                                            <label className="icon text-orange">{"\uf03e"}</label>
                                            <label className="text-orange text-12">{global[locale]["รูปภาพสลิปหลักฐาน"]}</label>
                                        </div>
                                    ) : (
                                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                            <div
                                                className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                onClick={() => { document.getElementById("file_sub2").click() }}
                                            >
                                                <label className="icon text-orange">{"\uf03e"}</label>
                                                <label className="text-orange text-12">{global[locale]["รูปภาพสลิปหลักฐาน"]}</label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_scale: false })
                                    }}
                                >
                                    {global[locale]["ยกเลิก"]}
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.Scale();
                                    }}
                                >
                                    {global[locale]["ขยายสัญญา"]}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
