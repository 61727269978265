import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarCustomer.js";
import Select from "react-select";
import MD5 from "crypto-js/md5";
import {
    tokens,
    select_style,
    alert,
    success_url,
    GET,
    PUT,
    float,
    validate,
    required,
    DatePickerTH,
    getStorage,
    locale,
    validateEmail,
    validateENNumber
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Resizer from "react-image-file-resizer";

import province from "../../assets/files/province.json"
import district from "../../assets/files/district.json"
import subdistrict from "../../assets/files/subdistrict.json"

export default class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_detail: false,
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            data: null,

            member_id: "",
            full_name: "",
            address: "",
            email: "",
            phone: "",
            id_card: "",
            address: "",
            birthday: "",
            member_code: "",
            member_id: "",
            member_status: "",
            picture: "",
            username: "",
            zip_code: "",
            points: 0,
            reward: 0,

            old_password: "",
            new_password: "",
            confirm_password: "",

            nationality: "",
            card_issue: "",
            date_issue: "",
            date_expire: "",
            facebook: "",
            id_line: "",

        }
    }
    async componentDidMount() {
        let user = getStorage("user");
        this.GetById(user.member_id);
    }
    GetById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/user-admin/profile", null);
        if (result && result.status) {
            let data = result.data.data;
            this.setState({
                member_id: data.member_id,
                full_name: data.full_name,
                address: data.address,
                email: data.email,
                phone: data.phone,
                id_card: data.id_card,
                birthday: data.birthday,
                member_code: data.member_code,
                member_status: data.member_status,
                picture: data.picture,
                username: data.username,
                zip_code: data.zip_code,
                points: data.points,
                reward: data.sk_wallet,
                nationality: data.nationality,
                card_issue: data.card_issue,
                date_issue: data.date_issue,
                date_expire: data.date_expire,
                facebook: data.facebook,
                id_line: data.id_line,
            })
            if (data.province) {
                this.setState({
                    province: province.filter(e => e.name_th === data.province).map(e => { { return { value: e.name_th, label: e.name_th, id: e.province_id } } })[0]
                })
            }
            if (data.district) {
                this.setState({
                    district: district.filter(e => e.name_th === data.district).map(e => { { return { value: e.name_th, label: e.name_th, id: e.district_id } } })[0]
                })
            }
            if (data.sub_district) {
                this.setState({
                    sub_district: subdistrict.filter(e => e.name_th === data.sub_district).map(e => { { return { value: e.name_th, label: e.name_th, zip_code: e.zip_code, id: e.sub_district_id } } })[0]
                })
            }
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => {
            this.GetAll()
        }, 20)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_import: false,

            page: 1,
            loading: false,

        })
    }
    Update = async () => {
        if (validate(this.state, ["id_card", "full_name", "phone"])) { return };
        if (this.state.phone.length > 0 && this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน")
            return
        } else if (this.state.email && this.state.email.length !== 0 && !validateEmail(this.state.email)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        }
        if (this.state.old_password === "" && this.state.new_password === "" && this.state.confirm_password === "") {

        } else if (this.state.old_password !== "" && MD5(this.state.old_password).toString() !== getStorage("5f4dcc3b5aa765d61d8327deb882cf99")) {
            alert("warning", "แจ้งเตือน", "รหัสผ่านเดิมไม่ถูกต้อง")
            return
        } else if (this.state.new_password === "" || this.state.confirm_password === "") {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน")
            return
        } else if (this.state.new_password !== this.state.confirm_password) {
            alert("warning", "แจ้งเตือน", "รหัสผ่านไม่ตรงกัน")
            return
        }
        this.setState({ loading: true });
        let data = {
            member_id: this.state.member_id,
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            birthday: this.state.birthday ? this.state.birthday : null,
            address: this.state.address,
            province: this.state.province ? this.state.province.value : "",
            district: this.state.district ? this.state.district.value : "",
            sub_district: this.state.sub_district ? this.state.sub_district.value : "",
            zip_code: this.state.zip_code,
            email: this.state.email,
            phone: this.state.phone,
            picture: this.state.picture,
            username: this.state.username,
            password: this.state.new_password,
            member_status: this.state.member_status ? this.state.member_status : 1,
            is_active: true,
            nationality: this.state.nationality,
            card_issue: this.state.card_issue,
            date_issue: this.state.date_issue,
            date_expire: this.state.date_expire,
            facebook: this.state.facebook,
            id_line: this.state.id_line,
        }
        let result = await PUT(tokens, "v1/TH/user-member/update", data);
        if (result && result.status) {
            let user = getStorage("user");
            user = { ...user, ...data }
            localStorage.setItem("user", JSON.stringify(user));
            if (this.state.new_password) {
                localStorage.setItem("5f4dcc3b5aa765d61d8327deb882cf99", MD5(this.state.new_password).toString());
            }
            success_url("/user/profile");
        }
        this.setState({ loading: false });

    }

    import_file = (e) => {
        this.changePhoto(e.target.files[0])
        document.getElementById("file").value = "";
        document.getElementById("file").file = null;
    }
    drop_file = (e) => {
        e.preventDefault();
        this.setState({ over: false });
        this.changePhoto(e.dataTransfer.items[0].getAsFile())
    }
    changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            this.setState({
                picture: e.target.result.split(",")[1],
            })
        }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    this.setState({ picture: uri.split(",")[1] })
                },
                "base64"
            );
        });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1={global[locale]["หน้าหลัก"]} page2={global[locale]["ข้อมูลส่วนตัว"]} />
                {/* BODY */}
                <div className="body background3 min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3 text-white"><b>{global[locale]["ข้อมูลส่วนตัว"]}</b></h4>
                            {/* <button className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/user/choose-car" }}>
                                <label className="icon mr-2" > {"\uf060"} </label>
                                ย้อนกลับ
                            </button> */}
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-1 px-1"></div>
                            <div className="col-12 col-xl-3 px-1 mb-3">
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-2">
                                        {this.state.picture === "" ? (
                                            <div
                                                className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5 mb-3"
                                                style={{ height: "100%", minHeight: 400 }}
                                                onClick={() => { document.getElementById("file").click() }}
                                                onDrop={(e) => this.drop_file(e)}
                                                onDragOver={(e) => { this.setState({ over: true }); e.preventDefault() }}
                                                onDragLeave={(e) => { this.setState({ over: false }); e.preventDefault() }}
                                            >
                                                <label className="icon text-orange">{"\uf03e"}</label>
                                                <label className="text-orange">อัปโหลดภาพ</label>
                                            </div>
                                        ) : (
                                            <div
                                                className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                            >
                                                <div className="position-relative w-100">
                                                    <img alt="รูปโปรไฟล์" src={this.state.picture.includes("https://") ? this.state.picture : "data:image/png;base64," + this.state.picture} className="w-100 rounded overflow-hidden"></img>
                                                    <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.setState({ picture: "" }) }}>
                                                        {"\uf00d"}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e) }} />

                                        <div className="w-100 text-center">
                                            <label className="icon mr-2">{"\uf2bd"}</label><label className="mb-2">{global[locale]["ชื่อสมาชิก"]}</label><br />
                                            <h4 className="text-orange mb-2">S.K. Service</h4>
                                            <hr />
                                            <label className="icon mr-2">{"\uf145"}</label><label className="mb-2">{global[locale]["คะแนนสะสม"]}</label><br />
                                            <h3 className="text-orange mb-2"><b>{this.state.points}</b></h3>
                                            <label className="mb-2">{global[locale]["คะแนน"]}</label>
                                            <hr />
                                            <label className="icon mr-2">{"\uf145"}</label><label className="mb-2">{global[locale]["ยอดเงินสะสม"]}</label><br />
                                            <h3 className="text-orange mb-2"><b>{this.state.reward}</b></h3>
                                            <label className="mb-2">{global[locale]["บาท"]}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-7 mb-3">
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-3">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-4">
                                                {/* รหัสบัตรประชาชนหรือพาสปอร์ต */}
                                                <div className="w-100 mb-3">
                                                    <label className="w-100">{global[locale]["รหัสบัตรประชาชนหรือพาสปอร์ต"]}{required}:</label>
                                                    <input
                                                        className="form-control "
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        maxLength={13}
                                                        onChange={(e) => {
                                                            if (!validateENNumber(e.target.value) && e.target.value !== "") {
                                                                return
                                                            }
                                                            this.setState({ id_card: e.target.value })
                                                        }}
                                                        value={this.state.id_card}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                {/* ชื่อ-นามสกุล */}
                                                <div className="w-100 mb-3">
                                                    <label className="w-100">{global[locale]["ชื่อ-นามสกุล"]}{required}:</label>
                                                    <input
                                                        className="form-control "
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            this.setState({ full_name: e.target.value })
                                                        }}
                                                        value={this.state.full_name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                {/* วัน /เดือน /ปีเกิด */}
                                                <div className="w-100 mb-3">
                                                    <label className="w-100">{global[locale]["วัน /เดือน /ปีเกิด"]} :</label>
                                                    <DatePickerTH
                                                        className="form-control"
                                                        placeholder="พิมพ์คำค้นหา..."
                                                        onChange={(e) => {
                                                            this.setState({ birthday: e });
                                                        }}
                                                        value={this.state.birthday}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                <label className="w-100">{global[locale]["สัญชาติ"]} :</label><br />
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ nationality: e.target.value })
                                                    }}
                                                    value={this.state.nationality}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                {/* ที่อยู่ */}
                                                <div className="w-100 mb-3">
                                                    <label className="w-100">{global[locale]["ที่อยู่"]} :</label>
                                                    <input
                                                        className="form-control "
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            this.setState({ address: e.target.value })
                                                        }}
                                                        value={this.state.address}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                <label className="w-100">{global[locale]["จังหวัด"]} : </label>
                                                <Select
                                                    className="form-control select-search"
                                                    styles={select_style}
                                                    options={province.map(item => { { return { value: item.name_th, label: item.name_th, id: item.province_id } } })}
                                                    onChange={(e) => {
                                                        this.setState({ province: e, district: null, sub_district: null, zip_code: "" });
                                                    }}
                                                    value={this.state.province}
                                                ></Select>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                <label className="w-100"> {global[locale]["อำเภอ / เขต"]} : </label>
                                                <Select
                                                    className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                                                    styles={select_style}
                                                    options={district.filter(item => this.state.province && this.state.province.id === item.province_id).map(item => { { return { value: item.name_th, label: item.name_th, id: item.district_id } } })}
                                                    onChange={(e) => {
                                                        this.setState({ district: e, sub_district: null, zip_code: "" });
                                                    }}
                                                    value={this.state.district}
                                                    isDisabled={!this.state.province}
                                                ></Select>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                <label className="w-100">{global[locale]["ตำบล / แขวง"]} : </label>

                                                <Select
                                                    className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                                                    styles={select_style}
                                                    options={subdistrict.filter(item => this.state.district && this.state.district.id === item.district_id).map(item => { { return { value: item.name_th, label: item.name_th, zip_code: item.zip_code, id: item.sub_district_id } } })}
                                                    onChange={(e) => {
                                                        this.setState({ sub_district: e, zip_code: e.zip_code.toString() });
                                                    }}
                                                    value={this.state.sub_district}
                                                    isDisabled={!this.state.district}
                                                ></Select>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                <label className="w-100">{global[locale]["รหัสไปรษณีย์"]} : </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="รหัสไปรษณีย์"
                                                    onChange={(e) => {
                                                        this.setState({ zip_code: e.target.value })
                                                    }}
                                                    value={this.state.zip_code}
                                                    disabled
                                                />
                                            </div>
                                            {/* <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">ผู้ออกบัตร :</label><br />
                                        <input
                                            className="form-control validate"
                                            type="text"
                                            placeholder="กรอกข้อมูล"
                                            onChange={(e) => {
                                                this.setState({ card_issue: e.target.value })
                                            }}
                                            value={this.state.card_issue}
                                        />
                                    </div> */}
                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                <label className="w-100">{global[locale]["วันออกบัตร"]} :</label><br />
                                                <DatePickerTH
                                                    className="form-control validate"
                                                    onChange={(e) => {
                                                        this.setState({ date_issue: e });
                                                    }}
                                                    value={this.state.date_issue}
                                                />

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                <label className="w-100">{global[locale]["วันที่หมดอายุ"]} :</label><br />
                                                <DatePickerTH
                                                    className="form-control validate"
                                                    onChange={(e) => {
                                                        this.setState({ date_expire: e });
                                                    }}
                                                    value={this.state.date_expire}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                {/* เบอร์โทรศัพท์ */}
                                                <div className="w-100 mb-3">
                                                    <label className="w-100">{global[locale]["เบอร์โทรศัพท์"]}{required}:</label>
                                                    <input
                                                        className="form-control "
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        maxLength={20}
                                                        onChange={(e) => {
                                                            // if (!float(e.target.value)) {
                                                            //     return
                                                            // }
                                                            this.setState({ phone: e.target.value })
                                                        }}
                                                        value={this.state.phone}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                {/* อีเมล */}
                                                <div className="w-100 mb-3">
                                                    <label className="w-100">{global[locale]["อีเมล"]} :</label>
                                                    <input
                                                        className="form-control "
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            this.setState({ email: e.target.value })
                                                        }}
                                                        value={this.state.email}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                <label className="w-100">{global[locale]["facebook"]} :</label><br />
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ facebook: e.target.value })
                                                    }}
                                                    value={this.state.facebook}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4 mb-3">
                                                <label className="w-100">{global[locale]["ไลน์ไอดี"]} :</label><br />
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ id_line: e.target.value })
                                                    }}
                                                    value={this.state.id_line}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card border-0 p-3 mb-3">
                                    <div className="row">
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="w-100">{global[locale]["ชื่อผู้ใช้"]} : </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder={global[locale]["ชื่อผู้ใช้"]}
                                                onChange={(e) => {
                                                    this.setState({ username: e.target.value })
                                                }}
                                                value={this.state.username}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="w-100">{global[locale]["รหัสผ่านเดิม"]} : </label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder={global[locale]["รหัสผ่านเดิม"]}
                                                onChange={(e) => {
                                                    this.setState({ old_password: e.target.value })
                                                }}
                                                value={this.state.old_password}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="w-100">{global[locale]["รหัสผ่านใหม่"]} : </label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder={global[locale]["รหัสผ่านใหม่"]}
                                                onChange={(e) => {
                                                    this.setState({ new_password: e.target.value })
                                                }}
                                                value={this.state.new_password}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="w-100">{global[locale]["ยืนยันรหัสผ่าน"]} : </label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder={global[locale]["ยืนยันรหัสผ่าน"]}
                                                onChange={(e) => {
                                                    this.setState({ confirm_password: e.target.value })
                                                }}
                                                value={this.state.confirm_password}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <button className="btn btn-orange w-100" onClick={() => { this.Update() }}>{global[locale]["บันทึกข้อมูล"]}</button>
                                </div>
                            </div>
                            <div className="col-12 col-md-1"></div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
