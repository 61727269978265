import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
    FCIconic: {
        normal: "FCIconic-Light.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Italic.ttf",
        bolditalics: "FCIconic-Italic.ttf",
    },
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
}

export default class ListWithdraw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            data: [],

            filter_search: "",
        }
        this.tableRef = React.createRef();
    }
    async componentDidMount() {
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/bike-manage/report-bike", null);
        if (result && result.status) {
            this.setState({ data: result.data.data })
        }
        this.setState({ loading: false });
    }
    pdf = () => {
        let table = this.state.data.map((item, index) => {
            return [
                { text: index + 1, alignment: "center" },
                { text: item.brand_name + " " + item.model_name, alignment: "left" },
                { text: item.total_bike ? item.total_bike : "0", alignment: "center" },
                { text: item.bike_status_2 ? item.bike_status_2 : "0", alignment: "center" },
                { text: item.bike_status_3 ? item.bike_status_3 : "0", alignment: "center" },
                { text: item.bike_status_2 + item.bike_status_3, alignment: "center" },
                { text: item.bike_status_1 ? item.bike_status_1 : "0", alignment: "center" }
            ]
        });

        var docDefinition = {
            content: [

                { text: "รายงานจำนวนรถ", alignment: "left", fontSize: 24, bold: true },
                " ",
                {
                    table: {
                        headerRows: 1,
                        widths: ['5%', '24%', '14%', '14%', '14%', '14%', '14%',],
                        body: [
                            [
                                { text: "ลำดับ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ข้อมูลรถ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "จำนวนรถทั้งหมด", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "จำนวนรถจอง", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "จำนวนรถเช่า", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "จำนวนรวมรถจอง + เช่า", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "จำนวนรถที่ว่าง", alignment: "center", fillColor: "#F2F2F2" }
                            ],
                            ...table,
                        ],
                    }
                },
            ],
            // , alignment: "justify" 
            defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: 'landscape',
        }
        pdfMake.createPdf(docDefinition).open()
    }
    excel = () => {

    }
    clear_form() {
        this.setState({
            loading: false,

            filter_search: ""
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายงานจำนวนรถ" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายงานจำนวนรถ</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/report" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.pdf() }}>
                                    PDF
                                </button>
                                <DownloadTableExcel
                                    filename="รายงานจำนวนรถ"
                                    sheet="รายงานจำนวนรถ"
                                    currentTableRef={this.tableRef.current}
                                >
                                    <button type="button" className="btn btn-dark px-4 mx-1 mb-3" onClick={() => { this.excel() }}>
                                        EXCEL
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_date: "",
                                                filter_booking_status: "",
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped" ref={this.tableRef}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">ข้อมูลรถ</th>
                                                <th className="text-center">จำนวนรถทั้งหมด</th>
                                                <th className="text-center">จำนวนรถจอง</th>
                                                <th className="text-center">จำนวนรถเช่า</th>
                                                <th className="text-center">จำนวนรวมรถจอง + เช่า</th>
                                                <th className="text-center">จำนวนรถที่ว่าง</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={9} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data
                                                .filter(item => !this.state.filter_search || (item.brand_name.toUpperCase().includes(this.state.filter_search.toUpperCase())) || (item.model_name.toUpperCase().includes(this.state.filter_search.toUpperCase()))).map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">{item.brand_name + " " + item.model_name}</td>
                                                        <td className="text-center">{item.total_bike ? item.total_bike : "0"}</td>
                                                        <td className="text-center">{item.bike_status_2 ? item.bike_status_2 : "0"}</td>
                                                        <td className="text-center">{item.bike_status_3 ? item.bike_status_3 : "0"}</td>
                                                        <td className="text-center">{item.bike_status_2 + item.bike_status_3}</td>
                                                        <td className="text-center">{item.bike_status_1 ? item.bike_status_1 : "0"}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
