import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    format_date,
    required,
    success_url,
    NumberZero,
    toFixed
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";

export default class ListCollateral extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            data: null,

            filter_search: "",

        }
    }
    async componentDidMount() {
        this.GetAll();

    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            pick_up_date: "",
            rental_status: "",
        }
        let result = await GET(tokens, "v1/TH/rental-manage/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    Update = () => {
        success_url("/admin/list-collateral")
    }
    Delete = () => {
        success_url("/admin/list-collateral")
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ข้อมูลเงินประกัน" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ข้อมูลเงินประกัน</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">วันที่</th>
                                                <th className="text-center">เลขที่ใบเงินประกัน</th>
                                                <th className="text-center">Ref No.</th>
                                                <th className="text-center">ประเภทการค้ำประกัน</th>
                                                <th className="text-center">ยอดเงิน</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={5} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{NumberZero(((this.state.page - 1) * 10) + index + 1, 5)}</td>
                                                    <td className="text-center">{item.pick_up_date ? format_date(item.pick_up_date, "dd/mm/yyyy") : "-"}</td>
                                                    <td className="text-center">{item.rental_number ? item.rental_number.replace("RT", "ISR") : "-"}</td>
                                                    <td className="text-center">{item.rental_number ? item.rental_number : "-"}</td>
                                                    <td className="text-left">
                                                        {item.guarantee_type === 1 ? "เงินประกัน" : ""}
                                                        {item.guarantee_type === 2 ? "สำเนาบัตรประชาชน" : ""}
                                                        {item.guarantee_type === 3 ? "บัตรประชาชนตัวจริง" : ""}
                                                        {item.guarantee_type === 4 ? "บัตรข้าราชการ" : ""}
                                                        {item.guarantee_type === 5 ? "บัตรนักศึกษา" : ""}
                                                        {item.guarantee_type === 6 ? "พาสปอร์ตตัวจริง" : ""}
                                                        {item.guarantee_type === 7 ? "สำเนาพาสปอร์ต" : ""}
                                                        {item.guarantee_type === 8 ? "ใบขับขี่" : ""}
                                                        {item.guarantee_type === 9 ? "ใบขับขี่ตัวจริง" : ""}
                                                        {item.guarantee_type === 10 ? "เงินหยวน" : ""}
                                                        {item.guarantee_type === 11 ? "เงินดอลล่า" : ""}
                                                        {item.guarantee_type === 12 ? "เงินยูโร" : ""}
                                                    </td>
                                                    <td className="text-right">{item.guarantee_amount ? toFixed(item.guarantee_amount) : "-"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* create or update */}
                <Modal
                    show={this.state.modal_update}
                    onHide={() => { this.setState({ modal_update: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>คืนเงินประกัน ISR2023030230</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <h5><b>ท่านต้องการคืนเงินประกันจำนวน 3,000.00 บาท ใช่หรือไม่</b></h5>
                        {/* ประเภทเงิน */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ประเภทเงิน{required}:</label>
                            <Select
                                className="form-control select-search  validate"
                                styles={select_style}
                                options={[
                                    { value: 1, label: "โอนกรรมสิทธิ์แล้ว" },
                                    { value: 2, label: "ยังไม่โอนกรรมสิทธิ์" },
                                    { value: 3, label: "ห้องว่าง" }
                                ]}
                                onChange={(e) => {
                                    this.setState({ status_transfer: e });
                                }}
                                value={this.state.status_transfer}
                            ></Select>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_update: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.Update();
                                    }}
                                >
                                    ตกลง
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบข้อมูล</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการลบข้อมูลดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
