import React from "react"
import { Router, Route } from "react-router"

import "./components/global.js"

// PUBLIC
import LogVersion from "./page/LogVersion";
import Loading from "./page/Loading";
import Login from "./page/Login";
import Register from "./page/Register";
import Validate from "./page/Validate";
import Index from "./page/Index";
import Forget from "./page/Forget";
import CarList from "./page/CarList";

// CUSTOMER
import ChooseCar from "./page/Customer/ChooseCar";
import ListBookingUser from "./page/Customer/ListBooking";
import CreateBooking from "./page/Customer/CreateBooking";
import ListRentUser from "./page/Customer/ListRent";
import ListUserTrafficTicket from "./page/Customer/ListTrafficTicket";
import ListHistoryPoint from "./page/Customer/ListHistoryPoint";
import Profile from "./page/Customer/Profile";

// ADMIN
import Cashier from "./page/Admin/Cashier";
import CashierDetail from "./page/Admin/CashierDetail";
import Dashboard from "./page/Admin/Dashboard";
import ListBookingAdmin from "./page/Admin/ListBooking";
import ListCollateral from "./page/Admin/ListCollateral";
import ListDeposit from "./page/Admin/ListDeposit";
import ListReceipt from "./page/Admin/ListReceipt";
import ListReceiptBooking from "./page/Admin/ListReceiptBooking";
import ListRentAdmin from "./page/Admin/ListRent";
import CreateUpdateRentCar from "./page/Admin/CreateUpdateRentCar";
import ListTaxNotice from "./page/Admin/ListTaxNotice";
import ListTrafficTicket from "./page/Admin/ListTrafficTicket";
import ListWithdraw from "./page/Admin/ListWithdraw";
import ListLog from "./page/Admin/ListLog";
import Master from "./page/Admin/Master";
import Report from "./page/Admin/Report";

// MASTER 
import ListBanner from "./page/Admin/Master/ListBanner";
import ListBankAccount from "./page/Admin/Master/ListBankAccount";
import CreateUpdateRole from "./page/Admin/Master/CreateUpdateRole";
import ListAdmin from "./page/Admin/Master/ListAdmin";
import ListAgent from "./page/Admin/Master/ListAgent";
import ListBrand from "./page/Admin/Master/ListBrand";
import ListEquipment from "./page/Admin/Master/ListEquipment";
import ListCar from "./page/Admin/Master/ListCar";
import ListMember from "./page/Admin/Master/ListMember";
import ListModel from "./page/Admin/Master/ListModel";
import ListRole from "./page/Admin/Master/ListRole";
import ListStorage from "./page/Admin/Master/ListStorage";
import ManageInfo from "./page/Admin/Master/ManageInfo";
import ListCarWarehouse from "./page/Admin/Master/ListCarWarehouse";

// REPORT
import Report01 from "./page/Admin/Report/Report01";
import Report02 from "./page/Admin/Report/Report02";
import Report03 from "./page/Admin/Report/Report03";
import Report04 from "./page/Admin/Report/Report04";
import Report05 from "./page/Admin/Report/Report05";
import Report06 from "./page/Admin/Report/Report06";
import Report07 from "./page/Admin/Report/Report07";
import Report08 from "./page/Admin/Report/Report08";
import Report09 from "./page/Admin/Report/Report09";
import Report10 from "./page/Admin/Report/Report10";
import Report11 from "./page/Admin/Report/Report11";
import Report12 from "./page/Admin/Report/Report12";
import Report13 from "./page/Admin/Report/Report13";
import Report14 from "./page/Admin/Report/Report14";
import Report15 from "./page/Admin/Report/Report15";


import DownloadReceiptMobile from "./page/Admin/DownloadReceiptMobile";
import DownloadRentMobile from "./page/Admin/DownloadRentMobile";


import notFound from "./page/notFound";

const Routes = (props) => (
  <Router {...props}>
    <Route path="/" component={Loading} />
    <Route path="/index" component={Index} />
    <Route path="/car-list" component={CarList} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/validate" component={Validate} />
    <Route path="/forget" component={Forget} />
    <Route path="/log" component={LogVersion} />

    <Route path="/user/choose-car" component={ChooseCar} />
    <Route path="/user/list-booking" component={ListBookingUser} />
    <Route path="/user/create-booking" component={CreateBooking} />
    <Route path="/user/list-rent" component={ListRentUser} />
    <Route path="/user/list-traffic-ticket" component={ListUserTrafficTicket} />
    <Route path="/user/list-history-point" component={ListHistoryPoint} />
    <Route path="/user/profile" component={Profile} />

    <Route path="/admin/cashier" component={Cashier} />
    <Route path="/admin/cashier-detail" component={CashierDetail} />
    <Route path="/admin/dashboard" component={Dashboard} />
    <Route path="/admin/list-booking" component={ListBookingAdmin} />
    <Route path="/admin/list-collateral" component={ListCollateral} />
    <Route path="/admin/list-deposit" component={ListDeposit} />
    <Route path="/admin/list-receipt" component={ListReceipt} />
    <Route path="/admin/receipt-booking" component={ListReceiptBooking} />
    <Route path="/admin/list-rent" component={ListRentAdmin} />
    <Route path="/admin/manage-rent" component={CreateUpdateRentCar} />
    <Route path="/admin/list-tax-notice" component={ListTaxNotice} />
    <Route path="/admin/list-traffic-ticket" component={ListTrafficTicket} />
    <Route path="/admin/list-withdraw" component={ListWithdraw} />
    <Route path="/admin/list-log" component={ListLog} />
    <Route path="/admin/master" component={Master} />
    <Route path="/admin/report" component={Report} />

    <Route path="/master/list-banner" component={ListBanner} />
    <Route path="/master/list-bank-account" component={ListBankAccount} />
    <Route path="/master/manage-role" component={CreateUpdateRole} />
    <Route path="/master/list-admin" component={ListAdmin} />
    <Route path="/master/list-agent" component={ListAgent} />
    <Route path="/master/list-brand" component={ListBrand} />
    <Route path="/master/list-equipment" component={ListEquipment} />
    <Route path="/master/list-car" component={ListCar} />
    <Route path="/master/list-member" component={ListMember} />
    <Route path="/master/list-model" component={ListModel} />
    <Route path="/master/list-role" component={ListRole} />
    <Route path="/master/list-storage" component={ListStorage} />
    <Route path="/master/manage-info" component={ManageInfo} />
    <Route path="/master/list-car-warehouse" component={ListCarWarehouse} />

    <Route path="/report/01" component={Report01} />
    <Route path="/report/02" component={Report02} />
    <Route path="/report/03" component={Report03} />
    <Route path="/report/04" component={Report04} />
    <Route path="/report/05" component={Report05} />
    <Route path="/report/06" component={Report06} />
    <Route path="/report/07" component={Report07} />
    <Route path="/report/08" component={Report08} />
    <Route path="/report/09" component={Report09} />
    <Route path="/report/10" component={Report10} />
    <Route path="/report/11" component={Report11} />
    <Route path="/report/12" component={Report12} />
    <Route path="/report/13" component={Report13} />
    <Route path="/report/14" component={Report14} />
    <Route path="/report/15" component={Report15} />

    <Route path="/download-receipt" component={DownloadReceiptMobile} />
    <Route path="/download-rent" component={DownloadRentMobile} />

    <Route path="/*" component={notFound} />
  </Router>
)

export default Routes
