import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import { tokens, GET, format_date, DatePickerTH, toFixed } from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Modal } from "react-bootstrap";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  FCIconic: {
    normal: "FCIconic-Light.ttf",
    bold: "FCIconic-Bold.ttf",
    italics: "FCIconic-Italic.ttf",
    bolditalics: "FCIconic-Italic.ttf",
  },
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

export default class ListWithdraw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      data: [],

      filter_search: "",
      filter_date: format_date(new Date(), "yyyy-mm-dd", "en"),
      filter_rental_status: "",

      modal_picture: false,
payment_picture: [],
index:0
    };
    this.tableRef = React.createRef();
  }
  async componentDidMount() {
    this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let data = {
      data_search: this.state.filter_search,
      pick_up_date: "",
      rental_status: "10",
      last_return_date: this.state.filter_date ? this.state.filter_date : "",
    };
    let result = await GET(tokens, "v1/TH/rental-manage/filter-search/page/0", data);
    if (result && result.status) { 
      this.setState({ data: result.data.data });
    }
    this.setState({ loading: false });
  };
  pdf = () => {
    let table = this.state.data.map((item, index) => {
      return [
        { text: index + 1, alignment: "center" },
        { text: item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-", alignment: "center" },
        { text: item.rental_number ? item.rental_number : "-", alignment: "center" },
        { text: format_date(item.pick_up_date, "dd/mm/yyyy hh:mm") + " - " + format_date(item.return_date, "dd/mm/yyyy hh:mm") + " น.", alignment: "left" },
        { text: format_date(item.last_return_date, "dd/mm/yyyy hh:mm:ss น."), alignment: "left" },
        { text: "เลขรถ " + (item.bike_channel_name ? item.bike_channel_name : "-") + " " + (item.full_name ? item.full_name : "-") + "\nยี่ห้อ" + (item.brand_name ? item.brand_name : "-") + "รุ่น" + (item.model_name ? item.model_name : "-"), alignment: "left" },
        { text: item.full_name ? item.full_name : "-", alignment: "left" },
        { text: item.admin_name ? item.admin_name : "-", alignment: "left" },
        { text: "คืนรถเรียบร้อย", alignment: "left" },
        { text: toFixed(item.payment_list && item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0] ? item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0].total_price : item.payment_list && !item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0] && item.payment_list.filter((e) => e.admin_remark === "พนักงาน คืนรถ")[0] ? item.payment_list.filter((e) => e.admin_remark === "พนักงาน คืนรถ")[0].total_price : "-"), alignment: "right" },
      ];
    });
    var docDefinition = {
      content: [
        { text: "รายงานการคืนรถประจำวัน", alignment: "left", fontSize: 24, bold: true },
        " ",
        {
          table: {
            headerRows: 1,
            widths: ["4%", "8%", "10%", "13%", "14%", "15%", "9%", "9%", "9%", "9%"],
            body: [
              [
                { text: "ลำดับ", alignment: "center", fillColor: "#F2F2F2" },
                { text: "วันที่", alignment: "center", fillColor: "#F2F2F2" },
                { text: "เลขที่สัญญาเช่า", alignment: "center", fillColor: "#F2F2F2" },
                { text: "วันที่เช่า - คืน", alignment: "center", fillColor: "#F2F2F2" },
                { text: "วันที่ส่งคืนรถ", alignment: "center", fillColor: "#F2F2F2" },
                { text: "ข้อมูลรถ", alignment: "center", fillColor: "#F2F2F2" },
                { text: "ลูกค้า", alignment: "center", fillColor: "#F2F2F2" },
                { text: "พนักงาน", alignment: "center", fillColor: "#F2F2F2" },
                { text: "สถานะ", alignment: "center", fillColor: "#F2F2F2" },
                { text: "ยอดเงิน", alignment: "center", fillColor: "#F2F2F2" },
              ],
              ...table,
            ],
          },
        },
      ],
      // , alignment: "justify"
      defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "landscape",
    };
    pdfMake.createPdf(docDefinition).open();
  };
  excel = () => {};
  clear_form() {
    this.setState({
      loading: false,

      filter_search: "",
    });
  }
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายงานการคืนรถประจำวัน" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex row mx-0 align-items-center justify-content-between test">
              <h4 className="mb-3">
                <b>รายงานการคืนรถประจำวัน</b>
              </h4>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-outline-orange px-4 mx-1 mb-3"
                  onClick={() => {
                    window.location.href = "/admin/report";
                  }}
                >
                  <label className="icon mr-2"> {"\uf060"} </label>
                  ย้อนกลับ
                </button>
                <button
                  type="button"
                  className="btn btn-orange px-4 mx-1 mb-3"
                  onClick={() => {
                    this.pdf();
                  }}
                >
                  PDF
                </button>
                <DownloadTableExcel filename="รายงานการคืนรถประจำวัน" sheet="รายงานการคืนรถประจำวัน" currentTableRef={this.tableRef.current}>
                  <button
                    type="button"
                    className="btn btn-dark px-4 mx-1 mb-3"
                    onClick={() => {
                      this.excel();
                    }}
                  >
                    EXCEL
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
            <div className="card border-0">
              <div className="card-header bg-contain pb-0 view">
                {/* ค้นหาและตัวกรอง */}
                <div className="d-flex row mx-0">
                  {/* ค้นหา */}
                  <input
                    type="search"
                    className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                    placeholder="พิมพ์คำค้นหา..."
                    onChange={(e) => {
                      this.setState({ filter_search: e.target.value });
                    }}
                    value={this.state.filter_search}
                  />
                  <div className="wpx-200 px-0 mx-1 mb-2">
                    <DatePickerTH
                      className="form-control border-0"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ filter_date: e });
                      }}
                      value={this.state.filter_date}
                    />
                  </div>
                  {/* ค้นหา */}
                  <button
                    className="btn btn-orange px-3 mb-2 mx-1"
                    onClick={() => {
                      this.setState({ page: 1 });
                      setTimeout(() => {
                        this.GetAll();
                      }, 1);
                    }}
                  >
                    ค้นหา
                  </button>
                  {/* รีเซ็ท */}
                  <button
                    className="btn btn-outline-orange px-3 mb-2 mx-1"
                    onClick={() => {
                      this.setState({
                        filter_search: "",
                        filter_date: "",
                        filter_booking_status: "",
                      });
                      setTimeout(() => {
                        this.GetAll();
                      }, 1);
                    }}
                  >
                    รีเซ็ท
                  </button>
                </div>
              </div>
              <div className="card-body p-0">
                {/* ตาราง */}
                <div className="table-responsive">
                  <table className="table table-borderless table-striped" ref={this.tableRef}>
                    <thead>
                      <tr>
                        <th className="text-center">ลำดับ</th>
                        <th className="text-center">วันที่</th>
                        <th className="text-center">เลขที่สัญญาเช่า</th>
                        <th className="text-center">วันที่เช่า - คืน</th>
                        <th className="text-center">วันที่ส่งคืนรถ</th>
                        <th className="text-center">ข้อมูลรถ</th>
                        <th className="text-center">ลูกค้า</th>
                        <th className="text-center">พนักงาน</th>
                        <th className="text-center">สถานะ</th>
                        <th className="text-center">ยอดเงิน</th>
                        <th className="text-center">รูปสลิป</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(!this.state.data || this.state.data.length === 0) && (
                        <tr>
                          {" "}
                          <td colSpan={11} className="text-center">
                            {" "}
                            ไม่มีข้อมูล{" "}
                          </td>{" "}
                        </tr>
                      )}
                      {this.state.data &&
                        this.state.data.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-"}</td>
                            <td className="text-center">{item.rental_number ? item.rental_number : "-"}</td>
                            <td className="text-center">{format_date(item.pick_up_date, "dd/mm/yyyy hh:mm") + " - " + format_date(item.return_date, "dd/mm/yyyy hh:mm") + " น."} </td>
                            <td className="text-center">{format_date(item.last_return_date, "dd/mm/yyyy hh:mm:ss น.")}</td>
                            <td className="text-center">
                              เลขรถ {item.bike_channel_name ? item.bike_channel_name : "-"} {item.license_plate ? item.license_plate : "-"}
                              <br />
                              ยี่ห้อ {item.brand_name ? item.brand_name : "-"} รุ่น {item.model_name ? item.model_name : "-"}
                            </td>
                            <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                            <td className="text-center">{item.payment_list ? item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0].admin_return_name : "-"}</td>
                            <td className="text-center">คืนรถเรียบร้อย</td>
                            <td className="text-center">{toFixed(item.payment_list && item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0] ? item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0].total_price : item.payment_list && !item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0] && item.payment_list.filter((e) => e.admin_remark === "พนักงาน คืนรถ")[0] ? item.payment_list.filter((e) => e.admin_remark === "พนักงาน คืนรถ")[0].total_price : "-")}</td>

                            <td className="text-center">{item.payment_list && item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0]&& item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0].payment_picture.length !==0 ? 
                            
                            (
                                <div className="d-flex justify-content-center view">
                                                            <label
                                                                className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1"
                                                                onClick={() => { 
                                                                    this.setState({modal_picture:true,payment_picture:item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0].payment_picture,index:0})
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer" > {"\uf03e"} </label>
                                                            </label> 
                                                        </div>
                            ) : 
                            item.payment_list && !item.payment_list.filter((e) => e.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ")[0] && item.payment_list.filter((e) => e.admin_remark === "พนักงาน คืนรถ")[0]&& item.payment_list.filter((e) => e.admin_remark === "พนักงาน คืนรถ")[0].payment_picture.length !==0 ? 
                            (
                                <div className="d-flex justify-content-center view">
                                                            <label
                                                                className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1"
                                                                onClick={() => { 
                                                                    this.setState({modal_picture:true,payment_picture:item.payment_list.filter((e) => e.admin_remark === "พนักงาน คืนรถ")[0].payment_picture,index:0})
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer" > {"\uf03e"} </label>
                                                            </label> 
                                                        </div>
                            )
                             : ""}</td>
                          </tr>
                        ))}
                      <tr>
                        <td className="text-right" colSpan={10}>
                          จำนวนรถทั้งหมด
                        </td>
                        <td className="text-center">
                          <h3 className="me-3">
                            <b>{this.state.data.length}</b>
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* picture */}
<Modal
          show={this.state.modal_picture}
          onHide={() => {
            this.setState({ modal_picture: false, modal_detail: true });
          }}
          size={"xl"}
        >
          <Modal.Header>
            <Modal.Title>
              <b>สภาพรถ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                onClick={() => {
                  let index = this.state.index;
                  if (index === 0) {
                    index = this.state.payment_picture.filter((item) => item !== "").length - 1;
                  } else {
                    index -= 1;
                  }
                  this.setState({ index: index });
                }}
              >
                {"<"}
              </div>
              {this.state.payment_picture[this.state.index] ? <img src={this.state.payment_picture[this.state.index].picture} className="w-75 mb-3" style={{ height: 500, objectFit: "contain" }}></img> : null}
              <div
                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                onClick={() => {
                  let index = this.state.index;
                  if (index === this.state.payment_picture.filter((item) => item !== "").length - 1) {
                    index = 0;
                  } else {
                    index += 1;
                  }
                  this.setState({ index: index });
                }}
              >
                {">"}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center overflow-scroll">
              {this.state.payment_picture
                .filter((item) => item !== "")
                .map((item, index) => (
                  <div className={this.state.index === index ? "wpx-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center overflow-hidden" : "wpx-100 hpx-100 d-flex align-items-center justify-content-center overflow-hidden"}>
                    <img
                      src={item.picture}
                      className="w-100 mb-3 wpx-100 hpx-100 mx-2 pointer"
                      style={{ objectFit: "contain" }}
                      onClick={() => {
                        this.setState({ index: index });
                      }}
                    ></img>
                  </div>
                ))}
            </div>
            <div className="w-100 d-flex justify-content-center mt-3">
              <button
                className="btn btn-outline-orange px-5"
                onClick={() => {
                  this.setState({ modal_picture: false });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
