import React, { Component } from "react"
import "../assets/css/bootstrap.css"
export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="container py-5">
        <h2 className="mb-4"><b>S.K. SERVICE</b></h2>
        <hr />
        <div className="text-danger">
          <h5><b>ส่วนที่ค้าง</b></h5>
          <b>ข้อมูล Agent</b>
          <ul>
            <li>เหลือส่วน เบิกค่าแนะนำ</li>
          </ul>
          <b>ข้อมูลบริษัท</b>
          <ul>
            <li>เหลือส่วนยกเลิกการเชื่อมต่อไลน์</li>
          </ul>
          <b>ช่องเก็บรถ</b>
          <ul>
            <li>ต้องรอให้มีรถก่อน แล้วเช็คว่ารถอะไรอยู่ในช่องนี้ แล้วเอาขึ้นมาแสดงด้วย</li>
          </ul>
        </div>
        <hr />
        {/* V.1.0.10 */}
        <div>
          <h5><b>V.1.0.10 ( 20 / 07 / 2023 )</b></h5>
          <b>New Feature</b>
          <ul>
            <li>อุปกรณ์ เพิ่ม function ค้นหา เพิ่ม แก้ไข ลบ ข้อมูลได้</li>
            <li>แจ้งเตือนการต่อภาษี function ยืนยันการต่อภาษี</li>
          </ul>
          <b>Fix</b>
          <ul>
            <li>ยี่ห้อ ลบข้อมูลได้</li>
            <li>บัญชีธนาคาร ลบข้อมูลได้</li>
            <li>คลังรถ แก้ไขข้อมูลได้</li>
            <li>ช่องเก็บรถ เพิ่มรถใส่ในช่องได้</li>
            <li>bug ข้อมูล agent</li>
            <li>bug ข้อมูลบริษัท</li>
            <li>เพิ่ม filter ข้อมูลลูกค้า</li>
          </ul>
        </div>
        {/* V.1.0.9 */}
        <div>
          <h5><b>V.1.0.9 ( 18 / 07 / 2023 )</b></h5>
          <b>Fix</b>
          <ul>
            <li>bug แบนเนอร์ ไม่สามารถย้ายได้</li>
            <li>bug แจ้งเตือนทั้งหมด</li>
            {/* <li>bug ข้อมูลรถ แก้ไข รูปภาพเพิ่มเติม</li> */}
            <li>bug ข้อมูลรถ validate ข้อมูล</li>
          </ul>
        </div>
        {/* V.1.0.8 */}
        <div>
          <h5><b>V.1.0.8 ( 17 / 07 / 2023 )</b></h5>
          <b>New Feature</b>
          <ul>
            <li>ข้อมูลรถ เพิ่ม function ค้นหา เพิ่ม แก้ไข ลบ ข้อมูลได้</li>
            <li>ข้อมูลรถ สามารถเพิ่มยี่ห้อ และรุ่นรถจากหน้านี้ได้</li>
            <li>แบนเนอร์ เพิ่ม function เพิ่ม แก้ไขลำดับ ลบ ข้อมูลได้</li>
            <li>คลังรถ เพิ่ม function ค้นหา แก้ไข ข้อมูล (เหลือส่วนกดเพิ่มลด รอข้อมูลจาก API)</li>
          </ul>
          <b>Fix</b>
          <ul>
            <li>bug ข้อมูล Agent</li>
            <li>bug ข้อมูล สมาชิก</li>
            <li>bug บทบาท</li>
          </ul>
        </div>
      </div>
    )
  }
}
