/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react"
import Footer from "../components/Footer"
import Select from "react-select";
import {
  tokens,
  select_style,
  success_url,
  alert,
  POST,
  float,
  validate,
  required,
  DatePickerTH,
  removeStorage,
  locale,
  validateENNumber,
  validateEmail
} from "../components/CustomComponent.js"
import Switch from "react-switch";

import Resizer from "react-image-file-resizer";
import province from "../assets/files/province.json"
import district from "../assets/files/district.json"
import subdistrict from "../assets/files/subdistrict.json"
import "../assets/css/bootstrap.css"
export default class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      loading: false,

      modal_login: false,
      modal_alert: false,

      username: "",
      password: "",
      id_card: "",
      full_name: "",
      birthday: "",
      address: "",
      province: "",
      district: "",
      sub_district: "",
      zip_code: "",
      email: "",
      phone: "",
      picture: "",

      nationality: "",
      card_issue: "",
      date_issue: "",
      date_expire: "",
      facebook: "",
      id_line: "",
    }
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const permission = localStorage.getItem("permission");
    if (token) {
      if (!user) {
        removeStorage();
      } else if (!user.user_type) {
        window.location.href = "/user/choose-car";
      } else {
        localStorage.setItem("locale", "th");
        window.location.href = "/admin/dashboard";
      }
    } else {
      removeStorage();
    }
  }

  Register = async () => {
    if (validate(this.state, ["id_card", "full_name", "phone", "username"])) { return };
    if (this.state.phone.length > 0 && this.state.phone.length < 10) {
      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน")
      return
    } else if (this.state.email.length !== 0 && !validateEmail(this.state.email)) {
      alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
      return
    } else if (this.state.password.length < 4) {
      alert("warning", "แจ้งเตือน", "รหัสผ่านต้องมากกว่า 4 ตัวขึ้นไป")
      return
    } else if (!this.state.email && !this.state.username) {
      alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล หรือ ชื่อผู้ใช้");
      return
    }
    this.setState({ loading: true });
    let data = {
      id_card: this.state.id_card,
      full_name: this.state.full_name,
      birthday: this.state.birthday ? this.state.birthday : null,
      address: this.state.address,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zip_code: this.state.zip_code,
      email: this.state.email,
      phone: this.state.phone,
      picture: this.state.picture,
      username: this.state.username,
      password: this.state.password,
      is_active: true,
      nationality: this.state.nationality,
      card_issue: this.state.card_issue,
      date_issue: this.state.date_issue,
      date_expire: this.state.date_expire,
      facebook: this.state.facebook,
      id_line: this.state.id_line,
    }
    let result = await POST(tokens, "v1/TH/register/create", data);
    if (result && result.status) {
      success_url("/login");
    }
    this.setState({ loading: false });
  }

  import_file = (e) => {
    this.changePhoto(e.target.files[0])
    document.getElementById("file").value = "";
    document.getElementById("file").file = null;
  }
  drop_file = (e) => {
    e.preventDefault();
    this.setState({ over: false });
    this.changePhoto(e.dataTransfer.items[0].getAsFile())
  }
  changePhoto = async (file) => {
    if (!file.type.includes("image")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return
    }
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      this.setState({
        picture: e.target.result.split(",")[1],
      })
    }
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file, 1024, 1024, "JPG", 100, 0,
        (uri) => {
          this.setState({ picture: uri.split(",")[1] })
        },
        "base64"
      );
    });
  }
  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="d-flex w-100 position-fixed bg-dark" style={{ zIndex: 1010 }}>
          <div className="w-100 px-4">
            <div className="w-100 d-flex align-items-center justify-content-between py-2">
              <h3 className="mb-0 text-light pointer" onClick={() => { window.location.href = "/index" }}><b>S.K.SERVICE</b></h3>

            </div>
          </div>
        </div>
        <div className="row background3" style={{ minHeight: "100vh", paddingTop: 60 }}>
          <div className="col-12 col-md-1"></div>
          <div className="col-12 px-4">
            <div className="center w-100">
              <div className="col-11 col-sm-10 col-md-9 col-lg-12 col-xl-9 mx-auto mb-3">
                <div className="card shadow">
                  <div className="col-12 p-4">
                    <div className="text-center">
                      <h3 className="mb-4"> <b>{global[locale]["สมัครสมาชิก"]}</b> </h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-3 mb-3 pb-3">
                        <label className="w-100">{global[locale]["รูปบัตรประชาชน"]}</label>
                        {this.state.picture === "" ? (
                          <div
                            className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                            style={{ height: "100%" }}
                            onClick={() => { document.getElementById("file").click() }}
                            onDrop={(e) => this.drop_file(e)}
                            onDragOver={(e) => { this.setState({ over: true }); e.preventDefault() }}
                            onDragLeave={(e) => { this.setState({ over: false }); e.preventDefault() }}
                          >
                            <label className="icon text-orange">{"\uf03e"}</label>
                            <label className="text-orange">{global[locale]["อัปโหลดภาพ"]}</label>
                          </div>
                        ) : (
                          <div
                            className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2 "
                          >
                            <div className="position-relative w-100">
                              <img alt="รูปโปรไฟล์" src={this.state.picture.includes("https://") ? this.state.picture : "data:image/png;base64," + this.state.picture} className="w-100 rounded overflow-hidden"></img>
                              <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.setState({ picture: "" }) }}>
                                {"\uf00d"}
                              </button>
                            </div>
                          </div>
                        )}
                        <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e) }} />
                      </div>
                      <div className="col-12 col-md-12 col-lg-9">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-4">
                            {/* รหัสบัตรประชาชนหรือพาสปอร์ต */}
                            <div className="w-100 mb-3">
                              <label className="w-100">{global[locale]["รหัสบัตรประชาชนหรือพาสปอร์ต"]}{required}:</label>
                              < input
                                className="form-control "
                                type="text"
                                placeholder="กรอกข้อมูล"
                                maxLength={13}
                                onChange={(e) => {
                                  if (!validateENNumber(e.target.value) && e.target.value !== "") {
                                    return
                                  }
                                  this.setState({ id_card: e.target.value })
                                }}
                                value={this.state.id_card}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4">
                            {/* ชื่อ-นามสกุล */}
                            <div className="w-100 mb-3">
                              <label className="w-100">{global[locale]["ชื่อ-นามสกุล"]}{required}:</label>
                              < input
                                className="form-control "
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                  this.setState({ full_name: e.target.value })
                                }}
                                value={this.state.full_name}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4">
                            {/* วัน /เดือน /ปีเกิด */}
                            <div className="w-100 mb-3">
                              <label className="w-100">{global[locale]["วัน /เดือน /ปีเกิด"]} :</label>
                              < DatePickerTH
                                className="form-control"
                                placeholder="พิมพ์คำค้นหา..."
                                onChange={(e) => {
                                  this.setState({ birthday: e });
                                }}
                                value={this.state.birthday}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100">{global[locale]["สัญชาติ"]} :</label><br />
                            < input
                              className="form-control validate"
                              type="text"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                this.setState({ nationality: e.target.value })
                              }}
                              value={this.state.nationality}
                            />
                          </div>
                          <div className="col-12 col-md-6 col-lg-4">
                            {/* ที่อยู่ */}
                            <div className="w-100 mb-3">
                              <label className="w-100">{global[locale]["ที่อยู่"]} :</label>
                              < input
                                className="form-control "
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                  this.setState({ address: e.target.value })
                                }}
                                value={this.state.address}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100">{global[locale]["จังหวัด"]} : </label>
                            < Select
                              className="form-control select-search"
                              styles={select_style}
                              options={province.map(item => { { return { value: item.name_th, label: item.name_th, id: item.province_id } } })}
                              onChange={(e) => {
                                this.setState({ province: e, district: null, sub_district: null, zip_code: "" });
                              }}
                              value={this.state.province}
                            ></Select>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100"> {global[locale]["อำเภอ / เขต"]} : </label>
                            < Select
                              className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                              styles={select_style}
                              options={district.filter(item => this.state.province && this.state.province.id === item.province_id).map(item => { { return { value: item.name_th, label: item.name_th, id: item.district_id } } })}
                              onChange={(e) => {
                                this.setState({ district: e, sub_district: null, zip_code: "" });
                              }}
                              value={this.state.district}
                              isDisabled={!this.state.province}
                            ></Select>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100">{global[locale]["ตำบล / แขวง"]} : </label>

                            < Select
                              className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                              styles={select_style}
                              options={subdistrict.filter(item => this.state.district && this.state.district.id === item.district_id).map(item => { { return { value: item.name_th, label: item.name_th, zip_code: item.zip_code, id: item.sub_district_id } } })}
                              onChange={(e) => {
                                this.setState({ sub_district: e, zip_code: e.zip_code.toString() });
                              }}
                              value={this.state.sub_district}
                              isDisabled={!this.state.district}
                            ></Select>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100">{global[locale]["รหัสไปรษณีย์"]} : </label>
                            < input
                              className="form-control"
                              type="text"
                              placeholder="รหัสไปรษณีย์"
                              onChange={(e) => {
                                this.setState({ zip_code: e.target.value })
                              }}
                              value={this.state.zip_code}
                              disabled
                            />
                          </div>
                          {/* <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100">ผู้ออกบัตร :</label><br />
                            <input
                              className="form-control validate"
                              type="text"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                this.setState({ card_issue: e.target.value })
                              }}
                              value={this.state.card_issue}
                            />
                          </div> */}
                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100">{global[locale]["วันออกบัตร"]} :</label><br />
                            < DatePickerTH
                              className="form-control validate"
                              onChange={(e) => {
                                this.setState({ date_issue: e });
                              }}
                              value={this.state.date_issue}
                            />
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100">{global[locale]["วันที่หมดอายุ"]} :</label><br />
                            < DatePickerTH
                              className="form-control validate"
                              onChange={(e) => {
                                this.setState({ date_expire: e });
                              }}
                              value={this.state.date_expire}
                            />
                          </div>

                          <div className="col-12 col-md-6 col-lg-4">
                            {/* เบอร์โทรศัพท์ */}
                            <div className="w-100 mb-3">
                              <label className="w-100">{global[locale]["เบอร์โทรศัพท์"]}{required}:</label>
                              < input
                                className="form-control "
                                type="text"
                                placeholder="กรอกข้อมูล"
                                maxLength={20}
                                onChange={(e) => {
                                  // if (!float(e.target.value)) {
                                  //   return
                                  // }
                                  this.setState({ phone: e.target.value })
                                }}
                                value={this.state.phone}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4">
                            {/* อีเมล */}
                            <div className="w-100 mb-3">
                              <label className="w-100">{global[locale]["อีเมล"]} :</label>
                              < input
                                className="form-control "
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                  this.setState({ email: e.target.value })
                                }}
                                value={this.state.email}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100">{global[locale]["facebook"]} :</label><br />
                            < input
                              className="form-control validate"
                              type="text"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                this.setState({ facebook: e.target.value })
                              }}
                              value={this.state.facebook}
                            />
                          </div>

                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label className="w-100">{global[locale]["ไลน์ไอดี"]} :</label><br />
                            < input
                              className="form-control validate"
                              type="text"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                this.setState({ id_line: e.target.value })
                              }}
                              value={this.state.id_line}
                            />
                          </div>

                          <div className="col-12 col-md-6 col-lg-4">
                            {/* ชื่อผู้ใช้ */}
                            <div className="w-100 mb-3">
                              <label className="w-100">{global[locale]["ชื่อผู้ใช้"]} :</label>
                              < input
                                className="form-control "
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                  this.setState({ username: e.target.value })
                                }}
                                value={this.state.username}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4">
                            {/* รหัสผ่าน */}
                            <div className="w-100 mb-3">
                              <label className="w-100">{global[locale]["รหัสผ่าน"]} :</label>
                              < input
                                className="form-control "
                                type="password"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                  this.setState({ password: e.target.value })
                                }}
                                value={this.statepassword}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" className="btn btn-orange w-100" onClick={() => { this.Register() }}>{global[locale]["สมัครสมาชิก"]}</button>
                    <div className="w-100 text-center mt-4">
                      {global[locale]["มีบัญชีอยู่แล้วใช่หรือไม่?"]} <label className="px-2 text-orenge pointer" onClick={() => { window.location.href = "/login" }}>{global[locale]["ลงชื่อเข้าใช้"]}</label>
                    </div>
                    <div class="w-100 text-center mt-2">
                      <small class="text-secondary">{global.version}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div >
    )
  }
}
