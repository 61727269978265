import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    format_date,
    DatePickerTH,
    toFixed,
    total,
    getimgBase64
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
    FCIconic: {
        normal: "FCIconic-Light.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Italic.ttf",
        bolditalics: "FCIconic-Italic.ttf",
    },
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
}

export default class ListWithdraw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            data: [],
            data_full_name: [],

            filter_search: "",
            filter_date: "",
            filter_rental_status: "",
            filter_pick_up_date: null,
            filter_return_date: null,

            full_name: "",
            rental_price: [0, 0, 0], //ค่าเช่า
            guarantee_amount: [0, 0, 0], //ค่าค้ำประกัน
            equipment_fee: [0, 0, 0], //ค่าอุปกรณ์
            delivery_price: [0, 0, 0], //ค่าขนส่ง
            fuel_cost: [0, 0, 0], //ค่าน้ำมัน
            damage_cost: [0, 0, 0], //ค่าเสียหาย
            service_charge: [0, 0, 0], //ค่าบริการรับส่ง
            other_expenses: [0, 0, 0], //ค่าอื่นๆ
            rental_price_overdue: [0, 0, 0], //ค่าเช่าเกินกำหนด
            scale_price: [0, 0, 0], //ค่าขยายสัญญา
            discount: 0, //ส่วนลด
            point: 0, //ใช้แต้ม
            wallet: 0, //ใช้เงินสะสม}
            agent: [0, 0],  //เบิกค่าแนะนำ
        }
        this.tableRef = React.createRef();
    }
    async componentDidMount() {
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/cashier/shift-all", null);
        if (result && result.status) {
            let data = result.data.data.filter(e => e.cashier_status === 2);
            for (let v of data) {
                if (!window.location.href.includes(":300") && v.detail_picture.length !== 0) {
                    for (let img of v.detail_picture) {
                        img.picture = await getimgBase64(img.picture);
                    }
                }
            }

            const data_water = Object.values(data.reduce((p, v) => {
                const old = p[v.full_name];
                if (!old) {

                    p[v.full_name] = {
                        full_name: v.full_name,
                        array: [v]
                    };
                }
                else {
                    p[v.full_name] = {
                        full_name: v.full_name,
                        array: [...old.array, v]
                    };
                }
                return p;
            }, {}));
            console.log(data_water)
            this.setState({ data: data_water, data_full_name: data_water.map(e => { return { value: e.full_name, label: e.full_name } }) });

        }
        this.setState({ loading: false });
    }
    pdf = () => {
        let stack = this.state.data
            .filter(main => !this.state.full_name || main.full_name === this.state.full_name.value)
            .map((main, main_index) => {
                let table = main.array
                    .filter(main => !this.state.filter_date || format_date(main.datetime_create, "dd/mm/yyyy") === format_date(this.state.filter_date, "dd/mm/yyyy"))
                    .map((item, index) => {
                        let header = index === 0 ?
                            [
                                {
                                    pageBreak: main_index === 0 ? "" : "before", text: main.full_name, alignment: "left", border: [false, false, false, false], colSpan: 5, bold: true, fontSize: 24, fillColor: "#fd7e14"
                                },
                                "",
                                "",
                                "",
                                "",
                            ]
                            : [
                                {
                                    pageBreak: main_index === 0 ? "" : "before", text: "", alignment: "left", border: [false, false, false, false], colSpan: 5, fontSize: 1
                                },
                                "",
                                "",
                                "",
                                ""
                            ]
                        var columns = [];
                        var img = [];
                        for (let e of item.detail_picture) {
                            img.push({
                                image: e.picture,
                                fit: [175, 175],
                                margin: [0, 0, 10, 0]
                            });
                        }
                        if (img.length !== 0) {
                            columns.push({ columns: img });
                        } else {
                            columns.push({ text: "" });
                        }
                        return {
                            table: {
                                headerRows: 0,
                                widths: ['20%', '20%', '20%', '20%', '20%'],
                                body: [
                                    header,
                                    [
                                        { text: "เปิดกะ : "+format_date(item.datetime_create, "dd mmmm yyyy hh:mm:ss น.")+ "\nปิดกะ : "+format_date(item.datetime_update, "dd mmmm yyyy hh:mm:ss น."), alignment: "left", border: [false, false, false, false], colSpan: 5, bold: true, fontSize: 16 },
                                        "",
                                        "",
                                        "",
                                        "",
                                    ],
                                    [
                                        { text: "", alignment: "center", fillColor: "#000000", color: "#ffffff" },
                                        { text: "เงินสด", alignment: "center", fillColor: "#000000", color: "#ffffff" },
                                        { text: "เงินโอน", alignment: "center", fillColor: "#000000", color: "#ffffff" },
                                        { text: "บัตรเครดิต", alignment: "center", fillColor: "#000000", color: "#ffffff" },
                                        { text: "รวม", alignment: "center", fillColor: "#000000", color: "#ffffff" },
                                    ],
                                    [
                                        { text: "1.ค่าเช่า", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.rental_price)), alignment: "center" },
                                    ],
                                    [
                                        { text: "2.ค่าค้ำประกัน", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.guarantee_amount)), alignment: "center" },
                                    ],
                                    [
                                        { text: "3.ค่าอุปกรณ์", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.equipment_fee)), alignment: "center" },
                                    ],
                                    [
                                        { text: "4.ค่าขนส่ง", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.delivery_price)), alignment: "center" },
                                    ],
                                    [
                                        { text: "5.ค่าน้ำมัน", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.fuel_cost)), alignment: "center" },
                                    ],
                                    [
                                        { text: "6.ค่าเสียหาย", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.damage_cost)), alignment: "center" },
                                    ],
                                    [
                                        { text: "7.ค่าบริการรับส่ง", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.service_charge)), alignment: "center" },
                                    ],
                                    [
                                        { text: "8.ค่าอื่นๆ", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.other_expenses)), alignment: "center" },
                                    ],
                                    [
                                        { text: "9.ค่าเช่าเกินกำหนด", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.rental_price_overdue)), alignment: "center" },
                                    ],
                                    [
                                        { text: "10.ค่าขยายสัญญา", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.scale_price)), alignment: "center" },
                                    ],
                                    [
                                        { text: "11.ส่วนลด", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(item.discount), alignment: "center" },
                                    ],
                                    [
                                        { text: "12.ส่วนลดจากแต้ม", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(item.point), alignment: "center" },
                                    ],
                                    [
                                        { text: "13.ส่วนลดจากเงินสะสม", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(item.wallet), alignment: "center" },
                                    ],
                                    [
                                        { text: "14.เบิกค่าแนะนำ", alignment: "center" },
                                        { text: toFixed(item.agent[0]), alignment: "center" },
                                        { text: toFixed(item.agent[1]), alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(total(item.agent)), alignment: "center" },
                                    ],
                                    [
                                        { text: "15.คืนค่าค้ำประกัน", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: "", alignment: "center" },
                                        { text: toFixed(item.return_guarantee_amount), alignment: "center" },
                                    ],
                                    [
                                        { text: "รวมทั้งสิ้น", alignment: "center" },
                                        {
                                            text: ""
                                            , alignment: "center"
                                        },
                                        {
                                            text: ""
                                            , alignment: "center"
                                        },
                                        {
                                            text: ""
                                            , alignment: "center"
                                        },
                                        {
                                            text: toFixed(
                                            (total(item.rental_price)+
                                            total(item.guarantee_amount)+
                                            total(item.equipment_fee)+
                                            total(item.delivery_price)+
                                            total(item.fuel_cost)+
                                            total(item.damage_cost)+
                                            total(item.service_charge)+
                                            total(item.other_expenses)+
                                            total(item.rental_price_overdue)+
                                            total(item.scale_price))-
                                            (item.discount+
                                            item.point+
                                            item.wallet+
                                            total(item.agent)) 
                                        )
                                            , alignment: "center"
                                        },
                                    ],
                                    [
                                        { text: "ยอดเงินรวมทั้งหมด", alignment: "center" },
                                        {
                                            text: item.total_pay ? toFixed(item.total_pay[0]) : "0.00"
                                            , alignment: "center"
                                        },
                                        {
                                            text: item.total_pay ? toFixed(item.total_pay[1]) : "0.00"
                                            , alignment: "center"
                                        },
                                        {
                                            text: item.total_pay ? toFixed(item.total_pay[2]) : "0.00"
                                            , alignment: "center"
                                        },
                                        {
                                            text: item.total_pay ? toFixed(item.total_pay[0] + item.total_pay[1] + item.total_pay[2]) : "0.00"
                                            , alignment: "center"
                                        },
                                    ],
                                    [
                                        { text: "หมายเหตุ : " + item.remark, colSpan: 5, alignment: "left" },
                                        "",
                                        "",
                                        "",
                                        "",
                                    ],
                                    [
                                        { stack: columns, colSpan: 5, alignment: "left" },
                                        "",
                                        "",
                                        "",
                                        "",
                                    ], 
                                ],
                            }
                        }
                    });
                return {
                    stack: table
                }
            });

        var docDefinition = {
            content: [

                { text: "รายงานนำส่งเงินประจำวัน", alignment: "left", fontSize: 24, bold: true, fillColor: "#fd7e14" },
                " ",
                ...stack
            ],
            // , alignment: "justify" 
            defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: 'potrait',
        }
        pdfMake.createPdf(docDefinition).open()
    }
    excel = () => {

    }
    clear_form() {
        this.setState({
            loading: false,

            filter_search: ""
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายงานนำส่งเงินประจำวัน" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายงานนำส่งเงินประจำวัน</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/report" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.pdf() }}>
                                    PDF
                                </button>
                                <DownloadTableExcel
                                    filename="รายงานนำส่งเงินประจำวัน"
                                    sheet="รายงานนำส่งเงินประจำวัน"
                                    currentTableRef={this.tableRef.current}
                                >
                                    <button type="button" className="btn btn-dark px-4 mx-1 mb-3" onClick={() => { this.excel() }}>
                                        EXCEL
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    <Select
                                        className="form-control select-search bg-white wpx-200 mx-1 mb-2"
                                        styles={select_style}
                                        options={this.state.data_full_name}
                                        onChange={(e) => {
                                            this.setState({ full_name: e });
                                        }}
                                        value={this.state.full_name}
                                        isClearable
                                    ></Select>
                                    <div className="wpx-200 px-0 mx-1 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_date: e });
                                            }}
                                            value={this.state.filter_date}
                                        />
                                    </div>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                full_name: "",
                                                filter_date: "",
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0 pt-3">

                                {/* ตาราง */}
                                <div className="table-responsive">


                                    <table className="table table-bordered" ref={this.tableRef}>
                                        {this.state.data.filter(main => !this.state.full_name || main.full_name === this.state.full_name.value).map((main, main_index) =>
                                            main.array.filter(main => !this.state.filter_date || format_date(main.datetime_create, "dd/mm/yyyy") === format_date(this.state.filter_date, "dd/mm/yyyy")).length !== 0 && (

                                                main.array.filter(main => !this.state.filter_date || format_date(main.datetime_create, "dd/mm/yyyy") === format_date(this.state.filter_date, "dd/mm/yyyy")).map((item, index) => (

                                                    <tbody>
                                                        {index === 0 && (
                                                            <tr className="bg-orange border-0">
                                                                <td className="text-left border-0" colSpan={5}>
                                                                    <h3 className="px-3 mb-0"><b>{main.full_name}</b></h3>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr className="bg-white border-0">
                                                            <td className="text-left border-0" colSpan={5}>
                                                                <h5 className="px-4 mb-0"><b>เปิดกะ : {format_date(item.datetime_create, "dd mmmm yyyy hh:mm:ss น.")} <br/> ปิดกะ : {format_date(item.datetime_update, "dd mmmm yyyy hh:mm:ss น.")}</b></h5>
                                                            </td>
                                                        </tr>
                                                        <tr className="bg-dark text-white">
                                                            <th className="text-left"></th>
                                                            <th className="text-center">เงินสด</th>
                                                            <th className="text-center">เงินโอน</th>
                                                            <th className="text-center">บัตรเครดิต</th>
                                                            <th className="text-center">รวม</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">1.ค่าเช่า</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.rental_price))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">2.ค่าค้ำประกัน</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.guarantee_amount))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">3.ค่าอุปกรณ์</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.equipment_fee))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">4.ค่าขนส่ง</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.delivery_price))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">5.ค่าน้ำมัน</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.fuel_cost))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">6.ค่าเสียหาย</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.damage_cost))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">7.ค่าบริการรับส่ง</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.service_charge))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">8.ค่าอื่นๆ</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.other_expenses))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">9.ค่าเช่าเกินกำหนด</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.rental_price_overdue))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">10.ค่าขยายสัญญา</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.scale_price))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">11.ส่วนลด</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(item.discount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">12.ส่วนลดจากแต้ม</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(item.point)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">13.ส่วนลดจากเงินสะสม</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(item.wallet)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">14.เบิกค่าแนะนำ</td>
                                                            <td className="text-center">{toFixed(item.agent[0])}</td>
                                                            <td className="text-center">{toFixed(item.agent[1])}</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(total(item.agent))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">15.คืนค่าค้ำประกัน</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(item.return_guarantee_amount)}</td>
                                                        </tr>
                                                        <tr>
                                    <td className="text-left">รวมทั้งสิ้น</td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">
                                        <h3><b>
                                        {toFixed(
                                            (total(item.rental_price)+
                                            total(item.guarantee_amount)+
                                            total(item.equipment_fee)+
                                            total(item.delivery_price)+
                                            total(item.fuel_cost)+
                                            total(item.damage_cost)+
                                            total(item.service_charge)+
                                            total(item.other_expenses)+
                                            total(item.rental_price_overdue)+
                                            total(item.scale_price))-
                                            (item.discount+
                                            item.point+
                                            item.wallet+
                                            total(item.agent)) 
                                        )
                                        }
                                        </b></h3>
                                    </td>
                                </tr> 
                                                        <tr>
                                                            <td className="text-left">ยอดเงินรวมทั้งหมด</td>
                                                            <td className="text-center">
                                                                {item.total_pay ? toFixed(item.total_pay[0]) : "0.00"}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.total_pay ? toFixed(item.total_pay[1]) : "0.00"}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.total_pay ? toFixed(item.total_pay[2]) : "0.00"}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.total_pay ? toFixed(item.total_pay[0] + item.total_pay[1] + item.total_pay[2]) : "0.00"}
                                                            </td>
                                                        </tr>
                                                        {item.remark && (
                                                            <tr>
                                                                <td colSpan={5}>หมายเหตุ : {item.remark}</td>
                                                            </tr>
                                                        )}
                                                        {item.detail_picture && (
                                                            <tr>
                                                                <td colSpan={5}>
                                                                    <div className="row">
                                                                        {item.detail_picture.map((item, index) => (
                                                                            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2 px-1">
                                                                                <div
                                                                                    className="w-100 hpx-150 card border-2 border-dark d-flex align-items-center justify-content-center p-2  mb-2"
                                                                                >
                                                                                    <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                                                        <img alt="รูปโปรไฟล์" src={item.picture} className="w-100 hpx-140 rounded overflow-hidden" style={{ objectFit: "contain" }}></img>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                ))

                                            ))}
                                    </table>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
