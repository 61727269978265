import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import React, { Component } from "react";

export default class Master extends Component {
    constructor(props) {
        super(props)

        this.state = {

            page: 1,
            loading: false,

            permission: []
        }
    }
    async componentDidMount() {
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายงาน</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-body p-3">
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/01" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf073"}</label><br />
                                            การจองประจำวัน
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/02" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf1b9"}</label><br />
                                            ตารางส่งรถประจำวัน
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/03" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf0d6"}</label><br />
                                            รายได้ประจำวัน
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/04" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf15b"}</label><br />
                                            สัญญาเช่า
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/05" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf5f3"}</label><br />
                                            ทะเบียนรถ
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/06" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf007"}</label><br />
                                            ทะเบียนลูกค้า
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/07" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf555"}</label><br />
                                            ค่าตอบแทน
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/08" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf022"}</label><br />
                                            เช่าตามทะเบียนรถ
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/09" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf507"}</label><br />
                                            เช่าตามลูกค้า
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/10" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf273"}</label><br />
                                            การแสดงวันภาษีรถหมดอายุ
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/11" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf362"}</label><br />
                                            เงินประกัน
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/12" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf5e4"}</label><br />
                                            การคืนรถประจำวัน
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/13" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf653"}</label><br />
                                            เงินมัดจำ
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/14" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf4d3"}</label><br />
                                            นำส่งเงินประจำวัน
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                        <div
                                            className="master hpx-150 w-100"
                                            onClick={() => { window.location.href = "/report/15" }} >
                                            <label className="icon text-48 mb-2 text-orange">{"\uf21c"}</label><br />
                                            จำนวนรถ
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
