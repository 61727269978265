import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    success_url,
    GET,
    PUT,
    DatePickerTH,
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import USER from "../../../assets/images/user.png"
import province from "../../../assets/files/province.json"
import district from "../../../assets/files/district.json"
import subdistrict from "../../../assets/files/subdistrict.json"
import Resizer from "react-image-file-resizer";

export default class ManageInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_delete: false,

            page: 1,
            loading: false,

            address: "",
            company_id: "",
            website: "",
            company_name: "",
            district: "",
            email: "",
            end_date_booking_mc: "",
            line_id: "",
            phone: "",
            picture: "",
            province: "",
            start_date_booking_mc: "",
            sub_district: "",
            zip_code: "",
            line_active: false,
        }
    }
    async componentDidMount() {
        this.GetAll();
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => {
            this.GetAll()
        }, 20)
    }
    clear_form() {
        this.setState({
            modal_delete: false,

            page: 1,
            loading: false,

        })
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {}
        let result = await GET(tokens, "v1/TH/company/detail", data);
        if (result && result.status) {
            let data = result.data.data;
            this.setState({
                address: data.address,
                website: data.website,
                company_id: data.company_id,
                company_name: data.company_name,
                email: data.email,
                end_date_booking_mc: data.end_date_booking_mc,
                line_id: data.line_id,
                phone: data.phone,
                picture: data.picture,
                start_date_booking_mc: data.start_date_booking_mc,
                zip_code: data.zip_code,
                line_active: data.line_active,
            })
            // this.setState({ data: result.data }) 
            if (data.province) {
                this.setState({
                    province: province.filter(item => item.name_th === data.province).map(item => { { return { value: item.name_th, label: item.name_th, id: item.province_id } } })[0]
                })
            }
            if (data.district) {
                this.setState({
                    district: district.filter(item => item.name_th === data.district).map(item => { { return { value: item.name_th, label: item.name_th, id: item.district_id } } })[0]
                })
            }
            if (data.sub_district) {
                this.setState({
                    sub_district: subdistrict.filter(item => item.name_th === data.sub_district).map(item => { { return { value: item.name_th, label: item.name_th, zip_code: item.zip_code, id: item.sub_district_id } } })[0]
                })
            }
        }

        this.setState({ loading: false });
    }
    Update = async () => {
        this.setState({ loading: true });
        let data = {
            company_name: this.state.company_name,
            address: this.state.address,
            email: this.state.email,
            phone: this.state.phone,
            line_id: this.state.line_id,
            province: this.state.province ? this.state.province.value : "",
            district: this.state.district ? this.state.district.value : "",
            sub_district: this.state.sub_district ? this.state.sub_district.value : "",
            zip_code: this.state.zip_code,
            start_date_booking_mc: this.state.start_date_booking_mc,
            end_date_booking_mc: this.state.end_date_booking_mc,
            picture: this.state.picture,
            website: this.state.website,
            line_active: this.state.line_active
        }
        let result = await PUT(tokens, "v1/TH/company/update", data);
        if (result && result.status) {
            success_url("/master/manage-info")
        }
        this.setState({ loading: false });
    }
    Delete = () => {
        success_url("/master/manage-info")
    }
    import_file = (e) => {
        this.changePhoto(e.target.files[0])
        document.getElementById("file").value = "";
        document.getElementById("file").file = null;
    }
    changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            this.setState({
                picture: e.target.result.split(",")[1],
            })
        }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    this.setState({ picture: uri.split(",")[1] })
                },
                "base64"
            );
        });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ข้อมูลบริษัท" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="row">
                            <div className="col-12 col-md-1"></div>
                            <div className="col-12 col-xl-10 mb-3">
                                <div className="d-flex row mx-0 align-items-center justify-content-between test">
                                    <h4 className="mb-3"><b>ข้อมูลบริษัท</b></h4>
                                    <button className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                        <label className="icon mr-2" > {"\uf060"} </label>
                                        ย้อนกลับ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-1"></div>
                            <div className="col-12 col-xl-3 mb-3">
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-4">
                                        <label className="mb-3 text-center w-100">รูปโลโก้บริษัท (ขนาดไม่เกิน 150 x 150 px)</label>
                                        <div className="wpx-180 hpx-180 position-relative mb-4 mx-auto">
                                            <img src={this.state.picture ? this.state.picture.includes("https://") ? this.state.picture : "data:image/png;base64," + this.state.picture : USER} className="wpx-180 hpx-180 rounded-circle" style={{ objectFit: "cover" }} />
                                            <div className="wpx-30 hpx-30 rounded-circle bg-white position-absolute shadow d-flex justify-content-center align-items-center" style={{ top: 8, right: 8 }}>
                                                <label className="icon">{"\uf304"}</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <button className="btn btn-outline-orange w-100 mb-3 edit"
                                            onClick={() => { document.getElementById("file").click() }}>อัพโหลดรูปภาพ</button>
                                        <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e) }} />
                                        {this.state.line_active ? (
                                            <button className="btn btn-danger w-100 mb-3 edit" onClick={() => {
                                                this.setState({ modal_delete: true })
                                            }}>ยกเลิกการเชื่อมต่อไลน์</button>
                                        ) : (
                                            <button className="btn btn-success w-100 mb-3 edit" onClick={() => {
                                                this.setState({ modal_delete: true })
                                            }}>เชื่อมต่อไลน์</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-7 mb-3">
                                <div className="card border-0 mb-3">
                                    <div className="card-body p-3">
                                        <div className="row mx-0">
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100">ชื่อบริษัท :</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="ชื่อบริษัท"
                                                    onChange={(e) => {
                                                        this.setState({ company_name: e.target.value })
                                                    }}
                                                    value={this.state.company_name}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100">ชื่อเว็ปไซต์ :</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="ชื่อเว็ปไซต์"
                                                    onChange={(e) => {
                                                        this.setState({ website: e.target.value })
                                                    }}
                                                    value={this.state.website}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100">อีเมลบริษัท :</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="อีเมลบริษัท"
                                                    onChange={(e) => {
                                                        this.setState({ email: e.target.value })
                                                    }}
                                                    value={this.state.email}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100">เบอร์โทรศัพท์บริษัท :</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="เบอร์โทรศัพท์บริษัท"
                                                    onChange={(e) => {
                                                        this.setState({ phone: e.target.value })
                                                    }}
                                                    value={this.state.phone}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100">ไลน์ไอดี :</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="ไลน์ไอดี"
                                                    onChange={(e) => {
                                                        this.setState({ line_id: e.target.value })
                                                    }}
                                                    value={this.state.line_id}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100">ที่อยู่บริษัท :</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="ที่อยู่บริษัท"
                                                    onChange={(e) => {
                                                        this.setState({ address: e.target.value })
                                                    }}
                                                    value={this.state.address}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100">จังหวัด : </label>
                                                <Select
                                                    className="form-control select-search"
                                                    styles={select_style}
                                                    options={province.map(item => { { return { value: item.name_th, label: item.name_th, id: item.province_id } } })}
                                                    onChange={(e) => {
                                                        this.setState({ province: e, district: null, sub_district: null, zip_code: "" });
                                                    }}
                                                    value={this.state.province}
                                                ></Select>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100"> อำเภอ / เขต : </label>
                                                <Select
                                                    className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                                                    styles={select_style}
                                                    options={district.filter(item => this.state.province && this.state.province.id === item.province_id).map(item => { { return { value: item.name_th, label: item.name_th, id: item.district_id } } })}
                                                    onChange={(e) => {
                                                        this.setState({ district: e, sub_district: null, zip_code: "" });
                                                    }}
                                                    value={this.state.district}
                                                    isDisabled={!this.state.province}
                                                ></Select>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100">ตำบล / แขวง : </label>

                                                <Select
                                                    className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                                                    styles={select_style}
                                                    options={subdistrict.filter(item => this.state.district && this.state.district.id === item.district_id).map(item => { { return { value: item.name_th, label: item.name_th, zip_code: item.zip_code, id: item.sub_district_id } } })}
                                                    onChange={(e) => {
                                                        this.setState({ sub_district: e, zip_code: e.zip_code.toString() });
                                                    }}
                                                    value={this.state.sub_district}
                                                    isDisabled={!this.state.district}
                                                ></Select>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="w-100">รหัสไปรษณีย์ : </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="รหัสไปรษณีย์"
                                                    onChange={(e) => {
                                                        this.setState({ zip_code: e.target.value })
                                                    }}
                                                    value={this.state.zip_code}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-12">
                                                <hr />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <h5><b>ปิดการจองรถ</b></h5>
                                                <label className="w-100">วันที่ : </label>
                                                <DatePickerTH
                                                    className="form-control"
                                                    placeholder="พิมพ์คำค้นหา..."
                                                    onChange={(e) => {
                                                        this.setState({ start_date_booking_mc: e });
                                                    }}
                                                    value={this.state.start_date_booking_mc}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <h5><b>ถึง</b></h5>
                                                <label className="w-100">วันที่ : </label>
                                                <DatePickerTH
                                                    className="form-control"
                                                    placeholder="พิมพ์คำค้นหา..."
                                                    onChange={(e) => {
                                                        this.setState({ end_date_booking_mc: e });
                                                    }}
                                                    value={this.state.end_date_booking_mc}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-100 edit">
                                    <button className="btn btn-orange w-100" onClick={() => {
                                        this.Update()
                                    }}>บันทึกข้อมูล</button>
                                </div>
                            </div>

                            <div className="col-12 col-md-1"></div>
                        </div>

                    </div>
                </div>

                {/* MODAL */}
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>{this.state.line_active ? "ยืนยันการยกเลิกเชื่อมต่อไลน์" : "เชื่อมต่อไลน์"}</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการ{this.state.line_active ? "ยกเลิกเชื่อมต่อไลน์" : "เชื่อมต่อไลน์"}
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-orange w-100" onClick={() => {
                                    this.setState({ line_active: !this.state.line_active });
                                    setTimeout(() => {
                                        this.Update();
                                    }, 10);
                                }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
