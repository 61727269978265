/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react"
import Footer from "../components/Footer"
import {
  success_url,
  alert,
  POST,
  removeStorage,
  locale
} from "../components/CustomComponent.js"
import "../assets/css/bootstrap.css"
export default class Validate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      new_password: "",
      confirm_password: "",
      loading: false,

    }
  }

  componentDidMount() {
    // const token = localStorage.getItem("token");
    // const user = localStorage.getItem("user");
    // const permission = localStorage.getItem("permission");
    // if (token) {
    //   if (!user) {
    //     removeStorage();
    //   } else if (!user.user_type) {
    //     window.location.href = "/user/choose-car";
    //   } else {
    //     localStorage.setItem("locale", "th");
    //     window.location.href = "/admin/dashboard";
    //   }
    // } else {
    //   removeStorage();
    // }
  }

  Verify = async () => {
    if (this.state.new_password === "" || this.state.confirm_password === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน")
      return
    } else if (this.state.new_password !== this.state.confirm_password) {
      alert("warning", "แจ้งเตือน", "รหัสผ่านไม่ตรงกัน")
      return
    }
    this.setState({ loading: true });
    let data = {
      token: this.props.location.query.token,
      new_password: this.state.new_password,
    }
    let result = await POST("", "v1/TH/forgot-password/reset-password", data);
    if (result && result.status) {
      success_url("/login", "success", "สำเร็จ", "เปลี่ยนรหัสผ่านสำเร็จ")
    }
    this.setState({ loading: false });
  }
  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="d-flex w-100 position-fixed bg-dark" style={{ zIndex: 1010 }}>
          <div className="w-100 px-4">
            <div className="w-100 d-flex align-items-center justify-content-between py-2">
              <h3 className="mb-0 text-light pointer" onClick={() => { window.location.href = "/index" }}><b>S.K.SERVICE</b></h3>

            </div>
          </div>
        </div>
        <div className="row background3 pt-5" style={{ height: "100vh" }}>
          <div className="col-12 col-md-1"></div>
          <div className="col-12 py-5 px-4">
            <div className="center w-100">
              <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-10 mx-auto">
                <div className="card shadow">
                  <div className="col-12 p-4 ">
                    <div className="text-center"> <h3 className="mb-4"> <b>{global[locale]["เปลี่ยนรหัสผ่าน"]}</b>  </h3></div>
                    <div className="row">
                      <div className="col-12 col-md-12 mb-3">
                        <label className="w-100">{global[locale]["รหัสผ่านใหม่"]} : </label>
                        <input
                          className="form-control"
                          type="password"
                          placeholder={global[locale]["รหัสผ่านใหม่"]}
                          onChange={(e) => {
                            this.setState({ new_password: e.target.value })
                          }}
                          value={this.state.new_password}
                        />
                      </div>
                      <div className="col-12 col-md-12 mb-3">
                        <label className="w-100">{global[locale]["ยืนยันรหัสผ่าน"]} : </label>
                        <input
                          className="form-control"
                          type="password"
                          placeholder={global[locale]["ยืนยันรหัสผ่าน"]}
                          onChange={(e) => {
                            this.setState({ confirm_password: e.target.value })
                          }}
                          value={this.state.confirm_password}
                        />
                      </div></div>
                    <button type="button" className="btn btn-orange w-100" onClick={() => { this.Verify() }}>{global[locale]["ยืนยัน"]}</button>
                    <div class="w-100 text-center mt-2">
                      <small class="text-secondary">{global.version}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
