import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    alert,
    success_url,
    GET,
    POST,
    PUT,
    DELETE,
    float,
    validate,
    required,
    DatePickerTH,
    validateEmail,
    validateENNumber,
    toFixed
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";
import Switch from "react-switch";

import Resizer from "react-image-file-resizer";
import province from "../../../assets/files/province.json"
import district from "../../../assets/files/district.json"
import subdistrict from "../../../assets/files/subdistrict.json"

export default class ListMember extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_point: false,
            modal_password: false,
            modal_delete: false,

            page: 1,
            loading: false,

            data: null,
            data_status: [
                { value: 0, label: "แบล็กลิสต์" },
                { value: 1, label: "ปกติ" },
            ],

            filter_search: "",

            id: "",
            id_card: "",
            full_name: "",
            birthday: "",
            address: "",
            province: "",
            district: "",
            sub_district: "",
            zip_code: "",
            email: "",
            phone: "",
            picture: "",
            username: "",
            password: "",
            member_status: "",
            is_active: false,
            nationality: "",
            card_issue: "",
            date_issue: "",
            date_expire: "",
            facebook: "",
            id_line: "",

            points: "",
            sk_wallet: "",
            new_points: "",
            new_sk_wallet: "",

        }
    }
    async componentDidMount() {
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search,
        }
        let result = await GET(tokens, "v1/TH/user-member/filter-search/page/" + this.state.page, data);
        if (result && result.status) { 
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        if (validate(this.state, ["id_card", "full_name", "phone", "username"])) { return };
        if (this.state.phone.length > 0 && this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน")
            return
        } else if (this.state.email.length !== 0 && !validateEmail(this.state.email)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        } else if (this.state.password.length < 4) {
            alert("warning", "แจ้งเตือน", "รหัสผ่านต้องมากกว่า 4 ตัวขึ้นไป")
            return
        } else if (!this.state.email && !this.state.username) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล หรือ ชื่อผู้ใช้");
            return
        }
        this.setState({ loading: true });
        let data = {
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            birthday: this.state.birthday ? this.state.birthday : null,
            address: this.state.address,
            province: this.state.province ? this.state.province.value : "",
            district: this.state.district ? this.state.district.value : "",
            sub_district: this.state.sub_district ? this.state.sub_district.value : "",
            zip_code: this.state.zip_code,
            email: this.state.email,
            phone: this.state.phone,
            picture: this.state.picture,
            username: this.state.username,
            password: this.state.password,
            is_active: true,
            nationality: this.state.nationality,
            card_issue: this.state.card_issue,
            date_issue: this.state.date_issue,
            date_expire: this.state.date_expire,
            facebook: this.state.facebook,
            id_line: this.state.id_line,
        }
        let result = await POST(tokens, "v1/TH/user-member/create", data);
        if (result && result.status) {
            success_url("/master/list-member");
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        if (!this.state.modal_password) {
            if (validate(this.state, ["id_card", "full_name", "phone"])) { return };
            if (this.state.phone.length > 0 && this.state.phone.length < 10) {
                alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน")
                return
            } else if (this.state.email && this.state.email.length !== 0 && !validateEmail(this.state.email)) {
                alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
                return
            }
        } else {
            if (this.state.password.length !== 0 && this.state.password.length < 4) {
                alert("warning", "แจ้งเตือน", "รหัสผ่านต้องมากกว่า 4 ตัวขึ้นไป")
                return
            }
        }
        this.setState({ loading: true });
        let data = {
            member_id: this.state.id,
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            birthday: this.state.birthday ? this.state.birthday : null,
            address: this.state.address,
            province: this.state.province ? this.state.province.value : "",
            district: this.state.district ? this.state.district.value : "",
            sub_district: this.state.sub_district ? this.state.sub_district.value : "",
            zip_code: this.state.zip_code,
            email: this.state.email,
            phone: this.state.phone,
            picture: this.state.picture,
            username: this.state.username,
            password: this.state.password,
            member_status: this.state.member_status ? this.state.member_status.value : 1,
            is_active: this.state.is_active,
            nationality: this.state.nationality,
            card_issue: this.state.card_issue,
            date_issue: this.state.date_issue,
            date_expire: this.state.date_expire,
            facebook: this.state.facebook,
            id_line: this.state.id_line,
        } 
        let result = await PUT(tokens, "v1/TH/user-member/update", data);
        if (result && result.status) {
            success_url("/master/list-member");
        }
        this.setState({ loading: false });
    }
    UpdatePoint = async () => {
        this.setState({ loading: true });
        let data = {
            member_id: this.state.id,
            points: Number(this.state.new_points),
            sk_wallet: Number(this.state.new_sk_wallet),
        }
        let result = await PUT(tokens, "v1/TH/user-member/update_point", data);
        if (result && result.status) {
            success_url("/master/list-member");
        }
        this.setState({ loading: false });
    }
    Delete = async () => {
        this.setState({ loading: true });
        let result = await DELETE(tokens, 'v1/TH/user-member/delete/' + this.state.id, null);
        if (result && result.status) {
            success_url("/master/list-member")
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_password: false,
            modal_delete: false,

            page: 1,
            loading: false,

            filter_search: "",

            id: "",
            id_card: "",
            full_name: "",
            birthday: "",
            address: "",
            province: "",
            district: "",
            sub_district: "",
            zip_code: "",
            email: "",
            phone: "",
            picture: "",
            username: "",
            password: "",
            member_status: "",
            is_active: false,
        })
    }

    import_file = (e) => {
        this.changePhoto(e.target.files[0])
        document.getElementById("file").value = "";
        document.getElementById("file").file = null;
    }
    drop_file = (e) => {
        e.preventDefault();
        this.setState({ over: false });
        this.changePhoto(e.dataTransfer.items[0].getAsFile())
    }
    changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            this.setState({
                picture: e.target.result.split(",")[1],
            })
        }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    this.setState({ picture: uri.split(",")[1] })
                },
                "base64"
            );
        });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ข้อมูลสมาชิก" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ข้อมูลสมาชิก</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add" onClick={async () => { await this.clear_form(); this.setState({ modal_create: true }) }}>
                                    เพิ่มสมาชิก
                                </button>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0 view">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-250 border-0 mx-1 mb-2"
                                        placeholder="ชื่อ-นามสกุล,รหัสบัตรประชาชน"
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">รหัสสมาชิก</th>
                                                <th className="text-left">ชื่อ-นามสกุล</th>
                                                <th className="text-left">รหัสบัตรประชาชน</th>
                                                <th className="text-left">อีเมล</th>
                                                <th className="text-left">เบอร์โทรศํพท์</th>
                                                <th className="text-center">แต้ม</th>
                                                <th className="text-center">สถานะ</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={8} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.member_code ? item.member_code : "-"}</td>
                                                    <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                                                    <td className="text-left">{item.id_card ? item.id_card : "-"}</td>
                                                    <td className="text-left">{item.email ? item.email : "-"}</td>
                                                    <td className="text-left">{item.phone ? item.phone : "-"}</td>
                                                    <td className="text-center">{item.points ? toFixed(item.points) : "0.00"}</td>
                                                    <td className="text-center">
                                                        {item.is_active ? (
                                                            <span className="bg-green rounded px-2 py-1 text-white">
                                                                ใช้งาน
                                                            </span>
                                                        ) : (<span className="bg-red rounded px-2 py-1 text-white">
                                                            ปิดการใช้งาน
                                                        </span>)}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <label className="wpx-30 hpx-30 bg-success-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_point: true,
                                                                        id: item.member_id,
                                                                        id_card: item.id_card,
                                                                        full_name: item.full_name,
                                                                        birthday: item.birthday,
                                                                        address: item.address,
                                                                        zip_code: item.zip_code,
                                                                        email: item.email,
                                                                        phone: item.phone,
                                                                        picture: item.picture.replaceAll("%0D", "").replaceAll("%0d", ""),
                                                                        username: item.username,
                                                                        member_status: this.state.data_status.filter(e => e.value === item.member_status)[0],
                                                                        is_active: item.is_active,
                                                                        password: "",
                                                                        nationality: item.nationality,
                                                                        card_issue: item.card_issue,
                                                                        date_issue: item.date_issue,
                                                                        date_expire: item.date_expire,
                                                                        facebook: item.facebook,
                                                                        id_line: item.id_line,
                                                                        points: item.points,
                                                                        sk_wallet: item.sk_wallet,
                                                                        new_points: item.points,
                                                                        new_sk_wallet: item.sk_wallet
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-success pointer"
                                                                > {"\uf2e8"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-primary-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_password: true,
                                                                        id: item.member_id,
                                                                        id_card: item.id_card,
                                                                        full_name: item.full_name,
                                                                        birthday: item.birthday,
                                                                        address: item.address,
                                                                        zip_code: item.zip_code,
                                                                        email: item.email,
                                                                        phone: item.phone,
                                                                        picture: item.picture.replaceAll("%0D", "").replaceAll("%0d", ""),
                                                                        username: item.username,
                                                                        member_status: this.state.data_status.filter(e => e.value === item.member_status)[0],
                                                                        is_active: item.is_active,
                                                                        password: "",
                                                                        nationality: item.nationality,
                                                                        card_issue: item.card_issue,
                                                                        date_issue: item.date_issue,
                                                                        date_expire: item.date_expire,
                                                                        facebook: item.facebook,
                                                                        id_line: item.id_line,
                                                                    })

                                                                    if (item.province) {
                                                                        this.setState({
                                                                            province: province.filter(e => e.name_th === item.province).map(e => { { return { value: e.name_th, label: e.name_th, id: e.province_id } } })[0]
                                                                        })
                                                                    }
                                                                    if (item.district) {
                                                                        this.setState({
                                                                            district: district.filter(e => e.name_th === item.district).map(e => { { return { value: e.name_th, label: e.name_th, id: e.district_id } } })[0]
                                                                        })
                                                                    }
                                                                    if (item.sub_district) {
                                                                        this.setState({
                                                                            sub_district: subdistrict.filter(e => e.name_th === item.sub_district).map(e => { { return { value: e.name_th, label: e.name_th, zip_code: e.zip_code, id: e.sub_district_id } } })[0]
                                                                        })
                                                                    }
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-primary pointer"
                                                                > {"\uf084"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_update: true,
                                                                        id: item.member_id,
                                                                        id_card: item.id_card,
                                                                        full_name: item.full_name,
                                                                        birthday: item.birthday,
                                                                        address: item.address,
                                                                        zip_code: item.zip_code,
                                                                        email: item.email,
                                                                        phone: item.phone,
                                                                        picture: item.picture.replaceAll("%0D", "").replaceAll("%0d", ""),
                                                                        username: item.username,
                                                                        member_status: this.state.data_status.filter(e => e.value === item.member_status)[0],
                                                                        is_active: item.is_active,
                                                                        password: "",
                                                                        nationality: item.nationality,
                                                                        card_issue: item.card_issue,
                                                                        date_issue: item.date_issue,
                                                                        date_expire: item.date_expire,
                                                                        facebook: item.facebook,
                                                                        id_line: item.id_line,
                                                                    })
                                                                    if (item.province) {
                                                                        this.setState({
                                                                            province: province.filter(e => e.name_th === item.province).map(e => { { return { value: e.name_th, label: e.name_th, id: e.province_id } } })[0]
                                                                        })
                                                                    }
                                                                    if (item.district) {
                                                                        this.setState({
                                                                            district: district.filter(e => e.name_th === item.district).map(e => { { return { value: e.name_th, label: e.name_th, id: e.district_id } } })[0]
                                                                        })
                                                                    }
                                                                    if (item.sub_district) {
                                                                        this.setState({
                                                                            sub_district: subdistrict.filter(e => e.name_th === item.sub_district).map(e => { { return { value: e.name_th, label: e.name_th, zip_code: e.zip_code, id: e.sub_district_id } } })[0]
                                                                        })
                                                                    }
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer"
                                                                > {"\uf304"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-danger-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 delete"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_delete: true,
                                                                        id: item.member_id,
                                                                        full_name: item.full_name,
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-danger pointer"
                                                                > {"\uf1f8"} </label>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* create or update */}
                <Modal
                    show={this.state.modal_create || this.state.modal_update}
                    onHide={() => { this.setState({ modal_create: false, modal_update: false }) }}
                    size="lg"
                >
                    <Modal.Header> <Modal.Title> <b>{this.state.modal_create ? "เพิ่มสมาชิก" : "แก้ไขสมาชิก"}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-3">
                                <label className="w-100">รูปบัตรประชาชน</label>

                                {this.state.picture === "" ? (
                                    <div
                                        className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5 mb-3"
                                        style={{ height: "80%" }}
                                        onClick={() => { document.getElementById("file").click() }}
                                        onDrop={(e) => this.drop_file(e)}
                                        onDragOver={(e) => { this.setState({ over: true }); e.preventDefault() }}
                                        onDragLeave={(e) => { this.setState({ over: false }); e.preventDefault() }}
                                    >
                                        <label className="icon text-orange">{"\uf03e"}</label>
                                        <label className="text-orange">อัปโหลดภาพ</label>
                                    </div>
                                ) : (
                                    <div
                                        className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                    >
                                        <div className="position-relative w-100">
                                            <img alt="รูปบัตรประชาชน" src={this.state.picture.includes("https://") ? this.state.picture : "data:image/png;base64," + this.state.picture} className="w-100 rounded overflow-hidden"></img>
                                            <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.setState({ picture: "" }) }}>
                                                {"\uf00d"}
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e) }} />
                            </div>
                            <div className="col-12 col-md-12 col-lg-9">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* รหัสบัตรประชาชนหรือพาสปอร์ต */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">รหัสบัตรประชาชนหรือพาสปอร์ต{required}:</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                maxLength={13}
                                                onChange={(e) => {
                                                    if (!validateENNumber(e.target.value) && e.target.value !== "") {
                                                        return
                                                    }
                                                    this.setState({ id_card: e.target.value })
                                                }}
                                                value={this.state.id_card}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* ชื่อ-นามสกุล */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">ชื่อ-นามสกุล{required}:</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ full_name: e.target.value })
                                                }}
                                                value={this.state.full_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* วัน /เดือน /ปีเกิด */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">วัน /เดือน /ปีเกิด :</label>
                                            <DatePickerTH
                                                className="form-control"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ birthday: e });
                                                }}
                                                value={this.state.birthday}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">สัญชาติ :</label><br />
                                        <input
                                            className="form-control validate"
                                            type="text"
                                            placeholder="กรอกข้อมูล"
                                            onChange={(e) => {
                                                this.setState({ nationality: e.target.value })
                                            }}
                                            value={this.state.nationality}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        {/* ที่อยู่ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">ที่อยู่ :</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ address: e.target.value })
                                                }}
                                                value={this.state.address}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">จังหวัด : </label>
                                        <Select
                                            className="form-control select-search"
                                            styles={select_style}
                                            options={province.map(item => { { return { value: item.name_th, label: item.name_th, id: item.province_id } } })}
                                            onChange={(e) => {
                                                this.setState({ province: e, district: null, sub_district: null, zip_code: "" });
                                            }}
                                            value={this.state.province}
                                        ></Select>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100"> อำเภอ / เขต : </label>
                                        <Select
                                            className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                                            styles={select_style}
                                            options={district.filter(item => this.state.province && this.state.province.id === item.province_id).map(item => { { return { value: item.name_th, label: item.name_th, id: item.district_id } } })}
                                            onChange={(e) => {
                                                this.setState({ district: e, sub_district: null, zip_code: "" });
                                            }}
                                            value={this.state.district}
                                            isDisabled={!this.state.province}
                                        ></Select>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">ตำบล / แขวง : </label>

                                        <Select
                                            className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                                            styles={select_style}
                                            options={subdistrict.filter(item => this.state.district && this.state.district.id === item.district_id).map(item => { { return { value: item.name_th, label: item.name_th, zip_code: item.zip_code, id: item.sub_district_id } } })}
                                            onChange={(e) => {
                                                this.setState({ sub_district: e, zip_code: e.zip_code.toString() });
                                            }}
                                            value={this.state.sub_district}
                                            isDisabled={!this.state.district}
                                        ></Select>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">รหัสไปรษณีย์ : </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="รหัสไปรษณีย์"
                                            onChange={(e) => {
                                                this.setState({ zip_code: e.target.value })
                                            }}
                                            value={this.state.zip_code}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">วันออกบัตร :</label><br />
                                        <DatePickerTH
                                            className="form-control validate"
                                            onChange={(e) => {
                                                this.setState({ date_issue: e });
                                            }}
                                            value={this.state.date_issue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">วันที่หมดอายุ :</label><br />
                                        <DatePickerTH
                                            className="form-control validate"
                                            onChange={(e) => {
                                                this.setState({ date_expire: e });
                                            }}
                                            value={this.state.date_expire}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* เบอร์โทรศัพท์ */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">เบอร์โทรศัพท์{required}:</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                maxLength={20}
                                                onChange={(e) => {
                                                    // if (!float(e.target.value)) {
                                                    //     return
                                                    // }
                                                    this.setState({ phone: e.target.value })
                                                }}
                                                value={this.state.phone}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {/* อีเมล */}
                                        <div className="w-100 mb-3">
                                            <label className="w-100">อีเมล :</label>
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ email: e.target.value })
                                                }}
                                                value={this.state.email}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">facebook :</label><br />
                                        <input
                                            className="form-control validate"
                                            type="text"
                                            placeholder="กรอกข้อมูล"
                                            onChange={(e) => {
                                                this.setState({ facebook: e.target.value })
                                            }}
                                            value={this.state.facebook}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                                        <label className="w-100">ไลน์ไอดี :</label><br />
                                        <input
                                            className="form-control validate"
                                            type="text"
                                            placeholder="กรอกข้อมูล"
                                            onChange={(e) => {
                                                this.setState({ id_line: e.target.value })
                                            }}
                                            value={this.state.id_line}
                                        />
                                    </div>
                                    {this.state.modal_create && (
                                        <div className="col-12 col-md-6 col-lg-4">
                                            {/* ชื่อผู้ใช้ */}
                                            <div className="w-100 mb-3">
                                                <label className="w-100">ชื่อผู้ใช้{required}:</label>
                                                <input
                                                    className="form-control "
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        if (!validateENNumber(e.target.value) && e.target.value !== "") {
                                                            return
                                                        }
                                                        this.setState({ username: e.target.value })
                                                    }}
                                                    value={this.state.username}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {this.state.modal_create && (
                                        <div className="col-12 col-md-6 col-lg-4">
                                            {/* รหัสผ่าน */}
                                            <div className="w-100 mb-3">
                                                <label className="w-100">รหัสผ่าน{required}:</label>
                                                <input
                                                    className="form-control "
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ password: e.target.value })
                                                    }}
                                                    value={this.state.password}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {this.state.modal_update && (
                                        <div className="col-12 col-md-6 col-lg-4">
                                            {/* สถานะ */}
                                            <div className="w-100 mb-3">
                                                <label className="w-100">สถานะ :</label>
                                                <Select
                                                    className="form-control  select-search"
                                                    styles={select_style}
                                                    options={this.state.data_status}
                                                    onChange={(e) => {
                                                        this.setState({ member_status: e });
                                                    }}
                                                    value={this.state.member_status}
                                                ></Select>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.modal_update && (
                                        <div className="col-12 col-md-6 col-lg-4">
                                            {/* สถานะการใช้งาน */}
                                            <div className="w-100 mb-3">
                                                <label className="w-100">สถานะการใช้งาน:</label>
                                                <Switch onChange={(e) => {
                                                    this.setState({
                                                        is_active: e,
                                                    })
                                                }} checked={this.state.is_active} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        if (this.state.modal_create) {
                                            this.Create();
                                        } else {
                                            this.Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* point */}
                <Modal
                    show={this.state.modal_point}
                    onHide={() => { this.setState({ modal_point: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>แก้ไขคะแนน</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                {/* รหัสบัตรประชาชนหรือพาสปอร์ต */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">รหัสบัตรประชาชนหรือพาสปอร์ต :</label><br />
                                    <b>{this.state.id_card}</b>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                {/* ชื่อ-นามสกุล */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">ชื่อ-นามสกุล :</label><br />
                                    <b>{this.state.full_name}</b>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                {/* แต้มสะสม */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">แต้มสะสม :</label><br />
                                    <b>{this.state.points}</b>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                {/* เงินมัดจำคงเหลือ */}
                                <div className="w-100 mb-3">
                                    <label className="w-100">เงินมัดจำคงเหลือ :</label><br />
                                    <b>{toFixed(this.state.sk_wallet)}</b>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <label className="w-100">แต้มสะสมใหม่ :</label><br />
                                <input
                                    className="form-control validate"
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                        this.setState({ new_points: e.target.value })
                                    }}
                                    value={this.state.new_points}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <label className="w-100">เงินมัดจำคงเหลือใหม่ :</label><br />
                                <input
                                    className="form-control validate"
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                        this.setState({ new_sk_wallet: e.target.value })
                                    }}
                                    value={this.state.new_sk_wallet}
                                />
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_point: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.UpdatePoint();
                                    }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* password */}
                <Modal
                    show={this.state.modal_password}
                    onHide={() => { this.setState({ modal_password: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>แก้ไขรหัสผ่าน</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {/* ชื่อผู้ใช้ */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ชื่อผู้ใช้{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ username: e.target.value })
                                }}
                                value={this.state.username}
                            />
                        </div>
                        {/* รหัสผ่าน */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">รหัสผ่าน{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ password: e.target.value })
                                }}
                                value={this.state.password}
                            />
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_password: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.Update();
                                    }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ลบสมาชิก</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                ท่านแน่ในว่าต้องการลบสมาชิก “{this.state.full_name}” หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
