import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    alert,
    GET,
    POST,
    PUT,
    format_date,
    float,
    validate,
    required,
    DatePickerTH,
    success_url,
    toFixed,
    validateEmail,
    total,
    days_between,
    print_receipt_booking,
    status_booking
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import CAR from "../../assets/images/bike.png"
import EMPTY from "../../assets/images/empty.png";
export default class ListBooking extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_detail: false,
            modal_car: false,

            filter_search: "",
            filter_booking_status: "",
            filter_date: "",

            page: 1,
            loading: false,

            data: null,
            data_bank: [],
            data_bike: [],
            data_equipment: [],

            tab: "สัญญาการจอง",
            product_index: -1,
            type: "",
            update_type: "",

            id: "",
            id_card: "",
            full_name: "",
            email: "",
            phone: "",
            brand_id: "",
            model_id: "",
            pick_up_date: new Date(),
            pick_up_time: format_date(new Date(), "hh:mm"),
            pick_up_location: "",
            return_date: new Date(new Date().setDate(new Date().getDate() + 1)),
            return_time: format_date(new Date(), "hh:mm"),
            return_location: "",
            daily_price: "",
            deposit_pay: "",
            payment_date: "",
            payment_time: "",
            payment_picture: [],
            booking_status: "",
            deposit_price: "",
            booking_price: "",
            traffic_ticket_price: 0,
            booking_number: "",
            bank: "",

            days: 0,
            hours: 0,

            search_bike: "",

            selected_bike: null,

            payment_list: [],

            modal_picture: false,
            index: -1,

            close: false,
            end_date_booking_mc: "",
            start_date_booking_mc: "",

            total_price: 0,
            old_daily_price: 0,
            dropdowns: -1,


            delivery_price: 0,
            total_price_equipment: 0,
        }
        this.divRef1 = React.createRef();
        this.divRef2 = React.createRef();
        this.divRef3 = React.createRef();
        this.divRef4 = React.createRef();
        this.divRef5 = React.createRef();
        this.divRef6 = React.createRef();
        this.divRef7 = React.createRef();
        this.divRef8 = React.createRef();
        this.divRef9 = React.createRef();
        this.divRef10 = React.createRef();
        this.divRef11 = React.createRef();
        this.divRef12 = React.createRef();
        this.divRef13 = React.createRef();
        this.close_dropdown = this.close_dropdown.bind(this);
    }
    async componentDidMount() {
        document.addEventListener('mousedown', this.close_dropdown);
        await this.GetAll();
        await this.GetBikeAll();
        await this.GetBankAll();
        await this.GetSettingAll();
        await this.GetEquipmentAll();
    }
    componentWillUnmount() {
        document.addEventListener('mousedown', this.close_dropdown);
    }
    close_dropdown = (event) => {
        if ((this.divRef1.current && this.divRef1.current.contains(event.target)) ||
            (this.divRef2.current && this.divRef2.current.contains(event.target)) ||
            (this.divRef3.current && this.divRef3.current.contains(event.target)) ||
            (this.divRef4.current && this.divRef4.current.contains(event.target)) ||
            (this.divRef5.current && this.divRef5.current.contains(event.target)) ||
            (this.divRef6.current && this.divRef6.current.contains(event.target)) ||
            (this.divRef7.current && this.divRef7.current.contains(event.target)) ||
            (this.divRef8.current && this.divRef8.current.contains(event.target)) ||
            (this.divRef9.current && this.divRef9.current.contains(event.target)) ||
            (this.divRef10.current && this.divRef10.current.contains(event.target)) ||
            (this.divRef11.current && this.divRef11.current.contains(event.target)) ||
            (this.divRef12.current && this.divRef12.current.contains(event.target)) ||
            (this.divRef13.current && this.divRef13.current.contains(event.target))) { } else {
            this.setState({ dropdowns: -1 })
        }
    }
    GetBankAll = async () => {
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bank-account/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_bank: result.data.data })
            console.log(result.data.data)
        }
    }
    GetSettingAll = async () => {
        let result = await GET(tokens, "v1/TH/company/detail", {});
        if (result && result.status) {
            let data = result.data.data;
            if (new Date(data.start_date_booking_mc).getTime() <= new Date().getTime() && new Date().getTime() <= new Date(data.end_date_booking_mc).getTime()) {
                this.setState({
                    close: true,
                    end_date_booking_mc: data.end_date_booking_mc,
                    start_date_booking_mc: data.start_date_booking_mc,
                })
            }
        }
    }
    GetEquipmentAll = async () => {
        let result = await GET(tokens, "v1/TH/equipment/filter-search/page/0", null);
        if (result && result.status) {
            this.setState({ data_equipment: result.data.data.map(item => { return { ...item, equipment_qty: 0, booking_equipment_id: "" } }) })
        }
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            booking_status: this.state.filter_booking_status ? this.state.filter_booking_status.value : "",
        }
        let result = await GET(tokens, "v1/TH/booking/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    GetMemberByIDCard = async () => {
        if (this.state.id_card === "") { return }
        this.setState({ loading: true });
        let data = {
            data_search: this.state.id_card,
        }
        let result = await GET(tokens, "v1/TH/user-member/filter-search/page/0", data);
        if (result && result.status) {
            if (result.data.data.filter(e => e.id_card === this.state.id_card).length === 1) {

                if (result.data.data.filter(e => e.id_card === this.state.id_card)[0].member_status === 0) {
                    alert("warning", "แจ้งเตือน", "ไม่สามารถทำการจองได้เนื่องจากลูกค้าติดแบล็กลิสต์");
                    this.setState({ loading: false })
                    return
                }
                this.setState({
                    id_card: result.data.data.filter(e => e.id_card === this.state.id_card)[0].id_card,
                    full_name: result.data.data.filter(e => e.id_card === this.state.id_card)[0].full_name,
                    email: result.data.data.filter(e => e.id_card === this.state.id_card)[0].email,
                    phone: result.data.data.filter(e => e.id_card === this.state.id_card)[0].phone,
                    traffic_ticket_price: total(result.data.data.filter(e => e.id_card === this.state.id_card)[0].traffic_ticket.filter(e => e.ticket_status !== 2), "traffic_fines")
                });
            } else {
                this.setState({ full_name: "", email: "", phone: "" });
                alert("warning", "แจ้งเตือน", "ไม่พบข้อมูลสมาชิก");
            }
        }
        this.setState({ loading: false });
    }
    GetBikeAll = async () => {
        let data = {
            start_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") + " " + this.state.pick_up_time : null,
            end_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") + " " + this.state.return_time : null,
            search: ""
        }
        let result = await GET(tokens, "v1/TH/bike-manage/filter-search-public/page/0", data);
        if (result && result.status) {
            this.setState({ data_bike: result.data.data });
        }
    }
    Create = async () => {
        if (validate(this.state, ["id_card", "full_name", "phone", "selected_bike", "pick_up_location", "return_location"])) { return };
        if (this.state.email !== "" && !validateEmail(this.state.email)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        }
        if (this.state.phne !== "" && this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบเบอร์โทรศัพท์ให้ถูกต้อง")
            return
        }
        this.setState({ loading: true });
        let data = {
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            email: this.state.email,
            phone: this.state.phone,
            brand_id: this.state.selected_bike.brand_id,
            model_id: this.state.selected_bike.model_id,
            pick_up_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : "",
            pick_up_time: this.state.pick_up_time,
            pick_up_location: this.state.pick_up_location,
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : "",
            return_time: this.state.return_time,
            return_location: this.state.return_location,
            daily_price: this.state.selected_bike.daily_price,
            title_picture: this.state.selected_bike ? this.state.selected_bike.title_picture : "",
            traffic_ticket_price: 0,
            delivery_price: Number(this.state.delivery_price),
            equipment_fee: Number(this.state.total_price_equipment),
            equipment_list: this.state.data_equipment.filter(e => e.equipment_qty > 0)
        }
        let result = await POST(tokens, "v1/TH/booking/create", data);
        if (result && result.status) {
            success_url("/admin/list-booking")
        }
        this.setState({ loading: false });

    }
    Update = async () => {
        let booking_status = this.state.booking_status;
        if (booking_status != 5) {
            if (validate(this.state, ["id_card", "full_name", "phone", "selected_bike", "pick_up_location", "return_location"])) { return };
            if (this.state.email !== "" && !validateEmail(this.state.email)) {
                alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
                return
            }
        }
        if (booking_status === 1 && Number(this.state.deposit_price) !== 0) {
            booking_status = 2;
        } else if (booking_status === 2) {
            booking_status = 2;
        } else if (booking_status === 3 && this.state.deposit_pay && this.state.payment_date && this.state.payment_time) {
            booking_status = 4;
        }
        this.setState({ loading: true });
        let data = {
            booking_id: this.state.id,
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            email: this.state.email,
            phone: this.state.phone,
            brand_id: this.state.selected_bike.brand_id,
            model_id: this.state.selected_bike.model_id,
            pick_up_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : "",
            pick_up_time: this.state.pick_up_time,
            pick_up_location: this.state.pick_up_location,
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : "",
            return_time: this.state.return_time,
            return_location: this.state.return_location,
            daily_price: this.state.selected_bike.daily_price,
            title_picture: this.state.selected_bike ? this.state.selected_bike.title_picture : "",
            deposit_pay: Number(this.state.deposit_pay),
            payment_date: this.state.payment_date,
            payment_time: this.state.payment_time,
            payment_picture: this.state.payment_picture,
            booking_status: booking_status,
            deposit_price: Number(this.state.deposit_price),
            booking_price: Number(this.state.booking_price),
            traffic_ticket_price: Number(this.state.traffic_ticket_price),
            admin_remark: (this.state.booking_status === 2 || this.state.booking_status === 3 || this.state.booking_status === 4) && this.state.old_daily_price < this.state.selected_bike.daily_price ? "รอตรวจสอบ" : "",

            delivery_price: Number(this.state.delivery_price),
            equipment_fee: Number(this.state.total_price_equipment),
            equipment_list: this.state.data_equipment.filter(e => e.equipment_qty > 0),

            bank_name : this.state.bank ? this.state.bank.split(":")[0] : "",
            account_number : this.state.bank ? this.state.bank.split(":")[1] : "",
        }

        let result = await PUT(tokens, "v1/TH/booking/update", data);
        if (result && result.status) {
            success_url("/admin/list-booking")
        }
        this.setState({ loading: false });
    }
    import_file = (e) => {
        this.changePhoto(e.target.files[0])
        document.getElementById("file").value = "";
        document.getElementById("file").file = null;
    }
    drop_file = (e) => {
        e.preventDefault();
        this.changePhoto(e.dataTransfer.items[0].getAsFile())
    }
    changePhoto = async (file) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            this.setState({
                payment_picture: e.target.result.split(",")[1],
            })
        }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    this.setState({ payment_picture: uri.split(",")[1] })
                },
                "base64"
            );
        });
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_detail: false,
            modal_car: false,

            filter_search: "",
            filter_booking_status: "",

            page: 1,
            loading: false,
            tab: "สัญญาการจอง",
            product_index: -1,
            type: "",

            id: "",
            id_card: "",
            full_name: "",
            email: "",
            phone: "",
            brand_id: "",
            model_id: "",
            pick_up_date: "",
            pick_up_time: "",
            pick_up_location: "",
            return_date: "",
            return_time: "",
            return_location: "",
            daily_price: "",
            deposit_pay: "",
            payment_date: "",
            payment_time: "",
            payment_picture: [],
            booking_status: "",
            deposit_price: "",
            booking_price: "",
            traffic_ticket_price: "",
            days: 0,
            booking_number: "",

            search_bike: "",

            selected_bike: null,

            delivery_price: 0,
            total_price_equipment: 0,
        })
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in position-relative">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="การจองรถ" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>การจองรถ</b></h4>
                            {!this.state.close ? (
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add"
                                    onClick={() => {
                                        this.clear_form();
                                        let data_equipment = this.state.data_equipment.map(e => { return { ...e, equipment_qty: 0 } });
                                        this.setState({ data_equipment: data_equipment, modal_create: true });
                                    }}>
                                    เพิ่มการจอง
                                </button>
                            ) : (<span className="text-danger add">ไม่สามารถจองได้เนื่องจากระบบ ปิดการจองรถ ตั้งแต่วันที่ {format_date(this.state.start_date_booking_mc)} - {format_date(this.state.end_date_booking_mc)}</span>)}
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    <Select
                                        className="form-control select-search bg-white wpx-200 border-0 mx-1 mb-2"
                                        styles={select_style}
                                        options={[
                                            { value: "1", label: "รอตรวจสอบ" },
                                            { value: "2", label: "รอเงินจอง" },
                                            { value: "3", label: "รออนุมัติ" },
                                            { value: "4", label: "อนุมัติ" },
                                            { value: "5", label: "ยกเลิก" },
                                            { value: "6", label: "ค้างชำระ" },
                                            { value: "7", label: "มีสัญญาเช่า" },
                                        ]}
                                        onChange={(e) => {
                                            this.setState({ filter_booking_status: e, page: 1 });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_booking_status}
                                        isClearable
                                    ></Select>
                                    <div className="wpx-200 mx-1 mb-2 px-0">
                                        <DatePickerTH
                                            className="form-control bg-white border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_date: e, page: 1 });
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }}
                                            onBlur={() => {
                                                this.GetAll();
                                            }}
                                            value={this.state.filter_date}
                                        />
                                    </div>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_booking_status: "",
                                                filter_date: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง notebook */}
                                <div className="table-responsive pb-5 d-none d-sm-none d-md-block">
                                    <table className="table table-borderless table-striped" style={{ marginBottom: 100 }}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">เลขที่การจอง</th>
                                                <th className="text-center">วันที่จอง</th>
                                                <th className="text-center">วันที่คืน</th>
                                                <th className="text-center">รหัสบัตรประชาชน</th>
                                                <th className="text-center">ชื่อ-นามสกุล</th>
                                                <th className="text-center">E-mail</th>
                                                <th className="text-center">ข้อมูลรถ</th>
                                                <th className="text-center">เลขที่บัญชีธนาคาร</th>
                                                <th className="text-center">สถานะ</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={9} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.booking_number ? item.booking_number : "-"}</td>
                                                    <td className="text-center">
                                                        {item.pick_up_date ? format_date(item.pick_up_date) : "-"}
                                                        {item.pick_up_time && (<br/>)}
                                                        {item.pick_up_time ? item.pick_up_time + " น." : ""}
                                                        </td>
                                                    <td className="text-center">
                                                        {item.return_date ? format_date(item.return_date) : "-"}
                                                        {item.return_time && (<br/>)}
                                                        {item.return_time ? item.return_time + " น." : ""}
                                                        </td>
                                                    <td className="text-center">{item.id_card ? item.id_card : "-"}</td>
                                                    <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                                                    <td className="text-center">{item.email ? item.email : "-"}</td>
                                                    <td className="text-center">{item.brand_name || item.model_name ? item.brand_name + " " + item.model_name : "-"}</td>
                                                    <td className="text-center">{item.account_number ? item.bank_name + " : " + item.account_number : "-"}</td>
                                                    <td className="text-center wpx-120">
                                                        {status_booking(item.booking_status)}
                                                    </td>
                                                    <td className="text-center position-relative">
                                                        <label className="icon text-18 p-2 pointer" ref={this.divRef9} onClick={() => {
                                                            this.setState({ dropdowns: this.state.dropdowns === index ? -1 : index })
                                                        }}>{"\uf141"}</label>
                                                        {this.state.dropdowns === index && (
                                                            <div className="bg-white position-absolute shadow" style={{ top: 40, right: 12, zIndex: 1000 }}>
                                                                <button ref={this.divRef10} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left view" onClick={() => {
                                                                    this.setState({ id_card: item.id_card });
                                                                    setTimeout(async () => {
                                                                        await this.GetMemberByIDCard();
                                                                        let payment_list = item.payment_list.length !== 0 ? item.payment_list[0] : null;
                                                                        let days = days_between(
                                                                            new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                                                                            new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en"))),
                                                                            item.pick_up_time,
                                                                            item.return_time
                                                                        )
                                                                        let hours_price = 0;
                                                                        if (days.day === 0) {
                                                                            hours_price = item.daily_price;
                                                                        } else {
                                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                                //ไม่ต้องหัก 
                                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                                if (item.daily_price < 400) {
                                                                                    hours_price = 50; //หัก 50 บาท
                                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                    hours_price = 100; //หัก 100 บาท
                                                                                } else if (item.daily_price > 700) {
                                                                                    hours_price = 200; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                                if (item.daily_price < 400) {
                                                                                    hours_price = 100; //หัก 50 บาท
                                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                    hours_price = 200; //หัก 100 บาท
                                                                                } else if (item.daily_price > 700) {
                                                                                    hours_price = 400; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                                if (item.daily_price < 400) {
                                                                                    hours_price = 150; //หัก 50 บาท
                                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                    hours_price = 300; //หัก 100 บาท
                                                                                } else if (item.daily_price > 700) {
                                                                                    hours_price = 600; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour >= 4) {
                                                                                hours_price = item.daily_price;//หัก daily_price
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            modal_detail: true,
                                                                            id: item.booking_id,
                                                                            id_card: item.id_card,
                                                                            full_name: item.full_name,
                                                                            email: item.email,
                                                                            phone: item.phone,
                                                                            brand_id: item.brand_id,
                                                                            model_id: item.model_id,
                                                                            pick_up_date: item.pick_up_date,
                                                                            pick_up_time: item.pick_up_time,
                                                                            pick_up_location: item.pick_up_location,
                                                                            return_date: item.return_date,
                                                                            return_time: item.return_time,
                                                                            return_location: item.return_location,
                                                                            daily_price: item.daily_price,
                                                                            deposit_pay: item.deposit_pay,
                                                                            payment_date: payment_list ? payment_list.payment_date : null,
                                                                            payment_time: payment_list ? payment_list.payment_time : "",
                                                                            payment_picture: payment_list ? payment_list.payment_picture : [],
                                                                            booking_status: item.booking_status,
                                                                            deposit_price: item.deposit_price ? item.deposit_price : (item.daily_price * days.day) + hours_price + item.delivery_price + item.equipment_fee,
                                                                            booking_price: (item.daily_price * days.day) + hours_price + item.delivery_price + item.equipment_fee,
                                                                            traffic_ticket_price: item.traffic_ticket_price ? item.traffic_ticket_price : this.state.traffic_ticket_price,

                                                                            days: days.day,
                                                                            hours: days.hour,
                                                                            total_price: (item.daily_price * days.day) + hours_price,

                                                                            search_bike: "",

                                                                            selected_bike: {
                                                                                brand_id: item.brand_id,
                                                                                brand_name: item.brand_name,
                                                                                model_id: item.model_id,
                                                                                model_name: item.model_name,
                                                                                title_picture: item.title_picture,
                                                                                daily_price: item.daily_price
                                                                            },
                                                                            tab: "สัญญาการจอง",
                                                                            payment_list: item.payment_list,

                                                                            delivery_price: item.delivery_price,
                                                                            total_price_equipment: item.equipment_fee,
                                                                            old_daily_price: item.daily_price,

                                                                            bank: item.account_number ? item.bank_name + ":" + item.account_number : ""
                                                                        })
                                                                        if (item.equipment_list) {
                                                                            let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
                                                                            for (let eq of item.equipment_list) {
                                                                                let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                                                                                if (index !== -1) {
                                                                                    data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                                                                                    data_equipment[index].equipment_qty = eq.equipment_qty;
                                                                                }
                                                                            }
                                                                            this.setState({ data_equipment: data_equipment });
                                                                        }
                                                                    }, 10);
                                                                }}>
                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                    รายละเอียด
                                                                </button>

                                                                {item.booking_status === 4 && (
                                                                    <button ref={this.divRef11} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit" onClick={() => {
                                                                        window.location.href = "/admin/manage-rent?booking_id=" + item.booking_id +
                                                                            "&booking_price=" + item.booking_price +
                                                                            "&deposit_price=" + total(item.payment_list, "deposit_price") +
                                                                            "&traffic_ticket_price=" + item.traffic_ticket_price +
                                                                            "&equipment_fee=" + item.equipment_fee +
                                                                            "&delivery_price=" + item.delivery_price +
                                                                            "&equipment_list=" + JSON.stringify(item.equipment_list);
                                                                        // window.location.href = "/admin/manage-rent?booking_id=" + item.booking_id +
                                                                        //     "&booking_price=" + item.booking_price +
                                                                        //     "&deposit_price=" + total(item.payment_list, "deposit_price") +
                                                                        //     "&traffic_ticket_price=" + item.traffic_ticket_price;
                                                                    }}>
                                                                        <label className="icon mx-2">{"\uf15b"}</label>
                                                                        ทำสัญญาเช่า
                                                                    </button>
                                                                )}
                                                                <button ref={this.divRef12} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left view"
                                                                    onClick={async () => {
                                                                        print_receipt_booking(item)
                                                                    }}>
                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                    พิมพ์ใบเสร็จ
                                                                </button>

                                                                {item.booking_status <= 3 && (
                                                                    <button ref={this.divRef13} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit" onClick={() => {
                                                                        let payment_list = item.payment_list.length !== 0 ? item.payment_list[0] : null;

                                                                        this.setState({
                                                                            modal_delete: true,
                                                                            booking_number: item.booking_number,
                                                                            id: item.booking_id,
                                                                            id_card: item.id_card,
                                                                            full_name: item.full_name,
                                                                            email: item.email,
                                                                            phone: item.phone,
                                                                            brand_id: item.brand_id,
                                                                            model_id: item.model_id,
                                                                            pick_up_date: item.pick_up_date,
                                                                            pick_up_time: item.pick_up_time,
                                                                            pick_up_location: item.pick_up_location,
                                                                            return_date: item.return_date,
                                                                            return_time: item.return_time,
                                                                            return_location: item.return_location,
                                                                            daily_price: item.daily_price,
                                                                            deposit_pay: item.deposit_pay,
                                                                            payment_date: payment_list ? payment_list.payment_date : null,
                                                                            payment_time: payment_list ? payment_list.payment_time : "",
                                                                            payment_picture: payment_list ? payment_list.payment_picture : [],
                                                                            booking_status: 5,
                                                                            deposit_price: item.deposit_price,
                                                                            booking_price: item.booking_price,
                                                                            traffic_ticket_price: item.traffic_ticket_price,
                                                                            days: days_between(
                                                                                new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                                                                                new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en")))
                                                                            ),

                                                                            search_bike: "",

                                                                            selected_bike: {
                                                                                brand_id: item.brand_id,
                                                                                brand_name: item.brand_name,
                                                                                model_id: item.model_id,
                                                                                model_name: item.model_name,
                                                                                title_picture: item.title_picture,
                                                                                daily_price: item.daily_price
                                                                            },
                                                                            tab: "สัญญาการจอง",
                                                                            payment_list: item.payment_list,
                                                                            update_type: "ยกเลิกรายการ",

                                                                            delivery_price: item.delivery_price,
                                                                            total_price_equipment: item.equipment_fee,
                                                                            old_daily_price: item.daily_price
                                                                        })
                                                                        if (item.equipment_list) {
                                                                            let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
                                                                            for (let eq of item.equipment_list) {
                                                                                let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                                                                                if (index !== -1) {
                                                                                    data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                                                                                    data_equipment[index].equipment_qty = eq.equipment_qty;
                                                                                }
                                                                            }
                                                                            this.setState({ data_equipment: data_equipment });
                                                                        }
                                                                    }}>
                                                                        <label className="icon mx-2">{"\uf15b"}</label>
                                                                        ยกเลิกรายการ
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {/* ตาราง mobile */}
                                <div className="card-body p-2 overflow-scroll none-scroll d-block d-sm-block d-md-none">
                                    {this.state.data && this.state.data.data.map((item, index) => (
                                        <div className="card bg-white p-2 mt-2">
                                            <div className="d-flex row">
                                                <div className="w-100 d-flex justify-content-between">
                                                    <div className="d-flex row mx-0">
                                                        <div className="wpx-180 px-0"><b>เลขใบเช่า : </b><label className="text-orange">{item.booking_number ? item.booking_number : "-"}</label></div>
                                                        <div className="px-0"><b>ผู้เช่า : </b><label>{item.full_name ? item.full_name : "-"}</label></div>
                                                    </div>
                                                    <div className="d-flex">
                                                        {status_booking(item.booking_status)}
                                                        <div className="position-relative">
                                                            <label ref={this.divRef1} className="icon text-14 px-2 pt-1 pointer" onClick={() => {
                                                                this.setState({ dropdowns: this.state.dropdowns === index ? -1 : index })
                                                            }}>{"\uf141"}</label>
                                                            {this.state.dropdowns === index && (
                                                                <div className="bg-white position-absolute shadow" style={{ top: 40, right: 12, zIndex: 1000 }}>
                                                                    <button ref={this.divRef2} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left view" onClick={() => {
                                                                        this.setState({ id_card: item.id_card });
                                                                        setTimeout(async () => {
                                                                            await this.GetMemberByIDCard();
                                                                            let payment_list = item.payment_list.length !== 0 ? item.payment_list[0] : null;
                                                                            let days = days_between(
                                                                                new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                                                                                new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en"))),
                                                                                item.pick_up_time,
                                                                                item.return_time
                                                                            )
                                                                            let hours_price = 0;
                                                                            if (days.day === 0) {
                                                                                hours_price = item.daily_price;
                                                                            } else {
                                                                                if (days.hour === 0 && days.minute >= 0) {
                                                                                    //ไม่ต้องหัก 
                                                                                } else if (days.hour === 1 && days.minute >= 0) {
                                                                                    if (item.daily_price < 400) {
                                                                                        hours_price = 50; //หัก 50 บาท
                                                                                    } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                        hours_price = 100; //หัก 100 บาท
                                                                                    } else if (item.daily_price > 700) {
                                                                                        hours_price = 200; //หัก 200 บาท
                                                                                    }
                                                                                } else if (days.hour === 2 && days.minute >= 0) {
                                                                                    if (item.daily_price < 400) {
                                                                                        hours_price = 100; //หัก 50 บาท
                                                                                    } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                        hours_price = 200; //หัก 100 บาท
                                                                                    } else if (item.daily_price > 700) {
                                                                                        hours_price = 400; //หัก 200 บาท
                                                                                    }
                                                                                } else if (days.hour === 3 && days.minute >= 0) {
                                                                                    if (item.daily_price < 400) {
                                                                                        hours_price = 150; //หัก 50 บาท
                                                                                    } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                        hours_price = 300; //หัก 100 บาท
                                                                                    } else if (item.daily_price > 700) {
                                                                                        hours_price = 600; //หัก 200 บาท
                                                                                    }
                                                                                } else if (days.hour >= 4) {
                                                                                    hours_price = item.daily_price;//หัก daily_price
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                modal_detail: true,
                                                                                id: item.booking_id,
                                                                                id_card: item.id_card,
                                                                                full_name: item.full_name,
                                                                                email: item.email,
                                                                                phone: item.phone,
                                                                                brand_id: item.brand_id,
                                                                                model_id: item.model_id,
                                                                                pick_up_date: item.pick_up_date,
                                                                                pick_up_time: item.pick_up_time,
                                                                                pick_up_location: item.pick_up_location,
                                                                                return_date: item.return_date,
                                                                                return_time: item.return_time,
                                                                                return_location: item.return_location,
                                                                                daily_price: item.daily_price,
                                                                                deposit_pay: item.deposit_pay,
                                                                                payment_date: payment_list ? payment_list.payment_date : null,
                                                                                payment_time: payment_list ? payment_list.payment_time : "",
                                                                                payment_picture: payment_list ? payment_list.payment_picture : [],
                                                                                booking_status: item.booking_status,
                                                                                deposit_price: item.deposit_price ? item.deposit_price : (item.daily_price * days.day) + hours_price + item.delivery_price + item.equipment_fee,
                                                                                booking_price: (item.daily_price * days.day) + hours_price + item.delivery_price + item.equipment_fee,
                                                                                traffic_ticket_price: item.traffic_ticket_price ? item.traffic_ticket_price : this.state.traffic_ticket_price,

                                                                                days: days.day,
                                                                                hours: days.hour,
                                                                                total_price: (item.daily_price * days.day) + hours_price,

                                                                                search_bike: "",

                                                                                selected_bike: {
                                                                                    brand_id: item.brand_id,
                                                                                    brand_name: item.brand_name,
                                                                                    model_id: item.model_id,
                                                                                    model_name: item.model_name,
                                                                                    title_picture: item.title_picture,
                                                                                    daily_price: item.daily_price
                                                                                },
                                                                                tab: "สัญญาการจอง",
                                                                                payment_list: item.payment_list,

                                                                                delivery_price: item.delivery_price,
                                                                                total_price_equipment: item.equipment_fee,
                                                                                old_daily_price: item.daily_price
                                                                            })
                                                                            if (item.equipment_list) {
                                                                                let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
                                                                                for (let eq of item.equipment_list) {
                                                                                    let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                                                                                    if (index !== -1) {
                                                                                        data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                                                                                        data_equipment[index].equipment_qty = eq.equipment_qty;
                                                                                    }
                                                                                }
                                                                                this.setState({ data_equipment: data_equipment });
                                                                            }
                                                                        }, 10);
                                                                    }}>
                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                        รายละเอียด
                                                                    </button>

                                                                    {item.booking_status === 4 && (
                                                                        <button ref={this.divRef6} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit" onClick={() => {
                                                                            window.location.href = "/admin/manage-rent?booking_id=" + item.booking_id +
                                                                                "&booking_price=" + item.booking_price +
                                                                                "&deposit_price=" + total(item.payment_list, "deposit_price") +
                                                                                "&traffic_ticket_price=" + item.traffic_ticket_price +
                                                                                "&equipment_fee=" + item.equipment_fee +
                                                                                "&delivery_price=" + item.delivery_price +
                                                                                "&equipment_list=" + JSON.stringify(item.equipment_list);
                                                                            // window.location.href = "/admin/manage-rent?booking_id=" + item.booking_id +
                                                                            //     "&booking_price=" + item.booking_price +
                                                                            //     "&deposit_price=" + total(item.payment_list, "deposit_price") +
                                                                            //     "&traffic_ticket_price=" + item.traffic_ticket_price;
                                                                        }}>
                                                                            <label className="icon mx-2">{"\uf15b"}</label>
                                                                            ทำสัญญาเช่า
                                                                        </button>
                                                                    )}
                                                                    <button ref={this.divRef7} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left view"
                                                                        onClick={async () => {
                                                                            print_receipt_booking(item)
                                                                        }}>
                                                                        <label className="icon mx-2">{"\uf543"}</label>
                                                                        พิมพ์ใบเสร็จ
                                                                    </button>

                                                                    {item.booking_status <= 3 && (
                                                                        <button ref={this.divRef8} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left edit" onClick={() => {
                                                                            let payment_list = item.payment_list.length !== 0 ? item.payment_list[0] : null;

                                                                            this.setState({
                                                                                modal_delete: true,
                                                                                booking_number: item.booking_number,
                                                                                id: item.booking_id,
                                                                                id_card: item.id_card,
                                                                                full_name: item.full_name,
                                                                                email: item.email,
                                                                                phone: item.phone,
                                                                                brand_id: item.brand_id,
                                                                                model_id: item.model_id,
                                                                                pick_up_date: item.pick_up_date,
                                                                                pick_up_time: item.pick_up_time,
                                                                                pick_up_location: item.pick_up_location,
                                                                                return_date: item.return_date,
                                                                                return_time: item.return_time,
                                                                                return_location: item.return_location,
                                                                                daily_price: item.daily_price,
                                                                                deposit_pay: item.deposit_pay,
                                                                                payment_date: payment_list ? payment_list.payment_date : null,
                                                                                payment_time: payment_list ? payment_list.payment_time : "",
                                                                                payment_picture: payment_list ? payment_list.payment_picture : [],
                                                                                booking_status: 5,
                                                                                deposit_price: item.deposit_price,
                                                                                booking_price: item.booking_price,
                                                                                traffic_ticket_price: item.traffic_ticket_price,
                                                                                days: days_between(
                                                                                    new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                                                                                    new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en")))
                                                                                ),

                                                                                search_bike: "",

                                                                                selected_bike: {
                                                                                    brand_id: item.brand_id,
                                                                                    brand_name: item.brand_name,
                                                                                    model_id: item.model_id,
                                                                                    model_name: item.model_name,
                                                                                    title_picture: item.title_picture,
                                                                                    daily_price: item.daily_price
                                                                                },
                                                                                tab: "สัญญาการจอง",
                                                                                payment_list: item.payment_list,
                                                                                update_type: "ยกเลิกรายการ",

                                                                                delivery_price: item.delivery_price,
                                                                                total_price_equipment: item.equipment_fee,
                                                                                old_daily_price: item.daily_price
                                                                            })
                                                                            if (item.equipment_list) {
                                                                                let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
                                                                                for (let eq of item.equipment_list) {
                                                                                    let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                                                                                    if (index !== -1) {
                                                                                        data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                                                                                        data_equipment[index].equipment_qty = eq.equipment_qty;
                                                                                    }
                                                                                }
                                                                                this.setState({ data_equipment: data_equipment });
                                                                            }
                                                                        }}>
                                                                            <label className="icon mx-2">{"\uf15b"}</label>
                                                                            ยกเลิกรายการ
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex row mx-0">
                                                    <div className="wpx-180 px-0 mb-1">
                                                        <b>วันที่เช่า</b><label>: {item.pick_up_date ? format_date(item.pick_up_date) : "-"} {item.pick_up_time ? item.pick_up_time.slice(0, 5) : "-"}</label>
                                                    </div>
                                                    <div className="wpx-180 px-0 mb-1">
                                                        <b>วันที่คืน</b><label>: {item.return_date ? format_date(item.return_date) : "-"} {item.return_time ? item.return_time.slice(0, 5) : "-"}</label>
                                                    </div>
                                                    <div className="wpx-180 px-0 mb-1">
                                                        <b>ยี่ห้อ</b><label>: {item.brand_name ? item.brand_name : "-"}</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex row mx-0">
                                                    <div className="mr-4 px-0 mb-1">
                                                        <b>รุ่น</b><label>: {item.model_name ? item.model_name : "-"}</label>
                                                    </div>
                                                    <div className="mr-4 px-0 mb-1">
                                                        <b>สถานที่รับรถ</b><label>: {item.pick_up_location ? item.pick_up_location : "-"}</label>
                                                    </div>
                                                    <div className="mr-4 px-0 mb-1">
                                                        <b>สถานที่คืนรถ</b><label>: {item.return_location ? item.return_location : "-"}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div style={{ height: 100 }}></div>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>

                {/* MODAL */}

                {/* create or update */}
                <Modal
                    show={this.state.modal_create || this.state.modal_update || this.state.modal_detail}
                    onHide={() => { this.setState({ modal_create: false, modal_update: false, modal_detail: false }) }}
                    size="lg"
                >
                    <Modal.Header> <Modal.Title>
                        <b>{this.state.modal_create ? "เพิ่มการจองรถ" : this.state.modal_update ? "แก้ไขการจองรถ" : "รายละเอียดการจองรถ"}
                        </b>
                        {!this.state.modal_create &&
                            status_booking(this.state.booking_status)}
                    </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {!this.state.modal_create && (
                            <div className="w-100 d-flex mb-3 border-bottom">
                                {this.state.tab === "สัญญาการจอง" ? (
                                    <div className="bg-contain text-orange p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "สัญญาการจอง" })
                                        }}>สัญญาการจอง</div>
                                ) : (
                                    <div className="bg-white text-dark p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "สัญญาการจอง" })
                                        }}>สัญญาการจอง</div>
                                )}

                                {this.state.tab === "สลิปเงินจอง" ? (
                                    <div className="bg-contain text-orange p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "สลิปเงินจอง" })
                                        }}>สลิปเงินจอง</div>
                                ) : (
                                    <div className="bg-white text-dark p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "สลิปเงินจอง" })
                                        }}>สลิปเงินจอง</div>
                                )}

                                {this.state.tab === "ประวัติเงินจอง" ? (
                                    <div className="bg-contain text-orange p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "ประวัติเงินจอง" })
                                        }}>ประวัติเงินจอง</div>
                                ) : (
                                    <div className="bg-white text-dark p-3 text-center pointer"
                                        style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid", width: "33.33%" }}
                                        onClick={() => {
                                            this.setState({ tab: "ประวัติเงินจอง" })
                                        }}>ประวัติเงินจอง</div>
                                )}
                            </div>
                        )}
                        {this.state.tab === "สัญญาการจอง" ? (
                            <div className="row">
                                <div className="col-12 col-lg-5">
                                    <div className="d-flex justify-content-between">
                                        <h4><b>ข้อมูลบุคคล</b></h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* รหัสบัตรประชาชน หรือ พาสปอร์ต */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">รหัสบัตรประชาชน หรือ พาสปอร์ต{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ id_card: e.target.value })
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.GetMemberByIDCard()
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        this.GetMemberByIDCard()
                                                    }}
                                                    value={this.state.id_card}
                                                    maxLength={13}
                                                    readOnly={this.state.booking_status > 2}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* ชื่อ- นามสกุล */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">ชื่อ- นามสกุล{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ full_name: e.target.value })
                                                    }}
                                                    value={this.state.full_name}
                                                    readOnly={this.state.booking_status > 2}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* เบอร์โทรศัพท์ */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">เบอร์โทรศัพท์{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ phone: e.target.value })
                                                    }}
                                                    value={this.state.phone}
                                                    readOnly={this.state.booking_status > 2}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* อีเมล */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">อีเมล :</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ email: e.target.value })
                                                    }}
                                                    value={this.state.email}
                                                    readOnly={this.state.booking_status > 2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7">
                                    <div className="d-flex justify-content-between">
                                        <h4><b>ข้อมูลรถ</b></h4>
                                        {this.state.selected_bike && this.state.booking_status <= 4 && (
                                            <button className="btn btn-outline-orange px-3"
                                                onClick={() => {
                                                    this.setState({ modal_car: true, modal_create: false, modal_update: false, type: this.state.modal_create ? "create" : "update", search_bike: "" }); setTimeout(() => {
                                                        this.GetBikeAll();
                                                    }, 10);
                                                }}>
                                                แก้ไข
                                            </button>
                                        )}
                                    </div>
                                    {!this.state.selected_bike ? (
                                        <div className="w-100 card  p-3 pt-1 mb-3">
                                            <div className="d-flex align-items-end position-relative mx-auto" style={{ height: 300, width: 300 }}>
                                                <img src={EMPTY} className="img-responsive position-absolute" style={{ width: 300, zIndex: 999, opacity: 0.08, objectFit: "contain" }} />
                                                <div className="w-100 text-center pb-5" style={{ zIndex: 1000 }}>
                                                    <h4>** ยังไม่ได้เลือกรถ **</h4>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center mb-3">
                                                <button
                                                    className="btn btn-orange wpx-200"
                                                    onClick={() => {
                                                        this.setState({
                                                            modal_car: true,
                                                            modal_create: false,
                                                            modal_update: false,
                                                            type: this.state.modal_create ? "create" : "update",
                                                            pick_up_date: this.state.pick_up_date ? this.state.pick_up_date : new Date(),
                                                            pick_up_time: this.state.pick_up_time ? this.state.pick_up_time : format_date(new Date(), "hh:mm"),
                                                            return_date: this.state.return_date ? this.state.return_date : new Date(new Date().setDate(new Date().getDate() + 1)),
                                                            return_time: this.state.return_time ? this.state.return_time : format_date(new Date(), "hh:mm"),
                                                            search_bike: ""
                                                        });
                                                        setTimeout(() => {
                                                            this.GetBikeAll();
                                                        }, 10);
                                                    }}>เลือกรถ</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3">
                                                <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-responsive w-100 rounded" style={{ height: "100%", objectFit: "contain", maxHeight: 300 }} />
                                            </div>
                                            <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                                <h6><b>รายละเอียดรถ</b></h6>
                                                <div className="row">
                                                    <div className="col-12 d-flex mb-2">
                                                        <b className="wpx-50">ยี่ห้อ</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex mb-2">
                                                        <b className="wpx-50">ชื่อรุ่น</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end mb-2">
                                                        <h4 className="text-orange">{this.state.selected_bike ? this.state.selected_bike.daily_price : "-"}  บาท/วัน</h4>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />
                                                <h6><b>วันที่รับ / คืนรถ</b></h6>
                                                <div className="d-flex justify-content-between">
                                                    <div className="w-100">
                                                        <b className="wpx-40">วันที่รับรถ</b>
                                                        <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time.slice(0, 5) + " น." : "-"}</label><br />
                                                        <b className="my-2">สถานที่รับรถ{required}</b>
                                                        <input
                                                            type="text"
                                                            className="form-control w-100 mb-2"
                                                            placeholder="เช่น สนามบินเชียงใหม่"
                                                            onChange={(e) => { this.setState({ pick_up_location: e.target.value }); }}
                                                            value={this.state.pick_up_location}
                                                            readOnly={this.state.booking_status > 2} />
                                                    </div>
                                                    <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                        <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                    </div>
                                                    <div className="w-100">
                                                        <b className="wpx-40">วันที่คืนรถ</b>
                                                        <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time.slice(0, 5) + " น." : "-"}</label><br />
                                                        <b className="my-2">สถานที่คืนรถ{required}</b>
                                                        <input
                                                            type="text"
                                                            className="form-control w-100 mb-2"
                                                            placeholder="เช่น สนามบินเชียงใหม่"
                                                            onChange={(e) => { this.setState({ return_location: e.target.value }); }}
                                                            value={this.state.return_location}
                                                            readOnly={this.state.booking_status > 2} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>

                                {/* อุปกรณ์รถ */}
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="mb-0"><b>อุปกรณ์รถ</b></h4>
                                    </div>
                                    <hr />
                                    <div className="w-100 py-3 mb-3">
                                        <div className="row mx-0">
                                            {this.state.data_equipment && this.state.data_equipment.map((item, index) => (
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 col-xxl-6 mb-2 px-1">
                                                    <div className="card bg-white w-100 p-2">
                                                        <div className="d-flex row mx-0 align-items-center w-100">
                                                            <div className="d-flex">
                                                                <img src={item.picture} className="wpx-90 hpx-90 rounded" />
                                                                <div>
                                                                    <h5 className="ml-3" style={{ minWidth: 190 }}></h5>
                                                                    <label className="ml-3 text-orange">ราคา {item.equipment_price} บาท</label>
                                                                </div>
                                                            </div>
                                                            <div className="ml-auto card bg-contain border-0 p-1">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="d-flex align-items-center justify-content-center mr-3">
                                                                        <h1 className={item.equipment_qty === 0 ? "text-right text-secondary mb-1" : "text-right text-orange mb-1"} style={{ width: 50 }}><b>{item.equipment_qty}</b></h1>
                                                                        <h6 className={item.equipment_qty === 0 ? "text-secondary mb-0 ml-2" : "text-orange mb-0 ml-2"}>ชิ้น</h6>
                                                                    </div>
                                                                    {this.state.booking_status <= 2 && (
                                                                        <div className="d-flex">
                                                                            <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                onClick={() => {
                                                                                    let data_equipment = this.state.data_equipment;
                                                                                    data_equipment[index].equipment_qty -= data_equipment[index].equipment_qty === 0 ? 0 : 1;

                                                                                    let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                    for (let data of data_equipment) {
                                                                                        total_qty_equipment += data.equipment_qty;
                                                                                        total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                    }
                                                                                    this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                }}>-</button>
                                                                            <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                onClick={() => {
                                                                                    let data_equipment = this.state.data_equipment;
                                                                                    data_equipment[index].equipment_qty += 1;

                                                                                    let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                    for (let data of data_equipment) {
                                                                                        total_qty_equipment += data.equipment_qty;
                                                                                        total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                    }
                                                                                    this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                }}>+</button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {this.state.tab === "สลิปเงินจอง" ? (
                            <div className="row">
                                {/* ยอดเงินจองลูกค้า */}
                                <div className="col-12">
                                    <h4><b>สลิปหลักฐาน</b></h4>
                                    <div className="row">
                                        <input className="d-none" type="file" id="file_sub" accept="image/*" onChange={(e) => { this.import_file(e, "sub") }} multiple />
                                        {this.state.payment_picture.map((item, index) => (
                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                <div
                                                    className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                >
                                                    <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                        <img
                                                            alt="รูปภาพสลิปหลักฐาน"
                                                            src={item.picture.includes("https://") ? item.picture : "data:image/png;base64," + item.picture}
                                                            className="w-100 hpx-140 rounded overflow-hidden"
                                                            style={{ objectFit: "contain" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    modal_picture: true,
                                                                    modal_detail: false,
                                                                    index: index
                                                                })
                                                            }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <hr />
                                    <div className="row">
                                        {/* เงินจอง */}
                                        <div className="col-12 col-md-4 mb-2">
                                            <label className="w-100 mb-2">เงินจอง :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.deposit_pay}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-12 col-md-4 mb-2">
                                            <label className="w-100 mb-2">วันที่ :</label><br />
                                            <DatePickerTH
                                                className="form-control w-100"
                                                value={this.state.payment_date}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-12 col-md-4 mb-2">
                                            <label className="w-100 mb-2">เวลา :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="time"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.payment_time}
                                                readOnly
                                            />
                                        </div>
                                         {/* ธนาคาร */}
                                         <div className="col-12 mb-2">
                                            <label className="w-100 mb-2">เลขบัญชีธนาคาร :</label><br />
                                            <select
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.bank}
                                                disabled
                                            >
                                                <option value=""></option>
                                                {this.state.data_bank.map((item,index)=>(
                                                <option value={item.bank_name+":"+item.account_number}>{item.bank_name + " : " + item.account_number}</option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {this.state.tab === "ประวัติเงินจอง" ? (
                            <table className="table table-borderless table-striped" style={{ marginBottom: 100 }}>
                                <thead>
                                    <tr>
                                        <th>วันที่/เวลา</th>
                                        <th>เงินจอง</th>
                                        <th>รูปภาพ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.payment_list && this.state.payment_list.map((item, index) => (
                                        <tr>
                                            <td>{item.payment_date ? format_date(item.payment_date) + " " + item.payment_time : ""}</td>
                                            <td>{toFixed(item.deposit_pay)}</td>
                                            <td>
                                                {item.payment_picture.map((picture_item, picture_index) => (
                                                    <img
                                                        onClick={() => {
                                                            this.setState({
                                                                modal_picture: true,
                                                                modal_detail: false,
                                                                index: picture_index
                                                            })
                                                        }}
                                                        src={picture_item.picture}
                                                        className="wpx-30 hpx-30 img-fluid mb-3 pointer mx-1"></img>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : null}
                        <div className="col-12">
                            <div className="w-100 p-3 bg-orange text-white mb-3">
                                {this.state.modal_detail && (
                                    <div className="d-flex align-items-center justify-content-end mb-2">
                                        <label className="mb-0 mr-2">เงินจองที่จะต้องชำระ</label>
                                        <input
                                            type="text"
                                            className="form-control wpx-250 text-end"
                                            onChange={(e) => {
                                                if (!float(e.target.value)) {
                                                    return
                                                }
                                                this.setState({ deposit_price: e.target.value });
                                            }}
                                            value={this.state.deposit_price}
                                            readOnly={this.state.booking_status > 2} />
                                        <label className="mb-0 ml-2">บาท</label>
                                    </div>
                                )}
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">ค่าบริการรับส่ง</label>
                                    <input
                                        type="text"
                                        className="form-control wpx-250 text-end"
                                        onChange={(e) => {
                                            if (!float(e.target.value)) {
                                                return
                                            }
                                            this.setState({ delivery_price: e.target.value });
                                        }}
                                        value={this.state.delivery_price}
                                        readOnly={this.state.booking_status > 2} />
                                    <label className="mb-0 ml-2">บาท</label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">ค่าอุปกรณ์</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.total_price_equipment)}</b></h3>
                                    <label className="mb-0 ml-2">บาท</label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">ค่าเช่า</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.total_price)}</b></h3>
                                    <label className="mb-0 ml-2">บาท</label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">ค่าปรับใบสั่ง</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.traffic_ticket_price)}</b></h3>
                                    <label className="mb-0 ml-2">บาท</label>
                                </div>
                                <div className="d-flex w-100 justify-content-end">
                                    <div className="d-flex align-items-center">
                                        <label className="mb-0 mr-2">จำนวนวันที่เช่า</label>
                                        <h3 className="mb-0"><b>{this.state.days}</b></h3>
                                        <label className="mb-0 ml-2 mr-4">วัน</label>
                                        <h3 className="mb-0"><b>{this.state.hours}</b></h3>
                                        <label className="mb-0 ml-2 mr-4">ชั่วโมง</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="mb-0 mr-2">ยอดชำระเงินทั้งหมด</label>
                                        <h3 className="mb-0"><b>{toFixed(Number(this.state.total_price) + Number(this.state.total_price_equipment) + Number(this.state.delivery_price) + Number(this.state.traffic_ticket_price))}</b></h3>
                                        <label className="mb-0 ml-2">บาท</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false, modal_detail: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        if (this.state.modal_create) {
                                            this.Create();
                                        } else {
                                            this.Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" :
                                        this.state.booking_status === 1 ? "ยืนยันเงินจอง" :
                                            this.state.booking_status === 2 ? "ยืนยันเงินจอง" :
                                                this.state.booking_status === 3 ? "อนุมัติ" :
                                                    "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* car */}
                <Modal show={this.state.modal_car} onHide={() => {
                    this.setState({ modal_car: false });
                    if (this.state.type === "create") {
                        this.setState({ modal_create: true })
                    } else {
                        this.setState({ modal_update: true })
                    }
                }} size="lg">
                    <Modal.Body>
                        <div className="w-100 d-flex justify-content-between mb-2">
                            <h3><b>เลือกรถ</b></h3>
                            <label className="icon text-24 text-danger pointer px-3" onClick={() => {
                                this.setState({ modal_car: false });
                                if (this.state.type === "create") {
                                    this.setState({ modal_create: true })
                                } else {
                                    this.setState({ modal_update: true })
                                }
                            }}>{"\uf00d"}</label>
                        </div>
                        {/* <hr className="my-2" /> */}
                        <div className="w-100">
                            {/* ค้นหา */}
                            <div className="row bg-orange mb-3">
                                <div className="col-12 p-3 mx-auto">
                                    <div className="card border-0 px-4 py-2 bg-white shadow">
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">วันที่รับรถ{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ pick_up_date: e });
                                                        if (Number(format_date(e, "yyyymmdd")) >= Number(format_date(this.state.return_date, "yyyymmdd"))) {
                                                            this.setState({ return_date: format_date(new Date(format_date(e, "yyyy,mm,dd")).setDate(new Date(format_date(e, "yyyy,mm,dd")).getDate() + 1), "yyyy-mm-dd", "en") });
                                                        }
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.pick_up_date}
                                                    clearable={false}
                                                    min={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">เวลารับรถ{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => { this.setState({ pick_up_time: e.target.value }); }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.pick_up_time} />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">วันที่คืนรถ{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ return_date: e });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.return_date}
                                                    clearable={false}
                                                    min={new Date(this.state.pick_up_date).setDate(new Date(this.state.pick_up_date).getDate() - 1)}

                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">เวลาคืนรถ{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => { this.setState({ return_time: e.target.value }); }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.return_time} />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 px-1">
                                                <b className="text-white mb-2">.</b>
                                                <button className="btn btn-orange w-100 mb-2" onClick={() => {
                                                    this.GetBikeAll();
                                                    if (this.state.selected_bike) {
                                                        let days = days_between(
                                                            new Date(Number(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en"))),
                                                            new Date(Number(format_date(this.state.return_date, "yyyy,mm,dd", "en"))),
                                                            this.state.pick_up_time,
                                                            this.state.return_time
                                                        )
                                                        let hours_price = 0;
                                                        if (days.day === 0) {
                                                            hours_price = this.state.selected_bike.daily_price;
                                                        } else {
                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                //ไม่ต้องหัก 
                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                if (this.state.selected_bike.daily_price < 400) {
                                                                    hours_price = 50; //หัก 50 บาท
                                                                } else if (this.state.selected_bike.daily_price >= 400 && this.state.selected_bike.daily_price <= 700) {
                                                                    hours_price = 100; //หัก 100 บาท
                                                                } else if (this.state.selected_bike.daily_price > 700) {
                                                                    hours_price = 200; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                if (this.state.selected_bike.daily_price < 400) {
                                                                    hours_price = 100; //หัก 50 บาท
                                                                } else if (this.state.selected_bike.daily_price >= 400 && this.state.selected_bike.daily_price <= 700) {
                                                                    hours_price = 200; //หัก 100 บาท
                                                                } else if (this.state.selected_bike.daily_price > 700) {
                                                                    hours_price = 400; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                if (this.state.selected_bike.daily_price < 400) {
                                                                    hours_price = 150; //หัก 50 บาท
                                                                } else if (this.state.selected_bike.daily_price >= 400 && this.state.selected_bike.daily_price <= 700) {
                                                                    hours_price = 300; //หัก 100 บาท
                                                                } else if (this.state.selected_bike.daily_price > 700) {
                                                                    hours_price = 600; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour >= 4) {
                                                                hours_price = this.state.selected_bike.daily_price;//หัก daily_price
                                                            }
                                                        }

                                                        this.setState({
                                                            modal_car: false,
                                                            days: days.day,
                                                            hours: days.hour,
                                                            total_price: (this.state.selected_bike.daily_price * days.day) + hours_price
                                                        });
                                                        if (this.state.type === "create") {
                                                            this.setState({ modal_create: true })
                                                        } else {
                                                            this.setState({ modal_update: true })
                                                        }
                                                    }
                                                }}>
                                                    ค้นหา
                                                    <label className="icon ml-2">{"\uf002"}</label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* รายการรถ */}
                            <div className="row">
                                <div className="col-12 pb-5 px-4">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <h4><b>ผลการค้นหา: รถว่างทั้งหมด</b></h4>
                                            <label className="text-secondary mb-3">พบรถว่าง {this.state.data_bike.length} คัน</label>
                                        </div>
                                        <div className="wpx-250 pb-2">
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-2"
                                                placeholder="เช่น ยี่ห้อ,รุ่น"
                                                onChange={(e) => { this.setState({ search_bike: e.target.value }); }}
                                                value={this.state.search_bike} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.data_bike && this.state.data_bike.filter(item => JSON.stringify(item).toUpperCase().includes(this.state.search_bike.toUpperCase()) || this.state.search_bike === "").map((item, index) => (
                                            <div className="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 mb-3 px-1">
                                                <div className="card bg-white shadow overflow-hidden border-0 position-relative"
                                                    onClick={() => {
                                                        let days = days_between(
                                                            new Date(Number(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en"))),
                                                            new Date(Number(format_date(this.state.return_date, "yyyy,mm,dd", "en"))),
                                                            this.state.pick_up_time,
                                                            this.state.return_time
                                                        )
                                                        let hours_price = 0;
                                                        if (days.day === 0) {
                                                            hours_price = item.daily_price;
                                                        } else {
                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                //ไม่ต้องหัก 
                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                if (item.daily_price < 400) {
                                                                    hours_price = 50; //หัก 50 บาท
                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                    hours_price = 100; //หัก 100 บาท
                                                                } else if (item.daily_price > 700) {
                                                                    hours_price = 200; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                if (item.daily_price < 400) {
                                                                    hours_price = 100; //หัก 50 บาท
                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                    hours_price = 200; //หัก 100 บาท
                                                                } else if (item.daily_price > 700) {
                                                                    hours_price = 400; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                if (item.daily_price < 400) {
                                                                    hours_price = 150; //หัก 50 บาท
                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                    hours_price = 300; //หัก 100 บาท
                                                                } else if (item.daily_price > 700) {
                                                                    hours_price = 600; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour >= 4) {
                                                                hours_price = item.daily_price;//หัก daily_price
                                                            }
                                                        }

                                                        this.setState({
                                                            modal_car: false,
                                                            selected_bike: item,
                                                            days: days.day,
                                                            hours: days.hour,
                                                            total_price: (item.daily_price * days.day) + hours_price
                                                        });
                                                        if (this.state.type === "create") {
                                                            this.setState({ modal_create: true })
                                                        } else {
                                                            this.setState({ modal_update: true })
                                                        }
                                                    }}
                                                // onMouseOver={() => { this.setState({ product_index: index }) }}
                                                // onMouseLeave={() => { this.setState({ product_index: -1 }) }}
                                                >
                                                    <img className="d-none d-sm-block" src={item.title_picture ? item.title_picture : CAR} style={{ height: 240, objectFit: "cover" }} />
                                                    <img className="d-block d-sm-none" src={item.title_picture ? item.title_picture : CAR} style={{ height: 180, objectFit: "cover" }} />
                                                    <div className="p-2">
                                                        <div style={{ height: 23, overflow: "hidden" }}>
                                                            <h6 className="mb-1 d-block d-sm-none"><b>{item.brand_name}</b> {item.model_name}</h6>
                                                            <h5 className="mb-1 d-none d-sm-block"><b>{item.brand_name}</b> {item.model_name}</h5>
                                                        </div>
                                                        <hr className="my-2" />
                                                        <div className="d-flex row justify-content-between mx-0">
                                                            <div className="p-0 d-flex align-items-end">
                                                                <h4 className="mb-0 text-orange"><b>฿{item.daily_price ? toFixed(item.daily_price) : "0.00"}</b></h4>
                                                                <small className="text-secondary ml-2">ต่อวัน</small>
                                                            </div>
                                                            <div className="p-0 d-flex align-items-end">
                                                                <small className="text-secondary ml-2">คงเหลือ {item.balance_app} คัน</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {index === this.state.product_index && (
                                                        <div
                                                            className="fade-in-product rounded pointer position-absolute d-flex align-items-center justify-content-center"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                left: 0,
                                                                top: 0,
                                                                borderWidth: 4,
                                                                borderStyle: "solid",
                                                                borderColor: "#fd7e14"
                                                            }}>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยกเลิกรายการ</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                ท่านแจ้งยกเลิกรายการจองใช่หรือไม่
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Update() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* picture */}
                <Modal show={this.state.modal_picture} onHide={() => { this.setState({ modal_picture: false, modal_detail: true }) }}>
                    <Modal.Header><Modal.Title><b>รูปสลิป</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === 0) {
                                        index = this.state.payment_picture.length - 1;
                                    } else {
                                        index -= 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {"<"}
                            </div>
                            {this.state.payment_picture && this.state.payment_picture[this.state.index] ? (
                                <img src={this.state.payment_picture[this.state.index].picture} className="w-75 mb-3" style={{ height: 500, objectFit: "contain" }}></img>
                            ) : null}
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === this.state.payment_picture.length - 1) {
                                        index = 0;
                                    } else {
                                        index += 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {">"}
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center overflow-scroll">
                            {this.state.payment_picture.map((item, index) => (
                                <div className={this.state.index === index ? "wpx-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center overflow-hidden" : "wpx-100 hpx-100 d-flex align-items-center justify-content-center overflow-hidden"}>
                                    <img src={item.picture} className="w-100 mb-3 wpx-100 hpx-100 mx-2 pointer" style={{ objectFit: "contain" }} onClick={() => { this.setState({ index: index }) }}></img>
                                </div>
                            ))}
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <button className="btn btn-outline-orange px-5" onClick={() => { this.setState({ modal_picture: false, modal_detail: true }) }}>
                                ปิดหน้าต่าง
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
