import React, { Component } from "react";
import { tokens, GET, locale } from "./CustomComponent.js"
import "../assets/css/bootstrap.css";
import "./global.js";
import TH from "../assets/images/th.svg";
import EN from "../assets/images/en.svg";

export default class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
      modal_logout: false,
      address: "",
      website: "",
      company_id: "",
      company_name: "",
      email: "",
      end_date_booking_mc: "",
      line_id: "",
      phone: "",
      picture: "",
      start_date_booking_mc: "",
      zip_code: "",
    };
  }
  componentDidMount() {
    this.GetAll();
  }
  icon = (icon) => { return (<label className="icon">{icon}</label>) }
  handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  GetAll = async () => {
    let result = await GET(tokens, "v1/TH/company/detail", {});
    if (result && result.status) {
      let data = result.data.data;
      this.setState({
        address: data.address,
        website: data.website,
        company_id: data.company_id,
        company_name: data.company_name,
        email: data.email,
        end_date_booking_mc: data.end_date_booking_mc,
        line_id: data.line_id,
        phone: data.phone,
        picture: data.picture,
        start_date_booking_mc: data.start_date_booking_mc,
        zip_code: data.zip_code,
        province: data.province,
        district: data.district,
        sub_district: data.sub_district
      })
    }
  }
  render() {
    return (
      <div className="row bg-dark text-white">
        <div className="col-12 col-md-1"></div>
        <div className="col-12 col-md-10 py-5 px-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-8 col-xl-6">
              <h6><b>{global[locale]["สนใจอยากเช่ามอเตอร์ไซค์ โทรมาได้เลย บริการคุณถึงที่ 08.30-18.30 น."]}</b></h6>
              <label className="mb-3">{global[locale]["บริการให้เช่ารถมอเตอร์ ในจังหวัดเชียงใหม่ มีบริการรับส่ง"]}</label><br />
              <label className="mb-3">
                {global[locale]["ที่อยู่"]} :<br />
                {this.state.company_name ? this.state.company_name : "S.K.Service Motorcycle For Rent"}{" "}
                {this.state.address ? this.state.address : "14/11-13 ซอย 5 (ถนนแก้วนวรัฐ)"}{" "}
                {this.state.sub_district ? "ต." + this.state.sub_district : "ต.วัดเกต"}{" "}
                {this.state.district ? "อ." + this.state.district : "อ.เมืองเชียงใหม่"}{" "}
                {this.state.province ? "จ." + this.state.province : "จ.เชียงใหม่"}{" "}
                {this.state.zip_code ? this.state.zip_code : "50000"}
              </label><br />
              <label className="mb-3">
                {global[locale]["โทร"]} :<br />
                {this.state.phone ? this.state.phone : "098-7532665, 089-5580007"}
              </label><br />
              <label className="mb-3">
                {global[locale]["อีเมล"]} :<br />
                {this.state.email ? this.state.email : "s.k.service665@gmail.com"}
              </label><br />
              <label className="mb-3">
                {global[locale]["ไลน์"]} :<br />
                {this.state.line_id ? this.state.line_id : "s.k.service"}
              </label>
            </div>
            <div className="col-12 col-md-4 col-lg-4 col-xl-3 text-right">
              <div className="row">
                <div className="col-6 col-md-12">
                  <h6 className="mb-3"><b>{global[locale]["เกี่ยวกับ S.K. Service"]}</b></h6>
                  <label className="mb-2 pointer" onClick={() => { this.handleClickScroll("index") }}>{global[locale]["หน้าแรก"]}</label><br />
                  <label className="mb-2 pointer" onClick={() => { this.handleClickScroll("service") }}>{global[locale]["บริการ"]}</label><br />
                  <label className="mb-2 pointer" onClick={() => { this.handleClickScroll("time") }}>{global[locale]["เวลารับส่ง"]}</label><br />
                  <label className="mb-4 pointer" onClick={() => { this.handleClickScroll("rent") }}>{global[locale]["วิธีการเช่า"]}</label><br />
                </div>
                <div className="col-6 col-md-12">
                  <h6 className="mb-3"><b>{global[locale]["ความช่วยเหลือ"]}</b></h6>
                  <label className="mb-2 pointer" onClick={() => { window.open("https://www.facebook.com/", "_blank") }}><label className="icon mr-2">{"\uf082"}</label>Facebook</label><br />
                  <label className="mb-2 pointer" onClick={() => { window.open("https://line.me/th/", "_blank") }}><label className="icon mr-2">{"\uf3c0"}</label>Line</label><br />
                  <div className="dropdowns">
                    <span className="dropbtns pointer">
                      <img src={locale === "th" ? TH : EN} className="wpx-30 hpx-30 rounded-circle mr-2" style={{ objectFit: "cover" }} />{locale === "th" ? "ภาษาไทย" : "English"}
                    </span>
                    <div className="dropdowns-content wpx-250 bg-white">
                      <a className="pointer text-left" onClick={() => {
                        localStorage.setItem("locale", "th");
                        window.location.reload();
                      }}> <img src={TH} className="wpx-20 hpx-20 rounded-circle mr-2" style={{ objectFit: "cover" }} />ภาษาไทย</a>
                      <a className="pointer text-left" onClick={() => {
                        localStorage.setItem("locale", "en");
                        window.location.reload();
                      }}> <img src={EN} className="wpx-20 hpx-20 rounded-circle mr-2" style={{ objectFit: "cover" }} />English</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-12 col-xl-3 text-right">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3776.963199900328!2d99.0141413759069!3d18.79979328234882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da254bb54b8665%3A0xf8352bad3de49a42!2zUy5LLlNlcnZpY2UgTW90b3JjeWNsZSBGb3IgUmVudCAtIOC4muC4o-C4tOC4geC4suC4o-C5g-C4q-C5ieC5gOC4iuC5iOC4suC4oeC4reC5gOC4leC4reC4o-C5jOC5hOC4i-C4hOC5jOC5g-C4meC5gOC4iuC4teC4ouC4h-C5g-C4q-C4oeC5iA!5e0!3m2!1sth!2sth!4v1698894553219!5m2!1sth!2sth"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}