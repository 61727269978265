import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarCustomer.js";
import {
    tokens,
    success_url,
    alert,
    GET,
    PUT,
    format_date,
    float,
    validate,
    required,
    DatePickerTH,
    locale,
    toFixed,
    validateEmail,
    days_between,
    status_booking
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";
import CAR from "../../assets/images/bike.png"
import EMPTY from "../../assets/images/empty.png";
import SLIP from "../../assets/images/slip.jpg";
import Resizer from "react-image-file-resizer";

export default class ListBooking extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_detail: false,
            modal_car: false,
            modal_promise: false,

            filter_search: "",
            filter_booking_status: "",

            page: 1,
            loading: false,

            data: null,
            data_bank: [],
            data_bike: [],
            data_equipment: [],

            tab: "สัญญาการจอง",
            product_index: -1,
            type: "",

            id: "",
            id_card: "",
            full_name: "",
            email: "",
            phone: "",
            brand_id: "",
            model_id: "",
            pick_up_date: new Date(),
            pick_up_time: format_date(new Date(), "hh:mm"),
            pick_up_location: "",
            return_date: new Date(new Date().setDate(new Date().getDate() + 1)),
            return_time: format_date(new Date(), "hh:mm"),
            return_location: "",
            daily_price: "",
            deposit_pay: "",
            payment_date: "",
            payment_time: "",
            payment_picture: [],
            booking_status: "",
            deposit_price: "",
            booking_price: "",
            traffic_ticket_price: "",
            booking_number: "",

            days: 0,

            search_bike: "",

            selected_bike: null,

            payment_list: [],

            modal_picture: false,
            index: -1,

            close: false,
            end_date_booking_mc: "",
            start_date_booking_mc: "",
            total_price: 0,
            old_daily_price: 0,
            dropdowns: -1,
            edit_date: false,
            bank:""
        }
        this.divRef1 = React.createRef();
        this.divRef2 = React.createRef();
        this.divRef3 = React.createRef();
        this.divRef4 = React.createRef();
        this.close_dropdown = this.close_dropdown.bind(this);
    }
    async componentDidMount() {
        document.addEventListener('mousedown', this.close_dropdown);
        await this.GetAll();
        await this.GetBikeAll();
        await this.GetSettingAll();
        await this.GetEquipmentAll();
        await this.GetBankAll();
    }
    componentWillUnmount() {
        document.addEventListener('mousedown', this.close_dropdown);
    }
    
    close_dropdown = (event) => {

        if ((this.divRef1.current && this.divRef1.current.contains(event.target)) ||
            (this.divRef2.current && this.divRef2.current.contains(event.target)) ||
            (this.divRef3.current && this.divRef3.current.contains(event.target)) ||
            (this.divRef4.current && this.divRef4.current.contains(event.target))) { } else {
            this.setState({ dropdowns: -1 })
        }
    }

    GetBankAll = async () => {
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bank-account/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_bank: result.data.data })
            console.log(result.data.data)
        }
    }
    GetEquipmentAll = async () => {
        let result = await GET(tokens, "v1/TH/equipment/filter-search/page/0", null);
        if (result && result.status) {
            this.setState({ data_equipment: result.data.data.map(item => { return { ...item, equipment_qty: 0, booking_equipment_id: "" } }) })
        }
    }
    GetSettingAll = async () => {
        let result = await GET(tokens, "v1/TH/company/detail", {});
        if (result && result.status) {
            let data = result.data.data;
            if (new Date(data.start_date_booking_mc).getTime() <= new Date().getTime() && new Date().getTime() <= new Date(data.end_date_booking_mc).getTime()) {
                this.setState({
                    close: true,
                    end_date_booking_mc: data.end_date_booking_mc,
                    start_date_booking_mc: data.start_date_booking_mc,
                })
            }
        }
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => {
            this.GetAll()
        }, 20)
    }
    clear_form() {
        this.setState({
            modal_detail: false,
            modal_slip: false,

            page: 1,
            loading: false,

        })
    }

    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            booking_status: this.state.filter_booking_status,
        }
        let result = await GET(tokens, "v1/TH/booking/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    GetBikeAll = async () => {
        let data = {
            start_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", 'en') + " " + this.state.pick_up_time : null,
            end_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", 'en') + " " + this.state.return_time : null,
            search: ""
        }
        let result = await GET(tokens, "v1/TH/bike-manage/filter-search-public/page/0", data);
        if (result && result.status) {
            this.setState({ data_bike: result.data.data })
        }
    }
    import_file = (e, type) => {
        if (e.target.files.length === 1) {
            this.changePhoto(e.target.files[0], type)
            document.getElementById("file_sub").value = "";
            document.getElementById("file_sub").file = null;
        } else {
            this.multiple_file(0, e.target.files.length, e.target.files);
        }
    }
    multiple_file = async (index, max, file) => {
        await this.changePhoto(file[index], "sub");
        if (index + 1 < max) {
            setTimeout(() => {
                this.multiple_file(index + 1, max, file);
            }, 50);
        }
    }
    changePhoto = async (file, type) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => { }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    let payment_picture = this.state.payment_picture;
                    payment_picture.push(
                        {
                            picture_id: "",
                            picture: uri.split(",")[1]
                        }
                    )
                    this.setState({
                        payment_picture: payment_picture
                    })
                },
                "base64"
            );
        });
    }

    Update = async () => {
        let booking_status = this.state.booking_status;
        if (booking_status != 5) {
            if (validate(this.state, ["id_card", "full_name", "phone", "selected_bike", "pick_up_location", "return_location"])) { return };
            if (this.state.email !== "" && !validateEmail(this.state.email)) {
                alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
                return
            }
        }
        if (booking_status === 2 && this.state.tab === "สลิปเงินจอง") {
            booking_status = 3;
            if (this.state.payment_picture.length === 0) {
                alert("warning", "แจ้งเตือน", "กรุณาเลือกรูปภาพ");
                return;
            } else if (this.state.deposit_pay === "") {
                alert("warning", "แจ้งเตือน", "กรุณากรอกเงินจอง");
                return;
            } else if (this.state.payment_date === "") {
                alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่");
                return;
            } else if (this.state.payment_time === "") {
                alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลา");
                return;
            } else if (Number(this.state.deposit_pay) !== Number(this.state.deposit_price)) {
                alert("warning", "แจ้งเตือน", "จำนวนเงินที่ชำระไม่เท่ากับยอดชำระเงินทั้งหมด");
                return;
            }
        }
        this.setState({ loading: true });
        let data = {
            booking_id: this.state.id,
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            email: this.state.email,
            phone: this.state.phone,
            brand_id: this.state.selected_bike.brand_id,
            model_id: this.state.selected_bike.model_id,
            pick_up_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") : null,
            pick_up_time: this.state.pick_up_time,
            pick_up_location: this.state.pick_up_location,
            return_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") : null,
            return_time: this.state.return_time,
            return_location: this.state.return_location,
            daily_price: this.state.selected_bike.daily_price,
            title_picture: this.state.selected_bike ? this.state.selected_bike.title_picture : "",
            deposit_pay: Number(this.state.deposit_pay),
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : null,
            payment_time: this.state.payment_time ? this.state.payment_time : null,
            payment_picture: this.state.payment_picture,
            booking_status: booking_status,
            deposit_price: Number(this.state.deposit_price),
            booking_price: Number(this.state.booking_price),
            traffic_ticket_price: Number(this.state.traffic_ticket_price),
            admin_remark: ((this.state.booking_status === 2 || this.state.booking_status === 3 || this.state.booking_status === 4) && this.state.old_daily_price < this.state.selected_bike.daily_price) || this.state.edit_date ? "รอตรวจสอบ" : "",

            delivery_price: Number(this.state.delivery_price),
            equipment_fee: Number(this.state.total_price_equipment),
            equipment_list: this.state.data_equipment.filter(e => e.equipment_qty > 0),

            bank_name : this.state.bank ? this.state.bank.split(":")[0] : "",
            account_number : this.state.bank ? this.state.bank.split(":")[1] : "",
        }
        let result = await PUT(tokens, "v1/TH/booking/update", data);
        if (result && result.status) {
            success_url("/user/list-booking")
        }
        this.setState({ loading: false });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1={global[locale]["หน้าหลัก"]} page2={global[locale]["การจองรถ"]} />
                {/* BODY */}
                <div className="body background3 min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3 text-white"><b>{global[locale]["การจองรถ"]}</b></h4>
                            {!this.state.close ? (
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/user/choose-car" }}>
                                    {global[locale]["เพิ่มการจอง"]}
                                </button>
                            ) : (<span className="text-danger add">{global[locale]["ไม่สามารถจองได้เนื่องจากระบบ ปิดการจองรถ ตั้งแต่วันที่"]} {format_date(this.state.start_date_booking_mc)} - {format_date(this.state.end_date_booking_mc)}</span>)}

                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* วันที่ */}
                                    <div className="wpx-200 mx-1 px-0">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            onChange={(e) => {
                                                this.setState({ filter_transfer_date: e, page: 1 });
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }}
                                            onBlur={() => {
                                                this.GetAll();
                                            }}
                                            value={this.state.filter_transfer_date}
                                        />
                                    </div>
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder={global[locale]["พิมพ์คำค้นหา..."]}
                                        onChange={(e) => { this.setState({ filter_data_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_data_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>{global[locale]["ค้นหา"]}</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_data_search: "",
                                                filter_transfer_date: "",
                                                filter_status_transfer: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>{global[locale]["รีเซ็ท"]}</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive pb-5" style={{ minHeight: 300 }}>
                                    <table className="table table-borderless table-striped mb-5">
                                        <thead>
                                            <tr>
                                                <th className="text-center">{global[locale]["เลขที่การจอง"]}</th>
                                                <th className="text-center">{global[locale]["วันที่จอง"]}</th>
                                                <th className="text-center">{global[locale]["วันที่คืน"]}</th>
                                                <th className="text-center">{global[locale]["ข้อมูลรถ"]}</th>
                                                <th className="text-center">{global[locale]["เลขบัญชีธนาคาร"]}</th>
                                                <th className="text-center">{global[locale]["สถานะ"]}</th>
                                                <th className="text-center">{global[locale]["จัดการ"]}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={6} className="text-center"> {global[locale]["ไม่มีข้อมูล"]} </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.booking_number ? item.booking_number : "-"}</td>
                                                    <td className="text-center">{item.pick_up_date ? format_date(item.pick_up_date, "dd/mm/yyyy") + " " + item.pick_up_time : "-"}</td>
                                                    <td className="text-center">{item.return_date ? format_date(item.return_date, "dd/mm/yyyy") + " " + item.return_time : "-"}</td>
                                                    <td className="text-center">{item.brand_name ? item.brand_name + " " + item.model_name : "-"}</td>
                                                    <td className="text-center">{item.account_number ? item.bank_name + " : " + item.account_number : "-"}</td>
                                                    <td className="text-center">
                                                        {status_booking(item.booking_status)}
                                                    </td>
                                                    <td className="text-center position-relative">
                                                        <label ref={this.divRef1} className="icon text-18 p-2 pointer" onClick={() => {
                                                            this.setState({ dropdowns: this.state.dropdowns === index ? -1 : index })
                                                        }}>{"\uf141"}</label>
                                                        {this.state.dropdowns === index && (
                                                            <div className="bg-white position-absolute shadow" style={{ top: 40, right: 12, zIndex: 1000 }}>
                                                                <button ref={this.divRef2} className="btn btn-outline-orange border-0 text-dark rounded-0 wpx-200 text-left" onClick={() => {
                                                                    let payment_list = item.payment_list.length !== 0 ? item.payment_list[0] : null;
                                                                    let days = days_between(
                                                                        new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                                                                        new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en"))),
                                                                        item.pick_up_time,
                                                                        item.return_time
                                                                    )
                                                                    let hours_price = 0;
                                                                    if (days.day === 0) {
                                                                        hours_price = item.daily_price;
                                                                    } else {
                                                                        if (days.hour === 0 && days.minute >= 0) {
                                                                            //ไม่ต้องหัก 
                                                                        } else if (days.hour === 1 && days.minute >= 0) {
                                                                            if (item.daily_price < 400) {
                                                                                hours_price = 50; //หัก 50 บาท
                                                                            } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                hours_price = 100; //หัก 100 บาท
                                                                            } else if (item.daily_price > 700) {
                                                                                hours_price = 200; //หัก 200 บาท
                                                                            }
                                                                        } else if (days.hour === 2 && days.minute >= 0) {
                                                                            if (item.daily_price < 400) {
                                                                                hours_price = 100; //หัก 50 บาท
                                                                            } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                hours_price = 200; //หัก 100 บาท
                                                                            } else if (item.daily_price > 700) {
                                                                                hours_price = 400; //หัก 200 บาท
                                                                            }
                                                                        } else if (days.hour === 3 && days.minute >= 0) {
                                                                            if (item.daily_price < 400) {
                                                                                hours_price = 150; //หัก 50 บาท
                                                                            } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                                hours_price = 300; //หัก 100 บาท
                                                                            } else if (item.daily_price > 700) {
                                                                                hours_price = 600; //หัก 200 บาท
                                                                            }
                                                                        } else if (days.hour >= 4) {
                                                                            hours_price = item.daily_price;//หัก daily_price
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        modal_detail: true,
                                                                        id: item.booking_id,
                                                                        id_card: item.id_card,
                                                                        full_name: item.full_name,
                                                                        email: item.email,
                                                                        phone: item.phone,
                                                                        brand_id: item.brand_id,
                                                                        model_id: item.model_id,
                                                                        pick_up_date: item.pick_up_date,
                                                                        pick_up_time: item.pick_up_time,
                                                                        pick_up_location: item.pick_up_location,
                                                                        return_date: item.return_date,
                                                                        return_time: item.return_time,
                                                                        return_location: item.return_location,
                                                                        daily_price: item.daily_price,
                                                                        deposit_pay: item.deposit_pay,
                                                                        payment_date: payment_list ? payment_list.payment_date : null,
                                                                        payment_time: payment_list ? payment_list.payment_time : "",
                                                                        payment_picture: payment_list ? payment_list.payment_picture : [],
                                                                        booking_status: item.booking_status,
                                                                        deposit_price: item.deposit_price ? item.deposit_price : (item.daily_price * days.day) + hours_price + item.delivery_price + item.equipment_fee,
                                                                        booking_price: (item.daily_price * days.day) + hours_price,
                                                                        traffic_ticket_price: item.traffic_ticket_price,

                                                                        days: days.day,
                                                                        hours: days.hour,
                                                                        total_price: (item.daily_price * days.day) + hours_price,

                                                                        search_bike: "",

                                                                        selected_bike: {
                                                                            brand_id: item.brand_id,
                                                                            brand_name: item.brand_name,
                                                                            model_id: item.model_id,
                                                                            model_name: item.model_name,
                                                                            title_picture: item.title_picture,
                                                                            daily_price: item.daily_price
                                                                        },
                                                                        tab: "สัญญาการจอง",
                                                                        payment_list: item.payment_list,

                                                                        delivery_price: item.delivery_price,
                                                                        total_price_equipment: item.equipment_fee,
                                                                        old_daily_price: item.daily_price,
                                                                        edit_date: false,

                                                                        bank: item.account_number ? item.bank_name + ":" + item.account_number : ""
                                                                    })
                                                                    if (item.equipment_list) {
                                                                        let data_equipment = this.state.data_equipment.map(e => { return { ...e, booking_equipment_id: "", equipment_qty: 0 } });
                                                                        for (let eq of item.equipment_list) {
                                                                            let index = data_equipment.findIndex(e => e.equipment_name === eq.equipment_name);
                                                                            if (index !== -1) {
                                                                                data_equipment[index].booking_equipment_id = eq.booking_equipment_id;
                                                                                data_equipment[index].equipment_qty = eq.equipment_qty;
                                                                            }
                                                                        }
                                                                        this.setState({ data_equipment: data_equipment });
                                                                    }
                                                                }}>
                                                                    <label className="icon mx-2">{"\uf543"}</label>
                                                                    {global[locale]["รายละเอียด"]}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 text-white mt-3">
                                <div><label> {global[locale]["แสดง"]} {this.state.data.end_index} {global[locale]["รายการ"]} {global[locale]["จาก"]} {this.state.data.count} {global[locale]["รายการ"]} </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}
                {/* create or update */}
                <Modal
                    show={this.state.modal_update || this.state.modal_detail}
                    onHide={() => { this.setState({ modal_update: false, modal_detail: false }) }}
                    size="lg"
                >
                    <Modal.Header> <Modal.Title>
                        <b>{this.state.modal_update ? "แก้ไขการจองรถ" : "รายละเอียดการจองรถ"}</b>
                        {status_booking(this.state.booking_status)}
                    </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3 border-bottom">
                            {this.state.tab === "สัญญาการจอง" ? (
                                <div className="bg-contain text-orange p-3 text-center pointer"
                                    style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid", width: "33.33%" }}
                                    onClick={() => {
                                        this.setState({ tab: "สัญญาการจอง" })
                                    }}>{global[locale]["สัญญาการจอง"]}</div>
                            ) : (
                                <div className="bg-white text-dark p-3 text-center pointer"
                                    style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid", width: "33.33%" }}
                                    onClick={() => {
                                        this.setState({ tab: "สัญญาการจอง" })
                                    }}>{global[locale]["สัญญาการจอง"]}</div>
                            )}

                            {this.state.tab === "สลิปเงินจอง" ? (
                                <div className="bg-contain text-orange p-3 text-center pointer"
                                    style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid", width: "33.33%" }}
                                    onClick={() => {
                                        if (!this.state.edit_date) {
                                            this.setState({ tab: "สลิปเงินจอง" })
                                        }
                                    }}>{global[locale]["สลิปเงินจอง"]}</div>
                            ) : (
                                <div className={this.state.edit_date ? "bg-white text-secondary p-3 text-center pointer" : "bg-white text-dark p-3 text-center pointer"}
                                    style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid", width: "33.33%" }}
                                    onClick={() => {

                                        if (!this.state.edit_date) {
                                            this.setState({ tab: "สลิปเงินจอง" })
                                        }
                                    }}>{global[locale]["สลิปเงินจอง"]}</div>
                            )}

                            {this.state.tab === "ประวัติเงินจอง" ? (
                                <div className="bg-contain text-orange p-3 text-center pointer"
                                    style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid", width: "33.33%" }}
                                    onClick={() => {
                                        this.setState({ tab: "ประวัติเงินจอง" })
                                    }}>{global[locale]["ประวัติเงินจอง"]}</div>
                            ) : (
                                <div className="bg-white text-dark p-3 text-center pointer"
                                    style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid", width: "33.33%" }}
                                    onClick={() => {
                                        this.setState({ tab: "ประวัติเงินจอง" })
                                    }}>{global[locale]["ประวัติเงินจอง"]}</div>
                            )}
                        </div>
                        {this.state.tab === "สัญญาการจอง" ? (
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <div className="d-flex justify-content-between">
                                        <h4><b>{global[locale]["ข้อมูลบุคคล"]}</b></h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* รหัสบัตรประชาชน หรือ พาสปอร์ต */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">{global[locale]["รหัสบัตรประชาชน หรือ พาสปอร์ต"]}{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ id_card: e.target.value })
                                                    }}
                                                    value={this.state.id_card}
                                                    maxLength={13}
                                                    readOnly={this.state.booking_status > 4}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* ชื่อ- นามสกุล */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">{global[locale]["ชื่อ - นามสกุล"]}{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ full_name: e.target.value })
                                                    }}
                                                    value={this.state.full_name}
                                                    readOnly={this.state.booking_status > 4}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* เบอร์โทรศัพท์ */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">{global[locale]["เบอร์โทรศัพท์"]}{required}:</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ phone: e.target.value })
                                                    }}
                                                    value={this.state.phone}
                                                    readOnly={this.state.booking_status > 4}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                            {/* อีเมล */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100">{global[locale]["อีเมล"]} :</label>
                                                <input
                                                    className="form-control validate"
                                                    type="text"
                                                    placeholder="กรอกข้อมูล"
                                                    onChange={(e) => {
                                                        this.setState({ email: e.target.value })
                                                    }}
                                                    value={this.state.email}
                                                    readOnly={this.state.booking_status > 4}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="d-flex justify-content-between">
                                        <h4><b>{global[locale]["ข้อมูลรถ"]}</b></h4>
                                        {this.state.selected_bike && this.state.booking_status <= 4 && (
                                            <button className="btn btn-outline-orange px-3" onClick={() => { this.setState({ modal_car: true, modal_create: false, modal_update: false, type: this.state.modal_create ? "create" : "update", search_bike: "" }) }}>
                                                {global[locale]["แก้ไข"]}
                                            </button>
                                        )}
                                    </div>
                                    {!this.state.selected_bike ? (
                                        <div className="w-100 card  p-3 pt-1 mb-3">
                                            <div className="d-flex align-items-end position-relative mx-auto" style={{ height: 300, width: 300 }}>
                                                <img src={EMPTY} className="img-responsive position-absolute" style={{ width: 300, zIndex: 999, opacity: 0.08, objectFit: "contain" }} />
                                                <div className="w-100 text-center pb-5" style={{ zIndex: 1000 }}>
                                                    <h4>** {global[locale]["ยังไม่ได้เลือกรถ"]} **</h4>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center mb-3">
                                                <button
                                                    className="btn btn-orange wpx-200"
                                                    onClick={() => {
                                                        this.setState({
                                                            modal_car: true,
                                                            modal_create: false,
                                                            modal_update: false,
                                                            type: this.state.modal_create ? "create" : "update",
                                                            pick_up_date: this.state.pick_up_date ? this.state.pick_up_date : new Date(),
                                                            pick_up_time: this.state.pick_up_time ? this.state.pick_up_time : format_date(new Date(), "hh:mm"),
                                                            return_date: this.state.return_date ? this.state.return_date : new Date(new Date().setDate(new Date().getDate() + 1)),
                                                            return_time: this.state.return_time ? this.state.return_time : format_date(new Date(), "hh:mm"),
                                                            search_bike: ""
                                                        })
                                                    }}>{global[locale]["เลือกรถ"]}</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3">
                                                <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-responsive w-100 rounded" style={{ height: "100%", objectFit: "contain" }} />
                                            </div>
                                            <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                                <h6><b>{global[locale]["รายละเอียดรถ"]}</b></h6>
                                                <div className="row">
                                                    <div className="col-12 d-flex mb-2">
                                                        <b className="wpx-50">{global[locale]["ยี่ห้อ"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex mb-2">
                                                        <b className="wpx-50">{global[locale]["ชื่อรุ่น"]}</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end mb-2">
                                                        <h4 className="text-orange">{this.state.selected_bike ? this.state.selected_bike.daily_price : "-"}  บาท/วัน</h4>
                                                    </div>
                                                </div>
                                                <hr className="my-1" />
                                                <div className="d-flex justify-content-between">
                                                    <div className="w-100">
                                                        <b className="wpx-40">{global[locale]["วันที่รับรถ"]}</b>
                                                        <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                        <b className="my-2">{global[locale]["สถานที่รับรถ"]}{required}</b>
                                                        <input
                                                            type="text"
                                                            className="form-control w-100 mb-2"
                                                            placeholder="เช่น สนามบินเชียงใหม่"
                                                            onChange={(e) => { this.setState({ pick_up_location: e.target.value }); }}
                                                            value={this.state.pick_up_location}
                                                            readOnly={this.state.booking_status > 4} />
                                                    </div>
                                                    <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                        <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                    </div>
                                                    <div className="w-100">
                                                        <b className="wpx-40">{global[locale]["วันที่คืนรถ"]}</b>
                                                        <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                        <b className="my-2">{global[locale]["สถานที่คืนรถ"]}{required}</b>
                                                        <input
                                                            type="text"
                                                            className="form-control w-100 mb-2"
                                                            placeholder="เช่น สนามบินเชียงใหม่"
                                                            onChange={(e) => { this.setState({ return_location: e.target.value }); }}
                                                            value={this.state.return_location}
                                                            readOnly={this.state.booking_status > 4} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/* อุปกรณ์รถ */}
                                {this.state.booking_status == 1 && (
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="mb-0"><b>{global[locale]["อุปกรณ์รถ"]}</b></h4>
                                        </div>
                                        <hr />
                                        <div className="w-100 py-3 mb-3">
                                            <div className="row mx-0">
                                                {this.state.data_equipment && this.state.data_equipment.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 col-xxl-6 mb-2 px-1">
                                                        <div className="card bg-white w-100 p-2">
                                                            <div className="d-flex row mx-0 align-items-center w-100">
                                                                <div className="d-flex">
                                                                    <img src={item.picture} className="wpx-90 hpx-90 rounded" />
                                                                    <div>
                                                                        <h5 className="ml-3" style={{ minWidth: 190 }}>{item.equipment_name}</h5>
                                                                        <label className="ml-3 text-orange">ราคา {item.equipment_price} {global[locale]["บาท"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-auto card bg-contain border-0 p-1">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="d-flex align-items-center justify-content-center mr-3">
                                                                            <h1 className={item.equipment_qty === 0 ? "text-right text-secondary mb-1" : "text-right text-orange mb-1"} style={{ width: 50 }}><b>{item.equipment_qty}</b></h1>
                                                                            <h6 className={item.equipment_qty === 0 ? "text-secondary mb-0 ml-2" : "text-orange mb-0 ml-2"}>{global[locale]["ชิ้น"]}</h6>
                                                                        </div>
                                                                        {this.state.booking_status <= 4 && (
                                                                            <div className="d-flex">
                                                                                <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                    onClick={() => {
                                                                                        let data_equipment = this.state.data_equipment;
                                                                                        data_equipment[index].equipment_qty -= data_equipment[index].equipment_qty === 0 ? 0 : 1;

                                                                                        let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                        for (let data of data_equipment) {
                                                                                            total_qty_equipment += data.equipment_qty;
                                                                                            total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                        }
                                                                                        this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                    }}>-</button>
                                                                                <button className="btn btn-orange hpx-30 mr-1 text-24 w-100"
                                                                                    onClick={() => {
                                                                                        let data_equipment = this.state.data_equipment;
                                                                                        data_equipment[index].equipment_qty += 1;

                                                                                        let total_price_equipment = 0, total_qty_equipment = 0;
                                                                                        for (let data of data_equipment) {
                                                                                            total_qty_equipment += data.equipment_qty;
                                                                                            total_price_equipment += data.equipment_price * data.equipment_qty;
                                                                                        }
                                                                                        this.setState({ data_equipment: data_equipment, total_qty_equipment: total_qty_equipment, total_price_equipment: total_price_equipment, equipment_fee: total_price_equipment })
                                                                                    }}>+</button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                        {this.state.tab === "สลิปเงินจอง" ? (
                            <div className="row">
                                {/* ยอดเงินจองลูกค้า */}
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 mt-3">
                                            <div className="w-100 p-3 bg-orange d-flex align-items-center justify-content-end text-white mb-3">
                                                <label className="mb-0 mr-2">{global[locale]["เงินจองที่จะต้องชำระ"]}</label>
                                                <h3 className="mb-0"><b>{toFixed(this.state.deposit_price)}</b></h3>
                                                <label className="mb-0 ml-2">บาท</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-3">
                                    <img alt="slip" src={SLIP} width={400} height={400}/>
                                    </div>
                                    <h4><b>{global[locale]["สลิปหลักฐาน"]}</b></h4>
                                    <div className="row">
                                        <input className="d-none" type="file" id="file_sub" accept="image/*" onChange={(e) => { this.import_file(e, "sub") }} multiple />
                                        {this.state.payment_picture.map((item, index) => (
                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                <div
                                                    className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                >
                                                    <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                        <img
                                                            alt="รูปภาพสลิปหลักฐาน"
                                                            src={item.picture.includes("https://") ? item.picture : "data:image/png;base64," + item.picture}
                                                            className="w-100 hpx-140 rounded overflow-hidden"
                                                            style={{ objectFit: "contain" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    modal_picture: true,
                                                                    modal_detail: false,
                                                                    index: index
                                                                })
                                                            }}
                                                        ></img>
                                                        {this.state.booking_status <= 4 && (
                                                            <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => {
                                                                let payment_picture = this.state.payment_picture;
                                                                payment_picture.splice(index, 1);
                                                                this.setState({ payment_picture: payment_picture })
                                                            }}>
                                                                {"\uf00d"}
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {this.state.payment_picture.length === 0 && this.state.booking_status <= 4 ? (
                                            <div
                                                className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                onClick={() => { document.getElementById("file_sub").click() }}
                                            >
                                                <label className="icon text-orange">{"\uf03e"}</label>
                                                <label className="text-orange text-12">{global[locale]["รูปภาพสลิปหลักฐาน"]}</label>
                                            </div>
                                        ) : this.state.booking_status <= 4 ? (
                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                <div
                                                    className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                    onClick={() => { document.getElementById("file_sub").click() }}
                                                >
                                                    <label className="icon text-orange">{"\uf03e"}</label>
                                                    <label className="text-orange text-12">{global[locale]["รูปภาพสลิปหลักฐาน"]}</label>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <hr />
                                    <div className="row">
                                        {/* เงินจอง */}
                                        <div className="col-12 col-md-4 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["เงินจอง"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    if (!float(e.target.value)) {
                                                        return
                                                    }
                                                    if (Number(e.target.value) > Number(this.state.deposit_price)) {
                                                        this.setState({ deposit_pay: this.state.deposit_price })
                                                    } else {
                                                        this.setState({ deposit_pay: e.target.value })
                                                    }
                                                }}
                                                value={this.state.deposit_pay}
                                                readOnly={this.state.booking_status > 4}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["วันที่"]} :</label><br />
                                            <DatePickerTH
                                                className="form-control w-100"
                                                value={this.state.payment_date}
                                                onChange={(e) => {
                                                    this.setState({ payment_date: e })
                                                }}
                                                disabled={this.state.booking_status > 4}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4 mb-2">
                                            <label className="w-100 mb-2">{global[locale]["เวลา"]} :</label><br />
                                            <input
                                                className="form-control validate"
                                                type="time"
                                                placeholder="กรอกข้อมูล"
                                                onChange={(e) => {
                                                    this.setState({ payment_time: e.target.value })
                                                }}
                                                value={this.state.payment_time}
                                                readOnly={this.state.booking_status > 4}
                                            />
                                        </div>
                                        <div className="col-12 mb-2">
                                        <label className="w-100 mb-2">{global[locale]["เลขบัญชีธนาคาร"]} :</label><br />
                                            <select
                                                className="form-control validate"
                                                type="text"
                                                placeholder="กรอกข้อมูล"
                                                value={this.state.bank}
                                                disabled={this.state.booking_status > 4}
                                            >
                                                <option value=""></option>
                                                {this.state.data_bank.map((item,index)=>(
                                                <option value={item.bank_name+":"+item.account_number}>{item.bank_name + " : " + item.account_number}</option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {this.state.tab === "ประวัติเงินจอง" ? (
                            <table className="table table-borderless table-striped" style={{ marginBottom: 100 }}>
                                <thead>
                                    <tr>
                                        <th>{global[locale]["วันที่/เวลา"]}</th>
                                        <th>{global[locale]["เงินจอง"]}</th>
                                        <th>{global[locale]["รูปภาพ"]}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.payment_list && this.state.payment_list.map((item, index) => (
                                        <tr>
                                            <td>{item.payment_date ? format_date(item.payment_date) + " " + item.payment_time : ""}</td>
                                            <td>{toFixed(item.deposit_pay)}</td>
                                            <td>
                                                {item.payment_picture.map((picture_item, picture_index) => (
                                                    <img
                                                        onClick={() => {
                                                            this.setState({
                                                                modal_picture: true,
                                                                modal_detail: false,
                                                                index: picture_index
                                                            })
                                                        }}
                                                        src={picture_item.picture}
                                                        className="wpx-30 hpx-30 img-fluid mb-3 pointer mx-1"></img>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : null}
                        <div className="col-12">
                            <div className="w-100 p-3 bg-orange text-white mb-3">
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">{global[locale]["ค่าบริการรับส่ง"]}</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.delivery_price)}</b></h3>
                                    <label className="mb-0 ml-2">{global[locale]["บาท"]}</label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">{global[locale]["ค่าอุปกรณ์"]}</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.total_price_equipment)}</b></h3>
                                    <label className="mb-0 ml-2">{global[locale]["บาท"]}</label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">{global[locale]["ค่าเช่า"]}</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.total_price)}</b></h3>
                                    <label className="mb-0 ml-2">{global[locale]["บาท"]}</label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mb-2">
                                    <label className="mb-0 mr-2">{global[locale]["ค่าปรับใบสั่ง"]}</label>
                                    <h3 className="mb-0"><b>{toFixed(this.state.traffic_ticket_price)}</b></h3>
                                    <label className="mb-0 ml-2">{global[locale]["บาท"]}</label>
                                </div>
                                <div className="d-flex w-100 justify-content-end">
                                    <div className="d-flex align-items-center">
                                        <label className="mb-0 mr-2">{global[locale]["จำนวนวันที่เช่า"]}</label>
                                        <h3 className="mb-0"><b>{this.state.days}</b></h3>
                                        <label className="mb-0 ml-2 mr-4">{global[locale]["วัน"]}</label>
                                        <h3 className="mb-0"><b>{this.state.hours}</b></h3>
                                        <label className="mb-0 ml-2 mr-4">{global[locale]["ชั่วโมง"]}</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="mb-0 mr-2">{global[locale]["ยอดชำระเงินทั้งหมด"]}</label>
                                        <h3 className="mb-0"><b>{toFixed(Number(this.state.total_price) + Number(this.state.total_price_equipment) + Number(this.state.delivery_price) + Number(this.state.traffic_ticket_price))}</b></h3>
                                        <label className="mb-0 ml-2">{global[locale]["บาท"]}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ปุ่ม */}

                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false, modal_detail: false })
                                    }}
                                >
                                    {global[locale]["ยกเลิก"]}
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.Update();
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" : this.state.tab === "สลิปเงินจอง" ? "ยืนยันเงินจอง" : "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* car */}
                <Modal show={this.state.modal_car} onHide={() => {
                    this.setState({ modal_car: false });
                    if (this.state.type === "create") {
                        this.setState({ modal_create: true })
                    } else {
                        this.setState({ modal_update: true })
                    }
                }} size="lg">
                    <Modal.Body>
                        <div className="w-100 d-flex justify-content-between mb-2">
                            <h3><b>{global[locale]["เลือกรถ"]}</b></h3>
                            <label className="icon text-24 text-danger pointer px-3" onClick={() => {
                                this.setState({ modal_car: false });
                                if (this.state.type === "create") {
                                    this.setState({ modal_create: true })
                                } else {
                                    this.setState({ modal_update: true })
                                }
                            }}>{"\uf00d"}</label>
                        </div>
                        {/* <hr className="my-2" /> */}
                        <div className="w-100">
                            {/* ค้นหา */}
                            <div className="row bg-orange mb-3">
                                <div className="col-12 p-3 mx-auto">
                                    <div className="card border-0 px-4 py-2 bg-white shadow">
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">{global[locale]["วันที่รับรถ"]}{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ pick_up_date: e, edit_date: true });
                                                        if (Number(format_date(e, "yyyymmdd")) >= Number(format_date(this.state.return_date, "yyyymmdd"))) {
                                                            this.setState({ return_date: format_date(new Date(format_date(e, "yyyy,mm,dd")).setDate(new Date(format_date(e, "yyyy,mm,dd")).getDate() + 1), "yyyy-mm-dd", "en") });
                                                        }
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.pick_up_date}
                                                    clearable={false}
                                                    min={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">{global[locale]["เวลารับรถ"]}{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => { this.setState({ pick_up_time: e.target.value, edit_date: true }); }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.pick_up_time} />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                                                <b className="text-orange mb-2">{global[locale]["วันที่คืนรถ"]}{required}</b>
                                                <DatePickerTH
                                                    className="form-control w-100"
                                                    onChange={(e) => {
                                                        this.setState({ return_date: e, edit_date: true });
                                                        setTimeout(() => {
                                                            this.GetBikeAll()
                                                        }, 10);
                                                    }}
                                                    value={this.state.return_date}
                                                    clearable={false}
                                                    min={new Date(this.state.pick_up_date).setDate(new Date(this.state.pick_up_date).getDate() - 1)}

                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                                                <b className="text-orange mb-2">{global[locale]["เวลาคืนรถ"]}{required}</b>
                                                <input
                                                    type="time"
                                                    className="form-control w-100 mb-2"
                                                    onChange={(e) => { this.setState({ return_time: e.target.value, edit_date: true }); }}
                                                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                                                    value={this.state.return_time} />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 px-1">
                                                <b className="text-white mb-2">.</b>
                                                <button className="btn btn-orange w-100 mb-2" onClick={() => {
                                                    // if (this.state.pick_up_location === "") {
                                                    //     alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่รับรถ");
                                                    //     return;
                                                    // } else if (this.state.return_location === "") {
                                                    //     alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่คืนรถ");
                                                    //     return;
                                                    // }
                                                    this.GetBikeAll();
                                                    if (this.state.selected_bike) {
                                                        let days = days_between(
                                                            new Date(Number(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en"))),
                                                            new Date(Number(format_date(this.state.return_date, "yyyy,mm,dd", "en"))),
                                                            this.state.pick_up_time,
                                                            this.state.return_time
                                                        )
                                                        let hours_price = 0;
                                                        if (days.day === 0) {
                                                            hours_price = this.state.selected_bike.daily_price;
                                                        } else {
                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                //ไม่ต้องหัก 
                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                if (this.state.selected_bike.daily_price < 400) {
                                                                    hours_price = 50; //หัก 50 บาท
                                                                } else if (this.state.selected_bike.daily_price >= 400 && this.state.selected_bike.daily_price <= 700) {
                                                                    hours_price = 100; //หัก 100 บาท
                                                                } else if (this.state.selected_bike.daily_price > 700) {
                                                                    hours_price = 200; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                if (this.state.selected_bike.daily_price < 400) {
                                                                    hours_price = 100; //หัก 50 บาท
                                                                } else if (this.state.selected_bike.daily_price >= 400 && this.state.selected_bike.daily_price <= 700) {
                                                                    hours_price = 200; //หัก 100 บาท
                                                                } else if (this.state.selected_bike.daily_price > 700) {
                                                                    hours_price = 400; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                if (this.state.selected_bike.daily_price < 400) {
                                                                    hours_price = 150; //หัก 50 บาท
                                                                } else if (this.state.selected_bike.daily_price >= 400 && this.state.selected_bike.daily_price <= 700) {
                                                                    hours_price = 300; //หัก 100 บาท
                                                                } else if (this.state.selected_bike.daily_price > 700) {
                                                                    hours_price = 600; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour >= 4) {
                                                                hours_price = this.state.selected_bike.daily_price;//หัก daily_price
                                                            }
                                                        }

                                                        this.setState({
                                                            modal_car: false,
                                                            days: days.day,
                                                            hours: days.hour,
                                                            total_price: (this.state.selected_bike.daily_price * days.day) + hours_price
                                                        });
                                                        if (this.state.type === "create") {
                                                            this.setState({ modal_create: true })
                                                        } else {
                                                            this.setState({ modal_update: true })
                                                        }
                                                    }
                                                }}>
                                                    {global[locale]["ค้นหา"]}
                                                    <label className="icon ml-2">{"\uf002"}</label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* รายการรถ */}
                            <div className="row">
                                <div className="col-12 pb-5 px-4">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <h4><b>{global[locale]["ผลการค้นหา: รถว่างทั้งหมด"]}</b></h4>
                                            <label className="text-secondary mb-3">{global[locale]["พบรถว่าง"]} {this.state.data_bike.length} {global[locale]["คัน"]}</label>
                                        </div>
                                        <div className="wpx-250 pb-2">
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-2"
                                                placeholder="เช่น ยี่ห้อ,รุ่น"
                                                onChange={(e) => { this.setState({ search_bike: e.target.value }); }}
                                                value={this.state.search_bike} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.data_bike && this.state.data_bike.filter(item => JSON.stringify(item).toUpperCase().includes(this.state.search_bike.toUpperCase()) || this.state.search_bike === "").map((item, index) => (
                                            <div className="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 mb-3 px-1">
                                                <div className="card bg-white shadow overflow-hidden border-0 position-relative"
                                                    onClick={() => {
                                                        let days = days_between(
                                                            new Date(Number(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en"))),
                                                            new Date(Number(format_date(this.state.return_date, "yyyy,mm,dd", "en"))),
                                                            this.state.pick_up_time,
                                                            this.state.return_time
                                                        )
                                                        let hours_price = 0;
                                                        if (days.day === 0) {
                                                            hours_price = item.daily_price;
                                                        } else {
                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                //ไม่ต้องหัก 
                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                if (item.daily_price < 400) {
                                                                    hours_price = 50; //หัก 50 บาท
                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                    hours_price = 100; //หัก 100 บาท
                                                                } else if (item.daily_price > 700) {
                                                                    hours_price = 200; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                if (item.daily_price < 400) {
                                                                    hours_price = 100; //หัก 50 บาท
                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                    hours_price = 200; //หัก 100 บาท
                                                                } else if (item.daily_price > 700) {
                                                                    hours_price = 400; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                if (item.daily_price < 400) {
                                                                    hours_price = 150; //หัก 50 บาท
                                                                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                                                                    hours_price = 300; //หัก 100 บาท
                                                                } else if (item.daily_price > 700) {
                                                                    hours_price = 600; //หัก 200 บาท
                                                                }
                                                            } else if (days.hour >= 4) {
                                                                hours_price = item.daily_price;//หัก daily_price
                                                            }
                                                        }

                                                        this.setState({
                                                            modal_car: false,
                                                            selected_bike: item,
                                                            days: days.day,
                                                            hours: days.hour,
                                                            total_price: (item.daily_price * days.day) + hours_price,
                                                            edit_date: true
                                                        });
                                                        if (this.state.type === "create") {
                                                            this.setState({ modal_create: true })
                                                        } else {
                                                            this.setState({ modal_update: true })
                                                        }
                                                    }}
                                                // onMouseOver={() => { this.setState({ product_index: index }) }}
                                                // onMouseLeave={() => { this.setState({ product_index: -1 }) }}
                                                >
                                                    <img className="d-none d-sm-block" src={item.title_picture ? item.title_picture : CAR} style={{ height: 240, objectFit: "cover" }} />
                                                    <img className="d-block d-sm-none" src={item.title_picture ? item.title_picture : CAR} style={{ height: 180, objectFit: "cover" }} />
                                                    <div className="p-2">
                                                        <div style={{ height: 23, overflow: "hidden" }}>
                                                            <h6 className="mb-1 d-block d-sm-none"><b>{item.brand_name}</b> {item.model_name}</h6>
                                                            <h5 className="mb-1 d-none d-sm-block"><b>{item.brand_name}</b> {item.model_name}</h5>
                                                        </div>
                                                        <hr className="my-2" />
                                                        <div className="d-flex row justify-content-between mx-0">
                                                            <div className="p-0 d-flex align-items-end">
                                                                <h4 className="mb-0 text-orange"><b>฿{toFixed(item.daily_price)}</b></h4>
                                                                <small className="text-secondary ml-2">{global[locale]["ต่อวัน"]}</small>
                                                            </div>
                                                            <div className="p-0 d-flex align-items-end">
                                                                <small className="text-secondary ml-2">{global[locale]["คงเหลือ"]} {item.balance_app} {global[locale]["คัน"]}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {index === this.state.product_index && (
                                                        <div
                                                            className="fade-in-product rounded pointer position-absolute d-flex align-items-center justify-content-center"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                left: 0,
                                                                top: 0,
                                                                borderWidth: 4,
                                                                borderStyle: "solid",
                                                                borderColor: "#fd7e14"
                                                            }}>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>{global[locale]["ยกเลิกรายการ"]}</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                {global[locale]["ท่านแจ้งยกเลิกรายการจองใช่หรือไม่"]}
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-danger w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    {global[locale]["ยกเลิก"]}
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Update() }}>
                                    {global[locale]["ยืนยัน"]}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                {/* picture */}
                <Modal show={this.state.modal_picture} onHide={() => { this.setState({ modal_picture: false, modal_detail: true }) }} size={"xl"}>
                    <Modal.Header><Modal.Title><b>{global[locale]["สภาพรถ"]}</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === 0) {
                                        index = this.state.payment_picture.length - 1;
                                    } else {
                                        index -= 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {"<"}
                            </div>
                            {this.state.payment_picture && this.state.payment_picture[this.state.index] ? (
                                <img src={this.state.payment_picture[this.state.index].picture} className="w-75 mb-3" style={{ height: 500, objectFit: "contain" }}></img>
                            ) : null}
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === this.state.payment_picture.length - 1) {
                                        index = 0;
                                    } else {
                                        index += 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {">"}
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center overflow-scroll">
                            {this.state.payment_picture.map((item, index) => (
                                <div className={this.state.index === index ? "wpx-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center overflow-hidden" : "wpx-100 hpx-100 d-flex align-items-center justify-content-center overflow-hidden"}>
                                    <img src={item.picture} className="w-100 mb-3 wpx-100 hpx-100 mx-2 pointer" style={{ objectFit: "contain" }} onClick={() => { this.setState({ index: index }) }}></img>
                                </div>
                            ))}
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <button className="btn btn-outline-orange px-5" onClick={() => { this.setState({ modal_picture: false, modal_detail: true }) }}>
                                {global[locale]["ปิดหน้าต่าง"]}
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
