/* eslint-disable jsx-a11y/anchor-is-valid */
import CAR from "../assets/images/bike.png"
import React, { Component } from "react"
import Footer from "../components/Footer"
import {
  tokens,
  toFixed,
  GET,
  format_date,
  required,
  DatePickerTH,
  removeStorage,
  locale
} from "../components/CustomComponent.js"
import "../assets/css/bootstrap.css"
export default class CarList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      loading: false,

      modal_login: false,
      modal_alert: false,

      data: null,
      product_index: -1,

      pick_up_date: new Date(),
      pick_up_time: format_date(new Date(), "hh:mm"),
      pick_up_location: "",
      return_date: new Date(new Date().setDate(new Date().getDate() + 1)),
      return_time: format_date(new Date(), "hh:mm"),
      return_location: "",
    }
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const permission = localStorage.getItem("permission");
    if (token) {
      if (!user) {
        removeStorage();
      } else if (!user.user_type) {
        window.location.href = "/user/choose-car";
      } else {
        localStorage.setItem("locale", "th");
        window.location.href = "/admin/dashboard";
      }
    } else {
      removeStorage();
    }
    this.GetBikeAll();
  }
  GetBikeAll = async () => {
    let data = {
      start_date: this.state.pick_up_date ? format_date(this.state.pick_up_date, "yyyy-mm-dd", "en") + " " + this.state.pick_up_time : null,
      end_date: this.state.return_date ? format_date(this.state.return_date, "yyyy-mm-dd", "en") + " " + this.state.return_time : null,
      search: ""
    }
    let result = await GET(tokens, "v1/TH/bike-manage/filter-search-public/page/0", data);
    if (result && result.status) {
      this.setState({ data: result.data.data });
    }
  }
  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}

        <div className="w-100 position-fixed bg-dark" style={{ zIndex: 1010 }}>
          <div className="w-100 px-3">
            <div className="w-100 d-flex row align-items-center justify-content-between py-2">
              <h3 className="mb-2 text-light pointer" onClick={() => { window.location.href = "/index" }}><b>S.K.SERVICE</b></h3>

              <div className="d-flex">
                <button className="btn btn-light mr-1 px-3" onClick={() => { window.location.href = "/register" }}>
                  {global[locale]["สมัครสมาชิก"]} </button>
                <button className="btn btn-orange px-3" onClick={() => { window.location.href = "/login" }}>
                  {global[locale]["เข้าสู่ระบบ"]} </button>
              </div>
            </div>
          </div>
        </div>
        {/* ค้นหา */}
        <div className="row background pb-4">
          <div className="col-12 col-md-10 pt-3 pb-3 px-3 mx-auto" style={{ marginTop: 80 }}>
            <div className="card border-0 px-4 py-3 shadow">
              <div className="row">
                <div className="col-12 col-sm-6 px-1">
                  <b className="text-orange mb-2">{global[locale]["สถานที่รับรถ"]}</b>
                  <input type="text" className="form-control w-100 mb-2" placeholder={global[locale]["เช่น สนามบินเชียงใหม่"]}></input>
                </div>
                <div className="col-12 col-sm-6 px-1">
                  <b className="text-orange mb-2">{global[locale]["สถานที่คืนรถ"]}</b>
                  <input type="text" className="form-control w-100 mb-2" placeholder={global[locale]["เช่น สนามบินเชียงใหม่"]}></input>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                  <b className="text-orange mb-2">{global[locale]["วันที่รับรถ"]}{required}</b>
                  <DatePickerTH
                    className="form-control w-100"
                    onChange={(e) => {
                      this.setState({ pick_up_date: e });
                      if (Number(format_date(e, "yyyymmdd")) >= Number(format_date(this.state.return_date, "yyyymmdd"))) {
                        this.setState({ return_date: format_date(new Date(format_date(e, "yyyy,mm,dd")).setDate(new Date(format_date(e, "yyyy,mm,dd")).getDate() + 1), "yyyy-mm-dd", "en") });
                      }
                      setTimeout(() => {
                        this.GetBikeAll()
                      }, 10);
                    }}
                    value={this.state.pick_up_date}
                    clearable={false}
                    min={new Date(new Date().setDate(new Date().getDate() - 1))}
                  />
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                  <b className="text-orange mb-2">{global[locale]["เวลารับรถ"]}{required}</b>
                  <input
                    type="time"
                    className="form-control w-100 mb-2"
                    onChange={(e) => { this.setState({ pick_up_time: e.target.value }); }}
                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                    value={this.state.pick_up_time} />
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 px-1">
                  <b className="text-orange mb-2">{global[locale]["วันที่คืนรถ"]}{required}</b>
                  <DatePickerTH
                    className="form-control w-100"
                    onChange={(e) => {
                      this.setState({ return_date: e });
                      setTimeout(() => {
                        this.GetBikeAll()
                      }, 10);
                    }}
                    value={this.state.return_date}
                    clearable={false}
                    min={new Date(this.state.pick_up_date).setDate(new Date(this.state.pick_up_date).getDate() - 1)}
                  />
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 px-1">
                  <b className="text-orange mb-2">{global[locale]["เวลาคืนรถ"]}{required}</b>
                  <input
                    type="time"
                    className="form-control w-100 mb-2"
                    onChange={(e) => { this.setState({ return_time: e.target.value }); }}
                    onKeyDown={(e) => { if (e.keyCode == 8) e.preventDefault(); }}
                    value={this.state.return_time} />
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 px-1">
                  <div style={{ height: 22 }}></div>
                  <button className="btn btn-orange w-100 mb-2" onClick={() => {
                    // if (this.state.pick_up_location === "") {
                    //     alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่รับรถ");
                    //     return;
                    // } else if (this.state.return_location === "") {
                    //     alert("warning", "แจ้งเตือน", "กรุณากรอกสถานที่คืนรถ");
                    //     return;
                    // }
                    this.GetBikeAll();
                  }}>
                    {global[locale]["ค้นหา"]}
                    <label className="icon ml-2">{"\uf002"}</label>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* รายการรถ */}
        <div className="row" style={{ backgroundColor: "#000" }}>
          <div className="col-12 col-md-1"></div>
          <div className="col-12 col-md-10 pb-5 px-4 pt-4">
            <div className="row">
              {this.state.data && this.state.data.map((item, index) => (
                <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3 mb-3 px-1">
                  <div className="card bg-white shadow overflow-hidden border-0 position-relative"
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                  // onMouseOver={() => { this.setState({ product_index: index }) }}
                  // onMouseLeave={() => { this.setState({ product_index: -1 }) }}
                  >
                    <img className="d-none d-sm-block" src={item.title_picture ? item.title_picture : CAR} style={{ height: 240, objectFit: "cover" }} />
                    <img className="d-block d-sm-none" src={item.title_picture ? item.title_picture : CAR} style={{ height: 180, objectFit: "cover" }} />
                    <div className="p-2">
                      <div style={{ height: 23, overflow: "hidden" }}>
                        <h6 className="mb-1 d-block d-sm-none"><b>{item.brand_name}</b> {item.model_name}</h6>
                        <h5 className="mb-1 d-none d-sm-block"><b>{item.brand_name}</b> {item.model_name}</h5>
                      </div>
                      <hr className="my-2" />
                      <div className="d-flex row justify-content-between mx-0">
                        <div className="p-0 d-flex align-items-end">
                          <h4 className="mb-0 text-orange"><b>฿{item.daily_price ? toFixed(item.daily_price) : "0.00"}</b></h4>
                          <small className="text-secondary ml-2">{global[locale]["ต่อวัน"]}</small>
                        </div>
                        <div className="p-0 d-flex align-items-end">
                          <small className="text-secondary ml-2">{global[locale]["คงเหลือ"]} {item.balance_app} {global[locale]["คัน"]}</small>
                        </div>
                      </div>
                    </div>
                    {index === this.state.product_index && (
                      <div
                        className="fade-in-product rounded pointer position-absolute d-flex align-items-center justify-content-center"
                        style={{
                          width: "100%",
                          height: "100%",
                          left: 0,
                          top: 0,
                          borderWidth: 4,
                          borderStyle: "solid",
                          borderColor: "#fd7e14"
                        }}>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
