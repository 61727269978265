/* eslint-disable jsx-a11y/anchor-is-valid */
import LOGO from "../assets/images/logo.png"
import React, { Component } from "react"
import Footer from "../components/Footer"
import "../assets/css/bootstrap.css"
export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      password: "",
      loading: false,

      modal_login: false,
      modal_alert: false,

    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="d-flex w-100 position-fixed bg-dark" style={{ zIndex: 1010 }}>
          <div className="w-100 px-4">
            <div className="w-100 d-flex align-items-center justify-content-between py-2">
              <h3 className="mb-0 text-light pointer" onClick={() => { window.location.href = "/index" }}><b>S.K.SERVICE</b></h3>
            </div>
          </div>
        </div>
        <div className="row background3 pt-5" style={{ height: "100vh" }}>
          <div className="col-12 col-md-1"></div>
          <div className="col-12 py-5 px-4">
            <div className="center w-100">
              <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-10 mx-auto">
                <div className=" d-flex justify-content-center mb-3">
                  <img src={LOGO} style={{ width: 170 }} />
                </div>
                <div className="card shadow">
                  <div className="col-12 p-4 text-center">
                    <h1 className="mb-4 text-center">
                      <b>404</b>
                    </h1>
                    <h3 className="mb-4 text-center">
                      ขออภัย ไม่พบหน้าที่คุณต้องการ
                    </h3>
                    <label className="mb-4">กรุณาลองใหม่อีกครั้ง หรือกดปุ่มเพื่อย้อนกลับไปหน้าหลัก</label>

                    <button type="button" className="btn btn-orange w-100 mb-3" onClick={() => { localStorage.clear(); window.location.href = "/" }}>กลับหน้าหลัก</button>

                    <div className="w-100 text-center mt-2">
                      <small className="text-secondary">{global.version}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
