import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    alert,
    GET,
    POST,
    PUT,
    DELETE,
    float,
    validate,
    required,
    DatePickerTH,
    success_url,
    format_date
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import CAR from "../../../assets/images/bike.png";

export default class ListCar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            filter_search: "",
            filter_channel_id: "",

            data: null,
            data_brand: [],
            data_model: [],
            data_channel: [],
            data_year: [],

            tab: "รายละเอียด",

            type: "",

            brand_id: "",
            model_id: "",
            channel_id: "",
            license_plate: "",
            chassis_number: "",
            bike_type: "",
            bike_color: "",
            cc_size: "",
            bike_year: "",
            tax_expire_date: "",
            tax_countdown: "",
            description: "",
            daily_price: "",
            weekly_price: "",
            monthly_price: "",
            title_picture: "",
            detail_picture: ["", "", "", "", "", "", "", "", "", ""],
            picture_delete: [],

            // brand
            modal_create_brand: false,
            brand_name: "",
            brand_description: "",

            // model
            modal_create_model: false,
            model_brand_id: "",
            model_name: "",
            model_description: "",

            channel_name: "",
            description: "",

            modal_type: ""
        }
    }
    async componentDidMount() {

        this.setState({ loading: true });
        let array = [];
        for (let i = 0; i < 100; i++) {
            array.push({ value: new Date().getFullYear() - i, label: new Date().getFullYear() - i + 543 });
        }
        this.setState({ data_year: array });
        await this.GetAll();
        await this.GetBrandAll();
        await this.GetModelAll();
        await this.GetChanelAll();
        this.setState({ loading: false });
    }
    GetAll = async () => {
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
            channel_id: this.state.filter_channel_id
        }
        let result = await GET(tokens, "v1/TH/bike-manage/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
    }
    GetBrandAll = async () => {
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bike-brand/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_brand: result.data.data.map(item => { return { label: item.brand_name, value: item.brand_id } }) })
        }
    }
    GetModelAll = async () => {
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bike-model/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_model: result.data.data.map(item => { return { label: item.model_data.model_name, value: item.model_data.model_id, brand_name: item.brand_name, brand_id: item.brand_id } }) })
        }
    }
    GetChanelAll = async () => {
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bike-channel/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({
                data_channel: result.data.data.map(item => { return { label: item.channel_name, value: item.channel_id, bike_info: item.bike_info } })
            })
        }
    }

    Create = async () => {
        if (validate(this.state, ["brand_id", "model_id", "license_plate", "chassis_number", "bike_type", "bike_color", "bike_year", "tax_expire_date", "daily_price"])) { return };
        this.setState({ loading: true });
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date();
        const secondDate = new Date(format_date(this.state.tax_expire_date, "yyyy,mm,dd"));

        const tax_countdown = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        let data = {
            brand_id: this.state.brand_id ? this.state.brand_id.value : "",
            model_id: this.state.model_id ? this.state.model_id.value : "",
            channel_id: this.state.channel_id ? this.state.channel_id.value : "",
            license_plate: this.state.license_plate,
            chassis_number: this.state.chassis_number,
            bike_type: this.state.bike_type ? this.state.bike_type.value : "",
            bike_color: this.state.bike_color,
            cc_size: this.state.cc_size,
            bike_year: this.state.bike_year ? this.state.bike_year.value : "",
            tax_expire_date: this.state.tax_expire_date,
            tax_countdown: tax_countdown,
            description: this.state.description,
            daily_price: Number(this.state.daily_price),
            weekly_price: Number(this.state.weekly_price),
            monthly_price: Number(this.state.monthly_price),
            title_picture: this.state.title_picture ? this.state.title_picture : global["logo"],
            detail_picture: this.state.detail_picture.filter(item => item.length !== 0),
        }
        let result = await POST(tokens, "v1/TH/bike-manage/create", data);
        if (result && result.status) {
            success_url("/master/list-car");
        }
        this.setState({ loading: false });

    }
    CreateBrand = async () => {
        if (validate(this.state, ["brand_name"])) { return };
        this.setState({ loading: true });
        let data = {
            brand_name: this.state.brand_name,
            brand_description: this.state.brand_description,
        }
        let result = await POST(tokens, "v1/TH/bike-brand/create", data);
        if (result && result.status) {
            if (this.state.type === "create") {
                this.setState({ modal_create: true })
            } else {
                this.setState({ modal_update: true })
            }
            this.setState({ modal_create_brand: false, loading: false });
            await this.GetBrandAll();
        }
        this.setState({ loading: false })

    }
    CreateModel = async () => {
        if (validate(this.state, ["model_brand_id", "model_name"])) { return };
        this.setState({ loading: true });
        let data = {
            brand_id: this.state.model_brand_id ? this.state.model_brand_id.value : "",
            model_name: this.state.model_name,
            model_description: this.state.model_description,
        }
        let result = await POST(tokens, "v1/TH/bike-model/create", data);
        if (result && result.status) {
            if (this.state.type === "create") {
                this.setState({ modal_create: true })
            } else {
                this.setState({ modal_update: true })
            }
            this.setState({ modal_create_brand: false, loading: false });
            await this.GetModelAll();
        }
        this.setState({ loading: false })

    }
    Create_Storage = async () => {
        if (validate(this.state, ["channel_name"])) { return };
        this.setState({ loading: true });
        let data = {
            channel_name: this.state.channel_name,
            description: this.state.description,
            bike_id: ""
        }
        let result = await POST(tokens, "v1/TH/bike-channel/create", data);
        if (result && result.status) {
            this.setState({ modal_create_storage: false })
            if (this.state.type === "create") {
                this.setState({ modal_create: true })
            } else {
                this.setState({ modal_update: true })
            }
            await this.GetChanelAll();
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        if (validate(this.state, ["brand_id", "model_id", "license_plate", "chassis_number", "bike_type", "bike_color", "bike_year", "tax_expire_date", "daily_price"])) { return };
        this.setState({ loading: true });
        const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = new Date();
        const secondDate = new Date(this.state.tax_expire_date.split("-")[0], this.state.tax_expire_date.split("-")[1], this.state.tax_expire_date.split("-")[2]);
        const tax_countdown = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        let picture_delete = this.state.picture_delete.filter(item => this.state.detail_picture.findIndex(e => e === item.picture) === -1).map(item => { return item.picture_id });
        let data = {
            bike_id: this.state.id,
            brand_id: this.state.brand_id ? this.state.brand_id.value : "",
            model_id: this.state.model_id ? this.state.model_id.value : "",
            channel_id: this.state.channel_id ? this.state.channel_id.value : "",
            license_plate: this.state.license_plate,
            chassis_number: this.state.chassis_number,
            bike_type: this.state.bike_type ? this.state.bike_type.value : "",
            bike_color: this.state.bike_color,
            cc_size: this.state.cc_size,
            bike_year: this.state.bike_year ? this.state.bike_year.value : "",
            tax_expire_date: this.state.tax_expire_date,
            tax_countdown: tax_countdown,
            description: this.state.description,
            daily_price: Number(this.state.daily_price),
            weekly_price: Number(this.state.weekly_price),
            monthly_price: Number(this.state.monthly_price),
            title_picture: this.state.title_picture ? this.state.title_picture : global["logo"],
            picture_delete: picture_delete,
            detail_picture: this.state.detail_picture.filter(item => item.length !== 0),
        }
        let result = await PUT(tokens, "v1/TH/bike-manage/update", data);
        if (result && result.status) {
            success_url("/master/list-car");
        }
        this.setState({ loading: false });
    }
    Delete = async () => {
        this.setState({ loading: true });
        let result = await DELETE(tokens, 'v1/TH/bike-manage/delete/' + this.state.id, null);
        if (result && result.status) {
            success_url("/master/list-car");
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            filter_search: "",
            filter_channel_id: "",

            tab: "รายละเอียด",

            type: "",

            brand_id: "",
            model_id: "",
            channel_id: "",
            license_plate: "",
            chassis_number: "",
            bike_type: "",
            bike_color: "",
            cc_size: "",
            bike_year: "",
            tax_expire_date: "",
            tax_countdown: "",
            description: "",
            daily_price: "",
            weekly_price: "",
            monthly_price: "",
            title_picture: "",
            detail_picture: ["", "", "", "", "", "", "", "", "", ""],
            picture_delete: [],

            // brand
            modal_create_brand: false,
            brand_name: "",
            brand_description: "",

            // model
            modal_create_model: false,
            model_brand_id: "",
            model_name: "",
            model_description: "",
        })
    }
    import_file = (e, type) => {
        if (this.state.detail_picture.filter(item => item.length !== 0).length + e.target.files.length > 10 && type === "sub") {
            alert("warning", "แจ้งเตือน", "สามารถอัพโหลดรูปได้สูงสุด 10 รูป");
            return;
        }
        if (e.target.files.length === 1) {
            this.changePhoto(e.target.files[0], type)
            document.getElementById("file").value = "";
            document.getElementById("file").file = null;
            document.getElementById("file_sub").value = "";
            document.getElementById("file_sub").file = null;
        } else {
            this.multiple_file(0, e.target.files.length, e.target.files);
        }
    }
    multiple_file = async (index, max, file) => {
        await this.changePhoto(file[index], "sub");
        if (index + 1 < max) {
            setTimeout(() => {
                this.multiple_file(index + 1, max, file);
            }, 50);
        }
    }
    changePhoto = async (file, type) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => { }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    if (type === "main") {
                        this.setState({ title_picture: uri.split(",")[1] })
                    } else {
                        let detail_picture = this.state.detail_picture;
                        let index = detail_picture.findIndex(item => item === "");
                        if (index !== -1) {
                            detail_picture[index] = uri.split(",")[1];
                        }
                        this.setState({
                            detail_picture: detail_picture
                        })
                    }

                },
                "base64"
            );
        });
    }
    delete_image = (picture) => {
        let data = this.state.detail_picture;
        let index = data.findIndex(item => item === picture);
        data[index] = "";
        let array = [];
        for (let item of data) {
            if (item !== "") {
                array.push(item);
            }
        }
        array = [...array, "", "", "", "", "", "", "", "", "", ""];
        array = array.filter((item, index) => index < 10);
        setTimeout(() => {
            this.setState({ detail_picture: data });
        }, 100);
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ข้อมูลรถ" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ข้อมูลรถ</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add" onClick={() => { this.clear_form(); this.setState({ modal_create: true, type: "create" }) }}>
                                    เพิ่มข้อมูลรถ
                                </button>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="ป้ายทะเบียน,ยี่ห้อ,รุ่น"
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_channel_id: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">หมายเลขรถ</th>
                                                <th className="text-center">รูปภาพ</th>
                                                <th className="text-center">สี</th>
                                                <th className="text-center">ป้ายทะเบียน</th>
                                                <th className="text-center">ปี</th>
                                                <th className="text-center">เครื่องยนต์</th>
                                                <th className="text-center">ยี่ห้อ</th>
                                                <th className="text-center">รุ่น</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={9} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.bike_channel_name ? item.bike_channel_name : "-"}</td>
                                                    <td className="text-center"><img className="wpx-30 hpx-30 rounded-circle" src={item.title_picture ? item.title_picture : CAR} style={{ objectFit: "cover" }} alt="รูปภาพ" />
                                                    </td>
                                                    <td className="text-center">{item.bike_color ? item.bike_color : "-"}</td>
                                                    <td className="text-center">{item.license_plate ? item.license_plate : "-"}</td>
                                                    <td className="text-center">{item.bike_year ? item.bike_year + 543 : "-"}</td>
                                                    <td className="text-center">{item.cc_size ? item.cc_size : "-"}</td>
                                                    <td className="text-center">{item.brand_name ? item.brand_name : "-"}</td>
                                                    <td className="text-center">{item.model_name ? item.model_name : "-"}</td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <label className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={() => {
                                                                    let detail_picture = item.all_picture.map(e => { return e.picture });
                                                                    for (let i = 0; i < 10 - item.all_picture.length; i++) {
                                                                        detail_picture.push("");
                                                                    }
                                                                    this.setState({
                                                                        modal_update: true,
                                                                        id: item.bike_id,
                                                                        brand_id: this.state.data_brand[this.state.data_brand.findIndex(e => e.value === item.brand_id)],
                                                                        model_id: this.state.data_model[this.state.data_model.findIndex(e => e.value === item.model_id)],
                                                                        channel_id: item.bike_channel_id ? this.state.data_channel[this.state.data_channel.findIndex(e => e.value === item.bike_channel_id)] : "",
                                                                        license_plate: item.license_plate,
                                                                        chassis_number: item.chassis_number,
                                                                        bike_type: item.bike_type.toString() === "1" ? { value: "1", label: "รถจักรยานยนต์" } : item.bike_type.toString() === "2" ? { value: "2", label: "รถยนต์" } : "",
                                                                        bike_color: item.bike_color,
                                                                        cc_size: item.cc_size,
                                                                        bike_year: item.bike_year ? { value: item.bike_year, label: item.bike_year + 543 } : "",
                                                                        tax_expire_date: item.tax_expire_date,
                                                                        tax_countdown: item.tax_countdown,
                                                                        description: item.description,
                                                                        daily_price: item.daily_price,
                                                                        weekly_price: item.weekly_price,
                                                                        monthly_price: item.monthly_price,
                                                                        title_picture: item.title_picture,
                                                                        detail_picture: detail_picture,
                                                                        picture_delete: item.all_picture,
                                                                        type: "update"
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer"
                                                                > {"\uf304"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-danger-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 delete"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_delete: true,
                                                                        id: item.bike_id,
                                                                        license_plate: item.license_plate,
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-danger pointer"
                                                                > {"\uf1f8"} </label>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* create or update */}
                <Modal
                    show={this.state.modal_create || this.state.modal_update}
                    onHide={() => { this.setState({ modal_create: false, modal_update: false }) }}
                    size="lg"
                >
                    <Modal.Header> <Modal.Title> <b>{this.state.modal_create ? "เพิ่มข้อมูลรถ" : "แก้ไขข้อมูลรถ"}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="card bg-contain p-3 pb-0">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-4 ml-auto mb-3">
                                            {this.state.title_picture === "" ? (
                                                <div
                                                    className="w-100 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5 mb-3"
                                                    style={{ height: "100%" }}
                                                    onClick={() => { document.getElementById("file").click() }}
                                                >
                                                    <label className="icon text-orange">{"\uf03e"}</label>
                                                    <label className="text-orange">อัปโหลดภาพรถ</label>
                                                </div>
                                            ) : (
                                                <div
                                                    className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                                >
                                                    <div className="position-relative w-100">
                                                        <img alt="รูปโปรไฟล์" src={this.state.title_picture.includes("https://") ? this.state.title_picture : "data:image/png;base64," + this.state.title_picture} className="w-100 rounded overflow-hidden"></img>
                                                        <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.setState({ title_picture: "" }) }}>
                                                            {"\uf00d"}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e, "main") }} />
                                        </div>

                                        <input className="d-none" type="file" id="file_sub" accept="image/*" onChange={(e) => { this.import_file(e, "sub") }} multiple />
                                        <div className="col-12 col-md-6 col-lg-6 mr-auto">
                                            <div className="row">
                                                {this.state.detail_picture.filter(e => e.length !== 0).map((item, index) => (
                                                    <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                        <div
                                                            className="w-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                        >
                                                            <div className="position-relative w-100 hpx-100 d-flex justify-content-center align-items-center">
                                                                <img alt="รูปโปรไฟล์" src={item.includes("https://") ? item : "data:image/png;base64," + item} className="w-100 hpx-90 rounded overflow-hidden" style={{ objectFit: "contain" }}></img>
                                                                <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => { this.delete_image(item) }}>
                                                                    {"\uf00d"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {this.state.detail_picture.filter(e => e.length !== 0).length !== 10 && (
                                                    <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                        <div
                                                            className="w-100 hpx-100 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                            onClick={() => { document.getElementById("file_sub").click() }}
                                                        >
                                                            <label className="icon text-orange">{"\uf03e"}</label>
                                                            <label className="text-orange text-12">รูปภาพเพิ่มเติม</label>
                                                        </div>
                                                    </div>
                                                )}
                                                {this.state.detail_picture.filter((e, index) => e.length === 0 && index !== 9).map((item, index) => (
                                                    <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                        <div className="card w-100 hpx-100 bg-contain">
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <h4><b>ข้อมูลรถ</b></h4>
                                <hr />
                            </div>
                            <div className="col-12 col-md-3">
                                {/* ป้ายทะเบียน */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-3">ป้ายทะเบียน{required}:</label><br />
                                    <input
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ license_plate: e.target.value })
                                        }}
                                        value={this.state.license_plate}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                {/* เลขตัวถัง */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-3">เลขตัวถัง{required}:</label><br />
                                    <input
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ chassis_number: e.target.value })
                                        }}
                                        value={this.state.chassis_number}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                {/* ยี่ห้อรถ */}
                                <div className="w-100 mb-3">
                                    <div className="d-flex">
                                        <label className="mb-3">ยี่ห้อรถ{required}:</label>
                                        <div className="wpx-20 hpx-20 rounded-circle bg-orange text-white d-flex justify-content-center align-items-center pointer ml-2"
                                            onClick={() => {
                                                this.setState({
                                                    modal_create: false,
                                                    modal_update: false,
                                                    modal_create_brand: true,
                                                    brand_name: "",
                                                    brand_description: ""
                                                })
                                            }}
                                        >+</div>
                                    </div>
                                    <Select
                                        className="form-control  validate select-search"
                                        styles={select_style}
                                        options={this.state.data_brand}
                                        onChange={(e) => {
                                            this.setState({ brand_id: e, model_id: null });
                                        }}
                                        value={this.state.brand_id}
                                    ></Select>
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                {/* รุ่นรถ */}
                                <div className="w-100 mb-3">

                                    <div className="d-flex">
                                        <label className="mb-3">รุ่นรถ{required}:</label>
                                        <div className="wpx-20 hpx-20 rounded-circle bg-orange text-white d-flex justify-content-center align-items-center pointer ml-2"
                                            onClick={() => {
                                                this.setState({
                                                    modal_create: false,
                                                    modal_update: false,
                                                    modal_create_model: true,
                                                    model_brand_id: "",
                                                    model_name: "",
                                                    model_description: "",
                                                })
                                            }}
                                        >+</div>
                                    </div>
                                    <Select
                                        className="form-control  validate select-search"
                                        styles={select_style}
                                        isDisabled={this.state.brand_id === ""}
                                        options={this.state.data_model.filter(item => !this.state.brand_id || (this.state.brand_id && this.state.brand_id.value === item.brand_id))}
                                        onChange={(e) => {
                                            this.setState({ model_id: e, brand_id: { label: e.brand_name, value: e.brand_id } });
                                        }}
                                        value={this.state.model_id}
                                    ></Select>
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                {/* ประเภท */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-3">ประเภท{required}:</label><br />
                                    <Select
                                        className="form-control  validate select-search"
                                        styles={select_style}
                                        options={[
                                            { value: "1", label: "รถจักรยานยนต์" },
                                            { value: "2", label: "รถยนต์" },
                                        ]}
                                        onChange={(e) => {
                                            this.setState({ bike_type: e });
                                        }}
                                        value={this.state.bike_type}
                                    ></Select>
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                {/* สีรถ */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-3">สีรถ{required}:</label><br />
                                    <input
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ bike_color: e.target.value })
                                        }}
                                        value={this.state.bike_color}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                {/* ขนาด ซีซี. */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-3">ขนาด ซีซี.:</label><br />
                                    <input
                                        className="form-control validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            if (!float(e.target.value)) {
                                                return
                                            }
                                            this.setState({ cc_size: e.target.value })
                                        }}
                                        value={this.state.cc_size}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                {/* ช่องเก็บรถ */}
                                <div className="w-100 mb-3">
                                    <div className="d-flex">
                                        <label className="mb-3">ช่องเก็บรถ :</label><br />
                                        <div className="wpx-20 hpx-20 rounded-circle bg-orange text-white d-flex justify-content-center align-items-center pointer ml-2"
                                            onClick={() => {
                                                this.setState({
                                                    modal_create: false,
                                                    modal_update: false,
                                                    modal_create_storage: true,
                                                    channel_name: "",
                                                    description: "",
                                                })
                                            }}
                                        >+</div>
                                    </div>
                                    <Select
                                        className="form-control  validate select-search"
                                        styles={select_style}
                                        options={this.state.modal_create ? this.state.data_channel.filter(item => !item.bike_info) : this.state.data_channel}
                                        onChange={(e) => {
                                            this.setState({ channel_id: e });
                                        }}
                                        value={this.state.channel_id}
                                    ></Select>
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                {/* ปีรถ */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-3">ปีรถ{required}:</label><br />
                                    <Select
                                        className="form-control  validate select-search"
                                        styles={select_style}
                                        options={this.state.data_year}
                                        onChange={(e) => {
                                            this.setState({ bike_year: e });
                                        }}
                                        value={this.state.bike_year}
                                    ></Select>
                                </div>
                                {/* วันภาษีหมดอายุ */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-3">วันภาษีหมดอายุ{required}:</label><br />
                                    <DatePickerTH
                                        className="form-control "
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => {
                                            this.setState({ tax_expire_date: e });
                                        }}
                                        value={this.state.tax_expire_date}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-9">
                                {/* คำอธิบาย */}
                                <div className="w-100 mb-3">
                                    <label className="w-100 mb-3">คำอธิบาย :</label><br />
                                    <textarea
                                        className="form-control  validate"
                                        type="text"
                                        placeholder="กรอกข้อมูล"
                                        onChange={(e) => {
                                            this.setState({ description: e.target.value })
                                        }}
                                        value={this.state.description}
                                        rows={6}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <h4><b>ราคาค่าเช่า</b></h4>
                            <hr />
                        </div>
                        <div className="row">
                            {/* รายวัน */}
                            <div className="col-12 col-md-4 mb-3">
                                <label className="w-100 mb-3">ราคารายวัน{required}:</label><br />
                                <input
                                    className="form-control  validate"
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                        if (!float(e.target.value)) {
                                            return
                                        }
                                        this.setState({ daily_price: e.target.value })
                                    }}
                                    value={this.state.daily_price}
                                />
                            </div>
                            {/* รายสัปดาห์ */}
                            <div className="col-12 col-md-4 mb-3">
                                <label className="w-100 mb-3">ราคารายสัปดาห์ :</label><br />
                                <input
                                    className="form-control  validate"
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                        if (!float(e.target.value)) {
                                            return
                                        }
                                        this.setState({ weekly_price: e.target.value })
                                    }}
                                    value={this.state.weekly_price}
                                />
                            </div>
                            {/* รายเดือน */}
                            <div className="col-12 col-md-4 mb-3">
                                <label className="w-100 mb-3">ราคารายเดือน :</label><br />
                                <input
                                    className="form-control  validate"
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                        if (!float(e.target.value)) {
                                            return
                                        }
                                        this.setState({ monthly_price: e.target.value })
                                    }}
                                    value={this.state.monthly_price}
                                />
                            </div>
                        </div>

                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        if (this.state.modal_create) {
                                            this.Create();
                                        } else {
                                            this.Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* create brand */}
                <Modal show={this.state.modal_create_brand} onHide={() => { this.setState({ modal_create_brand: false }) }} >
                    <Modal.Header> <Modal.Title> <b>เพิ่มยี่ห้อรถ</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {/* ชื่อยี่ห้อ */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ชื่อยี่ห้อ{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ brand_name: e.target.value })
                                }}
                                value={this.state.brand_name}
                            />
                        </div>
                        {/* คำอธิบาย */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">คำอธิบาย{required}:</label>
                            <textarea
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ brand_description: e.target.value })
                                }}
                                value={this.state.brand_description}
                            />
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => {
                                    if (this.state.type === "create") {
                                        this.setState({ modal_create: true })
                                    } else {
                                        this.setState({ modal_update: true })
                                    }
                                    this.setState({ modal_create_brand: false })
                                }}>ยกเลิก</button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-orange w-100" onClick={() => { this.CreateBrand(); }}>เพิ่ม</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* create model */}
                <Modal show={this.state.modal_create_model} onHide={() => { this.setState({ modal_create_model: false }) }}>
                    <Modal.Header> <Modal.Title> <b>เพิ่มรุ่นรถ</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {/* ยี่ห้อ */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ยี่ห้อ{required}:</label>
                            <Select
                                className="form-control  validate select-search"
                                styles={select_style}
                                options={this.state.data_brand}
                                onChange={(e) => {
                                    this.setState({ model_brand_id: e });
                                }}
                                value={this.state.model_brand_id}
                            ></Select>
                        </div>
                        {/* ปุ่ม */}
                        {/* ชื่อรุ่น */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ชื่อรุ่น{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ model_name: e.target.value })
                                }}
                                value={this.state.model_name}
                            />
                        </div>
                        {/* คำอธิบาย */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">คำอธิบาย :</label>
                            <textarea
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ model_description: e.target.value })
                                }}
                                value={this.state.model_description}
                            />
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        if (this.state.type === "create") {
                                            this.setState({ modal_create: true })
                                        } else {
                                            this.setState({ modal_update: true })
                                        }
                                        this.setState({ modal_create_model: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.CreateModel();
                                    }}
                                >
                                    เพิ่ม
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบข้อมูล</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการลบข้อมูลดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* create or update */}
                <Modal
                    show={this.state.modal_create_storage}
                    onHide={() => { this.setState({ modal_create_storage: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>เพิ่มข้อมูลช่องเก็บรถ</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {/* ชื่อช่องเก็บรถ */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-110">ชื่อช่องเก็บรถ{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ channel_name: e.target.value })
                                }}
                                value={this.state.channel_name}
                            />
                        </div>
                        {/* คำอธิบาย */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-110">คำอธิบาย{required}:</label>
                            <textarea
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ description: e.target.value })
                                }}
                                value={this.state.description}
                            />
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create_storage: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.Create_Storage();
                                    }}
                                >
                                    เพิ่ม
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
