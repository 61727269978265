import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
    format_date,
    DatePickerTH,
    toFixed
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
    FCIconic: {
        normal: "FCIconic-Light.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Italic.ttf",
        bolditalics: "FCIconic-Italic.ttf",
    },
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
}

export default class ListWithdraw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            data: [],

            filter_search: "",
            filter_date: format_date(new Date(), "yyyy-mm-dd", "en"),
            filter_rental_status: ""
        }
        this.tableRef = React.createRef();
    }
    async componentDidMount() {
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search,
            pick_up_date: this.state.filter_date,
            rental_status: "",
        }
        let result = await GET(tokens, "v1/TH/rental-manage/filter-search/page/0", data);
        if (result && result.status) {
            const data = Object.values(result.data.data.reduce((p, v) => {
                const old = p[v.full_name];
                if (!old) {
                    p[v.full_name] = {
                        full_name: v.full_name,
                        member_code: v.member_code,
                        id_card: v.id_card,
                        array: [v]
                    };
                }
                else {
                    p[v.full_name] = {
                        ...old,
                        array: [...old.array, v]
                    };
                }
                return p;
            }, {}));
            this.setState({ data: data })
        }
        this.setState({ loading: false });
    }
    pdf = () => {
        let stack = this.state.data.filter(item => this.state.filter_search === "" || (JSON.stringify(item).includes(this.state.filter_search))).map((license, license_index) => {
            let table = license.array.filter(item => this.state.filter_search === "" || (item.rental_number && item.rental_number.includes(this.state.filter_search)) || (item.bike_channel_name && item.bike_channel_name.includes(this.state.filter_search)) || (item.license_plate && item.license_plate.includes(this.state.filter_search)))
                .filter(item => this.state.filter_date === "" || format_date(new Date(item.datetime_create), "dd-mm-yyyy") === format_date(new Date(this.state.filter_date), "dd-mm-yyyy")).map((item, index) => {
                    return [
                        { text: index + 1, alignment: "center" },
                        { text: item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-", alignment: "center" },
                        { text: item.rental_number ? item.rental_number : "-", alignment: "center" },
                        { text: "หมายเลขรถ" + (item.bike_channel_name ? item.bike_channel_name : "-") + " ทะเบียนรถ " + item.license_plate + " " + item.brand_name + " " + item.model_name, alignment: "left" },
                        { text: item.agent_name ? item.agent_name : "-", alignment: "left" },
                        { text: item.equipment_list ? item.equipment_list.map(e => { return e.equipment_name + " X " + e.equipment_qty + " (" + (e.equipment_price * e.equipment_qty) + ")\n" }) : "-", alignment: "left" },
                        { text: item.rental_price ? toFixed(item.rental_price) : "0.00", alignment: "left" },
                        { text: item.guarantee_amount ? toFixed(item.guarantee_amount) : "0.00", alignment: "left" },
                        { text: item.discount_price ? toFixed(item.discount_price) : "0.00", alignment: "left" },
                    ]
                });
            if (license.array && license.array
                .filter(item => this.state.filter_search === "" || (item.rental_number && item.rental_number.includes(this.state.filter_search)) || (item.bike_channel_name && item.bike_channel_name.includes(this.state.filter_search)) || (item.license_plate && item.license_plate.includes(this.state.filter_search)))
                .filter(item => this.state.filter_date === "" || format_date(new Date(item.datetime_create), "dd-mm-yyyy") === format_date(new Date(this.state.filter_date), "dd-mm-yyyy"))
                .length !== 0) {
                return [
                    " ",
                    { text: license.member_code + " : " + license.full_name + "(ลูกค้าทั่วไป)", bold: true, fontSize: 20 },
                    " ",
                    {
                        table: {
                            headerRows: 1,
                            widths: ['4%', '8%', '11%', '20%', '12%', '23%', '7%', '8%', '7%'],
                            body: [
                                [
                                    { text: "ลำดับ", alignment: "center", fillColor: "#F2F2F2" },
                                    { text: "วันที่", alignment: "center", fillColor: "#F2F2F2" },
                                    { text: "เลขที่สัญญาเช่า", alignment: "center", fillColor: "#F2F2F2" },
                                    { text: "ข้อมูลรถ", alignment: "center", fillColor: "#F2F2F2" },
                                    { text: "Agent", alignment: "center", fillColor: "#F2F2F2" },
                                    { text: "อุปกรณ์", alignment: "center", fillColor: "#F2F2F2" },
                                    { text: "ยอดเช่า", alignment: "center", fillColor: "#F2F2F2" },
                                    { text: "เงินประกัน", alignment: "center", fillColor: "#F2F2F2" },
                                    { text: "ส่วนลด", alignment: "center", fillColor: "#F2F2F2" }
                                ],
                                ...table
                            ]
                        }
                    },
                ]
            }
        });
        var docDefinition = {
            content: [

                { text: "รายงานเช่าตามลูกค้า", alignment: "left", fontSize: 24, bold: true },
                { stack: stack },
            ],
            // , alignment: "justify" 
            defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: 'landscape',
        }
        pdfMake.createPdf(docDefinition).open()
    }
    excel = () => {

    }
    clear_form() {
        this.setState({
            loading: false,

            filter_search: ""
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายงานเช่าตามลูกค้า" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายงานเช่าตามลูกค้า</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/report" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.pdf() }}>
                                    PDF
                                </button>
                                <DownloadTableExcel
                                    filename="รายงานเช่าตามลูกค้า"
                                    sheet="รายงานเช่าตามลูกค้า"
                                    currentTableRef={this.tableRef.current}
                                >
                                    <button type="button" className="btn btn-dark px-4 mx-1 mb-3" onClick={() => { this.excel() }}>
                                        EXCEL
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        value={this.state.filter_search}
                                    />
                                    <div className="wpx-200 px-0 mx-1 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_date: e });
                                            }}
                                            value={this.state.filter_date}
                                        />
                                    </div>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_date: "",
                                                filter_booking_status: "",
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}

                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped" ref={this.tableRef}>
                                        {this.state.data && this.state.data.map((license, license_index) =>
                                        (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={9}> <h3>{license.member_code + " : " + license.full_name + "(ลูกค้าทั่วไป)"}</h3>
                                                    </td>
                                                </tr>
                                                <tr className="bg-dark text-white">
                                                    <th className="text-center">ลำดับ</th>
                                                    <th className="text-center">วันที่</th>
                                                    <th className="text-center">เลขที่สัญญาเช่า</th>
                                                    <th className="text-center">ข้อมูลรถ</th>
                                                    <th className="text-center">Agent</th>
                                                    <th className="text-center">อุปกรณ์</th>
                                                    <th className="text-center">ยอดเช่า</th>
                                                    <th className="text-center">เงินประกัน</th>
                                                    <th className="text-center">ส่วนลด</th>
                                                </tr>
                                                {license.array && license.array.length === 0 && (
                                                    <tr> <td colSpan={9} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                                )}
                                                {license.array && license.array.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">{item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-"}</td>
                                                        <td className="text-center">{item.rental_number ? item.rental_number : "-"}</td>
                                                        <td className="text-center">{"หมายเลขรถ " + (item.bike_channel_name ? item.bike_channel_name : "-") + " ทะเบียนรถ " + item.license_plate + " " + item.brand_name + " " + item.model_name}</td>
                                                        <td className="text-center">{item.agent_name ? item.agent_name : "-"}</td>
                                                        <td className="text-left"><ol>{item.equipment_list ? item.equipment_list.map(e => { return (<li>{e.equipment_name + " X " + e.equipment_qty + " (" + (e.equipment_price * e.equipment_qty) + ")"}</li>) }) : "-"}</ol></td>
                                                        <td className="text-center">{item.rental_price ? toFixed(item.rental_price) : "0.00"}</td>
                                                        <td className="text-center">{item.guarantee_amount ? toFixed(item.guarantee_amount) : "0.00"}</td>
                                                        <td className="text-center">{item.discount_price ? toFixed(item.discount_price) : "0.00"}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
