import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Swal from "sweetalert2";
import {
    tokens,
    alert,
    GET,
    POST,
    PUT,
    DELETE,
    format_date,
    float,
    validate,
    required,
    getStorage,
    success_url,
    NumberZero,
    validateEmail,
    validateENNumber,
    total,
    toFixed
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";
import Switch from "react-switch";
import SignatureCanvas from 'react-signature-canvas';

export default class ListAgent extends Component {
    constructor(props) {
        super(props)

        this.sigPad = {}
        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_detail: false,

            page: 1,
            loading: false,

            data: null,
            data_withdraw: [],
            data_admin: [],

            filter_search: "",

            id: "",
            id_card: "",
            full_name: "",
            address: "",
            email: "",
            phone: "",
            remark: "",
            is_active: false,
            picture: "",

            payment_list: [],
            total_amount: "",
            signature: "",
            user_id: "",
            filter_withdraw: "",
            user: getStorage("user")
        }
    }
    async componentDidMount() {
        this.GetAll();
        this.GetAdminAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search,
        }
        let result = await GET(tokens, "v1/TH/user-agent/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    GetAgentById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/user-agent/bill-agent/" + id, null);
        if (result && result.status) {
            if (result.data.data.length === 0) {
                alert("warning", "แจ้งเตือน", "Agent รายนี้ยังไม่มีค่าแนะนำ");
            } else {
                this.setState({ data_withdraw: result.data.data.map(e => { return { ...e, ...e.event_agent, selected: false } }), modal_detail: true })
            }
        }
        this.setState({ loading: false });
    }
    GetAdminAll = async () => {
        let data = {
            data_search: "",
            user_type: "admin"
        }
        let result = await GET(tokens, "v1/TH/user-admin/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_admin: result.data.data.map(e => { return { value: e.user_id, label: e.user_code + " : " + e.full_name } }) })
        }
    }
    CreateWithdraw = async () => {
        if (this.state.data_withdraw.findIndex(e => e.selected) === -1) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกใบเช่าอย่างน้อย 1 รายการ");
            return;
        }
        if (validate(this.state, ["signature"])) { return };
        this.setState({ loading: true });
        let data = {
            agent_id: this.state.id,
            payment_list: this.state.data_withdraw.filter(e => e.selected).map(e => { return e.payment_number }),
            remark: this.state.remark,
            total_amount: total(this.state.data_withdraw.filter(e => e.selected), "agent_total_pay"),
            signature: this.state.signature,
            user_id: this.state.user ? this.state.user.user_id : ""
        }
        let result = await POST(tokens, "v1/TH/user-agent/create-group-bill", data);
        if (result && result.status) {
            success_url("/master/list-agent");
        }
        this.setState({ loading: false });
    }
    Create = async () => {
        if (validate(this.state, ["id_card", "full_name", "phone"])) { return };
        if (this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน")
            return
        } else if (this.state.email.length !== 0 && !validateEmail(this.state.email)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        }
        this.setState({ loading: true });
        let data = {
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            address: this.state.address,
            email: this.state.email,
            phone: this.state.phone,
            remark: this.state.remark,
            is_active: true,
            picture: this.state.picture,
        }
        let result = await POST(tokens, "v1/TH/user-agent/create", data);
        if (result && result.status) {
            success_url("/master/list-agent");
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        if (validate(this.state, ["id_card", "full_name", "phone"])) { return };
        if (this.state.phone.length < 10) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน")
            return
        } else if (this.state.email.length !== 0 && !validateEmail(this.state.email)) {
            alert("warning", "แจ้งเตือน", "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
            return
        }
        this.setState({ loading: true });
        let data = {
            agent_id: this.state.id,
            id_card: this.state.id_card,
            full_name: this.state.full_name,
            address: this.state.address,
            email: this.state.email,
            phone: this.state.phone,
            remark: this.state.remark,
            is_active: this.state.is_active,
            picture: this.state.picture,
        }
        let result = await PUT(tokens, "v1/TH/user-agent/update", data);
        if (result && result.status) {
            success_url("/master/list-agent");
        }
        this.setState({ loading: false });
    }
    Delete = async () => {
        this.setState({ loading: true });
        let result = await DELETE(tokens, 'v1/TH/user-agent/delete/' + this.state.id, null);
        if (result && result.status) {
            success_url("/master/list-agent")
        }
        this.setState({ loading: false });
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_detail: false,

            page: 1,
            loading: false,

            filter_search: "",

            id: "",
            id_card: "",
            full_name: "",
            address: "",
            email: "",
            phone: "",
            remark: "",
            is_active: false,
            picture: ""
        })
    }
    trim = () => {
        this.setState({ signature: this.sigPad.getTrimmedCanvas().toDataURL('image/png').split(",")[1] })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ข้อมูล Agent" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ข้อมูล Agent</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/master" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3 add" onClick={() => { this.clear_form(); this.setState({ modal_create: true }) }}>
                                    เพิ่ม Agent
                                </button>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="ชื่อ"
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-left">ชื่อ</th>
                                                <th className="text-left">รหัสบัตรประชาชน</th>
                                                <th className="text-left">ที่อยู่</th>
                                                <th className="text-left">อีเมล</th>
                                                <th className="text-left">เบอร์โทรศัพท์</th>
                                                <th className="text-center">สถานะ</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={8} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{NumberZero(((this.state.page - 1) * 10) + index + 1, 5)}</td>
                                                    <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                                                    <td className="text-left">{item.id_card ? item.id_card : "-"}</td>
                                                    <td className="text-left">{item.address ? item.address : "-"}</td>
                                                    <td className="text-left">{item.email ? item.email : "-"}</td>
                                                    <td className="text-left">{item.phone ? item.phone : "-"}</td>
                                                    <td className="text-center">
                                                        {item.is_active ? (
                                                            <span className="bg-green rounded px-2 py-1 text-white">
                                                                ใช้งาน
                                                            </span>
                                                        ) : (<span className="bg-red rounded px-2 py-1 text-white">
                                                            ปิดการใช้งาน
                                                        </span>)}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <label className="wpx-30 hpx-30 bg-primary-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={() => {
                                                                    this.GetAgentById(item.agent_id);
                                                                    this.setState({ id: item.agent_id });
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-primary pointer"
                                                                > {"\uf0d6"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 edit"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_update: true,
                                                                        id: item.agent_id,
                                                                        id_card: item.id_card,
                                                                        full_name: item.full_name,
                                                                        address: item.address,
                                                                        email: item.email,
                                                                        phone: item.phone,
                                                                        remark: item.remark,
                                                                        is_active: item.is_active,
                                                                        picture: item.picture,
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer"
                                                                > {"\uf304"} </label>
                                                            </label>
                                                            <label className="wpx-30 hpx-30 bg-danger-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1 delete"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_delete: true, id: item.agent_id,
                                                                        full_name: item.full_name,
                                                                    })
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-danger pointer"
                                                                > {"\uf1f8"} </label>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}
                {/* create or update */}
                <Modal
                    show={this.state.modal_create || this.state.modal_update}
                    onHide={() => { this.setState({ modal_create: false, modal_update: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>{this.state.modal_create ? "เพิ่ม Agent" : "แก้ไข Agent"}</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        {/* รหัสบัตรประชาชนหรือพาสปอร์ต */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">รหัสบัตรประชาชนหรือพาสปอร์ต{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                maxLength={13}
                                onChange={(e) => {
                                    if (!validateENNumber(e.target.value) && e.target.value !== "") {
                                        return
                                    }
                                    this.setState({ id_card: e.target.value })
                                }}
                                value={this.state.id_card}
                            />
                        </div>
                        {/* ชื่อ-นามสกุล */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ชื่อ-นามสกุล{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ full_name: e.target.value })
                                }}
                                value={this.state.full_name}
                            />
                        </div>
                        {/* ที่อยู่ */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">ที่อยู่ :</label>
                            <textarea
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ address: e.target.value })
                                }}
                                value={this.state.address}
                            />
                        </div>
                        {/* เบอร์โทร */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">เบอร์โทร{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                maxLength={10}
                                onChange={(e) => {
                                    if (!float(e.target.value)) {
                                        return
                                    }
                                    this.setState({ phone: e.target.value })
                                }}
                                value={this.state.phone}
                            />
                        </div>
                        {/* อีเมล */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">อีเมล :</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ email: e.target.value })
                                }}
                                value={this.state.email}
                            />
                        </div>
                        {/* อื่นๆ */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">อื่นๆ :</label>
                            <textarea
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ remark: e.target.value })
                                }}
                                value={this.state.remark}
                            />
                        </div>
                        {/* สถานะ */}
                        {this.state.modal_update && (
                            <div className="w-100 d-flex align-items-center mb-3">
                                <label className="wpx-140">สถานะ{required}:</label>
                                <Switch onChange={(e) => {
                                    this.setState({
                                        is_active: e,
                                    })
                                }} checked={this.state.is_active} />
                            </div>
                        )}
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_create: false, modal_update: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        if (this.state.modal_create) {
                                            this.Create();
                                        } else {
                                            this.Update();
                                        }
                                    }}
                                >
                                    {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* เบิกค่าแนะนำ */}
                <Modal
                    show={this.state.modal_detail}
                    onHide={() => { this.setState({ modal_detail: false }) }}
                >
                    <Modal.Header> <Modal.Title> <b>เบิกค่าแนะนำ</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_withdraw: e.target.value }); }}
                                        value={this.state.filter_withdraw}
                                    />
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center"><input type="checkbox"
                                                    checked={this.state.data_withdraw.findIndex(e => !e.selected) === -1 ? true : false}
                                                    onChange={(e) => {
                                                        let data_withdraw = this.state.data_withdraw;
                                                        for (let d of data_withdraw) {
                                                            d.selected = e.target.checked;
                                                        }
                                                        this.setState({ data_withdraw: data_withdraw })
                                                    }}
                                                /></th>
                                                <th className="text-center">เลขที่ใบเช่า</th>
                                                <th className="text-center">วันที่</th>
                                                <th className="text-center">ประเภทการชำระ</th>
                                                <th className="text-center">จำนวนเงิน</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data_withdraw || this.state.data_withdraw.length === 0) && (
                                                <tr> <td colSpan={5} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data_withdraw && this.state.data_withdraw.filter(item => this.state.filter_withdraw === "" || item.payment_number.includes(this.state.filter_withdraw)).map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center"><input type="checkbox" checked={item.selected} onChange={(e) => {
                                                        let data_withdraw = this.state.data_withdraw;
                                                        data_withdraw[index].selected = !data_withdraw[index].selected;
                                                        this.setState({ data_withdraw: data_withdraw })
                                                    }} /></td>
                                                    <td className="text-center">{item.payment_number ? item.payment_number.replaceAll("DP", "RT") : "-"}</td>
                                                    <td className="text-center">{item.payment_date ? format_date(item.payment_date) + " " + item.payment_time.slice(0, 5) : "-"}</td>
                                                    <td className="text-center">{item.agent_payment_type === 1 ? "เงินสด" : "เงินโอน"}</td>
                                                    <td className="text-center">{item.agent_total_pay ? toFixed(item.agent_total_pay) : "-"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* ยอดเบิกรวม */}
                        <div className="w-100 d-flex align-items-center justify-content-end mb-3">
                            <label className="mx-3">ยอดเบิกรวม</label>
                            <h3><b>฿{toFixed(total(this.state.data_withdraw.filter(e => e.selected), "agent_total_pay"))}</b></h3>
                        </div>

                        {/* ลายมือชื่ิอผู้เช่า */}
                        <div className="d-flex w-100 justify-content-between">
                            <h4 className="mb-0"><b>ลายเซ็นต์</b></h4>
                            {this.state.signature ? (
                                <button className="btn btn-orange" onClick={() => {
                                    Swal.fire({
                                        icon: "warning",
                                        title: "แจ้งเตือน",
                                        text: "ยืนยันการล้างลายมือชื่ิอผู้เช่าหรือไม่",
                                        confirmButtonText: "ตกลง",
                                        cancelButtonText: "ยกเลิก",
                                        showCancelButton: true,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.setState({ signature: "" })
                                        }
                                    });
                                }}>ล้างลายมือ</button>
                            ) : null}
                        </div>
                        <hr />
                        <div className="row mb-3">
                            <div className="col-12">
                                {this.state.signature === "" ? (
                                    <div className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center pointer" style={{ height: 250 }} onClick={() => { this.setState({ modal_signature: true, modal_detail: false }) }}>
                                        <label className="icon text-orange">{"\uf03e"}</label>
                                        <label className="text-orange">ลายเซ็นต์</label>
                                    </div>
                                ) : (
                                    <div
                                        className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-3"
                                    >
                                        <div className="position-relative w-100 p-4">
                                            <img alt="รูปลายเซ็นต์" src={this.state.signature.includes("https://") ? this.state.signature : "data:image/png;base64," + this.state.signature} className="w-100 img-fluid" style={{ height: 250, objectFit: "contain" }}></img>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                        {/* พนักงานส่งคืน */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">พนักงานส่งคืน{required}:</label>
                            <input
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                readOnly
                                value={this.state.user ? this.state.user.full_name : ""}
                            />
                        </div>
                        {/* หมายเหตุ */}
                        <div className="w-100 d-flex align-items-center mb-3">
                            <label className="wpx-140">หมายเหตุ:</label>
                            <textarea
                                className="form-control  validate"
                                type="text"
                                placeholder="กรอกข้อมูล"
                                onChange={(e) => {
                                    this.setState({ remark: e.target.value })
                                }}
                                value={this.state.remark}
                            />
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_detail: false })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.CreateWithdraw();
                                    }}
                                >
                                    ตกลง
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* ลายมือชื่อผู้เช่า */}
                <Modal show={this.state.modal_signature} >
                    <Modal.Header> <Modal.Title> <b>ลายมือชื่อผู้เช่า</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center">
                            <SignatureCanvas
                                penColor='black'
                                canvasProps={{ height: 500, className: 'w-100 sigCanvas' }}
                                clearOnResize={false}
                                ref={(ref) => { this.sigPad = ref }}
                                onEnd={(e) => { this.trim() }} />
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_signature: false, signature: "" })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_signature: false, modal_detail: true })
                                    }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบ Agent</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                ท่านแน่ในว่าต้องการลบ Agent “{this.state.full_name}” หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
