import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
    PUT,
    format_date,
    success_url
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";

export default class ListaxNotice extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_confirm: false,

            page: 1,
            loading: false,

            data: null,

            filter_search: "",
            id: ""

        }
    }
    async componentDidMount() {
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("#", "%23"),
        }
        let result = await GET(tokens, "v1/TH/tax-warning/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    Update = async () => {
        this.setState({ loading: true });
        let data = {
            bike_id: this.state.id
        }
        let result = await PUT(tokens, "v1/TH/tax-warning/update", data);
        if (result && result.status) {
            success_url("/admin/list-tax-notice")
        }
        this.setState({ loading: false });

    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_confirm: false,

            page: 1,
            loading: false,

            filter_search: "",
            id: ""

        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="แจ้งเตือนต่อภาษี" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>แจ้งเตือนต่อภาษี</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 add">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="ป้ายทะเบียน,ยี่ห้อรถ,รุ่นรถ"
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">หมายเลขรถ</th>
                                                <th className="text-center">ป้ายทะเบียน</th>
                                                <th className="text-center">ปีรถ</th>
                                                <th className="text-center">ยี่ห้อรถ</th>
                                                <th className="text-center">รุ่นรถ</th>
                                                <th className="text-center">วันภาษีหมดอายุ</th>
                                                <th className="text-center"></th>
                                                <th className="text-center">จำนวนวันที่จะครบกำหนด</th>
                                                <th className="text-center edit">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={9} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.bike_channel_name ? item.bike_channel_name : "-"}</td>
                                                    <td className="text-center">{item.license_plate ? item.license_plate : "-"}</td>
                                                    <td className="text-center">{item.bike_year ? item.bike_year + 543 : "-"}</td>
                                                    <td className="text-center">{item.brand_name ? item.brand_name : "-"}</td>
                                                    <td className="text-center">{item.model_name ? item.model_name : "-"}</td>
                                                    <td className="text-center">{item.tax_expire_date ? format_date(item.tax_expire_date) : "-"}</td>
                                                    <td className="text-center">
                                                        {item.tax_countdown < 0 && (
                                                            <span className="bg-danger rounded px-2 py-1 text-white">
                                                                {"เกินกำหนดต่อภาษี"}
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td className="text-center">{item.tax_countdown ? item.tax_countdown < 0 ? item.tax_countdown * -1 : item.tax_countdown : "0"}</td>
                                                    <td className="text-center edit">
                                                        <button className="btn btn-orange w-100" onClick={() => { this.setState({ modal_confirm: true, id: item.bike_id }) }}>
                                                            ยืนยันการต่อภาษี
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* delete */}
                <Modal show={this.state.modal_confirm} onHide={() => { this.setState({ modal_confirm: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการต่อภาษี</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการลบข้อมูลดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_confirm: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-orange w-100" onClick={() => { this.Update() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
