import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import {
    GET,
    format_date,
    print_paper01,
    days_between,
    getimgBase64
} from "../../components/CustomComponent.js";
import React, { Component } from "react";

export default class ListReceipt extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            data: null,

            filter_search: "",

            district: "",
            province: "",
            sub_district: "",
            zip_code: "",

        }
    }
    async componentDidMount() {
        let result = await GET(null, "v1/TH/rental-manage/detail-public/" + this.props.location.query.id, null);
        if (result && result.status) {
            let item = result.data.data; 
            let image = item.detail_picture64 ? item.detail_picture64.map((e) => { return "data:image/png;base64," + e.picture64; }) : [];
            let signature = item.signature64 ? item.signature64.includes("base64") ? item.signature64 : "data:image/png;base64," + item.signature64 : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACAQMAAABIeJ9nAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAMSURBVHicY2BgYAAAAAQAAfYXOFUAAAAASUVORK5CYII=";
                                       
            let total_day = days_between(
                new Date(Number(format_date(item.pick_up_date, "yyyy,mm,dd", "en"))),
                new Date(Number(format_date(item.return_date, "yyyy,mm,dd", "en"))),

                format_date(item.pick_up_date, "hh:mm"),
                format_date(item.return_date, "hh:mm"));
            print_paper01(
                {
                    created_by: item.created_by,
                    district: item.district,
                    province: item.province,
                    sub_district: item.sub_district,
                    zip_code: item.zip_code,
                    address: item.address,
                    rental_id: this.props.location.query.id,
                    admin_name: item.admin_name,
                    rental_number: item.rental_number,
                    booking_number: item.booking_number,
                    id_card: item.id_card,
                    full_name: item.full_name,
                    birthday: item.birthday,
                    nationality: item.nationality,
                    card_issue: item.card_issue,
                    date_issue: item.date_issue,
                    date_expire: item.date_expire,
                    temporary_accommodation: item.temporary_accommodation,
                    facebook: item.facebook,
                    phone: item.phone,
                    email: item.email,
                    id_line: item.id_line,
                    selected_bike: {
                        brand_name: item.brand_name,
                        model_name: item.model_name,
                        license_plate: item.license_plate,
                        chassis_number: item.chassis_number,
                        bike_color: item.bike_color,
                        cc_size: item.cc_size,
                        bike_year: item.bike_year,
                        title_picture: item.title_picture,
                        daily_price: item.rental_price / days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en"))),
                        bike_channel_name: item.bike_channel_name
                    },
                    pick_up_date: item.pick_up_date,
                    pick_up_time: item.pick_up_time,
                    pick_up_location: item.pick_up_location,
                    return_date: item.return_date,
                    return_time: item.return_time,
                    return_location: item.return_location,
                    rental_type: item.rental_type === "daily" ? { value: "daily", label: "รายวัน" } :
                        item.rental_type === "weekly" ? { value: "weekly", label: "รายสัปดาห์" } :
                            item.rental_type === "monthly" ? { value: "monthly", label: "รายเดือน" } : null,
                    mileage: item.mileage,
                    oil_volume: item.oil_volume,
                    rental_price: item.rental_price,
                    guarantee_type: item.guarantee_type === 1 ? { value: "1", label: "เงินประกัน" } :
                        item.guarantee_type === 2 ? { value: "2", label: "สำเนาบัตรประชาชน" } :
                            item.guarantee_type === 3 ? { value: "3", label: "บัตรประชาชนตัวจริง" } :
                                item.guarantee_type === 4 ? { value: "4", label: "บัตรข้าราชการ" } :
                                    item.guarantee_type === 5 ? { value: "5", label: "บัตรนักศึกษา" } :
                                        item.guarantee_type === 6 ? { value: "6", label: "พาสปอร์ตตัวจริง" } :
                                            item.guarantee_type === 7 ? { value: "7", label: "สำเนาพาสปอร์ต" } :
                                                item.guarantee_type === 8 ? { value: "8", label: "ใบขับขี่" } :
                                                    item.guarantee_type === 9 ? { value: "9", label: "ใบขับขี่ตัวจริง" } :
                                                        item.guarantee_type === 10 ? { value: "10", label: "เงินหยวน" } :
                                                            item.guarantee_type === 11 ? { value: "11", label: "เงินดอลล่า" } :
                                                                item.guarantee_type === 12 ? { value: "12", label: "เงินยูโร" } : null,
                    guarantee_amount: item.guarantee_amount,
                    guarantee_money: item.guarantee_money,
                    equipment_fee: item.equipment_fee,
                    discount_price: item.discount_price,
                    delivery_price: item.delivery_price,
                    recommen_id: item.recommen_id,
                    total_price: item.total_price,
                    signature: signature,
                    equipment_list: item.equipment_list,
                    total_price_equipment: item.total_price_equipment,
                    total_qty_equipment: item.total_qty_equipment,
                    detail_picture: image,
                    title_picture: item.title_picture,
                    rental_status: item.rental_status,
                    days: total_day,

                    district: this.state.district,
                    province: this.state.province,
                    sub_district: this.state.sub_district,
                    zip_code: this.state.zip_code,
                }
            )
        }

    }
    render() {
        return (
            <div></div>
        )
    }
}
