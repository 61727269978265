import React, { Component } from "react"
import {
  removeStorage,
} from "../components/CustomComponent.js"
import "../assets/css/bootstrap.css"

export default class Loading extends Component {
  componentDidMount() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (token) {
      if (!user) {
        removeStorage();
        window.location.href = "/index";
      } else if (!user.user_type) {
        window.location.href = "/user/choose-car";
      } else {
        localStorage.setItem("locale", "th");
        window.location.href = "/admin/dashboard";
      }
    } else {
      removeStorage();
      window.location.href = "/index";
    }
  }
  render() {
    return <div></div>
  }
}
