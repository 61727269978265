import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import {
    tokens,
    GET,
    success_url,
    NumberZero,
    toFixed,
    print_receipt,
    total
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import Pagination from '@mui/material/Pagination';
import { Modal } from "react-bootstrap";

export default class ListReceipt extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            data: null,

            filter_search: ""

        }
    }
    async componentDidMount() {

        await this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search.replaceAll("RE", "DP").replaceAll("#", "%23"),
        }
        let result = await GET(tokens, "v1/TH/receipt/filter-search/page/" + this.state.page, data);
        if (result && result.status) {
            this.setState({ data: result.data })
        }
        this.setState({ loading: false });
    }
    Delete = () => {
        success_url("/admin/list-receipt")
    }
    handlePageChange(pageNumber) {
        this.setState({ page: pageNumber })
        setTimeout(() => { this.GetAll() }, 10)
    }
    clear_form() {
        this.setState({
            modal_create: false,
            modal_update: false,
            modal_delete: false,

            page: 1,
            loading: false,

            filter_search: ""
        })
    }
    GetListById = async (item, id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/cashier/detail/" + id, null);
        if (result && result.status) {
            let event4 = result.data.data.filter(e => e.event_type === 4 && (e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูล" || e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูลค้างชำระ"));
            item.service_charge_pay = total(event4, "total_price");
            print_receipt(item)
        }
        this.setState({ loading: false });
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="ใบเสร็จรับเงิน" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>ใบเสร็จรับเงิน</b></h4>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({ page: 1 })
                                                setTimeout(() => {
                                                    this.GetAll();
                                                }, 1);
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({ page: 1 })
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_search}
                                    />
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>
                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                page: 1
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">เลขที่ใบเสร็จ</th>
                                                <th className="text-center">ลูกค้า</th>
                                                <th className="text-center">Ref No.</th>
                                                <th className="text-center">ยอดบิล</th>
                                                <th className="text-center">ยอดรับชำระ</th>
                                                <th className="text-center">หมายเหตุ</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={8} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{NumberZero(((this.state.page - 1) * 10) + index + 1, 5)}</td>
                                                    <td className="text-center">{item.payment_number ? item.payment_number.replaceAll("DP", "RE") : "-"}</td>
                                                    <td className="text-center">{item.full_name ? item.full_name : "-"}</td>
                                                    <td className="text-center">{item.rental_number ? item.rental_number : "-"}</td>
                                                    <td className="text-center">{item.total_price ? toFixed(item.total_price) : "0.00"}</td>
                                                    <td className="text-center">{item.total_pay || item.total_pay_transfer || item.total_pay_cradit ? toFixed(item.total_pay + item.total_pay_transfer + item.total_pay_cradit) : "0.00"}</td>
                                                    <td className="text-center">{item.remark ? item.remark : "-"}{item.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ" ? "คืนรถ" : "เช่า"}</td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center view">
                                                            <label
                                                                className="wpx-30 hpx-30 bg-warning-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1"
                                                                onClick={() => { 
                                                                    if (item.admin_remark === "แคชเชียร์ ยืนยันการคืนรถ") {
                                                                        this.GetListById(item, item.rental_id);
                                                                    } else {
                                                                        print_receipt(item)
                                                                    }
                                                                }}>
                                                                <label
                                                                    className="icon mx-2 text-warning pointer" > {"\uf02f"} </label>
                                                            </label>
                                                            {/* <label
                                                                className="wpx-30 hpx-30 bg-danger-light pointer rounded-1 d-flex align-items-center justify-content-center mx-1"
                                                                onClick={() => { this.setState({ modal_delete: true }) }}
                                                            >
                                                                <label
                                                                    className="icon mx-2 text-danger pointer"
                                                                > {"\uf1f8"} </label>
                                                            </label> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        {this.state.data && this.state.data.data.length !== 0 && (
                            <div className="d-flex justify-content-between row mx-0 w-100 px-3 mt-3">
                                <div><label> แสดง {this.state.data.end_index} รายการ จาก {this.state.data.count} รายการ </label></div>
                                <Pagination count={this.state.data.page_number} page={this.state.page} color="warning" showFirstButton shape="rounded" showLastButton onChange={(event, value) => { this.handlePageChange(value) }} />
                            </div>
                        )}
                    </div>
                </div>
                {/* MODAL */}

                {/* delete */}
                <Modal show={this.state.modal_delete} onHide={() => { this.setState({ modal_delete: false }) }}>
                    <Modal.Header><Modal.Title><b>ยืนยันการลบข้อมูล</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="w-100 d-flex mb-3">
                            <label>
                                คุณแน่ใจว่าต้องการลบข้อมูลดังกล่าว
                                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
                            </label>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button className="btn btn-outline-orange w-100" onClick={() => { this.setState({ modal_delete: false }) }}>
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button className="btn btn-danger w-100" onClick={() => { this.Delete() }}>
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
