import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import Navbar from "../../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    GET,
    format_date,
    DatePickerTH,
    toFixed,
    total
} from "../../../components/CustomComponent.js";
import React, { Component } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
    FCIconic: {
        normal: "FCIconic-Light.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Italic.ttf",
        bolditalics: "FCIconic-Italic.ttf",
    },
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
}

export default class ListWithdraw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            data: [],

            filter_search: "",
            filter_date: format_date(new Date(), "yyyy-mm-dd", "en"),
            filter_status: { value: "ยังไม่คืน", label: "ยังไม่คืน" }
        }
        this.tableRef = React.createRef();
    }
    async componentDidMount() {
        this.GetAll();
    }
    GetAll = async () => {
        this.setState({ loading: true });
        let data = {
            data_search: this.state.filter_search,
            pick_up_date: this.state.filter_status && this.state.filter_status.value === "ยังไม่คืน" ? this.state.filter_date : "",
            rental_status: "",
            last_return_date: this.state.filter_status && this.state.filter_status.value === "คืนแล้ว" ? this.state.filter_date : "",
        }
        let result = await GET(tokens, "v1/TH/rental-manage/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data: result.data.data })
        }
        this.setState({ loading: false });
    }
    pdf = () => {
        let table = this.state.data.filter(item => this.state.filter_status === "" || this.state.filter_status === null || (this.state.filter_status.value === "ยังไม่คืน" && item.rental_status !== 10 && item.rental_status !== 11) || (this.state.filter_status.value === "คืนแล้ว" && (item.rental_status === 10 || item.rental_status === 11)))
            .map((item, index) => {
                return [
                    { text: index + 1, alignment: "center" },
                    { text: item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-", alignment: "center" },
                    { text: item.last_return_date ? format_date(item.last_return_date, "dd/mm/yyyy hh:mm:ss น.") : "-", alignment: "center" },
                    { text: item.rental_number ? item.rental_number.replaceAll("RT", "ISR") : "-", alignment: "center" },
                    { text: item.full_name ? item.full_name : "-", alignment: "left" },
                    { text: item.rental_number ? "ใบเช่า: " + item.rental_number : "-", alignment: "left" },
                    { text: item.guarantee_amount ? toFixed(item.guarantee_amount) : "0.00", alignment: "left" },
                    { text: item.rental_status === 10 || item.rental_status === 11 ? "คืนแล้ว" : "ยังไม่คืน", alignment: "left" },
                    {
                        text: item.guarantee_type === 1 ? "เงินประกัน" :
                            item.guarantee_type === 2 ? "สำเนาบัตรประชาชน" :
                                item.guarantee_type === 3 ? "บัตรประชาชนตัวจริง" :
                                    item.guarantee_type === 4 ? "บัตรข้าราชการ" :
                                        item.guarantee_type === 5 ? "บัตรนักศึกษา" :
                                            item.guarantee_type === 6 ? "พาสปอร์ตตัวจริง" :
                                                item.guarantee_type === 7 ? "สำเนาพาสปอร์ต" :
                                                    item.guarantee_type === 8 ? "ใบขับขี่" :
                                                        item.guarantee_type === 9 ? "ใบขับขี่ตัวจริง" :
                                                            item.guarantee_type === 10 ? "เงินหยวน" :
                                                                item.guarantee_type === 11 ? "เงินดอลล่า" :
                                                                    item.guarantee_type === 12 ? "เงินยูโร"
                                                                        : "-", alignment: "left"
                    },
                ]
            });
        var docDefinition = {
            content: [

                { text: "รายงานค้ำประกัน", alignment: "left", fontSize: 24, bold: true },
                " ",
                {
                    table: {
                        headerRows: 1,
                        widths: ['4%', '16%', '16%', '11%', '12%', '14%', '7%', '7%', '13%'],
                        body: [
                            [
                                { text: "ลำดับ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "วันที่", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "วันที่คืนรถ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "เลขที่ใบเงินประกัน", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ลูกค้า", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ref No.", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "ยอดเงิน", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "สถานะ", alignment: "center", fillColor: "#F2F2F2" },
                                { text: "หมายเหตุ", alignment: "center", fillColor: "#F2F2F2" }
                            ],
                            ...table,
                            [
                                { text: "ยอดรวมทั้งหมด", alignment: "right", fillColor: "#F2F2F2",colSpan:8 },
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                { text: toFixed(total(this.state.data.filter(item => this.state.filter_status === "" || this.state.filter_status === null || (this.state.filter_status.value === "ยังไม่คืน" && item.rental_status !== 10 && item.rental_status !== 11) || (this.state.filter_status.value === "คืนแล้ว" && (item.rental_status === 10 || item.rental_status === 11))), "guarantee_amount")), alignment: "right", fillColor: "#F2F2F2", fontsSize: 24, bold: true }
                            ],
                        ],
                    }
                },
            ],
            // , alignment: "justify" 
            defaultStyle: { font: "THSarabunNew", fontSize: 16, lineHeight: 1 },
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: 'landscape',
        }
        pdfMake.createPdf(docDefinition).open()
    }
    excel = () => {

    }
    clear_form() {
        this.setState({
            loading: false,

            filter_search: ""
        })
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="รายงาน" page3="รายงานค้ำประกัน" />
                {/* BODY */}
                <div className="body bg-light min-vh-100">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>รายงานค้ำประกัน</b></h4>
                            <div className="d-flex">
                                <button type="button" className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { window.location.href = "/admin/report" }}>
                                    <label className="icon mr-2" > {"\uf060"} </label>
                                    ย้อนกลับ
                                </button>
                                <button type="button" className="btn btn-orange px-4 mx-1 mb-3" onClick={() => { this.pdf() }}>
                                    PDF
                                </button>
                                <DownloadTableExcel
                                    filename="รายงานค้ำประกัน"
                                    sheet="รายงานค้ำประกัน"
                                    currentTableRef={this.tableRef.current}
                                >
                                    <button type="button" className="btn btn-dark px-4 mx-1 mb-3" onClick={() => { this.excel() }}>
                                        EXCEL
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                        <div className="card border-0">
                            <div className="card-header bg-contain pb-0 view">
                                {/* ค้นหาและตัวกรอง */}
                                <div className="d-flex row mx-0">
                                    {/* ค้นหา */}
                                    <input
                                        type="search"
                                        className="form-control bg-white wpx-200 border-0 mx-1 mb-2"
                                        placeholder="พิมพ์คำค้นหา..."
                                        onChange={(e) => { this.setState({ filter_search: e.target.value }); }}
                                        value={this.state.filter_search}
                                    />

                                    <Select
                                        className="form-control select-search bg-white wpx-200 mx-1 mb-2"
                                        styles={select_style}
                                        options={[
                                            { value: "ยังไม่คืน", label: "ยังไม่คืน" },
                                            { value: "คืนแล้ว", label: "คืนแล้ว" }
                                        ]}
                                        onChange={(e) => {
                                            this.setState({ filter_status: e });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}
                                        value={this.state.filter_status}
                                    ></Select>
                                    <div className="wpx-200 px-0 mx-1 mb-2">
                                        <DatePickerTH
                                            className="form-control border-0"
                                            placeholder="พิมพ์คำค้นหา..."
                                            onChange={(e) => {
                                                this.setState({ filter_date: e });
                                            }}
                                            value={this.state.filter_date}
                                        />
                                    </div>
                                    {/* ค้นหา */}
                                    <button className="btn btn-orange px-3 mb-2 mx-1" onClick={() => {
                                        this.setState({ page: 1 });
                                        setTimeout(() => {
                                            this.GetAll();
                                        }, 1);
                                    }}>ค้นหา</button>

                                    {/* รีเซ็ท */}
                                    <button className="btn btn-outline-orange px-3 mb-2 mx-1"
                                        onClick={() => {
                                            this.setState({
                                                filter_search: "",
                                                filter_date: "",
                                                filter_status: { value: "ยังไม่คืน", label: "ยังไม่คืน" },
                                            });
                                            setTimeout(() => {
                                                this.GetAll();
                                            }, 1);
                                        }}>รีเซ็ท</button>
                                </div>
                                <small className="mb-2">*** หมายเหตุ : ถ้ายังไม่คืนระบบจะค้นหาจากวันที่เช่า / ถ้าคืนแล้วระบบจะค้นหาจากวันที่คืน ***</small>
                            </div>
                            <div className="card-body p-0">
                                {/* ตาราง */}
                                <div className="table-responsive">
                                    <table className="table table-borderless table-striped" ref={this.tableRef}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">วันที่</th>
                                                <th className="text-center">วันที่คืนรถ</th>
                                                <th className="text-center">เลขที่ใบเงินประกัน</th>
                                                <th className="text-center">ลูกค้า</th>
                                                <th className="text-center">ref No.</th>
                                                <th className="text-left">ข้อมูลรถ</th>
                                                <th className="text-center">ยอดเงิน</th>
                                                <th className="text-center">สถานะ</th>
                                                <th className="text-center">หมายเหตุ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!this.state.data || this.state.data.length === 0) && (
                                                <tr> <td colSpan={9} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                            )}
                                            {this.state.data && this.state.data.filter(item => this.state.filter_status === "" || this.state.filter_status === null || (this.state.filter_status.value === "ยังไม่คืน" && item.rental_status !== 10 && item.rental_status !== 11) || (this.state.filter_status.value === "คืนแล้ว" && (item.rental_status === 10 || item.rental_status === 11)))
                                                .map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">{item.datetime_create ? format_date(item.datetime_create, "dd/mm/yyyy hh:mm:ss น.") : "-"}</td>
                                                        <td className="text-center">{item.last_return_date ? format_date(item.last_return_date, "dd/mm/yyyy hh:mm:ss น.") : "-"}</td>
                                                        <td className="text-center">{item.rental_number ? item.rental_number.replaceAll("RT", "ISR") : "-"}</td>
                                                        <td className="text-center">{item.full_name ? item.full_name : "-"} </td>
                                                        <td className="text-center">{item.rental_number ? "ใบเช่า: " + item.rental_number : "-"}</td>
                                                        <td className="text-left">{item.bike_channel_name ? item.bike_channel_name : ""} {item.license_plate ? item.license_plate : ""} {item.brand_name ? item.brand_name : ""} {item.model_name ? item.model_name : ""}</td>
                                                        <td className="text-center">{item.guarantee_amount ? toFixed(item.guarantee_amount) : "0.00"}</td>
                                                        <td className="text-center">{item.rental_status === 10 || item.rental_status === 11 ? "คืนแล้ว" : "ยังไม่คืน"}</td>
                                                        <td className="text-center">{
                                                            item.guarantee_type === 1 ? "เงินประกัน" :
                                                                item.guarantee_type === 2 ? "สำเนาบัตรประชาชน" :
                                                                    item.guarantee_type === 3 ? "บัตรประชาชนตัวจริง" :
                                                                        item.guarantee_type === 4 ? "บัตรข้าราชการ" :
                                                                            item.guarantee_type === 5 ? "บัตรนักศึกษา" :
                                                                                item.guarantee_type === 6 ? "พาสปอร์ตตัวจริง" :
                                                                                    item.guarantee_type === 7 ? "สำเนาพาสปอร์ต" :
                                                                                        item.guarantee_type === 8 ? "ใบขับขี่" :
                                                                                            item.guarantee_type === 9 ? "ใบขับขี่ตัวจริง" :
                                                                                                item.guarantee_type === 10 ? "เงินหยวน" :
                                                                                                    item.guarantee_type === 11 ? "เงินดอลล่า" :
                                                                                                        item.guarantee_type === 12 ? "เงินยูโร"
                                                                                                            : "-"}
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td className="text-right" colSpan={8}>ยอดรวมทั้งหมด</td>
                                                <td className="text-right"><h3><b>{toFixed(total(this.state.data.filter(item => this.state.filter_status === "" || this.state.filter_status === null || (this.state.filter_status.value === "ยังไม่คืน" && item.rental_status !== 10 && item.rental_status !== 11) || (this.state.filter_status.value === "คืนแล้ว" && (item.rental_status === 10 || item.rental_status === 11))), "guarantee_amount"))}</b></h3></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
